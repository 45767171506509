import { LoadingBar, Modal, ModalBottomButtons } from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GenerateModal.module.scss';
import { useTimetableStore } from '../TimetableProvider';
import { GenerateForm } from './GenerateForm';
import { useGetTimetableCardType } from '../../../hooks/useGetTimetableCardType';

export const GenerateModal: FC = observer(() => {
  const { t } = useTranslation();
  const pinboardStore = useTimetableStore();

  const cards = useGetTimetableCardType(pinboardStore.currentVersion?.uuid ?? '', false, 'network-only');

  useEffect(() => {
    if (pinboardStore.mathplanJob?.status === 'FINISHED') {
      pinboardStore.initCards(cards);
      pinboardStore.setGenerateModalOpen(false);
    }
  }, [cards, pinboardStore, pinboardStore.mathplanJob, pinboardStore.jobRunning]);

  return (
    <Modal
      hideCloseButton={true}
      title={t('pinboard.generate.title')}
      className={styles['generate-modal']}
      isOpen={pinboardStore.generateModalOpen}
      onRequestClose={() => pinboardStore.setGenerateModalOpen(false)}
    >
      {!pinboardStore.mathplanJob && <GenerateForm />}
      {pinboardStore.mathplanJob && pinboardStore.mathplanJob.status !== 'FINISHED' && (
        <div className={styles['job-running']}>
          <LoadingBar progress={pinboardStore.mathplanJob.progress} height={'l'} />
        </div>
      )}
      <ModalBottomButtons
        isLoading={pinboardStore.mathplanJob !== null && pinboardStore.mathplanJob?.status !== 'FINISHED'}
        closeButton={{
          disabled: pinboardStore.mathplanJob?.status !== 'RUNNING',
          callback: async () => {
            pinboardStore.setGenerateModalOpen(false);
            await pinboardStore.abortJob();
          },
        }}
        submitButton={{
          callback: () => pinboardStore.generate(),
          text: t('pinboard.generate.start'),
        }}
      />
    </Modal>
  );
});
