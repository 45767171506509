import {
  TeachingBlockClassGridCard,
  TeachingBlockRoomGridCard,
  TeachingBlockTeacherGridCard,
  use_LessonsQuery,
  use_TeachingBlockCardsQuery,
} from '../types/planung-graphql-client-defs';
import { generateGradientString } from '../utils/generate-gradient-string';
import { isBrightColor } from '../utils/isBrightColor';
import { averageColor } from '../utils/average-color';
import { useLoadBasicData } from './useLoadBasicData';
import { useMemorizedCacheTag } from './useMemorizedCacheTag';

export type GroupedTeachingBlockClassGridCard = {
  lessons: {
    lessonUuid: string;
    cards: {
      byDuration: {
        biggestDivisionsCount: number;
        duration: number;
        teachingBlockCardsCount: number;
        cards: TeachingBlockClassGridCard[];
      }[];
    };
  }[];
};

type GridDataCards = {
  classGridCards: TeachingBlockClassGridCard[];
  teacherGridCards: TeachingBlockTeacherGridCard[];
  roomGridCards: TeachingBlockRoomGridCard[];
};

export const useTeachingBlockDataCards = (versionUuid: string): GridDataCards => {
  const teachingBlockCardContext = useMemorizedCacheTag('TEACHING_BLOCK_CARDS');

  const [{ data: teachingBlockCardsData }] = use_TeachingBlockCardsQuery({
    variables: {
      where: {
        teachingBlockVersion: { uuid: versionUuid },
        lessonAggregate: { count_GT: 0 },
      },
    },
    pause: !versionUuid || versionUuid === '',
    context: teachingBlockCardContext,
  });

  const [{ data: lessonsData }] = use_LessonsQuery({
    variables: {
      where: {
        uuid_IN: teachingBlockCardsData?.teachingBlockCards.map(
          (teachingBlockCard) => teachingBlockCard.lesson?.uuid ?? '',
        ),
      },
    },
    pause: !teachingBlockCardsData,
  });

  const { teacherData, lessonClassesData, divisionsData, roomsData } = useLoadBasicData({
    pause: !versionUuid || versionUuid === '',
  });

  const classCards: TeachingBlockClassGridCard[] = [];
  const teacherCards: TeachingBlockTeacherGridCard[] = [];
  const roomCards: TeachingBlockRoomGridCard[] = [];

  // classes
  teachingBlockCardsData?.teachingBlockCards.forEach((teachingBlockCard) => {
    const lesson = lessonsData?.lessons.find((lesson) => lesson.uuid === teachingBlockCard.lesson?.uuid);
    const _colors: string[] = [];

    lesson?.teachersConnection.edges.forEach((lessonTeacher) => {
      const order = lessonTeacher.properties?.order;
      const teacher = teacherData?.people.find((teacher) => teacher.uuid === lessonTeacher.node.uuid);

      if (teacher?.timetableConfig?.color) {
        if (_colors[order] === undefined) {
          _colors[order] = teacher.timetableConfig.color;
        } else {
          _colors.push(teacher.timetableConfig.color);
        }
      }
    });

    const colors = _colors.filter((color) => color);
    const cssGradientString = generateGradientString(colors);

    if (teachingBlockCard.lesson && teachingBlockCard.uuid) {
      const lessonClasses = lessonClassesData?.lessonClasses.filter((lc) =>
        teachingBlockCard.lessonClasses?.some((l) => l.uuid === lc.uuid),
      );

      lessonClasses?.forEach((lessonClass) => {
        const division = divisionsData?.divisions.find((division) => division.uuid === lessonClass.usedDivision?.uuid);

        const divisionsCount = division?.groupsConnection.totalCount ?? 1;

        if (lessonClass.groups.length > 0) {
          lessonClass.groups.forEach((group) => {
            division?.groupsConnection.edges.forEach(({ properties: { order }, node: { uuid } }) => {
              if (uuid === group.uuid) {
                const height = 100 / divisionsCount;
                classCards.push({
                  __typename: 'TeachingBlockClassGridCard',
                  uuid: `${group.uuid}_${teachingBlockCard.uuid}`,
                  classUuid: lessonClass.class.uuid,
                  teachingBlockCardUuid: teachingBlockCard.uuid ?? '',
                  lessonUuid: teachingBlockCard.lesson?.uuid ?? '',
                  locked: teachingBlockCard.locked,
                  colors,
                  startDate: teachingBlockCard.startDate,
                  endDate: teachingBlockCard.endDate,
                  duration: teachingBlockCard.duration,
                  divisionsCount,
                  usedPosition: order,
                  cssGradientString: cssGradientString || undefined,
                  cssHeight: `${height.toFixed(3)}%`,
                  cssTop: `${(height * order).toFixed(3)}%`,
                  textColor: colors.length
                    ? isBrightColor(averageColor(colors))
                      ? 'var(--grid-card-text)'
                      : 'var(--grid-card-text-light)'
                    : 'var(--grid-card-text)',
                  includeHoliday: teachingBlockCard.includeHoliday ?? false,
                });
              }
            });
          });
        } else {
          classCards.push({
            __typename: 'TeachingBlockClassGridCard',
            uuid: `${lessonClass.class.uuid}_${teachingBlockCard.uuid}`,
            classUuid: lessonClass.class.uuid,
            teachingBlockCardUuid: teachingBlockCard.uuid ?? '',
            lessonUuid: teachingBlockCard.lesson?.uuid ?? '',
            locked: teachingBlockCard.locked,
            colors,
            startDate: teachingBlockCard.startDate,
            endDate: teachingBlockCard.endDate,
            duration: teachingBlockCard.duration,
            divisionsCount: 1,
            usedPosition: 1,
            cssGradientString: cssGradientString || undefined,
            cssHeight: `100%`,
            cssTop: '0',
            textColor: colors.length
              ? isBrightColor(averageColor(colors))
                ? 'var(--grid-card-text)'
                : 'var(--grid-card-text-light)'
              : 'var(--grid-card-text)',
            includeHoliday: teachingBlockCard.includeHoliday ?? false,
          });
        }
      });
    }
  });

  // teachers
  teachingBlockCardsData?.teachingBlockCards.forEach((teachingBlockCard) => {
    const teachers = teacherData?.people.filter((p) => teachingBlockCard.teachers.some((t) => t.uuid === p.uuid));

    teachers?.forEach((teacher) => {
      const lesson = lessonsData?.lessons.find((lesson) => lesson.uuid === teachingBlockCard.lesson?.uuid);
      const _colors: string[] = [];

      lesson?.teachersConnection.edges.forEach((lessonTeacher) => {
        const order = lessonTeacher.properties?.order;
        const teacher = teacherData?.people.find((teacher) => teacher.uuid === lessonTeacher.node.uuid);

        if (teacher?.timetableConfig?.color) {
          if (_colors[order] === undefined) {
            _colors[order] = teacher.timetableConfig.color;
          } else {
            _colors.push(teacher.timetableConfig.color);
          }
        }
      });

      const colors = _colors.filter((color) => color);
      const cssGradientString = generateGradientString(colors);

      teacherCards.push({
        __typename: 'TeachingBlockTeacherGridCard',
        uuid: `${teacher.uuid}_${teachingBlockCard.uuid}`,
        teacherUuid: teacher?.uuid ?? '',
        teachingBlockCardUuid: teachingBlockCard.uuid ?? '',
        lessonUuid: teachingBlockCard.lesson?.uuid ?? '',
        locked: teachingBlockCard.locked,
        colors,
        startDate: teachingBlockCard.startDate,
        endDate: teachingBlockCard.endDate,
        duration: teachingBlockCard.duration,
        usedPosition: 0,
        cssGradientString: cssGradientString || undefined,
        textColor: colors.length
          ? isBrightColor(averageColor(colors))
            ? 'var(--grid-card-text)'
            : 'var(--grid-card-text-light)'
          : 'var(--grid-card-text)',
        includeHoliday: teachingBlockCard.includeHoliday ?? false,
      });
    });
  });

  // rooms
  teachingBlockCardsData?.teachingBlockCards.forEach((teachingBlockCard) => {
    const rooms = roomsData?.rooms.filter((room) => teachingBlockCard.rooms.some((r) => r.uuid === room.uuid));

    rooms?.forEach((room) => {
      const lesson = lessonsData?.lessons.find((lesson) => lesson.uuid === teachingBlockCard.lesson?.uuid);
      const _colors: string[] = [];

      lesson?.teachersConnection.edges.forEach((lessonTeacher) => {
        const order = lessonTeacher.properties?.order;
        const teacher = teacherData?.people.find((teacher) => teacher.uuid === lessonTeacher.node.uuid);

        if (teacher?.timetableConfig?.color) {
          if (_colors[order] === undefined) {
            _colors[order] = teacher.timetableConfig.color;
          } else {
            _colors.push(teacher.timetableConfig.color);
          }
        }
      });

      const colors = _colors.filter((color) => color);
      const cssGradientString = generateGradientString(colors);

      roomCards.push({
        __typename: 'TeachingBlockRoomGridCard',
        uuid: `${room.uuid}_${teachingBlockCard.uuid}`,
        roomUuid: room?.uuid ?? '',
        teachingBlockCardUuid: teachingBlockCard.uuid ?? '',
        lessonUuid: teachingBlockCard.lesson?.uuid ?? '',
        locked: teachingBlockCard.locked,
        colors,
        startDate: teachingBlockCard.startDate,
        endDate: teachingBlockCard.endDate,
        duration: teachingBlockCard.duration,
        usedPosition: 0,
        cssGradientString: cssGradientString || undefined,
        textColor: colors.length
          ? isBrightColor(averageColor(colors))
            ? 'var(--grid-card-text)'
            : 'var(--grid-card-text-light)'
          : 'var(--grid-card-text)',
        includeHoliday: teachingBlockCard.includeHoliday ?? false,
      });
    });
  });

  return {
    classGridCards: classCards.sort((a, b) => {
      return a.lessonUuid === b.lessonUuid
        ? (a.duration ?? 1) - (b.duration ?? 1)
        : a.lessonUuid === b.lessonUuid
          ? 0
          : -1;
    }),
    teacherGridCards: teacherCards.sort((a, b) => {
      return a.lessonUuid === b.lessonUuid
        ? (a.duration ?? 1) - (b.duration ?? 1)
        : a.lessonUuid === b.lessonUuid
          ? 0
          : -1;
    }),
    roomGridCards: roomCards.sort((a, b) => {
      return a.lessonUuid === b.lessonUuid
        ? (a.duration ?? 1) - (b.duration ?? 1)
        : a.lessonUuid === b.lessonUuid
          ? 0
          : -1;
    }),
  };
};
