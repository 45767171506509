import styles from '../../RequireAuth.module.css';
import { AboutModal } from '../../components/AboutModal/AboutModal';
import { useState } from 'react';
import { Button, Card, InfoIcon, Logo } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

export const Login = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [showAbout, setShowAbout] = useState<boolean>(false);

  const login = async () => {
    await auth.signinRedirect();
  };

  return (
    <div className={styles.wrapper} data-cy='layoutWrapper'>
      <header>
        <Button hierarchy={'tertiary'} icon={<InfoIcon />} onClick={() => setShowAbout(true)}></Button>
      </header>
      <AboutModal state={showAbout} setState={setShowAbout}></AboutModal>
      <main className={styles.container}>
        <Card>
          <div className={styles.logo}>
            <Logo type={'planung'} collapsed={false} />
          </div>
          <div className={styles.vspace}>{t('auth.app-about')}</div>
          <div className={styles.vspace}>
            <Button hierarchy='primary' onClick={() => login()} fullWidth={true}>
              {t('auth.login')}
            </Button>
          </div>
        </Card>
      </main>
      <footer>Impressum | Datenschutz | Nutzungsbedingungen | Partner</footer>
    </div>
  );
};
