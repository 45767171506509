import { useColumnsSort } from '../../../hooks/useColumnsSort';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  Row,
  Table,
  useDefaultSelecting,
} from '@bp/ui-components';
import React, { useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { createTimetableVersionTableColumns } from '../utils/TimetableVersionTableUtils';
import { TimetableVersionTableType } from '../graphql/types';
import { useConfirm } from '../../../hooks/useConfirm';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { hexToColorOption } from '../../../utils/colorUtils';
import { loadingReducer, loadingReducerInitialState } from '../../../reducer/loadingReducer';
import { useTimetableClasses } from '../hooks/useTimetableClasses';
import { useTimetableStore } from '../../TimetableGrid/TimetableProvider';

type TimetableVersionClassesTableProps = {
  onRowSelection: (rowData: TimetableVersionTableType, index: number) => void;
  onShowLessonClick: (uuid: string) => void;
  versionUuid: string;
  versionClassesUuids: string[];
  onRemove: (uuids: string[]) => void;
};

export const TimetableVersionClassesTable = ({
  onRowSelection,
  onShowLessonClick,
  versionUuid,
  versionClassesUuids,
  onRemove,
}: TimetableVersionClassesTableProps) => {
  const { t } = useTranslation();

  const { sorting, saveSorting } = useColumnsSort('timetableVersionClassesTable');

  const { rowSelection, onRowSelectionChange } = useDefaultSelecting({ 0: true });
  const columns = useMemo(() => createTimetableVersionTableColumns(t('classes.title.singular') as string), [t]);

  const { classesData } = useLoadBasicData({ pause: !versionUuid });

  const classTableData: TimetableVersionTableType[] = useMemo(() => {
    return (
      classesData?.classes
        .filter((c) => versionClassesUuids.includes(c.uuid))
        .map((c) => {
          const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
          return {
            color: {
              color: html,
              label,
            },
            count: 0,
            name: c.name,
            shortName: c.shortName,
            uuid: c.uuid,
          };
        }) ?? []
    );
  }, [classesData?.classes, versionClassesUuids]);

  const [loadingState] = useReducer(loadingReducer, loadingReducerInitialState);

  const { confirm, ConfirmationDialog } = useConfirm();

  const handleRemove = async (uuids: string[]) => {
    const res = await confirm({
      title: t('timetableVersion.removeClasses', { count: uuids.length }),
      message: t('timetableVersion.removeClassesDescription', { count: uuids.length }),
    });

    if (res) {
      onRowSelectionChange({ 0: true });
      onRemove(uuids);
    }
  };

  const { canBeRemoved } = useTimetableClasses(versionUuid);
  const store = useTimetableStore();
  const readOnly = store.readonly;

  return (
    <>
      <Table<TimetableVersionTableType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={900}
        sorting={sorting}
        onSortingChange={saveSorting}
        columns={columns}
        data={classTableData}
        lastColWidth='140px'
        lastCol={(row: Row<TimetableVersionTableType>) => {
          return (
            <ButtonGroup loading={loadingState.some((ls) => ls.uuid === row.original.uuid)}>
              <Button hierarchy='secondary' onClick={() => onShowLessonClick(row.original.uuid)}>
                {t('timetableVersion.showLessons')}
              </Button>
              <Dropdown
                noPadding
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu
                  data={[
                    {
                      label: t('timetableVersion.editData'),
                      disabled: true,
                      onClick: () => {
                        console.log('edit clicked');
                      },
                    },
                    {
                      label: t('timetableVersion.test'),
                      disabled: true,
                    },
                    {
                      label: t('timetableVersion.remove'),
                      type: 'default',
                      color: 'error',
                      disabled: !canBeRemoved([row.original.uuid]) || readOnly,
                      onClick: async () => {
                        await handleRemove([row.original.uuid]);
                      },
                    },
                  ]}
                />
              </Dropdown>
            </ButtonGroup>
          );
        }}
        showRowHover
        onRowClick={(event, row) => {
          if (onRowSelectionChange) onRowSelectionChange({ [row.id]: true });
          onRowSelection(row.original, row.index);
          document.querySelector('#content')?.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        rowSelection={rowSelection}
        showSelected
      />
      <ConfirmationDialog />
    </>
  );
};
