import { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { SubjectCurriculumStats } from '../../../../components/CurriculumStats/SubjectCurriculumStats';
import { CurriculumOverviewTable } from '../../../../components/Curricula/Tables/CurriculumOverviewTable';
import { useCurriculum } from '../../../../components/Curricula/hooks/useCurriculum';
import { sumUpArray } from '../../../../utils/arrayFunc';
import { Grid, GridColumn, GridRow } from '@bp/ui-components';
import { CurriculumContext } from './CurriculaPage';

export const GradeCurriculaPage: FC = observer(() => {
  const curriculumContext = useContext(CurriculumContext);

  // statistical data
  const { tableData } = useCurriculum({
    versionUuid: curriculumContext.versionUuid ?? '',
    grade: curriculumContext.grade,
  });

  const studentsHours = sumUpArray(tableData.map((c) => c?.curriculumInfo?.studentHours ?? 0)),
    teachersHours = sumUpArray(tableData.map((c) => c?.curriculumInfo?.teacherHours ?? 0)),
    teachingBlockWeeks = sumUpArray(tableData.map((c) => c?.curriculumInfo?.teachingBlockWeeks ?? 0)),
    deputateSubjectContainer = sumUpArray(tableData.map((c) => c?.curriculumInfo?.epochenDeputat ?? 0)),
    deputateLessonUnit = sumUpArray(tableData.map((c) => c?.curriculumInfo?.fachstundenDeputat ?? 0));

  return (
    <Grid useFormGap>
      <GridRow>
        <GridColumn width={6}>
          <></>
        </GridColumn>
        <GridColumn width={6}>
          <SubjectCurriculumStats
            studentsHours={studentsHours}
            teachersHours={teachersHours}
            teachingBlockWeeks={teachingBlockWeeks}
            deputateSubjectContainer={deputateSubjectContainer}
            deputateLessonUnit={deputateLessonUnit}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <CurriculumOverviewTable />
      </GridRow>
    </Grid>
  );
});
