import { CurriculaListQuery, TeachingLoadFactorsQuery } from '../../../types/planung-graphql-client-defs';
import { groupByArray, partition } from '../../../utils/arrayFunc';
import { t } from 'i18next';
import { calculateLessonInfos, DeputatInfo, TimetableInfo } from '../../../utils/calculateLessonInfos';
import { CurriculaTableDataType } from '../types';
import { CardsInfo } from '../../Lessons/Tables/TimetableVersionLessonsTable';

export const curriculumResponseToTableType = ({
  curriculumVersionUuid,
  teachingLoadFactors,
  queryCurriculaData,
  schoolYearUuid,
  filterByGrade,
  filterBySubject,
}: {
  curriculumVersionUuid: string | undefined;
  queryCurriculaData: CurriculaListQuery | undefined;
  teachingLoadFactors?: TeachingLoadFactorsQuery | undefined;
  schoolYearUuid?: string;
  filterByGrade?: string;
  filterBySubject?: string;
}): CurriculaTableDataType[] => {
  return (
    queryCurriculaData?.curricula
      .filter((curriculum) => {
        const isGradeFiltered =
          !curriculum.curriculumGrades.length ||
          curriculum.curriculumGrades.some((cg) => cg.grades.includes(parseInt(filterByGrade as string)));
        const isSubjectFiltered = filterBySubject && curriculum.subject.uuid === filterBySubject;

        return isGradeFiltered || isSubjectFiltered || (!filterByGrade && !filterBySubject);
      })
      .map((curriculum) => {
        const data: CurriculaTableDataType = {
          isEpochPlan: curriculum.subject.__typename === 'SubjectContainer',
          subject: curriculum.subject.name,
          subjectUuid: curriculum.subject.uuid,
          subjectShortName: curriculum.subject.shortName,
          uuid: curriculum.uuid,
          comment: curriculum.comment,
          subTitle: curriculum.subTitle,
          elective: curriculum.elective ?? false,
          teachingLoadEnabled: curriculum.teachingLoadEnabled ?? false,
          timetableEnabled: curriculum.timetableEnabled ?? false,
          teachers: curriculum.persons.map((person) => {
            const properties = curriculum.personsConnection.edges.find(
              (edge) => edge.node.uuid === person.uuid,
            )?.properties;

            return {
              ...person,
              teachingLoadEnabled: !!properties?.teachingLoad,
              presenceEnabled: !!properties?.present,
            };
          }),
          teacherUuids: curriculum.persons.map((p) => p.uuid),
          curriculumVersionUuid: curriculumVersionUuid ?? '',
          grades: curriculum.curriculumGrades.flatMap((cg) => cg.grades),
          classes: curriculum.curriculumGrades.flatMap((cg) => {
            return cg.lessonClasses.map((lc) => ({
              uuid: lc.class.name,
              name: lc.class.name,
              shortName: lc.class.shortName,
              grade: isNaN(lc.class.grade as number) ? undefined : (lc.class.grade as number),
              gradeGroup: lc.class.gradeGroup,
              groups: lc.groups.map((g) => ({
                uuid: g.uuid,
                name: g.name,
                shortName: g.shortName,
                classFraction: 1 / Math.max((g.division?.groups ?? []).length, 1),
              })),
            }));
          }),
          lessonUnits:
            curriculum.curriculumUnit?.map((cu) => {
              return {
                uuid: cu.uuid,
                duration: cu.duration ?? 0,
                count: cu.count ?? 0,
                subjectContainer: cu.subjectContainer,
              };
            }) ?? [],
          curriculumGrades: curriculum.curriculumGrades,
          groupGradeNames: curriculum.groupGradeNames,
          teachingLoadFactors: teachingLoadFactors?.teachingLoadFactors.map((t) => t.value.toFixed(2)).join('/') ?? '',
          lessonsInSchoolYear: curriculum.lessons.filter((l) => l.schoolYear.uuid === schoolYearUuid).length ?? 0,
        };

        const colors = curriculum.labels?.map((label) => label.color ?? '#fff') ?? ['#fff'];
        data.lessonInfo = curriculum.lessonBlueprints
          .filter((lesson) =>
            lesson.lessonClasses.some(
              (lc) => !filterByGrade || lc.class.grade === (filterByGrade ? parseInt(filterByGrade, 10) : null),
            ),
          )
          .map((lesson) =>
            calculateLessonInfos(
              {
                uuid: lesson.uuid,
                subject: curriculum.subject.name,
                subjectShortName: curriculum.subject.shortName,
                subjectUuid: curriculum.subject.uuid,
                classes: lesson.lessonClasses.map((lc) => ({
                  uuid: `uuid_${lc.class.grade ?? undefined}`,
                  name: lc.class.name,
                  shortName: lc.class.shortName,
                  grade: Number.isNaN(lc.class.grade) ? undefined : (lc.class.grade as number),
                  gradeGroup: lc.class.gradeGroup,
                  groups: lc.groups.map((g) => ({
                    uuid: g.uuid,
                    name: g.name,
                    shortName: g.shortName,
                    classFraction: g.classFraction ?? 1,
                  })),
                })),
                teachers: curriculum.personsConnection.edges.map((p) => ({
                  uuid: p.node.uuid,
                  name: p.node.name,
                  color: '',
                  teachingLoadEnabled: p.properties.teachingLoad ?? true,
                  presenceEnabled: p.properties.present ?? true,
                })),
                teachingLoadEnabled: lesson.teachingLoadEnabled ?? true,
                timetableEnabled: lesson.timetableEnabled ?? true,
                lessonUnits:
                  lesson.lessonUnit.map((lu) => ({
                    ...lu,
                    duration: lu.duration ?? 0,
                    count: lu.count ?? 0,
                  })) ?? [],
                isEpochPlan: curriculum.subject.__typename === 'SubjectContainer',
                curriculumUuid: curriculum.uuid,
              },
              teachingLoadFactors,
              ['BASE', 'TIME'],
              true,
            ),
          );

        data.curriculumInfo = {
          uuid: curriculum.uuid,
          partialClasses: false,
          teachingLoadEnabled: curriculum.teachingLoadEnabled ?? false,
          teachersCount: data.lessonInfo.map((i) => i.teachersCount).reduce((a, b) => a + b, 0),
          teachersCountTeachingLoadEnabled: data.lessonInfo
            .map((i) => i.teachersCountTeachingLoadEnabled)
            .reduce((a, b) => a + b, 0),
          classesCount: data.lessonInfo.map((i) => i.classesCount).reduce((a, b) => a + b, 0),

          subjectContainerUuids: [],
          subjectContainerTeacherInfos: new Map<string, Map<string, DeputatInfo>>(),
          subjectContainerClassInfos: new Map<string, Map<string, DeputatInfo>>(),
          teacherInfos: data.lessonInfo
            .map((i) => i.classInfos)
            .reduce((a, b) => {
              b &&
                b.forEach((v, k) => {
                  a.set(k, {
                    deputat: (a.get(k)?.deputat ?? 0) + v.deputat,
                    fachstundenDeputat: (a.get(k)?.fachstundenDeputat ?? 0) + (v.fachstundenDeputat ?? 0),
                    epochenDeputat: (a.get(k)?.epochenDeputat ?? 0) + (v.epochenDeputat ?? 0),
                  });
                });
              return a;
            }, new Map<string, DeputatInfo>()),
          classInfos: data.lessonInfo
            .map((i) => i.classInfos)
            .reduce((a, b) => {
              b.forEach((v, k) => {
                a.set(k, {
                  studentCards: (a.get(k)?.studentCards ?? 0.0) + (v.studentCards ?? 0.0),
                  studentHours: (a.get(k)?.studentHours ?? 0.0) + (v.studentHours ?? 0.0),

                  teacherCards: (a.get(k)?.teacherCards ?? 0.0) + (v.teacherCards ?? 0.0),
                  teacherHours: (a.get(k)?.teacherHours ?? 0.0) + (v.teacherHours ?? 0.0),

                  teachingBlockWeeks: (a.get(k)?.teachingBlockWeeks ?? 0.0) + (v.teachingBlockWeeks ?? 0.0),
                  teachingBlocks: (a.get(k)?.teachingBlocks ?? 0.0) + (v.teachingBlocks ?? 0.0),

                  deputat: (a.get(k)?.deputat ?? 0.0) + v.deputat,
                  fachstundenDeputat: (a.get(k)?.fachstundenDeputat ?? 0.0) + (v.fachstundenDeputat ?? 0.0),
                  epochenDeputat: (a.get(k)?.epochenDeputat ?? 0.0) + (v.epochenDeputat ?? 0.0),
                });
              });
              return a;
            }, new Map<string, TimetableInfo & DeputatInfo>()),

          studentCards: data.lessonInfo.map((i) => i.studentCards).reduce((a, b) => a + b, 0.0),
          studentHours: data.lessonInfo.map((i) => i.studentHours).reduce((a, b) => a + b, 0.0),

          teacherCards: data.lessonInfo.map((i) => i.teacherCards).reduce((a, b) => a + b, 0.0),
          teacherHours: data.lessonInfo.map((i) => i.teacherHours).reduce((a, b) => a + b, 0.0),

          teachingBlockWeeks: data.lessonInfo.map((i) => i.teachingBlockWeeks).reduce((a, b) => a + b, 0.0),
          teachingBlocks: data.lessonInfo.map((i) => i.teachingBlocks).reduce((a, b) => a + b, 0.0),

          deputat: data.lessonInfo.map((i) => i.deputat).reduce((a, b) => a + b, 0.0),
          fachstundenDeputat: data.lessonInfo.map((i) => i.fachstundenDeputat ?? 0.0).reduce((a, b) => a + b, 0.0),
          epochenDeputat: data.lessonInfo.map((i) => i.epochenDeputat ?? 0.0).reduce((a, b) => a + b, 0.0),
        };

        const [subjectContainers, defaultLessons] = partition(
          curriculum.curriculumUnit ?? [],
          (e) => !!e.subjectContainer,
        );

        const subjectContainersDurationGroups = groupByArray(
          subjectContainers,
          (curriculumUnit) => curriculumUnit.duration ?? 1,
        );
        const defaultLessonsDurationGroups = groupByArray(defaultLessons, (cu) => cu.duration ?? 1);

        const defaultLessonsCardData = defaultLessonsDurationGroups.map((group) => {
          const count = group
            .map((g) => g.count)
            .reduce((previousValue, currentValue) => {
              return (previousValue ?? 0) + (currentValue ?? 0);
            }, 0);

          return group.map((curriculumUnit) => {
            const card: CardsInfo = {
              uuid: `${curriculumUnit.uuid}-${curriculumUnit.duration}-${curriculum.subject?.uuid}`,
              type: 'FS',
              typeLabel: t('FS'),
              typeLabelShort: t('FS'),
              cardLabel: curriculum.subject?.name,
              colors,
              counter: count ?? 1,
              cardLabelShort: curriculum.subject?.shortName,
              duration: curriculumUnit.duration ?? 1,
            };
            return card;
          });
        });

        const subjectContainersCardData = subjectContainersDurationGroups.map((group) => {
          const count = group
            .map((g) => g.count)
            .reduce((previousValue, currentValue) => {
              return (previousValue ?? 0) + (currentValue ?? 0);
            }, 0);
          return group.map((curriculumUnit) => {
            const card: CardsInfo = {
              uuid: `${curriculumUnit.uuid}-${curriculumUnit.duration}-${curriculum.subject?.uuid}`,
              type: 'Container',
              typeLabel: curriculumUnit.subjectContainer?.name ?? '',
              typeLabelShort: curriculumUnit.subjectContainer?.shortName ?? '',
              cardLabel: `${curriculumUnit.duration} ${t('common.week', { count: curriculumUnit.duration ?? 1 })}`,
              cardLabelShort: `${curriculumUnit.duration} ${t('common.week', { count: curriculumUnit.duration ?? 1 })}`,
              colors,
              counter: count ?? 1,
              duration: curriculumUnit.duration ?? 1,
            };
            return card;
          });
        });

        data.cardsInfos = { defaultCards: defaultLessonsCardData, subjectContainerCards: subjectContainersCardData };

        return data;
      }) ?? []
  );
};
