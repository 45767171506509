import {
  AddIcon,
  AlertDialog,
  Button,
  ButtonGroup,
  DeleteIcon,
  Dropdown,
  DropdownMenu,
  ImportIcon,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { SortDirection, useTimetableReportsQuery } from '../../types/planung-graphql-client-defs';
import {
  createTimetableCollectiveReport,
  createTimetableSingleReport,
  deleteReport,
  downloadDebug,
  downloadReport,
} from '../../utils/pdfReport';
import { ReportType } from './types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { TimetableReportType } from '@bp/planung-graphql-types';

type ReportsTableProps = {
  contextUuid: string;
  type: ReportType;
  subType: TimetableReportType;
  topic: string;
};

export type ReportPdfTableData = {
  uuid: string;
  name: string;
  timestamp: dayjs.Dayjs;
};

export const TimetableReportsTable = ({ contextUuid, type, subType }: ReportsTableProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const permissionChecker = usePermissionChecker();

  const [{ data }, refetchReports] = useTimetableReportsQuery({
    variables: { where: {}, options: { sort: [{ jobFinished: SortDirection.Desc }] } },
  });

  const tableColumns: TableColumns<ReportPdfTableData>[] = [
    {
      header: t('common.date'),
      id: 'name',
      accessorKey: 'name',
      size: 350,
    },
    {
      header: t('common.type'),
      id: 'type',
      accessorKey: 'type',
      size: 100,
    },
    {
      header: '',
      id: 'timestamp',
      accessorFn: (row) => {
        return row.timestamp.format('DD.MM.YY HH:mm');
      },
      size: 100,
    },
  ];

  const isSingle =
    subType === TimetableReportType.SingleClass ||
    subType === TimetableReportType.SingleRoom ||
    subType === TimetableReportType.SingleSubject ||
    subType === TimetableReportType.SingleTeacher;

  const tableData = useMemo(() => {
    return (
      data?.timetableReports
        .filter((r) => {
          return r.type === subType && r.context?.uuid === contextUuid;
        })
        .map((report) => {
          return {
            uuid: report.uuid,
            name: report.title,
            type: isSingle ? (report.fileEntries.length > 1 ? t('pdf.singlePdf') : t('pdf.collectivePdf')) : '',
            timestamp: dayjs(report.jobFinished),
          };
        })
        .sort((a, b) => {
          return a.timestamp.isAfter(b.timestamp) ? -1 : 1;
        }) ?? []
    );
  }, [contextUuid, data, subType]);
  const handleDownloadClick = (report: ReportPdfTableData) => {
    if (loading) return;
    void downloadReport(report, subType);
  };

  const handleDeleteClick = async (report: ReportPdfTableData) => {
    if (loading) return;
    setLoading(true);
    await deleteReport(report, subType);
    refetchReports({ requestPolicy: 'network-only' });
    setLoading(false);
  };

  const handleGenerateCollectiveClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    await createTimetableCollectiveReport(contextUuid, type, subType);
    refetchReports({ requestPolicy: 'network-only' });
    setLoading(false);
  }, [contextUuid, loading, refetchReports, subType, type]);

  const handleGenerateSingleClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    await createTimetableSingleReport(contextUuid, type, subType);
    refetchReports({ requestPolicy: 'network-only' });
    setLoading(false);
  }, [contextUuid, loading, refetchReports, subType, type]);

  const handleDebugClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    await downloadDebug(contextUuid, type);
    setLoading(false);
  }, [contextUuid, loading, type]);

  const actionBarSettings = useMemo(() => {
    if (isSingle) {
      return {
        extendedActionsRight: (
          <>
            {permissionChecker?.isOmniAdmin() && (
              <Button hierarchy={'secondary'} isLoading={loading} onClick={handleDebugClick}>
                Download Debug
              </Button>
            )}
            <Dropdown
              disabled={loading}
              trigger={
                <Button isLoading={loading} hierarchy='secondary' icon={<AddIcon />} title={t('common.generate')}>
                  {t('common.generate')}
                </Button>
              }
            >
              <DropdownMenu
                data={[
                  { label: t('pdf.collectivePdf'), onClick: handleGenerateCollectiveClick },
                  { label: t('pdf.singlePdf'), onClick: handleGenerateSingleClick },
                ]}
              />
            </Dropdown>
          </>
        ),
      };
    }
    return {
      extendedActionsRight: (
        <Button
          isLoading={loading}
          hierarchy='secondary'
          icon={<AddIcon />}
          title={t('common.generate')}
          onClick={handleGenerateCollectiveClick}
        >
          {t('common.generate')}
        </Button>
      ),
    };
  }, [handleDebugClick, handleGenerateCollectiveClick, handleGenerateSingleClick, loading, permissionChecker, subType]);

  return (
    <Table<ReportPdfTableData>
      showShadow={false}
      data={tableData}
      columns={tableColumns}
      showActionBar={true}
      hideHeader
      customPadding='var(--spacing-6)'
      actionBarSettings={actionBarSettings}
      emptyStateSettings={{ size: 'small', hideIcon: true, subtitle: '', title: t('pdf.noPdfFound') }}
      lastColWidth={'75px'}
      lastCol={(row) => {
        return (
          <>
            <ButtonGroup disabled={loading}>
              <Button
                hierarchy='secondary'
                type='button'
                isLoading={loading}
                icon={<ImportIcon className='small' />}
                onClick={() => {
                  handleDownloadClick(row.original);
                }}
              />
              <AlertDialog
                title={t('divisions.deleteConfirm', {
                  type: 'divisionRow.original.name',
                })}
                confirmText={t('common.confirmDelete')}
                cancelText={t('common.cancelDelete')}
                onConfirm={() => handleDeleteClick(row.original)}
                trigger={<Button hierarchy='tertiary' icon={<DeleteIcon className={'small'} />} />}
              ></AlertDialog>
            </ButtonGroup>
          </>
        );
      }}
    />
  );
};
