import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { CardType } from '../TimetableVersion/graphql/types';
import { BadgeCard, BadgeCardWidth, ContextMenu, ContextMenuItem } from '@bp/ui-components';
import styles from './PinboardCard.module.scss';
import classNames from 'classnames';
import { t } from 'i18next';
import { createIntArray } from '../../utils/arrayFunc';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { useMutation } from 'urql';
import {
  CreateCardDocument,
  CreateSubjectContainerCardDocument,
  useDeleteCardsMutation,
} from '../../types/planung-graphql-client-defs';
import { useTimetableStore } from '../TimetableGrid/TimetableProvider';
import { useDebounceCallback } from 'usehooks-ts';

export interface PinboardCardProps {
  card: CardType;
  count?: number;
  classUuid?: string;
  passive?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>, card: CardType) => void;
  className?: string | undefined;
}

export const PinboardCard: FC<PinboardCardProps> = observer(
  ({ card, count = 1, classUuid, passive = false, onClick, className }) => {
    const context = useMemorizedCacheTag('LESSONS');

    const [, createSubjectContainerCard] = useMutation(CreateSubjectContainerCardDocument);
    const [, createCard] = useMutation(CreateCardDocument);
    const [, deleteCards] = useDeleteCardsMutation();

    const pinboardStore = useTimetableStore();

    const hasWarnings = card.warnings ? card.warnings.length > 0 : false;
    const hasAvailabilityWarnings = card.availabilityWarnings ? card.availabilityWarnings.length > 0 : false;
    const hasRooms = card.rooms.length !== 0;

    const convertToSingle = useCallback(async () => {
      let cardsToCreateCounter = 0;
      const cardsToDeleteUuids: string[] = [];

      if (card && card.duration && card.duration > 1) {
        cardsToDeleteUuids.push(card.uuid);
        cardsToCreateCounter = cardsToCreateCounter + card.duration;
      } else {
        return;
      }

      if (card.isSubjectContainer) {
        await Promise.all(
          createIntArray(cardsToCreateCounter).map(() => {
            return createSubjectContainerCard(
              {
                duration: 1,
                lessonUuid: card.lesson?.uuid,
                versionUuid: pinboardStore.getCurrentVersion()?.uuid,
                subjectContainerUuid: card.subject?.uuid,
              },
              context,
            );
          }),
        );
      } else {
        await Promise.all(
          createIntArray(cardsToCreateCounter).map(() => {
            return createCard(
              {
                duration: 1,
                lessonUuid: card.lesson?.uuid,
                versionUuid: pinboardStore.getCurrentVersion()?.uuid,
              },
              context,
            );
          }),
        );
      }

      await deleteCards({ where: { uuid_IN: cardsToDeleteUuids } }, context);
    }, [card, context, createCard, createSubjectContainerCard, deleteCards, pinboardStore]);

    const contextMenu: ContextMenuItem[] = useMemo(() => {
      const menu: ContextMenuItem[] = [];

      menu.push({
        type: 'component',
        node: <div className={styles['menu-name']}>{card.subject?.name}</div>,
      });

      if (hasWarnings || hasAvailabilityWarnings || !hasRooms) {
        menu.push({
          type: 'ruler',
        });
      }

      if (hasWarnings) {
        menu.push({
          type: 'component',
          node: <></>,
        });
      }

      if (hasAvailabilityWarnings) {
        menu.push({
          type: 'component',
          node: <></>,
        });
      }

      if (!hasRooms) {
        menu.push({
          type: 'component',
          node: <div className={styles['menu-info']}>{t('pinboard.reason.noRoom')}</div>,
        });
      }

      menu.push(
        {
          type: 'ruler',
        },
        // {
        //   label: pinboardStore.pickedCard?.card === card ? t('pinboard.actions.unpick') : t('pinboard.actions.pick'),
        //   onClick: (e) => {
        //     e.stopPropagation();
        //     if (card && !card.locked) {
        //       if (pinboardStore.pickedCard?.card === card) {
        //         pinboardStore.pickPlacedCard(null);
        //       } else {
        //         pinboardStore.pickPlacedCard(card, { x: e.clientX, y: e.clientY });
        //       }
        //     }
        //   },
        //   disabled: card.locked,
        // },
        {
          label: pinboardStore.pinnedCard === card.uuid ? t('pinboard.actions.unpin') : t('pinboard.actions.pin'),
          onClick: (e) => {
            e.stopPropagation();
            if (card) {
              if (pinboardStore.pinnedCard === card.uuid) {
                pinboardStore.setPinnedCard(null);
              } else {
                pinboardStore.setPinnedCard(card.uuid);
              }
            }
          },
        },
        {
          label: pinboardStore.lockedCards.has(card.uuid) ? t('pinboard.actions.unlock') : t('pinboard.actions.lock'),
          onClick: () => {
            if (card) {
              if (pinboardStore.lockedCards.has(card.uuid)) pinboardStore.unlockCard(card.uuid);
              else pinboardStore.lockCard(card.uuid);
            }
          },
        },
        {
          type: 'ruler',
        },
        {
          label: t('rooms.title.plural'),
          tooltip: t('pinboard.actions.noRoomsHint'),
          disabled: true,
        },
        {
          label: t('pinboard.actions.singleHour'),
          onClick: async () => {
            await convertToSingle();
          },
          disabled: !!card.duration && card.duration <= 1,
        },
        {
          type: 'ruler',
        },
        {
          label: t('common.remove'),
          onClick: () => {
            if (card) pinboardStore.discardPickedCard();
          },
          type: 'error',
          disabled: !card.startTimeGridEntry,
        },
      );

      return menu;
    }, [card, hasWarnings, hasAvailabilityWarnings, hasRooms, pinboardStore, convertToSingle]);

    const wrapperClasses = classNames(
      styles['pinboard-card'],
      {
        [styles['is-multi']]: count >= 1,
        [styles['is-picked']]: pinboardStore.pickedCard?.cardUuid === card.uuid,
        [styles['is-pinned']]: pinboardStore.pinnedCard === card.uuid,
        [styles['is-locked']]: pinboardStore.lockedCards.has(card.uuid),
        [styles['has-conflict']]: pinboardStore.highlightMode.includes('rooms')
          ? card.rooms.length === 0
          : pinboardStore.highlightMode.includes('warnings')
            ? hasAvailabilityWarnings || hasWarnings
            : false,
      },
      className,
    );

    const menuClasses = classNames(styles.menu, {
      [styles['is-placed']]: !!card.startTimeGridEntry,
    });

    const debounceOnCardHover = useDebounceCallback(() => {
      pinboardStore.setHoveredCard(card.uuid);
    }, 500);

    const onMouseEnter = () => {
      if (!pinboardStore.hoveredCard) {
        pinboardStore.setHoveredCard(card.uuid);
      } else {
        debounceOnCardHover();
      }
    };
    const onMouseLeave = () => {
      debounceOnCardHover.cancel();
    };

    return (
      <ContextMenu className={menuClasses} disabled={passive} data={contextMenu} usePortal={false}>
        <div
          className={wrapperClasses}
          title={card.subject?.name ?? ''}
          onClick={(e) => {
            onClick && onClick(e, card);
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <BadgeCard
            label={card.subject?.shortName ?? ''}
            width={card.duration as BadgeCardWidth}
            rows={card.badgeCardRows}
            color={card.badgeCardTextColor}
            count={count}
          />
        </div>
      </ContextMenu>
    );
  },
);
