import {
  _TeachingBlockCardsQuery,
  _TimetableCardsQuery,
  LessonsQuery,
  LessonsWithoutCardsQuery,
  TeachingLoadFactorsQuery,
} from '../../../types/planung-graphql-client-defs';
import { CardsInfo, CardsInfoLesson, LessonTableType } from '../Tables/TimetableVersionLessonsTable';
import { groupByArray, partition } from '../../../utils/arrayFunc';
import { t } from 'i18next';
import { isSubject } from '../../../utils/typeguards';
import { calculateLessonInfos } from '../../../utils/calculateLessonInfos';
import { getStatus } from './getStatus';

export const lessonResponseToTableType = ({
  versionContext,
  teachingLoadFactors,
  queryLessonsData,
  isTeachingBlock,
  teachingBlockCards,
  timetableCards,
  subjectsData,
  subjectContainerData,
}: {
  queryLessonsData: LessonsQuery | LessonsWithoutCardsQuery | undefined;
  teachingLoadFactors?: TeachingLoadFactorsQuery | undefined;
  versionContext: boolean;
  isTeachingBlock?: boolean;
  teachingBlockCards?: _TeachingBlockCardsQuery['teachingBlockCards'];
  timetableCards?: _TimetableCardsQuery['timetableCards'];
  subjectsData?: { uuid: string; name: string; shortName: string }[];
  subjectContainerData?: { uuid: string; name: string; shortName: string }[];
}) => {
  const lessonsData: LessonTableType[] =
    queryLessonsData?.lessons.map((lesson): LessonTableType => {
      /*
        VORSICHT !
        versionsAggregate = aktive Stundenpläne
        timetableCardsAggregate = gesteckte Karten
       */

      const lessonClasses: CardsInfoLesson | null =
        lesson.lessonClassesConnection.edges && lesson.lessonClassesConnection.edges.length > 0
          ? {
              uuid: lesson.uuid,
              usedDivision: lesson.lessonClassesConnection.edges[0].node.usedDivision,
              groups: lesson.lessonClassesConnection.edges[0].node.groups,
            }
          : null;

      const data: LessonTableType = {
        onlyInTimetableVersion: lesson.onlyInTimetableVersion,
        subject: lesson.subject.name,
        subjectShortName: lesson.subject.shortName,
        subjectUuid: lesson.subject.uuid,
        editStatus: getStatus(lesson, versionContext),
        uuid: lesson.uuid,
        classes: lesson.lessonClassesConnection.edges.map((lessonClass) => {
          return {
            uuid: lessonClass.node.class.uuid,
            name: lessonClass.node.class.shortName,
            shortName: lessonClass.node.class.shortName,
            grade: lessonClass.node.class.grade ?? 0,
            gradeGroup: lessonClass.node.class.gradeGroup,
            groups: lessonClass.node.groups.map((group) => ({
              ...group,
              classFraction: 1 / (lessonClass.node.usedDivision?.groupsAggregate?.count ?? 1),
            })),
          };
        }),
        classesUuids:
          lesson.lessonClassesConnection.edges.map((lessonClass) => {
            return lessonClass.node.class.uuid;
          }) ?? [],
        teachers: lesson.teachersConnection.edges.map((teacher) => {
          return {
            uuid: teacher.node.uuid,
            name: teacher.node.displayNameShort ?? teacher.node.shortName,
            color: teacher.node.timetableConfig?.color,
            teachingLoadEnabled: teacher.properties.teachingLoad ?? false,
            presenceEnabled: teacher.properties.present ?? false,
            teachingLoadHours: teacher.properties.teachingLoadHours ?? undefined,
          };
        }),
        teacherUuids:
          lesson.teachersConnection.edges.map((teacher) => {
            return teacher.node.uuid;
          }) ?? [],
        elective: lesson.elective ?? false,
        teachingLoadEnabled: lesson.teachingLoadEnabled ?? false,
        timetableEnabled: lesson.timetableEnabled ?? false,
        lessonUnits:
          lesson.lessonUnitConnection.edges.map((lessonUnit) => {
            return {
              uuid: lessonUnit.node.uuid,
              duration: lessonUnit.node.duration ?? 0,
              count: lessonUnit.node.count ?? 0,
              subjectContainer: lessonUnit.node.subjectContainer,
            };
          }) ?? [],
        groupClassNames: lesson.groupClassNames ?? '',
        teachingLoadFactors: teachingLoadFactors?.teachingLoadFactors.map((t) => t.value.toFixed(2)).join('/ ') ?? '',
        placedCardsCount: (lesson.placedCardsCount ?? 0) + (lesson.teachingBlockPlacedCardsCount ?? 0),
        isEpochPlan: !isSubject(lesson.subject),
        curriculumUuid: lesson.curriculum?.uuid,
        schoolYearUuid: lesson.schoolYear.uuid,
        rooms: lesson.roomSupplyConnection.edges.map((edge) => edge.node),
        roomsUuids: lesson.roomSupplyConnection.edges.map((edge) => edge.node.uuid),
      };

      data.lessonInfo = calculateLessonInfos(data, teachingLoadFactors, ['BASE', 'PERSON', 'TIME']);

      if (!isTeachingBlock && timetableCards) {
        const _timetableCards = timetableCards?.filter((card) => card.lesson.uuid === lesson.uuid) ?? [];
        const _teachingBlockCards = teachingBlockCards?.filter((card) => card.lesson.uuid === lesson.uuid) ?? [];
        const defaultDurationGroups = groupByArray(_timetableCards, (card) => card.duration ?? 1);
        const subjectContainerDurationGroups = groupByArray(_teachingBlockCards, (card) => card.duration ?? 1);

        data.cardsInfos = {
          defaultCards: defaultDurationGroups
            ? defaultDurationGroups.map((group) => {
                const subject = subjectsData?.find((s) => s.uuid === group[0].subject.uuid);
                const subjectContainer = subjectContainerData?.find((s) => s.uuid === group[0].subject.uuid);

                return group.map((card) => {
                  return {
                    uuid: card.uuid,
                    type: 'FS' as const,
                    typeLabel: t('FS'),
                    typeLabelShort: t('FS'),
                    cardLabel: subject?.name ?? subjectContainer?.name ?? '',
                    cardLabelShort: subject?.shortName ?? subjectContainer?.shortName ?? '',
                    colors: lesson.teachersConnection.edges.map((e) => e.node.timetableConfig?.color ?? '#fff'),
                    counter: group.length,
                    lesson: lessonClasses,
                    duration: card.duration ?? 1,
                  };
                });
              })
            : [],
          subjectContainerCards: subjectContainerDurationGroups
            ? subjectContainerDurationGroups.map((group) => {
                const subjectContainer = subjectContainerData?.find((s) => s.uuid === group[0].subject.uuid);

                return group.map((card) => {
                  return {
                    uuid: card.uuid,
                    type: 'Container' as const,
                    typeLabel: subjectContainer?.name ?? '',
                    typeLabelShort: subjectContainer?.shortName ?? '',
                    cardLabel: `${card.duration} ${t('common.week', { count: card.duration ?? 1 })}`,
                    cardLabelShort: `${card.duration} ${t('common.week', { count: card.duration ?? 1 })}`,
                    colors: lesson.teachersConnection.edges.map((e) => e.node.timetableConfig?.color ?? '#fff'),
                    counter: group.length,
                    lesson: lessonClasses,
                    duration: card.duration ?? 1,
                  };
                });
              })
            : [],
        };
      } else if (isTeachingBlock && teachingBlockCards) {
        const _teachingBlockCards = teachingBlockCards?.filter((card) => card.lesson.uuid === lesson.uuid) ?? [];
        const subjectContainerDurationGroups = groupByArray(_teachingBlockCards, (card) => card.duration ?? 1);

        data.cardsInfos = {
          defaultCards: [],
          subjectContainerCards: subjectContainerDurationGroups
            ? subjectContainerDurationGroups.map((group) => {
                const subject = subjectsData?.find((s) => s.uuid === group[0].subject.uuid);

                return group.map((card) => {
                  return {
                    uuid: card.uuid,
                    type: 'Container' as const,
                    typeLabel: subject?.name ?? '',
                    typeLabelShort: subject?.shortName ?? '',
                    cardLabel: `${card.duration} ${t('common.week', { count: card.duration ?? 1 })}`,
                    cardLabelShort: `${card.duration} ${t('common.week', { count: card.duration ?? 1 })}`,
                    colors: lesson.teachersConnection.edges.map((e) => e.node.timetableConfig?.color ?? '#fff'),
                    counter: group.length,
                    lesson: lessonClasses,
                    duration: card.duration ?? 1,
                  };
                });
              })
            : [],
        };
      } else {
        const [subjectContainers, defaultLessons] = partition(
          lesson.lessonUnitConnection.edges,
          (e) => !!e.node.subjectContainer,
        );

        const subjectContainersDurationGroups = groupByArray(
          subjectContainers,
          (lessonUnit) => lessonUnit.node.duration ?? 1,
        );
        const defaultLessonsDurationGroups = groupByArray(
          defaultLessons,
          (lessonUnit) => lessonUnit.node.duration ?? 1,
        );

        const defaultLessonsCardData = defaultLessonsDurationGroups.map((group) => {
          const count = group
            .map((g) => g.node.count)
            .reduce((previousValue, currentValue) => {
              return (previousValue ?? 0) + (currentValue ?? 0);
            }, 0);

          return group.map((lessonUnitConnection) => {
            const lessonUnit = lessonUnitConnection.node;
            const card: CardsInfo = {
              uuid: `${lessonUnit.uuid}-${lessonUnit.duration}-${lesson.subject.uuid}`,
              type: 'FS',
              typeLabel: t('FS'),
              typeLabelShort: t('FS'),
              cardLabel: lesson.subject.name,
              colors: lesson.teachersConnection.edges.map((e) => e.node.timetableConfig?.color ?? '#fff'),
              counter: count ?? 1,
              lesson: lessonClasses,
              cardLabelShort: lesson.subject.shortName,
              duration: lessonUnit.duration ?? 1,
            };
            return card;
          });
        });

        const subjectContainersCardData = subjectContainersDurationGroups.map((group) => {
          const count = group
            .map((g) => g.node.count)
            .reduce((previousValue, currentValue) => {
              return (previousValue ?? 0) + (currentValue ?? 0);
            }, 0);
          return group.map((lessonUnitConnection) => {
            const lessonUnit = lessonUnitConnection.node;
            const card: CardsInfo = {
              uuid: `${lessonUnit.uuid}-${lessonUnit.duration}-${lesson.subject.uuid}`,
              type: 'Container',
              typeLabel: lessonUnit.subjectContainer?.name ?? '',
              typeLabelShort: lessonUnit.subjectContainer?.shortName ?? '',
              cardLabel: `${lessonUnit.duration} ${t('common.week', { count: lessonUnit.duration ?? 1 })}`,
              cardLabelShort: `${lessonUnit.duration} ${t('common.week', { count: lessonUnit.duration ?? 1 })}`,
              colors: lesson.teachersConnection.edges.map((e) => e.node.timetableConfig?.color ?? '#fff'),
              counter: count ?? 1,
              lesson: lessonClasses,
              duration: lessonUnit.duration ?? 1,
            };
            return card;
          });
        });

        data.cardsInfos = { defaultCards: defaultLessonsCardData, subjectContainerCards: subjectContainersCardData };
      }

      return data;
    }) ?? [];
  return lessonsData;
};
