import { Button, ButtonGroup, Card, Table, TableColumns } from '@bp/ui-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { usePimOrganizationsQuery } from '../../types/planung-graphql-client-defs';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { getAccessToken } from '../../utils/authStore';
import { useUpload } from '../../hooks/useUpload';
import { apiHelper } from '../../utils/apiHelper';

type OrganizationsListDataType = {
  uuid: string;
  name: string;
  planungEnabled: boolean;
};

export const OrganizationsTable = observer(() => {
  const { t } = useTranslation();

  const { sorting, saveSorting } = useColumnsSort('organization-list');
  const context = useMemorizedCacheTag('ORGANIZATION');

  const [returnLog, setReturnLog] = useState<string>('');
  const [running, setRunning] = useState<boolean>(false);

  const [{ data }] = usePimOrganizationsQuery({
    variables: {
      where: {},
    },
    context,
  });

  const organizations: OrganizationsListDataType[] = useMemo(() => {
    return (
      data?.pimOrganizations.map((o) => {
        return {
          ...o,
          planungEnabled: o.planungEnabled ?? false,
        };
      }) ?? []
    );
  }, [data]);

  const createPersonColumns = (): TableColumns<OrganizationsListDataType>[] => {
    return [
      {
        header: t('common.name') as string,
        accessorKey: 'name',
        id: 'name',
        size: 200,
      },
      {
        header: t('common.uuid') as string,
        accessorKey: 'uuid',
        id: 'uuid',
        size: 100,
      },
      {
        header: t('Planung') as string,
        accessorKey: 'planungEnabled',
        type: 'boolean',
        id: 'planungEnabled',
        size: 80,
      },
    ];
  };

  const tableColumns = useMemo(createPersonColumns, []);

  const memoizedData = useMemo((): OrganizationsListDataType[] => {
    return data ? organizations : [];
  }, [data, organizations]);

  const handleSetup = async (row: OrganizationsListDataType) => {
    await callActionAndHandleResult(
      `${import.meta.env.VITE_APP_BACKEND_URI}${import.meta.env.VITE_APP_BACKEND_REST_PATH}/setup/instance`,
      JSON.stringify({
        uuid: row.uuid,
      }),
    );
  };

  const handleUploadChange = async (files: FileList | null, params?: { uuid: string }) => {
    if (files) {
      const fileUploaded = files[0];

      const reader = new FileReader();
      reader.onload = async (e) => {
        const stringData = e.target?.result;
        try {
          setRunning(true);
          setReturnLog('');
          const result = await apiHelper({
            path: `/import/iphis`,
            method: 'POST',
            data: stringData,
            type: 'xml',
          });
          setReturnLog(JSON.stringify(result, null, 2));
          setRunning(false);
        } catch (error) {
          setReturnLog('Fehler beim Hochladen der XML-Datei:' + JSON.stringify(error));
          setRunning(false);
        }
      };
      reader.readAsText(fileUploaded);
    }
  };

  const { upload } = useUpload<{ uuid: string }>({
    uploadFile: handleUploadChange,
  });

  const callActionAndHandleResult = async (url: string, body?: BodyInit | null | undefined) => {
    setRunning(true);
    setReturnLog('');
    const result = await fetch(url, {
      method: 'POST',
      headers: { Authorization: `Bearer ${getAccessToken()}`, 'content-type': 'application/json' },
      body: body,
    });

    setReturnLog(JSON.stringify(await result.json(), null, 2));
    setRunning(false);
  };

  return (
    <div className={'tks__grid'}>
      <div className={`tks__row`}>
        <div className={'tks__col col-xs-6'}>
          <Table<OrganizationsListDataType>
            showBorderRadius
            showShadow
            canScroll
            minHeight={600}
            breakpoint={null}
            isOnWhite={false}
            onSortingChange={saveSorting}
            sorting={sorting}
            columns={tableColumns}
            data={memoizedData}
            showActionBar
            actionBarSettings={{
              showExpertFilter: false,
              showAddButton: false,
              showBulkEdit: false,
              showPrintButton: false,
            }}
            lastColWidth='120px'
            lastCol={(row) => {
              return (
                <ButtonGroup>
                  <Button
                    hierarchy='tertiary'
                    type='button'
                    disabled={row.original.planungEnabled || running}
                    onClick={async () => {
                      await handleSetup(row.original);
                    }}
                  >
                    Setup
                  </Button>
                  <Button
                    hierarchy='tertiary'
                    type='button'
                    disabled={!row.original.planungEnabled || running}
                    onClick={async () => {
                      upload({ uuid: row.original.uuid });
                    }}
                  >
                    IPHIS Import
                  </Button>
                </ButtonGroup>
              );
            }}
          />
        </div>
        <div className='tks__col col-xs-6'>
          <Card>
            <pre>{returnLog}</pre>
          </Card>
        </div>
      </div>
    </div>
  );
});
