import { useParams } from 'react-router-dom';
import { FC, useMemo } from 'react';
import {
  useAdditionalTeachingLoadsListQuery,
  useListPlannedTeacherTeachingLoadQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import {
  DeputateDetailsOverviewData,
  DeputatesDetailTableData,
  DeputateDetailsTeacherOverview,
} from '../../../../components/Deputate/Overview/DeputateDetailsTeacherOverview';
import { groupByMapArray } from '../../../../utils/arrayFunc';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';

export const EvaluatePlannedTeachersDetail: FC = () => {
  const { pimAuthClaims } = useAuthClaims();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const teacherUuid = useParams().teacherUuid;

  const [{ data: deputateData }] = useListPlannedTeacherTeachingLoadQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: currentSchoolYear?.uuid ?? '',
      teacherUuid,
    },
  });

  const atlContext = useMemorizedCacheTag('ADDITIONAL_TEACHINGLOAD');
  const [{ data: additionalTeachingLoadQueryResult }] = useAdditionalTeachingLoadsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolyear: currentSchoolYear?.uuid ?? '',
      teacherUuid,
    },
    context: atlContext,
  });

  const memoizedData: DeputateDetailsOverviewData = useMemo(() => {
    const deputatData: Array<DeputatesDetailTableData> = deputateData?.plannedTeachingLoadByTeacher ?? [];
    const groupedData = groupByMapArray<string, DeputatesDetailTableData>(
      deputatData,
      (lesson) => lesson.subjectContainerDisplayName ?? '',
    );

    const subjectLessons = groupedData.get('');
    groupedData.delete('');
    const subjectContainerLessons = groupedData;

    const additional =
      additionalTeachingLoadQueryResult?.additionalTeachingLoads.map(
        (additional) =>
          ({
            comment: additional.comment,
            teacherUuid: additional.teacher.uuid ?? '',
            teacherDisplayName: additional.teacherListName ?? '',
            subjectUuid: additional.subject?.uuid ?? '',
            subjectDisplayName: additional.subject?.name ?? '',
            partialClasses: false,
            lessonClassesDisplayName: additional.class?.name ?? '',
            countClasses: additional.class ? 1 : 0,
            countTeacher: 0,
            studentCards: 0,
            teacherCards: 0,
            studentHours: 0,
            teacherHours: 0,
            teachingBlockWeeks: 0,
            teachingBlocks: 0,
            factor: 0,
            deputat: additional.hours,
          }) as DeputatesDetailTableData,
      ) ?? [];

    const sumSubjectLessons = subjectLessons?.map((d) => d.deputat ?? 0).reduce((acc, cur) => acc + cur) ?? 0;
    const sumSubjectContainerLessons = new Map<string, number>();
    const sumAdditional = additional?.map((d) => d.deputat ?? 0).reduce((acc, cur) => acc + cur, 0) ?? 0;

    let sumTotal = sumSubjectLessons + sumAdditional;

    subjectContainerLessons.forEach((value, key) => {
      sumSubjectContainerLessons.set(key, value.map((d) => d.deputat ?? 0).reduce((acc, cur) => acc + cur, 0) ?? 0);
      sumTotal += sumSubjectContainerLessons.get(key) ?? 0;
    });

    return {
      subjectLessons,
      subjectContainerLessons,
      additional,
      sumSubjectLessons,
      sumSubjectContainerLessons,
      sumAdditional,
      sumTotal,
    };
  }, [additionalTeachingLoadQueryResult, deputateData]);

  return <DeputateDetailsTeacherOverview deputateData={memoizedData} />;
};
