import { Chip, Tooltip } from '@bp/ui-components';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './Page.module.scss';
import { ColorType } from '../../types/global';

type PageHeaderProps = {
  headline?: string | null;
  subHeadline?: string | number | null;
  colorHint?: ColorType;
  size?: 's' | 'm' | 'l';
  noMargin?: boolean;
  isModal?: boolean;
  isForm?: boolean;
  bigActionsGap?: boolean;
  showSubHeadlineAsChip?: boolean;
  className?: string | undefined;
};

export const PageHeader: FC<PageHeaderProps> = ({
  headline,
  subHeadline,
  colorHint,
  size = 'm',
  noMargin = false,
  isModal = false,
  isForm = false,
  bigActionsGap = false,
  showSubHeadlineAsChip = false,
  className,
}) => {
  const classes = classNames(
    styles['page-header'],
    styles[`size-${size}`],
    {
      [styles['no-margin']]: noMargin,
      'bp__form-header': isForm,
      'bp__modal-header': isModal,
      [styles['big-gap']]: bigActionsGap,
      [styles['with-chip']]: showSubHeadlineAsChip,
    },
    className,
  );

  return (
    <div className={classes}>
      <div className={styles['headline-wrapper']}>
        {headline && (
          <div className={styles.headline}>
            {headline}
            {colorHint && (
              <Tooltip content={colorHint.colorLabel}>
                <span
                  className={styles.color}
                  style={{
                    backgroundColor: colorHint.color ?? '#FFF',
                  }}
                />
              </Tooltip>
            )}
          </div>
        )}
        {(subHeadline || subHeadline === 0) && (
          <>
            {showSubHeadlineAsChip ? (
              <Chip className={styles.subline} value={subHeadline.toString()} maxWidth='100%' />
            ) : (
              <div className={styles.subline}>{subHeadline.toString()}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
