import styles from './GridWeek.module.scss';
import dayjs from 'dayjs';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import { FC, Ref, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { GridDateType, RowItem } from '../types';
import { GridCard } from '../GridCard/GridCard';
import classNames from 'classnames';
import { VirtualGridCard } from '../VirtualGridCard/VirtualGridCard';

type GridWeekProps = {
  row: RowItem;
  weekNumber: number;
  days: GridDateType[];
  weekRef?: Ref<HTMLDivElement>;
};

export const GridWeek: FC<GridWeekProps> = observer(({ row, weekNumber, days, weekRef }) => {
  const {
    pickedCard,
    placeCard,
    placedCards,
    virtualCards,
    isUuidPresentInTimetableCard,
    pickCard,
    gridContext,
    currentVersion,
    getParentCard,
  } = useTeachingBlockStore();
  // const { teachingBlockVersionUuid } = useParams();
  // const availabilityStore = availabilityStoreHandler.get(teachingBlockVersionUuid ?? '');

  const [available] = useState(true);
  // const [warning, setWarning] = useState(false);

  const virtual = virtualCards.filter((card) => {
    const parent = getParentCard(card.parentUuid);
    if (!parent) throw new Error('parent is undefined');
    return (
      isUuidPresentInTimetableCard(parent, row.value) &&
      dayjs(card.startDateString).utc().week() === weekNumber &&
      pickedCard?.card.uuid !== card.parentUuid
    );
  });

  const placed = placedCards.filter((card) => {
    return (
      isUuidPresentInTimetableCard(card, row.value) &&
      dayjs(card.startDate).utc().week() === weekNumber &&
      pickedCard?.card.uuid !== card.uuid
    );
  });

  // TODO: refactor
  // if (pickedCard && availabilityStore) {
  //   const startDay = dayjs(days[0].dateString).startOf('day').utc();
  //   const endDay = dayjs(days[6].dateString).startOf('day').utc();
  //   const allDays = endDay.diff(startDay, 'days');
  //   for (let i = 0; i <= allDays; i++) {
  //     const availability = availabilityStore.checkCardAvailability(
  //       startDay.add(i, 'days').toDate(),
  //       pickedCard.availabilityCard,
  //       'classes',
  //     );
  //     if (availability.status === 'blocked' || availability.status === 'placed') {
  //       if (isUuidPresentInTimetableCard(pickedCard.card, row.value)) {
  //         if (available) {
  //           setAvailable(false);
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }

  return (
    <div ref={weekRef} className={classNames(styles['grid-week'], { [styles.blocked]: !available })}>
      {days.map((day, dayIndex) => {
        return (
          <div
            key={weekNumber + '_' + dayIndex}
            style={{ left: `calc(100% / 7 * ${dayIndex})` }}
            className={classNames('droppable', styles.day, { [styles.holiday]: !!day.holidayUuid })}
            onMouseDown={(event) => {
              let firstDroppable = days[0];
              for (let i = 0; i < dayIndex; i++) {
                if (
                  days[i].holidayUuid ||
                  (currentVersion.active && dayjs(days[i].dateString).isSameOrBefore(dayjs().startOf('day')))
                ) {
                  firstDroppable = days[i + 1] ? days[i + 1] : days[0];
                }
              }
              if (!day.holidayUuid && pickedCard) {
                placeCard(pickedCard.card, firstDroppable?.dateString ?? day.dateString, {
                  x: event.pageX,
                  y: event.pageY,
                });
              }
            }}
          >
            {virtual
              .filter((v) => dayjs(v.startDateString).isSame(day.dateString))
              .map((card) => {
                return <VirtualGridCard key={card.uuid + dayIndex} card={card} row={row} />;
              })}
            {placed
              .filter((c) => dayjs(c.startDate).isSame(day.dateString, 'day'))
              .map((card, i) => {
                let duration = (card.durationInDays ?? 7) / 7;
                if (duration < 0) duration = 0;
                const borders = (duration - 1) * 2;

                const gridCard =
                  gridContext === 'classes'
                    ? card.classGridCards.find((c) => c.classUuid === row.value)
                    : gridContext === 'teachers'
                      ? card.teacherGridCards.find((t) => t.teacherUuid === row.value)
                      : gridContext === 'rooms'
                        ? card.roomGridCards.find((r) => r.roomUuid === row.value)
                        : null;

                return (
                  <GridCard
                    key={card.uuid + i}
                    card={card}
                    onCardClick={(event) => pickCard(card, { x: event.pageX, y: event.pageY })}
                    background={gridCard?.cssGradientString ?? ''}
                    textColor={gridCard?.textColor ?? ''}
                    top={gridCard?.cssTop ?? '0%'}
                    width={`calc(${duration * 7 * 100}%  + ${borders}px)`}
                    height={gridCard?.cssHeight ?? '100%'}
                  />
                );
              })}
          </div>
        );
      })}
    </div>
  );
});
