import { HolidayType } from '../components/Holidays/graphql';
import { useEffect, useState } from 'react';
import { useSchoolYearsQuery } from '../types/planung-graphql-client-defs';
import { useAuthClaims } from './useAuthClaims';
import { useMemorizedCacheTag } from './useMemorizedCacheTag';
import { useUserConfigContext } from './useUserConfigContext';

export const useGetHolidays = () => {
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('SCHOOL_YEAR');
  const selectedSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [holidays, setHolidays] = useState<HolidayType[] | null>(null);

  const [{ data }] = useSchoolYearsQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: context,
  });

  useEffect(() => {
    const year = data ? data.schoolYears.find((year) => year.uuid === selectedSchoolYear?.uuid) : null;
    setHolidays(year ? year.holidays : null);
  }, [selectedSchoolYear, data]);

  return { holidays, schoolYear: selectedSchoolYear };
};
