import {
  ClassesIllustration,
  LazyLoader,
  RoomIllustration,
  SchoolYearIllustration,
  SubjectIllustration,
  TeacherIllustration,
} from '@bp/ui-components';
import { Page } from '../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import IconMenu from '../../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../../components/IconMenuItem/IconMenuItem';
import { Suspense } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const EvaluatePage = ({ baseSlug }: { baseSlug: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const currentBaseSlug = '/' + baseSlug + '/' + t('routes.evaluate.slug') + '/';

  return (
    <Page title={`${t('timetable.title.plural')} ${t('deputate.evaluate.title').toLowerCase()}`}>
      <IconMenu className='mb-6'>
        <IconMenuItem
          label={t('routes.evaluate.byClass.title')}
          icon={<ClassesIllustration />}
          bubbleType={3}
          onClick={() => navigate(currentBaseSlug + t('routes.evaluate.byClass.slug'))}
          active={location.pathname.includes(t('routes.evaluate.byClass.slug'))}
        />
        <IconMenuItem
          label={t('routes.evaluate.byTeacher.title')}
          icon={<TeacherIllustration />}
          bubbleType={2}
          onClick={() => navigate(currentBaseSlug + t('routes.evaluate.byTeacher.slug'))}
          active={location.pathname.includes(t('routes.evaluate.byTeacher.slug'))}
        />
        <IconMenuItem
          label={t('routes.evaluate.byRooms.title')}
          icon={<RoomIllustration />}
          bubbleType={4}
          onClick={() => navigate(currentBaseSlug + t('routes.evaluate.byRooms.slug'))}
          active={location.pathname.includes(t('routes.evaluate.byRooms.slug'))}
        />
        <IconMenuItem
          label={t('routes.evaluate.bySubjects.title')}
          icon={<SubjectIllustration />}
          bubbleType={1}
          onClick={() => navigate(currentBaseSlug + 'planned-subjects')}
          active={location.pathname.includes('planned-subjects')}
        />

        <IconMenuItem
          label={t('common.other')}
          icon={<SchoolYearIllustration />}
          bubbleType={18}
          onClick={() => navigate(currentBaseSlug + 'planned-other')}
          active={location.pathname.includes('planned-other')}
        />
      </IconMenu>
      <Suspense fallback={<LazyLoader transparent size='xxl' forceHeight='50vh' />}>
        <Outlet />
      </Suspense>
    </Page>
  );
};
