import { TableColumns } from '@bp/ui-components';
import { t } from 'i18next';
import { TeachingBlockVersionTableType } from '../graphql/types';

export function createTeachingBlockVersionTableColumns(name: string): TableColumns<TeachingBlockVersionTableType>[] {
  return [
    {
      header: name,
      accessorKey: 'name',
      id: 'name',
      size: 150,
    },
    {
      header: t('common.shortName'),
      accessorKey: 'shortName',
      id: 'shortName',
      size: 90,
    },
    {
      header: t('common.count'),
      accessorKey: 'count',
      id: 'count',
      cell: () => {
        return 0;
      },
      size: 50,
    },
    {
      header: t('common.color'),
      accessorKey: 'color',
      id: 'color',
      type: 'color',
      size: 60,
    },
  ];
}
