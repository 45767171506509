import {
  AddIcon,
  AlertDialog,
  ArrowDownIcon,
  ArrowUpIcon,
  Button,
  Checkbox,
  DatePicker,
  DeleteIcon,
  Grid,
  GridRow,
  Input,
} from '@bp/ui-components';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BillingMethods } from '../../../types/planung-graphql-client-defs';
import { PersonContractType, PersonDataType } from '../graphql/types';
import styles from './ContractsForm.module.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';

export const ContractsForm = () => {
  const formik = useFormikContext<PersonDataType>();
  const { values, handleChange, handleBlur, errors, touched, setFieldValue, setFieldTouched } = formik;
  const [contractStates, setContractStates] = useState<boolean[]>(values.contracts.map(() => false));
  const { t } = useTranslation();

  const addContractState = () => {
    const states = [...contractStates];
    states.unshift(false);
    setContractStates(states);
  };

  const removeContractState = (index: number) => {
    const states = [...contractStates];
    states.splice(index, 1);
    setContractStates(states);
  };

  const contractPlaceholder = (contracts: PersonContractType[]) => `${t('contracts.contract')} ${contracts.length + 1}`;

  const stylizedDate = (dateString = undefined) => {
    const date = dateString ? new Date(dateString) : new Date();
    return date.toLocaleString(undefined, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const billingMethods = useMemo(
    () => [BillingMethods.GivenHours, BillingMethods.ContractIsMax, BillingMethods.ContractIsMin, BillingMethods.Exact],
    [],
  );

  function getBillingMethodLabel(method: BillingMethods) {
    let label = '';
    switch (method) {
      case BillingMethods.ContractIsMax:
        label = t('contracts.billing.contractIsMax');
        break;
      case BillingMethods.ContractIsMin:
        label = t('contracts.billing.contractIsMin');
        break;
      case BillingMethods.Exact:
        label = t('contracts.billing.exact');
        break;
      case BillingMethods.GivenHours:
        label = t('contracts.billing.givenHours');
        break;
      default:
        break;
    }
    return label;
  }

  const getValidFromDate = async (contracts: PersonContractType[]): Promise<Date> => {
    const lastContract = contracts
      .sort((a, b) => {
        return Date.parse(a.validFrom) - Date.parse(b.validFrom);
      })
      .reverse()[0];
    const index = values.contracts.findIndex((c) => c.uuid === lastContract.uuid);

    const today = dayjs();
    if (lastContract) {
      if (lastContract.validUntil) {
        return dayjs(lastContract.validUntil).add(1, 'day').toDate();
      } else {
        if (dayjs(lastContract.validFrom).isSameOrAfter(today, 'day')) {
          // set end date to validFrom + one day and return this
          const newValidFrom = dayjs(lastContract.validFrom).add(1, 'day');
          await setFieldValue(`contracts.${index}.validUntil`, newValidFrom.toDate());
          return newValidFrom.add(1, 'day').toDate();
        } else {
          // set end date to today
          const newValidFrom = dayjs();
          await setFieldValue(`contracts.${index}.validUntil`, newValidFrom.toDate());
          return newValidFrom.add(1, 'day').toDate();
        }
      }
    }
    return today.toDate();
  };

  return (
    <FieldArray
      name={'contracts'}
      render={(arrayHelpers) => {
        const ContractsErrors = () =>
          typeof errors.contracts === 'string' ? <div className={'error-message'}>{errors.contracts}</div> : <></>;

        return (
          <Grid className={`${styles['contracts']}`} useFormGap>
            <GridRow headline={t('contracts.occupation')}>
              <Button
                hierarchy={'tertiary'}
                icon={<AddIcon />}
                disabled={!!errors.contracts}
                onClick={async () => {
                  const contractsLength = values.contracts.length;
                  if (contractsLength > 0) {
                    for (const index in values.contracts) {
                      await setFieldTouched(`contracts.${index}`, true, true);
                      if (typeof errors.contracts === 'object') return;
                      else await setFieldTouched(`contracts.${index}`, false, false);
                    }
                    if (!values.contracts[contractsLength - 1].validUntil) {
                      await setFieldTouched(`contracts.${contractsLength - 1}`, true, true);
                    }
                  }
                  const newValideFrom = await getValidFromDate(values.contracts);
                  arrayHelpers.insert(0, {
                    name: contractPlaceholder(values.contracts),
                    validFrom: newValideFrom,
                    hoursWeekly: 0,
                    billingMethod: BillingMethods.GivenHours,
                  });
                  addContractState();
                }}
              >
                {t('contracts.add')}
              </Button>
            </GridRow>
            <GridRow>
              <ContractsErrors />
            </GridRow>

            {values.contracts.map((contract, index) => {
              return (
                <div
                  key={contract.uuid}
                  className={`${styles['form-group']} ${contractStates[index] ? styles['collapsed'] : ''}`}
                >
                  {contractStates[index] && (
                    <div className={styles['contract-name-hint']}>
                      <div
                        className={classNames(styles.name, {
                          [styles['has-error']]: getIn(errors, `contracts.${index}.name`),
                        })}
                      >
                        {values.contracts[index].name !== ''
                          ? values.contracts[index].name
                          : contractPlaceholder(values.contracts)}
                      </div>
                      <div
                        className={
                          getIn(errors, `contracts.${index}.validFrom`) ||
                          getIn(errors, `contracts.${index}.validUntil`)
                            ? styles['has-error']
                            : undefined
                        }
                      >
                        {values.contracts[index].validFrom
                          ? stylizedDate(values.contracts[index].validFrom)
                          : stylizedDate()}
                        {values.contracts[index].validUntil
                          ? ` - ${stylizedDate(values.contracts[index].validUntil)}`
                          : ''}
                      </div>
                    </div>
                  )}
                  <div className={styles['form-col-wrapper']}>
                    <div className={styles['form-col-actions']}>
                      <Button
                        hierarchy='tertiary'
                        onClick={async () => {
                          const states = [...contractStates];
                          states[index] = !states[index];
                          setContractStates(states);
                          await setFieldTouched(`contracts.${index}.collapsed`, states[index]);
                        }}
                        type='button'
                        icon={contractStates[index] ? <ArrowUpIcon /> : <ArrowDownIcon />}
                      />
                      <AlertDialog
                        title={t('contracts.deleteConfirm', {
                          name:
                            values.contracts[index].name !== ''
                              ? values.contracts[index].name
                              : contractPlaceholder(values.contracts),
                        })}
                        confirmText={t('common.confirmDelete')}
                        cancelText={t('common.cancelDelete')}
                        onConfirm={() => {
                          arrayHelpers.remove(index);
                          removeContractState(index);
                        }}
                        trigger={<Button hierarchy='tertiary' type='button' icon={<DeleteIcon />}></Button>}
                      />
                    </div>
                    <div className={styles['form-col']}>
                      <div className={styles['form-row']}>
                        <Input
                          label={t('contracts.name')}
                          name={`contracts.${index}.name`}
                          onChange={handleChange}
                          placeholder={contractPlaceholder(values.contracts)}
                          value={values.contracts[index].name}
                          className='full'
                          error={getIn(errors, `contracts.${index}.name`)}
                        />
                      </div>
                      <div className={`form-row`}>
                        <DatePicker
                          showMonthYearDropdown={true}
                          label={t('contracts.validFrom')}
                          name={`contracts.${index}.validFrom`}
                          className={'quarter'}
                          value={
                            values.contracts[index].validFrom
                              ? new Date(values.contracts[index].validFrom ?? '')
                              : undefined
                          }
                          onBlur={async () => {
                            await setFieldTouched(`contracts.${index}.validFrom`, true);
                          }}
                          onChange={async (date) => {
                            await setFieldValue(`contracts.${index}.validFrom`, date, true);
                          }}
                          error={
                            getIn(touched, `contracts.${index}`) && getIn(errors, `contracts.${index}.validFrom`)
                              ? getIn(errors, `contracts.${index}.validFrom`)
                              : undefined
                          }
                        />
                        <DatePicker
                          showMonthYearDropdown={true}
                          className={'quarter'}
                          label={t('contracts.validUntil')}
                          name={`contracts.${index}.validUntil`}
                          error={
                            getIn(touched, `contracts.${index}`) && getIn(errors, `contracts.${index}.validUntil`)
                              ? getIn(errors, `contracts.${index}.validUntil`)
                              : undefined
                          }
                          value={
                            values.contracts[index].validUntil
                              ? new Date(values.contracts[index].validUntil ?? '')
                              : undefined
                          }
                          onBlur={async () => {
                            await setFieldTouched(`contracts.${index}.validUntil`, true);
                          }}
                          onChange={async (date) => {
                            await setFieldValue(`contracts.${index}.validUntil`, date, true);
                            await setFieldTouched(`contracts.${index}.validUntil`, true);
                          }}
                        />
                        <Input
                          name={`contracts.${index}.hoursWeekly`}
                          onChange={handleChange}
                          label={t('contracts.hoursWeekly')}
                          onBlur={handleBlur}
                          value={values.contracts[index].hoursWeekly}
                          type={'number'}
                          className={'quarter'}
                          error={
                            getIn(touched, `contracts.${index}`) && getIn(errors, `contracts.${index}.hoursWeekly`)
                              ? getIn(errors, `contracts.${index}.hoursWeekly`)
                              : undefined
                          }
                        />
                        <Input
                          name={`contracts.${index}.substituteHours`}
                          onChange={handleChange}
                          label={t('contracts.substituteHours')}
                          onBlur={handleBlur}
                          value={values.contracts[index].substituteHours}
                          type={'number'}
                          className={'quarter'}
                          error={
                            getIn(touched, `contracts.${index}`) && getIn(errors, `contracts.${index}.substituteHours`)
                              ? getIn(errors, `contracts.${index}.substituteHours`)
                              : undefined
                          }
                        />
                      </div>
                    </div>
                    <div className={styles['form-col']}>
                      <p className={styles['form-sublabel']}>{t('contracts.billingModalities')}</p>
                      <Checkbox
                        name={`contracts.${index}.freelancer`}
                        label={t(`contracts.freelancer`)}
                        checked={values.contracts[index].freelancer ?? false}
                        onChange={async (event) => {
                          await setFieldValue(`contracts.${index}.freelancer`, event.target.checked, true);
                          await setFieldTouched(`contracts.${index}.freelancer`, true);
                        }}
                        className={'check-box'}
                      />
                      <Checkbox
                        name={`contracts.${index}.limitedToFullTime`}
                        label={t(`contracts.limitedToFullTime`)}
                        checked={values.contracts[index].limitedToFullTime ?? false}
                        onChange={async (event) => {
                          await setFieldValue(`contracts.${index}.limitedToFullTime`, event.target.checked, true);
                          await setFieldTouched(`contracts.${index}.limitedToFullTime`, true);
                        }}
                        className={'check-box'}
                      />
                      <div className={'form-hr my-2'} />
                      {billingMethods.map((billingMethod, key) => {
                        return (
                          <Checkbox
                            key={key}
                            name={`contracts.${index}.${billingMethod}`}
                            label={getBillingMethodLabel(billingMethod)}
                            checked={values.contracts[index].billingMethod === billingMethod}
                            onChange={async (event) => {
                              await setFieldValue(
                                `contracts.${index}.billingMethod`,
                                event.target.checked ? billingMethod : '',
                              );
                              await setFieldTouched(`contracts.${index}.billingMethod`, true);
                            }}
                            className={'check-box'}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </Grid>
        );
      }}
    ></FieldArray>
  );
};
