import {
  TimetableBlockCreateInput,
  TimetableBlockUpdateInput,
  useCreateTimetableBlocksMutation,
  useDeleteTimetableBlocksMutation,
  useTimetableBlocksQuery,
  useUpdateTimetableBlocksMutation,
} from '../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useCallback, useMemo, useReducer, useState } from 'react';
import { loadingReducer, loadingReducerInitialState } from '../../reducer/loadingReducer';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { TimetableBlockType } from './graphql';
import { getDaysDifference, isFirstBeforeSecond, isFirstSameOrBeforeSecond } from '../../utils/dateCalculations';
import dayjs, { Dayjs } from 'dayjs';
import { Modal } from '@bp/ui-components';
import { TimetableBlockForm } from './Forms/TimetableBlockForm';

export type ProcessedTimetableBlockType = {
  uuid: string;
  name: string;
  start: Date;
  end: Date;
  days: number;
  empty: boolean;
  currentActive: boolean;
};

type ObjectWithArray = {
  [key: string]: string[];
};

export const useTimetableBlock = () => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const context = useMemorizedCacheTag('TIMETABLE_BLOCK');

  const [loading, dispatch] = useReducer(loadingReducer, loadingReducerInitialState);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedBlock, setSelectedBlock] = useState<ProcessedTimetableBlockType | null>(null);

  const openModal = (block: ProcessedTimetableBlockType | null) => {
    setSelectedBlock(block);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBlock(null);
    setModalOpen(false);
  };

  const [, create] = useCreateTimetableBlocksMutation();
  const [, update] = useUpdateTimetableBlocksMutation();
  const [, remove] = useDeleteTimetableBlocksMutation();

  const [{ data }] = useTimetableBlocksQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
      },
    },
    context,
  });

  const timetableBlocks = useMemo(() => {
    return (
      data?.timetableBlocks.sort((a, b) => {
        return new Date(a.start).getTime() - new Date(b.start).getTime();
      }) ?? []
    );
  }, [data?.timetableBlocks]);

  const createTimetableBlocks = (
    schoolYearStart: Dayjs,
    schoolYearEnd: Dayjs,
    timetableBlocks: TimetableBlockType[],
  ): ProcessedTimetableBlockType[] => {
    const emptyBlocks: ProcessedTimetableBlockType[] = [];
    let currentStart = schoolYearStart;

    while (isFirstSameOrBeforeSecond(currentStart, schoolYearEnd)) {
      const overlappingBlock = timetableBlocks.find(
        // eslint-disable-next-line no-loop-func
        (block) =>
          isFirstSameOrBeforeSecond(dayjs(block.start), currentStart) &&
          isFirstBeforeSecond(currentStart, dayjs(block.end)),
      );

      if (!overlappingBlock) {
        let emptyBlockEnd = currentStart;

        while (
          isFirstSameOrBeforeSecond(emptyBlockEnd, schoolYearEnd) &&
          !timetableBlocks.some(
            // eslint-disable-next-line no-loop-func
            (block) =>
              isFirstSameOrBeforeSecond(dayjs(block.start), emptyBlockEnd) &&
              isFirstBeforeSecond(emptyBlockEnd, dayjs(block.end)),
          )
        ) {
          emptyBlockEnd = emptyBlockEnd.add(1, 'day');
        }

        emptyBlocks.push({
          uuid: '',
          name: '',
          start: currentStart.toDate(),
          end: emptyBlockEnd.subtract(1, 'day').toDate(),
          days: getDaysDifference(currentStart, emptyBlockEnd.subtract(1, 'day'), false),
          empty: true,
          currentActive:
            isFirstSameOrBeforeSecond(currentStart.toDate(), new Date()) &&
            isFirstSameOrBeforeSecond(new Date(), emptyBlockEnd.toDate()),
        });

        currentStart = emptyBlockEnd;
      } else {
        currentStart = dayjs(overlappingBlock.end).add(1, 'day');
      }
    }

    const processedTimetableBlocks = timetableBlocks.map((block) => ({
      uuid: block.uuid,
      name: block.name,
      start: new Date(block.start),
      end: new Date(block.end),
      days: getDaysDifference(block.start, block.end, false),
      empty: false,
      currentActive:
        isFirstSameOrBeforeSecond(block.start, new Date()) && isFirstSameOrBeforeSecond(new Date(), block.end),
    }));
    return [...processedTimetableBlocks, ...emptyBlocks.map((eb) => ({ ...eb, uuid: eb.start.toString() }))].sort(
      (a, b) => a.start.getTime() - b.start.getTime(),
    );
  };

  const schoolYearStart = schoolYear ? dayjs(schoolYear.start) : dayjs('2023-01-01');
  const schoolYearEnd = schoolYear ? dayjs(schoolYear.end) : dayjs('2023-12-31');

  const processedBlocks = createTimetableBlocks(schoolYearStart, schoolYearEnd, timetableBlocks);

  const getFormData = (uuid: string): TimetableBlockType => {
    const block = timetableBlocks.find((b) => b.uuid === uuid);
    const currentProcessedBlock = processedBlocks.find((b) => b.uuid === uuid);
    const prevBlock = currentProcessedBlock
      ? processedBlocks[processedBlocks.indexOf(currentProcessedBlock) - 1]
      : null;
    const nextBlock = currentProcessedBlock
      ? processedBlocks[processedBlocks.indexOf(currentProcessedBlock) + 1]
      : null;
    return {
      uuid: uuid,
      name: block?.name ?? '',
      end: block?.end
        ? block?.end
        : nextBlock
          ? dayjs(nextBlock?.start).subtract(1, 'day').toDate()
          : dayjs(schoolYearEnd).toDate(),
      start: block?.start
        ? block.start
        : prevBlock
          ? dayjs(prevBlock?.end).add(1, 'day').toDate()
          : dayjs(schoolYearStart).toDate(),
      schoolYear: {
        __typename: undefined,
        uuid: block?.schoolYear.uuid ?? '',
        start: block?.schoolYear.start ?? undefined,
        end: block?.schoolYear.end ?? undefined,
      },
      timetables: block?.timetables ?? [],
    };
  };

  const getLatestStart = (uuid: string) => {
    const currentBlock = processedBlocks.find((b) => b.uuid === uuid);
    const prevBlock = currentBlock ? processedBlocks[processedBlocks.indexOf(currentBlock) - 1] : null;
    return prevBlock ? dayjs(prevBlock?.end).toDate() : schoolYearStart.toDate();
  };

  const getEarliestEnd = (uuid: string) => {
    const currentBlock = processedBlocks.find((b) => b.uuid === uuid);
    const nextBlockNotEmptyBlock = currentBlock
      ? processedBlocks.find((b) => b.start > currentBlock.start && !b.empty)
      : null;
    return nextBlockNotEmptyBlock ? dayjs(nextBlockNotEmptyBlock?.start).toDate() : schoolYearEnd.toDate();
  };

  const createTimetableBlock = async (values: TimetableBlockType) => {
    const createInput: TimetableBlockCreateInput = {
      start: dayjs(values.start).utc(true),
      end: dayjs(values.end).utc(true),
      organization: { connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } } },
      schoolYear: { connect: { where: { node: { uuid: schoolYear?.uuid } } } },
      timetables: {
        connect: [{ where: { node: { uuid_IN: values.timetables.map((t) => t.uuid) } } }],
      },
    };
    return await create({ input: createInput }, context);
  };

  const updateTimetableBlock = async (values: TimetableBlockType, uuid: string) => {
    dispatch({ type: 'SET_LOADING', uuids: [uuid] });

    let result;

    if (values.timetables.length === 0) {
      result = await deleteTimetableBlock(uuid);
    } else {
      const updateInput: TimetableBlockUpdateInput = {
        start: dayjs(values.start).utc(true),
        end: dayjs(values.end).utc(true),
        organization: { connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } } },
        schoolYear: { connect: { where: { node: { uuid: schoolYear?.uuid } } } },
        timetables: [
          {
            disconnect: [{}],
            connect: [{ where: { node: { uuid_IN: values.timetables.map((t) => t.uuid) } } }],
          },
        ],
      };
      result = await update({ update: updateInput, uuid: uuid }, context);
    }
    dispatch({ type: 'REMOVE_LOADING', uuids: [uuid] });
    return result;
  };

  const deleteTimetableBlock = async (uuid: string) => {
    dispatch({ type: 'SET_LOADING', uuids: [uuid] });
    const res = await remove({ where: { uuid: uuid } }, context);
    dispatch({ type: 'REMOVE_LOADING', uuids: [uuid] });
    return res;
  };

  const TimetableBlockModal = () => (
    <Modal
      title={selectedBlock ? t('timetableBlock.editBlock') : t('timetableBlock.addBlock')}
      isOpen={modalOpen}
      onRequestClose={() => {
        setModalOpen(false);
      }}
    >
      {selectedBlock && (
        <TimetableBlockForm
          block={selectedBlock}
          canEditStart={selectedBlock?.empty === true || !isFirstBeforeSecond(selectedBlock?.start, new Date())}
          canEditEnd={selectedBlock?.empty === true || !isFirstBeforeSecond(selectedBlock?.end, new Date())}
          canEditTimetables={selectedBlock?.empty === true || !isFirstBeforeSecond(selectedBlock?.start, new Date())}
          onClose={() => {
            setModalOpen(false);
          }}
        />
      )}
    </Modal>
  );

  const findOverlappingTimetables = useCallback((array1: ObjectWithArray[], array2: ObjectWithArray[]): string[] => {
    const result: string[] = [];

    array1.forEach((obj1) => {
      array2.forEach((obj2) => {
        for (const key1 in obj1) {
          for (const key2 in obj2) {
            const overlap = obj1[key1].filter((value) => obj2[key2].includes(value));
            if (overlap.length > 0) {
              result.push(key2);
            }
          }
        }
      });
    });

    return result;
  }, []);

  return {
    createTimetableBlock,
    updateTimetableBlock,
    deleteTimetableBlock,
    getFormData,
    findOverlappingTimetables,
    blocks: timetableBlocks,
    processedBlocks,
    isLoading: loading.some((ls) => timetableBlocks.find((block) => ls.uuid === block.uuid)),
    openModal,
    closeModal,
    TimetableBlockModal,
    selectedBlock,
    getLatestStart,
    getEarliestEnd,
  };
};
