import { t } from 'i18next';
import { RouteObject } from 'react-router-dom';
import { TimetableSetupPage } from './TimetableSetupPage';
import { SetupSubjectsAndSubjectContainer } from './SubjectsAndSubjectContainer/SetupSubjectsAndSubjectContainer';
import { SetupPersons } from './Persons/SetupPersons';
import { SetupClassesAndDivisions } from './ClassesAndDivisions/SetupClassesAndDivisions';
import { SetupRoomsAndBuildings } from './RoomsAndBuildings/SetupRoomsAndBuildings';
import { SetupTimeGrids } from './TimeGrids/SetupTimeGrids';

export const timetableSetupRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <TimetableSetupPage />,
    },
    {
      path: t('routes.subjects.slug'),
      element: <SetupSubjectsAndSubjectContainer />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.subjects.slug')}`,
          title: t('routes.subjects.title'),
        }),
      },
    },
    {
      path: t('routes.teacher.slug'),
      element: <SetupPersons />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.teacher.slug')}`,
          title: t('routes.teacher.title'),
        }),
      },
    },
    {
      path: t('routes.classes.slug'),
      element: <SetupClassesAndDivisions />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.classes.slug')}`,
          title: t('routes.classes.title'),
        }),
      },
    },
    {
      path: t('routes.rooms.slug'),
      element: <SetupRoomsAndBuildings />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.rooms.slug')}`,
          title: t('routes.rooms.title'),
        }),
      },
    },
    {
      path: t('routes.timegrid.slug'),
      element: <SetupTimeGrids />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.timegrid.slug')}`,
          title: t('routes.timegrid.title'),
        }),
      },
    },
  ];
};
