import { observer } from 'mobx-react-lite';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { SortDirection, useSubjectsListQuery } from '../../../types/planung-graphql-client-defs';
import { useContext, useMemo } from 'react';
import { ArrowRightIcon, Button, Table, TableColumns, Tooltip } from '@bp/ui-components';
import { useCurriculum } from '../hooks/useCurriculum';
import { useNavigate } from 'react-router-dom';
import { CurriculumContext } from '../../../pages/Deputate/Setup/Curricula/CurriculaPage';

type CurriculumSubjectTableType = {
  name: string;
  subjectUuid: string;
  subjectHours: string;
  studentHours: string;
  deputat: string;
};

export const CurriculumSubjectsTable = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const navigate = useNavigate();
  const curriculumContext = useContext(CurriculumContext);

  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('curriculumSubject-table');
  const { sorting, saveSorting } = useColumnsSort('curriculumSubject-table');

  const { tableData } = useCurriculum({
    versionUuid: curriculumContext.versionUuid ?? '',
  });

  const subjectContext = useMemorizedCacheTag('SUBJECTS');
  const [{ data: subjectsQueryResult }] = useSubjectsListQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
      options: {
        sort: [{ name: SortDirection.Asc }],
      },
    },
    context: subjectContext,
  });

  const memoizedData = useMemo((): CurriculumSubjectTableType[] => {
    return (
      subjectsQueryResult?.subjects.map((subject) => {
        const itemData = tableData.filter((c) => c.subjectUuid === subject.uuid);
        return {
          subjectUuid: subject.uuid,
          name: subject.name ?? '',
          subjectHours: itemData.reduce((val, cur) => val + (cur.curriculumInfo?.studentCards ?? 0.0), 0.0).toFixed(2),
          studentHours: itemData.reduce((val, cur) => val + (cur.curriculumInfo?.studentHours ?? 0.0), 0.0).toFixed(2),
          deputat: itemData.reduce((val, cur) => val + (cur.curriculumInfo?.deputat ?? 0.0), 0.0).toFixed(2),
        };
      }) ?? []
    );
  }, [subjectsQueryResult, tableData]);

  function tableColumns(): TableColumns<CurriculumSubjectTableType>[] {
    return [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        size: 400,
        cell: (cell) => {
          return (
            <Tooltip triggerStyle={{ display: 'inline' }} content={cell.row.original.name}>
              {cell.row.original.name}
            </Tooltip>
          );
        },
      },

      {
        header: t('subjectHours.subjectHour_other'),
        accessorKey: 'subjectHours',
        id: 'subjectHours',
        size: 90,
        alignment: 'right',
      },
      {
        header: t('common.studentHours'),
        accessorKey: 'studentHours',
        id: 'studentHours',
        size: 90,
        alignment: 'right',
      },
      {
        header: t('deputate.titleSingluar'),
        accessorKey: 'deputat',
        id: 'deputat',
        size: 90,
        alignment: 'right',
      },
    ];
  }

  const columns = useMemo(tableColumns, []);

  function handleRowClick(subjectUuid: string) {
    navigate(
      '/' +
        t('routes.deputate.slug') +
        '/' +
        t('routes.setup.slug') +
        '/' +
        t('routes.curriculum.slug') +
        '/' +
        curriculumContext.versionUuid +
        '/' +
        t('routes.lessons.plan.subjects.slug') +
        '/' +
        subjectUuid,
    );
  }

  return (
    <Table<CurriculumSubjectTableType>
      showBorderRadius
      showRowHover
      showShadow
      canScroll
      minHeight={600}
      columns={columns}
      data={memoizedData}
      sorting={sorting}
      onSortingChange={saveSorting}
      isOnWhite={false}
      showVisibility
      showActionBar
      actionBarSettings={{
        showPrintButton: true,
      }}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={saveColumnVisibility}
      printerSettings={{
        headline: pimAuthClaims.getProfile()?.organization.name,
        subline: `${t('curriculum.title.plural')} - ${t('subject.title.plural')} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
        filename: `${t('curriculum.title.plural')}_${currentSchoolYear?.shortName}`,
      }}
      showSelect={false}
      onRowClick={(e, row) => {
        handleRowClick(row.original.subjectUuid);
      }}
      lastCol={(row) => {
        return (
          <Button
            hierarchy='ghost'
            onClick={() => {
              handleRowClick(row.original.subjectUuid);
            }}
            icon={<ArrowRightIcon className='small' />}
          />
        );
      }}
    />
  );
});
