import { RouteObject } from 'react-router-dom';
import { SubstitutionsSetupPage } from './SubstituionsSetupPage';
import { t } from 'i18next';
import { SetupSchoolEvent } from './SchoolEvent/SetupSchoolEvent';

export const substitutionSetupRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <SubstitutionsSetupPage />,
    },
    {
      path: t('routes.schoolEvents.slug'),
      element: <SetupSchoolEvent />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.schoolEvents.slug')}`,
          title: t('routes.schoolEvents.title'),
        }),
      },
    },
  ];
};
