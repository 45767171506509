import { UsedInCurriculumQueryDataType, useIsUsedInCurriculum } from './useIsUsedInCurriculum';
import { UsedInLessonsQueryDataType, useIsUsedInLesson } from './useIsUsedInLesson';

type IsUsedInCurriculumOrLessonResult = {
  isUsed: boolean;
  lessons?: UsedInLessonsQueryDataType[];
  curricula?: UsedInCurriculumQueryDataType[];
};

export const useIsUsedInCurriculumOrLesson = () => {
  const checkCurriculum = useIsUsedInCurriculum();
  const checkLesson = useIsUsedInLesson();

  return (
    uuid: string,
    entityToCheck?:
      | 'class'
      | 'subject'
      | 'teacher'
      | 'room'
      | 'subjectContainer'
      | 'classDivision'
      | 'classDivisionGroup',
  ): IsUsedInCurriculumOrLessonResult => {
    const lessonResult: IsUsedInCurriculumOrLessonResult = checkLesson(uuid, entityToCheck);
    const curriculumResult: IsUsedInCurriculumOrLessonResult =
      entityToCheck === 'subject' || entityToCheck === 'room' ? checkCurriculum(uuid, entityToCheck) : lessonResult;

    return {
      isUsed: lessonResult.isUsed || curriculumResult.isUsed,
      lessons: lessonResult.lessons,
      curricula: curriculumResult.curricula,
    };
  };
};
