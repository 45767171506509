import { useAuth } from 'react-oidc-context';
import {
  isOmniOrganizationClaim,
  isOrganizationClaim,
  isOtherProfilesClaim,
  isProfileClaim,
  OMNI_ORGANIZATION_CLAIM,
  OmniOrganizationClaim,
  ORGANIZATION_CLAIM,
  OrganizationClaim,
  OTHER_PROFILES_CLAIM,
  OtherProfilesClaim,
  PROFILE_CLAIM,
  ProfileClaim,
  ROLES_CLAIM,
  RolesClaim,
  USER_CLAIM,
  UserClaim,
} from '@bp/pim-auth-constants';

export type PimAuthClaimsType = {
  getOrganizationUuid: () => string;
  getAllowedOrganizationUuids: () => string[];
  getOmniOrganization: () => OmniOrganizationClaim;
  getOrganization: () => OrganizationClaim;
  getProfile: () => ProfileClaim;
  getOtherProfiles: () => OtherProfilesClaim;
  getUser: () => UserClaim;
  getRoles: () => RolesClaim;
};

const useAuthClaims = () => {
  const auth = useAuth();
  if (!auth.user) {
    throw new Error('useAuthClaims must be used within a component wrapped by AuthProvider');
  }

  const pimAuthClaims: PimAuthClaimsType = {
    getOrganizationUuid: (): string => {
      const profile = auth.user?.profile[PROFILE_CLAIM] as ProfileClaim;
      if (!profile || !profile.organization) {
        throw new Error('Invalid user profile or missing organization');
      }
      return profile.organization.uuid;
    },
    getAllowedOrganizationUuids: (): string[] => {
      const organization = auth.user?.profile[ORGANIZATION_CLAIM];
      if (!organization || !isOrganizationClaim(organization)) {
        throw new Error('Invalid organization claim');
      }
      return [organization.uuid, ...organization.collaboratesWith.map((o) => o.uuid)];
    },
    getOmniOrganization: () => {
      const omniOrganization = auth.user?.profile[OMNI_ORGANIZATION_CLAIM];
      if (!omniOrganization || !isOmniOrganizationClaim(omniOrganization)) {
        throw new Error('Invalid or missing omni-organization');
      }
      return omniOrganization;
    },
    getOrganization: (): OrganizationClaim => {
      const organization = auth.user?.profile[ORGANIZATION_CLAIM];
      if (!organization || !isOrganizationClaim(organization)) {
        throw new Error('Invalid or missing organization');
      }
      return organization;
    },
    getProfile: (): ProfileClaim => {
      const profile = auth.user?.profile[PROFILE_CLAIM];
      if (!profile || !isProfileClaim(profile)) {
        throw new Error('Invalid or missing profile');
      }
      return profile;
    },
    getOtherProfiles: (): OtherProfilesClaim => {
      const otherProfiles = auth.user?.profile[OTHER_PROFILES_CLAIM];
      if (!otherProfiles || !isOtherProfilesClaim(otherProfiles)) {
        return [];
      }
      return otherProfiles;
    },
    getUser: (): UserClaim => {
      if (!auth || !auth.user) {
        throw new Error('No Auth User found');
      }
      return auth.user?.profile[USER_CLAIM] as UserClaim;
    },
    getRoles: (): RolesClaim => {
      const roles = auth.user?.profile[ROLES_CLAIM] as RolesClaim;
      if (!roles) {
        throw new Error('User roles information not available');
      }
      return roles;
    },
  };
  return { pimAuthClaims: pimAuthClaims };
};

export { useAuthClaims };
