import { useTimetableStore } from '../TimetableProvider';
import React, { ReactNode } from 'react';
import { getWarningTypeTranslation } from '../../../utils/getWarningTypeTranslation';
import styles from './CardInfo.module.scss';
import { ExclamationmarkIcon, Tooltip } from '@bp/ui-components';

export const CardInfoConflict = () => {
  const store = useTimetableStore();

  const selectedCard = store.pinnedCard || store.hoveredCard ? store.getCardToShowOnInfo() : null;

  const cardConflicts = selectedCard ? store.cardConflicts.get(selectedCard.uuid) : null;

  const conflicts: ReactNode[] = [];
  const maybe: ReactNode[] = [];

  cardConflicts?.forEach((conflict, key) => {
    const placedWithoutCurrent = Array.from(conflict.placed).filter((uuid) => uuid !== selectedCard?.uuid);

    if (conflict.type === 'class' && conflict.maybe) {
      maybe.push(
        <>
          {getWarningTypeTranslation('classMaybe')} ({store.getNameForUuid(key, 'class')})
        </>,
      );
    }
    if (conflict.type === 'teacher' && conflict.maybe) {
      maybe.push(
        <>
          {getWarningTypeTranslation('teacherMaybe')} ({store.getNameForUuid(key, 'teacher')})
        </>,
      );
    }
    if (conflict.type === 'room' && conflict.maybe) {
      maybe.push(
        <>
          {getWarningTypeTranslation('roomMaybe')} ({store.getNameForUuid(key, 'room')})
        </>,
      );
    }
    if (conflict.type === 'subject' && conflict.maybe) {
      maybe.push(
        <>
          {getWarningTypeTranslation('subjectMaybe')} ({store.getNameForUuid(key, 'subject')})
        </>,
      );
    }
    if (conflict.type === 'subject' && conflict.blocked) {
      conflicts.push(<>{getWarningTypeTranslation('subjectNotAvailable')}</>);
    }
    if (conflict.type === 'class' && conflict.blocked) {
      conflicts.push(
        <>
          {getWarningTypeTranslation('classNotAvailable')} ({store.getNameForUuid(key, 'class')})
        </>,
      );
    }
    if (conflict.type === 'class' && !conflict.blocked && !conflict.maybe && placedWithoutCurrent.length > 0) {
      conflicts.push(
        <>
          {getWarningTypeTranslation('overlappingClass')} ({store.getNameForUuid(key, 'class')})
        </>,
      );
    }
    if (conflict.type === 'teacher' && conflict.blocked) {
      conflicts.push(
        <>
          {getWarningTypeTranslation('teacherNotAvailable')} ({store.getNameForUuid(key, 'teacher')})
        </>,
      );
    }
    if (conflict.type === 'teacher' && !conflict.blocked && !conflict.maybe && placedWithoutCurrent.length > 0) {
      conflicts.push(
        <>
          {getWarningTypeTranslation('overlappingTeachers')} ({store.getNameForUuid(key, 'teacher')})
        </>,
      );
    }
    if (conflict.type === 'room' && conflict.blocked) {
      conflicts.push(
        <>
          {getWarningTypeTranslation('roomNotAvailable')} ({store.getNameForUuid(key, 'room')})
        </>,
      );
    }
    if (conflict.type === 'room' && !conflict.blocked && !conflict.maybe && placedWithoutCurrent.length > 0) {
      conflicts.push(
        <>
          {getWarningTypeTranslation('overlappingRooms')} ({store.getNameForUuid(key, 'room')})
        </>,
      );
    }
    if (conflict.type === 'divisionGroup' && !conflict.blocked && !conflict.maybe && placedWithoutCurrent.length > 0) {
      conflicts.push(
        <>
          {getWarningTypeTranslation('notMatchingDivision')} ({store.getNameForUuid(key, 'divisionGroup')})
        </>,
      );
    }
  });

  return (
    <>
      {selectedCard && conflicts.length > 0 && (
        <Tooltip
          triggerClass={styles.warning}
          content={conflicts.map((c, i) => {
            return <div key={i}>{c}</div>;
          })}
        >
          <ExclamationmarkIcon />
        </Tooltip>
      )}
    </>
  );
};
