import { ColorOption, GroupedColorOption } from '@bp/ui-components';
import colors from '../assets/colors/Colors.json';
import { TimetableConfigOwner } from '../types/planung-graphql-client-defs';
import i18next from 'i18next';

export type UsedColors = {
  color?: string | null | undefined;
  owner?: Partial<TimetableConfigOwner>;
};

type ColorPickerOptionsType = {
  htmlColorsInUse?: Array<UsedColors | null>;
  currentColor?: string;
  currentColorUsedBy?: string;
  forTypename: 'Class' | 'Person' | 'Subject' | 'SubjectContainer' | 'Label';
};

type ColorPickerOptionsReturnType = {
  groupedColorOptions: GroupedColorOption[];
  currentColor?: ColorOption;
};

interface ColorOptionWithType extends ColorOption {
  type?: 'Class' | 'Person' | 'Subject' | 'SubjectContainer';
}

export function colorPickerOptions({
  htmlColorsInUse,
  currentColor,
  currentColorUsedBy,
  forTypename,
}: ColorPickerOptionsType): ColorPickerOptionsReturnType {
  const allColors: ColorOptionWithType[] = [...colors];
  const usedColors = allColors
    .map((color) => {
      const usedColor = htmlColorsInUse?.find((colorInUse) => {
        return colorInUse?.color === color.html;
      });
      if (usedColor && usedColor.owner?.__typename === 'Class') {
        color.usedBy = [usedColor.owner.name ?? ''];
        color.type = 'Class';
      }
      if (usedColor && usedColor.owner?.__typename === 'Person') {
        color.usedBy = [usedColor.owner.displayName ?? ''];
        color.type = 'Person';
      }
      if (usedColor && usedColor.owner?.__typename === 'Subject') {
        color.usedBy = [usedColor.owner.name ?? ''];
        color.type = 'Subject';
      }
      if (usedColor && usedColor.owner?.__typename === 'SubjectContainer') {
        color.usedBy = [usedColor.owner.name ?? ''];
        color.type = 'SubjectContainer';
      }
      return color;
    })
    .filter((c) => c.usedBy && c.type === forTypename);

  const current = usedColors.find((uc) => {
    return uc.html === currentColor;
  });

  if (current) {
    current.usedBy = [currentColorUsedBy ?? ''];
  }
  const options: GroupedColorOption[] = [
    {
      label: i18next.t('timetable.freeColors'),
      options: allColors.filter((c) => !c.usedBy),
      isUsed: false,
    },
    {
      label: i18next.t('timetable.usedColors'),
      options: usedColors,
      isUsed: true,
    },
  ];
  return {
    groupedColorOptions: options,
    currentColor: current,
  };
}
