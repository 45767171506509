import { Button, CloseIcon, Modal } from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useTeachingBlockStore } from '../../TeachingBlockContext';
import styles from './CardRoomsModal.module.scss';
import { TeachingBlockCard } from '../../TeachingBlockCard/TeachingBlockCard';
import { CardRoomsForm } from '../../Forms/CardRoomsForm/CardRoomsForm';

export const CardRoomsModal = observer(() => {
  const { t } = useTranslation();

  const { cardRoomsModal, setCardRoomsModal } = useTeachingBlockStore();

  const { isOpen, card } = cardRoomsModal;

  const Header = (
    <div className={styles['custom-header']}>
      <div className={styles.headline}>{t('pinboard.actions.editRooms')}</div>
      {card && <TeachingBlockCard card={card} passive />}
      <Button className={styles.close} icon={<CloseIcon />} hierarchy='tertiary' onClick={onClose} />
    </div>
  );

  function onClose() {
    setCardRoomsModal({ isOpen: false });
  }

  return (
    <Modal
      parent={document.fullscreenElement ? document.getElementById('teaching-block-grid') : null}
      isOpen={isOpen ?? false}
      onRequestClose={onClose}
      customHeader={Header}
      className={styles['card-edit-modal']}
      width='s'
    >
      {card && <CardRoomsForm card={card} onClose={onClose} />}
    </Modal>
  );
});
