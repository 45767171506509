import { t } from 'i18next';

export function getPersonModalTitle(profile?: { listName?: string | null } | null, isOther?: boolean) {
  if (profile) {
    return t('common.namedEdit', { name: profile?.listName });
  }
  if (isOther) {
    return t('persons.addOther');
  }
  return t('persons.addTeacher');
}
