import { useTranslation } from 'react-i18next';
import styles from '../../Constraints.module.scss';
import { Button, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { useFormikContext } from 'formik';
import { ConstraintsFormInitialValuesType } from '../../types';
import { SingleValue } from 'react-select';
import { usePriorityOptions } from '../../../../hooks/usePriorityOptions';
import classNames from 'classnames';

type LunchBreakConditionItemProps = {
  onRemove: () => void;
  options: SelectOptionType[];
};

export const LunchBreakConstraintItem = ({ onRemove, options }: LunchBreakConditionItemProps) => {
  const { t } = useTranslation();

  const { setFieldValue, setFieldTouched, values } = useFormikContext<ConstraintsFormInitialValuesType>();

  const priorityOptions = usePriorityOptions();

  const handleRemove = () => {
    setFieldValue('lunchBreak', null);
    setFieldTouched('lunchBreak', false);
    setFieldTouched('lunchBreakPriority', false);
    setFieldValue('lunchBreakPriority', null);
    onRemove();
  };

  return (
    <>
      <div className={styles['title']}>{t('conditions.lunchBreak.label')}</div>
      <div className={styles['row-wrapper']}>
        <div className='tks__row row-xs-11 mr-5'>
          <div className='tks__col col-xs-6'>
            <Select
              options={options}
              onChange={(value) => {
                const opt = value as SingleValue<SelectOptionType>;
                setFieldTouched('lunchBreak.from', true);
                setFieldValue('lunchBreak.from', opt?.value);
              }}
              value={options.find((o) => o.value === values.lunchBreak?.from)}
              label={t('common.from') as string}
              name={'lunchBreak.from'}
            />
          </div>
          <div className='tks__col col-xs-6'>
            <Select
              options={options}
              value={options.find((o) => o.value === values.lunchBreak?.until)}
              onChange={(value) => {
                const opt = value as SingleValue<SelectOptionType>;
                setFieldTouched('lunchBreak.until', true);
                setFieldValue('lunchBreak.until', opt?.value);
              }}
              label={t('common.until') as string}
              name={'lunchBreak.until'}
            />
          </div>
        </div>
        <div className='tks__col col-xs-1'>
          <Select
            className={classNames(styles['priority-icon-select'], styles[`icon-${values.lunchBreakPriority}`])}
            onChange={(value) => {
              const opt = value as SingleValue<SelectOptionType>;
              setFieldTouched('lunchBreakPriority', true);
              setFieldValue('lunchBreakPriority', opt?.value);
            }}
            label={t('common.priority.full') as string}
            options={priorityOptions}
            value={priorityOptions.find((option) => option.value === values.lunchBreakPriority)}
            name={'lunchBreakPriority'}
            fitMenuToOptions
            showIconOnly
          />
        </div>
        <div className={styles['button-wrapper']}>
          <Button name='remove' hierarchy='secondary' onClick={handleRemove} icon={<DeleteIcon />} />
        </div>
      </div>
    </>
  );
};
