import dayjs from 'dayjs';
import styles from './GridHeader.module.scss';

type GridHeaderMonthProps = {
  index: number;
  count: number;
};

export const GridHeaderMonth = ({ index, count }: GridHeaderMonthProps) => {
  const month = dayjs()
    .utc()
    .startOf('month')
    .month(index - 1);

  return (
    <div className={styles['grid-header-month']} style={{ flexBasis: `calc(100% / ${count})` }}>
      {month.toDate().toLocaleString('default', { month: 'long' })}
    </div>
  );
};
