import { Form, Formik, FormikHelpers } from 'formik';
import { LazyLoader } from '@bp/ui-components';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import FormBlockHeader from '../../Form/FormBlockHeader';
import { CurriculumFormType } from '../types';
import { observer } from 'mobx-react-lite';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { CombinedError } from 'urql';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { useCurriculum } from '../hooks/useCurriculum';
import { epochSchema } from '../../Lessons/Forms/validation/schema';
import { EpochBasicsForm } from '../../Lessons/Forms/Basics/EpochBasicsForm';
import { EpochLessonUnitForm } from '../../Lessons/Forms/LessonUnits/EpochLessonUnitForm';
import { CurriculumGradesForm } from './CurriculumGradesForm';

type CurriculumBlockContainerFormProps = {
  closeForm: () => void;
  defaultInitialValues: Partial<CurriculumFormType>;
  versionUuid?: string | null;
  curriculumUuid?: string | null;
};

export const CurriculumBlockContainerForm = observer(
  ({ closeForm, defaultInitialValues, curriculumUuid, versionUuid }: CurriculumBlockContainerFormProps) => {
    const { t } = useTranslation();

    const { createCurriculum, updateCurriculum, queryData, tableData } = useCurriculum({
      versionUuid: versionUuid ?? '',
      grade: undefined,
    });

    const curriculum = queryData?.curricula.find((curriculum) => {
      return curriculum.uuid === curriculumUuid;
    });

    const status = tableData.find((curriculum) => {
      return curriculum.uuid === curriculumUuid;
    })?.editStatus;

    const initialValues: CurriculumFormType = curriculum
      ? {
          uuid: curriculum.uuid,
          comment: curriculum.comment,
          lessonUnit: curriculum.curriculumUnit.map((cu) => ({
            uuid: cu.uuid,
            duration: cu.duration,
            count: cu.count,
            subjectContainerUuid: cu.subjectContainer?.uuid,
            factor: null,
            blocked: false,
            lessonUnitType: cu.subjectContainer?.uuid ? 'epoch' : 'subjectHour',
          })),
          curriculumGrades: curriculum.curriculumGrades,
          subject: curriculum.subject,
          subTitle: curriculum.subTitle,
        }
      : { ...defaultInitialValues };

    const handleSubmit = async (values: CurriculumFormType, formikHelpers: FormikHelpers<CurriculumFormType>) => {
      let result: { error?: CombinedError | undefined; success?: boolean };
      if (curriculum) {
        result = await updateCurriculum({ curriculumUuid: curriculum.uuid, values });
        if (!result.error) {
          showSuccessUpdateToast();
        }
      } else {
        result = await createCurriculum(values);
        if (!result.error) {
          showSuccessCreateToast();
        }
      }

      if (result.error) {
        showUserErrorToast({ error: result.error });
      }
      formikHelpers.resetForm();
      closeForm();
    };

    return (
      <Suspense fallback={<LazyLoader transparent forceHeight='40vh' />}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={epochSchema}>
          {(formik) => {
            return (
              <Form>
                <div className='form-block'>
                  <div className={'form-group'}>
                    <EpochBasicsForm
                      key={formik.values.subject?.uuid}
                      status={status}
                      filterSubjectContainers={false}
                    />
                  </div>
                </div>

                <div className='form-block'>
                  <FormBlockHeader title={t('classes.title.plural')} />
                  <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                    {/* TODO: filter all grades with Lessons for this container too */}
                    <CurriculumGradesForm enableGroups={false} selectableGrades={formik.values.subject?.grades ?? []} />
                  </Suspense>
                </div>

                <div className='form-block'>
                  <FormBlockHeader title={t('subject.subjectHour')} />
                  <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                    <EpochLessonUnitForm disabled={false} key={formik.values.subject?.uuid} />
                  </Suspense>
                </div>

                <ModalBottomButtons
                  closeButton={{
                    callback: () => {
                      formik.resetForm();
                      closeForm();
                    },
                  }}
                  isLoading={formik.isSubmitting}
                  errors={formik.errors}
                />
              </Form>
            );
          }}
        </Formik>
      </Suspense>
    );
  },
);
