import { useNavigate } from 'react-router-dom';
import { CurrentDeputatesBySubjectTable } from '../../../../components/Deputate/Tables/CurrentDeputatesBySubjectTable';
import { FC } from 'react';

type Props = { slug: string };
export const EvaluateCurrentBySubject: FC<Props> = ({ slug }) => {
  const navigate = useNavigate();
  const handleRowClick = (data: string) => navigate(slug + '/' + data);

  return <CurrentDeputatesBySubjectTable handleRowClick={handleRowClick} />;
};
