import { Button } from '@bp/ui-components';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ModalBottomButtons.module.scss';
import { FormikErrors } from 'formik/dist/types';

interface BpActionButton {
  text?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  disabled?: boolean;
  hierarchy?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-not-on-white' | 'ghost' | undefined;
  callback?: (event: React.MouseEvent<Element>) => void;
  className?: string | undefined;
}

type ModalBottomButtonsProps = {
  closeButton: BpActionButton;
  submitButton?: BpActionButton;
  additionalButtons?: BpActionButton[];
  isModal?: boolean;
  isLoading?: boolean;
  errors?: FormikErrors<unknown>;
  className?: string | undefined;
};

export const ModalBottomButtons: FC<ModalBottomButtonsProps> = ({
  closeButton,
  submitButton,
  additionalButtons,
  isModal = true,
  isLoading = false,
  errors,
  className,
}) => {
  const { t } = useTranslation();

  const classes = classNames(styles['modal-bottom-buttons'], { 'modal-footer': isModal }, className);

  return (
    <div className={classes}>
      {errors && <div className={styles.errors}>{Object.values(errors).join(' | ')}</div>}
      <Button hierarchy='tertiary' onClick={closeButton.callback} onKeyUp={(e) => e.key === 'Escape'}>
        {closeButton.text ?? t('common.cancel')}
      </Button>
      {additionalButtons?.map((button, index) => {
        return (
          <Button
            key={index}
            disabled={button.disabled}
            icon={button.icon}
            iconPosition={button.iconPosition ?? 'left'}
            hierarchy={button.hierarchy ?? 'secondary'}
            onClick={button.callback}
            type='button'
          >
            {button.text}
          </Button>
        );
      })}
      <Button
        isLoading={isLoading}
        type='submit'
        hierarchy='primary'
        disabled={(errors ? Object.values(errors).length > 0 : false) || submitButton?.disabled}
        {...(submitButton?.callback ? { onClick: submitButton.callback } : {})}
      >
        {submitButton?.text ?? t('common.save')}
      </Button>
    </div>
  );
};
