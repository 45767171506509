import { Modal, ModalBottomButtons } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import {
  TimetablePrintBaseData,
  TimetablePrintConfiguration,
  TimetablePrintLayoutData,
} from '../TimetablePrintConfiguration';
import { useEffect, useState } from 'react';
import { TimetableReportType } from '@bp/planung-graphql-types';
import {
  use_CreateTimetablePrintConfigsMutation,
  use_TimetablePrintConfigsQuery,
  use_UpdateTimetablePrintConfigsMutation,
} from '../../../types/planung-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { useGetInitialTimetablePrintConfig } from '../useGetTimetablePrintConfig';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';

type TimetablePrintConfigurationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  topic: TimetableReportType;
};

export const TimetablePrintConfigurationModal = ({ isOpen, onClose, topic }: TimetablePrintConfigurationModalProps) => {
  const { t } = useTranslation();

  const { pimAuthClaims } = useAuthClaims();

  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const [, updateConfig] = use_UpdateTimetablePrintConfigsMutation();
  const [, createConfig] = use_CreateTimetablePrintConfigsMutation();

  const [baseData, setBaseData] = useState<TimetablePrintBaseData | null>(null);
  const [layoutData, setLayoutData] = useState<TimetablePrintLayoutData | null>(null);

  const context = useMemorizedCacheTag('TIMETABLE_PRINT_CONFIG');

  const [{ data }] = use_TimetablePrintConfigsQuery({
    variables: {
      where: {
        type: topic,
      },
    },
    context,
    pause: !topic,
  });

  const current = data?.timetablePrintConfigs?.[0];

  const { initialBaseData, initialLayoutData } = useGetInitialTimetablePrintConfig({ topic });

  useEffect(() => {
    setBaseData(initialBaseData);
    setLayoutData(initialLayoutData);
  }, [initialBaseData, initialLayoutData]);

  async function handleSubmit() {
    const data = {
      printClasses: baseData?.printClasses,
      scaleText: baseData?.scaleText,
      exactMinute: baseData?.exactMinute,
      printSubtitle: baseData?.printSubtitle,
      printLogo: baseData?.printLogo,
      printColorOrigin: baseData?.colorOrigin,
      printColorPosition: baseData?.colorPosition,
      bottomCenter: layoutData?.bottomCenter,
      bottomLeft: layoutData?.bottomLeft,
      bottomRight: layoutData?.bottomRight,
      center: layoutData?.center,
      topLeft: layoutData?.topLeft,
      topCenter: layoutData?.topCenter,
      topRight: layoutData?.topRight,
    };

    if (current?.uuid) {
      const { error } = await updateConfig({
        where: {
          uuid: current.uuid,
        },
        update: {
          ...data,
        },
      });
      if (error) {
        showUserErrorToast({ error });
      } else {
        showSuccessUpdateToast();
      }
      onClose();
    } else {
      const { error } = await createConfig({
        input: {
          type: topic,
          organization: {
            connect: {
              where: {
                node: {
                  uuid: organizationUuid,
                },
              },
            },
          },
          ...data,
        },
      });
      if (error) {
        showUserErrorToast({ error });
      } else {
        showSuccessCreateToast();
      }
      onClose();
    }
  }

  const restoreDefaults = () => {
    setBaseData(initialBaseData);
    setLayoutData(initialLayoutData);
  };

  return (
    <Modal
      title={t('timetable.configuration.configure')}
      isOpen={isOpen}
      onRequestClose={() => {
        restoreDefaults();
        onClose();
      }}
    >
      {baseData && layoutData && (
        <TimetablePrintConfiguration
          onBaseDataChange={(data) => {
            setBaseData({ ...data });
          }}
          baseData={baseData}
          layoutData={layoutData}
          onLayoutChange={(data) => {
            setLayoutData({ ...data });
          }}
        />
      )}
      <ModalBottomButtons
        closeButton={{
          callback: () => {
            restoreDefaults();
            onClose();
          },
        }}
        submitButton={{
          text: t('common.save'),
          callback: () => handleSubmit(),
        }}
      />
    </Modal>
  );
};
