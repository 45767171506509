import React from 'react';
import { FormikHelpers } from 'formik/dist/types';
import { Form, Formik } from 'formik';
import { vpoSchema } from './validation/vpoSchema';
import { Input, ModalBottomButtons } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { showSuccessSaveToast, showUserErrorToast } from '../../../utils/toast';
import {
  useOrganizationsQuery,
  useUpdateSubstitutionSettingsMutation,
} from '../../../types/planung-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';

export type VpoSubstitutionSettingsType = {
  uuid: string;
  url: string;
};

export const VpoSubstitutionSettingsForm = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();

  const [{ data }] = useOrganizationsQuery({});
  const [, updateSubstitionSettings] = useUpdateSubstitutionSettingsMutation();

  let initialValues: VpoSubstitutionSettingsType = { uuid: pimAuthClaims.getOrganizationUuid(), url: '' };
  try {
    initialValues = {
      ...initialValues,
      ...JSON.parse(
        data?.organizations.find((orga) => orga.uuid === pimAuthClaims.getOrganizationUuid())?.vpoSubstitutionProps ??
          '{}',
      ),
    };
  } catch (e) {
    console.error(e);
  }

  const handleSubmit = async (
    values: VpoSubstitutionSettingsType,
    formikHelpers: FormikHelpers<VpoSubstitutionSettingsType>,
  ) => {
    let result;
    if (values?.url) {
      result = await updateSubstitionSettings({
        organizationUuid: values.uuid,
        update: { vpoSubstitutionProps: JSON.stringify({ url: values.url }) },
      });
    } else {
      result = await updateSubstitionSettings({
        organizationUuid: values.uuid,
        update: { vpoSubstitutionProps: JSON.stringify({}) },
      });
    }
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessSaveToast();
    }
    formikHelpers.resetForm();
  };

  return (
    <Formik<VpoSubstitutionSettingsType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={vpoSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values, handleChange, resetForm, dirty, isValidating, isSubmitting, errors, touched }) => (
        <Form>
          <Input
            label={t('subject.name')}
            name={'url'}
            onChange={handleChange}
            value={values.url}
            error={touched.url ? errors.url : undefined}
          ></Input>
          <ModalBottomButtons
            closeButton={{
              text: t('common.cancelChanges'),
              callback: () => {
                resetForm();
              },
            }}
            submitButton={{ disabled: isSubmitting || !dirty || isValidating }}
            errors={errors}
          />
        </Form>
      )}
    </Formik>
  );
};
