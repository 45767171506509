import { CheckIcon, EmptyState, TextArea } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { ConstraintsFormInitialValuesType, ConstraintsItemType } from '../../Constraints/types';
import { useConstraints } from '../../../hooks/useConstraints';
import { ConstraintsItem } from '../../Constraints/ConstraintsItem';
import { observer } from 'mobx-react-lite';
import { useTimetableStore } from '../../TimetableGrid/TimetableProvider';

type TimetableVersionClassesConstraintsProps = {
  constraintsData: ConstraintsFormInitialValuesType;
  onChange: (data: ConstraintsFormInitialValuesType) => Promise<boolean> | boolean;
};

export const TimetableVersionClassesConstraints = observer(
  ({ constraintsData, onChange }: TimetableVersionClassesConstraintsProps) => {
    const { t } = useTranslation();

    const [comment, setComment] = useState<string>(constraintsData.comment);
    const store = useTimetableStore();
    const constraints = useConstraints(constraintsData);

    function handleDuplicate() {
      console.log('duplicate clicked');
    }

    function handleDelete(item: ConstraintsItemType) {
      if (store.readonly) return;
      constraintsData[item.type] = undefined;
      const priorityName = `${item.type}Priority`;
      const newData: ConstraintsFormInitialValuesType = { ...constraintsData, [item.type]: null, [priorityName]: null };
      onChange({ ...newData });
    }

    async function handleCommentChange(e: ChangeEvent<HTMLTextAreaElement>) {
      if (store.readonly) return;
      setComment(e.target.value);
      onChange({ ...constraintsData, comment: e.target.value });
    }

    return constraints.length === 0 ? (
      <EmptyState
        icon={<CheckIcon className='bordered' />}
        size='small'
        iconColor='var(--color-success)'
        title={t('timetableVersion.constraint.noConstraints', {
          type: t('classes.title.singular'),
        })}
        padding='xl'
      />
    ) : (
      <>
        {constraints.length !== 0 && constraintsData.comment && (
          <TextArea
            className='px-6'
            name='comment'
            onChange={handleCommentChange}
            label={t('common.comment')}
            value={comment ?? ''}
            isScrollable
            readonly
          />
        )}
        {constraints.map((constraint) => {
          return (
            <ConstraintsItem
              key={constraint.type}
              item={constraint}
              onDelete={handleDelete}
              onDuplicate={handleDuplicate}
            />
          );
        })}
      </>
    );
  },
);
