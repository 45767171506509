import { showSuccessCreateToast } from './toast';
import { apiHelper } from './apiHelper';
import { ReportPdfTableData } from '../components/EvaluateTimetable/TimetableReportsTable';
import { TeachingLoadReportType } from '@bp/planung-graphql-types';

export const downloadExcelReport = async (report: ReportPdfTableData, reportType: TeachingLoadReportType) => {
  await apiHelper<void>({
    method: 'GET',
    path: `/excel/${reportType}/${report.uuid}`,
    type: 'download',
    downloadFileName: 'Excel_Deputate',
  });
};

export const deleteExcelReport = async (report: { uuid: string; name: string }, reportType: TeachingLoadReportType) => {
  await apiHelper<void>({
    method: 'DELETE',
    path: `/excel/${reportType}/${report.uuid}`,
  });
};

export const createTeachingLoadExcelReport = async (schoolYearUuid: string, timetableBlockUuid: string) => {
  const response = await apiHelper<Response>({
    method: 'POST',
    path: `/excel/${schoolYearUuid}/${timetableBlockUuid}`,
  });

  if (response.ok) {
    showSuccessCreateToast();
  }
};
