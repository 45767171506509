import { useAuthClaims } from './useAuthClaims';
import { ClaimsPermissionChecker, PermissionChecker } from '@bp/pim-auth-permissions';
import { useRef } from 'react';

export const usePermissionChecker = () => {
  const { pimAuthClaims } = useAuthClaims();
  const profile = pimAuthClaims.getProfile();
  const roles = pimAuthClaims.getRoles();
  const omniOrganization = pimAuthClaims.getOmniOrganization();

  const permissionChecker = useRef<PermissionChecker | null>(null);

  if (!profile || !roles || !omniOrganization) {
    console.warn('Insufficient claims for permission checker. Returning deny-all permission checker');
    permissionChecker.current = null;
  } else {
    permissionChecker.current = new ClaimsPermissionChecker(
      profile,
      pimAuthClaims.getOrganization(),
      roles,
      pimAuthClaims.getUser(),
      omniOrganization,
    );
  }

  return permissionChecker.current;
};
