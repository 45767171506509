import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useLessonsWithoutCardsQuery } from '../../../../types/planung-graphql-client-defs';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoader, Modal, showToast, Table, TableColumns } from '@bp/ui-components';
import { ModalBottomButtons } from '../../../ModalBottomButtons/ModalBottomButtons';
import { useTimetableLessons } from '../../hooks/useTimetableLessons';
import { selectedToData } from '../../../../utils/selectedToData';

export type LessonsImportProps = {
  versionUuid: string;
  onClose: () => void;
  isOpen: boolean;
};

export type ImportLessonsType = {
  uuid: string;
  subject: string;
  classes: string;
  teachers: string;
  elective: boolean;
  teachingLoadEnabled: boolean;
  timetableEnabled: boolean;
};

export const ImportLessonsToTimetableVersionModal = ({ versionUuid, onClose, isOpen }: LessonsImportProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const { t } = useTranslation();

  const [selected, setSelected] = useState({});
  const [sorting, onSorting] = useState([
    { id: 'classes', desc: false },
    { id: 'subject', desc: false },
  ]);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  const { addLessonsToVersion, lessonContext, loadingState, dispatch } = useTimetableLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
    versionUuid: versionUuid,
  });

  const [{ data: lessonsData }] = useLessonsWithoutCardsQuery({
    variables: {
      lessonWhere: {
        versions_NONE: { uuid: versionUuid },
        lessonClasses_ALL: {
          class: {
            timetableVersions_SOME: {
              uuid: versionUuid,
            },
          },
        },
        onlyInTimetableVersion: false,
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
        lessonUnit_SOME: {
          subjectContainer: null,
        },
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
      },
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: lessonContext,
  });

  const handleSubmit = async () => {
    const selectedLessons = selectedToData<ImportLessonsType>(selected, memorizedLessonsData);

    const uuids = selectedLessons.map((r) => r.uuid);
    const response = await addLessonsToVersion(uuids);
    if (response.error) {
      showToast(t('lesson.message.import.error'), { type: 'error' });
    } else {
      showToast(t('lesson.message.import.success'), { type: 'success' });
    }
    dispatch({ type: 'RESET' });
    onClose();
    setSelected({});
  };

  const memorizedLessonsData: ImportLessonsType[] = useMemo(() => {
    return (
      lessonsData?.lessons.map((lesson): ImportLessonsType => {
        return {
          subject: lesson.subject.name,
          uuid: lesson.uuid,
          classes: lesson.lessonClassesConnection.edges
            .map((lessonClass) => {
              return lessonClass.node.class.shortName;
            })
            .join(', '),
          teachers: lesson.teachersConnection.edges
            .map((teacher) => {
              return teacher.node.displayNameShort ?? teacher.node.shortName;
            })
            .join(', '),
          elective: lesson.elective ?? false,
          teachingLoadEnabled: lesson.teachingLoadEnabled ?? false,
          timetableEnabled: lesson.timetableEnabled ?? false,
        };
      }) ?? []
    );
  }, [lessonsData?.lessons]);

  const tableColumns = useMemo((): TableColumns<ImportLessonsType>[] => {
    return [
      {
        header: t('lesson.table.subject'),
        id: 'subject',
        accessorKey: 'subject',
        meta: {
          filterName: t('lesson.table.subject'),
        },
        size: 200,
        canExpand: true,
      },
      {
        header: t('lesson.table.classes'),
        id: 'classes',
        accessorKey: 'classes',
        size: 50,
        canExpand: true,
      },
      {
        header: t('lesson.table.teacher'),
        id: 'teachers',
        accessorKey: 'teachers',
        size: 175,
        canExpand: true,
      },
      {
        header: t('lesson.basics.isElective'),
        id: 'elective',
        accessorKey: 'elective',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTeachingLoadEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTeachingLoadEnabled.full'),
          tooltip: t('lesson.basics.isTeachingLoadEnabled.full'),
        },
        id: 'teachingLoadEnabled',
        accessorKey: 'teachingLoadEnabled',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTimetableEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTimetableEnabled.full'),
          tooltip: t('lesson.basics.isTimetableEnabled.full'),
        },
        id: 'timetableEnabled',
        accessorKey: 'timetableEnabled',
        type: 'boolean',
        size: 60,
      },
    ];
  }, [t]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      hideFooter={false}
      height={'l'}
      loading={loadingState.length > 0}
      onAfterOpen={(options) => {
        if (options?.contentEl.clientHeight) {
          setMaxHeight(options?.contentEl.clientHeight);
        }
      }}
      footer={
        <ModalBottomButtons
          isLoading={loadingState.length > 0}
          closeButton={{ callback: () => onClose() }}
          submitButton={{ callback: () => handleSubmit(), text: t('lesson.import') }}
        />
      }
    >
      <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
        {isOpen && (
          <Table<ImportLessonsType>
            canScroll={false}
            maxHeight={maxHeight}
            isOnWhite={true}
            rowSelection={selected}
            onRowSelectionChange={setSelected}
            showActionBar
            actionBarSettings={{
              showExpertFilter: true,
              showAddButton: false,
              showPrintButton: false,
              showBulkEdit: false,
              showSelectedRowsCount: true,
            }}
            sorting={sorting}
            onSortingChange={onSorting}
            columns={tableColumns}
            data={memorizedLessonsData ?? []}
            showSelect
          />
        )}
      </Suspense>
    </Modal>
  );
};
