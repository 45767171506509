import { TimetableVersionLessonType } from '../components/TimetableVersion/graphql/types';
import { createIntArray, partition } from './arrayFunc';
import { TeachingBlockCardType } from '../components/TeachingBlockVersion/graphql/types';

export const getOriginalTeachingBlockCardsFormLesson = ({
  lesson,
  subjectContainerUuid,
  version,
}: {
  lesson: TimetableVersionLessonType;
  subjectContainerUuid?: string | null;
  version: {
    uuid: string;
    version: number;
  };
}) => {
  const cards: TeachingBlockCardType[] = [];
  if (lesson) {
    const [passed, missed] = partition(lesson.lessonUnitConnection.edges, ({ node }) => node.subjectContainer === null);
    if (subjectContainerUuid) {
      missed.forEach((lessonUnit) => {
        createIntArray(lessonUnit.node.count ?? 0).forEach(() => {
          if (subjectContainerUuid === lessonUnit.node.subjectContainer?.uuid) {
            cards.push({
              duration: lessonUnit.node.duration,
              uuid: '',
              teachingBlockVersion: {
                version: version.version,
              },
              lesson: lesson,
              locked: false,
              rooms: lesson.roomSupplyConnection.edges.length === 1 ? [lesson.roomSupplyConnection.edges[0].node] : [],
              lessonClasses: lesson.lessonClassesConnection.edges.map((e) => e.node),
              teachers: lesson.teachersConnection.edges.map((e) => e.node),
              subject: lesson.subject,
              editable: true,
              includeHoliday: false,
            });
          }
        });
      });
    } else {
      passed.forEach((lessonUnit) => {
        createIntArray(lessonUnit.node.count ?? 0).forEach(() => {
          cards.push({
            duration: lessonUnit.node.duration,
            uuid: '',
            lesson: lesson,
            teachingBlockVersion: {
              version: version.version,
            },
            locked: false,
            rooms: lesson.roomSupplyConnection.edges.length === 1 ? [lesson.roomSupplyConnection.edges[0].node] : [],
            lessonClasses: lesson.lessonClassesConnection.edges.map((e) => e.node),
            teachers: lesson.teachersConnection.edges.map((e) => e.node),
            subject: lesson.subject,
            editable: true,
            includeHoliday: false,
          });
        });
      });
    }
  }
  return cards;
};
