import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { TeachingBlockVersionLessonsTable } from '../../../../../../components/Lessons/Tables/TeachingBlockVersionLessonsTable';

export const PlanTeachingBlockLessons = () => {
  const { teachingBlockVersionUuid } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const removeFilterParam = () => {
    if (searchParams.has('filter')) {
      searchParams.delete('filter');
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    removeFilterParam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TeachingBlockVersionLessonsTable versionUuid={teachingBlockVersionUuid ?? ''} />;
};
