import { ControlIcon, EmptyState, Grid, GridRow } from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import classNames from 'classnames';
import { CardControlItem } from './CardControlItem';
import { useTranslation } from 'react-i18next';
import { useTimetableStore } from '../TimetableProvider';

type CardControlProps = {
  className?: string | undefined;
};

export const CardControl: FC<CardControlProps> = observer(({ className }) => {
  const { t } = useTranslation();
  const pinboardStore = useTimetableStore();
  const result = pinboardStore.getControlModeResult();

  const hasAvailability = result?.availabilityWarnings && result.availabilityWarnings.length !== 0;
  const hasCondition = result?.conditionWarnings && result.conditionWarnings.length !== 0;
  const hasDependency = result?.dependencyWarnings && result.dependencyWarnings.length !== 0;

  return (
    <Grid className={classNames('has-scrollbar', className)} style={{ height: 'fit-content' }}>
      {!hasAvailability && !hasCondition && !hasDependency && (
        <EmptyState
          size='small'
          subtitle={t('pinboard.control.noWarnings')}
          icon={<ControlIcon />}
          iconColor='var(--color-success)'
        />
      )}
      <GridRow
        direction='column'
        spacingTop={!hasAvailability ? 'none' : 'm'}
        spacingBottom={!hasAvailability ? 'none' : 'm'}
      >
        {hasAvailability &&
          result?.availabilityWarnings?.map((warning, index) => {
            return <CardControlItem key={index} count={index} type='availability' availabilityWarning={warning} />;
          })}
      </GridRow>
      <GridRow
        direction='column'
        spacingTop={!hasCondition || !hasAvailability ? 'none' : 'm'}
        spacingBottom={!hasCondition || !hasAvailability ? 'none' : 'm'}
      >
        {hasCondition &&
          result?.conditionWarnings?.map((warning, index) => {
            return <CardControlItem key={index} count={index} type='condition' genericWarning={warning} />;
          })}
      </GridRow>
      <GridRow
        direction='column'
        spacingTop={!hasDependency ? 'none' : 'm'}
        spacingBottom={!hasDependency ? 'none' : 'm'}
      >
        {hasDependency &&
          result?.dependencyWarnings?.map((warning, index) => {
            return <CardControlItem key={index} count={index} type='dependency' genericWarning={warning} />;
          })}
      </GridRow>
    </Grid>
  );
});
