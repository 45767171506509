import { CardType } from '../components/TimetableVersion/graphql/types';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { createIntArray, uniqueBy } from '../utils/arrayFunc';
import { useLoadBasicData } from './useLoadBasicData';
import {
  _ClassesQuery,
  _LessonClassesQuery,
  _PeopleQuery,
  use_LessonsQuery,
  use_TeachingBlockCardsQuery,
  use_TeachingBlocksQuery,
  use_TimeGridEntriesQuery,
  use_TimetableCardsQuery,
  use_TimetablesQuery,
  useClassGridCardsQuery,
} from '../types/planung-graphql-client-defs';
import { BadgeCardRow } from '@bp/ui-components';
import { useMemorizedCacheTag } from './useMemorizedCacheTag';
import { RequestPolicy } from '@urql/core';

export const useGetTimetableCardType = (versionUuid: string, pause: boolean = false, requestPolicy?: RequestPolicy) => {
  const timetableCardsContext = useMemorizedCacheTag('TIMETABLE_CARD');
  const lessonContext = useMemorizedCacheTag('LESSONS');
  const cardContext = useMemorizedCacheTag('CLASS_GRID_CARDS');

  const [{ data: timetableData }] = use_TimetablesQuery({
    variables: {
      where: {
        versions_SOME: { uuid: versionUuid },
      },
    },
    pause: !versionUuid,
  });

  const timegridUuid = timetableData?.timetables[0]?.timegridConfigsConnection.edges[0]?.node.timegrid?.uuid;

  const [{ data: lessonsQueryData }] = use_LessonsQuery({
    variables: {
      where: {
        versions_SOME: { uuid: versionUuid },
      },
    },
    pause: !versionUuid || versionUuid === '' || pause,
    context: lessonContext,
  });

  const { subjectData, lessonClassesData, classesData, teacherData, groupsData, roomsData, subjectContainerData } =
    useLoadBasicData({
      pause: !lessonsQueryData,
    });

  const [{ data }] = use_TimetableCardsQuery({
    variables: {
      where: {
        timetableVersion: { uuid: versionUuid },
        lesson: {
          versions_SOME: { uuid: versionUuid },
        },
      },
    },
    pause: !versionUuid || versionUuid === '' || pause,
    requestPolicy,
    context: timetableCardsContext,
  });

  const subjectContainerUuids = data?.timetableCards.map((card) => card.subject.uuid) ?? [];

  const [{ data: teachingBlockCardsData }] = use_TeachingBlockCardsQuery({
    variables: {
      where: {
        teachingBlockVersion: {
          active: true,
          teachingBlock: {
            subjectContainer: {
              uuid_IN: subjectContainerUuids,
            },
          },
        },
      },
    },
    pause: !versionUuid || versionUuid === '' || pause,
  });

  const [{ data: teachingBlockData }] = use_TeachingBlocksQuery({
    variables: {
      where: {
        subjectContainer: {
          uuid_IN: subjectContainerUuids,
        },
      },
    },
  });

  const [{ data: timeGridEntriesData }] = use_TimeGridEntriesQuery({
    variables: {
      where: {
        timeGrid: {
          uuid: timegridUuid,
        },
      },
    },
    pause: !timegridUuid || pause,
  });

  const [{ data: classCardsData }] = useClassGridCardsQuery({
    variables: {
      where: { timetableVersion: { uuid: versionUuid } },
    },
    context: cardContext,
    pause: !versionUuid || versionUuid === '' || pause,
  });

  const cards: CardType[] = useMemo(() => {
    return (
      data?.timetableCards.map((card): CardType => {
        const lesson = lessonsQueryData?.lessons.find((lesson) => lesson.uuid === card?.lesson.uuid);

        const lessonClasses =
          lesson?.lessonClassesConnection.edges.map((edge) => {
            return lessonClassesData?.lessonClasses.find((lessonClass) => lessonClass.uuid === edge.node.uuid);
          }) ?? [];

        const classes = lessonClasses
          .map((lc) => {
            return classesData?.classes.find((c) => c.uuid === lc?.class.uuid);
          })
          .sort((a, b) => {
            return a?.name.localeCompare(b?.name ?? '') ?? 0;
          });

        const teachers = lesson?.teachersConnection.edges.map((t) => {
          return (
            teacherData?.people.find((p) => p.uuid === t.node.uuid) ?? ({} as Pick<_PeopleQuery, 'people'>['people'][0])
          );
        });

        const rooms = roomsData?.rooms.filter((room) => card.rooms.some((r) => r.uuid === room.uuid));

        // get timeblock card infos
        const cardClasses = classes.map((c) => c?.uuid);
        const subject = subjectData?.subjects.find((subject) => subject.uuid === card.subject.uuid);
        const subjectContainer = subjectContainerData?.subjectContainers.find((sc) => sc.uuid === card.subject.uuid);

        const teachingBlockTeachers: Pick<_PeopleQuery, 'people'>['people'] = [];
        const teachingBlockRooms: { uuid: string; name: string }[] = [];

        // Hier eine Map mit LessonUuid und den zugehörigen Lehrern erstellen
        // Und eine Map mit TeacherUuid und den Lessons in denen die vorkommen
        // Diese dann an die Card übergeben und im Store wieder auslesen und dort in eingene Maps schreiben! Niemals die Card oberserven oder aus die Daten direkt zugreifen
        // In der GridCard dann mithilfe der zwei maps prüfen ob der Konflikt angezeit werden soll. -> Wenn Lehrende aus gleicher Lesson dann nicht.

        if (subjectContainer) {
          const teachingBlock = teachingBlockData?.teachingBlocks.find(
            (tb) => tb.subjectContainer.uuid === subjectContainer.uuid,
          );
          const version = teachingBlock?.versions.find((version) => version.active);
          const teachingBlockCards = teachingBlockCardsData?.teachingBlockCards.filter((tbc) => {
            const teachingBlockCardClasses = tbc.lessonClasses.map((lc) => lc.class.uuid);
            return (
              tbc.teachingBlockVersion.uuid === version?.uuid &&
              teachingBlockCardClasses.some((c) => cardClasses.includes(c))
            );
          });
          teachingBlockCards?.forEach((tbc) => {
            const teacherUuids = tbc.teachers.map((t) => t.uuid);
            const teachers = teacherData?.people.filter((p) => teacherUuids.includes(p.uuid));
            if (teachers) {
              teachingBlockTeachers.push(...teachers);
            }
            // card is placed
            if (tbc.startDate && tbc.endDate) {
              const _rooms = tbc.rooms
                .map((r) => {
                  return roomsData?.rooms.find((room) => room.uuid === r.uuid);
                })
                .filter((r) => r) as { uuid: string; name: string }[]; // since we filter out undefined
              if (_rooms && _rooms.length > 0) {
                teachingBlockRooms.push(..._rooms);
              }
            }
          });
        }

        const classGridCards =
          classCardsData?.classGridCards.filter((classGridCard) => {
            return classGridCard.timetableCardUuid === card?.uuid;
          }) ?? [];

        const groupUuids = lessonClasses.map((lc) => {
          return lc?.groups.map((g) => g.uuid);
        });
        const groups = groupsData?.groups.filter((group) => {
          return groupUuids.some((groupUuid) => groupUuid?.includes(group.uuid)) ?? false;
        });

        const postions: number[] = [];
        classGridCards?.forEach((c) => {
          if ((c.divisionsCount ?? 1) > 1) {
            postions.push(c.usedPosition ?? 1);
          } else {
            postions.push(0);
          }
        });

        const start = timeGridEntriesData?.timeGridEntries.find(
          (entry) => entry.uuid === card.startTimeGridEntry?.uuid,
        );
        const startTime = start ? dayjs(start.start).format('HH:mm') : null;

        const end = timeGridEntriesData?.timeGridEntries.find((entry) => entry.uuid === card.endTimeGridEntry?.uuid);
        const endTime = end ? dayjs(end.end).format('HH:mm') : null;
        const cardWithBiggestDivisionsCount = classGridCards.find(
          (c) => c.divisionsCount === Math.max(...classGridCards.map((c) => c.divisionsCount ?? 1)),
        );
        const rows: BadgeCardRow[] = createIntArray(cardWithBiggestDivisionsCount?.divisionsCount ?? 1).map((i) => {
          if (postions.includes(i)) {
            return {
              rowColor: '',
              colColors: cardWithBiggestDivisionsCount?.colors ?? [],
            };
          }
          return {
            rowColor: '',
            colColors: [],
          };
        });

        return {
          ...card,
          start: card.startTimeGridEntry?.uuid ?? null,
          end: card.endTimeGridEntry?.uuid ?? null,
          startTime: startTime,
          endTime: endTime,
          subject: subjectContainer ? subjectContainer : subject,
          isSubjectContainer: !!subjectContainer,
          lesson: lesson,
          classGridCards: classGridCards,
          lessonClasses:
            (lessonClasses.filter((lc) => lc) as Pick<_LessonClassesQuery, 'lessonClasses'>['lessonClasses']) ?? [], // hack hack hack since we remove lessonClasses or change cache (no idea if it will help then). After lesson update (delete lessonClasses / create lessonClasses) we have to reload the page. We can not delete / create lessonClasses separately because of missing connection
          classes: (classes.filter((c) => c) as Pick<_ClassesQuery, 'classes'>['classes']) ?? [], // hack hack hack since we remove lessonClasses
          teachers: subjectContainer ? uniqueBy(teachingBlockTeachers, 'uuid') : (teachers ?? []),
          groups: groups ?? [],
          rooms: subjectContainer ? uniqueBy(teachingBlockRooms, 'uuid') : (rooms ?? []),
          badgeCardRows: rows,
          badgeCardTextColor: cardWithBiggestDivisionsCount?.textColor ?? '',
        };
      }) ?? []
    );
  }, [
    data?.timetableCards,
    lessonsQueryData?.lessons,
    roomsData?.rooms,
    subjectData?.subjects,
    subjectContainerData?.subjectContainers,
    classCardsData?.classGridCards,
    groupsData?.groups,
    timeGridEntriesData?.timeGridEntries,
    lessonClassesData?.lessonClasses,
    classesData?.classes,
    teacherData?.people,
    teachingBlockData?.teachingBlocks,
    teachingBlockCardsData?.teachingBlockCards,
  ]);
  return cards;
};
