import * as yup from 'yup';
import i18next from 'i18next';
import { LessonFormUnitSchema } from '../../../Lessons/Forms/validation/schema';

const LessonFormSubjectTypeSchema = yup.object().shape({
  uuid: yup.string().required(),
  epochEnabled: yup.boolean().nullable(),
  subjectHourEnabled: yup.boolean().nullable(),
});

export const schema = yup.object().shape({
  uuid: yup.string(),
  subject: LessonFormSubjectTypeSchema.required(i18next.t('validation.common.required')),
  subTitle: yup.string().nullable(),
  comment: yup.string().nullable(),
  elective: yup.boolean(),
  teachingLoadEnabled: yup.boolean(),
  timetableEnabled: yup.boolean(),
  curriculumGrades: yup.array(),
  lessonUnit: yup.array().of(LessonFormUnitSchema),
});

export const createCurriculumVersionSchema = yup.object().shape({
  name: yup.string().nullable().required(i18next.t('validation.common.required')),
  description: yup.string(),
});
