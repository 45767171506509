import { useMemo } from 'react';

export const useMemoizedClassNumberOptions = () =>
  useMemo(
    () =>
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((level) => {
        return {
          value: level,
          label: '' + level,
        };
      }),
    [],
  );
