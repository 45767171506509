import { useState } from 'react';
import { SchoolEventTable } from '../../../../components/SchoolEvent/SchoolEventTable';
import { Page } from '../../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { SchoolEventModal } from '../../../../components/SchoolEvent/SchoolEventModal';

export type SchoolEventType = {
  uuid: string;
  subjectUuid: string;
  name: string;
  classeUuids: string[];
  groupUuids: string[];
  teacherUuids: string[];
  startDate: Date;
  startTime: Date;
  endDate: Date;
  endTime: Date;
  repetition: 'daily' | 'weekly' | 'weekdays' | 'annually' | null;
  isClassbookRelevant: boolean;
};

export const SetupSchoolEvent = () => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);

  function handleAdd() {
    setSelectedUuid(null);
    setModalOpen(true);
  }

  function handleEdit(uuid: string) {
    setSelectedUuid(uuid);
    setModalOpen(true);
  }

  function handleDuplicate(uuid: string) {
    console.log('TODO: implement');
  }

  function handleDelete(uuid: string) {
    console.log('TODO: implement');
  }

  function handleSubmit() {
    console.log('TODO: implement');
  }

  return (
    <Page title={t('schoolEvents.title.plural')}>
      <SchoolEventTable
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDuplicate={handleDuplicate}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
      />
      <SchoolEventModal
        selectedEventUuid={selectedUuid}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={() => handleSubmit()}
      />
    </Page>
  );
};
