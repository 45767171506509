import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import {
  ClassesIllustration,
  DivisionIllustration,
  GradegroupsIllustration,
  RoomIllustration,
  SchoolYearIllustration,
  SubjectIllustration,
  TeacherIllustration,
  UsersetupIllustration,
} from '@bp/ui-components';
import IconMenu from '../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../components/IconMenuItem/IconMenuItem';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page/Page';

export const SettingsPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page title={t('settings.title')}>
      <IconMenu headline={t('common.coreData')}>
        <IconMenuItem
          label={`${t('subject.title.plural')} / ${t('subjectContainer.title')}`}
          icon={<SubjectIllustration />}
          bubbleType={1}
          onClick={() => navigate(t('routes.subjects.slug'))}
        />
        <IconMenuItem
          label={t('teachers.titleSingular')}
          icon={<TeacherIllustration />}
          bubbleType={2}
          onClick={() => navigate(t('routes.teacher.slug'))}
        />
        <IconMenuItem
          label={t('classes.title.plural')}
          icon={<ClassesIllustration />}
          bubbleType={3}
          onClick={() => navigate(t('routes.classes.slug'))}
        />
        <IconMenuItem
          label={t('rooms.title.long')}
          icon={<RoomIllustration />}
          bubbleType={4}
          onClick={() => navigate(t('routes.rooms.slug'))}
        />
      </IconMenu>
      <IconMenu hasMarginTop headline={t('common.additionalData')}>
        <IconMenuItem
          label={`${t('schoolYear.title')} / ${t('holidays.title')}`}
          icon={<SchoolYearIllustration />}
          bubbleType={14}
          onClick={() => navigate(t('routes.schoolyear.slug'))}
        />
        <IconMenuItem
          label={t('routes.divisions.title')}
          icon={<DivisionIllustration />}
          bubbleType={16}
          onClick={() => navigate(t('routes.divisions.slug'))}
        />
        <IconMenuItem
          label={t('routes.gradeGroups.title')}
          icon={<GradegroupsIllustration />}
          bubbleType={15}
          onClick={() => navigate(t('routes.gradeGroups.slug'))}
        />
        <IconMenuItem
          label={t('routes.users.title')}
          icon={<UsersetupIllustration />}
          bubbleType={12}
          onClick={() => navigate(t('routes.users.slug'))}
        />
      </IconMenu>
    </Page>
  );
};
