import { ArrowRightIcon, Button, Table, TableColumns } from '@bp/ui-components';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurriculum } from '../hooks/useCurriculum';
import { CurriculumContext } from '../../../pages/Deputate/Setup/Curricula/CurriculaPage';

export type CurriculumGradesTableType = {
  grade: number | string;
  name: string;
  subjectHours: string;
  studentHours: string;
  deputat: string;
};

export const CurriculumGradesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const curriculumContext = useContext(CurriculumContext);

  const { tableData: allGradesData } = useCurriculum({ versionUuid: curriculumContext.versionUuid });

  const tableData: CurriculumGradesTableType[] = useMemo(() => {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, '-'].map((grade) => {
      return {
        grade,
        name: grade.toString(),
        subjectHours: allGradesData
          .map((d) => d.curriculumInfo?.classInfos.get('uuid_' + grade)?.studentCards ?? 0.0)
          .reduce((a, b) => a + b, 0.0)
          .toFixed(2),
        studentHours: allGradesData
          .map((d) => d.curriculumInfo?.classInfos.get('uuid_' + grade)?.studentHours ?? 0.0)
          .reduce((a, b) => a + b, 0.0)
          .toFixed(2),
        deputat: allGradesData
          .map((d) => d.curriculumInfo?.classInfos.get('uuid_' + grade)?.deputat ?? 0.0)
          .reduce((a, b) => a + b, 0.0)
          .toFixed(2),
      };
    });
  }, [allGradesData]);

  const tableColumns: TableColumns<CurriculumGradesTableType>[] = useMemo(() => {
    return [
      {
        header: t('classes.level.full'),
        id: 'name',
        accessorKey: 'name',
        size: 400,
      },
      {
        header: t('common.studentHours'),
        accessorKey: 'studentHours',
        id: 'studentHours',
        size: 90,
        alignment: 'right',
      },
      {
        header: t('subjectHours.subjectHour_other'),
        accessorKey: 'subjectHours',
        id: 'subjectHours',
        size: 90,
        alignment: 'right',
      },
      {
        header: t('deputate.titleSingluar'),
        accessorKey: 'deputat',
        id: 'deputat',
        size: 90,
        alignment: 'right',
      },
    ];
  }, [t]);

  function handleRowClick(grade: number | string) {
    navigate(
      '/' +
        t('routes.deputate.slug') +
        '/' +
        t('routes.setup.slug') +
        '/' +
        t('routes.curriculum.slug') +
        '/' +
        curriculumContext.versionUuid +
        '/' +
        t('routes.lessons.plan.classes.slug') +
        '/' +
        grade,
    );
  }

  return (
    <>
      <Table<CurriculumGradesTableType>
        data={tableData}
        columns={tableColumns}
        showSelect={false}
        showRowHover
        showBorderRadius
        showShadow
        onRowClick={(_e, row) => {
          handleRowClick(row.original.grade);
        }}
        lastCol={(row) => {
          return (
            <Button
              hierarchy='ghost'
              onClick={() => {
                handleRowClick(row.original.grade);
              }}
              icon={<ArrowRightIcon className='small' />}
            />
          );
        }}
      />
    </>
  );
};
