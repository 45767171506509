import { useTranslation } from 'react-i18next';
import ClassDivisionsClassesTable from '../../../components/ClassDivisions/ClassDivisionsClassesTable';
import { Tab } from '@bp/ui-components';
import { SettingsClassesTable } from '../../../components/Classes/Tables/SettingsClassesTable';
import { Page } from '../../../components/Page/Page';

export const SetupClassesAndDivisions = () => {
  const { t } = useTranslation();

  return (
    <Page title={null}>
      <Tab
        variant={'large'}
        tabs={[
          {
            title: t('classes.title.plural'),
            value: 'classes',
            content: <SettingsClassesTable key={'classes'} />,
          },
          {
            title: t('divisions.title.plural'),
            value: 'divisions',
            content: <ClassDivisionsClassesTable key={'divisions'} />,
          },
        ]}
      />
    </Page>
  );
};
