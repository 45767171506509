import { Table, TableColumns } from '@bp/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnsSort } from '../../../../hooks/useColumnsSort';
import styles from '../../LessonsComponents.module.scss';

export type TeachersInfoTableData = {
  teacherName: string;
  plannedDeputate: string;
  contractDeputate: string;
  status: string;
};

type TeachersInfoTableProps = { data: TeachersInfoTableData[]; subjectName?: string };
export const TeachersInfoTable = ({ data, subjectName }: TeachersInfoTableProps) => {
  const { t } = useTranslation();
  const { sorting, saveSorting } = useColumnsSort('status');
  const memoizedData = useMemo(() => data, [data]);
  const tableColumns = useMemo((): TableColumns<TeachersInfoTableData>[] => {
    return [
      {
        header: `${subjectName ?? ' '} ${t('persons.colleague')}`,
        id: 'teacherName',
        accessorKey: 'teacherName',
      },
      {
        header: t('common.planned'),
        id: 'plannedDeputate',
        accessorKey: 'plannedDeputate',
        alignment: 'right',
      },
      {
        header: t('contracts.contract'),
        id: 'contractDeputate',
        accessorKey: 'contractDeputate',
        alignment: 'right',
      },

      {
        header: t('common.status'),
        id: 'status',
        accessorKey: 'status',
        alignment: 'right',
      },
    ];
  }, []);

  return (
    <Table<TeachersInfoTableData>
      showSort
      sorting={sorting}
      onSortingChange={saveSorting}
      columns={tableColumns}
      data={memoizedData}
      canScroll
      emptyStateSettings={{ size: 'small', title: t('persons.noPerson'), subtitle: '' }}
      showBorderRadius
      showShadow
      maxHeight={275}
      className={styles['teacher-info-table']}
    />
  );
};
