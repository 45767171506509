import { useTranslation } from 'react-i18next';
import { FC, ReactNode } from 'react';
import { UsedInLessonsQueryDataType } from '../../../hooks/useIsUsedInLesson';

type UsedInLessonTooltipProps = {
  lessons: UsedInLessonsQueryDataType[];
  usedInText: ReactNode;
};

export const UsedInLessonsTooltipContent: FC<UsedInLessonTooltipProps> = ({ lessons, usedInText }) => {
  const { t } = useTranslation();
  return (
    <div>
      {usedInText}
      <ul style={{ textAlign: 'left' }}>
        {lessons.slice(0, 3).map((lesson) => {
          return (
            <li key={lesson.uuid}>
              {lesson.subject.name},{' '}
              {lesson.lessonClassesConnection.edges.length === 1
                ? t('classes.title.singular')
                : t('classes.title.plural')}
              : {lesson.lessonClassesConnection.edges.map((e) => e.node.class.shortName).join(', ')}
              {lesson.onlyInTimetableVersion ? (
                <ul>
                  <li>{t('deputate.lesson')}</li>
                </ul>
              ) : (
                <ul>
                  {lesson.versions.slice(0, 3).map((v, index) => {
                    return (
                      <li key={v.uuid}>
                        {v.timetable.name}, {t('timetableVersion.title.singular')} {v.version}
                      </li>
                    );
                  })}
                  {lesson.versions.length > 3 && <li style={{ listStyle: 'none' }}>...</li>}
                </ul>
              )}
            </li>
          );
        })}
        {lessons.length > 3 && <li style={{ listStyle: 'none' }}>...</li>}
      </ul>
    </div>
  );
};
