import React, { useEffect, useState } from 'react';

const getContainerDimensions = (ref: React.RefObject<HTMLElement>) => {
  return {
    width: ref.current?.getBoundingClientRect().width ?? 0,
    height: ref.current?.getBoundingClientRect().height ?? 0,
  };
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export function useContainerDimensions(ref: React.RefObject<HTMLElement>) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function handleResize() {
      setDimensions(getContainerDimensions(ref));
    }

    if (ref.current) {
      setDimensions(getContainerDimensions(ref));
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return dimensions;
}

export function useWindowDimensions() {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return dimensions;
}
