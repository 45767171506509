import classNames from 'classnames';
import { FC, MouseEvent } from 'react';
import styles from './CardPicker.module.scss';
import { TeachingBlockCardType } from '../types';
import { TeachingBlockCard } from '../TeachingBlockCard/TeachingBlockCard';
import { observer } from 'mobx-react-lite';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import { groupByArray } from '../../../utils/arrayFunc';

export const CardPicker: FC = observer(() => {
  const {
    selectCard,
    pickedCard,
    pickCard,
    selectedRows,
    isUuidPresentInTimetableCard,
    freeCards,
    gridContext,
    placedCards,
  } = useTeachingBlockStore();

  const getCards = () => {
    if (gridContext === 'rooms') {
      return placedCards.filter((c) => c.rooms.length === 0);
    }
    return selectedRows.length > 0
      ? freeCards.filter((card) => {
          return selectedRows.some((row) => {
            return isUuidPresentInTimetableCard(card, row.value);
          });
        })
      : freeCards;
  };

  const cards: TeachingBlockCardType[][] = [];
  const lessonGroups = groupByArray(getCards(), (card) => card.lesson?.uuid);

  lessonGroups.forEach((lessonGroup) => {
    const durationGroups = groupByArray(lessonGroup, (group) => group.duration);
    durationGroups.forEach((durationGroup) => cards.push(durationGroup));
  });

  // sort by subject-name
  const stacks: TeachingBlockCardType[][] = cards.sort((a, b) =>
    (a[0].subject?.name ?? '').localeCompare(b[0].subject?.name ?? ''),
  );

  const onCardClick = (event: MouseEvent<HTMLDivElement>, card: TeachingBlockCardType) => {
    event.stopPropagation();
    event.preventDefault();
    if (!card.locked) {
      pickCard(card, { x: event.clientX, y: event.clientY }, true);
    }
  };

  return (
    <div className={classNames(styles['card-picker'], 'has-scrollbar', 'drop-and-delete')}>
      {stacks.map((container) => {
        const card = container[0];
        const cardClasses = classNames({
          [styles.hidden]: container.length === 1 && pickedCard?.card === card,
          [styles.multi]: container.length >= 2,
        });

        return (
          <TeachingBlockCard
            className={cardClasses}
            card={card}
            count={container.length}
            key={card.uuid}
            onClick={onCardClick}
            onHover={(isHovering, card) => {
              if (!isHovering && pickedCard) {
                selectCard(pickedCard.card);
              } else {
                selectCard(card);
              }
            }}
            passive={false}
          />
        );
      })}
    </div>
  );
});
