import {
  TimeGridEntriesByTimeGridDocument,
  TimeGridEntriesByTimeGridQuery,
  TimeGridEntriesByTimeGridQueryVariables,
  useUpdateTimeGridEntriesMutation,
} from '../types/planung-graphql-client-defs';
import { urqlClient } from '../utils/urqlClient';

export const useUpdateTimegridOrder = (gridUuid: string) => {
  const [, update] = useUpdateTimeGridEntriesMutation();

  const updateOrders = async () => {
    const { data } = await urqlClient
      .query<TimeGridEntriesByTimeGridQuery, TimeGridEntriesByTimeGridQueryVariables>(
        TimeGridEntriesByTimeGridDocument,
        {
          timeGridUuid: gridUuid,
        },
        { requestPolicy: 'network-only' },
      )
      .toPromise();

    const promises: Promise<unknown>[] = [];
    const entries = data?.timeGridEntries ?? [];

    for (const entry of entries) {
      const index = data?.timeGridEntries.indexOf(entry);
      promises.push(
        update({
          where: {
            uuid: entry.uuid,
          },
          update: {
            order: index,
          },
        }),
      );
    }
    await Promise.all(promises);
  };

  return { updateOrders };
};
