import { useTranslation } from 'react-i18next';
import { Skeleton } from '@bp/ui-components';
import { Page } from '../../../components/Page/Page';

export const SubstitutionsPlanPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={`${t('substitutions.title.plural')} ${t('substitutions.plan').toLowerCase()}`}>
      <Skeleton />
    </Page>
  );
};
