import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './IconMenuItem.module.scss';
import { IllustrativeIcon } from '../IllustrativeIcon/IllustrativeIcon';

type IconMenuItemProps = {
  icon: ReactNode;
  iconSize?: number;
  bubbleType?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18;
  label: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

export const IconMenuItem: FC<IconMenuItemProps> = ({
  icon,
  iconSize,
  bubbleType,
  label,
  active,
  disabled,
  onClick,
  className,
}) => {
  const classes = classNames(
    styles['icon-menu-item'],
    { [styles.active]: active, [styles.disabled]: disabled || !onClick },
    className,
  );

  return (
    <div className={classes} onClick={() => !disabled && onClick && onClick()}>
      <IllustrativeIcon
        icon={icon}
        size={iconSize}
        bubbleType={bubbleType}
        isActive={active}
        disabled={disabled || !onClick}
      />
      <span className={styles.label}>{label}</span>
    </div>
  );
};
