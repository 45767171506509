import { Form, Formik } from 'formik';
import { SchoolEventType } from '../../pages/Substitutions/Setup/SchoolEvent/SetupSchoolEvent';
import {
  Checkbox,
  ClockIcon,
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  ModalBottomButtons,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { useLoadBasicData } from '../../hooks/useLoadBasicData';
import dayjs from 'dayjs';
import { lowercaseFirstLetter } from '../../utils/helper';
import { MultiValue } from 'react-select';
import { isFirstAfterSecond, isFirstBeforeSecond } from '../../utils/dateCalculations';

export type SchoolEventFormProps = {
  selectedEvent?: SchoolEventType;
  onSubmit: () => void;
  onClose: () => void;
};

export const SchoolEventForm = ({ selectedEvent, onSubmit, onClose }: SchoolEventFormProps) => {
  const { t } = useTranslation();

  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);

  const initialValues: SchoolEventType = {
    uuid: selectedEvent?.uuid ?? '',
    subjectUuid: selectedEvent?.subjectUuid ?? '',
    name: selectedEvent?.name ?? '',
    classeUuids: selectedEvent?.classeUuids ?? [],
    groupUuids: selectedEvent?.groupUuids ?? [],
    teacherUuids: selectedEvent?.teacherUuids ?? [],
    startDate: selectedEvent?.startDate ?? new Date(),
    startTime: selectedEvent?.startTime ?? new Date(),
    endDate: selectedEvent?.endDate ?? new Date(),
    endTime: selectedEvent?.endTime ?? new Date(),
    repetition: selectedEvent?.repetition ?? null,
    isClassbookRelevant: selectedEvent?.isClassbookRelevant ?? false,
  };

  const { classesData, groupsData, divisionsData, teacherData } = useLoadBasicData({ pause: false });

  const activeTeachers = teacherData?.people.filter((p) => p.active);

  const classesOptions = useCreateSelectOptions(classesData?.classes, 'uuid', 'name');
  const teacherOptions = useCreateSelectOptions(activeTeachers, 'uuid', 'listName');
  const repetitionOptions: SelectOptionType[] = [
    {
      label: t('common.repetition.daily'),
      value: 'daily',
    },
    {
      label: t('common.repetition.weekly'),
      value: 'weekly',
    },
    {
      label: t('common.repetition.weekdays'),
      value: 'weekdays',
    },
    {
      label: t('common.repetition.annually'),
      value: 'annually',
    },
  ];

  const filteredGroupOptions: SelectOptionType[] = useMemo(() => {
    const options: SelectOptionType[] = [];
    const classes = classesData?.classes.filter((c) => selectedClasses.includes(c.uuid));

    classes?.forEach((c) => {
      const divisions = divisionsData?.divisions.filter((d) => c.divisions.map((d) => d.uuid).includes(d.uuid));
      const groups = groupsData?.groups.filter((g) =>
        divisions?.flatMap((d) => d.groupsConnection.edges.map((gc) => gc.node.uuid)).includes(g.uuid),
      );
      groups?.forEach((g) => {
        options.push({
          label: `${g.name} (${c.name})`,
          value: g.uuid,
        });
      });
    });

    return options;
  }, [selectedClasses]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ setFieldValue, values, isSubmitting, touched, setFieldTouched, resetForm, errors, setFieldError }) => {
        return (
          <Form>
            <Grid useFormGap>
              <GridRow>
                <GridColumn width={10}>
                  <GridRow spacingBottom='s'>
                    <Input
                      name='name'
                      label={t('common.name')}
                      value={values.name}
                      required
                      onChange={(field) => {
                        setFieldTouched('name');
                        setFieldValue('name', field.target.value);
                      }}
                    />
                  </GridRow>
                  <GridRow spacingTop='s' spacingBottom='s'>
                    <GridColumn width={6}>
                      <Select
                        name='classes'
                        isMulti
                        isSearchable
                        isClearable
                        menuPosition='fixed'
                        label={t('classes.title.singular')}
                        options={classesOptions}
                        onChange={async (e) => {
                          await setFieldValue('classes', e);
                          const opt = e as MultiValue<SelectOptionType>;
                          setFieldTouched('classes');
                          setSelectedClasses(opt.map((o) => o.value as string));
                        }}
                      />
                    </GridColumn>
                    <GridColumn width={6}>
                      <Select
                        name='groups'
                        isMulti
                        isSearchable
                        isClearable
                        menuPosition='fixed'
                        label={t('groups.title.singular')}
                        options={selectedClasses.length <= 0 ? [] : filteredGroupOptions}
                        onChange={async (e) => {
                          setFieldTouched('groups');
                          await setFieldValue('groups', e);
                        }}
                        placeholder={lowercaseFirstLetter(t('schoolEvents.noClassesHint'))}
                      />
                    </GridColumn>
                  </GridRow>
                  <GridRow spacingTop='s'>
                    <GridColumn width={12}>
                      <Select
                        name='teachers'
                        isMulti
                        isClearable
                        isSearchable
                        menuPosition='fixed'
                        label={t('persons.title.singular')}
                        options={teacherOptions}
                        onChange={async (e) => {
                          setFieldTouched('teachers');
                          await setFieldValue('teachers', e);
                        }}
                      />
                    </GridColumn>
                  </GridRow>
                </GridColumn>
                <GridColumn width={2}>
                  <Checkbox
                    className='mt-3'
                    name='classbookRelevant'
                    label={t('schoolEvents.classbookRelevant')}
                    onChange={async (e) => {
                      setFieldTouched('classbookRelevant');
                      await setFieldValue('classbookRelevant', e);
                    }}
                  />
                </GridColumn>
              </GridRow>
              <GridRow headline={t('common.timeFrame')}>
                <GridColumn width={10}>
                  <GridRow spacingBottom='s'>
                    <GridColumn width={4}>
                      <DatePicker
                        name='startDate'
                        value={values.startDate}
                        label={t('common.from')}
                        onChange={async (e) => {
                          await setFieldTouched('startDate');
                          await setFieldValue('startDate', e);
                          if (isFirstAfterSecond(e, values.endDate)) {
                            setFieldError('startDate', t('validation.common.startDateAfterEnd'));
                          }
                        }}
                        error={errors.startDate as string}
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <DatePicker
                        name='endDate'
                        value={values.endDate}
                        label={t('common.until')}
                        onChange={async (e) => {
                          await setFieldTouched('endDate');
                          await setFieldValue('endDate', e);
                          if (isFirstBeforeSecond(e, values.startDate)) {
                            setFieldError('endDate', t('validation.common.endDateAfterStart'));
                          }
                        }}
                        error={errors.endDate as string}
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <Select
                        name='repetition'
                        placeholder={t('common.none')}
                        isClearable
                        label={t('schoolEvents.repetition')}
                        options={repetitionOptions}
                        onChange={async (e) => {
                          setFieldTouched('repetition');
                          await setFieldValue('repetition', e);
                        }}
                      />
                    </GridColumn>
                  </GridRow>
                  <GridRow spacingTop='s'>
                    <GridColumn width={4}>
                      <Input
                        name='startTime'
                        label={t('common.start')}
                        value={dayjs(values.startTime).format('HH:mm')}
                        onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                          const newTime = dayjs(values.startDate).format('YYYY-MM-DD') + ':' + event.target.value;
                          setFieldTouched('startTime');
                          await setFieldValue('startTime', newTime);
                        }}
                        type='time'
                        suffix={<ClockIcon style={{ color: 'var(--color-grey-dark)' }} />}
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <Input
                        name='endTime'
                        label={t('common.end')}
                        value={dayjs(values.endTime).format('HH:mm')}
                        onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                          const newTime = dayjs(values.endDate).format('YYYY-MM-DD') + ':' + event.target.value;
                          setFieldTouched('endTime');
                          await setFieldValue('endTime', newTime);
                        }}
                        type='time'
                        suffix={<ClockIcon style={{ color: 'var(--color-grey-dark)' }} />}
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <></>
                    </GridColumn>
                  </GridRow>
                </GridColumn>
                <GridColumn width={2}>
                  <></>
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  onClose();
                  resetForm();
                },
              }}
              submitButton={{
                disabled: Object.keys(errors).length > 0 || Object.keys(touched).length === 0 || isSubmitting,
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
