import * as Yup from 'yup';
import { t } from 'i18next';

export const validationSchema = Yup.object().shape({
  uuid: Yup.string(),
  grade: Yup.number()
    .defined(t('validation.class.gradeRange'))
    .nullable()
    .oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], t('validation.class.gradeRange')),
  gradeGroupUuid: Yup.string().nullable(),
  name: Yup.string().required(t('validation.class.nameRequired')),
  shortName: Yup.string().required(t('validation.class.shortNameRequired')),
  printPageNr: Yup.number().moreThan(0, t('validation.class.printPageNrMin')),
  printOrder: Yup.number().moreThan(0, t('validation.class.printOrderMin')),
  tutorUuid_1: Yup.string()
    .nullable()
    .test('is_tutor', 'Person is always tutor', (value, context) => {
      return !value || (context.parent.tutorUuid_2 !== value && context.parent.tutorUuid_3 !== value);
    }),
  tutorUuid_2: Yup.string()
    .nullable()
    .test('is_tutor', 'Person is always tutor', (value, context) => {
      return !value || (context.parent.tutorUuid_1 !== value && context.parent.tutorUuid_3 !== value);
    }),
  tutorUuid_3: Yup.string()
    .nullable()
    .test('is_tutor', 'Person is always tutor', (value, context) => {
      return !value || (context.parent.tutorUuid_1 !== value && context.parent.tutorUuid_2 !== value);
    }),
});
