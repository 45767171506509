import { Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { selectedToData } from '../../../utils/selectedToData';
import { TeachingBlockVersionTableType } from '../graphql/types';
import { useTeachingBlockStore } from '../../TeachingBlockGrid/TeachingBlockContext';
import { observer } from 'mobx-react-lite';
import { use_SubjectContainersQuery, use_TeachingBlocksQuery } from '../../../types/planung-graphql-client-defs';
import { hexToColorOption } from '../../../utils/colorUtils';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';

export const TeachingBlockVersionImportClasses = observer(
  ({
    versionUuid,
    onClose,
    importClasses,
  }: {
    versionUuid: string;
    onClose: () => void;
    importClasses: (uuids: string[]) => void;
  }) => {
    const store = useTeachingBlockStore();
    const versionClassesUuids: string[] = Array.from(store.classes.keys());

    const [{ data: teachingBlockResult }] = use_TeachingBlocksQuery({
      variables: {
        where: {
          versions_SOME: {
            uuid: versionUuid,
          },
        },
      },
    });

    const [{ data: subjectContainerResult }] = use_SubjectContainersQuery({
      variables: {
        where: {
          uuid: teachingBlockResult?.teachingBlocks[0].subjectContainer.uuid,
        },
      },
    });

    const grades = subjectContainerResult?.subjectContainers.flatMap((sc) => sc.grades);
    const { classesData } = useLoadBasicData({ pause: !subjectContainerResult });
    const classTableData: TeachingBlockVersionTableType[] = useMemo(() => {
      return (
        classesData?.classes
          .filter((c) => !versionClassesUuids.includes(c.uuid))
          .filter((c) => c.grade && grades?.includes(c.grade))
          .map((c) => {
            const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');

            return {
              color: {
                color: html,
                label,
              },
              count: 0,
              name: c.name,
              shortName: c.shortName,
              uuid: c.uuid,
            };
          }) ?? []
      );
    }, [classesData?.classes, grades, versionClassesUuids]);

    const { t } = useTranslation();
    const [selected, setSelected] = useState<Record<string, boolean>>({});

    function createColumns(): TableColumns<TeachingBlockVersionTableType>[] {
      return [
        {
          header: t('common.name'),
          accessorKey: 'name',
          id: 'name',
          size: 250,
        },
        {
          header: t('classes.shortName'),
          accessorKey: 'shortName',
          id: 'shortName',
          size: 75,
        },
        {
          header: t('classes.level.short'),
          meta: {
            filterName: t('classes.level.full'),
            tooltip: t('classes.level.full'),
          },
          id: 'grade',
          size: 75,
          accessorFn: (row) => {
            return (row.grade || row.grade === 0) && row.grade >= 0 ? row.grade : '-';
          },
          cell: ({ row }) => {
            return (row.original.grade || row.original.grade === 0) && row.original.grade >= 0
              ? row.original.grade
              : '-';
          },
        },
      ];
    }

    const tableColumns = useMemo(() => createColumns(), []);

    return (
      <>
        <Table<TeachingBlockVersionTableType>
          rowSelection={selected}
          onRowSelectionChange={setSelected}
          showSelected={true}
          columns={tableColumns}
          data={classTableData}
          showSelect={true}
          showActionBar={true}
        />
        <ModalBottomButtons
          closeButton={{
            text: t('common.cancel'),
            callback: () => {
              onClose();
            },
          }}
          submitButton={{
            disabled: selected ? Object.keys(selected).length === 0 : true,
            text: t('common.import'),
            callback: () => {
              const selectedClasses = selectedToData<TeachingBlockVersionTableType>(selected, classTableData);
              importClasses(selectedClasses.map((c) => c.uuid));
            },
          }}
        />
      </>
    );
  },
);
