import { Grid, GridRow } from '@bp/ui-components';
import { t } from 'i18next';
import { TimetablePrintConfigurationBaseData } from './TimetablePrintConfigurationBaseData/TimetablePrintConfigurationBaseData';
import { TimetablePrintConfigurationGrid } from './TimetablePrintConfigurationGrid/TimetablePrintConfigurationGrid';
import {
  TimetablePrintColorOrigins,
  TimetablePrintColorPositions,
  TimetablePrintLayoutOptions,
} from '@bp/planung-graphql-types';

export type TimetablePrintBaseData = {
  color: string;
  colorPosition: TimetablePrintColorPositions;
  colorOrigin: TimetablePrintColorOrigins;
  printClasses: boolean;
  scaleText: boolean;
  exactMinute: boolean;
  printSubtitle: boolean;
  printLogo: boolean;
};

export enum TimetablePrintConfigurationOptions {
  printClasses = 'printClasses',
  scaleText = 'scaleText',
  exactMinute = 'exactMinute',
  printSubtitle = 'printSubtitle',
  printLogo = 'printLogo',
}

export enum TimetablePrintLayoutPositions {
  topLeft = 'topLeft',
  topCenter = 'topCenter',
  topRight = 'topRight',
  center = 'center',
  bottomLeft = 'bottomLeft',
  bottomCenter = 'bottomCenter',
  bottomRight = 'bottomRight',
}

export type TimetablePrintLayoutData = {
  topLeft: TimetablePrintLayoutOptions;
  topCenter: TimetablePrintLayoutOptions;
  topRight: TimetablePrintLayoutOptions;
  center: TimetablePrintLayoutOptions;
  bottomLeft: TimetablePrintLayoutOptions;
  bottomCenter: TimetablePrintLayoutOptions;
  bottomRight: TimetablePrintLayoutOptions;
};

type TimetablePrintConfigurationProps = {
  baseData: TimetablePrintBaseData;
  layoutData: TimetablePrintLayoutData;
  onLayoutChange: (data: TimetablePrintLayoutData) => void;
  onBaseDataChange: (data: TimetablePrintBaseData) => void;
};

export const TimetablePrintConfiguration = ({
  onLayoutChange,
  onBaseDataChange,
  baseData,
  layoutData,
}: TimetablePrintConfigurationProps) => {
  const subjectColor = '#C0CEF2';
  const teacherColor = '#F2C1C0';
  const roomColor = '#C8F2C0';
  const classColor = '#FAECB9';
  const white = '#f7f7f7';

  function handleColorOriginChange(origin: TimetablePrintColorOrigins) {
    let color = subjectColor;
    switch (origin) {
      case TimetablePrintColorOrigins.Teacher:
        color = teacherColor;
        break;
      case TimetablePrintColorOrigins.Room:
        color = roomColor;
        break;
      case TimetablePrintColorOrigins.Class:
        color = classColor;
        break;
      case TimetablePrintColorOrigins.Subject:
        color = subjectColor;
        break;
      case TimetablePrintColorOrigins.None:
        color = white;
        break;
    }
    onBaseDataChange({ ...baseData, colorOrigin: origin, color });
  }

  function handleColorPositionChange(position: TimetablePrintColorPositions) {
    onBaseDataChange({ ...baseData, colorPosition: position });
  }

  function handleConfigChange(config: TimetablePrintConfigurationOptions, value: boolean) {
    onBaseDataChange({
      ...baseData,
      [config]: value,
    });
  }

  function handleLayoutChange(position: TimetablePrintLayoutPositions, option: TimetablePrintLayoutOptions | null) {
    onLayoutChange({
      ...layoutData,
      [position]: option,
    });
  }

  return (
    <Grid>
      <GridRow spacingTop='none' spacingBottom='l'>
        <TimetablePrintConfigurationBaseData
          data={baseData}
          onOriginChange={handleColorOriginChange}
          onPositionChange={handleColorPositionChange}
          onConfigChange={handleConfigChange}
        />
      </GridRow>
      <GridRow headline={t('timetable.configuration.positions')}>
        <TimetablePrintConfigurationGrid
          data={layoutData}
          color={baseData.color}
          colorPosition={baseData.colorPosition}
          onLayoutChange={handleLayoutChange}
        />
      </GridRow>
    </Grid>
  );
};
