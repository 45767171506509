import * as yup from 'yup';

yup.addMethod(yup.array, 'uniqueIn', function (mapper = (a: unknown) => a, message = '') {
  return this.test('unique', message, function (list: unknown[]) {
    return list?.length === new Set(list?.map(mapper)).size;
  });
});

// eslint-disable-next-line import/no-default-export
export default yup;
