import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeachingLoadReports } from '../../../../components/EvaluateTeachingLoad/TeachingLoadReports';
import { TeachingLoadReportType } from '@bp/planung-graphql-types';

export const PlannedReports = () => {
  const { t } = useTranslation();

  const tableData = useMemo(() => {
    return [
      { id: TeachingLoadReportType.Curriculum, name: t('common.notYet', { planned: t('pdf.plannedTeachingLoad') }) },
      { id: TeachingLoadReportType.PlanSingleTeacher, name: t('pdf.plannedDetails') },
      { id: TeachingLoadReportType.PlanOverview, name: t('pdf.plannedOverview') },
      { id: TeachingLoadReportType.PlanSubjectGroup, name: t('pdf.plannedSubjectGroups') },
      { id: TeachingLoadReportType.PlanClass, name: t('common.notYet', { planned: t('pdf.plannedClasses') }) },
      { id: TeachingLoadReportType.PlanKeyFigures, name: t('common.notYet', { planned: t('pdf.plannedKeyFigures') }) },
      { id: TeachingLoadReportType.PlanExcel, name: t('pdf.excelExport') },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TeachingLoadReports tableData={tableData} />;
};
