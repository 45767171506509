import { Button, ButtonGroup, DeleteIcon, EditIcon, Modal, Row, Table, TableColumns } from '@bp/ui-components';
import { FC, useMemo, useState } from 'react';
import { BuildingsListType } from '../graphgl/types';
import { useBuildingsListQuery, useDeleteBuildingsMutation } from '../../../types/planung-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { BuildingsForm } from '../Forms/BuildingsForm';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { showSuccessDeleteToast, showUserErrorToast } from '../../../utils/toast';

export const DeputateBuildingsTable: FC = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const context = useMemorizedCacheTag('BUILDINGS');
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('deputate-buildings-list');
  const { sorting, saveSorting } = useColumnsSort('deputate-buildings-list');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [buildingUuid, setBuildingUuid] = useState<string | null>(null);

  const [, deleteBuilding] = useDeleteBuildingsMutation();

  const [{ data }] = useBuildingsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  const memoizedData = useMemo((): BuildingsListType[] => {
    return data && data ? data.buildings : [];
  }, [data]);

  function tableColumns(): TableColumns<BuildingsListType>[] {
    return [
      {
        header: t('common.name') as string,
        id: 'name',
        accessorKey: 'name',
        size: 450,
      },
      {
        header: t('common.description') as string,
        id: 'description',
        accessorKey: 'description',
        size: 450,
        canExpand: true,
      },
      {
        header: t('common.active.full') as string,
        accessorKey: 'active',
        id: 'active',
        type: 'active',
      },
    ];
  }

  const columns = useMemo(tableColumns, []);

  const onModalClose = () => {
    setBuildingUuid(null);
    setIsModalOpen(false);
  };

  const handleEdit = (buildingsRow: Row<BuildingsListType>) => {
    setBuildingUuid(buildingsRow.original.uuid);
    setIsModalOpen(true);
  };

  async function handleDelete(row: Row<BuildingsListType>) {
    if (!row.original.uuid) {
      return;
    }
    const response = await deleteBuilding(
      {
        uuid: row.original.uuid,
      },
      context,
    );

    if (response.error) {
      showUserErrorToast({ error: response.error });
    } else {
      showSuccessDeleteToast([row.original.name]);
    }
  }

  return (
    <>
      <Table<BuildingsListType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={600}
        columns={columns}
        data={memoizedData}
        sorting={sorting}
        onSortingChange={saveSorting}
        showActionBar
        actionBarSettings={{
          showAddButton: true,
        }}
        isOnWhite={false}
        showVisibility
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('buildings.title')} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('buildings.title')}_${currentSchoolYear?.shortName}`,
        }}
        showSelect={false}
        onAddClick={() => {
          setIsModalOpen(true);
        }}
        lastColWidth='80px'
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                type='button'
                onClick={() => handleEdit(row)}
                icon={<EditIcon className='small' />}
              />
              <Button
                hierarchy='secondary'
                type='button'
                onClick={() => handleDelete(row)}
                icon={<DeleteIcon className='small' />}
              />
            </ButtonGroup>
          );
        }}
      />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={onModalClose}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          title={buildingUuid ? (t('buildings.edit') as string) : (t('buildings.add') as string)}
        >
          <BuildingsForm handleClose={onModalClose} buildingUuid={buildingUuid} />
        </Modal>
      )}
    </>
  );
});
