import { AddIcon, Button, Grid, GridRow, Select, SelectOptionType } from '@bp/ui-components';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { SubjectContainerType } from '../../Teachingblock/graphql';
import { Formik, FormikHelpers } from 'formik';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { useTeachingBlockQuery } from '../../../types/planung-graphql-client-defs';
import styles from './TimetableBlockForm.module.scss';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';

type TimetableSubjectContainerFormProps = {
  onClose: () => void;
};

export const TimetableSubjectContainerForm = ({ onClose }: TimetableSubjectContainerFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const context = useMemorizedCacheTag('SUBJECT_CONTAINER');

  const [{ data }] = useTeachingBlockQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      where: {
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
      },
    },
    context,
  });

  const teachingBlocks = data?.teachingBlocks ?? [];

  const containerOptions = useCreateSelectOptions(teachingBlocks, 'uuid', 'name');

  const defaultValue: {
    subjectContainer: SubjectContainerType | undefined;
  } = {
    subjectContainer: undefined,
  };

  async function handleSubmit(
    values: {
      subjectContainer: SubjectContainerType | undefined;
    },
    formHelpers: FormikHelpers<{
      subjectContainer: SubjectContainerType | undefined;
    }>,
  ) {
    console.log(values);
  }

  return (
    <div className={styles['timetable-subject-container-form']}>
      <Formik initialValues={defaultValue} onSubmit={handleSubmit}>
        {({ values, setFieldValue, resetForm, isSubmitting, isValidating, dirty, errors }) => {
          return (
            <Form>
              <Grid useFormGap>
                <GridRow spacingBottom='s'>
                  <Select
                    name='subjectContainer'
                    isSearchable
                    isClearable
                    label={t('subjectContainer.titleSingular')}
                    value={containerOptions.find((option) => option.value === values?.subjectContainer)}
                    options={containerOptions}
                    onChange={async (option) => {
                      const opt = option as SingleValue<SelectOptionType>;
                      await setFieldValue('subjectContainer', opt);
                    }}
                  />
                </GridRow>
                <GridRow spacingTop='s'>
                  <Button className={styles['add-button']} hierarchy='tertiary' icon={<AddIcon />}>
                    {t('subjectContainer.activateAdditional')}
                  </Button>
                </GridRow>
              </Grid>

              <ModalBottomButtons
                closeButton={{
                  callback: () => {
                    resetForm();
                    onClose();
                  },
                  text: t('common.cancelChanges'),
                }}
                submitButton={{ disabled: isSubmitting || !dirty || isValidating }}
                isLoading={isSubmitting}
                errors={errors}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
