import { useTranslation } from 'react-i18next';
import { ModalBottomButtons } from '../../../ModalBottomButtons/ModalBottomButtons';
import { LessonTableType } from '../TimetableVersionLessonsTable';
import { Table, TableColumns } from '@bp/ui-components';
import { useMemo } from 'react';
import styles from '../../LessonsComponents.module.scss';
import { useDeputateLessons } from '../../hooks/useDeputateLessons';

type AssignTeacherProps = {
  teacher: {
    uuid: string;
    displayName?: string | null;
  };
  lessonUuid: string;
  onClose: () => void;
};
export const AssignTeacher = ({ teacher, lessonUuid, onClose }: AssignTeacherProps) => {
  const { t } = useTranslation();

  const { lessonsData, assignLessons, loadingState } = useDeputateLessons({});

  const lesson = lessonsData.find((l) => l.uuid === lessonUuid);

  const tableColumns = useMemo((): TableColumns<LessonTableType>[] => {
    return [
      {
        header: t('lesson.table.subject'),
        id: 'subject',
        accessorKey: 'subject',
        meta: {
          filterName: t('lesson.table.subject'),
        },
        size: 100,
        canExpand: true,
      },
      {
        header: t('lesson.table.classesGroups'),
        id: 'groupClassNames',
        accessorKey: 'groupClassNames',
        size: 100,
        canExpand: true,
      },
      {
        header: t('deputate.titleSingluar'),
        id: 'deputate.titleSingluar',
        cell: ({ row }) => {
          const lessonInfo = row.original.lessonInfo;
          // TODO: Work in Progress, what is contextUuid?!
          const uuid = 'contextUuid';

          // relevant deputate
          const td = lessonInfo?.teacherInfos.get(uuid)?.deputat ?? 0;
          const cd = lessonInfo?.classInfos.get(uuid)?.deputat ?? 0;
          const d = lessonInfo?.deputat ?? 0;
          return (td + cd + d).toFixed(2);
        },
        alignment: 'right',
        size: 75,
      },
    ];
  }, []);

  return (
    <div>
      {t('lesson.assignLessonToPerson', { name: teacher.displayName })}

      {lesson && (
        <Table
          className={styles['open-lessons-table']}
          showBorderRadius
          showShadow={false}
          isOnWhite={false}
          customPadding={'0'}
          canScroll={false}
          columns={tableColumns}
          data={[lesson]}
          emptyStateSettings={{ size: 'small', padding: 's' }}
          loading={loadingState.some((ls) => ls.uuid === lessonUuid && ls.loading)}
        />
      )}

      <ModalBottomButtons
        closeButton={{ callback: onClose }}
        isLoading={false}
        errors={false}
        submitButton={{
          text: t('common.assign'),
          callback: async (event) => {
            await assignLessons([lessonUuid], [{ uuid: teacher.uuid }]);
            onClose();
          },
        }}
      ></ModalBottomButtons>
    </div>
  );
};
