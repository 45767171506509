import { useMemo } from 'react';
import { SelectOptionType } from '@bp/ui-components';

export const useCreateNumbersSelectValues = () => {
  return useMemo(() => {
    const values: SelectOptionType[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v, k) => {
      return { value: v, label: v.toString() };
    });
    return values;
  }, []);
};
