import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteDivisionsMutation, useDivisionsQuery } from '../../types/planung-graphql-client-defs';
import {
  AlertDialog,
  Button,
  ButtonGroup,
  DeleteIcon,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { DivisionsForm } from './Forms/DivisionsForm';
import { DivisionsListDataType, DivisionsListGroupsEdgesType } from './graphql/types';
import styles from './Divisions.module.css';
import { useHiddenColumns } from '../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { showSuccessDeleteToast, showUserErrorToast } from '../../utils/toast';

export const DivisionsTable = () => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [divisionUuid, setDivisionUuid] = useState<null | string>(null);

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('divisions-list');
  const { sorting, saveSorting } = useColumnsSort('divisions-list');

  const context = useMemorizedCacheTag('DIVISIONS');

  const [divisionsResult] = useDivisionsQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      classCount: 0,
    },
    context,
  });

  const { data, fetching, error } = divisionsResult;
  const [, deleteDivisions] = useDeleteDivisionsMutation();

  async function handleDelete(row: Row<DivisionsListDataType>) {
    if (!row.original.uuid) {
      return;
    }
    const response = await deleteDivisions(
      {
        where: {
          uuid: row.original.uuid,
        },
      },
      context,
    );

    if (response.error) {
      showUserErrorToast({ error: response.error });
    } else {
      showSuccessDeleteToast([row.original.name]);
    }
  }

  function createColumns(): TableColumns<DivisionsListDataType>[] {
    return [
      {
        header: t('common.type'),
        accessorKey: 'name',
        id: 'name',
        size: 200,
      },
      {
        id: 'totalCount',
        header: t('divisions.groupCount') as string,
        accessorKey: 'groupsConnection.totalCount',
        enableGlobalFilter: false,
        enableColumnFilter: false,
        size: 200,
      },
      {
        id: 'groupsConnections',
        header: t('common.group_other') as string,
        accessorKey: 'groupsConnection.edges',
        enableGlobalFilter: false,
        enableColumnFilter: false,
        canExpand: true,
        size: 400,
        cell: ({ row }) => {
          const value: DivisionsListGroupsEdgesType = row.original.groupsConnection.edges;
          return (
            <div className={styles['bars-wrapper']}>
              {value.map((group, index) => {
                return (
                  <div key={group.node.uuid} className={styles['bars-item']}>
                    {group.node.shortName}
                  </div>
                );
              })}
            </div>
          );
        },
      },
    ];
  }

  const tableColumns = useMemo(createColumns, []);

  const onModalClose = () => {
    setDivisionUuid(null);
    setIsModalOpen(false);
  };

  const handleEdit = (row: Row<DivisionsListDataType>) => {
    setDivisionUuid(row.original.uuid ?? null);
    setIsModalOpen(true);
  };

  const memoizedData = useMemo((): DivisionsListDataType[] => {
    return data ? data.divisions : [];
  }, [data]);

  return (
    <>
      {!error && !fetching && (
        <Table<DivisionsListDataType>
          showBorderRadius
          showShadow
          canScroll
          minHeight={600}
          onColumnVisibilityChange={saveColumnVisibility}
          columnVisibility={columnVisibility}
          sorting={sorting}
          onSortingChange={saveSorting}
          columns={tableColumns}
          data={memoizedData}
          showActionBar
          actionBarSettings={{
            showAddButton: true,
          }}
          isOnWhite={false}
          onAddClick={() => {
            setDivisionUuid(null);
            setIsModalOpen(true);
          }}
          lastColWidth='80px'
          lastCol={(divisionRow) => {
            return (
              <ButtonGroup>
                <Button
                  hierarchy='secondary'
                  type='button'
                  onClick={() => handleEdit(divisionRow)}
                  icon={<EditIcon className='small' />}
                ></Button>
                <AlertDialog
                  title={t('divisions.deleteConfirm', {
                    type: divisionRow.original.name,
                  })}
                  confirmText={t('common.confirmDelete')}
                  cancelText={t('common.cancelDelete')}
                  onConfirm={() => handleDelete(divisionRow)}
                  trigger={<Button hierarchy='secondary' type='button' icon={<DeleteIcon className='small' />} />}
                />
              </ButtonGroup>
            );
          }}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        title={divisionUuid ? t('divisions.edit-default') : t('divisions.create-default')}
      >
        <DivisionsForm cacheTag={'DIVISIONS'} closeForm={onModalClose} divisionUuid={divisionUuid}></DivisionsForm>
      </Modal>
    </>
  );
};
