import { useTranslation } from 'react-i18next';
import styles from '../../Constraints.module.scss';
import { Button, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { ConstraintsFormInitialValuesType } from '../../types';
import { useCreateNumbersSelectValues } from '../../../../hooks/useCreateNumbersSelectValues';
import { usePriorityOptions } from '../../../../hooks/usePriorityOptions';
import classNames from 'classnames';

type HourCountConditionItemProps = {
  onRemove: () => void;
};

export const MinMaxHoursConstraintItem = ({ onRemove }: HourCountConditionItemProps) => {
  const { t } = useTranslation();
  const { setFieldValue, setFieldTouched, values } = useFormikContext<ConstraintsFormInitialValuesType>();

  const options = useCreateNumbersSelectValues();
  const priorityOptions = usePriorityOptions();

  const handleRemove = () => {
    setFieldValue('minMaxHours', null);
    setFieldTouched('minMaxHours', true);
    setFieldTouched('minMaxHoursPriority', false);
    setFieldValue('minMaxHoursPriority', null);
    onRemove();
  };

  return (
    <>
      <div className={styles['title']}>{t('conditions.hourCount.label')}</div>
      <div className={styles['row-wrapper']}>
        <div className='tks__row row-xs-11 mr-5'>
          <div className='tks__col col-xs-6'>
            <Select
              isClearable={true}
              options={options}
              onChange={(value) => {
                const opt = value as SingleValue<SelectOptionType>;
                setFieldTouched('minMaxHours.min', true);
                setFieldValue('minMaxHours.min', opt?.value);
              }}
              value={values.minMaxHours?.min !== undefined ? options[values.minMaxHours.min] : null}
              label={t('common.minimum.short') as string}
              name={'minMaxHours.min'}
            />
          </div>
          <div className='tks__col col-xs-6'>
            <Select
              isClearable={true}
              options={options}
              onChange={(value) => {
                const opt = value as SingleValue<SelectOptionType>;
                setFieldTouched('minMaxHours.max', true);
                setFieldValue('minMaxHours.max', opt?.value);
              }}
              value={values.minMaxHours?.max !== undefined ? options[values.minMaxHours.max] : null}
              label={t('common.maximum.short') as string}
              name={'minMaxHours.max'}
            />
          </div>
        </div>
        <div className='tks__col col-xs-1'>
          <Select
            className={classNames(styles['priority-icon-select'], styles[`icon-${values.minMaxHoursPriority}`])}
            options={priorityOptions}
            value={priorityOptions.find((option) => option.value === values.minMaxHoursPriority)}
            onChange={(value) => {
              const opt = value as SingleValue<SelectOptionType>;
              setFieldTouched('minMaxHoursPriority', true);
              setFieldValue('minMaxHoursPriority', opt?.value);
            }}
            label={t('common.priority.full') as string}
            name={'minMaxHoursPriority'}
            fitMenuToOptions
            showIconOnly
          />
        </div>
        <div className={styles['button-wrapper']}>
          <Button name='remove' hierarchy='secondary' onClick={handleRemove} icon={<DeleteIcon />} />
        </div>
      </div>
    </>
  );
};
