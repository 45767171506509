import { Select, SelectOptionType } from '@bp/ui-components';
import { Form, Formik } from 'formik';
import React from 'react';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { PimProfile, useUpdatePeopleMutation } from '../../../types/planung-graphql-client-defs';
import { SingleValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import { showErrorToast, showSuccessCreateToast } from '../../../utils/toast';

type PimProfileType = Omit<PimProfile, 'organization' | 'organizationConnection'>[] | undefined;
type ConnectPimProfileFormProps = {
  onClose: () => void;
  pimProfiles: PimProfileType;
  uuid: string | undefined;
  context: { additionalTypenames: string[] };
};

type ConnectPimProfileFormValues = {
  pimProfileUuid: string;
};

export const ConnectPimProfileForm = ({ pimProfiles, onClose, uuid, context }: ConnectPimProfileFormProps) => {
  const { t } = useTranslation();

  const [, updatePerson] = useUpdatePeopleMutation();

  const onSubmit = async (values: ConnectPimProfileFormValues) => {
    if (uuid) {
      const { error } = await updatePerson(
        {
          where: {
            uuid: uuid,
          },
          update: {
            pimProfileUuid: values.pimProfileUuid,
          },
        },
        context,
      );
      error ? showErrorToast(error) : showSuccessCreateToast([]);

      !error && onClose();
    }
  };

  const options: SelectOptionType[] = useCreateSelectOptions(pimProfiles, 'uuid', 'selectName');

  const initialValues: ConnectPimProfileFormValues = {
    pimProfileUuid: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={true}
      onSubmit={async (values, formikHelpers) => {
        formikHelpers.resetForm();
        await onSubmit(values);
      }}
    >
      {({ errors, isSubmitting, setFieldValue, setFieldTouched, touched }) => (
        <Form>
          <Select
            options={options}
            name={'profile'}
            label={t('profile.chooseProfile')}
            onChange={async (option) => {
              const opt = option as SingleValue<SelectOptionType>;
              await setFieldValue('pimProfileUuid', opt?.value);
              opt?.value && (await setFieldTouched('pimProfileUuid', true));
            }}
          />
          <ModalBottomButtons
            closeButton={{
              callback: onClose,
            }}
            submitButton={{
              disabled: !touched.pimProfileUuid,
            }}
            isLoading={isSubmitting}
            errors={errors}
          />
        </Form>
      )}
    </Formik>
  );
};
