import {
  Curriculum,
  LessonsQuery,
  Subject,
  TeachingBlockLessonsQuery,
  TimetableVersionsQuery,
} from '../types/planung-graphql-client-defs';
import { LessonFormType, LessonFormUnitType, VersionLessonFormType } from '../components/Lessons/types';
import { Day } from '../components/AvailabilityMatrix/AvailabilityMatrix';
import { FilterLoaderArgs } from './routes-loader/filterLoader';
import { HolidayType } from '../components/Holidays/graphql';
import { BadgeCardWidth } from '@bp/ui-components';

export function isPlainOldObject(possibleObject?: unknown): possibleObject is Record<string, unknown | undefined> {
  return !!possibleObject && typeof possibleObject === 'object' && !Array.isArray(possibleObject);
}

export function isFilterType(obj: unknown): obj is FilterLoaderArgs {
  return (
    isNotEmpty(obj) && isPlainOldObject(obj) && typeof obj.column === 'string' && typeof obj.filterValue === 'string'
  );
}

export function isArrayOf<T>(array: unknown[]): array is T[] {
  return Array.isArray(array) && array.every((a) => isNotEmpty(a));
}

export function isNotEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function isVersionData(obj: unknown): obj is TimetableVersionsQuery {
  return isNotEmpty(obj) && isPlainOldObject(obj) && Array.isArray(obj.timetableVersions);
}

export function isData(obj: unknown): obj is { data: unknown } {
  return isNotEmpty(obj) && isPlainOldObject(obj) && typeof obj.data === 'object';
}

export function hasDurationCountArray(
  obj: unknown,
): obj is Required<Pick<VersionLessonFormType, 'uuid' | 'durationCountArray'>> {
  return isPlainOldObject(obj) && obj.durationCountArray !== undefined;
}

export function hasLessonUnit(obj: unknown): obj is LessonFormType & { lessonUnit: LessonFormUnitType[] } {
  return isPlainOldObject(obj) && obj.lessonUnit !== undefined && Array.isArray(obj.lessonUnit);
}

export function isLessonsQueryWithTimetableCards(obj: unknown): obj is LessonsQuery {
  return isPlainOldObject(obj) && obj.timetableCards !== undefined;
}

export function isLessonsQueryWithTeachingBlockCards(obj: unknown): obj is TeachingBlockLessonsQuery {
  return isPlainOldObject(obj) && obj.teachingBlockCards !== undefined;
}

export function isSubject(obj: unknown): obj is Subject {
  return isPlainOldObject(obj) && (obj.subjectGroup !== undefined || obj.__typename === 'Subject');
}

export function isCurriculum(obj: unknown): obj is Curriculum {
  return isPlainOldObject(obj) && obj.curriculumVersionUuid !== undefined;
}

export function isDay(value?: string | null): value is Day {
  if (!value) return false;
  return ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].includes(value);
}

export function getTypeChecktArray<T>(array: unknown[]): T[] {
  const _res: T[] = [];
  if (Array.isArray(array) && isArrayOf<T>(array)) {
    array.forEach((a) => {
      if (isNotEmpty(a)) {
        _res.push(a);
      }
    });
  }
  return _res;
}

export function isHoliday(value: unknown): value is HolidayType {
  return isPlainOldObject(value) && value.__typename === 'Holiday';
}

export function isBadgeCardWidth(value: unknown): value is BadgeCardWidth {
  if (Number.isInteger(value)) {
    return [0, 1, 2, 3, 4, 5, 6].includes(value as number);
  }
  return false;
}
