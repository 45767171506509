import { GridHeader } from './GridHeader/GridHeader';
import styles from './TeachingBlockGrid.module.scss';
import { GridRows } from './GridRows/GridRows';
import { useTeachingBlockStore } from './TeachingBlockContext';
import { observer } from 'mobx-react-lite';
import { CardPreview } from './CardPreview/CardPreview';
import { TimeConflictModal } from './Modals/TimeConflictModal/TimeConflictModal';
import { CardDurationModal } from './Modals/CardDurationModal/CardDurationModal';
import { CardRoomsModal } from './Modals/CardRoomsModal/CardRoomsModal';

export const TeachingBlockGrid = observer(() => {
  const { months, pickedCard, timeConflictModal } = useTeachingBlockStore();

  return (
    <div className={styles['teaching-block-grid']}>
      <GridHeader months={months} />
      <GridRows />
      {pickedCard && <CardPreview pickedCard={pickedCard} preventEscape={timeConflictModal.isOpen ?? false} />}
      <TimeConflictModal />
      <CardDurationModal />
      <CardRoomsModal />
    </div>
  );
});
