import { FC, MouseEvent, useCallback, useMemo } from 'react';
import styles from './CardPicker.module.scss';
import { observer } from 'mobx-react-lite';
import { PinboardCard } from '../../PinboardCard/PinboardCard';
import { CardType } from '../../TimetableVersion/graphql/types';
import classNames from 'classnames';
import { useTimetableStore } from '../TimetableProvider';

type CardPickerProps = {
  searchString: string;
  filterFunctionClasses: boolean;
};

export const CardPicker: FC<CardPickerProps> = observer(({ searchString, filterFunctionClasses }: CardPickerProps) => {
  const pinboardStore = useTimetableStore();

  function handleClick(e: MouseEvent) {
    e.stopPropagation();
    const pickedCard = pinboardStore.pickedCard;
    if (pickedCard) {
      if (pinboardStore.context === 'rooms') {
        pinboardStore.discardPickedCardToRoomsStack();
      } else {
        pinboardStore.discardPickedCardToStack();
      }
    }
  }
  const cardsStack = pinboardStore.getCardsStack();

  const freeCards = useMemo(() => {
    return pinboardStore.getFreeCardsCount();
  }, [pinboardStore.freeCardsSet]);

  const cardsArray = useMemo(() => {
    return cardsStack ? Array.from(cardsStack.values()) : [];
  }, [cardsStack]);

  const filteredCards = useMemo(() => {
    return cardsArray.filter((card) => {
      const subject = card[0].subject?.shortName ?? '';
      return (
        (searchString === '' || subject.toLowerCase().includes(searchString.toLowerCase())) &&
        (!filterFunctionClasses || card[0].classes.filter((c) => c.grade === null).length > 0)
      );
    });
  }, [cardsArray, filterFunctionClasses, searchString]);

  // sort by subject-name
  const stacks: CardType[][] = filteredCards.sort((a, b) =>
    (a[0].subject?.shortName ?? '').localeCompare(b[0].subject?.shortName ?? ''),
  );

  const onCardClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, card: CardType) => {
      event.stopPropagation();
      event.preventDefault();
      if (pinboardStore.pickedCard) {
        pinboardStore.discardPickedCard();
      }
      pinboardStore.pickCard(
        {
          cardUuid: card.uuid,
          cardRows: card.badgeCardRows,
          duration: card.duration ?? 1,
          counter: 1,
          fromStack: true,
          label: card.subject?.shortName ?? '',
          labelColor: card.badgeCardTextColor,
          position: { x: event.clientX, y: event.clientY },
        },
        undefined,
        true,
      );
    },
    [pinboardStore],
  );

  return (
    <div className={classNames(styles['card-picker'], 'has-scrollbar')} onClick={(e) => handleClick(e)}>
      {freeCards > 0 &&
        stacks.map((stack) => {
          const card = stack[0];
          const count = stack.length;
          const cardClasses = classNames({
            [styles.multi]: stack.length >= 2,
          });

          return (
            <PinboardCard className={cardClasses} card={card} count={count} key={card.uuid} onClick={onCardClick} />
          );
        })}
    </div>
  );
});
