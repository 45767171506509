import { useTranslation } from 'react-i18next';
import {
  use_TeachingBlocksQuery,
  useCreateSubjectContainersMutation,
  useGradeGroupsListQuery,
  useSubjectContainersListQuery,
  useUpdateSubjectContainerMutation,
} from '../../../types/planung-graphql-client-defs';
import { ColorPicker, Grid, GridColumn, GridRow, Input, Select, SelectOptionType } from '@bp/ui-components';
import { SubjectContainerListDataType } from '../graphql/types';
import { colorPickerOptions, UsedColors } from '../../../utils/colorPickerOptions';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { schema } from './validation/schema';
import { MultiValue, SingleValue } from 'react-select';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import React, { useState } from 'react';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { useGradeOptions } from '../../../hooks/useGradeOptions';
import { showSuccessSaveToast, showUserErrorToast } from '../../../utils/toast';
import { useIsUsedInLesson } from '../../../hooks/useIsUsedInLesson';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';

export type SubjectContainerFormProps = {
  subjectContainerUuid?: string | null;
  closeForm: () => void;
  htmlColorsInUse?: Array<UsedColors | null>;
};

export const SubjectContainerForm = ({
  subjectContainerUuid,
  closeForm,
  htmlColorsInUse,
}: SubjectContainerFormProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [{ data: teachingBlockData }] = use_TeachingBlocksQuery();

  const check = useIsUsedInLesson();
  const usedInTeachingBlock = subjectContainerUuid
    ? teachingBlockData?.teachingBlocks?.findIndex((tb) => tb.subjectContainer.uuid === subjectContainerUuid) !== -1
    : false;
  const usedInLesson = subjectContainerUuid ? check(subjectContainerUuid, 'subjectContainer').isUsed : false;

  const context = useMemorizedCacheTag('SUBJECT_CONTAINER');
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [, updateSubjectContainer] = useUpdateSubjectContainerMutation();
  const [, createSubjectContainer] = useCreateSubjectContainersMutation();

  const [{ data }] = useSubjectContainersListQuery({
    variables: {
      subjectContainerUuid: subjectContainerUuid,
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  const gradeGroupsContext = useMemorizedCacheTag('GRADE_GROUPS');

  const [{ data: gradeGroupsResult }] = useGradeGroupsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: gradeGroupsContext,
  });
  const gradeGroupsOptions = useCreateSelectOptions(gradeGroupsResult?.gradeGroups, 'uuid', 'shortName');
  const classNumberOptions = useGradeOptions();

  const initialValues: SubjectContainerListDataType =
    data && data.subjectContainers && data.subjectContainers[0]
      ? data.subjectContainers[0]
      : {
          grades: [],
          order: 0,
          gradeGroup: [],
          uuid: '',
          name: '',
          shortName: '',
          timetableConfig: {
            color: '',
            colorLabel: '',
          },
          validFrom: {
            uuid: currentSchoolYear?.uuid ?? '',
            name: currentSchoolYear?.name ?? '',
            shortName: currentSchoolYear?.shortName ?? '',
            start: currentSchoolYear?.start,
            end: currentSchoolYear?.end,
          },
          validUntil: null,
        };

  const schoolYearOptions: SelectOptionType[] =
    data?.schoolYears.map((sy) => {
      return { value: sy, label: sy.name };
    }) ?? [];

  const colorPickerData = colorPickerOptions({
    htmlColorsInUse: htmlColorsInUse,
    currentColor: initialValues.timetableConfig?.color ?? '',
    currentColorUsedBy: initialValues.name,
    forTypename: 'SubjectContainer',
  });

  const handleSubmit = async (
    values: SubjectContainerListDataType,
    formikHelpers: FormikHelpers<SubjectContainerListDataType>,
  ) => {
    if (loading) {
      return;
    }

    setLoading(true);
    let result;
    if (values.uuid) {
      const validUntilUpdate = values.validUntil?.uuid
        ? { disconnect: {}, connect: { where: { node: { uuid: values.validUntil?.uuid } } } }
        : { disconnect: {} };
      result = await updateSubjectContainer(
        {
          uuid: values.uuid,
          update: {
            gradeGroup: [
              {
                disconnect: [{}],
                connect: values.gradeGroup.map((gg) => {
                  return { where: { node: { uuid: gg.uuid } } };
                }),
              },
            ],
            grades: values.grades ?? -1,
            name: values.name,
            order: values.order,
            shortName: values.shortName,
            validFrom: { disconnect: {}, connect: { where: { node: { uuid: values.validFrom?.uuid } } } },
            validUntil: validUntilUpdate,
            timetableConfig: {
              update: {
                node: {
                  colorLabel: values.timetableConfig?.colorLabel ?? '',
                  color: values.timetableConfig?.color ?? '',
                },
              },
            },
          },
        },
        context,
      );
    } else {
      const validUntilCreate = values.validUntil?.uuid
        ? { connect: { where: { node: { uuid: values.validUntil?.uuid } } } }
        : {};
      result = await createSubjectContainer(
        {
          input: {
            gradeGroup: {
              connect: values.gradeGroup.map((gg) => {
                return { where: { node: { uuid: gg.uuid } } };
              }),
            },
            grades: values.grades ?? -1,
            name: values.name,
            shortName: values.shortName,
            order: values.order,
            timetableConfig: {
              create: {
                node: {
                  organization: {
                    connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } },
                  },
                  colorLabel: values.timetableConfig?.colorLabel ?? '',
                  color: values.timetableConfig?.color ?? '',
                },
              },
            },
            organization: {
              connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } },
            },
            validFrom: { connect: { where: { node: { uuid: values.validFrom?.uuid } } } },
            validUntil: validUntilCreate,
          },
        },
        context,
      );
    }
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessSaveToast();
      formikHelpers.resetForm();
      closeForm();
    }
    setLoading(false);
  };
  return (
    <Formik<SubjectContainerListDataType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({
        values,
        handleChange,
        handleBlur,
        resetForm,
        isSubmitting,
        dirty,
        isValidating,
        touched,
        errors,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Form>
          <Grid useFormGap={true}>
            <GridRow spacingBottom={'s'}>
              <GridColumn width={12}>
                <Input
                  label={t('subjectContainer.name')}
                  className={'full'}
                  name={'name'}
                  onChange={handleChange}
                  value={values.name}
                  error={touched.name ? errors.name : undefined}
                />
              </GridColumn>
            </GridRow>
            <GridRow spacingBottom={'s'}>
              <GridColumn width={3}>
                <Input
                  label={t('subjectContainer.shortName')}
                  className={'quarter'}
                  name={'shortName'}
                  onChange={handleChange}
                  value={values.shortName}
                  error={touched.shortName ? errors.shortName : undefined}
                />
              </GridColumn>
              <GridColumn width={3}>
                <Input
                  label={t('subjectContainer.order')}
                  className={'quarter'}
                  name={'order'}
                  onChange={handleChange}
                  type='number'
                  value={values.order ?? 0}
                  error={touched.order ? errors.order : undefined}
                />
              </GridColumn>
              <GridColumn width={3}>
                <Select
                  label={t('subjectContainer.validFrom')}
                  className={'quarter'}
                  name={'validFrom'}
                  options={schoolYearOptions}
                  onChange={(option) => {
                    const opt = option as SingleValue<SelectOptionType>;
                    setFieldTouched('validFrom', true);
                    setFieldValue('validFrom', opt?.value, true);
                  }}
                  value={values.validFrom ? { value: values.validFrom, label: values.validFrom.name } : null}
                  error={errors.validFrom}
                />
              </GridColumn>
              <GridColumn width={3}>
                <Select
                  label={t('subjectContainer.validUntil')}
                  className={'quarter'}
                  isClearable={true}
                  name={'validUntil'}
                  options={schoolYearOptions}
                  onChange={(option) => {
                    const opt = option as SingleValue<SelectOptionType>;
                    setFieldTouched('validUntil', true);
                    setFieldValue('validUntil', opt?.value, true);
                  }}
                  value={values.validUntil ? { value: values.validUntil, label: values.validUntil.name } : null}
                />
              </GridColumn>
            </GridRow>
            <GridRow spacingBottom={'none'}>
              <GridColumn width={6}>
                <Select
                  label={t('common.grades')}
                  className={'half'}
                  isMulti
                  isClearable
                  disabled={usedInLesson || usedInTeachingBlock}
                  isSearchable
                  tooltipText={t('subjectContainer.validForCombinations')}
                  name={'grades'}
                  options={classNumberOptions}
                  onChange={(_option) => {
                    const options = _option as MultiValue<SelectOptionType>;
                    setFieldValue(
                      'grades',
                      options.map((o) => o.value),
                    );
                  }}
                  value={values.grades.map((grade) => {
                    return { value: grade, label: `${grade}` };
                  })}
                />
              </GridColumn>
              <GridColumn width={6}>
                <Select
                  label={t('gradeGroups.title.plural')}
                  disabled={usedInLesson || usedInTeachingBlock}
                  className={'half'}
                  isMulti
                  isClearable
                  isSearchable
                  tooltipText={t('subjectContainer.validForCombinations')}
                  name={'gradeGroup'}
                  options={gradeGroupsOptions}
                  onChange={(_option) => {
                    const options = _option as MultiValue<SelectOptionType>;
                    const uuids = options.map((o) => o.value);
                    const data = gradeGroupsResult?.gradeGroups.filter((gg) => {
                      return uuids.includes(gg.uuid);
                    });
                    setFieldValue('gradeGroup', data);
                  }}
                  value={values.gradeGroup.map((gg) => {
                    return { value: gg.uuid, label: gg.shortName };
                  })}
                />
              </GridColumn>
            </GridRow>
            {(usedInLesson || usedInTeachingBlock) && (
              <GridRow spacingTop={'none'}>
                <GridColumn width={12}>
                  <small>{t('subjectContainer.usedSubjectContainerEditText')}</small>
                </GridColumn>
              </GridRow>
            )}
            <GridRow>
              <GridColumn width={12}>
                <ColorPicker
                  name='color'
                  label={t('common.color')}
                  className={'full'}
                  options={colorPickerData.groupedColorOptions}
                  maxMenuHeight={300}
                  tooltipText=''
                  onChange={(option) => {
                    setFieldValue('timetableConfig.color', option?.html ?? '');
                    setFieldValue('timetableConfig.colorLabel', option?.label ?? '');
                    setFieldTouched('color', true, false);
                  }}
                  onBlur={handleBlur}
                  defaultValue={colorPickerData.currentColor}
                />
              </GridColumn>
            </GridRow>
          </Grid>
          <ModalBottomButtons
            closeButton={{
              callback: () => {
                resetForm();
                closeForm();
              },
            }}
            isLoading={loading}
          />
        </Form>
      )}
    </Formik>
  );
};
