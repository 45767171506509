import { RowSelectionState } from '@tanstack/react-table';

export function selectedToData<T>(obj: RowSelectionState, arr: T[]) {
  const result = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const index = parseInt(key);
      if (index >= 0 && index < arr.length) {
        result.push(arr[index]);
      }
    }
  }
  return result;
}
