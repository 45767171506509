import { use_LessonsQuery, use_UpdateTimetableVersionsMutation } from '../../../types/planung-graphql-client-defs';
import { useCallback, useMemo, useReducer } from 'react';
import { loadingReducer, loadingReducerInitialState } from '../../../reducer/loadingReducer';
import { initialMatrix } from '../utils/TimetableVersionTableUtils';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';

export const useTimetableClasses = (versionUuid: string) => {
  const [loadingState, dispatch] = useReducer(loadingReducer, loadingReducerInitialState);

  const [, updateVersion] = use_UpdateTimetableVersionsMutation();

  const [{ data: lessonsData }] = use_LessonsQuery({
    variables: {
      where: {
        versions_SOME: { uuid: versionUuid },
      },
    },
  });

  const importExportCache = useMemo(() => {
    return {
      additionalTypenames: ['TimetableVersion'],
    };
  }, []);

  const importClasses = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    await updateVersion(
      {
        where: {
          uuid: versionUuid,
        },
        update: {
          classes: [{ connect: [{ where: { node: { uuid_IN: uuids } }, edge: { ...initialMatrix } }] }],
        },
      },
      { context: importExportCache },
    );
    dispatch({ type: 'SET_LOADING', uuids: uuids });
  };

  const { lessonClassesData } = useLoadBasicData({ pause: !versionUuid });

  const canBeRemoved = (uuids: string[]) => {
    const lesson = lessonsData?.lessons.find((l) => {
      const classes = l.lessonClassesConnection.edges.map((edge) => {
        return lessonClassesData?.lessonClasses.find((lc) => lc.uuid === edge.node.uuid)?.class.uuid ?? '';
      });
      return classes.some((c) => uuids.includes(c));
    });
    return !lesson;
  };

  const removeClasses = useCallback(
    async (uuids: string[]) => {
      dispatch({ type: 'SET_LOADING', uuids: uuids });
      await updateVersion(
        {
          where: {
            uuid: versionUuid,
          },
          update: {
            classes: [{ disconnect: [{ where: { node: { uuid_IN: uuids } } }] }],
          },
        },
        { context: importExportCache },
      );
      dispatch({ type: 'SET_LOADING', uuids: uuids });
    },
    [importExportCache, updateVersion, versionUuid],
  );

  return {
    importClasses,
    canBeRemoved,
    removeClasses,
    loadingState,
  };
};
