import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOptionType } from '@bp/ui-components';

export const usePriorityOptions = () => {
  const { t } = useTranslation();

  return useMemo((): SelectOptionType[] => {
    return [
      {
        label: t('conditions.priority.optimizing'),
        value: 'optimizing',
        icon: 'OptimizingIcon',
      },
      {
        label: t('conditions.priority.important'),
        value: 'important',
        icon: 'ImportantIcon',
      },
      {
        label: t('conditions.priority.mandatory'),
        value: 'mandatory',
        icon: 'MandatoryIcon',
      },
    ];
  }, [t]);
};
