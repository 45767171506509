import styles from './App.module.css';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AppHeader } from './components/AppHeader/AppHeader';
import * as Sentry from '@sentry/react';
import { RequireAuth } from './RequireAuth';
import { UserConfigProvider } from './contexts/StoreContext/UserConfigContext';
import { LazyLoader, Toast } from '@bp/ui-components';
import { SideBarWrapper } from './components/SidebarWrapper/SidebarWrapper';
import { Breadcrumb } from './components/Breadcrumb/Breadcrumb';
import { PlanungAuthProvider } from './PlanungAuthProvider';
import { UrqlClientProvider } from './UrqlClientProvider';
import classNames from 'classnames';

export const App = Sentry.withProfiler(() => {
  return (
    <PlanungAuthProvider>
      <RequireAuth>
        <UrqlClientProvider>
          <UserConfigProvider>
            <SideBarWrapper>
              <div className={classNames(styles.content, 'has-scrollbar')} id='content' data-cy='layoutContentColumn'>
                <AppHeader />
                <main className={styles.container}>
                  <Breadcrumb />
                  <Suspense fallback={<LazyLoader transparent forceHeight='50vh' fullWidth size='xxl' />}>
                    <Outlet />
                  </Suspense>
                </main>
              </div>
            </SideBarWrapper>
          </UserConfigProvider>
        </UrqlClientProvider>
        <Toast />
      </RequireAuth>
    </PlanungAuthProvider>
  );
});
