import * as yup from 'yup';
import i18next from 'i18next';

export const schema = yup
  .object()
  .shape({
    name: yup.string().required(i18next.t('validation.subjectContainer.nameRequired') as string),
    shortName: yup.string().required(i18next.t('validation.subjectContainer.shortNameRequired') as string),
    order: yup.number().nullable(),
    validFrom: yup.object().shape({
      name: yup.string().required(),
      shortName: yup.string().required(),
      start: yup.mixed().required(),
      end: yup.mixed().required(),
    }),
    validUntil: yup
      .object()
      .shape({
        name: yup.string(),
        shortName: yup.string(),
        start: yup.mixed(),
        end: yup.mixed(),
      })
      .nullable(),
  })
  .test('date-range', function (value) {
    if (!value?.validFrom?.start || !value?.validUntil?.start) {
      return true;
    }
    const validFrom = new Date(value.validFrom.start as string);
    const validUntil = new Date(value.validUntil.start as string);
    return validFrom <= validUntil
      ? true
      : this.createError({
          message: i18next.t('validation.subjectContainer.validFromBeforeValidUntil') as string,
          path: 'validFrom',
        });
  });
