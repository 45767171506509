import { createPortal } from 'react-dom';
import { CSSProperties, FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { PickedCard } from '../types';
import { useMousePosition } from '../../../hooks/useMousePosition';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import { BadgeCard, BadgeCardWidth } from '@bp/ui-components';

export interface CardPreviewProps {
  pickedCard: PickedCard;
  preventEscape: boolean;
}

export const CardPreview: FC<CardPreviewProps> = observer(({ pickedCard, preventEscape }) => {
  const card = pickedCard.card;

  const { x, y } = useMousePosition({ defaultX: pickedCard.position.x, defaultY: pickedCard.position.y });
  const { pickCard, freeCards, discardCard, onEscape } = useTeachingBlockStore();

  const count = freeCards.filter((c) => c.lesson?.uuid === card?.lesson?.uuid && c.duration === card.duration).length;

  const offsetX = -10;
  const offsetY = -10;

  const style: CSSProperties = {
    position: 'fixed',
    zIndex: 10,
    left: x - offsetX,
    top: y - offsetY,
    opacity: 0.75,
  };

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (!preventEscape && event.key === 'Escape') {
        onEscape(card);
      }
    }

    function handleClick(event: MouseEvent) {
      const element = event.target as HTMLElement;
      if (element.classList.contains('drop-and-delete')) {
        if (card.startDate) discardCard(card);
        else pickCard(null);
      } else if (!element.classList.contains('droppable')) {
        pickCard(null);
      } else if ((pickedCard.counter ?? 0) < 1) {
        pickCard(null);
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('mousedown', handleClick);
    };
  }, [card, discardCard, pickCard, pickedCard.counter]);

  const badgeCardRows = card.badgeCardRows ?? [];

  return createPortal(
    <BadgeCard
      label={card.subject?.shortName ?? ''}
      count={count}
      rows={badgeCardRows}
      width={card.duration as BadgeCardWidth}
      showHover={false}
      showGap={!!badgeCardRows.length}
      showRounded={false}
      style={style}
      color={card.badgeCardTextColor}
    />,
    document.fullscreenElement ? (document.querySelector('#teaching-block-grid') ?? document.body) : document.body,
  );
});
