import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { LessonFormType } from '../../types';
import { AddIcon, Button, DeleteIcon, Input, Select, SelectOptionType } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';

const LessonRoomSupplyForm = ({ disabled }: { disabled: boolean }) => {
  const formik = useFormikContext<LessonFormType>();
  const { t } = useTranslation();

  const { roomsData } = useLoadBasicData({ pause: false });

  const usedRooms = formik.values.roomSupply?.map((rs) => rs.uuid);
  const options = useCreateSelectOptions(roomsData?.rooms, 'uuid', 'name');

  return (
    <>
      <FieldArray name={'roomSupply'}>
        {(arrayHelpers) => {
          return (
            <>
              <div className='form-group tks__grid'>
                {formik.values.roomSupply?.map((room, index) => {
                  const current = options.find((option) => {
                    return option.value === room.uuid;
                  }) ?? { value: '', label: '' };
                  return (
                    <div key={index} className={'tks__row'}>
                      <div className={'tks__col col-xs-9'}>
                        <div className={'form-row'}>
                          <Select
                            isSearchable
                            readonly={disabled}
                            label={t('rooms.title.singular')}
                            options={options.filter((r) => !usedRooms?.includes(r.value as string))}
                            name={`roomSupply.${index}.uuid`}
                            className={'half'}
                            value={current}
                            onChange={async (option) => {
                              const opt = option as SingleValue<SelectOptionType>;
                              await formik.setFieldTouched(`roomSupply.${index}.uuid`, true);
                              await formik.setFieldValue(`roomSupply.${index}.uuid`, opt?.value);
                            }}
                          ></Select>
                          <Input
                            readonly={disabled}
                            className={'half'}
                            label={t('rooms.addition')}
                            name={`roomSupply.${index}.description`}
                            onChange={formik.handleChange}
                            value={
                              formik.values.roomSupply
                                ? (formik.values.roomSupply[index].description ?? undefined)
                                : undefined
                            }
                          />
                        </div>
                      </div>
                      <div className={'tks__col col-xs-3'}>
                        <Button
                          disabled={disabled}
                          className='mt-6 align-end'
                          hierarchy='tertiary'
                          icon={
                            <DeleteIcon
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <Button
                hierarchy={'tertiary'}
                disabled={!formik.values.subject || disabled}
                icon={<AddIcon />}
                onClick={async () => {
                  if (formik.values.roomSupply && formik.values.roomSupply.length > 0) {
                    for (const index in formik.values.roomSupply) {
                      await formik.setFieldTouched(`roomSupply.${index}`);
                    }
                  }
                  arrayHelpers.push({});
                }}
              >
                {t('rooms.add')}
              </Button>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

export default LessonRoomSupplyForm;
