import {
  FactorType,
  SortDirection,
  useCreateTeachingLoadFactorMutation,
  useGradeGroupsListQuery,
  useSubjectsListQuery,
  useTeachingLoadScenariosQuery,
  useTimeGridQuery,
  useUpdateTeachingLoadFactorMutation,
} from '../../../types/planung-graphql-client-defs';
import { FormikHelpers } from 'formik/dist/types';
import { Form, Formik } from 'formik';
import { schema } from './validation/schema';
import { Checkbox, Input, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import FormBlockHeader from '../../Form/FormBlockHeader';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useMemoizedClassNumberOptions } from '../../../hooks/useMemoizedClassNumberOptions';
import { FactorInterpretationInfo } from './components/FactorInterpretationInfo';
import { interpreteAsNumber } from '../../../utils/helper';
import { FactorsFormProps, FactorsFormTypes } from '../types';
import { submitFactor } from './utils/submitFactor';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';

export const SubjectFactorForm = ({ currentSchoolYear, closeForm, teachingLoadFactor }: FactorsFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('TEACHINGLOADFACTOR');

  const [subjectsQueryResult] = useSubjectsListQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
      options: {
        sort: [
          {
            active: SortDirection.Desc,
          },
          {
            name: SortDirection.Asc,
          },
        ],
      },
    },

    context,
  });
  const [gradeGroupsQueryResult] = useGradeGroupsListQuery({ context });
  const [timeGridsQueryResult] = useTimeGridQuery({ context });
  const [scenariosQueryResult] = useTeachingLoadScenariosQuery({ context });

  // data
  const subjects = subjectsQueryResult.data && subjectsQueryResult.data.subjects;
  const gradeGroups = gradeGroupsQueryResult.data && gradeGroupsQueryResult.data.gradeGroups;
  const timeGridEntries = timeGridsQueryResult.data?.timeGrids.flatMap((t) =>
    t.itemsConnection.edges.map((e) => ({
      ...e.node,
      name: `${t.name} - ${e.node.name}`, //timeGridName - timeGridEntryName
    })),
  );
  const teachingLoadScenarios = scenariosQueryResult.data && scenariosQueryResult.data.teachingLoadScenarios;

  // select options
  const subjectsOptions: SelectOptionType[] = useCreateSelectOptions(subjects, 'uuid', 'name');
  const gradeGroupsOptions: SelectOptionType[] = useCreateSelectOptions(gradeGroups, 'uuid', 'name');
  const timeGridsOptions: SelectOptionType[] = useCreateSelectOptions(timeGridEntries, 'uuid', 'name');
  const classNumberOptions = useMemoizedClassNumberOptions();
  const teachingLoadScenariosOptions: SelectOptionType[] = useCreateSelectOptions(
    teachingLoadScenarios,
    'uuid',
    'name',
  );

  const [, updateTeachingLoadFactor] = useUpdateTeachingLoadFactorMutation();
  const [, createTeachingLoadFactor] = useCreateTeachingLoadFactorMutation();

  const handleSubmit = async (values: FactorsFormTypes, formikHelpers: FormikHelpers<FactorsFormTypes>) => {
    const result = await submitFactor(
      values,
      teachingLoadFactor,
      currentSchoolYear,
      context,
      updateTeachingLoadFactor,
      createTeachingLoadFactor,
    );

    if (result.error) {
      showUserErrorToast({ error: result.error });
    } else if (teachingLoadFactor?.uuid) {
      showSuccessUpdateToast();
    } else {
      showSuccessCreateToast();
    }
    formikHelpers.resetForm();
    closeForm();
  };

  const initialValues: FactorsFormTypes = {
    uuid: teachingLoadFactor?.uuid ?? null,
    factorType: FactorType.Base, // "Fachstunde"
    grades: teachingLoadFactor?.grades ?? [],
    gradeGroups: teachingLoadFactor?.gradeGroup?.map((item) => item.uuid),
    subjects: teachingLoadFactor?.subject?.map((item) => item.uuid),
    subjectContainers: teachingLoadFactor?.subjectContainer?.map((item) => item.uuid),
    teachingLoadScenario: teachingLoadFactor?.scenario?.uuid ?? '',
    value: teachingLoadFactor?.value.toString() ?? '1',
    valueRaw: teachingLoadFactor?.valueRaw ?? '1',
    valuePartial: teachingLoadFactor?.valuePartial?.toString() ?? '1',
    valuePartialRaw: teachingLoadFactor?.valuePartialRaw ?? '1',
    comment: teachingLoadFactor?.comment ?? '',
    schoolYear: currentSchoolYear,
    active: teachingLoadFactor?.active ?? true,
    timeGridEntries: teachingLoadFactor?.timeGridEntries.map((item) => item.uuid),
  };

  return (
    <Formik<FactorsFormTypes>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        values,
        handleChange,
        resetForm,
        dirty,
        isValidating,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        errors,
      }) => {
        const handleMultiSelectChange = async (name: keyof FactorsFormTypes, options: MultiValue<SelectOptionType>) => {
          await setFieldTouched(name, true);
          await setFieldValue(
            name,
            options.map((o) => o.value),
          );
        };

        return (
          <Form>
            <div className='tks__grid mt-6 mb-8'>
              <div className='tks__row'>
                {/*Fächer*/}
                <div className='tks__col col-xs-10'>
                  <Select
                    placeholder={t('common.all')}
                    tooltipText={t('common.allIfNothingSelected')}
                    name={'subjects'}
                    onChange={async (options) =>
                      handleMultiSelectChange('subjects', options as MultiValue<SelectOptionType>)
                    }
                    options={subjectsOptions}
                    defaultValue={
                      teachingLoadFactor?.subject
                        ? teachingLoadFactor?.subject.map((item) => ({ label: item.name, value: item.uuid }))
                        : null
                    }
                    isSearchable
                    isClearable
                    isMulti
                    label={t('subject.title.plural')}
                  />
                </div>
                <div className='tks__col col-xs-2'>
                  <Select
                    name={'teachingLoadScenario'}
                    onChange={async (option) => {
                      const o = option as SingleValue<SelectOptionType>;
                      if (o !== null) {
                        await setFieldTouched('teachingLoadScenario', true);
                        await setFieldValue('teachingLoadScenario', o.value);
                      }
                    }}
                    options={teachingLoadScenariosOptions}
                    isSearchable
                    isClearable
                    label={t('common.scenario')}
                    defaultValue={
                      teachingLoadFactor?.scenario
                        ? { label: teachingLoadFactor?.scenario?.name, value: teachingLoadFactor?.scenario.uuid }
                        : null
                    }
                  />
                </div>
              </div>

              <div className='tks__row'>
                {/*Jahrgangsstufen*/}
                <div className='tks__col col-xs-5'>
                  <Select
                    tooltipText={t('common.allIfNothingSelected')}
                    placeholder={t('common.all')}
                    name={'grades'}
                    onChange={async (options) =>
                      handleMultiSelectChange('grades', options as MultiValue<SelectOptionType>)
                    }
                    defaultValue={classNumberOptions.filter((opt) => values.grades.includes(opt.value))}
                    options={classNumberOptions}
                    isSearchable
                    isClearable
                    isMulti={true}
                    label={t('common.grades')}
                  />
                </div>

                {/*Klassenzug*/}
                <div className='tks__col col-xs-5'>
                  <Select
                    tooltipText={t('common.allIfNothingSelected')}
                    placeholder={t('common.all')}
                    name={'gradeGroups'}
                    onChange={async (options) =>
                      handleMultiSelectChange('gradeGroups', options as MultiValue<SelectOptionType>)
                    }
                    options={gradeGroupsOptions}
                    isSearchable
                    isClearable
                    isMulti
                    label={t('gradeGroups.title.singular')}
                    defaultValue={
                      teachingLoadFactor?.gradeGroup
                        ? teachingLoadFactor?.gradeGroup.map((item) => ({ label: item.name, value: item.uuid }))
                        : null
                    }
                  />
                </div>
                <div className='tks__col col-xs-2 align-center'>
                  <Checkbox
                    checked={values.active}
                    name={'active'}
                    label={t('common.active.full')}
                    onChange={async (e) => {
                      await setFieldValue('active', e.target.checked);
                      await setFieldTouched('active', true);
                    }}
                  />
                </div>
              </div>

              <div className='tks__row row-xs-10'>
                {/*schulzeiten*/}
                <div className='tks__col col-xs-12'>
                  <Select
                    tooltipText={t('common.allIfNothingSelected')}
                    placeholder={t('common.all')}
                    name={'timeGridEntries'}
                    onChange={async (options) =>
                      handleMultiSelectChange('timeGridEntries', options as MultiValue<SelectOptionType>)
                    }
                    options={timeGridsOptions}
                    isSearchable
                    isClearable
                    isMulti
                    label={t('common.timeGrids')}
                    defaultValue={
                      teachingLoadFactor?.timeGridEntries
                        ? teachingLoadFactor?.timeGridEntries.map((item) => ({ label: item.name, value: item.uuid }))
                        : null
                    }
                  />
                </div>
              </div>
            </div>

            <FormBlockHeader title={'Faktor'} />

            <div className='tks__grid'>
              <div className='tks__row row-xs-10'>
                {/*Faktor*/}
                <div className='tks__col  col-xs-3'>
                  <Input
                    tooltipText={t('factors.tooltip')}
                    value={values.valueRaw}
                    name={'value'}
                    onChange={async (e) => {
                      await setFieldValue('valueRaw', e.target.value);
                      await setFieldValue('value', interpreteAsNumber(e.target.value));
                      await setFieldTouched('value');
                    }}
                    label={t('factors.titleSingular')}
                    error={errors.value}
                    type={'text'}
                  />
                </div>

                <div className='tks__col col-xs-3 align-center'>
                  <FactorInterpretationInfo value={values.value} />
                </div>

                {/*Faktor geteilt*/}
                <div className='tks__col  col-xs-3'>
                  <Input
                    tooltipText={t('factors.tooltip')}
                    value={values.valuePartialRaw}
                    name={'value'}
                    onChange={async (e) => {
                      await setFieldValue('valuePartialRaw', e.target.value);
                      await setFieldValue('valuePartial', interpreteAsNumber(e.target.value));
                      await setFieldTouched('valuePartial');
                    }}
                    label={t('factors.titleSingularPartial')}
                    error={errors.value}
                    type={'text'}
                  />
                </div>

                <div className='tks__col col-xs-3 align-center'>
                  <FactorInterpretationInfo value={values.valuePartial as string} />
                </div>
              </div>

              <div className='tks__row row-xs-10'>
                {/*Bemerkung*/}
                <div className='tks__col col-xs-12'>
                  <Input
                    name={'comment'}
                    onChange={handleChange}
                    label={t('common.comment')}
                    value={values.comment ?? ''}
                    error={errors.comment}
                  />
                </div>
              </div>
            </div>

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  resetForm();
                  closeForm();
                },
                disabled: isSubmitting || !dirty || isValidating,
              }}
              errors={errors}
              isLoading={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
