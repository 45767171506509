import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import { isString } from 'formik';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type UniversalDate = string | Date | Dayjs | undefined | null;

export const isFirstBeforeSecond = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isBefore(dayjs(b).startOf(unit), unit);
};

export const isFirstAfterSecond = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isAfter(dayjs(b).startOf(unit), unit);
};

export const isFirstSameOrAfterSecond = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isSameOrAfter(dayjs(b).startOf(unit), unit);
};

export const isFirstSameOrBeforeSecond = (a: UniversalDate, b: UniversalDate, unit: OpUnitType = 'day'): boolean => {
  return dayjs(a).startOf(unit).isSameOrBefore(dayjs(b).startOf(unit), unit);
};

export const isSame = (a: UniversalDate, b: UniversalDate): boolean => {
  return parseInt(dayjs.duration(dayjs(b).diff(a)).asDays().toFixed()) === 0;
};

export const isFirstBetweenSecondAndThird = (
  date: UniversalDate,
  a: UniversalDate,
  b: UniversalDate,
  unit: OpUnitType = 'day',
  d: '()' | '[]' | '[)' | '(]' = '[]',
): boolean => {
  return dayjs(date).startOf(unit).isBetween(dayjs(a).startOf(unit), dayjs(b).startOf(unit), unit, d);
};

export const getDaysDifference = (a: UniversalDate, b: UniversalDate, including = true): number => {
  const diff = dayjs(b).startOf('day').diff(dayjs(a).startOf('day'), 'days');
  return including ? diff + 1 : diff;
};

export const niceDate = (
  date: UniversalDate,
  dateStyle?: 'medium' | 'full' | 'long' | 'short' | undefined,
  timeStyle?: 'medium' | 'full' | 'long' | 'short' | undefined,
) => {
  if (isString(date)) {
    const event = new Date(date);

    if (dateStyle && timeStyle) {
      return event.toLocaleString('de-DE', { dateStyle: dateStyle, timeStyle: timeStyle });
    }
    return dateStyle ? event.toLocaleString('de-DE', { dateStyle: dateStyle }) : event.toLocaleString('de-DE');
  } else if (date instanceof Date) {
    if (dateStyle && timeStyle) {
      return date.toLocaleString('de-DE', { dateStyle: dateStyle, timeStyle: timeStyle });
    }
    return dateStyle ? date.toLocaleString('de-DE', { dateStyle: dateStyle }) : date.toLocaleString('de-DE');
  }
};
