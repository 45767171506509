import {
  ArrowDownIcon,
  ArrowUpIcon,
  Button,
  ControlIcon,
  EmptyState,
  Grid,
  GridRow,
  LazyLoader,
  Modal,
} from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CardControlItem } from '../CardControl/CardControlItem';
import styles from './TestModal.module.scss';
import { useTimetableStore } from '../TimetableProvider';

export const TestModal: FC = observer(() => {
  const { t } = useTranslation();
  const pinboardStore = useTimetableStore();

  const result = pinboardStore.testModal.result;
  return (
    <Modal
      className={styles['test-modal']}
      isOpen={pinboardStore.testModal.isOpen}
      onRequestClose={() => pinboardStore.setTestModalOpen(false)}
      title={t('pinboard.test.testAll')}
      parent={document.fullscreenElement ? document.getElementById('timetable-grid') : null}
      actions={
        <div className={styles.actions}>
          <Button
            hierarchy='tertiary'
            icon={<ArrowDownIcon />}
            onClick={() => pinboardStore.expandControlResults(true)}
          >
            {t('pinboard.test.expandAll')}
          </Button>
          <Button hierarchy='tertiary' icon={<ArrowUpIcon />} onClick={() => pinboardStore.expandControlResults(false)}>
            {t('pinboard.test.collapseAll')}
          </Button>
        </div>
      }
    >
      <Grid>
        {pinboardStore.testModal.isLoading ? (
          <EmptyState
            title={t('pinboard.test.loadingHint')}
            icon={<LazyLoader size='xxl' embedded />}
            iconColor='var(--color-grey-dark)'
            forcedHeight='60vh'
          />
        ) : (
          <>
            <GridRow headline={t('availability.title.plural')} direction='column' spacingBottom='l'>
              {!result || result.length === 0 ? (
                <EmptyState
                  size='small'
                  subtitle={t('pinboard.test.noWarnings', { type: t('availability.title.plural') })}
                  icon={<ControlIcon />}
                  iconColor='var(--color-success)'
                />
              ) : (
                result?.map((warning, index) => {
                  return (
                    <CardControlItem
                      key={index}
                      type='test'
                      genericWarning={warning}
                      count={index}
                      isInteractive={false}
                    />
                  );
                })
              )}
            </GridRow>
          </>
        )}
      </Grid>
    </Modal>
  );
});
