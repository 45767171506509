import { Button, Card, DuplicateIcon, ImportIcon, LazyLoader, Skeleton, Tab, Tooltip } from '@bp/ui-components';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../TimetableVersionData.module.scss';
import { TimetableVersionTeachersTable } from '../../../../../../components/TimetableVersion/TimetableVersionDataTables/TimetableVersionTeachersTable';
import { useNavigate, useParams } from 'react-router-dom';
import { TimetableVersionTableType } from '../../../../../../components/TimetableVersion/graphql/types';
import { TimetableVersionTeachersMatrix } from '../../../../../../components/TimetableVersion/TimetableVersionDataAvailabilityMatrix/TimetableVersionTeachersMatrix';
import { useMemorizedCacheTag } from '../../../../../../hooks/useMemorizedCacheTag';
import {
  TimetableRelationshipProperties,
  use_UpdateTimetableVersionsMutation,
} from '../../../../../../types/planung-graphql-client-defs';
import { availabilityStore } from '../../TimetableVersion';
import { createLessonFilterUrl } from '../../../../../../utils/create-lesson-filter-url';
import { hexToColorOption } from '../../../../../../utils/colorUtils';
import { showSuccessUpdateToast, showUserErrorToast } from '../../../../../../utils/toast';
import { useTimetableStore } from '../../../../../../components/TimetableGrid/TimetableProvider';
import { initialMatrix } from '../../../../../../components/TimetableVersion/utils/TimetableVersionTableUtils';
import { observer } from 'mobx-react-lite';
import { useLoadBasicData } from '../../../../../../hooks/useLoadBasicData';

export const PlanTimetableTeachers = observer(() => {
  const { t } = useTranslation();
  const { versionUuid } = useParams();

  const navigate = useNavigate();

  const store = useTimetableStore();
  const timeAvailabilityStore = store.getTimeAvailabilityStore();

  const [tab, setTab] = useState<'availability' | 'condition'>('availability');
  const [selected, setSelected] = useState<TimetableVersionTableType | null>(null);
  const [loading, setLoading] = useState(false);

  const versionsContext = useMemorizedCacheTag('TIMETABLE_VERSIONS');
  const [, updateVersion] = use_UpdateTimetableVersionsMutation();

  const versionTeachersUuids: string[] = Array.from(store.teachers.keys());

  const { teacherData } = useLoadBasicData({ pause: !versionUuid });

  const teachersTableData: TimetableVersionTableType[] = useMemo(() => {
    return (
      teacherData?.people
        .filter((t) => versionTeachersUuids.includes(t.uuid))
        .map((t) => {
          const { html, label } = hexToColorOption(t.timetableConfig?.color ?? '');
          return {
            uuid: t.uuid,
            name: t.displayNameShort ?? t.fullName,
            count: 0,
            shortName: t.shortName,
            color: {
              color: html,
              colorLabel: label,
            },
          };
        }) ?? []
    );
  }, [teacherData?.people, versionTeachersUuids]);

  async function handleChange(items: TimetableRelationshipProperties) {
    if (store.readonly) return;
    if (versionUuid && selected) {
      store.removeConflicts();
      availabilityStore?.setAvailability('teacher', selected.uuid, items, versionUuid);
      timeAvailabilityStore?.setResourceAvailability(
        [
          {
            uuid: selected.uuid,
            days: {
              mon: items.mon,
              tue: items.tue,
              wed: items.wed,
              thu: items.thu,
              fri: items.fri,
              sat: items.sat,
              sun: items.sun,
            },
          },
        ],
        'teacher',
      );
      store.setConflicts();
    }
  }
  const delta = teacherData?.people.filter((c) => !versionTeachersUuids.includes(c.uuid));

  async function handleImport() {
    if (store.readonly) return;
    setLoading(true);
    if (versionUuid) {
      const result = await updateVersion(
        {
          where: {
            uuid: versionUuid,
          },
          update: {
            teachers: [
              { connect: [{ where: { node: { uuid_IN: delta?.map((c) => c.uuid) } }, edge: { ...initialMatrix } }] },
            ],
          },
        },
        versionsContext,
      );
      if (!result || result.error) {
        showUserErrorToast({ text: t('common.errorToastText'), error: result.error });
      } else {
        showSuccessUpdateToast();
      }
    }
    store.addTeachers(delta ?? []);
    setLoading(false);
  }

  const onRemove = async (uuids: string[]) => {
    if (store.readonly) return;
    setSelected(teachersTableData[0]);
    await updateVersion(
      {
        where: {
          uuid: versionUuid,
        },
        update: {
          teachers: [{ disconnect: [{ where: { node: { uuid_IN: uuids } } }] }],
        },
      },
      versionsContext,
    );
    store.removeTeachers(uuids);
  };

  return (
    <div>
      <div className={'tks__grid'}>
        <div className='tks__row row end-xs'>
          <Button
            hierarchy='tertiary'
            icon={<ImportIcon />}
            onClick={handleImport}
            disabled={!delta?.length || loading}
          >
            {t('timetableVersion.import', { import: t('persons.title.plural') })}
          </Button>
        </div>
        <div className={'tks__row'}>
          <div className={`${teachersTableData.length === 0 ? 'col-xs-12' : 'col-xs-6'} ${styles['left']}`}>
            <TimetableVersionTeachersTable
              onRowSelection={(selected) => {
                setSelected(selected);
              }}
              onRemove={onRemove}
              versionTeacherUuids={versionTeachersUuids}
              versionUuid={versionUuid ?? ''}
              onShowLessonClick={(uuid) => {
                if (versionUuid) {
                  navigate(
                    createLessonFilterUrl({
                      context: 'stundenplan',
                      filterValue: uuid,
                      versionUuid: versionUuid,
                      column: 'teachers',
                    }),
                  );
                }
              }}
            />
          </div>
          {teachersTableData.length !== 0 && (
            <div className={'tks__col col-xs-6'}>
              <Suspense fallback={<LazyLoader />}>
                <Card className={styles['version-data']} contentPadding='none' fitContent>
                  <small>{t('timetableVersion.specificInfo', { for: selected?.name })}</small>
                  <div className={styles['mini-tab']}>
                    <Tab
                      className={styles.tabs}
                      onValueChange={(content) => setTab(content as 'availability' | 'condition')}
                      tabs={[
                        {
                          title: t('availability.title.plural'),
                          value: 'availability',
                          content: (
                            <TimetableVersionTeachersMatrix
                              timeGridEntries={store.timeGridEntries ?? []}
                              versionUuid={versionUuid}
                              selected={selected}
                              onChange={handleChange}
                            />
                          ),
                        },
                        {
                          title: t('conditions.title.plural'),
                          value: 'condition',
                          content: (
                            <div className='px-6 py-4'>
                              <Skeleton />
                            </div>
                          ),
                        },
                      ]}
                    />
                    <div className={styles['additional-actions']}>
                      {tab === 'availability' && (
                        <Tooltip content={t('availability.duplicate')}>
                          <Button hierarchy='ghost' icon={<DuplicateIcon />} disabled />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </Card>
              </Suspense>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
