import { UsedInLessonQuery, useUsedInCurriculaQuery, useUsedInLessonQuery } from '../types/planung-graphql-client-defs';
import { useAuthClaims } from './useAuthClaims';
import { useMemorizedCacheTag } from './useMemorizedCacheTag';

export type UsedInLessonsQueryDataType = Pick<UsedInLessonQuery, 'lessons'>['lessons'][0];
export const useIsUsedInLesson = () => {
  const { pimAuthClaims } = useAuthClaims();

  const context = useMemorizedCacheTag('LESSONS');

  const [{ data: usedInLessonQueryData }] = useUsedInLessonQuery({
    variables: { lessonWhere: { organization: { uuid: pimAuthClaims.getOrganizationUuid() } } },
    context,
  });

  const [{ data: curriculaQueryData }] = useUsedInCurriculaQuery({
    variables: { curriculaWhere: { organization: { uuid: pimAuthClaims.getOrganizationUuid() } } },
    context,
  });

  return (
    uuid: string,
    entityToCheck?:
      | 'class'
      | 'subject'
      | 'teacher'
      | 'room'
      | 'subjectContainer'
      | 'classDivision'
      | 'classDivisionGroup',
  ): { isUsed: boolean; lessons: UsedInLessonsQueryDataType[] } => {
    const checkSubject = (lesson: UsedInLessonsQueryDataType) => {
      const inLesson = lesson.subject.uuid === uuid;
      const inCurriculum = curriculaQueryData?.curricula.some((c) => c.subject.uuid === uuid);
      return inLesson || inCurriculum;
    };
    const checkSubjectContainer = (lesson: UsedInLessonsQueryDataType) => {
      const asSubject = lesson.subject.uuid === uuid;
      const inLesson = lesson.lessonUnitConnection.edges.some((lu) => lu.node.subjectContainer?.uuid === uuid);
      const inCurriculum = curriculaQueryData?.curricula.some((c) => c.subject.uuid === uuid);
      return inLesson || inCurriculum || asSubject;
    };
    const checkClass = (lesson: UsedInLessonsQueryDataType) =>
      lesson.lessonClassesConnection.edges.some((e) => e.node.class.uuid === uuid);
    const checkTeacher = (lesson: UsedInLessonsQueryDataType) =>
      lesson.teachersConnection.edges.some((e) => e.node.uuid === uuid);
    const checkRoom = (lesson: UsedInLessonsQueryDataType) =>
      lesson.roomSupplyConnection.edges.some((r) => r.node.uuid === uuid);
    const checkClassDivision = (lesson: UsedInLessonsQueryDataType) =>
      lesson.lessonClassesConnection.edges.some((lc) => lc.node.usedDivision?.uuid === uuid);

    const checkClassDivisionGroups = (lesson: UsedInLessonsQueryDataType) =>
      lesson.lessonClassesConnection.edges.some((lc) => lc.node.groups.some((g) => g.uuid === uuid));

    const checkEntity = (lesson: UsedInLessonsQueryDataType) => {
      switch (entityToCheck) {
        case 'subject':
          return checkSubject(lesson);
        case 'subjectContainer':
          return checkSubjectContainer(lesson);
        case 'class':
          return checkClass(lesson);
        case 'teacher':
          return checkTeacher(lesson);
        case 'room':
          return checkRoom(lesson);
        case 'classDivision':
          return checkClassDivision(lesson);
        case 'classDivisionGroup':
          return checkClassDivisionGroups(lesson);
        default:
          return false;
      }
    };
    const usages = usedInLessonQueryData?.lessons.filter((lesson) => checkEntity(lesson)) || [];

    return { isUsed: usages.length > 0, lessons: usages };
  };
};
