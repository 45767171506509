import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import { Button, ButtonGroup, DeleteIcon, EditIcon, Modal, Row, Table, TableColumns } from '@bp/ui-components';
import { GradeGroupForm } from './Forms/GradeGroupForm';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { DeleteGradeGroupsDocument, useGradeGroupsListQuery } from '../../types/planung-graphql-client-defs';
import { useConfirm } from '../../hooks/useConfirm';
import { showSuccessDeleteToast, showUserErrorToast } from '../../utils/toast';

export type GradeGroupData = {
  uuid?: string;
  name: string;
  shortName: string;
  description?: string | null;
};

export const GradeGroupsTable: FC = () => {
  const { pimAuthClaims } = useAuthClaims();

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [gradeGroupUuid, setGradeGroupUuid] = useState<null | string>(null);
  const { confirm, ConfirmationDialog } = useConfirm();
  const { sorting, saveSorting } = useColumnsSort('gradeGroups-list', []);

  const context = useMemorizedCacheTag('GRADE_GROUPS');
  const [gradeGroupsResult] = useGradeGroupsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });
  const { data, fetching } = gradeGroupsResult;

  const [, deleteGradeGroups] = useMutation(DeleteGradeGroupsDocument);

  function createColumns(): TableColumns<GradeGroupData>[] {
    return [
      {
        header: t('common.name') as string,
        accessorKey: 'name',
        id: 'name',
        size: 300,
        canExpand: true,
      },
      {
        header: t('classes.shortName') as string,
        accessorKey: 'shortName',
        id: 'shortName',
        size: 150,
      },
      {
        header: t('common.description') as string,
        meta: {
          filterName: t('classes.level.full') as string,
          tooltip: t('classes.level.full') as string,
        },
        accessorKey: 'description',
        id: 'description',
        size: 300,
        canExpand: true,
      },
    ];
  }

  const tableColumns = useMemo(createColumns, [t]);

  const memoizedData = useMemo((): GradeGroupData[] => {
    return data?.gradeGroups ?? [];
  }, [data]);

  const onModalClose = () => {
    setGradeGroupUuid(null);
    setIsModalOpen(false);
  };

  const handleEdit = (row: Row<GradeGroupData>) => {
    setGradeGroupUuid(row.original.uuid ?? null);
    setIsModalOpen(true);
  };

  async function handleDelete(row: Row<GradeGroupData>) {
    if (!row.original.uuid) {
      return;
    }
    const confirmed = await confirm({
      message: t('gradeGroups.deleteConfirm', {
        gradeGroup: row.original.name,
      }),
    });

    if (confirmed) {
      const response = await deleteGradeGroups(
        {
          where: { uuid: row.original.uuid },
        },
        context,
      );

      if (response.error) {
        showUserErrorToast({ error: response.error });
      } else {
        showSuccessDeleteToast();
      }
    }
  }

  return (
    <>
      {!fetching && (
        <Table<GradeGroupData>
          showBorderRadius
          showShadow
          canScroll
          minHeight={600}
          breakpoint={null}
          isOnWhite={false}
          showSort
          sorting={sorting}
          onSortingChange={saveSorting}
          columns={tableColumns}
          data={memoizedData}
          showActionBar
          actionBarSettings={{
            showExpertFilter: true,
            showAddButton: true,
            showPrintButton: true,
          }}
          onAddClick={() => {
            setIsModalOpen(true);
          }}
          lastColWidth='80px'
          showSelect={false}
          lastCol={(row) => {
            return (
              <ButtonGroup>
                <Button
                  hierarchy='secondary'
                  type='button'
                  onClick={() => handleEdit(row)}
                  icon={<EditIcon className='small' />}
                />
                <Button
                  hierarchy='secondary'
                  type='button'
                  icon={<DeleteIcon className='small' />}
                  onClick={() => handleDelete(row)}
                />
              </ButtonGroup>
            );
          }}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        title={t('gradeGroups.add')}
      >
        <GradeGroupForm
          gradeGroupUuid={gradeGroupUuid}
          closeForm={() => {
            setIsModalOpen(false);
          }}
        />
      </Modal>

      <ConfirmationDialog />
    </>
  );
};
