import { Button, DotsHorizontalIcon, Dropdown, DropdownMenu, Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { SchoolEventType } from '../../pages/Substitutions/Setup/SchoolEvent/SetupSchoolEvent';

type SchoolEventTableProps = {
  onAdd: () => void;
  onEdit: (uuid: string) => void;
  onDuplicate: (uuid: string) => void;
  onDelete: (uuid: string) => void;
  onSubmit: () => void;
};

export const SchoolEventTable = ({ onAdd, onEdit, onDuplicate, onDelete, onSubmit }: SchoolEventTableProps) => {
  const { t } = useTranslation();

  const columns: TableColumns<SchoolEventType>[] = [
    {
      header: t('common.name'),
      accessorKey: 'name',
      id: 'name',
    },
    {
      header: t('classes.title.plural'),
      accessorKey: 'classes',
      id: 'classes',
    },
    {
      header: t('subject.title.plural'),
      accessorKey: 'subjects',
      id: 'subjects',
    },
    {
      header: t('persons.title.plural'),
      accessorKey: 'teachers',
      id: 'teachers',
    },
    {
      header: t('common.timeFrame'),
      accessorKey: 'timeFrame',
      id: 'timeFrame',
    },
  ];

  return (
    <Table<SchoolEventType>
      isOnWhite={false}
      showBorderRadius
      showShadow
      showActionBar
      actionBarSettings={{ showAddButton: true, addButtonText: t('common.add') }}
      onAddClick={() => onAdd()}
      emptyStateSettings={{ title: t('schoolEvents.noEvents') }}
      columns={columns}
      data={[]}
      lastColWidth='80px'
      lastCol={(row) => {
        return (
          <Dropdown trigger={<Button icon={<DotsHorizontalIcon />} hierarchy='tertiary' />}>
            <DropdownMenu
              data={[
                {
                  label: t('common.edit'),
                  onClick: () => onEdit(row.original.uuid),
                },
                {
                  label: t('common.duplicate'),
                  onClick: () => onDuplicate(row.original.uuid),
                },
                {
                  label: t('common.delete'),
                  onClick: () => onDelete(row.original.uuid),
                },
              ]}
            />
          </Dropdown>
        );
      }}
    />
  );
};
