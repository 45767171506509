import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useMemo, useReducer } from 'react';
import { loadingReducer, loadingReducerInitialState } from '../../../reducer/loadingReducer';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import {
  ClassesListDocument,
  ClassesListQuery,
  ClassesListQueryVariables,
  CurriculumWhere,
  SortDirection,
  useAddDivisionGroupsMutation,
  useClassesListQuery,
  useCreateCurriculaMutation,
  useCreateDivisionsMutation,
  useCurriculaListQuery,
  useDeleteCurriculaMutation,
  useDivisionsQuery,
  useTeachingLoadFactorsQuery,
  useUpdateCurriculaMutation,
} from '../../../types/planung-graphql-client-defs';
import { curriculumResponseToTableType } from '../utils/curriculumResponseToTableType';
import { CurriculaTableDataType, CurriculumFormType } from '../types';
import { getTypeChecktArray, isNotEmpty } from '../../../utils/typeguards';
import { CombinedError } from 'urql';
import { useDeputateLessons } from '../../Lessons/hooks/useDeputateLessons';
import { LessonFormClassType, LessonFormType } from '../../Lessons/types';
import { DivisionsFormType } from '../../Divisions/Forms/DivisionsForm';
import { ClassListClassesDataType } from '../../Classes/graphql/types';
import { partition } from '../../../utils/arrayFunc';
import { LessonTableType } from '../../Lessons/Tables/TimetableVersionLessonsTable';
import { urqlClient } from '../../../utils/urqlClient';
import { v4 } from 'uuid';

type useCurriculumProps = {
  versionUuid: string;
  grade?: string | undefined;
  subjectUuid?: string | undefined;
};
export const useCurriculum = ({ versionUuid, grade, subjectUuid }: useCurriculumProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const { createLesson, lessonsData, deleteLessons: _deleteLessons } = useDeputateLessons({});

  const [loadingState, dispatch] = useReducer(loadingReducer, loadingReducerInitialState);

  const context = useMemorizedCacheTag('CURRICULA');
  const teachingLoadFactorContext = useMemorizedCacheTag('TEACHINGLOADFACTOR');
  const classesContext = useMemorizedCacheTag('CLASSES');
  const divisionsContext = useMemorizedCacheTag('DIVISIONS');

  const [, remove] = useDeleteCurriculaMutation();
  const [, create] = useCreateCurriculaMutation();
  const [, update] = useUpdateCurriculaMutation();
  const [, createDivision] = useCreateDivisionsMutation();
  const [, addDivisionGroups] = useAddDivisionGroupsMutation();

  const queryWhere: CurriculumWhere = {
    curriculumVersionConnection: {
      node: { uuid: versionUuid },
    },
  };

  if (subjectUuid)
    queryWhere.subject = {
      Subject: {
        uuid: subjectUuid,
      },
    };

  const [{ data: queryData }] = useCurriculaListQuery({
    variables: {
      where: queryWhere,
      organizationUuid: organizationUuid,
      sort: [{ curriculumClassesNames: SortDirection.Asc, subjectName: SortDirection.Asc }],
    },
    context,
  });

  const [{ data: teachingLoadFactors }] = useTeachingLoadFactorsQuery({
    variables: {
      where: {
        schoolYear: { uuid: schoolYear?.uuid },
      },
    },
    context: teachingLoadFactorContext,
  });

  const [{ data: classesData }, refetchClasses] = useClassesListQuery({
    variables: {
      organizationUuid: organizationUuid,
      schoolYearUuid: schoolYear?.uuid,
    },
    context: classesContext,
  });

  const [{ data: defaultDivisionsData }] = useDivisionsQuery({
    variables: {
      organizationUuid: organizationUuid,
      classCount: 0,
    },
    context: divisionsContext,
  });

  // transformations
  const tableData = useMemo(() => {
    return curriculumResponseToTableType({
      curriculumVersionUuid: versionUuid,
      teachingLoadFactors,
      queryCurriculaData: queryData,
      schoolYearUuid: schoolYear?.uuid,
      filterByGrade: grade,
      filterBySubject: subjectUuid,
    });
  }, [teachingLoadFactors, queryData, versionUuid, schoolYear, grade, subjectUuid]);

  const isSingle = (uuid: string) => {
    const curricula = getQueryData(uuid);
    return (
      curricula?.curriculumGrades.length === 1 ||
      curricula?.curriculumGrades.filter((c) => {
        return c.lessonClasses.length !== 0;
      }).length === 1
    );
  };

  const getQueryData = (uuid: string) => {
    return queryData?.curricula.find((c) => c.uuid === uuid);
  };

  const updateCheckboxes = async ({
    type,
    uuids,
    value,
  }: {
    type: 'teachingLoadEnabled' | 'timetableEnabled' | 'elective' | 'isClassTrip';
    uuids: string[];
    value: boolean;
  }) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    const res = await update({ where: { uuid_IN: uuids }, update: { [type]: value } }, context);
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return res;
  };

  const deleteCurriculum = async (curricula: CurriculaTableDataType[]) => {
    const uuids = curricula.map((c) => c.uuid);

    dispatch({ type: 'SET_LOADING', uuids: uuids });

    const teacherUuids = getTypeChecktArray<string>(curricula.flatMap((c) => c.teacherUuids));

    const curriculumUnitUuids = getTypeChecktArray<string>(
      curricula.flatMap((c) => c.lessonUnits?.map((lu) => lu.uuid)).filter((item) => isNotEmpty(item)),
    );

    const curriculumGradesUuids: string[] =
      (curricula
        .flatMap((c) => c.curriculumGrades?.map((cu) => cu.uuid))
        .filter((item) => isNotEmpty(item)) as string[]) ?? [];

    const result: { error?: CombinedError; success?: boolean } = await remove(
      {
        where: { uuid_IN: uuids },
        delete: {
          curriculumGrades: [{ where: { node: { uuid_IN: curriculumGradesUuids } } }],
          curriculumUnit: [{ where: { node: { uuid_IN: curriculumUnitUuids } } }],
          persons: [{ where: { node: { uuid_IN: teacherUuids } } }],
        },
      },
      context,
    );
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });

    return result;
  };

  const updateCurriculum = async ({
    curriculumUuid,
    values,
  }: {
    curriculumUuid: string;
    values: CurriculumFormType;
  }) => {
    return await update(
      {
        where: {
          uuid: curriculumUuid,
        },
        update: {
          subject: {
            Subject: {
              disconnect: {},
              connect: {
                where: {
                  node: {
                    uuid: values.subject?.uuid,
                  },
                },
              },
            },
          },
          comment: values.comment,
          subTitle: values.subTitle,
          teachingLoadEnabled: values.teachingLoadEnabled,
          timetableEnabled: values.timetableEnabled,
          elective: values.elective,
          isClassTrip: values.isClassTrip,
          persons: [
            {
              delete: [{}],
              create: values.teachers?.map((person, index) => {
                return {
                  edge: {
                    order: index,
                    present: person.present,
                    teachingLoad: person.teachingLoad,
                    description: person.name,
                  },
                  node: {
                    name: person.name,
                    organization: {
                      connect: {
                        where: {
                          node: { uuid: organizationUuid },
                        },
                      },
                    },
                  },
                };
              }),
            },
          ],
          curriculumGrades: [
            {
              delete: [{}],
              create: values.curriculumGrades
                ?.filter((cc) => {
                  return cc.grades && cc.grades.length > 0;
                })
                .map((curriculumClass, index) => ({
                  edge: { order: index },
                  node: {
                    gradeGroups: {
                      ...(curriculumClass.gradeGroups
                        ? {
                            connect: curriculumClass.gradeGroups.map((gg) => ({
                              where: {
                                node: { uuid: gg.uuid },
                              },
                            })),
                          }
                        : {}),
                    },
                    grades: curriculumClass.grades?.map((g) => g) ?? [],
                    groups: {
                      connect: curriculumClass.groups?.map((group) => ({
                        where: {
                          node: { uuid: group.uuid },
                        },
                      })),
                    },
                  },
                })),
            },
          ],
          curriculumUnit: [
            {
              delete: [{}],
              create: values.lessonUnit?.map((curriculumUnit, index) => {
                return {
                  edge: { order: index },
                  node: {
                    count: curriculumUnit.count,
                    duration: curriculumUnit.duration,
                    ...(curriculumUnit.subjectContainerUuid && {
                      subjectContainer: {
                        connect: {
                          where: {
                            node: {
                              uuid: curriculumUnit.subjectContainerUuid,
                            },
                          },
                        },
                      },
                    }),
                  },
                };
              }),
            },
          ],
          roomSupply: [
            {
              disconnect: [{ where: {} }],
              connect: values.roomSupply?.map((room, index) => ({
                where: { node: { uuid: room.uuid ?? '' } },
                edge: {
                  order: index,
                  descriptions: room.description,
                },
                overwrite: true,
              })),
            },
          ],
        },
      },
      context,
    );
  };

  const createCurriculum = async (values: CurriculumFormType) => {
    return create(
      {
        input: {
          subject: {
            Subject: {
              connect: { where: { node: { uuid: values.subject?.uuid ?? 'noConnect' } } },
            },

            SubjectContainer: {
              connect: {
                where: {
                  node: {
                    uuid: values.subject?.uuid ?? 'noConnect',
                  },
                },
              },
            },
          },
          comment: values.comment,
          subTitle: values.subTitle,
          teachingLoadEnabled: values.teachingLoadEnabled,
          timetableEnabled: values.timetableEnabled,
          elective: values.elective,
          isClassTrip: values.isClassTrip,
          persons: {
            create: values.teachers?.map((p, index) => ({
              node: {
                name: p.name,
                organization: {
                  connect: {
                    where: {
                      node: { uuid: organizationUuid },
                    },
                  },
                },
              },
              edge: {
                description: p.name,
                present: p.present,
                teachingLoad: p.teachingLoad,
                order: index,
              },
            })),
          },
          curriculumGrades: {
            // creates  CurriculumClasses
            create: values.curriculumGrades
              ?.filter((cc) => {
                return cc.grades && cc.grades.length > 0;
              })
              .map((curriculumClass, index) => ({
                edge: { order: index },
                node: {
                  gradeGroups: {
                    ...(curriculumClass.gradeGroups
                      ? {
                          connect: curriculumClass.gradeGroups.map((gg) => ({
                            where: {
                              node: { uuid: gg.uuid },
                            },
                          })),
                        }
                      : {}),
                  },
                  grades: curriculumClass.grades ?? [],
                  groups: {
                    connect: curriculumClass.groups?.map((group) => ({
                      where: {
                        node: { uuid: group.uuid },
                      },
                    })),
                  },
                },
              })),
          },
          curriculumVersion: {
            connect: {
              where: {
                node: {
                  uuid: values.curriculumVersionUuid,
                },
              },
            },
          },
          curriculumUnit: {
            create: values.lessonUnit?.map((curriculumUnit, index) => {
              return {
                edge: { order: index },
                node: {
                  count: curriculumUnit.count,
                  duration: curriculumUnit.duration,
                  ...(curriculumUnit.subjectContainerUuid && {
                    subjectContainer: {
                      connect: {
                        where: {
                          node: {
                            uuid: curriculumUnit.subjectContainerUuid,
                          },
                        },
                      },
                    },
                  }),
                },
              };
            }),
          },
          roomSupply: {
            connect: values.roomSupply?.map(({ uuid, description }, index) => ({
              where: { node: { uuid: uuid ?? '' } },
              edge: {
                order: index,
                descriptions: description,
              },
              overwrite: true,
            })),
          },
          organization: {
            connect: {
              where: {
                node: {
                  uuid: pimAuthClaims.getOrganizationUuid(),
                },
              },
            },
          },
        },
      },
      context,
    );
  };

  const addDivision = (values: DivisionsFormType, classUuid: string) => {
    const divisionGroups = values.groups.map((group, index) => {
      return {
        node: {
          name: group.name,
          shortName: group.shortName,
          organization: {
            connect: {
              where: {
                node: {
                  uuid: pimAuthClaims.getOrganizationUuid(),
                },
              },
            },
          },
        },
        edge: {
          order: index,
        },
      };
    });

    return createDivision(
      {
        divisionName: values.name,
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
        classUuid: classUuid,
        groups: divisionGroups,
      },
      context,
    );
  };
  const getClassesForSchoolyear = (curriculumUuid: string) => {
    const curriculum = getQueryData(curriculumUuid);
    const classes: ClassListClassesDataType[] = [];
    if (curriculum) {
      const isSingleCurriculum = isSingle(curriculum.uuid);
      if (isSingleCurriculum) {
        curriculum.curriculumGrades[0].lessonClasses.forEach((lessonClass) => {
          const grade = lessonClass.class.grade;
          const gradeGroupUuid =
            lessonClass.class.gradeGroup?.uuid === '' ? null : (lessonClass.class.gradeGroup?.uuid ?? null);
          const currentClass = classesData?.classes.find((c) => {
            return (
              c.grade === grade &&
              gradeGroupUuid === (c.gradeGroup?.uuid ?? null) &&
              c.schoolYear.uuid === schoolYear?.uuid
            );
          });
          if (currentClass) {
            classes.push(currentClass);
          }
        });
      } else {
        curriculum.curriculumGrades.forEach((curriculumGrade) => {
          const lessonClass = curriculumGrade.lessonClasses[0];
          const grade = lessonClass.class.grade;
          const gradeGroupUuid =
            lessonClass.class.gradeGroup?.uuid === '' ? null : (lessonClass.class.gradeGroup?.uuid ?? null);
          const currentClass = classesData?.classes.find((c) => {
            return (
              c.grade === grade &&
              gradeGroupUuid === (c.gradeGroup?.uuid ?? null) &&
              c.schoolYear.uuid === schoolYear?.uuid
            );
          });
          if (currentClass) {
            classes.push(currentClass);
          }
        });
      }
    }
    return classes;
  };

  const createLessonsFromCurriculum = async ({ uuids }: { uuids: string[] }) => {
    const promises: Promise<
      { error?: unknown; success?: boolean | undefined } | { error?: unknown; success?: boolean | undefined }[]
    >[] = [];
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    refetchClasses({ requestPolicy: 'network-only' });

    const curricula = queryData?.curricula.filter(
      (c) => uuids.includes(c.uuid) && getClassesForSchoolyear(c.uuid).length !== 0,
    );
    if (curricula === undefined) return false;

    for (const curriculum of curricula) {
      const lessons = getLessonsForCurriculums([curriculum.uuid]);
      if (lessons.length === 0) {
        const isSingleCurriculum = isSingle(curriculum.uuid);
        if (isSingleCurriculum) {
          for (const lessonClass of curriculum.curriculumGrades[0].lessonClasses) {
            const grade = lessonClass.class.grade;
            const gradeGroupUuid =
              lessonClass.class.gradeGroup?.uuid === '' ? null : (lessonClass.class.gradeGroup?.uuid ?? null);
            const groupUuids = lessonClass.groups?.map((group) => group.uuid);
            const groupNames = lessonClass.groups?.map((group) => group.name);

            const currentClass = classesData?.classes.find((c) => {
              return c.grade === grade && gradeGroupUuid === (c.gradeGroup?.uuid ?? null);
            });

            if (currentClass !== undefined) {
              let usedGroups = lessonClass.groups ?? [];
              const usedDivision = defaultDivisionsData?.divisions.find((defaultDivision) => {
                return defaultDivision.groupsConnection.edges.some((g) => groupUuids.includes(g.node.uuid));
              });

              if (usedDivision) {
                usedGroups = (
                  await ensureAndGetDivisionWithGroups(
                    currentClass.uuid,
                    usedDivision.name,
                    usedDivision.groupsConnection.edges.map((g) => g.node),
                  )
                ).filter((g) => groupNames.includes(g.name));
              } else {
                usedGroups = []; // to ensure that usedGroups is empty if no division is found
              }

              const data: LessonFormType = {
                subject: curriculum.subject,
                comment: curriculum.comment,
                subTitle: curriculum.subTitle,
                elective: curriculum.elective,
                teachingLoadEnabled: curriculum.teachingLoadEnabled,
                timetableEnabled: curriculum.timetableEnabled,
                roomSupply: curriculum.roomSupplyConnection.edges.map((rs) => {
                  return { uuid: rs.node.uuid, description: rs.properties.descriptions };
                }),
                lessonClasses: [{ uuid: v4(), class: { uuid: currentClass.uuid }, groups: usedGroups }],
                teachers: curriculum.personsConnection.edges.map((edge) => {
                  return {
                    present: edge.properties.present,
                    teachingLoad: edge.properties.teachingLoad,
                    description: edge.node.name, // keep name for description for backwards compatibility
                  };
                }),
                lessonUnit: curriculum.curriculumUnit.map((cu) => {
                  return {
                    subjectContainerUuid: cu.subjectContainer?.uuid,
                    count: cu.count,
                    duration: cu.duration,
                    blocked: false,
                    lessonUnitType: cu.subjectContainer?.uuid ? 'epoch' : 'subjectHour',
                  };
                }),
                curriculumUuid: curriculum.uuid,
              };
              promises.push(createLesson(data, false));
            }
          }
        } else {
          const lessonClasses: LessonFormClassType[] | undefined = [];

          for (const curriculumGrade of curriculum.curriculumGrades) {
            const existingLessonClasses = curriculumGrade.lessonClasses.filter((lc) => {
              const currentClass = classesData?.classes.find((c) => {
                return c.grade === lc.class.grade && lc.class.gradeGroup?.uuid === (c.gradeGroup?.uuid ?? '');
              });
              return !!currentClass?.uuid;
            });

            for (const lc of existingLessonClasses) {
              const groupUuids = lc.groups?.map((group) => group.uuid);
              const groupNames = lc.groups?.map((group) => group.name);
              const currentClass = classesData?.classes.find((c) => {
                return c.grade === lc.class.grade && lc.class.gradeGroup?.uuid === (c.gradeGroup?.uuid ?? '');
              });
              const usedDivision = defaultDivisionsData?.divisions.find((defaultDivision) => {
                return defaultDivision.groupsConnection.edges.some((g) => groupUuids.includes(g.node.uuid));
              });

              let usedGroups = lc.groups ?? [];
              if (usedDivision) {
                usedGroups = (
                  await ensureAndGetDivisionWithGroups(
                    currentClass?.uuid ?? '',
                    usedDivision.name,
                    usedDivision.groupsConnection.edges.map((g) => g.node),
                  )
                ).filter((g) => groupNames.includes(g.name));
              } else {
                usedGroups = []; // to ensure that usedGroups is empty if no division is found
              }

              lessonClasses.push({
                uuid: v4(),
                class: { uuid: currentClass?.uuid ?? '' },
                groups: usedGroups,
              });
            }
          }

          const data: LessonFormType = {
            subject: curriculum.subject,
            comment: curriculum.comment,
            subTitle: curriculum.subTitle,
            elective: curriculum.elective,
            teachingLoadEnabled: curriculum.teachingLoadEnabled,
            timetableEnabled: curriculum.timetableEnabled,
            roomSupply: curriculum.roomSupply,
            lessonClasses: lessonClasses,
            teachers: curriculum.personsConnection.edges.map((edge) => {
              return { present: edge.properties.present, teachingLoad: edge.properties.teachingLoad };
            }),
            lessonUnit: curriculum.curriculumUnit.map((cu) => {
              return {
                subjectContainerUuid: cu.subjectContainer?.uuid,
                count: cu.count,
                duration: cu.duration,
                blocked: false,
                lessonUnitType: cu.subjectContainer?.uuid ? 'epoch' : 'subjectHour',
              };
            }),
            curriculumUuid: curriculum.uuid,
          };
          promises.push(createLesson(data, false));
        }
      }
    }
    const results = await Promise.all(promises);
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return results.every((r) => (Array.isArray(r) ? r.every((k) => !k.error) : !r.error));
  };

  /**
   * Ensures that the division with the given name exists in the class with the given name and that all
   * default groups are defined.
   * @param classUuid
   * @param divisionName
   * @param groups
   */
  const ensureAndGetDivisionWithGroups = async (
    classUuid: string,
    divisionName: string,
    groups: { name: string; shortName: string }[],
  ) => {
    let currentClassesData = await urqlClient
      .query<ClassesListQuery, ClassesListQueryVariables>(
        ClassesListDocument,
        {
          organizationUuid: organizationUuid,
          schoolYearUuid: schoolYear?.uuid,
          classUuid: classUuid,
        },
        { requestPolicy: 'network-only' },
      )
      .toPromise();

    const currentClass = currentClassesData.data?.classes.find((c) => c.uuid === classUuid);
    if (!currentClass) return [];

    const currentDivision = currentClass?.divisions.find((d) => d.name === divisionName);
    if (currentDivision) {
      const missingGroups = groups.filter(
        (group) => !currentDivision.groupsConnection.edges.some((g) => g.node.name === group.name),
      );
      if (missingGroups) {
        await addDivisionGroups({
          divisionUuid: currentDivision.uuid,
          groups: missingGroups?.map((groupName, i) => {
            return {
              node: {
                name: groupName.name,
                shortName: groupName.shortName,
                organization: { connect: { where: { node: { uuid: organizationUuid } } } },
              },
              edge: { order: i },
            };
          }),
        });
      }
    } else {
      await addDivision(
        {
          groups: groups.map((group) => {
            return {
              name: group.name,
              shortName: group.shortName,
              organization: { connect: { where: { node: { uuid: organizationUuid } } } },
            };
          }),
          name: divisionName,
        },
        currentClass.uuid,
      );
    }

    currentClassesData = await urqlClient
      .query<ClassesListQuery, ClassesListQueryVariables>(
        ClassesListDocument,
        {
          organizationUuid: organizationUuid,
          schoolYearUuid: schoolYear?.uuid,
          classUuid: classUuid,
        },
        { requestPolicy: 'network-only' },
      )
      .toPromise();

    return (
      currentClassesData.data?.classes
        .find((c) => c.uuid === classUuid)
        ?.divisions.find((d) => d.name === divisionName)
        ?.groupsConnection.edges.filter((g) => groups.some((group) => group.name === g.node.name))
        .map((g) => g.node) ?? []
    );
  };

  const duplicateCurriculum = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    const curricula = queryData?.curricula.filter((c) => uuids.includes(c.uuid));
    const createResponses =
      curricula?.map((curriculum) =>
        createCurriculum({
          comment: curriculum.comment ?? '',
          subTitle: curriculum.subTitle ?? '',
          timetableEnabled: curriculum.timetableEnabled ?? false,
          teachingLoadEnabled: curriculum.timetableEnabled ?? false,
          elective: curriculum.elective ?? false,
          curriculumGrades:
            curriculum.curriculumGrades.map((curriculumClass) => ({
              gradeGroups: curriculumClass.gradeGroups,
              grades: curriculumClass.grades,
              groups: curriculumClass.groups,
              uuid: curriculumClass.uuid,
            })) ?? [],
          // need this name, because we use the LessonUnitForm which names the field;
          // naming it curriculumUnit breaks the form, thus creation / update
          lessonUnit: curriculum.curriculumUnit.map((cu) => ({
            uuid: cu.uuid,
            duration: cu.duration,
            count: cu.count,
            subjectContainerUuid: cu.subjectContainer?.uuid,
            factor: null,
            blocked: false,
            lessonUnitType: cu.subjectContainer?.uuid ? 'epoch' : 'subjectHour',
          })),
          subject: {
            uuid: curriculum.subject.uuid,
            name: curriculum.subject.name,
            subjectHourEnabled:
              curriculum.subject.__typename === 'Subject' ? curriculum.subject.subjectHourEnabled : false,
            epochEnabled: curriculum.subject.__typename === 'Subject' ? curriculum.subject.epochEnabled : false,
          },
          teachers: curriculum.persons?.map((person) => {
            const properties = curriculum.personsConnection?.edges.find(
              (edge) => edge.node.uuid === person.uuid,
            )?.properties;

            return {
              ...person,
              teachingLoad: !!properties?.teachingLoad,
              present: !!properties?.present,
            };
          }),
          roomSupply:
            curriculum.roomSupplyConnection &&
            curriculum.roomSupplyConnection.edges.map((edge) => ({
              uuid: edge.node.uuid,
              description: edge.properties.descriptions ?? '',
            })),
          curriculumVersionUuid: versionUuid,
        }),
      ) ?? [];

    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });

    return Promise.all(createResponses);
  };

  const getLessonsForCurriculums = (curriculumUuids: string[]) => {
    const result: LessonTableType[] = [];
    curriculumUuids.forEach((curriculumUuid) => {
      const curriculum = getQueryData(curriculumUuid);
      const lessonUuids = curriculum?.lessons.map((l) => l.uuid);
      if (lessonUuids) {
        const lessons = lessonsData.filter(
          (ld) => lessonUuids.includes(ld.uuid) && ld.schoolYearUuid === schoolYear?.uuid,
        );
        result.push(...lessons);
      }
    });
    return result;
  };

  const deleteLessons = async (curriculumUuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: curriculumUuids });
    let result: { error?: unknown; success?: boolean } = {};
    const lessons = getLessonsForCurriculums(curriculumUuids);
    const [canBeDeleted] = partition(lessons, (l) => l.editStatus !== 'blocked' && l.editStatus !== 'placedCards');
    const uuidsToDelete: string[] = canBeDeleted.map((c) => c.uuid);
    if (uuidsToDelete.length > 0) {
      result = await _deleteLessons(uuidsToDelete);
    }

    dispatch({ type: 'REMOVE_LOADING', uuids: curriculumUuids });
    return result;
  };

  return {
    tableData,
    loadingState,
    queryData,
    updateCheckboxes,
    createLessonsFromCurriculum,
    deleteCurriculum,
    updateCurriculum,
    createCurriculum,
    duplicateCurriculum,
    getClassesForSchoolyear,
    getLessonsForCurriculums,
    getQueryData,
    deleteLessons,
    isSingle,
  };
};
