import { formatNumber } from '../../../../utils/helper';
import styles from '../../LessonsComponents.module.scss';
import { Card } from '@bp/ui-components';
import { BillingMethods } from '@bp/planung-graphql-types';
import { useTranslation } from 'react-i18next';
import { _evalBillingMethod } from './utils/eval-billing-method';
import classNames from 'classnames';

type TeacherStatsProps = {
  deputateLesson: number;
  deputateManually: number;
  deputateContract: number;
  billingMethod?: BillingMethods | null;
  limitedToFullTime?: boolean | null;
  fullTimeHours?: number | null;
};

export const TeacherStats = ({
  deputateLesson = 0,
  deputateManually = 0,
  deputateContract = 0,
  billingMethod,
  limitedToFullTime,
  fullTimeHours,
}: TeacherStatsProps) => {
  const { t } = useTranslation();

  const payed = _evalBillingMethod({
    deputateLesson,
    deputateManually,
    deputateContract,
    billingMethod,
    limitedToFullTime,
    fullTimeHours,
  });

  const actualWorkload = deputateManually + deputateLesson;

  return (
    <Card className={styles['teacher-stats']} contentPadding='none'>
      <div className={styles.stat}>
        <div>{t('deputate.deputateLesson')}</div>
        <div>{formatNumber(deputateLesson)}</div>
      </div>

      <div className={styles.stat}>
        <div>{t('deputate.deputateManually')}</div>
        <div>{formatNumber(deputateManually)}</div>
      </div>

      <div className={classNames(styles.stat, styles.bold)}>
        <div>{t('deputate.deputateTotal')}</div>
        <div>{formatNumber(actualWorkload)}</div>
      </div>

      <div className={classNames(styles.stat, styles.bold, styles.border)}>
        <div>{t('deputate.deputateContract')}</div>
        <div>{formatNumber(deputateContract)}</div>
      </div>

      <div className={classNames(styles.stat, styles.bold)}>
        <div>{t('common.difference')}</div>
        <div>{formatNumber(actualWorkload - deputateContract)}</div>
      </div>

      <div className={styles.stat}>
        <div>{t('deputate.payed')}</div>
        <div>{formatNumber(payed)}</div>
      </div>

      <div className={styles.stat}>
        <div>{t('deputate.differencePayedLoad')}</div>
        <div>{formatNumber(payed - actualWorkload)}</div>
      </div>
    </Card>
  );
};
