import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeachingLoadReports } from '../../../../components/EvaluateTeachingLoad/TeachingLoadReports';
import { TeachingLoadReportType } from '@bp/planung-graphql-types';

export const CurrentReports = () => {
  const { t } = useTranslation();

  const tableData = useMemo(() => {
    return [
      { id: TeachingLoadReportType.CurrentSingleTeacher, name: t('pdf.currentDetails') },
      { id: TeachingLoadReportType.CurrentOverview, name: t('pdf.currentOverview') },
      { id: TeachingLoadReportType.CurrentSubjectGroup, name: t('pdf.currentSubjectGroups') },
      { id: null, name: t('common.notYet', { planned: t('pdf.currentKeyFigures') }) },
      { id: TeachingLoadReportType.CurrentExcel, name: t('pdf.excelExport') },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TeachingLoadReports tableData={tableData} />;
};
