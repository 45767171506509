import dayjs from 'dayjs';

type DateString = string; // 'YYYY-MM-DD'
type ResourceUUID = string; // Klasse / DivisionGroupFormt / Lehrer / Raum UUID
type TeachingBlockCardUUID = string;

type ClassUuid = string;
type DivisionUuid = string;
type GroupUuid = string;

export type ClassDivisionGroupFormat = `${ClassUuid}:${DivisionUuid}:${GroupUuid}`;

export type AvailabilityResourceType = 'teacher' | 'class' | 'divisionGroup' | 'room' | 'subject';

export type Availability = Map<
  DateString,
  Map<
    ResourceUUID,
    {
      type: AvailabilityResourceType;
      maybe: boolean;
      blocked: boolean;
      placed: Set<TeachingBlockCardUUID>;
    }
  >
>;

export type ResourceAvailability = Map<
  ResourceUUID,
  Map<
    DateString,
    {
      type: AvailabilityResourceType;
      maybe: boolean;
      blocked: boolean;
      placed: Set<TeachingBlockCardUUID>;
    }
  >
>;

export type AvailabilityCardType = {
  uuid: string;
  classes: string[];
  classDivisionGroup: ClassDivisionGroupFormat[];
  teachers: string[];
  rooms: string[];
};

export type Context = 'teachers' | 'classes' | 'divisionGroups' | 'rooms';
export type AvailableType = 'blocked' | 'maybe' | 'placed' | 'available';

export class DayAvailabilityStore {
  availabilities: Availability = new Map();

  constructor(from: Date, to: Date) {
    const fromUtc = dayjs(from).startOf('day').utc();
    const toUtc = dayjs(to).startOf('day').utc();
    const allDays = toUtc.diff(fromUtc, 'days');

    for (let i = 0; i <= allDays; i++) {
      const dateString = fromUtc.add(i, 'days').format('YYYY-MM-DD');
      this.availabilities.set(dateString, new Map());
    }
  }

  isResourcePlaced(date: Date, resourceUUID: ResourceUUID) {
    const dateString = dayjs(date).utc().format('YYYY-MM-DD');
    const placedSize = this.availabilities.get(dateString)?.get(resourceUUID)?.placed.size ?? 0;
    return placedSize > 0;
  }

  isResourceBlocked(date: Date, resourceUUID: ResourceUUID) {
    const dateString = dayjs(date).utc().format('YYYY-MM-DD');
    return this.availabilities.get(dateString)?.get(resourceUUID)?.blocked ?? false;
  }

  isResourceMaybe(date: Date, resourceUUID: ResourceUUID) {
    const dateString = dayjs(date).utc().format('YYYY-MM-DD');
    return this.availabilities.get(dateString)?.get(resourceUUID)?.maybe ?? false;
  }

  setResourcePlaced(date: Date, resourceUUID: ResourceUUID, cardUuid: string, type: AvailabilityResourceType) {
    const dateString = dayjs(date).utc().format('YYYY-MM-DD');
    const dayAvailabilities = this.availabilities.get(dateString);
    if (dayAvailabilities) {
      const currentBlocked = dayAvailabilities?.get(resourceUUID)?.blocked ?? false;
      const currentMaybe = dayAvailabilities?.get(resourceUUID)?.maybe ?? false;
      const currentPlaced = dayAvailabilities?.get(resourceUUID)?.placed ?? new Set();
      dayAvailabilities?.set(resourceUUID, {
        type: type,
        placed: currentPlaced.add(cardUuid),
        maybe: currentMaybe,
        blocked: currentBlocked,
      });
    }
  }

  removeResourcePlaced(date: Date, resourceUUID: ResourceUUID, cardUuid: string, type: AvailabilityResourceType) {
    const dateString = dayjs(date).utc().format('YYYY-MM-DD');
    const dayAvailabilities = this.availabilities.get(dateString);
    if (dayAvailabilities) {
      const currentBlocked = dayAvailabilities?.get(resourceUUID)?.blocked ?? false;
      const currentMaybe = dayAvailabilities?.get(resourceUUID)?.maybe ?? false;
      const currentPlaced = dayAvailabilities?.get(resourceUUID)?.placed ?? new Set();
      currentPlaced.delete(cardUuid);
      dayAvailabilities?.set(resourceUUID, {
        type: type,
        placed: currentPlaced,
        maybe: currentMaybe,
        blocked: currentBlocked,
      });
    }
  }

  setResourceBlocked(date: Date, resourceUUID: ResourceUUID, blocked: boolean, type: AvailabilityResourceType) {
    const dateString = dayjs(date).utc().format('YYYY-MM-DD');
    const newAvailabilities = this.availabilities.get(dateString);
    if (newAvailabilities) {
      const currentMaybe = newAvailabilities?.get(resourceUUID)?.maybe ?? false;
      const currentPlaced = newAvailabilities?.get(resourceUUID)?.placed ?? new Set();
      newAvailabilities?.set(resourceUUID, { type: type, placed: currentPlaced, maybe: currentMaybe, blocked });
    }
  }

  setResourceMaybe(date: Date, resourceUUID: ResourceUUID, maybe: boolean, type: AvailabilityResourceType) {
    const dateString = dayjs(date).utc().format('YYYY-MM-DD');
    const newAvailabilities = this.availabilities.get(dateString);
    if (newAvailabilities) {
      const currentBlocked = newAvailabilities?.get(resourceUUID)?.blocked ?? false;
      const currentPlaced = newAvailabilities?.get(resourceUUID)?.placed ?? new Set();
      newAvailabilities?.set(resourceUUID, {
        type: type,
        placed: currentPlaced,
        maybe,
        blocked: currentBlocked,
      });
    }
  }

  removeExclusion({
    from,
    holder,
    priority,
    to,
  }: {
    holder:
      | { __typename: 'Class'; uuid: string }
      | { __typename: 'Person'; uuid: string }
      | { __typename: 'Room'; uuid: string }
      | { __typename: 'Subject'; uuid: string };
    from: Date;
    to: Date;
    priority: number;
  }) {
    const type =
      holder.__typename === 'Class'
        ? 'class'
        : holder.__typename === 'Person'
          ? 'teacher'
          : holder.__typename === 'Room'
            ? 'room'
            : 'subject';
    if (priority === 0) {
      this.setBlockedForRange({ start: from, end: to }, holder.uuid, false, type);
    }
    if (priority === 1) {
      this.setMaybeForRange({ start: from, end: to }, holder.uuid, false, type);
    }
  }

  setExclusion({
    from,
    holder,
    priority,
    to,
  }: {
    holder:
      | { __typename: 'Class'; uuid: string }
      | { __typename: 'Person'; uuid: string }
      | { __typename: 'Room'; uuid: string }
      | { __typename: 'Subject'; uuid: string };
    from: Date;
    to: Date;
    priority: number;
  }) {
    const type =
      holder.__typename === 'Class'
        ? 'class'
        : holder.__typename === 'Person'
          ? 'teacher'
          : holder.__typename === 'Room'
            ? 'room'
            : 'subject';
    if (priority === 0) {
      this.setBlockedForRange({ start: from, end: to }, holder.uuid, true, type);
    }
    if (priority === 1) {
      this.setMaybeForRange({ start: from, end: to }, holder.uuid, true, type);
    }
  }

  setCardsResourcesPlaced(
    cards: AvailabilityCardType[],
    range: {
      start: Date;
      end: Date;
    },
  ) {
    cards.forEach((card) => {
      const { start, end } = range;
      const startDay = dayjs(start).startOf('day').utc();
      const endDay = dayjs(end).startOf('day').utc();
      const allDays = endDay.diff(startDay, 'days');
      for (let i = 0; i <= allDays; i++) {
        card.teachers.forEach((teacher) => {
          this.setResourcePlaced(startDay.add(i, 'days').toDate(), teacher, card.uuid, 'teacher');
        });
        card.classes.forEach((c) => {
          this.setResourcePlaced(startDay.add(i, 'days').toDate(), c, card.uuid, 'class');
        });
        card.classDivisionGroup.forEach((g) => {
          this.setResourcePlaced(startDay.add(i, 'days').toDate(), g, card.uuid, 'divisionGroup');
        });
        card.rooms.forEach((r) => {
          this.setResourcePlaced(startDay.add(i, 'days').toDate(), r, card.uuid, 'room');
        });
      }
    });
  }

  removeCardsResourcesPlaced(
    cards: AvailabilityCardType[],
    range: {
      start: Date;
      end: Date;
    },
  ) {
    cards.forEach((card) => {
      const { start, end } = range;
      const startDay = dayjs(start).startOf('day').utc();
      const endDay = dayjs(end).startOf('day').utc();
      const allDays = endDay.diff(startDay, 'days');
      for (let i = 0; i <= allDays; i++) {
        card.teachers.forEach((teacher) => {
          this.removeResourcePlaced(startDay.add(i, 'days').toDate(), teacher, card.uuid, 'teacher');
        });
        card.classes.forEach((c) => {
          this.removeResourcePlaced(startDay.add(i, 'days').toDate(), c, card.uuid, 'class');
        });
        card.classDivisionGroup.forEach((g) => {
          this.removeResourcePlaced(startDay.add(i, 'days').toDate(), g, card.uuid, 'divisionGroup');
        });
        card.rooms.forEach((r) => {
          this.removeResourcePlaced(startDay.add(i, 'days').toDate(), r, card.uuid, 'room');
        });
      }
    });
  }

  setBlockedForRange(
    range: { start: Date; end: Date },
    resourceUUID: ResourceUUID,
    blocked: boolean,
    type: AvailabilityResourceType,
  ) {
    const { start, end } = range;
    const startDay = dayjs(start).startOf('day').utc();
    const endDay = dayjs(end).startOf('day').utc();
    const allDays = endDay.diff(startDay, 'days');
    for (let i = 0; i <= allDays; i++) {
      this.setResourceBlocked(startDay.add(i, 'days').toDate(), resourceUUID, blocked, type);
    }
  }

  setMaybeForRange(
    range: { start: Date; end: Date },
    resourceUUID: ResourceUUID,
    maybe: boolean,
    type: AvailabilityResourceType,
  ) {
    const { start, end } = range;
    const startDay = dayjs(start).startOf('day').utc();
    const endDay = dayjs(end).startOf('day').utc();
    const allDays = endDay.diff(startDay, 'days');
    for (let i = 0; i <= allDays; i++) {
      this.setResourceMaybe(startDay.add(i, 'days').toDate(), resourceUUID, maybe, type);
    }
  }

  checkCardAvailability(
    date: Date,
    card: AvailabilityCardType,
    context: Context,
  ): { status: AvailableType; placed?: string[] } {
    const dateString = dayjs(date).utc().format('YYYY-MM-DD');
    const dayAvailabilities = this.availabilities.get(dateString);
    const placedCards: string[] = [];

    if (dayAvailabilities) {
      const blocked =
        card.teachers.some((teacher) => dayAvailabilities.get(teacher)?.blocked) ||
        card.classes.some((c) => dayAvailabilities.get(c)?.blocked) ||
        card.classDivisionGroup.some((g) => dayAvailabilities.get(g)?.blocked) ||
        card.rooms.some((r) => dayAvailabilities.get(r)?.blocked) ||
        false;

      if (blocked) return { status: 'blocked' };

      let placed = card.teachers.some((teacher) => {
        const cards = dayAvailabilities.get(teacher)?.placed;
        return cards ? [...cards].filter((c) => c !== card.uuid).length > 0 : false;
      });
      placed =
        placed ||
        (context === 'classes'
          ? false
          : card.classes.some((c) => {
              const cards = dayAvailabilities.get(c)?.placed;
              return cards ? [...cards].filter((c) => c !== card.uuid).length > 0 : false;
            }));
      placed =
        placed ||
        card.rooms.some((r) => {
          const cards = dayAvailabilities.get(r)?.placed;
          return cards ? [...cards].filter((c) => c !== card.uuid).length > 0 : false;
        });

      if (!placed) {
        card.classDivisionGroup.forEach((g) => {
          const [classUuid, divisionUuid, groupUuid] = g.split(':');
          dayAvailabilities.forEach((value, key) => {
            const placedWithoutCurrent = [...value.placed].filter((c) => c !== card.uuid);
            if (key.includes(':')) {
              // andere Teilung
              if (key.includes(classUuid) && !key.includes(divisionUuid) && placedWithoutCurrent.length > 0) {
                placed = true;
                placedCards.push(...value.placed);
              }
              // gleiche Teilung gleiche Gruppe
              if (
                key.includes(classUuid) &&
                key.includes(divisionUuid) &&
                key.includes(groupUuid) &&
                placedWithoutCurrent.length > 0
              ) {
                placed = true;
                placedCards.push(...value.placed);
              }
            }
          });
        });
      }

      if (placed) return { status: 'placed', placed: placedCards };

      const maybe =
        card.teachers.some((teacher) => dayAvailabilities.get(teacher)?.maybe) ||
        card.classes.some((c) => dayAvailabilities.get(c)?.maybe) ||
        card.classDivisionGroup.some((g) => dayAvailabilities.get(g)?.maybe) ||
        card.rooms.some((r) => dayAvailabilities.get(r)?.maybe) ||
        false;

      if (maybe) return { status: 'maybe' };
    }
    return { status: 'available' };
  }

  getResourceAvailability(resourceUUID: ResourceUUID): ResourceAvailability {
    const result: ResourceAvailability = new Map();

    this.availabilities.forEach((dateMap, dateString) => {
      const resourceDetails = dateMap.get(resourceUUID);
      if (resourceDetails) {
        let resourceDateMap = result.get(resourceUUID);
        if (!resourceDateMap) {
          resourceDateMap = new Map();
          result.set(resourceUUID, resourceDateMap);
        }
        resourceDateMap.set(dateString, {
          type: resourceDetails.type,
          maybe: resourceDetails.maybe,
          blocked: resourceDetails.blocked,
          placed: new Set(resourceDetails.placed),
        });
      }
    });

    return result;
  }
}
