import { LoaderFunctionArgs } from 'react-router-dom';
import { isFilterType } from '../typeguards';

export type FilterLoaderArgs = {
  column: string;
  filterValue: string;
};

export async function filterLoader(args: LoaderFunctionArgs<unknown>): Promise<null | FilterLoaderArgs> {
  const url = new URL(args.request.url);
  const filterConfigString = url.searchParams.get('filter');
  if (filterConfigString) {
    try {
      const filterConfig = JSON.parse(filterConfigString);
      if (isFilterType(filterConfig)) {
        return filterConfig;
      }
      return null;
    } catch {
      return null;
    }
  }
  return null;
}
