import { DatePicker } from '@bp/ui-components';
import { useFormikContext } from 'formik';
import { HolidayType } from '../graphql';
import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

type DatePickerHolidaysFieldProps = {
  label: string;
  fieldName: 'end' | 'start';
  updateEnd: boolean;
};

export const DatePickerHolidaysField = ({ label, fieldName, updateEnd }: DatePickerHolidaysFieldProps) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<HolidayType>();

  const newEnd = useMemo(() => {
    return dayjs(values.start).utc(true).add(1, 'days').toDate();
  }, [values.start]);

  useEffect(() => {
    if (fieldName === 'start' && touched.start && !touched.end && updateEnd) {
      setFieldValue('end', newEnd, true);
    }
  }, [fieldName, newEnd, setFieldTouched, setFieldValue, touched.end, touched.start]);

  return (
    <DatePicker
      label={label}
      name={fieldName}
      onChange={(date) => {
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, dayjs(date).utc(true).toDate());
      }}
      value={values[fieldName]}
      error={errors[fieldName] as string}
      showIcon
    />
  );
};
