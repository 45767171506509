import * as Yup from 'yup';
import i18next from 'i18next';
import dayjs from 'dayjs';

function formatDate(date: string | Date) {
  return new Date(date).toLocaleDateString();
}

function validateContractsNoOverlap(value: { validFrom: Date; validUntil?: Date | null }[]) {
  return value.every((contractA, index) =>
    value.slice(index + 1).every((contractB) => {
      const contractAStart = dayjs(contractA.validFrom);
      const contractAEnd = contractA.validUntil ? dayjs(contractA.validUntil) : dayjs().add(1000, 'year'); // Ein weit in der Zukunft liegendes Enddatum, falls kein Enddatum vorhanden ist
      const contractBStart = dayjs(contractB.validFrom);
      const contractBEnd = contractB.validUntil ? dayjs(contractB.validUntil) : dayjs().add(1000, 'year'); // Ein weit in der Zukunft liegendes Enddatum, falls kein Enddatum vorhanden ist

      return contractAEnd.isBefore(contractBStart) || contractBEnd.isBefore(contractAStart);
    }),
  );
}

const contractSchema = Yup.object().shape({
  name: Yup.string().required(i18next.t('validation.persons.contracts.nameRequired')),
  validFrom: Yup.date().nullable().required(i18next.t('validation.persons.contracts.validFromRequired')),
  validUntil: Yup.date()
    .nullable()
    .min(Yup.ref('validFrom'), ({ min }) =>
      i18next.t('validation.persons.contracts.afterData', { date: formatDate(min) }),
    ),
  freelancer: Yup.boolean().nullable(),
  hoursWeekly: Yup.number()
    .required('validation.persons.contracts.hoursWeeklyRequired')
    .min(0, i18next.t('validation.persons.contracts.hoursWeeklyMin', { min: 0 })),
  substituteHours: Yup.number()
    .nullable()
    .min(0, i18next.t('validation.persons.contracts.substituteHoursMin', { min: 0 })),
});

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(i18next.t('validation.persons.firstNameRequired')),
  lastName: Yup.string().required(i18next.t('validation.persons.lastNameRequired')),
  shortName: Yup.string(),
  email: Yup.string().notRequired().email(i18next.t('validation.persons.invalidEmail')),
  personalId: Yup.string().notRequired(),
  contracts: Yup.array()
    .of(contractSchema)
    .max(100, i18next.t('validation.persons.contracts.max'))
    .test('no-overlap', i18next.t('validation.persons.contracts.overlap'), validateContractsNoOverlap),
  qualifications: Yup.array().of(
    Yup.object().shape({
      minGrade: Yup.number()
        .nullable()
        .min(0, i18next.t('validation.persons.qualifications.gradeMin', { min: 0 }))
        .max(20, i18next.t('validation.persons.qualifications.gradeMax', { max: 20 })),
      maxGrade: Yup.number()
        .nullable()
        .min(0, i18next.t('validation.persons.qualifications.gradeMin', { min: 0 }))
        .max(20, i18next.t('validation.persons.qualifications.gradeMax', { max: 20 })),
      maxTeachingLoad: Yup.number()
        .nullable()
        .min(0, i18next.t('validation.persons.qualifications.maxTeachingLoadMin', { min: 0 })),
    }),
  ),
});
