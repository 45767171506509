import { useTimetableClasses } from '../hooks/useTimetableClasses';
import { Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { selectedToData } from '../../../utils/selectedToData';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { observer } from 'mobx-react-lite';
import { useTimetableStore } from '../../TimetableGrid/TimetableProvider';

type TimetableVersionImportClassesTableType = {
  uuid: string;
  name: string;
  shortName: string;
  grade: number | null;
};

export const TimetableVersionImportClasses = observer(
  ({ versionUuid, onClose }: { versionUuid: string; onClose: () => void }) => {
    const store = useTimetableStore();

    const versionClassesUuids: string[] = Array.from(store.classes.keys());

    const { classesData } = useLoadBasicData({ pause: !versionUuid });

    const delta = classesData?.classes.filter((c) => !versionClassesUuids.includes(c.uuid));

    const { importClasses } = useTimetableClasses(versionUuid);

    const { t } = useTranslation();

    function createColumns(): TableColumns<TimetableVersionImportClassesTableType>[] {
      return [
        {
          header: t('common.name'),
          accessorKey: 'name',
          id: 'name',
          size: 250,
        },
        {
          header: t('classes.shortName'),
          accessorKey: 'shortName',
          id: 'shortName',
          size: 75,
        },
        {
          header: t('classes.level.short'),
          meta: {
            filterName: t('classes.level.full'),
            tooltip: t('classes.level.full'),
          },
          id: 'grade',
          size: 75,
          accessorFn: (row) => {
            return (row.grade || row.grade === 0) && row.grade >= 0 ? row.grade : '-';
          },
          cell: ({ row }) => {
            return (row.original.grade || row.original.grade === 0) && row.original.grade >= 0
              ? row.original.grade
              : '-';
          },
        },
      ];
    }

    const data: TimetableVersionImportClassesTableType[] = useMemo(() => {
      return (
        delta?.map((c) => ({
          uuid: c.uuid,
          name: c.name,
          shortName: c.shortName,
          grade: c.grade ?? null,
        })) ?? []
      );
    }, [delta]);

    const [selected, setSelected] = useState<{ [key: string]: boolean }>({});
    const [loading, setLoading] = useState<boolean>(false);

    return (
      <>
        <Table<TimetableVersionImportClassesTableType>
          rowSelection={selected}
          onRowSelectionChange={setSelected}
          showSelected={true}
          columns={createColumns()}
          data={data}
          showSelect={true}
          showActionBar={true}
          actionBarSettings={{
            showAddButton: false,
            showSelectedRowsCount: true,
          }}
        />
        <ModalBottomButtons
          closeButton={{
            text: t('common.cancel'),
            callback: () => {
              onClose();
            },
          }}
          submitButton={{
            disabled: selected ? Object.keys(selected).length === 0 : true,
            text: t('common.import'),
            callback: async () => {
              setLoading(true);
              const selectedClasses = selectedToData<TimetableVersionImportClassesTableType>(selected, data);
              if (selectedClasses.length > 0) {
                await importClasses(selectedClasses.map((c) => c.uuid));
                store.addClasses(
                  classesData?.classes.filter((c) => selectedClasses.map((sc) => sc.uuid).includes(c.uuid)) ?? [],
                );
              }
              setLoading(false);
              onClose();
            },
          }}
          isLoading={loading}
        />
      </>
    );
  },
);
