import { Outlet, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  ClassGridCardsQuery,
  use_TimeGridEntriesQuery,
  use_TimetablesQuery,
} from '../../../../types/planung-graphql-client-defs';
import { AccessIcon, EmptyState, LazyLoader } from '@bp/ui-components';
import { GridCardsStore } from '../../../../stores/GridCardsStore';
import { Page } from '../../../../components/Page/Page';
import { TimetableVersionTopBar } from '../../../../components/TimetableVersion/TimetableVersionTopBar/TimetableVersionTopBar';
import { TimetableProvider } from '../../../../components/TimetableGrid/TimetableProvider';
import { StoreHandler } from '../../../../stores/StoreHandler';
import { TimeAvailabilityStore } from '../../../../stores/TimeAvailabilityStore';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { useTranslation } from 'react-i18next';
import { AvailabilityStore } from '../../../../stores/AvailabilityStore';

export const gridCardsStore = new GridCardsStore();
export const timeAvailabilityStoreHandler = new StoreHandler<TimeAvailabilityStore>();
export const availabilityStore = new AvailabilityStore();

export type ClassGridCards = ClassGridCardsQuery['classGridCards'][0];

export const TimetableVersion = observer(({ baseSlug }: { baseSlug: string }) => {
  const { versionUuid } = useParams();

  const [{ data: timetableData }] = use_TimetablesQuery({
    variables: {
      where: {
        versions_SOME: { uuid: versionUuid },
      },
    },
    pause: !versionUuid,
  });

  const timegridUuid = timetableData?.timetables[0].timegridConfigsConnection.edges[0].node.timegrid?.uuid;

  const [{ data: timeGridEntriesData }] = use_TimeGridEntriesQuery({
    variables: {
      where: {
        timeGrid: {
          uuid: timegridUuid,
        },
      },
    },
    pause: !timegridUuid,
  });

  const schoolYear = useUserConfigContext();

  const [ready, setReady] = useState(false);

  const [inSchoolYear, setInSchoolYear] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (versionUuid) {
      const inSchoolYear = timetableData?.timetables[0].schoolYear.uuid === schoolYear.selectedSchoolYear?.uuid;
      setInSchoolYear(inSchoolYear);
    }
  }, [versionUuid, timetableData, schoolYear.selectedSchoolYear]);

  useEffect(() => {
    if (versionUuid && timetableData && timeGridEntriesData) {
      timeAvailabilityStoreHandler.register(versionUuid, () => {
        return new TimeAvailabilityStore('00:00', '24:00', undefined, timeGridEntriesData.timeGridEntries);
      });
      setReady(true);
    }
  }, [timetableData, versionUuid, timeGridEntriesData]);

  return (
    <Page title={null} fullWidth fullHeight>
      {versionUuid && ready && inSchoolYear ? (
        <TimetableProvider>
          <TimetableVersionTopBar versionUuid={versionUuid} baseSlug={baseSlug} />
          <Outlet />
        </TimetableProvider>
      ) : (
        <LazyLoader embedded transparent forceHeight='60vh' />
      )}
      {!inSchoolYear && <EmptyState icon={<AccessIcon />} title={t('timetableVersion.notInSchoolYear')}></EmptyState>}
    </Page>
  );
});
