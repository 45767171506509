import { TableColumns } from '@bp/ui-components';
import { t } from 'i18next';
import { TimetableVersionTableType } from '../graphql/types';
import { TimetableRelationshipProperties } from '@bp/planung-graphql-types';

export function createTimetableVersionTableColumns(name: string): TableColumns<TimetableVersionTableType>[] {
  return [
    {
      header: name,
      accessorKey: 'name',
      id: 'name',
      size: 150,
    },
    {
      header: t('common.shortName'),
      accessorKey: 'shortName',
      id: 'shortName',
      size: 90,
    },
    {
      header: t('common.count'),
      accessorKey: 'count',
      id: 'count',
      cell: () => {
        return 0;
      },
      size: 50,
    },
    {
      header: t('common.color'),
      accessorKey: 'color',
      id: 'color',
      type: 'color',
      size: 60,
    },
  ];
}

export const initialMatrix: TimetableRelationshipProperties = {
  mon: '22222222222222222222',
  tue: '22222222222222222222',
  wed: '22222222222222222222',
  thu: '22222222222222222222',
  fri: '22222222222222222222',
  sat: '22222222222222222222',
  sun: '22222222222222222222',
};
