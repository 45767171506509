import {
  ClassesIllustration,
  FactorIllustration,
  RoomIllustration,
  ScenarioIllustration,
  SubjectIllustration,
  TeacherIllustration,
  TransferQuotaTableIllustration,
} from '@bp/ui-components';
import IconMenu from '../../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../../components/IconMenuItem/IconMenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../../components/Page/Page';
import { memo } from 'react';

export const DeputateSetupPage = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page title={`${t('deputate.title')} ${t('deputate.setup').toLowerCase()}`}>
      <IconMenu headline={t('common.coreData')}>
        <IconMenuItem
          label={`${t('subject.title.plural')} / ${t('subjectContainer.title')}`}
          icon={<SubjectIllustration />}
          bubbleType={1}
          onClick={() => navigate(t('routes.subjects.slug'))}
        />
        <IconMenuItem
          label={t('teachers.titleSingular')}
          icon={<TeacherIllustration />}
          bubbleType={2}
          onClick={() => navigate(t('routes.teacher.slug'))}
        />
        <IconMenuItem
          label={t('classes.title.plural')}
          icon={<ClassesIllustration />}
          bubbleType={3}
          onClick={() => navigate(t('routes.classes.slug'))}
        />
        <IconMenuItem
          label={t('rooms.title.long')}
          icon={<RoomIllustration />}
          bubbleType={4}
          onClick={() => navigate(t('routes.rooms.slug'))}
        />
      </IconMenu>
      <IconMenu headline={t('common.additionalData')} hasMarginTop>
        <IconMenuItem
          label={t('routes.factors.title')}
          icon={<FactorIllustration />}
          onClick={() => navigate(t('routes.factors.slug'))}
          bubbleType={9}
        />
        <IconMenuItem
          label={t('curriculum.title.plural')}
          icon={<TransferQuotaTableIllustration />}
          bubbleType={11}
          onClick={() => navigate(t('routes.curriculum.slug'))}
        />
        <IconMenuItem
          label={t('common.scenarios')}
          icon={<ScenarioIllustration />}
          bubbleType={10}
          onClick={() => navigate(t('routes.schoolyear.slug'))}
        />
      </IconMenu>
    </Page>
  );
});

DeputateSetupPage.displayName = 'DeputateSetupPage';
