import { Checkbox, Grid, GridColumn, GridRow, Input, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { SingleValue } from 'react-select';
import { LessonFormType } from '../../types';
import { LessonTableType } from '../../Tables/TimetableVersionLessonsTable';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';

export const LessonBasicsForm = ({
  status,
  timetableDisabled,
}: {
  status: LessonTableType['editStatus'];
  timetableDisabled?: boolean | null;
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext<LessonFormType>();

  const { subjectData } = useLoadBasicData({ pause: false });

  const subjectsOptions = useCreateSelectOptions(
    subjectData?.subjects.filter((s) => {
      return s.epochEnabled || s.subjectHourEnabled;
    }),
    'uuid',
    'name',
  );
  const subjectValue = subjectsOptions.find((option) => {
    return option.value === formik.values.subject?.uuid;
  });

  return (
    <Grid useFormGap>
      <GridRow>
        <GridColumn width={8}>
          <GridRow spacingBottom={'none'}>
            <Select
              options={subjectsOptions}
              readonly={status === 'blocked'}
              name={'subject'}
              isSearchable
              onChange={async (option) => {
                const opt = option as SingleValue<SelectOptionType>;
                await formik.setFieldTouched(`subject`, true);
                if (opt) {
                  const subject = subjectData?.subjects.find((s) => s.uuid === opt.value);
                  await formik.setFieldValue(`subject`, { uuid: subject?.uuid, name: subject?.name }, true);
                } else {
                  await formik.setFieldValue(`subject`, null, true);
                }
              }}
              value={subjectValue}
              label={t('lesson.basics.name')}
              tooltipText={t('lesson.tooltip.name')}
            />
          </GridRow>
          <GridRow spacingBottom={'none'}>
            <GridColumn width={6}>
              <Input
                value={formik.values.subTitle ?? ''}
                name={'subTitle'}
                onChange={formik.handleChange}
                label={t('lesson.basics.subTitle')}
                tooltipText={t('lesson.tooltip.subTitle')}
                readonly={status === 'blocked'}
                className={'col-xs-6'}
              />
            </GridColumn>
            <GridColumn width={6}>
              <Input
                value={formik.values.comment ?? ''}
                name={'comment'}
                onChange={formik.handleChange}
                label={t('lesson.basics.comment')}
                tooltipText={t('lesson.tooltip.comment')}
                readonly={status === 'blocked'}
                className={'col-xs-6'}
              />
            </GridColumn>
          </GridRow>
        </GridColumn>

        <GridColumn width={4} className={'ml-3 mt-3'}>
          <Checkbox
            name={'teachingLoadEnabled'}
            checked={formik.values.teachingLoadEnabled ?? false}
            label={t('lesson.basics.isTeachingLoadEnabled.full')}
            onChange={async (event) => {
              await formik.setFieldValue(`teachingLoadEnabled`, event.target.checked, true);
              await formik.setFieldTouched(`teachingLoadEnabled`, true);
            }}
          />
          <Checkbox
            name={'timetableEnabled'}
            disabled={status === 'placedCards' || status === 'blocked' || status === 'inVersion' || !!timetableDisabled}
            checked={formik.values.timetableEnabled ?? false}
            label={t('lesson.basics.isTimetableEnabled.full')}
            onChange={async (event) => {
              await formik.setFieldValue(`timetableEnabled`, event.target.checked, true);
              await formik.setFieldTouched(`timetableEnabled`, true);
            }}
          />
          <Checkbox
            name={'elective'}
            disabled={status === 'blocked'}
            checked={formik.values.elective ?? false}
            label={t('lesson.basics.isElective')}
            onChange={async (event) => {
              await formik.setFieldValue(`elective`, event.target.checked, true);
              await formik.setFieldTouched(`elective`, true);
            }}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
