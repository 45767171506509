import { TeachingLoadFactorsQuery, ValueMode } from '../types/planung-graphql-client-defs';
import { requireNullOrPositiveNumber } from './helper';
import { onlyUnique } from './unique-filter';
import { LessonTableType } from '../components/Lessons/Tables/TimetableVersionLessonsTable';
import { multiplyArray, sumUpArray } from './arrayFunc';
import { LessonBlueprintType } from '../components/Curricula/types';

type TeachingLoadFactor = TeachingLoadFactorsQuery['teachingLoadFactors'][number];

type LessonUnit = { uuid: string; duration?: number | null; count?: number | null };

export type RelevantFactor = {
  uuid: string;
  factorType: string;
  targetUuids: Array<string | undefined>;
  subjectContainerUuids: Array<string | undefined>;
  value: number;
  valueRaw: string;
  valuePartial?: number;
  valuePartialRaw?: string;
  valueMode?: string;
  comment?: string;
};

export type LessonInfo = {
  uuid: string;
  lessonClasses: Array<{ grade: number; gradeGroupUuid: string; group: string }>;
  teachingLoadEnabled: boolean;
  partialClasses: boolean; // geteilte Klassen? (Für geteilten Faktor)
  teachersCount: number;
  teachersCountTeachingLoadEnabled: number;
  classesCount: number;
  subjectContainerUuids: string[];
  subjectContainerTeacherInfos: Map<string, Map<string, DeputatInfo>>;
  subjectContainerClassInfos: Map<string, Map<string, DeputatInfo>>;
  teacherInfos: Map<string, DeputatInfo>;
  classInfos: Map<string, Partial<TimetableInfo> & DeputatInfo>;
} & TimetableInfo &
  DeputatInfo;

export type TimetableInfo = {
  studentCards: number; // Schülerstunden (Kärtchen)
  teacherCards: number; // Lehrerstunden (Kärtchen)
  studentHours: number; // Schülerstunden (Std / Anteil)
  teacherHours: number; // Lehrerstunden (Std / Anteil)
  teachingBlockWeeks: number; // Epochenwochen
  teachingBlocks: number; // Epochen
};

export type DeputatInfo = {
  deputat: number;
  fachstundenDeputat?: number;
  epochenDeputat?: number;
};

export const calculateLessonInfos = (
  lesson: LessonTableType | LessonBlueprintType,
  teachingLoadFactors: TeachingLoadFactorsQuery | undefined,
  includeFactorTypes: Array<'BASE' | 'PERSON' | 'TIME'> = ['BASE', 'PERSON', 'TIME'],
  includeEmptyPersonFactors: boolean = true,
  multiplyBy: number = 1,
): LessonInfo => {
  const filterFactorsByLessonProperties = (
    subjectUuid: string | undefined,
    subjectContainerUuids: Array<string | undefined>,
    grades: Array<number | undefined>,
    gradeGroupUuids: Array<string | undefined>,
    teacherUuids: Array<string | undefined>,
    factors: TeachingLoadFactorsQuery['teachingLoadFactors'] | undefined,
    factorType?: string | undefined,
  ): TeachingLoadFactor[] => {
    return (
      factors?.filter((f) => {
        const matchingSubject =
          (subjectUuid && f.subject.some((s) => s.uuid === subjectUuid)) || f.subject.length === 0;
        const matchingSubjectContainer = subjectContainerUuids.some(
          (sc) => f.subjectContainer.some((s) => sc && s.uuid === sc) || f.subjectContainer.length === 0,
        );
        const matchingGrade = grades.some((lg) => f.grades.some((g) => g === lg)) || f.grades.length === 0;
        const matchingGradeGroups =
          gradeGroupUuids.some((gg) => f.gradeGroup.some((g) => g.uuid === gg)) || f.gradeGroup.length === 0;
        const matchingTeachers =
          teacherUuids.some((lt) => f.teachers.some((t) => t.uuid === lt)) || f.teachers.length === 0;

        return (
          matchingSubject &&
          matchingSubjectContainer &&
          matchingGrade &&
          matchingGradeGroups &&
          matchingTeachers &&
          (factorType === undefined || f.factorType === factorType) &&
          f.active &&
          f.timeGridEntries.length === 0 // ignore all factors with timegridEntries for now...
        );
      }) ?? []
    );
  };

  const getMatchingFactorsForLesson = (
    lesson: LessonBlueprintType,
    factors: TeachingLoadFactorsQuery['teachingLoadFactors'],
    onlyTeacherUuid: string | undefined,
    onlyClassUuid?: string,
  ): RelevantFactor[] => {
    const subjectUuid: string | undefined = lesson.subjectUuid;
    const subjectContainerUuids: Array<string | undefined> =
      lesson.lessonUnits?.map((lu) => lu.subjectContainer?.uuid).filter(onlyUnique) ?? [];
    const grades: Array<number | undefined> =
      lesson.classes
        ?.filter((lc) => {
          if (onlyClassUuid === undefined) {
            return true;
          }
          return lc.uuid === onlyClassUuid;
        })
        .map((lc) => lc.grade ?? 0)
        .filter(onlyUnique) ?? [];
    const gradeGroupUuids: Array<string | undefined> =
      lesson.classes
        ?.map((lc) => lc.gradeGroup?.uuid)
        .filter((g) => typeof g === 'string')
        .filter(onlyUnique) ?? [];

    let teacherUuids: Array<string | undefined>;
    if (onlyTeacherUuid === undefined) {
      teacherUuids = lesson.teachers?.map((t) => t.uuid) ?? [];
    } else {
      teacherUuids = [onlyTeacherUuid];
    }

    return filterFactorsByLessonProperties(
      subjectUuid,
      subjectContainerUuids,
      grades,
      gradeGroupUuids,
      teacherUuids,
      factors,
    ).map((f) => ({
      uuid: f.uuid,
      targetUuids: [subjectUuid, ...subjectContainerUuids, ...gradeGroupUuids, ...teacherUuids],
      subjectContainerUuids: subjectContainerUuids ?? [],
      factorType: f.factorType,
      value: f.value,
      valueRaw: f.valueRaw,
      valuePartial: f.valuePartial ?? undefined,
      valuePartialRaw: f.valuePartialRaw ?? undefined,
      valueMode: f.valueMode ?? undefined,
      comment: f.comment ?? undefined,
    }));
  };

  const lessonUnitWeight = (lessonUnit: LessonUnit) => {
    const count = requireNullOrPositiveNumber(lessonUnit.count ?? 0);
    const duration = requireNullOrPositiveNumber(lessonUnit.duration ?? 0);
    return count * duration;
  };

  const partialClasses = !!lesson.classes?.some((lc) => (lc.groups?.length ?? 0) > 0);

  const infoData: LessonInfo = {
    uuid: lesson.uuid,
    lessonClasses: [],
    teachingLoadEnabled: lesson.teachingLoadEnabled ?? true,
    partialClasses: partialClasses ?? false,
    studentCards: 0,
    teacherCards: 0,
    studentHours: 0,
    teacherHours: 0,
    teachingBlockWeeks: 0,
    teachingBlocks: 0,
    teachersCount: 0,
    teachersCountTeachingLoadEnabled: 0,
    classesCount: 0,
    subjectContainerUuids: [],
    subjectContainerTeacherInfos: new Map<string, Map<string, DeputatInfo>>(),
    subjectContainerClassInfos: new Map<string, Map<string, DeputatInfo>>(),
    teacherInfos: new Map<string, DeputatInfo>(),
    classInfos: new Map<string, TimetableInfo & DeputatInfo>(),
    deputat: 0,
    fachstundenDeputat: 0,
    epochenDeputat: 0,
  };

  const fachstundenDeputat = (infoData: LessonInfo, matchingFactors: RelevantFactor[]): number => {
    return (
      infoData.studentCards *
      multiplyArray(
        matchingFactors
          .filter((f) => ['BASE', 'PERSON'].includes(f.factorType))
          .map((f) => (infoData.partialClasses ? f.valuePartial ?? f.value : f.value)),
      )
    );
  };

  const epochenDeputat = (
    lesson: LessonTableType | LessonBlueprintType,
    matchingFactors: RelevantFactor[],
    subjectContainerUuid?: string,
  ): number => {
    if (!lesson.teachingLoadEnabled) {
      return 0;
    }
    return sumUpArray(
      lesson.lessonUnits
        ?.filter(
          (lu) =>
            lu.subjectContainer &&
            (subjectContainerUuid === undefined || lu.subjectContainer.uuid === subjectContainerUuid),
        )
        .map((lu) => {
          const factors = matchingFactors.filter(
            (f) => f.factorType === 'TIME' && f.subjectContainerUuids.some((sc) => sc === lu.subjectContainer?.uuid),
          );
          let base = lessonUnitWeight(lu);
          if (factors.some((f) => f.valueMode === ValueMode.UnitCount)) {
            base = lu.count;
          } else if (factors.some((f) => f.valueMode === ValueMode.UnitDurationCount)) {
            base = 1;
          }
          return (
            base * multiplyArray(factors.map((f) => (infoData.partialClasses ? f.valuePartial ?? f.value : f.value)))
          );
        }) ?? [],
    );
  };

  const getDeputateDetails = (infoData: LessonInfo, matchingFactors: RelevantFactor[]) => {
    const fsDeputat = fachstundenDeputat(infoData, matchingFactors);
    const epDeputat = epochenDeputat(lesson, matchingFactors) * multiplyBy;
    const deputat = fsDeputat + epDeputat;

    return { fsDeputat, epDeputat, deputat };
  };

  const teachers = lesson.teachers?.map((p) => p.uuid) ?? [];
  const classes = lesson.classes ?? [];
  infoData.lessonClasses = classes.map((lc) => ({
    grade: lc.grade ?? -1,
    gradeGroupUuid: lc.gradeGroup?.uuid ?? '',
    group:
      lc.groups
        ?.map((g) => g.shortName)
        .sort()
        .join('/') ?? '',
  }));

  infoData.subjectContainerUuids =
    (lesson.lessonUnits
      ?.map((lu) => lu.subjectContainer?.uuid)
      .filter(onlyUnique)
      .filter((u) => u !== undefined) as string[]) ?? [];

  infoData.classesCount = classes.length;
  infoData.teachersCount = teachers.length;
  infoData.teachersCountTeachingLoadEnabled = lesson.teachers.filter((t) => t.teachingLoadEnabled).length;

  lesson.lessonUnits?.forEach((lu) => {
    if (lu.subjectContainer) {
      infoData.teachingBlockWeeks += lessonUnitWeight(lu) * multiplyBy;
      infoData.teachingBlocks += lu.count * multiplyBy;
    } else {
      infoData.studentCards += lessonUnitWeight(lu) * multiplyBy;
      infoData.teacherCards += lessonUnitWeight(lu) * infoData.teachersCount * multiplyBy;

      infoData.teacherHours += lessonUnitWeight(lu) * infoData.teachersCount * multiplyBy;

      infoData.studentHours +=
        lesson.classes.reduce((hours, lc) => {
          return (
            hours + lessonUnitWeight(lu) * (lc.groups?.reduce((prev, curr) => prev * (curr.classFraction ?? 1), 1) ?? 1)
          );
        }, 0) * multiplyBy;
    }
  });

  if (lesson.teachingLoadEnabled && lesson.teachers.length > 0) {
    const filteredTeachingLoadFactors =
      teachingLoadFactors?.teachingLoadFactors.filter(
        (f) =>
          includeFactorTypes.includes(f.factorType) ||
          (includeEmptyPersonFactors && f.factorType === 'PERSON' && f.teachers.length === 0),
      ) ?? [];

    lesson.teachers
      .filter((t) => t.teachingLoadEnabled)
      .forEach((t) => {
        const tUuid = t.uuid;
        if (classes.length === 0) {
          const existingTeacher = infoData.teacherInfos.get(tUuid);
          const matchingFactors = getMatchingFactorsForLesson(lesson, filteredTeachingLoadFactors, tUuid, undefined);

          const { deputat, fsDeputat, epDeputat } = getDeputateDetails(infoData, matchingFactors);

          infoData.subjectContainerUuids.forEach((su) => {
            const existingSubjectContainerTeacher = infoData.subjectContainerTeacherInfos.get(su)?.get(tUuid);
            if (infoData.subjectContainerTeacherInfos.get(su) === undefined) {
              infoData.subjectContainerTeacherInfos.set(su, new Map<string, { deputat: number }>());
            }

            const subjectContainerDeputat = epochenDeputat(lesson, matchingFactors, su) * multiplyBy;

            infoData.subjectContainerTeacherInfos.get(su)?.set(tUuid, {
              deputat: (existingSubjectContainerTeacher?.deputat ?? 0) + subjectContainerDeputat,
            });
          });

          infoData.teacherInfos.set(tUuid, {
            deputat: (existingTeacher?.deputat ?? 0) + deputat,
            fachstundenDeputat: (existingTeacher?.fachstundenDeputat ?? 0) + fsDeputat,
            epochenDeputat: (existingTeacher?.epochenDeputat ?? 0) + epDeputat,
          });
          infoData.deputat = (infoData.deputat ?? 0) + deputat / infoData.teachersCountTeachingLoadEnabled;
          infoData.fachstundenDeputat =
            (infoData.fachstundenDeputat ?? 0) + fsDeputat / infoData.teachersCountTeachingLoadEnabled;
          infoData.epochenDeputat =
            (infoData.epochenDeputat ?? 0) + epDeputat / infoData.teachersCountTeachingLoadEnabled;
        } else {
          classes.forEach((cl) => {
            const matchingFactors = getMatchingFactorsForLesson(lesson, filteredTeachingLoadFactors, tUuid, cl.uuid);

            const existingTeacher = infoData.teacherInfos.get(tUuid);
            const existingClass = infoData.classInfos.get(cl.uuid ?? '');

            const { deputat, fsDeputat, epDeputat } = getDeputateDetails(infoData, matchingFactors);

            infoData.subjectContainerUuids.forEach((su) => {
              const existingSubjectContainerTeacher = infoData.subjectContainerTeacherInfos.get(su)?.get(tUuid);
              const existingSubjectContainerClass = infoData.subjectContainerClassInfos.get(su)?.get(cl.uuid ?? '');
              if (infoData.subjectContainerTeacherInfos.get(su) === undefined) {
                infoData.subjectContainerTeacherInfos.set(su, new Map<string, { deputat: number }>());
              }
              if (infoData.subjectContainerClassInfos.get(su) === undefined) {
                infoData.subjectContainerClassInfos.set(su, new Map<string, { deputat: number }>());
              }

              const subjectContainerDeputat = epochenDeputat(lesson, matchingFactors, su) * multiplyBy;

              infoData.subjectContainerTeacherInfos.get(su)?.set(tUuid, {
                deputat:
                  (existingSubjectContainerTeacher?.deputat ?? 0) +
                  subjectContainerDeputat / infoData.classesCount / multiplyBy,
              });
              infoData.subjectContainerClassInfos.get(su)?.set(cl.uuid ?? '', {
                deputat:
                  (existingSubjectContainerClass?.deputat ?? 0) + subjectContainerDeputat / infoData.teachersCount,
              });
            });

            infoData.classInfos.set(cl.uuid ?? '', {
              studentCards: infoData.studentCards,
              studentHours: infoData.studentHours / infoData.classesCount,
              teacherCards: infoData.teacherCards / infoData.teachersCount,
              teacherHours: infoData.teacherHours / infoData.teachersCount,
              teachingBlocks: infoData.teachingBlocks,
              teachingBlockWeeks: infoData.teachingBlockWeeks,
              deputat: (existingClass?.deputat ?? 0) + deputat / infoData.teachersCountTeachingLoadEnabled,
              fachstundenDeputat:
                (existingClass?.fachstundenDeputat ?? 0) + fsDeputat / infoData.teachersCountTeachingLoadEnabled,
              epochenDeputat:
                (existingClass?.epochenDeputat ?? 0) + epDeputat / infoData.teachersCountTeachingLoadEnabled,
            });

            infoData.teacherInfos.set(tUuid, {
              deputat: (existingTeacher?.deputat ?? 0) + deputat / infoData.classesCount,
              fachstundenDeputat: (existingTeacher?.fachstundenDeputat ?? 0) + fsDeputat / infoData.classesCount,
              epochenDeputat: (existingTeacher?.epochenDeputat ?? 0) + epDeputat / infoData.classesCount,
            });
            infoData.deputat =
              (infoData.deputat ?? 0) + deputat / infoData.teachersCountTeachingLoadEnabled / infoData.classesCount;
            infoData.fachstundenDeputat =
              (infoData.fachstundenDeputat ?? 0) +
              fsDeputat / infoData.teachersCountTeachingLoadEnabled / infoData.classesCount;
            infoData.epochenDeputat =
              (infoData.epochenDeputat ?? 0) +
              epDeputat / infoData.teachersCountTeachingLoadEnabled / infoData.classesCount;
          });
        }
      });

    if (infoData.teachersCountTeachingLoadEnabled === 0) {
      classes.forEach((cl) => {
        const matchingFactors = getMatchingFactorsForLesson(lesson, filteredTeachingLoadFactors, undefined, cl.uuid);

        const existingClass = infoData.classInfos.get(cl.uuid ?? '');
        const { deputat, fsDeputat, epDeputat } = getDeputateDetails(infoData, matchingFactors);
        infoData.classInfos.set(cl.uuid ?? '', {
          studentCards: infoData.studentCards,
          studentHours: infoData.studentHours,
          teacherCards: infoData.teacherCards,
          teacherHours: infoData.teacherHours,
          teachingBlocks: infoData.teachingBlocks,
          teachingBlockWeeks: infoData.teachingBlockWeeks,
          deputat: (existingClass?.deputat ?? 0) + deputat,
          fachstundenDeputat: (existingClass?.fachstundenDeputat ?? 0) + fsDeputat,
          epochenDeputat: (existingClass?.epochenDeputat ?? 0) + epDeputat,
        });
        infoData.deputat = (infoData.deputat ?? 0) + deputat / infoData.classesCount;
        infoData.fachstundenDeputat = (infoData.fachstundenDeputat ?? 0) + fsDeputat / infoData.classesCount;
        infoData.epochenDeputat = (infoData.epochenDeputat ?? 0) + epDeputat / infoData.classesCount;
      });
    }
  }

  if (!lesson.timetableEnabled) {
    infoData.studentHours = 0;
    infoData.studentCards = 0;
    infoData.teacherHours = 0;
    infoData.teachingBlockWeeks = 0;
    infoData.teachingBlocks = 0;
    infoData.teacherCards = 0;
  }

  return infoData;
};
