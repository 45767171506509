import styles from './../TimetableGrid.module.scss';
import { ContextMenu, ContextMenuItem } from '@bp/ui-components';
import { DayItem } from '../../../stores/PinboardStore';
import { GridHeaderHour } from '../GridHeaderHour/GridHeaderHour';
import { useTranslation } from 'react-i18next';
import { useTimetableStore } from '../TimetableProvider';
import classNames from 'classnames';

export const GridHeaderDay = ({
  day,
  daysWidth,
  cellWidth,
}: {
  day: DayItem;
  daysWidth: string;
  cellWidth: string;
}) => {
  const pinboardStore = useTimetableStore();
  const hours = pinboardStore.showPause ? pinboardStore.hours : pinboardStore.hours.filter((h) => !h.isPause);

  const { t } = useTranslation();

  const getContextMenu = (day: DayItem): ContextMenuItem[] => {
    return [
      {
        node: <div className={styles['menu-name']}>{day.label}</div>,
        type: 'component',
      },
      {
        type: 'ruler',
      },
      {
        label: t('pinboard.actions.lockAll'),
        type: 'default',
        onClick: (e) => {
          e.stopPropagation();
          pinboardStore.lockCardsAtDay(day);
        },
      },
      {
        label: t('pinboard.actions.unlockAll'),
        type: 'default',
        onClick: (e) => {
          e.stopPropagation();
          pinboardStore.unlockCardsAtDay(day);
        },
      },
    ];
  };

  const dayNameClasses = classNames(styles['day-name'], {
    [styles.highlight]: true,
    [styles.lowlight]: pinboardStore.lowlightDay(day),
  });

  return (
    <ContextMenu usePortal={false} data={getContextMenu(day)}>
      <div className={styles.day} style={{ flex: `1 1 ${daysWidth}` }}>
        <div className={dayNameClasses}>{day.label}</div>
        <div className={styles.hours}>
          {hours.map((hour) => {
            return <GridHeaderHour key={day.value + '_' + hour.value} hour={hour} day={day} cellWidth={cellWidth} />;
          })}
        </div>
      </div>
    </ContextMenu>
  );
};
