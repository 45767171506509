import { FC, useState } from 'react';
import { Button, Card, InfoIcon } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import { AboutModal } from '../../components/AboutModal/AboutModal';
import styles from '../../RequireAuth.module.css';

export const SignupPage: FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [showAbout, setShowAbout] = useState<boolean>(false);

  const { token } = useParams();

  return (
    <div className={styles.wrapper} data-cy='layoutWrapper'>
      <AboutModal state={showAbout} setState={setShowAbout}></AboutModal>
      <main className={styles.container}>
        <Card
          header={{
            headline: t('signup.title'),
            size: 'l',
            subHeadline: '',
            actions: [{ callback: () => setShowAbout(true), icon: <InfoIcon color={'var(--color-grey-light)'} /> }],
          }}
          fitContent={true}
        >
          <p className={'mt-6'}>{t('signup.salutation')}</p>
          <Button
            hierarchy={'primary'}
            onClick={() =>
              auth.signinRedirect({
                prompt: 'signup login consent',
                login_hint: token,
              })
            }
            fullWidth
            className={'mt-6'}
          >
            {t('signup.create')}
          </Button>
        </Card>
      </main>
    </div>
  );
};
