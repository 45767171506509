import { Page } from '../../../components/Page/Page';
import { DivisionsTable } from '../../../components/Divisions/DivisionsTable';
import { useTranslation } from 'react-i18next';

export const SetupDivisions = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('divisions.default')}>
      <DivisionsTable />
    </Page>
  );
};
