import { useCreateSelectOptions } from './useCreateSelectOptions';

export const useGradeOptions = (selectableGrades: number[] = []) => {
  return useCreateSelectOptions(
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
      .filter((grade) => selectableGrades.length === 0 || selectableGrades.includes(grade))
      .map((grade) => ({ value: grade })),
    'value',
    'value',
  );
};
