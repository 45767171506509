import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TimetableReports } from '../../../components/EvaluateTimetable/TimetableReports';
import { TimetableReportType } from '@bp/planung-graphql-types';

export const EvaluateByTeachers = () => {
  const { t } = useTranslation();

  const tableData = useMemo(() => {
    return [
      { id: TimetableReportType.SingleTeacher, name: t('pdf.singlePlans') },
      { id: TimetableReportType.AllTeachers, name: t('pdf.overallPlans') },
      { id: TimetableReportType.TeachingBlocksTeacher, name: t('pdf.epochPlans') },
      { id: null, name: t('common.notYet', { planned: t('pdf.annualPlans') }) },
      { id: null, name: t('common.notYet', { planned: t('pdf.annualOverviewPlans') }) },
      { id: null, name: t('common.notYet', { planned: t('pdf.annualCompactPlans') }) },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TimetableReports tableData={tableData} topic={'teachers'} />;
};
