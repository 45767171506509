type TimeRangeType = {
  start: Date;
  end: Date;
};
type OverlappingResultType = {
  overlapping: boolean;
  startOverlaps: boolean;
  endOverlaps: boolean;
};

export const checkTimeRangeOverlap = (
  targetRange: TimeRangeType,
  otherRanges: TimeRangeType[],
): OverlappingResultType => {
  const result: OverlappingResultType = {
    overlapping: false,
    startOverlaps: false,
    endOverlaps: false,
  };

  for (const range of otherRanges) {
    if (targetRange.start <= range.end && targetRange.end >= range.start) {
      result.overlapping = true;
      if (targetRange.start < range.start) {
        result.startOverlaps = true;
      }
      if (targetRange.end > range.end) {
        result.endOverlaps = true;
      }
    }
  }

  return result;
};
