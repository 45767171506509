import { TeachingBlockVersionDependenciesTable } from '../../../../../../components/TeachingBlockVersion/TeachingBlockVersionDataTables/TeachingBlockVersionDependenciesTable';
import { useParams } from 'react-router-dom';
import styles from '../TeachingBlockVersionData.module.scss';

export const PlanTeachingBlockDependencies = () => {
  const { teachingBlockVersionUuid } = useParams();

  return (
    <div className={styles['dependencies-table']}>
      <TeachingBlockVersionDependenciesTable teachingBlockVersionUuid={teachingBlockVersionUuid ?? ''} />
    </div>
  );
};
