import { LessonTableType } from '../components/Lessons/Tables/TimetableVersionLessonsTable';

export const createLessonFilterUrl = ({
  context,
  versionUuid,
  filterValue,
  column,
}: {
  context: 'stundenplan' | 'epochenplan';
  versionUuid: string;
  filterValue: string;
  column: keyof LessonTableType;
}) => {
  const params = {
    column: column,
    filterValue: filterValue,
  };
  return `/stundenplan/planen/${context}/${versionUuid}/daten/zuordnung?filter=${JSON.stringify(params)}`;
};
