import React, { useEffect } from 'react';

type Coords = {
  x: number;
  y: number;
};

export const useMousePosition = ({ defaultX, defaultY }: { defaultX: number; defaultY: number }) => {
  const [mousePosition, setMousePosition] = React.useState<Coords>({ x: defaultX, y: defaultY });

  const updateMousePosition = (event: MouseEvent) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  return mousePosition;
};
