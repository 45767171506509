import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TimeGridFormType } from '../graphql/types';
import { Checkbox, Input, showToast } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { convertMinsToHrsMins, timeStringToDate } from '../../../utils/helper';
import { itemSchema } from './validation/schema';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import {
  useCreateTimeGridEntryMutation,
  useTimeGridEntriesByTimeGridQuery,
  useTimetableCardAggregateQuery,
  useUpdateTimeGridEntryMutation,
} from '../../../types/planung-graphql-client-defs';
import dayjs from 'dayjs';
import { useUpdateTimegridOrder } from '../../../hooks/useUpdateTimegridOrder';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';

type TimeGridEntryFormProps = {
  entryUuid: string;
  handleClose: () => void;
  gridUuid: string;
};

const TimeGridEntryForm: FC<TimeGridEntryFormProps> = ({ entryUuid, handleClose, gridUuid }) => {
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('TIME_GRID');

  const [loading, setLoading] = useState<boolean>(false);

  const [, create] = useCreateTimeGridEntryMutation();
  const [, update] = useUpdateTimeGridEntryMutation();

  const [{ data }] = useTimeGridEntriesByTimeGridQuery({
    variables: {
      timeGridUuid: gridUuid,
    },
    context: context,
  });

  const [{ data: cardsData }] = useTimetableCardAggregateQuery({
    variables: {
      where: {
        startTimeGridEntry: {
          uuid: entryUuid,
        },
      },
    },
  });

  const disableCriticalFields = cardsData ? cardsData.timetableCardsAggregate.count > 0 : false;

  const { updateOrders } = useUpdateTimegridOrder(gridUuid);

  const timeGridEntries = data?.timeGridEntries;

  const current = useMemo(() => {
    const c = timeGridEntries?.find((tge) => {
      return tge.uuid === entryUuid;
    });
    return c ?? null;
  }, [timeGridEntries, entryUuid]);

  const other = useMemo(() => {
    const o = timeGridEntries?.filter((tge) => {
      return tge.uuid !== entryUuid;
    });
    return o ?? [];
  }, [timeGridEntries, entryUuid]);

  const start = dayjs(
    current
      ? current.start
      : timeGridEntries && timeGridEntries.length > 0
        ? timeGridEntries[timeGridEntries.length - 1].end
        : null,
  );

  const end = current?.end ? dayjs(current?.end) : null;

  const initialValue: TimeGridFormType = {
    name: current?.name ?? '',
    shortName: current?.shortName ?? '',
    start: start,
    end: end,
    order: current?.order ?? 0,
    reliable: current?.reliable ?? false,
    pause: current?.pause ?? false,
    print: current?.print ?? false,
  };

  const handleSubmit = async (values: TimeGridFormType, formHelpers: FormikHelpers<TimeGridFormType>) => {
    setLoading(true);
    let mutationResult;

    if (entryUuid) {
      mutationResult = await update(
        {
          uuid: entryUuid,
          update: {
            name: values.name,
            shortName: values.shortName,
            start: values.start,
            end: values.end,
            order: values.order,
            reliable: values.reliable,
            pause: values.pause,
            print: values.print,
          },
        },
        context,
      );
    } else {
      mutationResult = await create(
        {
          input: {
            ...values,
            timeGrid: {
              connect: {
                where: {
                  node: {
                    uuid: gridUuid,
                  },
                },
              },
            },
          },
        },
        context,
      );
    }
    await updateOrders();
    if (!mutationResult || mutationResult.error) {
      showToast('Ups.', { type: 'error' });
    } else {
      showToast('Success', { type: 'success' });
    }
    formHelpers.resetForm();
    setLoading(false);
    handleClose();
  };

  return (
    <>
      <Formik validationSchema={itemSchema} initialValues={initialValue} enableReinitialize onSubmit={handleSubmit}>
        {(formik) => {
          const values = formik.values;
          const errors = formik.errors;
          const touched = formik.touched;
          const differenceInMinutes = values.end ? dayjs(values.end).diff(values.start, 'm') : 0;

          const validateTime = (value: Date | string, isStart: boolean): string | null => {
            if (isStart) {
              if (values.end && value > values.end) {
                return t('validation.common.startBeforeEnd');
              }
            } else {
              if (values.start && value < values.start) {
                return t('validation.common.startBeforeEnd');
              }
            }
            for (let i = 0; i < other.length; i++) {
              const startDate = new Date(other[i].start);
              const endDate = new Date(other[i].end);
              if (value > startDate && value < endDate) {
                return t('validation.timeGrid.noOverlapping');
              }
            }
            return null;
          };
          return (
            <Form>
              <div className='tks__grid mt-6 mb-10 no-gap'>
                <div className='tks__col col-xs-10 small-gap'>
                  <div className='tks__row small-gap'>
                    <div className='tks__col col-xs-6'>
                      <Input
                        value={values.name ?? ''}
                        name={'name'}
                        onChange={formik.handleChange}
                        label={t('common.name')}
                        error={errors.name}
                      />
                    </div>
                    <div className='tks__col col-xs-6'>
                      <Input
                        value={values.shortName ?? ''}
                        name={'shortName'}
                        onChange={formik.handleChange}
                        label={t('timeGrid.item.shortName') as string}
                        error={errors.shortName}
                      />
                    </div>
                  </div>
                  <div className='tks__row small-gap'>
                    <div className='tks__col col-xs-3'>
                      <Field
                        disabled={disableCriticalFields}
                        type={'time'}
                        validate={(value: Date) => {
                          return validateTime(value, true);
                        }}
                        name={`start`}
                        label={t('timeGrid.item.start') as string}
                        error={touched.start ? (errors.start as string) : undefined}
                        value={values.start.format('HH:mm')}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue('start', timeStringToDate(event.target.value), true);
                          formik.setFieldTouched('start', true);
                        }}
                        as={Input}
                      />
                    </div>
                    <div className='tks__col col-xs-3'>
                      <Field
                        disabled={disableCriticalFields}
                        type={'time'}
                        validate={(value: Date) => {
                          return validateTime(value, false);
                        }}
                        name={`end`}
                        label={t('timeGrid.item.end') as string}
                        error={touched.end ? (errors.end as string) : undefined}
                        value={values.end ? values.end.format('HH:mm') : ''}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue('end', timeStringToDate(event.target.value), true);
                          formik.setFieldTouched('end', true);
                        }}
                        as={Input}
                      />
                    </div>
                    <div className='tks__col col-xs-6'>
                      <Input
                        label={t('timeGrid.item.duration') as string}
                        name={`duration`}
                        value={differenceInMinutes > 0 ? convertMinsToHrsMins(differenceInMinutes) : '0'}
                        readonly={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='tks__row row-xs-2'>
                  <div className='tks__col col-xs-12 mt-5 px-5 no-gap'>
                    <Checkbox
                      disabled={disableCriticalFields}
                      name={'pause'}
                      checked={values.pause ?? false}
                      label={t('timeGrid.item.isPause') as string}
                      onChange={formik.handleChange}
                    />
                    <Checkbox
                      name={'reliable'}
                      checked={values.reliable ?? false}
                      label={t('timeGrid.item.isReliable') as string}
                      onChange={formik.handleChange}
                    />
                    <Checkbox
                      name={'print'}
                      checked={values.print ?? false}
                      label={t('timeGrid.item.print') as string}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <ModalBottomButtons
                isLoading={loading}
                closeButton={{
                  text: t('common.cancelChanges'),
                  callback: () => {
                    formik.resetForm();
                    handleClose();
                  },
                }}
                submitButton={{ disabled: formik.isSubmitting || !formik.dirty || !formik.isValid }}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default TimeGridEntryForm;
