import { memo, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Page.module.scss';
import { useDocumentTitle } from '@uidotdev/usehooks';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useTranslation } from 'react-i18next';
import { PageHeader } from './PageHeader';
import { ColorType } from '../../types/global';

type PageProps = {
  title?: string | null;
  headline?: string | null;
  subheadline?: string | null;
  colorHint?: ColorType;
  fullWidth?: boolean;
  fullHeight?: boolean;
  noHeadlineMargin?: boolean;
  hideHeader?: boolean;
  hasOutlet?: boolean;
  children: ReactNode;
  className?: string | undefined;
};

export const Page = memo(
  ({
    title,
    headline = null,
    subheadline = null,
    colorHint,
    fullWidth = false,
    fullHeight = false,
    noHeadlineMargin = false,
    hideHeader = false,
    hasOutlet = false,
    children,
    className,
  }: PageProps) => {
    const { t } = useTranslation();
    const { pimAuthClaims } = useAuthClaims();

    const organization = pimAuthClaims.getOrganization();
    useDocumentTitle(`${!title ? t('auth.app-title') : title}${organization ? ` - ${organization.name}` : ''}`);

    const classes = classNames(
      styles.page,
      { [styles['full-width']]: fullWidth, [styles['has-outlet']]: hasOutlet, [styles['full-height']]: fullHeight },
      className,
    );

    return (
      <div className={classes}>
        {!hideHeader && (title || headline) && (
          <PageHeader
            headline={!headline ? title : headline}
            subHeadline={subheadline}
            colorHint={colorHint}
            size='l'
            noMargin={noHeadlineMargin}
          />
        )}
        {children}
      </div>
    );
  },
);

Page.displayName = 'Page';
