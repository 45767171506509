import { ClassesIllustration, LazyLoader, OverviewIllustration, SubjectIllustration } from '@bp/ui-components';
import IconMenu from '../IconMenu/IconMenu';
import { IconMenuItem } from '../IconMenuItem/IconMenuItem';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Suspense, useContext } from 'react';
import { CurriculumStats } from '../CurriculumStats/CurriculumStats';
import { useCurriculum } from './hooks/useCurriculum';
import { CurriculumContext } from '../../pages/Deputate/Setup/Curricula/CurriculaPage';

export const CurriculumOutlet = ({ baseSlug }: { baseSlug: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const curriculumContext = useContext(CurriculumContext);

  const { tableData } = useCurriculum({
    versionUuid: curriculumContext.versionUuid ?? '',
    grade: curriculumContext.grade,
  });

  return (
    <>
      {curriculumContext.showMenu && (
        <IconMenu hasMarginBottom>
          <IconMenuItem
            label={`${t('deputate.menuItems.overview')}`}
            icon={<OverviewIllustration />}
            bubbleType={5}
            onClick={() => navigate(`/${baseSlug}/${t('routes.curriculum.slug')}/${curriculumContext.versionUuid}`)}
            active={
              !location.pathname.includes(t('routes.lessons.plan.classes.slug')) &&
              !location.pathname.includes(t('routes.lessons.plan.subjects.slug'))
            }
          />
          <IconMenuItem
            label={`${t('deputate.menuItems.grades')}`}
            icon={<ClassesIllustration />}
            bubbleType={3}
            onClick={() => navigate(t('routes.lessons.plan.classes.slug'))}
            active={location.pathname.includes(t('routes.lessons.plan.classes.slug'))}
          />
          <IconMenuItem
            label={`${t('deputate.menuItems.subject')}`}
            icon={<SubjectIllustration />}
            bubbleType={1}
            onClick={() => navigate(t('routes.lessons.plan.subjects.slug'))}
            active={location.pathname.includes(t('routes.lessons.plan.subjects.slug'))}
          />
          <CurriculumStats
            subjectHours={tableData.reduce((prev, curr) => prev + (curr.curriculumInfo?.studentCards ?? 0), 0)}
            studentHours={tableData.reduce((prev, curr) => prev + (curr.curriculumInfo?.studentHours ?? 0), 0)}
            deputat={tableData.reduce((prev, curr) => prev + (curr.curriculumInfo?.deputat ?? 0), 0)}
          />
        </IconMenu>
      )}

      <Suspense fallback={<LazyLoader transparent forceHeight='50vh' />}>{<Outlet />}</Suspense>
    </>
  );
};
