import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { _TimetablesQuery, useTimetableQuery } from '../../../types/planung-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';

export const useValidCopyTargets = (timetable: Pick<_TimetablesQuery, 'timetables'>['timetables'][0] | null) => {
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('TIMETABLE_VERSIONS');

  const [{ data: timetableQueryResult }] = useTimetableQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context,
  });

  return () => {
    const originalTimeGrids = timetableQueryResult?.timetables
      .find((tt) => tt.uuid === timetable?.uuid)
      ?.timegridConfigs.map((tgc) => tgc.timegrid?.uuid);

    const allTimeGrids = timetableQueryResult?.timetables.flatMap((tt) =>
      tt.timegridConfigs.map((tgc) => tgc.timegrid?.uuid),
    );
    const otherTimeGrids = allTimeGrids ? allTimeGrids.filter((uuid) => originalTimeGrids?.includes(uuid)) : [];

    return timetableQueryResult
      ? timetableQueryResult.timetables.filter((targetTimeTable) => {
          const timeTableTimeGrids = targetTimeTable.timegridConfigs.map(
            (timeGridConfig) => timeGridConfig.timegrid?.uuid,
          );
          const targetTimeTableClasses = targetTimeTable.timegridConfigs.flatMap((timeGridConfig) =>
            timeGridConfig.classes.map((c) => c.uuid),
          );

          const sourceTimeTableClasses = timetable?.timegridConfigsConnection.edges.flatMap((timeGridConfig) =>
            timeGridConfig.node.classes.map((c) => c.uuid),
          );

          return (
            // has same timeGrid
            otherTimeGrids?.some((item) => timeTableTimeGrids.includes(item)) &&
            // has same  classes
            sourceTimeTableClasses?.every((item) => targetTimeTableClasses.includes(item)) &&
            // not the original timetable
            targetTimeTable.uuid !== timetable?.uuid
          );
        })
      : [];
  };
};
