import { InfoIcon, Tooltip } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';

export const FactorInterpretationInfo = ({ value }: { value: string }) => {
  const { t } = useTranslation();
  return (
    <div className={'mt-3 color-grey-light'}>
      {t('common.calculationValue')}: {value}
      <Tooltip
        triggerStyle={{ display: 'inline', marginLeft: 'var(--spacing-2)' }}
        content={t('factors.valueWillBeUsed')}
      >
        <InfoIcon style={{ marginBottom: '2px' }} className='small' />
      </Tooltip>
    </div>
  );
};
