import { getTokens } from './authStore';
import { showUserErrorToast } from './toast';

export async function apiHelper<T>({
  path,
  method,
  config,
  data,
  type,
  downloadFileName,
}: {
  path: string;
  method: 'GET' | 'POST' | 'DELETE';
  type?: 'json' | 'xml' | 'download';
  data?: BodyInit | null;
  config?: RequestInit;
  downloadFileName?: string;
}): Promise<T> {
  const tokens = getTokens();
  const backendUrl = import.meta.env.VITE_APP_BACKEND_URI + import.meta.env.VITE_APP_BACKEND_REST_PATH;

  const headers: Record<string, string> = {};

  if (tokens) {
    headers['Authorization'] = `Bearer ${tokens.access_token}`;
  }

  const url = `${backendUrl}${path}`;

  if (method === 'POST' && data) {
    if (type === 'xml') {
      headers['Content-Type'] = 'application/xml';
    } else {
      headers['Content-Type'] = 'application/json';
    }
  }

  const requestOptions: RequestInit = {
    method,
    headers,
    ...config,
    body: data,
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      if (type === 'json') {
        const errorResponse = await response.json();
        showUserErrorToast({ text: errorResponse.message, error: errorResponse.error });
      }

      return undefined as unknown as T;
    }

    if (type === 'download') {
      const blob = await response.blob();

      const contentDisposition = response.headers.get('Content-Disposition');

      let fileName = downloadFileName ?? 'download';

      if (contentDisposition) {
        const match = contentDisposition.match(/filename="?([^"]+)"?/);
        if (match && match[1]) {
          fileName = match[1];
        }
      }

      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobURL;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(blobURL);
      document.body.removeChild(a);

      return undefined as unknown as T;
    } else if (type === 'json') {
      return (await response.json()) as T;
    } else {
      return (await response.text()) as unknown as T;
    }
  } catch (error) {
    console.error('Fehler beim Senden der Anfrage:', error);
    throw error;
  }
}
