export const averageColor = (colors: string[]) => {
  let sumR = 0;
  let sumG = 0;
  let sumB = 0;

  for (const color of colors) {
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);

    sumR += r;
    sumG += g;
    sumB += b;
  }

  const avgR = Math.round(sumR / colors.length);
  const avgG = Math.round(sumG / colors.length);
  const avgB = Math.round(sumB / colors.length);

  return `#${avgR.toString(16).padStart(2, '0')}${avgG.toString(16).padStart(2, '0')}${avgB.toString(16).padStart(2, '0')}`;
};
