import {
  AddIcon,
  AlertDialog,
  Button,
  ButtonGroup,
  DeleteIcon,
  ImportIcon,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { useMemo, useState } from 'react';
import { SortDirection, useTeachingLoadReportsQuery } from '../../types/planung-graphql-client-defs';
import { createTeachingLoadReport, deleteReport, downloadReport } from '../../utils/pdfReport';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import dayjs from 'dayjs';
import { ReportPdfTableData } from '../EvaluateTimetable/TimetableReportsTable';
import { useParams } from 'react-router-dom';
import { TeachingLoadReportType } from '@bp/planung-graphql-types';
import { createTeachingLoadExcelReport, deleteExcelReport, downloadExcelReport } from '../../utils/excelReport';

type Props = {
  scenarioName: string;
  subType: TeachingLoadReportType;
};

export const TeachingLoadReportsTable = ({ scenarioName, subType }: Props) => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const [loading, setLoading] = useState(false);

  const timetableBlockUuid = useParams().blockUuid;

  const [{ data }, refetchReports] = useTeachingLoadReportsQuery({
    variables: { where: {}, options: { sort: [{ jobFinished: SortDirection.Desc }] } },
  });
  const tableColumns: TableColumns<ReportPdfTableData>[] = [
    {
      header: '',
      id: 'name',
      accessorKey: 'name',
      size: 200,
      canExpand: true,
    },
    {
      header: '',
      id: 'timestamp',
      accessorFn: (row) => {
        return row.timestamp.format('DD.MM.YY HH:mm');
      },
      size: 100,
    },
  ];

  const tableData = useMemo(() => {
    return (
      data?.teachingLoadReports
        .filter((r) => {
          return r.type === subType;
        })
        .map((report) => {
          return {
            uuid: report.uuid,
            name: report.title,
            timestamp: dayjs(report.jobFinished),
          };
        }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioName, data, subType]);

  const handleDownloadClick = (report: ReportPdfTableData) => {
    if (loading) return;
    if (subType === TeachingLoadReportType.PlanExcel || subType === TeachingLoadReportType.CurrentExcel) {
      downloadExcelReport(report, subType);
    } else {
      void downloadReport(report, subType);
    }
  };

  const handleDeleteClick = async (report: ReportPdfTableData) => {
    if (loading) return;
    setLoading(true);
    if (subType === TeachingLoadReportType.PlanExcel || subType === TeachingLoadReportType.CurrentExcel) {
      await deleteExcelReport(report, subType);
    } else {
      await deleteReport(report, subType);
    }
    refetchReports({ requestPolicy: 'network-only' });
    setLoading(false);
  };

  const handleGenerateClick = async () => {
    setLoading(true);
    if (subType === TeachingLoadReportType.PlanExcel || subType === TeachingLoadReportType.CurrentExcel) {
      await createTeachingLoadExcelReport(currentSchoolYear?.uuid ?? '', timetableBlockUuid ?? 'default');
    } else {
      await createTeachingLoadReport(
        currentSchoolYear?.uuid ?? '',
        timetableBlockUuid ?? 'default',
        'teaching-load',
        subType,
      );
    }
    refetchReports({ requestPolicy: 'network-only' });
    setLoading(false);
  };

  return (
    <Table<ReportPdfTableData>
      showShadow={false}
      data={tableData}
      columns={tableColumns}
      showActionBar={true}
      hideHeader
      actionBarSettings={{
        extendedActionsRight: (
          <Button
            isLoading={loading}
            disabled={loading}
            icon={<AddIcon />}
            onClick={handleGenerateClick}
            title={t('common.generate')}
          >
            {t('common.generate')}
          </Button>
        ),
      }}
      onAddClick={handleGenerateClick}
      lastColWidth={'60px'}
      lastCol={(row) => {
        return (
          <>
            <ButtonGroup disabled={loading}>
              <Button
                hierarchy='secondary'
                type='button'
                isLoading={loading}
                icon={<ImportIcon className='small' />}
                onClick={() => {
                  handleDownloadClick(row.original);
                }}
              />
              <AlertDialog
                title={t('divisions.deleteConfirm', {
                  type: 'divisionRow.original.name',
                })}
                confirmText={t('common.confirmDelete')}
                cancelText={t('common.cancelDelete')}
                onConfirm={() => handleDeleteClick(row.original)}
                trigger={<Button hierarchy='tertiary' icon={<DeleteIcon className={'small'} />} />}
              ></AlertDialog>
            </ButtonGroup>
          </>
        );
      }}
    />
  );
};
