import i18next from 'i18next';
import { AvailabilityResourceType } from '../stores/TimeAvailabilityStore';

export type WarningType =
  | 'overlappingTeachers'
  | 'teacherNotAvailable'
  | 'classNotAvailable'
  | 'roomNotAvailable'
  | 'subjectNotAvailable'
  | 'overlappingClass'
  | 'overlappingRooms'
  | 'overlappingGroups'
  | 'notMatchingDivision'
  | 'overlappingTeachingBlock'
  | 'teacherMaybe'
  | 'classMaybe'
  | 'roomMaybe'
  | 'subjectMaybe';

export function getWarningTypeTranslation(type: WarningType) {
  switch (type) {
    case 'overlappingTeachers':
      return i18next.t('pinboard.reason.overlappingTeachers');
    case 'teacherNotAvailable':
      return i18next.t('pinboard.reason.teacherNotAvailable');
    case 'classNotAvailable':
      return i18next.t('pinboard.reason.classNotAvailable');
    case 'roomNotAvailable':
      return i18next.t('pinboard.reason.roomNotAvailable');
    case 'subjectNotAvailable':
      return i18next.t('pinboard.reason.subjectNotAvailable');
    case 'overlappingClass':
      return i18next.t('pinboard.reason.overlappingClass');
    case 'overlappingRooms':
      return i18next.t('pinboard.reason.overlappingRooms');
    case 'overlappingGroups':
      return i18next.t('pinboard.reason.overlappingGroups');
    case 'notMatchingDivision':
      return i18next.t('pinboard.reason.notMatchingDivision');
    case 'overlappingTeachingBlock':
      return i18next.t('pinboard.reason.overlappingTeachingBlock');
    case 'teacherMaybe':
      return i18next.t('pinboard.reason.teacherMaybe');
    case 'classMaybe':
      return i18next.t('pinboard.reason.classMaybe');
    case 'roomMaybe':
      return i18next.t('pinboard.reason.roomMaybe');
    case 'subjectMaybe':
      return i18next.t('pinboard.reason.subjectMaybe');
    default:
      return '';
  }
}

export function availabilityResourceTypeToWarningType(
  type: AvailabilityResourceType,
  blocked: boolean,
  maybe: boolean,
): WarningType {
  switch (type) {
    case 'teacher':
      if (blocked) {
        return 'teacherNotAvailable';
      }
      if (maybe) {
        return 'teacherMaybe';
      }
      return 'overlappingTeachers';
    case 'class':
      if (blocked) {
        return 'classNotAvailable';
      }
      if (maybe) {
        return 'classMaybe';
      }
      return 'overlappingClass';
    case 'room':
      if (blocked) {
        return 'roomNotAvailable';
      }
      if (maybe) {
        return 'roomMaybe';
      }
      return 'overlappingRooms';
    case 'divisionGroup':
      if (blocked) {
        return 'subjectNotAvailable';
      }
      return 'notMatchingDivision';
    case 'subject':
      if (blocked) {
        return 'subjectNotAvailable';
      }
      if (maybe) {
        return 'subjectMaybe';
      }
      return 'subjectNotAvailable';
    default:
      return 'subjectNotAvailable';
  }
}
