import { Table, TableColumns } from '@bp/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LessonTableType } from '../Tables/TimetableVersionLessonsTable';
import { TeachingLoadFactorsQuery, useCurriculaListQuery } from '../../../types/planung-graphql-client-defs';
import { LessonInfo } from '../../../utils/calculateLessonInfos';
import styles from '../LessonsComponents.module.scss';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { curriculumResponseToTableType } from '../../Curricula/utils/curriculumResponseToTableType';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { CurriculaTableDataType } from '../../Curricula/types';

type SubjectStatsData = { plan: number; sst: number; name: string };
type SubjectStatsProps = { lessonsData: LessonTableType[]; factorsData?: TeachingLoadFactorsQuery };

export const LessonStats = ({ lessonsData, factorsData }: SubjectStatsProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [{ data: queryData }] = useCurriculaListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      where: {
        uuid_IN: lessonsData.filter((l) => l.curriculumUuid !== undefined).map((l) => l.curriculumUuid as string),
      },
    },
  });

  const curriculum: CurriculaTableDataType[] = useMemo(() => {
    return curriculumResponseToTableType({
      curriculumVersionUuid: '',
      teachingLoadFactors: factorsData,
      queryCurriculaData: queryData,
      schoolYearUuid: currentSchoolYear?.uuid,
    });
  }, [queryData, factorsData, currentSchoolYear]);

  const tableData = useMemo(() => {
    const lessonInfos = lessonsData.map((l) => l.lessonInfo).filter((i) => !!i) as LessonInfo[];

    const stats = lessonInfos.reduce(
      (accumulator, lesson) => {
        return {
          ...accumulator,
          studentHours: (accumulator.studentHours ?? 0) + (lesson.studentHours ?? 0),
          teacherHours: (accumulator.teacherHours ?? 0) + (lesson.teacherHours ?? 0),
          teachingBlockWeeks: (accumulator.teachingBlockWeeks ?? 0) + (lesson.teachingBlockWeeks ?? 0),
          deputat: (accumulator.deputat ?? 0) + (lesson.deputat ?? 0),
        };
      },
      {
        studentHours: 0,
        teacherHours: 0,
        teachingBlockWeeks: 0,
        deputat: 0,
      },
    );

    // TODO: sollstunden
    // const sstStats = curriculum.reduce(
    //   (accumulator, curr) => {
    //     const validCurriculum = curr.lessonInfo?.find((li) =>
    //       lessonInfos.some((lc) => JSON.stringify(li.lessonClasses) === JSON.stringify(lc.lessonClasses)),
    //     );
    //     return {
    //       ...accumulator,
    //       studentHours: (accumulator.studentHours ?? 0) + (validCurriculum?.studentHours ?? 0),
    //       teacherHours: (accumulator.teacherHours ?? 0) + (validCurriculum?.teacherHours ?? 0),
    //       teachingBlockWeeks: (accumulator.teachingBlockWeeks ?? 0) + (validCurriculum?.teachingBlockWeeks ?? 0),
    //       deputat: (accumulator.deputat ?? 0) + (validCurriculum?.deputat ?? 0),
    //     };
    //   },
    //   {
    //     studentHours: 0,
    //     teacherHours: 0,
    //     teachingBlockWeeks: 0,
    //     deputat: 0,
    //   },
    //
    // );

    const sstStats = {
      studentHours: 0,
      teacherHours: 0,
      teachingBlockWeeks: 0,
      deputat: 0,
    };

    return [
      { plan: stats.studentHours, sst: sstStats.studentHours, name: t('common.studentHours') },
      { plan: stats.teacherHours, sst: sstStats.teacherHours, name: t('common.teacherHours') },
      { plan: stats.deputat, sst: sstStats.deputat, name: t('deputate.titleSingluar') },
      { plan: stats.teachingBlockWeeks, sst: sstStats.teachingBlockWeeks, name: t('common.epochWeeksInTimeTable') },
    ];
  }, [curriculum, lessonsData]);

  const tableColumns = useMemo((): TableColumns<SubjectStatsData>[] => {
    return [
      {
        header: '',
        id: 'name',
        accessorKey: 'name',
        canExpand: true,
      },
      {
        header: t('common.plan'),
        id: 'plan',
        size: 40,
        accessorFn: (row) => {
          return row.plan?.toFixed(2);
        },
        alignment: 'right',
      },
    ];
  }, []);

  return (
    <Table<SubjectStatsData>
      className={styles['lesson-stats-table']}
      showBorderRadius
      showShadow
      maxHeight={194}
      data={tableData}
      columns={tableColumns}
    />
  );
};
