import { FC, Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoader } from '@bp/ui-components';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useTimeGridQuery } from '../../../../types/planung-graphql-client-defs';
import { TimeGridType } from '../../../../components/TimeGrid/graphql/types';
import TimeGridTable from '../../../../components/TimeGrid/TimeGridTable';
import { TimeGridEntriesTable } from '../../../../components/TimeGrid/TimeGridEntriesTable';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { Page } from '../../../../components/Page/Page';

export const SetupTimeGrids: FC = () => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('TIME_GRID');

  const [gridUuid, setGridUuid] = useState<null | string>(null);

  const [{ data }] = useTimeGridQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: context,
  });

  const memoizedData = useMemo((): TimeGridType[] => {
    return data && data ? data.timeGrids : [];
  }, [data]);

  const onSelect = (uuid: string) => {
    setGridUuid(uuid);
  };

  return (
    <Page title={t('timeGrid.title')}>
      <div className={'tks__grid'}>
        <div className={'tks__row'}>
          <div className={`tks__col ${memoizedData.length === 0 ? 'col-xs-12' : 'col-xs-6'}`}>
            <TimeGridTable data={data?.timeGrids ?? []} onSelect={onSelect} />
          </div>
          {memoizedData.length !== 0 && (
            <div className={'tks__col col-md-6'}>
              <Suspense fallback={<LazyLoader />}>
                <TimeGridEntriesTable gridUuid={gridUuid ?? memoizedData[0].uuid} />
              </Suspense>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};
