import { ContextMenu, LockIcon, PinIcon, RoomIcon } from '@bp/ui-components';
import styles from './VirtualGridCard.module.scss';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Item, TeachingBlockVirtualCard } from '../types';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import { useTeachingBlockCardMenu } from '../../../hooks/useTeachingBlockCardMenu';
import { useDebounceCallback } from 'usehooks-ts';
import { toJS } from 'mobx';
import { useCallback } from 'react';

type VirtualGridCardProps = {
  card: TeachingBlockVirtualCard;
  row: Item;
};

export const VirtualGridCard = observer(({ card, row }: VirtualGridCardProps) => {
  const {
    pinnedCard,
    pickedCard,
    gridContext,
    selectCard,
    pickCard,
    setCardDurationModal,
    highlightMode,
    getParentCard,
    selectedCard,
  } = useTeachingBlockStore();

  const parent = getParentCard(card.parentUuid);
  if (!parent) throw new Error('parent is undefined');

  const isPicked = parent.uuid === pickedCard?.card.uuid;
  const isPinned = parent.uuid === pinnedCard?.uuid;
  const isLocked = parent.locked;

  const hasWarnings = !!parent.warnings && parent.warnings.length > 0;
  const hasAvailabilityWarnings = !!parent.availabilityWarnings && parent.availabilityWarnings.length > 0;

  const borders = card.duration > 7 ? Math.floor((card.duration / 7) * 2) - 1 : 1;

  const gridCard =
    gridContext === 'classes'
      ? parent.classGridCards.find((c) => c.classUuid === row?.value)
      : gridContext === 'teachers'
        ? parent.teacherGridCards.find((t) => t.teacherUuid === row?.value)
        : gridContext === 'rooms'
          ? parent.roomGridCards.find((r) => r.roomUuid === row?.value)
          : null;

  function onCardEditOpen() {
    setCardDurationModal({
      isOpen: true,
      card: parent ?? undefined,
      isVirtual: true,
    });
  }

  const { menu, hasRooms } = useTeachingBlockCardMenu({
    card: parent,
    styles,
    hasWarnings,
    hasAvailabilityWarnings,
    isPicked,
    isPinned,
    onCardEditOpen,
  });

  const menuClasses = classNames(styles.menu, { [styles['has-warning']]: hasWarnings || hasAvailabilityWarnings });

  const cardClasses = classNames(styles['virtual-grid-card'], {
    [styles.picked]: isPicked,
    [styles.pinned]: isPinned,
    [styles.locked]: isLocked,
    [styles.warning]: hasWarnings || hasAvailabilityWarnings,
    // [styles['high-light']]:
    //   pinboardStore.controlMode.isActive &&
    //   pinboardStore.controlMode.highlights.length !== 0 &&
    //   pinboardStore.controlMode.highlights.includes(card.uuid),
    // [styles['low-light']]:
    //   pinboardStore.controlMode.isActive &&
    //   pinboardStore.controlMode.highlights.length !== 0 &&
    //   !pinboardStore.controlMode.highlights.includes(card.uuid),
  });

  const debounceOnCardHover = useDebounceCallback(() => {
    selectCard(toJS(parent));
  }, 500);

  const onMouseEnter = useCallback(() => {
    if (!selectedCard) {
      selectCard(toJS(parent));
    } else {
      debounceOnCardHover();
    }
  }, [parent, debounceOnCardHover, selectCard, selectedCard]);

  const onMouseLeave = useCallback(() => {
    debounceOnCardHover.cancel();
  }, [debounceOnCardHover]);

  const onOpenChange = useCallback(() => {
    debounceOnCardHover.cancel();
    selectCard(toJS(parent));
  }, [parent, debounceOnCardHover, selectCard]);

  return (
    <>
      <ContextMenu
        className={menuClasses}
        data={menu}
        parent={document.getElementById('teaching-block-grid')}
        onOpenChange={onOpenChange}
      >
        <div
          key={card.uuid}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={(event) => pickCard(parent, { x: event.pageX, y: event.pageY })}
          title={parent.label}
          className={cardClasses}
          style={{
            height: gridCard?.cssHeight ?? '100%',
            width: `round(nearest, calc(${card.duration * 100}%  + ${borders}px), 1px)`,
            top: gridCard?.cssTop ?? '0%',
            background: gridCard?.cssGradientString ?? 'var(--color-grey)',
            color: gridCard?.textColor ?? 'var(--grid-card-text)',
          }}
        >
          <div className={styles.title}>{parent.labelShort}</div>
          {!highlightMode.includes('noIcons') && (isPinned || isLocked || !hasRooms) && (
            <div className={styles.info}>
              {isPinned && <PinIcon className={styles.icon} />}
              {isLocked && <LockIcon className={styles.icon} />}
              {!hasRooms && <RoomIcon className={styles.icon} />}
            </div>
          )}
        </div>
      </ContextMenu>
    </>
  );
});
