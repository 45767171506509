import * as Sentry from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import React from 'react';
import { createRoot } from 'react-dom/client';
import i18n from './core/i18n/i18n';
import { I18nextProvider } from 'react-i18next';
import { Router } from './Router';

// styles
import 'sanitize.css';
import './core/styles/flex.scss';
import './core/styles/form.scss';
import './core/styles/global.scss';
import './core/styles/grid.scss';
import './core/styles/modal.scss';
import './core/styles/styles.scss';
import './core/styles/table.scss';
import './core/styles/typography.scss';
import './core/styles/mixins/paddingMarginMixin.scss';
import './core/styles/board.scss';

// yup functions
import './globals/yup.custom';

// dayjs
import './setupDayjs';

// Sentry instrumentation
if (import.meta.env.VITE_APP_SENTRY_DSN !== 'false') {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    autoSessionTracking: true,
    release: 'planung-frontend@' + import.meta.env.VITE_APP_VERSION,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: import.meta.env.VITE_APP_SENTRY_TRACING_SAMPLE_RATE,
    tracePropagationTargets: [import.meta.env.VITE_APP_BACKEND_URI, 'localhost'],
    profilesSampleRate: import.meta.env.VITE_APP_SENTRY_TRACING_SAMPLE_RATE,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
