import { t } from 'i18next';
import { RouteObject } from 'react-router-dom';
import { AdminPage } from './AdminPage';
import { OrganizationsSubpage } from './Organizations/OrganizationsSubpage';
import { MathplanSubpage } from './Mathplan/MathplanSubpage';

export const adminRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      element: <AdminPage />,
      index: true,
    },
    {
      path: t('routes.organizations.slug') as string,
      element: <OrganizationsSubpage />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.organizations.slug')}`,
          title: t('routes.organizations.title'),
        }),
      },
    },
    {
      path: t('routes.mathplan.slug') as string,
      element: <MathplanSubpage />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.mathplan.slug')}`,
          title: t('routes.mathplan.title'),
        }),
      },
    },
  ];
};
