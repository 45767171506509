import { useRef } from 'react';

type useUploadProps<t> = {
  uploadFile: (files: FileList | null, params?: t) => void;
  beforeUpload?: () => void;
  afterUpload?: () => void;
  onError?: (error: unknown) => void;
};

export const useUpload = <t>({ beforeUpload, onError, afterUpload, uploadFile }: useUploadProps<t>) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const upload = (params?: t) => {
    const i = document.createElement('input');

    const onChange = () => {
      uploadFile(i.files, params);
    };

    try {
      if (beforeUpload) {
        beforeUpload();
      }
      ref.current = i;
      document.body.appendChild(i);
      i.type = 'file';
      i.onchange = onChange;

      ref.current?.click();
      if (afterUpload) {
        afterUpload();
      }
      ref.current = null;
      document.body.removeChild(i);
    } catch (error) {
      if (onError) onError(error);
    }
  };

  return { upload };
};
