import { t } from 'i18next';
import { Outlet, RouteObject } from 'react-router-dom';
import { EvaluatePage } from './Evaluate/EvaluatePage';
import { timetableSetupRoutes } from './Setup/routes';
import { timetablePlanRoutes } from './Plan/routes';
import { EvaluateByClass } from './Evaluate/EvaluateByClass';
import { EvaluateByRooms } from './Evaluate/EvaluateByRooms';
import { Skeleton } from '@bp/ui-components';
import { EvaluateByTeachers } from './Evaluate/EvaluateByTeachers';
import { TimetablePage } from './TimetablePage';

export const timetableRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <TimetablePage />,
    },
    {
      path: t('routes.setup.slug') + '/*',
      element: <Outlet />,
      children: [...timetableSetupRoutes(`${baseSlug}/${t('routes.setup.slug')}`)],
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.setup.slug')}`,
          title: t('routes.setup.title'),
        }),
      },
    },
    {
      path: t('routes.plan.slug') + '/*',
      element: <Outlet />,
      children: [...timetablePlanRoutes(`${baseSlug}/${t('routes.plan.slug')}`)],
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.plan.slug')}`,
          title: t('routes.plan.title'),
        }),
      },
    },
    {
      path: t('routes.evaluate.slug'),
      element: <EvaluatePage baseSlug={baseSlug} />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.evaluate.slug')}`,
          title: t('routes.evaluate.title'),
        }),
      },
      children: [
        {
          element: <EvaluateByClass />,
          path: t('routes.evaluate.byClass.slug'),
          index: true,
        },
        {
          element: <EvaluateByTeachers />,
          path: t('routes.evaluate.byTeacher.slug'),
        },
        {
          element: <EvaluateByRooms />,
          path: t('routes.evaluate.byRooms.slug'),
        },
        {
          element: <Skeleton />,
          path: 'planned-subjects',
        },
        {
          element: <Skeleton />,
          path: 'planned-other',
        },
      ],
    },
  ];
};
