import { Button, Grid, GridColumn, GridRow, Modal } from '@bp/ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ConflictModal.module.scss';
import { observer } from 'mobx-react-lite';
import { partition } from '../../../utils/arrayFunc';
import { getWarningTypeTranslation } from '../../../utils/getWarningTypeTranslation';
import { useTimetableStore } from '../TimetableProvider';

export const ConflictModal: FC = observer(() => {
  const { t } = useTranslation();

  const pinboardStore = useTimetableStore();
  if (pinboardStore.conflictModal.conflicts === null) {
    return <></>;
  }

  const [roomConflicts, otherConflicts] = partition(pinboardStore.conflictModal.conflicts, (conflict) => {
    return conflict.type === 'overlappingRooms';
  });
  const isLastConflict = pinboardStore.conflictModal.conflicts.length === 1;

  return (
    <Modal
      className={styles['conflict-modal']}
      width='s'
      isOpen={pinboardStore.conflictModal.conflicts.length > 0}
      onRequestClose={() => pinboardStore.abortConflict()}
      title={t('pinboard.conflict.found')}
      hideCloseButton
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      parent={document.fullscreenElement ? document.getElementById('timetable-grid') : null}
    >
      <Grid>
        {roomConflicts.length > 0 && (
          <>
            <GridRow headline={t('common.roomConflict', { count: roomConflicts.length })} spacingBottom={'none'}>
              <GridColumn width={12}>
                <div className={styles.conflicts}>
                  {roomConflicts.map((conflict, i) => {
                    const conflictText = `${getWarningTypeTranslation(conflict.type)} (${conflict.data?.name})`;
                    return (
                      <div className={styles.conflict} key={i}>
                        {conflictText}
                        <GridRow>
                          <GridColumn width={12}>
                            <div className={styles.actions}>
                              <Button
                                height={'s'}
                                className='mr-3'
                                hierarchy='secondary'
                                onClick={() => pinboardStore.ignoreRoomConflict()}
                              >
                                {t('pinboard.conflict.ignoreRoomConflict')}
                              </Button>
                              <Button
                                height={'s'}
                                className='mr-3'
                                hierarchy='secondary'
                                onClick={() => pinboardStore.removeOtherRooms(conflict, i)}
                              >
                                {t('pinboard.conflict.removeOtherRooms')}
                              </Button>
                              <Button
                                height={'s'}
                                className='mr-3'
                                hierarchy='secondary'
                                onClick={() => pinboardStore.placeWithoutRoom(conflict, i)}
                              >
                                {t('pinboard.conflict.placeWithoutRoom')}
                              </Button>
                              {isLastConflict && (
                                <Button
                                  height={'s'}
                                  hierarchy='secondary'
                                  onClick={(e) => pinboardStore.abortConflict()}
                                >
                                  {t('pinboard.conflict.abort')}
                                </Button>
                              )}
                            </div>
                          </GridColumn>
                        </GridRow>
                      </div>
                    );
                  })}
                </div>
              </GridColumn>
            </GridRow>
          </>
        )}
        {otherConflicts.length > 0 && (
          <>
            <GridRow headline={t('common.conflict', { count: otherConflicts.length })}>
              <GridColumn width={12}>
                <div className={styles.conflicts}>
                  {otherConflicts.map((conflict, i) => {
                    const conflictText = `${getWarningTypeTranslation(conflict.type)} (${conflict.data?.name})`;
                    return (
                      <div className={styles.conflict} key={i}>
                        {conflictText}
                      </div>
                    );
                  })}
                </div>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn width={12}>
                <div className={styles.actions}>
                  <Button className='mr-3' hierarchy='secondary' onClick={() => pinboardStore.ignoreConflict()}>
                    {t('pinboard.conflict.ignore')}
                  </Button>
                  <Button className='mr-3' hierarchy='secondary' onClick={() => console.log('swap')} disabled={true}>
                    {t('pinboard.conflict.swap')}
                  </Button>
                  <Button hierarchy='primary' onClick={(e) => pinboardStore.abortConflict()}>
                    {t('pinboard.conflict.abort')}
                  </Button>
                </div>
              </GridColumn>
            </GridRow>
          </>
        )}
      </Grid>
    </Modal>
  );
});
