import React, { FC } from 'react';
import { PermissionChecker } from '@bp/pim-auth-permissions';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { Navigate, Outlet } from 'react-router-dom';

type ProtectedOutletProps = {
  isAllowed: (permissionChecker: PermissionChecker | null) => boolean;
  redirect?: string;
};

export const ProtectedOutlet: FC<ProtectedOutletProps> = ({ isAllowed, redirect }) => {
  const perms = usePermissionChecker();

  if (isAllowed(perms)) {
    return <Outlet />;
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return <>Permission Denied</>;
};
