import {
  cacheExchange,
  createClient,
  fetchExchange,
  mapExchange,
  OperationResult,
  subscriptionExchange,
} from '@urql/core';
import { authExchange } from '@urql/exchange-auth';
import { getTokens } from './authStore';
import { UNAUTHORIZED_QUERY } from '../globals/constants';
import { createClient as createWSClient } from 'graphql-ws';
import * as Sentry from '@sentry/react';
import { showUserDbErrorToast } from './toast';

const wsClient = createWSClient({
  url: import.meta.env.VITE_APP_BACKEND_URI.replace('http', 'ws') + import.meta.env.VITE_APP_BACKEND_GRAPHQL_PATH,
});

export const urqlClient = createClient({
  url: import.meta.env.VITE_APP_BACKEND_URI + import.meta.env.VITE_APP_BACKEND_GRAPHQL_PATH,
  suspense: true,
  exchanges: [
    cacheExchange,
    authExchange(async (utils) => {
      return {
        addAuthToOperation(operation) {
          const tokens = getTokens();

          if (tokens) {
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${tokens.access_token}`,
            });
          }
          return operation;
        },
        willAuthError(_operation) {
          const tokens = getTokens();

          return !tokens;
        },
        didAuthError(error, _operation) {
          return error.graphQLErrors.some((e) => e.extensions?.code === UNAUTHORIZED_QUERY);
        },
        async refreshAuth() {
          const tokens = getTokens();

          if (!tokens?.access_token || (tokens?.expires_at && tokens?.expires_at <= Date.now())) {
            localStorage.clear();
          }
        },
      };
    }),
    mapExchange({
      onResult(result: OperationResult): Promise<OperationResult> | OperationResult | void {
        if (result.error) {
          Sentry.captureException(result.error, {});

          console.error(result.error);
          showUserDbErrorToast();

          return { ...result, data: undefined, error: undefined };
        }
      },
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(request) {
        const input = { ...request, query: request.query || '' };
        return {
          subscribe(sink) {
            const unsubscribe = wsClient.subscribe(input, sink);
            return { unsubscribe };
          },
        };
      },
    }),
  ],
});
