import classNames from 'classnames';
import styles from './../TimetableGrid.module.scss';
import React, { FC, useCallback } from 'react';
import { CellItem } from '../../../stores/PinboardStore';
import { observer } from 'mobx-react-lite';
import { useTimetableStore } from '../TimetableProvider';
import dayjs from 'dayjs';
import { Time } from '../../../stores/TimeAvailabilityStore';
import { useDebounceCallback } from 'usehooks-ts';

type GridCellProps = {
  width: string;
  className?: string | undefined;
  cell: CellItem;
  dayIndex: number;
  isPause?: boolean;
};

export const GridCell: FC<GridCellProps> = observer(({ className, cell, isPause }) => {
  const store = useTimetableStore();
  const availabilityStore = store.getTimeAvailabilityStore();
  let blocked: boolean = false;
  let maybe: boolean = false;
  let valid: boolean = false;

  if (
    store.context !== 'rooms' &&
    store.pickedCard &&
    availabilityStore &&
    store.rowsToHighlight.has(cell.row.value) &&
    !cell.row.isFunctionClass
  ) {
    valid = store.canBeDropped(store.pickedCard.cardUuid, store.pickedCard.duration, cell.hour.value, cell.day.value);
    const timeGridEntries = store.timeGridEntries;

    const timeGridEntry = timeGridEntries?.find((entry) => entry.uuid === cell.hour.value);
    if (timeGridEntry) {
      const start = dayjs(timeGridEntry.start).add(1, 'minute'); // only check for one minute

      const availability = availabilityStore.checkCardAvailability({
        time: start.format('HH:mm') as Time,
        day: cell.day.value,
        card: store.getAvailableCardForCardUuid(store.pickedCard.cardUuid),
        context: store.context,
      });

      if (availability.status === 'blocked' || availability.status === 'placed') {
        blocked = true;
      }
      if (availability.status === 'maybe') {
        maybe = true;
      }
    }
  }

  if (store.pickedCard && cell.row.isFunctionClass) {
    valid = store.canBeDropped(store.pickedCard.cardUuid, store.pickedCard.duration, cell.hour.value, cell.day.value);
  }

  if (store.context === 'rooms' && store.pickedCard) {
    valid = store.canBeDropped(store.pickedCard.cardUuid, store.pickedCard.duration, cell.hour.value, cell.day.value);
  }

  const classes = classNames(
    styles['grid-cell'],
    {
      [styles.valid]: valid,
      [styles.blocked]: blocked,
      [styles.warning]: maybe,
      [styles.pause]: isPause,
    },
    className,
  );

  const onCellClick = useCallback(
    async (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      store.placeCard(cell);
    },
    [cell, store],
  );

  const debounceOnCardHover = useDebounceCallback(() => {
    if (store.pickedCard) {
      store.setHoveredCard(store.pickedCard.cardUuid);
    }
  }, 500);

  const onMouseEnter = () => {
    debounceOnCardHover();
  };
  const onMouseLeave = () => {
    debounceOnCardHover.cancel();
  };

  return (
    <div className={classes} onClick={onCellClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className={styles['grid-cell-wrapper']}></div>
    </div>
  );
});
