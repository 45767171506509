import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useDeleteSubjectGroupMutation, useSubjectGroupsQuery } from '../../../types/planung-graphql-client-defs';
import { Button, ButtonGroup, DeleteIcon, EditIcon, Modal, Table, TableColumns } from '@bp/ui-components';
import { useConfirm } from '../../../hooks/useConfirm';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { SubjectGroupForm } from '../Forms/SubjectGroupForm';
import { observer } from 'mobx-react-lite';

type SubjectGroupTableType = {
  uuid: string;
  name: string;
};

export const SubjectGroupTable = observer(() => {
  const { t } = useTranslation();

  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('SUBJECT_GROUPS');

  const { confirm: confirmDelete, ConfirmationDialog } = useConfirm({
    defaultTitle: t('common.delete'),
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [subjectGroupUuid, setSubjectGroupUuid] = useState<null | string>(null);
  const [, deleteSubjectGroup] = useDeleteSubjectGroupMutation();

  const [{ data }] = useSubjectGroupsQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  const tableColumns: TableColumns<SubjectGroupTableType>[] = useMemo(() => {
    return [
      {
        header: t('common.name') as string,
        accessorKey: 'name',
        id: 'name',
        canExpand: true,
      },
    ];
  }, []);

  const memoizedData = useMemo((): SubjectGroupTableType[] => {
    return data
      ? data.subjectGroups.map((subjectGroup) => {
          return {
            uuid: subjectGroup.uuid,
            name: subjectGroup.name,
          };
        })
      : [];
  }, [data]);

  async function onDelete(uuid: string) {
    await deleteSubjectGroup({ uuid }, context);
  }

  return (
    <>
      <Table
        data={memoizedData}
        showActionBar
        showBorderRadius
        showShadow
        minHeight={600}
        onAddClick={() => {
          setSubjectGroupUuid(null);
          setIsModalOpen(true);
        }}
        actionBarSettings={{
          showAddButton: true,
        }}
        hideHeader={memoizedData?.length === 0}
        isOnWhite={false}
        columns={tableColumns}
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                type='button'
                onClick={() => {
                  setSubjectGroupUuid(row.original.uuid);
                  setIsModalOpen(true);
                }}
                icon={<EditIcon className='small' />}
              />
              <Button
                hierarchy='secondary'
                type='button'
                icon={<DeleteIcon className='small' />}
                onClick={async () => {
                  const res = await confirmDelete();
                  if (res) {
                    await onDelete(row.original.uuid);
                  }
                }}
              />
            </ButtonGroup>
          );
        }}
      />
      {isModalOpen && (
        <Modal
          title={subjectGroupUuid ? t('subjectGroup.edit') : t('subjectGroup.add')}
          isOpen={isModalOpen}
          onRequestClose={() => {
            setSubjectGroupUuid(null);
            setIsModalOpen(false);
          }}
        >
          <SubjectGroupForm
            subjectGroupUuid={subjectGroupUuid}
            closeForm={() => {
              setSubjectGroupUuid(null);
              setIsModalOpen(false);
            }}
          ></SubjectGroupForm>
        </Modal>
      )}
      <ConfirmationDialog />
    </>
  );
});
