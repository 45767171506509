import { RouteObject } from 'react-router-dom';
import { t } from 'i18next';
import { EvaluatePlannedPage } from './Planned/EvaluatePlannedPage';
import { EvaluateCurrentPage } from './Current/EvaluateCurrentPage';
import { urqlClient } from '../../../utils/urqlClient';
import { EvaluatePage } from './EvaluatePage';
import { EvaluatePlannedOverview } from './Planned/EvaluatePlannedOverview';
import { ClassesTable } from '../../../components/Lessons/Tables/Classes/ClassesTable';
import { SubjectsTable } from '../../../components/Lessons/Tables/Subjects/SubjectsTable';
import { EvaluateCurrentOverview } from './Current/EvaluteCurrentOverview';
import { EvaluateCurrentBySubject } from './Current/EvaluateCurrentBySubject';
import {
  _PeopleQuery,
  _PeopleQueryVariables,
  _PeopleDocument,
  TimetableBlocksDocument,
  TimetableBlocksQuery,
  TimetableBlocksQueryVariables,
} from '../../../types/planung-graphql-client-defs';
import { EvaluatePlannedTeachersDetail } from './Planned/EvaluatePlannedTeachersDetail';
import { CurrentReports } from './Current/CurrentReports';
import { PlannedReports } from './Planned/PlannedReports';

export const deputateEvaluateRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <EvaluatePage />,
    },

    // Plan Deputate
    {
      path: t('routes.deputate.evaluate.planned.slug'),
      element: <EvaluatePlannedPage baseSlug={baseSlug} />,

      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.deputate.evaluate.planned.slug')}`,
          title: t('routes.deputate.evaluate.planned.title'),
        }),
      },
      children: [
        {
          index: true,
          element: (
            <EvaluatePlannedOverview
              slug={`/${t('routes.deputate.slug')}/${t('routes.evaluate.slug')}/${t('routes.deputate.evaluate.planned.slug')}/${t('routes.lessons.plan.teachers.slug')}`}
            />
          ),
        },
        {
          path: t('routes.lessons.plan.teachers.slug') + '/:teacherUuid',

          loader: ({ params }) => {
            return urqlClient
              .query<_PeopleQuery, _PeopleQueryVariables>(_PeopleDocument, { where: { uuid: params.teacherUuid } })
              .toPromise();
          },
          handle: {
            crumb: ({ data }: { data?: _PeopleQuery }) => {
              return [
                {
                  slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.teachers.slug')}`,
                  title: data?.people[0].fullName,
                },
              ];
            },
          },
          element: <EvaluatePlannedTeachersDetail />,
        },
        {
          path: t('routes.deputate.byClass.slug'),
          element: (
            <ClassesTable
              onRowClickRoute={`/${t('routes.deputate.slug')}/${t('routes.plan.slug')}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.classes.slug')}`}
              isEvaluation={true}
            />
          ),
          handle: {
            crumb: () => ({
              slug: `${baseSlug}/${t('routes.deputate.byClass.slug')}`,
              title: t('routes.deputate.byClass.title'),
            }),
          },
        },
        {
          path: t('routes.deputate.bySubject.slug'),
          element: (
            <SubjectsTable
              onRowClickRoute={`/${t('routes.deputate.slug')}/${t('routes.plan.slug')}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.subjects.slug')}`}
              isEvaluation={true}
            />
          ),
          handle: {
            crumb: () => ({
              slug: `${baseSlug}/${t('routes.deputate.bySubject.slug')}`,
              title: t('routes.deputate.bySubject.title'),
            }),
          },
        },
        {
          path: t('routes.deputate.pdf.slug'),
          element: <PlannedReports />,
          handle: {
            crumb: () => ({
              slug: `${baseSlug}/${t('routes.deputate.pdf.slug')}`,
              title: t('routes.deputate.pdf.title'),
            }),
          },
        },
      ],
    },

    // IST Deputate
    {
      path: t('routes.deputate.evaluate.current.slug') + '/:blockUuid',
      element: <EvaluateCurrentPage baseSlug={baseSlug} />,
      loader: ({ params }) => {
        return urqlClient
          .query<
            TimetableBlocksQuery,
            TimetableBlocksQueryVariables
          >(TimetableBlocksDocument, { where: { uuid: params.blockUuid } })
          .toPromise();
      },
      handle: {
        crumb: ({ data }: { data: TimetableBlocksQuery }) => {
          return [
            {
              slug: `${baseSlug}/${t('routes.deputate.evaluate.current.slug') + '/' + data.timetableBlocks.map((tb) => tb.uuid).join(',')}`,
              title:
                t('routes.deputate.evaluate.current.title') +
                ' ' +
                data.timetableBlocks.map((tb) => tb.timetables.map((tt) => tt.name).join(', ')).join(','),
            },
          ];
        },
      },
      children: [
        {
          index: true,
          element: (
            <EvaluateCurrentOverview
              slug={`/${t('routes.deputate.slug')}/${t('routes.evaluate.slug')}/${t('routes.deputate.evaluate.current.slug')}`}
            />
          ),
        },
        {
          path: t('routes.lessons.plan.teachers.slug') + '/:teacherUuid',

          loader: ({ params }) => {
            return urqlClient
              .query<_PeopleQuery, _PeopleQueryVariables>(_PeopleDocument, { where: { uuid: params.teacherUuid } })
              .toPromise();
          },
          handle: {
            crumb: ({ data }: { data: _PeopleQuery }) => {
              return [
                {
                  slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.teachers.slug')}`,
                  title: data?.people[0].fullName,
                },
              ];
            },
          },

          async lazy() {
            const { EvaluateCurrentTeachersDetail } = await import('./Current/EvaluateCurrentTeachersDetail');
            return { element: <EvaluateCurrentTeachersDetail /> };
          },
        },
        {
          path: t('routes.deputate.bySubject.slug'),
          element: (
            <EvaluateCurrentBySubject
              slug={`/${t('routes.deputate.slug')}/${t('routes.plan.slug')}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.subjects.slug')}`}
            />
          ),
          handle: {
            crumb: () => ({
              slug: `${baseSlug}/${t('routes.deputate.bySubject.slug')}`,
              title: t('routes.deputate.bySubject.title'),
            }),
          },
        },
        {
          path: t('routes.deputate.pdf.slug'),
          element: <CurrentReports />,
          handle: {
            crumb: () => ({
              slug: `${baseSlug}/${t('routes.deputate.pdf.slug')}`,
              title: t('routes.deputate.pdf.title'),
            }),
          },
        }
      ],
    },
  ];
};
