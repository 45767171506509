import { useMutation } from 'urql';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useReducer } from 'react';
import {
  CreateSubjectDocument,
  CreateSubjectMutation,
  CreateSubjectMutationVariables,
  DeleteSubjectsDocument,
  SortDirection,
  UpdateSubjectsDocument,
  UpdateSubjectsMutation,
  UpdateSubjectsMutationVariables,
  useDeleteQualificationMutation,
  useRoomsListQuery,
  useSubjectGroupsQuery,
  useSubjectsListQuery,
} from '../../../../types/planung-graphql-client-defs';
import { loadingReducer, loadingReducerInitialState } from '../../../../reducer/loadingReducer';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { SubjectFormType } from '../../Forms/SubjectForm';

export function useSubject() {
  const context = useMemorizedCacheTag('SUBJECTS');
  const subjectGroupsContext = useMemorizedCacheTag('SUBJECT_GROUPS');
  const roomsContext = useMemorizedCacheTag('ROOMS');

  const { pimAuthClaims } = useAuthClaims();

  const [, create] = useMutation<CreateSubjectMutation, CreateSubjectMutationVariables>(CreateSubjectDocument);
  const [, update] = useMutation<UpdateSubjectsMutation, UpdateSubjectsMutationVariables>(UpdateSubjectsDocument);
  const [, remove] = useMutation(DeleteSubjectsDocument);
  const [, deleteQualification] = useDeleteQualificationMutation();

  const [loadingState, dispatch] = useReducer(loadingReducer, loadingReducerInitialState);

  const [{ data }] = useSubjectsListQuery({
    variables: {
      options: {
        sort: [
          {
            active: SortDirection.Desc,
          },
          {
            name: SortDirection.Asc,
          },
        ],
      },
    },
    context,
  });

  const [{ data: subjectGroupsData }] = useSubjectGroupsQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: subjectGroupsContext,
  });

  const [{ data: roomsData }] = useRoomsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: roomsContext,
  });

  const createSubject = async (values: SubjectFormType) => {
    dispatch({ type: 'SET_LOADING', uuids: ['all'] });

    const res = await create(
      {
        input: {
          name: values.name,
          shortName: values.shortName,
          defaultRooms: {
            connect: values.defaultRooms?.map((roomUuid, index) => {
              return {
                edge: { order: index },
                where: { node: { uuid: roomUuid } },
              };
            }),
          },
          epochEnabled: values.epochEnabled,
          other: values.other,
          subjectHourEnabled: values.subjectHourEnabled,
          subjectGroup: {
            connect: {
              where: { node: { uuid: values.subjectGroup } },
            },
          },
          timetableConfig: {
            create: {
              node: {
                color: values.color,
                colorLabel: values.colorLabel,
                organization: {
                  connect: {
                    where: {
                      node: {
                        uuid: pimAuthClaims.getOrganizationUuid(),
                      },
                    },
                  },
                },
              },
            },
          },

          active: values.active,
          organization: { connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } } },
        },
      },
      context,
    );
    dispatch({ type: 'REMOVE_LOADING', uuids: ['create'] });
    return res;
  };

  const setSubjectGroup = async (subjectUuid: string, subjectGroupUuid: string) => {
    dispatch({ type: 'SET_LOADING', uuids: [subjectUuid] });

    const res = await update(
      {
        where: { uuid: subjectUuid },
        update: { subjectGroup: { disconnect: {}, connect: { where: { node: { uuid: subjectGroupUuid } } } } },
      },
      context,
    );
    dispatch({ type: 'REMOVE_LOADING', uuids: [subjectUuid] });
    return res;
  };

  const updateSubject = async (subjectUuid: string, values: SubjectFormType) => {
    dispatch({ type: 'SET_LOADING', uuids: [subjectUuid] });

    const res = await update(
      {
        where: { uuid: subjectUuid },
        update: {
          name: values?.name,
          shortName: values?.shortName,
          subjectHourEnabled: values?.subjectHourEnabled,
          epochEnabled: values?.epochEnabled,
          active: values?.active,
          other: values?.other,
          timetableConfig: {
            update: {
              node: {
                color: values.color,
                colorLabel: values.colorLabel,
              },
            },
          },
          defaultRooms: [
            {
              disconnect: [{}],
              connect: values.defaultRooms?.map((room, index) => ({
                edge: {
                  order: index,
                },
                where: {
                  node: {
                    uuid: room,
                  },
                },
              })),
            },
          ],
          subjectGroup: {
            disconnect: {},
            connect: {
              where: {
                node: {
                  uuid: values.subjectGroup,
                },
              },
            },
          },
        },
      },
      context,
    );
    dispatch({ type: 'REMOVE_LOADING', uuids: [subjectUuid] });
    return res;
  };

  const deleteSubjects = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });

    await deleteQualification({ where: { subject: { uuid_IN: uuids } } }, context);
    const res = await remove({ where: { uuid_IN: uuids } }, context);
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return res;
  };

  const updateCheckboxes = async ({
    type,
    uuids,
    value,
  }: {
    type: 'subjectHourEnabled' | 'epochEnabled' | 'other' | 'active';
    uuids: string[];
    value: boolean;
  }) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });

    const res = await update({ where: { uuid_IN: uuids }, update: { [type]: value } }, context);

    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });

    return res;
  };

  return {
    createSubject,
    updateSubject,
    deleteSubjects,
    updateCheckboxes,
    setSubjectGroup,
    loadingState,
    data,
    subjectGroupsData,
    roomsData,
  };
}
