import { Card } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import styles from './EvaluatePlannedDeputateStats.module.scss';
import { formatNumber } from '../../../utils/helper';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useMemo } from 'react';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import {
  useAdditionalTeachingLoadsListQuery,
  useListTeacherTeachingLoadQuery,
} from '../../../types/planung-graphql-client-defs';
import { groupByMapArray } from '../../../utils/arrayFunc';
import { DeputateDetailsOverviewData, DeputatesDetailTableData } from '../Overview/DeputateDetailsTeacherOverview';

export const EvaluateCurrentDeputateStats = ({ timetableBlockUuid }: { timetableBlockUuid: string }) => {
  const { pimAuthClaims } = useAuthClaims();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [{ data: deputateData }] = useListTeacherTeachingLoadQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: currentSchoolYear?.uuid ?? '',
      timetableBlockUuid: timetableBlockUuid ?? '',
    },
  });

  const atlContext = useMemorizedCacheTag('ADDITIONAL_TEACHINGLOAD');
  const [{ data: additionalTeachingLoadQueryResult }] = useAdditionalTeachingLoadsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolyear: currentSchoolYear?.uuid ?? '',
    },
    context: atlContext,
  });

  const memoizedStats: DeputateDetailsOverviewData = useMemo(() => {
    const deputatData: Array<DeputatesDetailTableData> = deputateData?.teachingLoadByTeacher ?? [];
    const groupedData = groupByMapArray<string, DeputatesDetailTableData>(
      deputatData,
      (lesson) => lesson.subjectContainerDisplayName ?? '',
    );

    const subjectLessons = groupedData.get('');
    groupedData.delete('');
    const subjectContainerLessons = groupedData;

    const additional =
      additionalTeachingLoadQueryResult?.additionalTeachingLoads.map(
        (additional) =>
          ({
            comment: additional.comment,
            teacherUuid: additional.teacher.uuid ?? '',
            teacherDisplayName: additional.teacherListName ?? '',
            subjectUuid: additional.subject?.uuid ?? '',
            subjectDisplayName: additional.subject?.name ?? '',
            partialClasses: false,
            lessonClassesDisplayName: additional.class?.name ?? '',
            countClasses: additional.class ? 1 : 0,
            countTeacher: 0,
            studentCards: 0,
            teacherCards: 0,
            studentHours: 0,
            teacherHours: 0,
            teachingBlockWeeks: 0,
            teachingBlocks: 0,
            factor: 0,
            deputat: additional.hours,
          }) as DeputatesDetailTableData,
      ) ?? [];

    const sumSubjectLessons = subjectLessons?.map((d) => d.deputat ?? 0).reduce((acc, cur) => acc + cur) ?? 0;
    const sumSubjectContainerLessons = new Map<string, number>();
    const sumAdditional = additional?.map((d) => d.deputat ?? 0).reduce((acc, cur) => acc + cur, 0) ?? 0;

    let sumTotal = sumSubjectLessons + sumAdditional;

    subjectContainerLessons.forEach((value, key) => {
      sumSubjectContainerLessons.set(key, value.map((d) => d.deputat ?? 0).reduce((acc, cur) => acc + cur, 0) ?? 0);
      sumTotal += sumSubjectContainerLessons.get(key) ?? 0;
    });

    return {
      subjectLessons,
      subjectContainerLessons,
      additional,
      sumSubjectLessons,
      sumSubjectContainerLessons,
      sumAdditional,
      sumTotal,
    };
  }, [additionalTeachingLoadQueryResult, deputateData]);

  const { t } = useTranslation();

  return (
    <Card
      header={{ headline: t('deputate.evaluate.allForSchoolyear', { year: currentSchoolYear?.shortName }) }}
      wrapperClass={styles['evaluate-planned-deputate-stats']}
      className={styles.content}
      fitContent
      contentPadding='none'
      fixedHeight={130 + 35 * [...memoizedStats.subjectContainerLessons.keys()].length + 'px'}
    >
      <div className={styles.stats}>
        <div className={styles.stat}>
          <div>{t('deputate.deputateSubject')}</div>
          <div>{formatNumber(memoizedStats.sumSubjectLessons)}</div>
        </div>
        {[...memoizedStats.subjectContainerLessons.keys()].map((key) => {
          return (
            <div className={styles.stat} key={key}>
              <div>{t('deputate.evaluate.subjectContainer', { container: key })}</div>
              <div>{formatNumber(memoizedStats.sumSubjectContainerLessons.get(key) ?? 0.0)}</div>
            </div>
          );
        })}
        <div className={styles.stat}>
          <div>{t('deputate.deputateManually')}</div>
          <div>{formatNumber(memoizedStats.sumAdditional)}</div>
        </div>
        <div className={styles.stat}>
          <div>{t('common.sum')}</div>
          <div>{formatNumber(memoizedStats.sumTotal)}</div>
        </div>
      </div>
    </Card>
  );
};
