import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export const useGetTodayForPersonsQuery = () => {
  const [today, setToday] = useState<string | null>(null);

  useEffect(() => {
    if (!today) {
      setToday(dayjs().utc().format('YYYY-MM-DD'));
    }
  }, [today]);

  return today;
};
