import { AddIcon, Button, Table, TableColumns } from '@bp/ui-components';
import styles from '../../LessonsComponents.module.scss';
import { LessonTableType } from '../TimetableVersionLessonsTable';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeputateLessons } from '../../hooks/useDeputateLessons';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';

type OpenLessonsListProps = {
  filter?: (lesson: LessonTableType) => boolean;
  onAssignClick?: (lesson: LessonTableType) => void;
  loading: boolean;
};

export const OpenLessonsList = ({ filter, onAssignClick, loading }: OpenLessonsListProps) => {
  const { t } = useTranslation();

  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { lessonsData: allLessonData } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
  });

  const data = allLessonData.filter((l) => (filter ? l.teachers.length === 0 && filter(l) : l.teachers.length === 0));

  const tableColumns = useMemo((): TableColumns<LessonTableType>[] => {
    return [
      {
        header: t('lesson.table.subject'),
        id: 'subject',
        accessorKey: 'subject',
        meta: {
          filterName: t('lesson.table.subject'),
        },
        size: 100,
        canExpand: true,
      },
      {
        header: t('lesson.table.classesGroups'),
        id: 'groupClassNames',
        accessorKey: 'groupClassNames',
        size: 100,
        canExpand: true,
      },
      {
        header: t('deputate.titleSingluar'),
        id: 'deputate.titleSingluar',
        cell: ({ row }) => {
          const lessonInfo = row.original.lessonInfo;
          // TODO: Work in Progress, what is contextUuid?!
          const uuid = 'contextUuid';

          // relevant deputate
          const td = lessonInfo?.teacherInfos.get(uuid)?.deputat ?? 0;
          const cd = lessonInfo?.classInfos.get(uuid)?.deputat ?? 0;
          const d = lessonInfo?.deputat ?? 0;
          return (td + cd + d).toFixed(2);
        },
        alignment: 'right',
        size: 75,
      },
      {
        header: t('lesson.basics.isElective') as string,
        id: 'elective',
        accessorKey: 'elective',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTeachingLoadEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTeachingLoadEnabled.full'),
          tooltip: t('lesson.basics.isTeachingLoadEnabled.full'),
        },
        id: 'teachingLoadEnabled',
        accessorKey: 'teachingLoadEnabled',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTimetableEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTimetableEnabled.full'),
          tooltip: t('lesson.basics.isTimetableEnabled.full'),
        },
        id: 'timetableEnabled',
        accessorKey: 'timetableEnabled',
        type: 'boolean',
        size: 60,
      },
    ];
  }, []);

  return (
    <Table
      loading={loading}
      className={styles['open-lessons-table']}
      showBorderRadius
      showShadow
      canScroll={data.length > 0}
      columns={tableColumns}
      data={data}
      maxHeight={244}
      emptyStateSettings={{ size: 'small', padding: 's' }}
      lastCol={(row) => {
        return (
          <Button
            hierarchy={'tertiary'}
            onClick={() => {
              onAssignClick && onAssignClick(row.original);
            }}
            icon={<AddIcon />}
          />
        );
      }}
    />
  );
};
