export enum BitStatus {
  OCCUPIED = 0,
  MAYBE = 1,
  FREE = 2,
  OCCUPIED_BLOCKED = 3,
  MAYBE_BLOCKED = 4,
  FREE_BLOCKED = 5,
  OCCUPIED_MULTI_BLOCKED = 6,
  MAYBE_MULTI_BLOCKED = 7,
  FREE_MULTI_BLOCKED = 8,
}

const BLOCKED = 3;

export function StatusManager(): {
  block: (status: BitStatus) => number;
  unblock: (status: BitStatus) => number;
  isBlocked: (status: BitStatus) => boolean;
  isMultiBlocked: (status: BitStatus) => boolean;
  stringToBit: (item: string) => BitStatus;
  isFree: (status: BitStatus) => boolean;
  isOccupied: (status: BitStatus) => boolean;
  isMaybe: (status: BitStatus) => boolean;
} {
  function block(status: BitStatus): number {
    if (status === BitStatus.OCCUPIED || status === BitStatus.MAYBE || status === BitStatus.FREE)
      return status + BLOCKED;
    if (
      status === BitStatus.OCCUPIED_BLOCKED ||
      status === BitStatus.MAYBE_BLOCKED ||
      status === BitStatus.FREE_BLOCKED
    )
      return status + BLOCKED;
    return status;
  }

  function unblock(status: BitStatus): number {
    if (
      status === BitStatus.OCCUPIED_BLOCKED ||
      status === BitStatus.MAYBE_BLOCKED ||
      status === BitStatus.FREE_BLOCKED ||
      status === BitStatus.OCCUPIED_MULTI_BLOCKED ||
      status === BitStatus.MAYBE_MULTI_BLOCKED ||
      status === BitStatus.FREE_MULTI_BLOCKED
    )
      return status - BLOCKED;
    return status;
  }

  function isBlocked(status: BitStatus): boolean {
    return status > BitStatus.FREE;
  }

  function isMultiBlocked(status: BitStatus): boolean {
    return status > BitStatus.FREE_BLOCKED;
  }

  function stringToBit(item: string): BitStatus {
    const stringToBitStatusMap: { [key: string]: BitStatus } = {
      '0': BitStatus.OCCUPIED,
      '1': BitStatus.MAYBE,
      '2': BitStatus.FREE,
      '3': BitStatus.OCCUPIED_BLOCKED,
      '4': BitStatus.MAYBE_BLOCKED,
      '5': BitStatus.FREE_BLOCKED,
      '6': BitStatus.OCCUPIED_MULTI_BLOCKED,
      '7': BitStatus.MAYBE_MULTI_BLOCKED,
      '8': BitStatus.FREE_MULTI_BLOCKED,
    };

    return stringToBitStatusMap[item] ?? BitStatus.OCCUPIED;
  }

  function isFree(status: BitStatus): boolean {
    return status % BLOCKED === BitStatus.FREE;
  }

  function isOccupied(status: BitStatus): boolean {
    return status % BLOCKED === BitStatus.OCCUPIED;
  }

  function isMaybe(status: BitStatus): boolean {
    return status % BLOCKED === BitStatus.MAYBE;
  }

  return {
    isFree,
    isOccupied,
    isMaybe,
    block,
    unblock,
    isBlocked,
    isMultiBlocked,
    stringToBit,
  };
}
