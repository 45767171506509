import {
  AddIcon,
  Button,
  ButtonGroup,
  Card,
  DuplicateIcon,
  Grid,
  GridColumn,
  GridRow,
  ImportIcon,
  Modal,
  Row,
  Skeleton,
  Tab,
  Tooltip,
} from '@bp/ui-components';
import { TeachingBlockVersionClassesTable } from '../../../../../../components/TeachingBlockVersion/TeachingBlockVersionDataTables/TeachingBlockVersionClassesTable';
import { TeachingBlockVersionTableType } from '../../../../../../components/TeachingBlockVersion/graphql/types';
import styles from '../TeachingBlockVersionData.module.scss';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createLessonFilterUrl } from '../../../../../../utils/create-lesson-filter-url';
import { ExclusionTimeframeForm } from '../../../../../../components/ExclusionTimeframe/ExclusionTimeframeForm';
import { TeachingBlockVersionImportClasses } from '../../../../../../components/TeachingBlockVersion/TeachingBlockVersionImportClasses/TeachingBlockVersionImportClasses';
import { observer } from 'mobx-react-lite';
import { useTeachingBlockStore } from '../../../../../../components/TeachingBlockGrid/TeachingBlockContext';
import { useLoadBasicData } from '../../../../../../hooks/useLoadBasicData';
import { hexToColorOption } from '../../../../../../utils/colorUtils';
import { Exclusions } from '../../../../../../components/TeachingBlockVersion/Exclusions/Exclusions';
import { cacheTags, useMemorizedCacheTag } from '../../../../../../hooks/useMemorizedCacheTag';
import {
  SortDirection,
  use_DeleteTeachingBlockAvailabilityExclusionsMutation,
  use_TeachingBlockAvailabilityExclusionsQuery,
  use_UpdateTeachingBlockVersionsMutation,
} from '../../../../../../types/planung-graphql-client-defs';
import { partition } from '../../../../../../utils/arrayFunc';
import { useConfirm } from '../../../../../../hooks/useConfirm';

export const PlanTeachingBlockClasses = observer(() => {
  const { t } = useTranslation();
  const { teachingBlockVersionUuid } = useParams();
  const navigate = useNavigate();
  const { confirm, ConfirmationDialog } = useConfirm();

  const [tab, setTab] = useState<'exclusion' | 'condition' | 'division'>('exclusion');

  const [exclusionModalOpen, setExclusionModalOpen] = useState(false);
  const [selectedExclusionUuid, setSelectedExclusionUuid] = useState<string | null>(null);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const store = useTeachingBlockStore();

  const versionClassesUuids: string[] = Array.from(store.classes.keys());
  const usedPlacedClasses = store.placedCards.flatMap((c) => c.lessonClasses.map((lc) => lc.class.uuid));
  const usedFreeClasses = store.freeCards.flatMap((c) => c.lessonClasses.map((lc) => lc.class.uuid));
  const usedClasses = [...usedPlacedClasses, ...usedFreeClasses];

  const { classesData } = useLoadBasicData({ pause: !teachingBlockVersionUuid });

  const context = useMemorizedCacheTag('TEACHING_BLOCK_AVAILABILITY_EXCLUSION');
  const importClassesContext = useMemo(() => {
    return {
      additionalTypenames: [cacheTags.TEACHINGBLOCK_VERSIONS, cacheTags.CLASSES],
    };
  }, []);

  const [, updateVersion] = use_UpdateTeachingBlockVersionsMutation();

  const [, deleteAvailabilityExclusions] = use_DeleteTeachingBlockAvailabilityExclusionsMutation();

  const [{ data: exclusionsData }] = use_TeachingBlockAvailabilityExclusionsQuery({
    variables: {
      where: {
        teachingBlockVersion: {
          uuid: teachingBlockVersionUuid,
        },
      },
      options: {
        sort: [{ from: SortDirection.Asc }],
      },
    },
    context: context,
  });

  const classTableData: TeachingBlockVersionTableType[] = useMemo(() => {
    return (
      classesData?.classes
        .filter((c) => versionClassesUuids.includes(c.uuid))
        .map((c) => {
          const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
          return {
            color: {
              color: html,
              label,
            },
            count: 0,
            name: c.name,
            shortName: c.shortName,
            uuid: c.uuid,
          };
        }) ?? []
    );
  }, [classesData?.classes, versionClassesUuids]);

  const [selected, setSelected] = useState<TeachingBlockVersionTableType | null>(classTableData[0] ?? null);

  const selectedExclusionUuids = exclusionsData?.teachingBlockAvailabilityExclusions
    .filter((e) => e.holder.uuid === selected?.uuid)
    .map((e) => e.uuid);

  const onExclusionEdit = (uuid: string) => {
    setSelectedExclusionUuid(uuid);
    setExclusionModalOpen(true);
  };

  const onExclusionModalClose = () => {
    setSelectedExclusionUuid(null);
    setExclusionModalOpen(false);
  };

  const importClasses = async (uuids: string[]) => {
    setLoading(true);
    await updateVersion({
      where: {
        uuid: teachingBlockVersionUuid,
      },
      update: {
        classes: uuids.map((uuid) => ({
          connect: [{ where: { node: { uuid } } }],
        })),
      },
    });
    setLoading(false);
    setImportModalOpen(false);
    store.addClasses(classesData?.classes.filter((c) => uuids.includes(c.uuid)) ?? []);
  };

  const handleRemove = async (rows: Row<TeachingBlockVersionTableType>[]) => {
    const [, notUsed] = partition(rows, (row) => usedClasses.includes(row.original.uuid));
    const uuids = notUsed.map((r) => r.original.uuid);

    const res = await confirm({
      title: t('timetableVersion.removeClasses', { count: uuids.length }),
      message: t('timetableVersion.removeClassesDescription', { count: uuids.length }),
    });

    if (res) {
      await deleteAvailabilityExclusions(
        {
          where: {
            holder: {
              Class: {
                uuid_IN: uuids,
              },
            },
          },
        },
        context,
      );

      await updateVersion(
        {
          where: {
            uuid: teachingBlockVersionUuid,
          },
          update: {
            classes: [{ disconnect: [{ where: { node: { uuid_IN: uuids } } }] }],
          },
        },
        importClassesContext,
      );
      store.removeClasses(uuids);
    }
  };

  return (
    <>
      <Grid>
        <GridRow spacingBottom='none'>
          <Button
            hierarchy='tertiary'
            icon={<ImportIcon />}
            className='mb-4'
            style={{ marginLeft: 'auto' }}
            onClick={() => setImportModalOpen(true)}
          >
            {t('teachingBlockVersion.import', { import: t('classes.title.plural') })}
          </Button>
        </GridRow>
        <GridRow spacingTop='none'>
          <GridColumn width={classTableData.length === 0 ? 12 : 6}>
            <TeachingBlockVersionClassesTable
              handleRemove={handleRemove}
              versionUuid={teachingBlockVersionUuid}
              versionClassesUuids={versionClassesUuids}
              onRowSelection={(selected) => {
                setSelected(selected);
              }}
              onShowLessonClick={(uuid) => {
                if (teachingBlockVersionUuid) {
                  navigate(
                    createLessonFilterUrl({
                      context: 'epochenplan',
                      filterValue: uuid,
                      versionUuid: teachingBlockVersionUuid,
                      column: 'classes',
                    }),
                  );
                }
              }}
            />
          </GridColumn>
          {classTableData.length !== 0 && (
            <GridColumn width={6}>
              <Card className={styles['teaching-block-version-data']} contentPadding='none'>
                <small>{t('teachingBlockVersion.specificInfo', { for: selected?.name })}</small>
                <div className={styles['mini-tab']}>
                  <Tab
                    value={tab}
                    key={selected?.uuid}
                    onValueChange={(content) => setTab(content as 'exclusion' | 'condition' | 'division')}
                    className={styles.tabs}
                    tabs={[
                      {
                        title: t('exclusion.title.plural'),
                        value: 'exclusion',
                        content: <Exclusions uuids={selectedExclusionUuids ?? []} onEdit={onExclusionEdit} />,
                      },
                      {
                        title: t('conditions.title.plural'),
                        value: 'condition',
                        content: (
                          <div className='px-6 py-4'>
                            <Skeleton />
                          </div>
                        ),
                      },
                      {
                        title: t('divisions.title.plural'),
                        value: 'division',
                        content: (
                          <div className='px-6 py-4'>
                            <Skeleton />
                          </div>
                        ),
                      },
                    ]}
                  />
                  <div className={styles['additional-actions']}>
                    {tab === 'exclusion' && (
                      <Tooltip content={t('exclusion.add')}>
                        <Button hierarchy='ghost' icon={<AddIcon />} onClick={() => setExclusionModalOpen(true)} />
                      </Tooltip>
                    )}
                    {tab === 'condition' && (
                      <ButtonGroup>
                        <Tooltip content={t('constraint.duplicate')}>
                          <Button hierarchy='ghost' icon={<DuplicateIcon />} disabled />
                        </Tooltip>
                        <Tooltip content={t('constraint.edit')}>
                          <Button hierarchy='ghost' icon={<AddIcon />} disabled />
                        </Tooltip>
                      </ButtonGroup>
                    )}
                  </div>
                </div>
              </Card>
            </GridColumn>
          )}
        </GridRow>
        <Modal
          width='s'
          title={t('exclusion.add')}
          isOpen={exclusionModalOpen}
          onRequestClose={() => onExclusionModalClose()}
        >
          <ExclusionTimeframeForm
            type='Class'
            holderUuid={selected?.uuid ?? ''}
            teachingBlockVersionUuid={teachingBlockVersionUuid ?? ''}
            exclusionUuid={selectedExclusionUuid}
            onClose={() => onExclusionModalClose()}
          />
        </Modal>

        {importModalOpen && (
          <Modal
            isOpen={importModalOpen}
            onRequestClose={() => setImportModalOpen(false)}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            title={t('timetableVersion.import', { import: t('classes.title.plural') })}
            height={'l'}
            loading={loading}
          >
            {teachingBlockVersionUuid && importModalOpen && (
              <TeachingBlockVersionImportClasses
                importClasses={importClasses}
                versionUuid={teachingBlockVersionUuid}
                onClose={() => {
                  setImportModalOpen(false);
                }}
              />
            )}
          </Modal>
        )}
      </Grid>
      <ConfirmationDialog />
    </>
  );
});
