import { LessonsQuery, LessonsWithoutCardsQuery } from '../../../types/planung-graphql-client-defs';

export type LessonType = Pick<LessonsQuery | LessonsWithoutCardsQuery, 'lessons'>['lessons'][number];

export const getStatus = (lesson: LessonType, versionContext: boolean) => {
  // Zuordnung ist im aktiven Stundenplan und aktiver Version
  if (
    // Stundenplan
    (lesson.versions.some((v) => v.active) && lesson.activeTimetables && lesson.activeTimetables?.length > 0) ||
    // Epochenplan
    (lesson.teachingBlockVersions.some((v) => v.active) &&
      lesson.activeTeachingBlocks &&
      lesson.activeTeachingBlocks?.length > 0)
  ) {
    return 'blocked';
  }

  // Deputats-Zuordnung in Stundenplan Version context
  if (versionContext && !lesson.onlyInTimetableVersion) {
    return 'blocked';
  }

  // Stunden/Epochenplan-plan-Zuordnung in Version context hat gesteckte Karten
  if (
    versionContext &&
    lesson.onlyInTimetableVersion &&
    lesson.placedCardsCount &&
    lesson.placedCardsCount !== 0 &&
    lesson.teachingBlockPlacedCardsCount !== 0
  ) {
    return 'placedCards';
  }

  // Stundenplan - Deputats-Zuordnung ist in aktiven Version und im nicht aktiven Stundenplan und es sind Karten gesteckt
  if (
    !lesson.onlyInTimetableVersion &&
    lesson.versions.some((v) => v.active) &&
    lesson.activeTimetables?.length === 0 &&
    lesson.placedCardsCount &&
    lesson.placedCardsCount !== 0
  ) {
    return 'placedCards';
  }

  // Epochenplan - Deputats-Zuordnung ist in aktiven Version und im nicht aktiven Epochenplan und es sind Karten gesteckt
  if (
    !lesson.onlyInTimetableVersion &&
    lesson.teachingBlockPlacedCardsCount &&
    lesson.teachingBlockPlacedCardsCount !== 0
  ) {
    return 'placedCards';
  }

  // EpochenPlan -  Deputats-Zuordnung ist in aktiven Version und im nicht aktiven Epochenplan und es sind keine Karten gesteckt
  if (!lesson.onlyInTimetableVersion && lesson.teachingBlockVersions.length > 0) {
    return 'inVersion';
  }

  // Stundenplan - Deputats-Zuordnung ist in aktiven Version und im nicht aktiven Stundenplan und es sind keine Karten gesteckt
  if (
    !lesson.onlyInTimetableVersion &&
    lesson.versions.length > 0 &&
    lesson.versions.some((v) => v.active) &&
    lesson.activeTimetables?.length === 0 &&
    lesson.placedCardsCount === 0
  ) {
    return 'inVersion';
  }

  //  Stundenplan - Deputats-Zuordnung ist in NICHT aktiven Version und im nicht aktiven Stundenplan und es sind keine Karten gesteckt
  if (
    !lesson.onlyInTimetableVersion &&
    lesson.versions.length > 0 &&
    lesson.versions.every((v) => !v.active) &&
    lesson.activeTimetables?.length === 0 &&
    lesson.placedCardsCount === 0
  ) {
    return 'inVersion';
  }

  // Deputats-Zuordnung ist in Version
  if (!lesson.onlyInTimetableVersion && (lesson.versions.length > 0 || lesson.teachingBlockVersions.length > 0)) {
    return 'used';
  }
};
