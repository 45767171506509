import {
  ArrowHeadLeftIcon,
  ArrowHeadRightIcon,
  Button,
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  Tooltip,
} from '@bp/ui-components';
import { ModalBottomButtons } from '../../../ModalBottomButtons/ModalBottomButtons';
import { t } from 'i18next';
import { TeachingBlockCardType } from '../../types';
import { useTeachingBlockStore } from '../../TeachingBlockContext';
import { HolidayType } from '../../../Holidays/graphql';
import dayjs, { Dayjs } from 'dayjs';
import {
  isFirstBetweenSecondAndThird,
  isFirstSameOrAfterSecond,
  isFirstSameOrBeforeSecond,
  isFirstBeforeSecond,
  niceDate,
} from '../../../../utils/dateCalculations';
import { TeachingBlockCard } from '../../TeachingBlockCard/TeachingBlockCard';
import styles from './CardDurationForm.module.scss';
import classNames from 'classnames';
import { isHoliday } from '../../../../utils/typeguards';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

type CardDurationFormProps = {
  card: TeachingBlockCardType;
  isVirtual: boolean;
  previous?: HolidayType | TeachingBlockCardType | null;
  next?: HolidayType | TeachingBlockCardType | null;
  onClose: () => void;
  className?: string | undefined;
};

export const CardDurationForm = observer(
  ({ card, isVirtual, previous, next, onClose, className }: CardDurationFormProps) => {
    const [startDate, setStartDate] = useState<Dayjs>();
    const [endDate, setEndDate] = useState<Dayjs>();
    const [errors, setErrors] = useState<{ start?: string; end?: string }>({});

    const { currentVersion, updateCard, schoolYear } = useTeachingBlockStore();

    const isActive = !!currentVersion.active;
    const today = dayjs().startOf('day');
    const todayInTimeframe = isFirstSameOrAfterSecond(schoolYear.start, today);
    const startInTimeframe = isActive && isFirstSameOrBeforeSecond(card.startDate, today);
    const previousBeforeToday =
      isActive &&
      !!previous &&
      isFirstBetweenSecondAndThird(isHoliday(previous) ? previous.end : previous?.endDate, today, card.endDate);

    function validate(startValue: Dayjs | null, endValue: Dayjs | null): boolean {
      setErrors({});

      const start = startValue ?? startDate ?? dayjs(card.startDate).startOf('day');
      const end = endValue ?? endDate ?? dayjs(card.endDate).startOf('day');

      if (previous) {
        if (isHoliday(previous)) {
          if (isFirstSameOrBeforeSecond(start, previous.end)) {
            setErrors({ ...errors, start: t('validation.pinboard.startBeforeHolidayEnd') });
          }
        } else {
          if (isFirstSameOrBeforeSecond(start, previous.endDate)) {
            setErrors({ ...errors, start: t('validation.pinboard.startBeforeCardEnd') });
          }
        }
      }
      if (isActive && todayInTimeframe && !startInTimeframe && isFirstSameOrBeforeSecond(start, today)) {
        setErrors({ ...errors, start: t('validation.pinboard.startBeforeToday') });
      }
      if (isFirstBeforeSecond(start, schoolYear.start)) {
        setErrors({ ...errors, start: t('validation.pinboard.startBeforeSchoolyearStart') });
      }
      if (isFirstSameOrAfterSecond(start, end)) {
        setErrors({ ...errors, start: t('validation.pinboard.startAfterEnd') });
      }

      if (next) {
        if (isHoliday(next)) {
          if (isFirstSameOrAfterSecond(end, next.start)) {
            setErrors({ ...errors, end: t('validation.pinboard.endAfterHolidayStart') });
          }
        } else {
          if (isFirstSameOrAfterSecond(end, next.startDate)) {
            setErrors({ ...errors, end: t('validation.pinboard.endAfterCardStart') });
          }
        }
      }
      if (isActive && startInTimeframe && isFirstSameOrBeforeSecond(end, today)) {
        setErrors({ ...errors, end: t('validation.pinboard.endBeforeToday') });
      }
      if (isFirstSameOrAfterSecond(end, dayjs(schoolYear.end).add(1, 'day'))) {
        setErrors({ ...errors, end: t('validation.pinboard.endAfterSchoolyearEnd') });
      }
      if (end.isSameOrBefore(start, 'day')) {
        setErrors({ ...errors, end: t('validation.pinboard.endBeforeStart') });
      }

      return Object.keys(errors).length === 0;
    }

    function reset() {
      setStartDate(undefined);
      setEndDate(undefined);
      setErrors({});
    }

    function submit() {
      if (startDate && endDate && !errors.start && !errors.end) {
        updateCard(card, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), card.includeHoliday, isVirtual);
      }
      reset();
      onClose();
    }

    useEffect(() => {
      setStartDate(dayjs(card.startDate).startOf('day'));
      setEndDate(dayjs(card.endDate).startOf('day'));
    }, []);

    const classes = classNames(styles['card-form'], className);

    const getMinMaxDates = () => {
      const minDateStart = previous
        ? isHoliday(previous)
          ? dayjs(previous.end)
          : dayjs(previous.endDate)
        : dayjs(schoolYear?.start);

      const maxDateStart = next
        ? isHoliday(next)
          ? dayjs(next.start)
          : dayjs(next.startDate)
        : dayjs(schoolYear?.end);
      const minDateEnd = startDate ?? dayjs(card.startDate).startOf('day');
      const maxDateEnd = next ? (isHoliday(next) ? dayjs(next.start) : dayjs(next.startDate)) : dayjs(schoolYear?.end);
      if (isActive) {
        return {
          minDateStart: (isFirstSameOrBeforeSecond(minDateStart, today)
            ? today.add(1, 'day')
            : minDateStart.add(1, 'day')
          ).toDate(),
          maxDateStart: (isFirstSameOrBeforeSecond(maxDateStart, today)
            ? today.subtract(1, 'day')
            : maxDateStart.subtract(1, 'day')
          ).toDate(),
          minDateEnd: (isFirstSameOrBeforeSecond(minDateEnd, today)
            ? today.add(1, 'day')
            : minDateEnd.add(1, 'day')
          ).toDate(),
          maxDateEnd: (isFirstSameOrBeforeSecond(maxDateEnd, today)
            ? today.subtract(1, 'day')
            : maxDateEnd.subtract(1, 'day')
          ).toDate(),
        };
      }

      return {
        minDateStart: minDateStart.add(1, 'day').toDate(),
        maxDateStart: maxDateStart.subtract(1, 'day').toDate(),
        minDateEnd: minDateEnd.add(1, 'day').toDate(),
        maxDateEnd: maxDateEnd.subtract(1, 'day').toDate(),
      };
    };

    return (
      <form className={classes}>
        <Grid useFormGap className={styles['date-grid']}>
          <GridRow spacingBottom='none'>
            <GridColumn width={2}>
              {startInTimeframe ? (
                <Input
                  name='start-before-today'
                  readonly
                  value={niceDate(card.startDate, 'medium')}
                  label={t('pinboard.edit.duration.startDate')}
                />
              ) : (!!previous && previousBeforeToday) || (!!previous && !isActive) ? (
                isHoliday(previous) ? (
                  <Input
                    name='prev-holiday'
                    readonly
                    value={niceDate(previous.end, 'medium')}
                    label={t('pinboard.edit.duration.prevHolidayEnd')}
                  />
                ) : (
                  <Input
                    name='prev-card'
                    readonly
                    value={niceDate(previous.endDate, 'medium')}
                    label={t('pinboard.edit.duration.prevCardEnd')}
                  />
                )
              ) : todayInTimeframe ? (
                <Input
                  name='timeframe'
                  readonly
                  value={niceDate(today.toDate(), 'medium')}
                  label={t('pinboard.edit.duration.currentDate')}
                />
              ) : (
                <Input
                  name='year-start'
                  readonly
                  value={niceDate(schoolYear?.start, 'medium')}
                  label={t('pinboard.edit.duration.schoolYearStart')}
                />
              )}
            </GridColumn>

            <GridColumn width={1}>
              <Tooltip
                triggerClass={styles.picker}
                content={
                  startInTimeframe
                    ? t('pinboard.edit.duration.pickStartDisabledTooltip')
                    : t('pinboard.edit.duration.pickStartTooltip')
                }
              >
                <Button
                  hierarchy='tertiary'
                  icon={<ArrowHeadRightIcon />}
                  disabled={startInTimeframe}
                  onClick={() => {
                    const startValue = dayjs(
                      (!!previous && previousBeforeToday) || (!!previous && !isActive)
                        ? isHoliday(previous)
                          ? previous.end
                          : previous.endDate
                        : todayInTimeframe
                          ? undefined
                          : schoolYear?.start,
                    )
                      .startOf('day')
                      .add(1, 'day');
                    validate(startValue, null);
                    setStartDate(startValue);
                  }}
                />
              </Tooltip>
            </GridColumn>

            <GridColumn width={3}>
              <DatePicker
                minDate={getMinMaxDates().minDateStart}
                maxDate={getMinMaxDates().maxDateStart}
                tooltipText={t('pinboard.edit.duration.fromHint')}
                label={t('pinboard.edit.duration.start')}
                name='start'
                disabled={startInTimeframe}
                onChange={(value) => {
                  const startValue = dayjs(value).startOf('day');
                  validate(startValue, null);
                  setStartDate(startValue);
                }}
                value={startDate?.toDate()}
                error={errors.start}
              />
            </GridColumn>
            <GridColumn width={3}>
              <DatePicker
                minDate={getMinMaxDates().minDateEnd}
                maxDate={getMinMaxDates().maxDateEnd}
                tooltipText={t('pinboard.edit.duration.untilHint')}
                label={t('pinboard.edit.duration.end')}
                name='end'
                onChange={(value) => {
                  const endValue = dayjs(value).startOf('day');
                  validate(null, endValue);
                  setEndDate(endValue);
                }}
                value={endDate?.toDate()}
                error={errors.end}
              />
            </GridColumn>

            <GridColumn width={1}>
              <Tooltip triggerClass={styles.picker} content={t('pinboard.edit.duration.pickEndTooltip')}>
                <Button
                  hierarchy='tertiary'
                  icon={<ArrowHeadLeftIcon />}
                  onClick={() => {
                    const endValue = dayjs(next ? (isHoliday(next) ? next.start : next.startDate) : schoolYear?.end)
                      .startOf('day')
                      .subtract(1, 'day');
                    validate(null, endValue);
                    setEndDate(endValue);
                  }}
                />
              </Tooltip>
            </GridColumn>

            <GridColumn width={2}>
              {next ? (
                isHoliday(next) ? (
                  <Input
                    name='next-holiday'
                    readonly
                    value={niceDate(dayjs(next.start).toDate(), 'medium')}
                    label={
                      next.uuid === 'school-year-end-indicator'
                        ? t('pinboard.edit.duration.schoolYearEnd')
                        : t('pinboard.edit.duration.nextHolidayStart')
                    }
                  />
                ) : (
                  <Input
                    name='next-card'
                    readonly
                    value={niceDate(next.startDate, 'medium')}
                    label={t('pinboard.edit.duration.nextCardStart')}
                  />
                )
              ) : (
                <Input
                  name='year-end'
                  readonly
                  value={niceDate(schoolYear?.end, 'medium')}
                  label={t('pinboard.edit.duration.schoolYearEnd')}
                />
              )}
            </GridColumn>
          </GridRow>

          <GridRow spacingTop='none'>
            <GridColumn width={2} align='end'>
              {startInTimeframe ? (
                <div className={styles['start-preview']}>{t('pinboard.edit.duration.start')}</div>
              ) : (!!previous && previousBeforeToday) || (!!previous && !isActive) ? (
                isHoliday(previous) ? (
                  <div className={styles['holiday-preview']}>{previous.name}</div>
                ) : (
                  <TeachingBlockCard className={styles['card-preview']} card={previous} passive />
                )
              ) : todayInTimeframe ? (
                <div className={styles['today-preview']}>{t('common.today')}</div>
              ) : (
                <div className={styles['holiday-preview']}>
                  {t('pinboard.edit.duration.schoolYearStartHint', { year: schoolYear?.shortName })}
                </div>
              )}
            </GridColumn>
            <GridColumn offset='left' width={2}>
              {next ? (
                isHoliday(next) ? (
                  <div className={styles['holiday-preview']}>{next.name}</div>
                ) : (
                  <TeachingBlockCard className={styles['card-preview']} card={next} passive />
                )
              ) : (
                <div className={styles['holiday-preview']}>
                  {t('pinboard.edit.duration.schoolYearEndHint', { year: schoolYear?.shortName })}
                </div>
              )}
            </GridColumn>
          </GridRow>
        </Grid>

        <ModalBottomButtons
          closeButton={{
            text: t('common.cancelChanges'),
            callback: () => {
              reset();
              onClose();
            },
          }}
          submitButton={{
            disabled: !!errors.start || !!errors.end,
            callback: () => submit(),
          }}
        />
      </form>
    );
  },
);
