import { useTranslation } from 'react-i18next';
import { Grid, GridColumn, GridRow, RadioGroup } from '@bp/ui-components';
import { lazy, useState } from 'react';
import { DependenciesFormProps } from '../types';

import styles from './DependenciesForm.module.scss';

const Dependency4001 = lazy(() => import('./components/Dependency4001'));
const Dependency4002 = lazy(() => import('./components/Dependency4002'));
const Dependency4004 = lazy(() => import('./components/Dependency4004'));
const Dependency4006 = lazy(() => import('./components/Dependency4006'));
const Dependency4005 = lazy(() => import('./components/Dependency4005'));
const Dependency4007 = lazy(() => import('./components/Dependency4007'));
const Dependency4008 = lazy(() => import('./components/Dependency4008'));
const Dependency4009 = lazy(() => import('./components/Dependency4009'));

export const DependenciesForm = ({ handleClose, versionUuid, currentDependency }: DependenciesFormProps) => {
  const { t } = useTranslation();
  const [activeDependency, setActiveDependency] = useState<string | null>(currentDependency?.alias ?? null);

  const radioGroupOptionsSubject = [
    { label: t('dependencies.4001.label'), value: '4001' },
    { label: t('dependencies.4002.label'), value: '4002' },
    { label: t('dependencies.4004.label'), value: '4004' },
    { label: t('dependencies.4005.label'), value: '4005' },
    { label: t('dependencies.4006.label'), value: '4006' },
    { label: t('dependencies.4007.label'), value: '4007' },
    { label: t('dependencies.4008.label'), value: '4008' },
    { label: t('dependencies.4009.label'), value: '4009' },
  ];

  // future cases
  // const radioGroupOptionsTeachers: RadioButtonType[] = [{ label: '', value: '0000' }];
  // const radioGroupOptionsClasses: RadioButtonType[] = [{ label: '', value: '0000' }];

  return (
    <div>
      {!activeDependency && (
        <>
          {t('dependencies.formText')}
          <Grid>
            <GridRow spacingTop={'l'}>
              <GridColumn width={4}>
                <h3>{t('subject.title.plural')}</h3>
                <RadioGroup
                  options={radioGroupOptionsSubject}
                  value={''}
                  className={styles.radio}
                  name={'subjectDependencies'}
                  onChange={(value) => {
                    value && setActiveDependency(value);
                  }}
                />
              </GridColumn>
              {/* future cases */}
              {/*<GridColumn width={4}>*/}
              {/*  <h3>{t('teachers.title')}</h3>*/}
              {/*  <RadioGroup*/}
              {/*    options={radioGroupOptionsTeachers}*/}
              {/*    value={''}*/}
              {/*    name={'teacherDependencies'}*/}
              {/*    onChange={(value) => {*/}
              {/*      value && setActiveDependency(value);*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</GridColumn>*/}
              {/*<GridColumn width={4}>*/}
              {/*  <h3>{t('classes.title.plural')}</h3>*/}
              {/*  <RadioGroup*/}
              {/*    options={radioGroupOptionsClasses}*/}
              {/*    value={''}*/}
              {/*    name={'classDependencies'}*/}
              {/*    onChange={(value) => {*/}
              {/*      value && setActiveDependency(value);*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</GridColumn>*/}
            </GridRow>
          </Grid>
        </>
      )}
      {activeDependency === '4001' && (
        <Dependency4001 handleClose={handleClose} versionUuid={versionUuid} currentDependency={currentDependency} />
      )}

      {activeDependency === '4002' && (
        <Dependency4002 handleClose={handleClose} versionUuid={versionUuid} currentDependency={currentDependency} />
      )}

      {activeDependency === '4004' && (
        <Dependency4004 handleClose={handleClose} versionUuid={versionUuid} currentDependency={currentDependency} />
      )}

      {activeDependency === '4006' && (
        <Dependency4006 handleClose={handleClose} versionUuid={versionUuid} currentDependency={currentDependency} />
      )}

      {activeDependency === '4005' && (
        <Dependency4005 handleClose={handleClose} versionUuid={versionUuid} currentDependency={currentDependency} />
      )}

      {activeDependency === '4007' && (
        <Dependency4007 handleClose={handleClose} versionUuid={versionUuid} currentDependency={currentDependency} />
      )}

      {activeDependency === '4008' && (
        <Dependency4008 handleClose={handleClose} versionUuid={versionUuid} currentDependency={currentDependency} />
      )}

      {activeDependency === '4009' && (
        <Dependency4009 handleClose={handleClose} versionUuid={versionUuid} currentDependency={currentDependency} />
      )}
    </div>
  );
};
