import { Day } from '../components/AvailabilityMatrix/AvailabilityMatrix';
import i18next from 'i18next';

export function dayToTranslation(day: Day): string {
  switch (day) {
    case 'mon':
      return i18next.t('days.monday');
    case 'tue':
      return i18next.t('days.tuesday');
    case 'wed':
      return i18next.t('days.wednesday');
    case 'thu':
      return i18next.t('days.thursday');
    case 'fri':
      return i18next.t('days.friday');
    case 'sat':
      return i18next.t('days.saturday');
    case 'sun':
      return i18next.t('days.sunday');
  }
}
