import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, RowSelectionState, Table, TableColumns } from '@bp/ui-components';
import { ModalBottomButtons } from '../../../ModalBottomButtons/ModalBottomButtons';
import {
  use_ClassesQuery,
  use_LessonClassesQuery,
  use_LessonsQuery,
  use_PeopleQuery,
  use_SubjectContainersQuery,
  use_SubjectsQuery,
  use_TeachingBlocksQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';

export type LessonsImportProps = {
  versionUuid: string;
  teachingBlockSubjectContainerUuid: string;
  onClose: () => void;
  onSubmit: (lessonUuids: string[]) => Promise<void>;
  isOpen: boolean;
};

export type ImportLessonsType = {
  uuid: string;
  subject: string;
  classes: string;
  teachers: string;
  elective: boolean;
  teachingLoadEnabled: boolean;
  timetableEnabled: boolean;
};

export const ImportLessonsToTeachingBlockVersionModal = ({
  versionUuid,
  onClose,
  onSubmit,
  teachingBlockSubjectContainerUuid,
  isOpen,
}: LessonsImportProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState({});
  const [sorting, onSorting] = useState([
    { id: 'classes', desc: false },
    { id: 'subject', desc: false },
  ]);

  const [loading, setLoading] = useState(false);

  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const [{ data: teachingBlockResult }] = use_TeachingBlocksQuery({
    variables: {
      where: {
        versions_SOME: {
          uuid: versionUuid,
        },
      },
    },
  });

  const [{ data: subjectContainerResult }] = use_SubjectContainersQuery({
    variables: {
      where: {
        uuid: teachingBlockResult?.teachingBlocks[0].subjectContainer.uuid,
      },
    },
  });

  const grades = subjectContainerResult?.subjectContainers.flatMap((sc) => sc.grades);

  const [{ data: lessonsData }] = use_LessonsQuery({
    variables: {
      where: {
        teachingBlockVersions_NONE: { uuid: versionUuid },
        onlyInTimetableVersion: false,
        lessonUnit_SOME: {
          subjectContainer: { uuid: teachingBlockSubjectContainerUuid },
        },
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
      },
    },
    pause: !teachingBlockSubjectContainerUuid,
  });

  const [{ data: teacherData }] = use_PeopleQuery({
    pause: !lessonsData,
  });

  const [{ data: subjectData }] = use_SubjectsQuery({
    pause: !lessonsData,
  });

  const [{ data: lessonClassesData }] = use_LessonClassesQuery({
    pause: !lessonsData,
  });

  const [{ data: classesData }] = use_ClassesQuery({
    pause: !lessonsData,
  });

  const memorizedLessonsData: ImportLessonsType[] = useMemo(() => {
    return (
      (lessonsData?.lessons
        .map((lesson): ImportLessonsType | null => {
          const subject = subjectData?.subjects.find((s) => s.uuid === lesson.subject.uuid);

          const lessonClasses = lessonClassesData?.lessonClasses.filter((c) =>
            lesson.lessonClassesConnection.edges.some(({ node }) => node.uuid === c.uuid),
          );

          const classes = lessonClasses?.map((lc) => {
            return classesData?.classes.find((c) => c.uuid === lc.class.uuid);
          });

          const teachers = teacherData?.people.filter((p) =>
            lesson.teachersConnection.edges.find((t) => t.node.uuid === p.uuid),
          );
          const classesGrades = classes?.map((c) => c?.grade) ?? [];

          if (classesGrades && grades && classesGrades.some((c) => c && grades.includes(c))) {
            return {
              subject: subject?.name ?? '',
              uuid: lesson.uuid,
              classes: classes?.map((c) => c?.name ?? '').join(', ') ?? '',
              teachers:
                teachers
                  ?.map((teacher) => {
                    return teacher.displayNameShort;
                  })
                  .join(', ') ?? '',
              elective: lesson.elective ?? false,
              teachingLoadEnabled: lesson.teachingLoadEnabled ?? false,
              timetableEnabled: lesson.timetableEnabled ?? false,
            };
          }
          return null;
        })
        .filter((l) => l) as ImportLessonsType[]) ?? []
    );
  }, [
    classesData?.classes,
    grades,
    lessonClassesData?.lessonClasses,
    lessonsData?.lessons,
    subjectData?.subjects,
    teacherData?.people,
  ]);

  function selectedToData<T>(obj: RowSelectionState, arr: T[]) {
    const result = [];
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const index = parseInt(key);
        if (index >= 0 && index < arr.length) {
          result.push(arr[index]);
        }
      }
    }
    return result;
  }

  const handleSubmit = async () => {
    setLoading(true);
    const selectedLessons = selectedToData<ImportLessonsType>(selected, memorizedLessonsData);
    const uuids = selectedLessons.map((r) => r.uuid);
    await onSubmit(uuids);

    setLoading(false);
    setSelected({});
    onClose();
  };

  const tableColumns = useMemo((): TableColumns<ImportLessonsType>[] => {
    return [
      {
        header: t('lesson.table.subject'),
        id: 'subject',
        accessorKey: 'subject',
        meta: {
          filterName: t('lesson.table.subject'),
        },
        size: 200,
        canExpand: true,
      },
      {
        header: t('lesson.table.classes'),
        id: 'classes',
        accessorKey: 'classes',
        size: 50,
        canExpand: true,
      },
      {
        header: t('lesson.table.teacher'),
        id: 'teachers',
        accessorKey: 'teachers',
        size: 175,
        canExpand: true,
      },
      {
        header: t('lesson.basics.isElective'),
        id: 'elective',
        accessorKey: 'elective',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTeachingLoadEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTeachingLoadEnabled.full'),
          tooltip: t('lesson.basics.isTeachingLoadEnabled.full'),
        },
        id: 'teachingLoadEnabled',
        accessorKey: 'teachingLoadEnabled',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTimetableEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTimetableEnabled.full'),
          tooltip: t('lesson.basics.isTimetableEnabled.full'),
        },
        id: 'timetableEnabled',
        accessorKey: 'timetableEnabled',
        type: 'boolean',
        size: 60,
      },
    ];
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      className={'lesson-import-modal'}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      hideFooter={false}
      height={'l'}
      footer={
        <>
          <ModalBottomButtons
            isLoading={loading}
            closeButton={{ callback: () => onClose() }}
            submitButton={{ callback: () => handleSubmit(), text: t('lesson.import') }}
            errors={undefined}
          />
        </>
      }
    >
      <Table<ImportLessonsType>
        canScroll={false}
        breakpoint={null}
        isOnWhite={false}
        rowSelection={selected}
        onRowSelectionChange={setSelected}
        showActionBar
        actionBarSettings={{
          showExpertFilter: true,
          showAddButton: false,
          showPrintButton: false,
          showBulkEdit: false,
        }}
        sorting={sorting}
        onSortingChange={onSorting}
        columns={tableColumns}
        data={memorizedLessonsData ?? []}
        showSelect
      />
    </Modal>
  );
};
