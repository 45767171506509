import { FC, useMemo, useState } from 'react';
import { useClassDivisionsListQuery, useDeleteClassDivisionsMutation } from '../../types/planung-graphql-client-defs';
import { ClassDivisionsClassType, ClassDivisionsListType } from './graphql/types';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, DeleteIcon, EditIcon, Modal, Row, Table, TableColumns, Tooltip } from '@bp/ui-components';
import { DivisionsForm } from '../Divisions/Forms/DivisionsForm';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import styles from './ClassDivisionsTable.module.scss';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useConfirm } from '../../hooks/useConfirm';
import { useIsUsedInLesson } from '../../hooks/useIsUsedInLesson';
import { partition } from '../../utils/arrayFunc';
import { UsedInLessonsTooltipContent } from '../TooltipContents/LessonUsedInTooltipContent/UsedInLessonsTooltipContent';
import { showSuccessDeleteToast, showUserErrorToast } from '../../utils/toast';

type ClassDivisionsListProps = {
  classUuid?: string | null;
};

export const ClassDivisionsTable: FC<ClassDivisionsListProps> = ({ classUuid }) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [divisionUuid, setDivisionUuid] = useState<null | string>(null);

  const [, deleteDivisions] = useDeleteClassDivisionsMutation();
  const context = useMemorizedCacheTag('CLASS_DIVISIONS');

  const { confirm, ConfirmationDialog } = useConfirm();
  const check = useIsUsedInLesson();

  async function handleDelete(rows: Row<ClassDivisionsListType>[]) {
    const [used, notUsed] = partition(rows, (row) => check(row.original.uuid, 'classDivision').isUsed);
    const uuids = notUsed.map((r) => r.original.uuid);
    await confirm({
      message: (
        <div>
          <div>{t('classDivision.deleteConfirm', { count: uuids.length })}</div>
          <ul>
            {notUsed.map((s) => {
              return <li key={s.original.uuid}>{s.original.name}</li>;
            })}
          </ul>
          {used && (
            <>
              <div>{t('classDivision.canNotDelete', { count: used.length })}</div>
              <ul>
                {used.map((s) => {
                  return <li key={s.original.uuid}>{s.original.name}</li>;
                })}
              </ul>
            </>
          )}
        </div>
      ),
      onConfirm: async () => {
        if (uuids) {
          const response = await deleteDivisions({ where: { uuid_IN: uuids } }, context);
          if (response.error) {
            showUserErrorToast({ error: response.error });
          } else {
            showSuccessDeleteToast(notUsed.map((cd) => cd.original.name));
          }
        }
      },
    });
  }

  const handleEdit = (row: Row<ClassDivisionsListType>) => {
    setDivisionUuid(row.original.uuid ?? null);
    setIsModalOpen(true);
  };

  const [{ data }] = useClassDivisionsListQuery({
    variables: {
      classUuid: classUuid ?? '',
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  const memoizedData = useMemo((): ClassDivisionsListType[] => {
    return data && data ? data.divisions : [];
  }, [data]);

  const memoizedClass = useMemo((): Partial<ClassDivisionsClassType> => {
    return data && data ? data.classes[0] : {};
  }, [data]);

  const columns: TableColumns<ClassDivisionsListType>[] = useMemo(() => {
    return [
      {
        header: t('common.group_other') as string,
        id: 'groupsConnections',
        accessorKey: 'groupsConnection.edges',
        enableGlobalFilter: false,
        enableColumnFilter: false,
        size: 400,
        cell: ({ row }) => {
          const value = row.original.groupsConnection.edges;
          return (
            <div className={styles['bars-wrapper']}>
              {value.map((group) => {
                return (
                  <div className={styles['bars-item']} key={group.node.uuid}>
                    {group.node.shortName}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        header: t('divisions.type') as string,
        accessorKey: 'name',
        id: 'name',
        cell: ({ row }) => {
          return row.original.name === '0' ? '-' : row.original.name;
        },
      },
    ];
  }, []);

  return (
    <>
      <Table<ClassDivisionsListType>
        showShadow={false}
        customPadding='var(--spacing-6)'
        canScroll
        breakpoint={null}
        minHeight={900}
        showActionBar={!!classUuid}
        onAddClick={() => {
          setDivisionUuid(null);
          setIsModalOpen(true);
        }}
        actionBarSettings={
          classUuid
            ? {
                showAddButton: true,
                addButtonText: t('divisions.add'),
                showGlobalFilter: false,
                extendedActionsLeft: (
                  <span className={styles.title}>{`${t('divisions.title.plural')} ${memoizedClass.name}`}</span>
                ),
              }
            : { showAddButton: false, showGlobalFilter: false }
        }
        columns={columns}
        data={memoizedData}
        lastColWidth='80px'
        lastCol={(divisionRow) => {
          const used = check(divisionRow.original.uuid, 'classDivision');
          const deleteButton = (
            <Button
              disabled={used.isUsed}
              onClick={() => handleDelete([divisionRow])}
              hierarchy='secondary'
              type='button'
              icon={<DeleteIcon className='small' />}
            />
          );
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                type='button'
                icon={<EditIcon className='small' />}
                onClick={() => {
                  handleEdit(divisionRow);
                }}
              />
              {used.isUsed ? (
                <Tooltip
                  content={
                    <UsedInLessonsTooltipContent lessons={used.lessons} usedInText={t('divisions.usedInLesson')} />
                  }
                >
                  {deleteButton}
                </Tooltip>
              ) : (
                deleteButton
              )}
            </ButtonGroup>
          );
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          setDivisionUuid(null);
        }}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={divisionUuid ? (t('divisions.edit') as string) : (t('divisions.add') as string)}
      >
        {classUuid && (
          <DivisionsForm
            cacheTag={'CLASS_DIVISIONS'}
            closeForm={() => {
              setIsModalOpen(false);
              setDivisionUuid(null);
            }}
            divisionUuid={divisionUuid}
            classUuid={classUuid}
            className={memoizedClass.name}
            showDefaultDivisionsSelect={true}
          />
        )}
      </Modal>
      <ConfirmationDialog />
    </>
  );
};
