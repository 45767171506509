import { useTranslation } from 'react-i18next';
import { SubjectContainerTable } from '../../../../components/SubjectContainer/Tables/SubjectContainerTable';
import { Tab } from '@bp/ui-components';
import { TimetableSubjectsTable } from '../../../../components/Subjects/Tables/TimetableSubjectsTable';
import { SubjectGroupTable } from '../../../../components/SubjectGroup/Tables/SubjectGroupTable';
import { Page } from '../../../../components/Page/Page';

export const SetupSubjectsAndSubjectContainer = () => {
  const { t } = useTranslation();

  return (
    <Page title={null}>
      <Tab
        variant={'large'}
        tabs={[
          {
            title: t('subject.title.plural'),
            value: 'subjects',
            content: <TimetableSubjectsTable key={'subjects'} />,
          },
          {
            title: t('subjectContainer.title'),
            value: 'subjectsContainer',
            content: <SubjectContainerTable key={'subjectsContainer'} />,
          },
          {
            title: t('subjectGroup.title'),
            value: 'subjectGroup',
            content: <SubjectGroupTable key={'subjectGroup'} />,
          },
        ]}
      />
    </Page>
  );
};
