import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  LazyLoader,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { useHiddenColumns } from '../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import dayjs from 'dayjs';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { TimetableVersionsOverviewModal } from '../TimetableVersion/TimetableVersionsOverview/TimetableVersionsOverviewModal';
import { use_DeleteTimetablesMutation, use_TimetablesQuery } from '../../types/planung-graphql-client-defs';
import TimetableForm from './Forms/TimetableForm';
import { useConfirm } from '../../hooks/useConfirm';
import { showSuccessDeleteToast, showUserErrorToast } from '../../utils/toast';

type TimetableTableType = {
  uuid: string;
  name: string;
  versions: number;
  createdAt: string;
  updatedAt: string;
  comment: string;
  active: boolean;
};

export const TimetableTable = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('timetable-list', {});
  const { sorting, saveSorting } = useColumnsSort('timetable-list');

  const { confirm, ConfirmationDialog } = useConfirm();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [timetableUuid, setTimetableUuid] = useState<string | null>(null);
  const [showVersionModal, setShowVersionModal] = useState(false);

  const context = useMemorizedCacheTag('TIMETABLE');
  const schoolYearUuid = schoolYear?.uuid;

  const [{ data: timetablesData }] = use_TimetablesQuery({
    variables: {},
    context,
  });

  const timetables = useMemo(() => {
    return timetablesData?.timetables
      ? timetablesData.timetables
          .filter((t) => {
            return t.schoolYear?.uuid === schoolYearUuid;
          })
          .sort((a, b) => {
            return dayjs(b.updatedAt).diff(dayjs(a.updatedAt));
          })
      : [];
  }, [schoolYearUuid, timetablesData]);

  const [, deleteTimetables] = use_DeleteTimetablesMutation();

  const tableColumns: TableColumns<TimetableTableType>[] = useMemo(() => {
    return [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        size: 250,
      },
      {
        header: t('timetableDraft.versionCount'),
        id: 'versions',
        accessorKey: 'versions',
        size: 50,
      },
      {
        header: t('common.createdAt'),
        id: 'createdAt',
        accessorKey: 'createdAt',
        type: 'date',
        size: 150,
      },
      {
        header: t('common.updatedAt'),
        id: 'updatedAt',
        accessorKey: 'updatedAt',
        type: 'date',
        size: 150,
      },
      {
        header: t('common.comment'),
        id: 'comment',
        accessorKey: 'comment',
        size: 150,
      },
      {
        header: t('timetableDraft.wasActive'),
        id: 'wasActive',
        accessorKey: 'wasActive',
        type: 'boolean',
        size: 100,
      },
      {
        header: t('timetableDraft.isActive'),
        id: 'active',
        type: 'boolean',
        accessorKey: 'active',
        size: 80,
      },
    ];
  }, []);

  const memoizedData = useMemo((): TimetableTableType[] => {
    return timetables.map((t) => {
      return {
        uuid: t.uuid,
        active: !t.draft,
        comment: t.comment ?? '',
        createdAt: dayjs(t.createdAt).format('DD.MM.YYYY - HH:mm'),
        name: t.name,
        versions: t.versions.length,
        updatedAt: dayjs(t.updatedAt).format('DD.MM.YYYY - HH:mm'),
      };
    });
  }, [timetables]);

  const handleDelete = async (uuids: string[]) => {
    const timetablesToDelete = timetables.filter((t) => uuids.includes(t.uuid) && t.draft && t.versions.length === 0);

    const tt = timetablesToDelete.map((tt) => tt.name);

    await confirm({
      onConfirm: async () => {
        const resp = await deleteTimetables({ where: { uuid_IN: timetablesToDelete.map((tt) => tt.uuid) } }, context);
        if (resp.error) {
          showUserErrorToast({ error: resp.error });
        }
        if (!resp.error) showSuccessDeleteToast();
      },
      message: t('timetableDraft.deleteConfirm', {
        timetables: tt?.join(', '),
        count: tt?.length,
      }),
    });
  };

  const handleEdit = (draftRow: Row<TimetableTableType>) => {
    setTimetableUuid(draftRow.original.uuid);
    setIsModalOpen(true);
  };

  function closeModal() {
    setTimetableUuid(null);
    setIsModalOpen(false);
  }

  function handleShowVersionModal(timetable: TimetableTableType) {
    setTimetableUuid(timetable.uuid);
    setShowVersionModal(true);
  }

  function handleHideVersionModal() {
    setTimetableUuid(null);
    setShowVersionModal(false);
  }

  const createActionItems = useCallback(
    (row: Row<TimetableTableType>): DropdownMenuItem[] => {
      return [
        {
          label: t('common.delete'),
          type: 'default',
          color: 'error',
          onClick: async () => {
            await handleDelete([row.original.uuid]);
          },
          disabled: row.original.active || row.original.versions > 0,
        },
      ];
    },
    [handleDelete],
  );

  return (
    <>
      <Table<TimetableTableType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={1100}
        breakpoint={null}
        showVisibility
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        sorting={sorting}
        onSortingChange={saveSorting}
        columns={tableColumns}
        data={memoizedData}
        showActionBar
        actionBarSettings={{ showAddButton: true }}
        isOnWhite={false}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('timetableDraft.title.plural')} - ${t('common.schoolYear')} ${schoolYear?.shortName}`,
          filename: `${t('timetableDraft.title.plural')}_${schoolYear?.shortName}`,
        }}
        onAddClick={() => {
          setIsModalOpen(true);
        }}
        lastColWidth='160px'
        lastCol={(row) => {
          return (
            <ButtonGroup className='ml-4'>
              <Button hierarchy='secondary' onClick={() => handleShowVersionModal(row.original)}>
                {t('pinboard.versions')}
              </Button>
              <Button
                hierarchy={'secondary'}
                onClick={() => {
                  handleEdit(row);
                }}
                icon={<EditIcon className='small' />}
                disabled={row.original.active}
              />
              <Dropdown
                noPadding
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu data={createActionItems(row)} />
              </Dropdown>
            </ButtonGroup>
          );
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        title={timetableUuid ? t('timetableDraft.edit') : t('timetableDraft.add')}
      >
        <Suspense fallback={<LazyLoader embedded={true} />}>
          <TimetableForm timetableUuid={timetableUuid} selectAllClasses onClose={closeModal} />
        </Suspense>
      </Modal>
      <TimetableVersionsOverviewModal
        timetableUuid={timetableUuid}
        draftName={memoizedData.find((tt) => tt.uuid === timetableUuid)?.name ?? ''}
        isOpen={showVersionModal && !!timetableUuid}
        onClose={() => handleHideVersionModal()}
      />

      <ConfirmationDialog />
    </>
  );
});
