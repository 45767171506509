import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  use_TeachingBlocksQuery,
  useDeleteSubjectContainerMutation,
  useSubjectContainersListQuery,
} from '../../../types/planung-graphql-client-defs';
import {
  Button,
  ButtonGroup,
  DeleteIcon,
  EditIcon,
  LazyLoader,
  Modal,
  Row,
  showToast,
  Table,
  TableColumns,
  Tooltip,
} from '@bp/ui-components';
import { SubjectContainerTableDataType } from '../graphql/types';
import { SubjectContainerForm } from '../Forms/SubjectContainerForm';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import dayjs from 'dayjs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useConfirm } from '../../../hooks/useConfirm';
import { useIsUsedInLesson } from '../../../hooks/useIsUsedInLesson';
import { partition } from '../../../utils/arrayFunc';
import { hexToColorOption } from '../../../utils/colorUtils';
import { UsedInLessonsTooltipContent } from '../../TooltipContents/LessonUsedInTooltipContent/UsedInLessonsTooltipContent';

export const SubjectContainerTable = observer(() => {
  const { pimAuthClaims } = useAuthClaims();

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [subjectContainerUuid, setSubjectContainerUuid] = useState<null | string>(null);
  const [, deleteSubjectContainer] = useDeleteSubjectContainerMutation();

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('subject-container-list', {
    validUntilName: false,
    validFromName: false,
  });
  const { sorting, saveSorting } = useColumnsSort('subject-container-list');

  const { confirm, ConfirmationDialog } = useConfirm();
  const check = useIsUsedInLesson();

  const [{ data: teachingBlockData }] = use_TeachingBlocksQuery();

  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const context = useMemorizedCacheTag('SUBJECT_CONTAINER');

  const [{ data }] = useSubjectContainersListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  function createColumns(): TableColumns<SubjectContainerTableDataType>[] {
    return [
      {
        header: t('subjectContainer.order'),
        accessorKey: 'order',
        id: 'order',
        size: 70,
      },
      {
        header: t('common.name'),
        accessorKey: 'name',
        id: 'name',
        canExpand: true,
      },
      {
        header: t('subjectContainer.shortName'),
        accessorKey: 'shortName',
        id: 'shortName',
        size: 150,
      },
      {
        header: t('common.grades'),
        id: 'grades',
        accessorKey: 'grades',
        size: 150,
        cell: ({ row }) => {
          return row.original.grades.sort().join(', ');
        },
      },
      {
        header: t('gradeGroups.title.plural'),
        id: 'gradeGroups',
        accessorKey: 'gradeGroups',
        size: 150,
        cell: ({ row }) => {
          return row.original.gradeGroups.sort().join(', ');
        },
      },

      {
        header: t('subjectContainer.validFrom'),
        accessorKey: 'validFromName',
        id: 'validFromName',
      },
      {
        header: t('subjectContainer.validUntil'),
        accessorKey: 'validUntilName',
        id: 'validUntilName',
      },

      {
        header: t('common.color'),
        accessorKey: 'color',
        id: 'color.color',
        type: 'color',
        size: 100,
      },
      {
        header: t('common.active.short'),
        accessorKey: 'active',
        id: 'active',
        type: 'active',
        meta: {
          filterName: t('common.active.full'),
          tooltip: t('common.active.full'),
        },
      },
    ];
  }

  const tableColumns = useMemo(createColumns, []);

  const memoizedData = useMemo((): SubjectContainerTableDataType[] => {
    return data && data
      ? data.subjectContainers.map((subjectContainer) => {
          const { html, label } = hexToColorOption(subjectContainer.timetableConfig?.color ?? '');
          return {
            uuid: subjectContainer.uuid,
            color: {
              color: html,
              colorLabel: label,
            },
            name: subjectContainer.name,
            shortName: subjectContainer.shortName,
            order: subjectContainer.order ?? 0,
            active:
              dayjs(subjectContainer.validFrom?.start) <= dayjs() && dayjs(subjectContainer.validUntil?.end) >= dayjs(),
            validFromName: subjectContainer.validFrom?.name ?? '',
            validUntilName: subjectContainer.validUntil?.name ?? '',
            grades: subjectContainer.grades,
            gradeGroups: subjectContainer.gradeGroup.map((gg) => gg.shortName),
          };
        })
      : [];
  }, [data]);

  const handleDelete = async (rows: Row<SubjectContainerTableDataType>[]) => {
    const [usedInLesson] = partition(rows, (row) => check(row.original.uuid, 'subjectContainer').isUsed);

    const [usedInTeachingBlock] = partition(
      rows,
      (row) =>
        teachingBlockData?.teachingBlocks?.findIndex((tb) => tb.subjectContainer.uuid === row.original.uuid) !== -1,
    );

    const used = [...usedInLesson, ...usedInTeachingBlock];

    const notUsed = rows.filter((r) => !used.some((u) => u.original.uuid === r.original.uuid));
    const uuids = notUsed.map((r) => r.original.uuid);
    await confirm({
      message: (
        <div>
          <div>{t('subjectContainer.deleteConfirm', { count: uuids.length })}</div>
          <ul>
            {notUsed.map((s) => {
              return <li key={s.original.uuid}>{s.original.name}</li>;
            })}
          </ul>
          {used && (
            <>
              <div>{t('subjectContainer.canNotDelete', { count: used.length })}</div>
              <ul>
                {used.map((s) => {
                  return <li key={s.original.uuid}>{s.original.name}</li>;
                })}
              </ul>
            </>
          )}
        </div>
      ),
      onConfirm: async () => {
        if (uuids) {
          const response = await deleteSubjectContainer({ uuid: uuids[0] }, context);
          if (response.error) {
            showToast(t('subjectContainer.delete.error'), { type: 'error' });
          } else {
            showToast(t('subjectContainer.delete.success'), { type: 'success' });
          }
        }
      },
    });
  };

  const handleEdit = (subjectContainerRow: Row<SubjectContainerTableDataType>) => {
    setSubjectContainerUuid(subjectContainerRow.original.uuid);
    setIsModalOpen(true);
  };
  const colorsInUse = data?.subjectContainers?.map((sc) => sc.timetableConfig ?? null) ?? [];
  return (
    <>
      <Table<SubjectContainerTableDataType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={600}
        breakpoint={null}
        onSortingChange={saveSorting}
        sorting={sorting}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        showVisibility
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('subjectContainer.title')} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('subjectContainer.title')}_${currentSchoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={{
          showExpertFilter: true,
          showAddButton: true,
          showPrintButton: true,
          showBulkEdit: true,
        }}
        isOnWhite={false}
        data={memoizedData}
        columns={tableColumns}
        onAddClick={() => setIsModalOpen(true)}
        lastColWidth='80px'
        lastCol={(row) => {
          const used = check(row.original.uuid, 'subjectContainer');
          const usedInTeachingBlock =
            teachingBlockData?.teachingBlocks?.findIndex((tb) => tb.subjectContainer.uuid === row.original.uuid) !== -1;
          const deleteButton = (
            <Button
              disabled={used.isUsed || usedInTeachingBlock}
              hierarchy='secondary'
              type='button'
              icon={<DeleteIcon className='small' />}
              onClick={() => handleDelete([row])}
            />
          );
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                type='button'
                onClick={() => handleEdit(row)}
                icon={<EditIcon className='small' />}
              />
              {used.isUsed || usedInTeachingBlock ? (
                <Tooltip
                  content={
                    used.isUsed ? (
                      <UsedInLessonsTooltipContent lessons={used.lessons} usedInText={t('classes.usedInLesson')} />
                    ) : usedInTeachingBlock ? (
                      t('subjectContainer.usedInTeachingBlock')
                    ) : (
                      ''
                    )
                  }
                >
                  {deleteButton}
                </Tooltip>
              ) : (
                deleteButton
              )}
            </ButtonGroup>
          );
        }}
      />
      <Modal
        title={t('subjectContainer.add')}
        isOpen={isModalOpen}
        onRequestClose={() => {
          setSubjectContainerUuid(null);
          setIsModalOpen(false);
        }}
      >
        <Suspense fallback={<LazyLoader />}>
          <SubjectContainerForm
            htmlColorsInUse={colorsInUse}
            subjectContainerUuid={subjectContainerUuid}
            closeForm={() => {
              setSubjectContainerUuid(null);
              setIsModalOpen(false);
            }}
          ></SubjectContainerForm>
        </Suspense>
      </Modal>
      <ConfirmationDialog />
    </>
  );
});
