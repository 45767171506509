import styles from './CurriculumStats.module.scss';
import { Card } from '@bp/ui-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../utils/helper';

type Props = {
  studentsHours: number;
  teachersHours: number;
  teachingBlockWeeks: number;
  deputateSubjectContainer: number;
  deputateLessonUnit: number;
};

export const SubjectCurriculumStats = ({
  studentsHours = 0,
  teachersHours = 0,
  teachingBlockWeeks = 0,
  deputateSubjectContainer = 0,
  deputateLessonUnit = 0,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Card wrapperClass={styles['curriculum-stats']} className={styles.content} fitContent contentPadding='none'>
      <div className={classNames(styles.stats, styles['small-padding'])}>
        <div className={styles.stat}>
          <div>{t('common.studentHours')}</div>
          <div>{formatNumber(studentsHours)}</div>
        </div>
        <div className={styles.stat}>
          <div>{t('common.teacherHours')}</div>
          <div>{formatNumber(teachersHours)}</div>
        </div>
        <div className={styles.stat}>
          <div>{t('deputate.deputateSubject')}</div>
          <div>{formatNumber(deputateLessonUnit)}</div>
        </div>
        <div className={styles.stat}>
          <div>{t('deputate.deputateEpoch')}</div>
          <div>{formatNumber(deputateSubjectContainer)}</div>
        </div>
        <div className={styles.stat}>
          <div>{t('deputate.deputateTotal')}</div>
          <div>{formatNumber(deputateSubjectContainer + deputateLessonUnit)}</div>
        </div>
      </div>
    </Card>
  );
};
