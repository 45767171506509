import { useEffect, useState } from 'react';
import { ConstraintsFormInitialValuesType, ConstraintsItemType } from '../components/Constraints/types';
import { useTranslation } from 'react-i18next';
import { useTimeGridEntryQuery } from '../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from './useMemorizedCacheTag';

export const useConstraints = (constraintsData: ConstraintsFormInitialValuesType) => {
  const [constraints, setConstraints] = useState<ConstraintsItemType[]>([]);
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('TIME_GRID');

  const [result] = useTimeGridEntryQuery({
    variables: {
      timeGridEntriesUuids: [
        constraintsData.startEnd?.latestStart ?? '',
        constraintsData.startEnd?.earliestEnd ?? '',
        constraintsData.lunchBreak?.from ?? '',
        constraintsData.lunchBreak?.until ?? '',
      ],
    },
    context,
  });

  const latestStartName =
    result.data?.timeGridEntries.find((entry) => {
      return entry.uuid === constraintsData.startEnd?.latestStart;
    })?.shortName ?? '';

  const earliestEndName =
    result.data?.timeGridEntries.find((entry) => {
      return entry.uuid === constraintsData.startEnd?.earliestEnd;
    })?.shortName ?? '';

  const lunchBreakFromName =
    result.data?.timeGridEntries.find((entry) => {
      return entry.uuid === constraintsData.lunchBreak?.from;
    })?.shortName ?? '';

  const lunchBreakUntilName =
    result.data?.timeGridEntries.find((entry) => {
      return entry.uuid === constraintsData.lunchBreak?.until;
    })?.shortName ?? '';

  useEffect(() => {
    const updatedConstraints: ConstraintsItemType[] = [];
    if (constraintsData.startEnd !== undefined) {
      const { latestStart, earliestEnd } = constraintsData.startEnd;
      updatedConstraints.push({
        type: 'startEnd',
        priority: constraintsData.startEndPriority ?? null,
        text: t('constraints.startEnd.listText', {
          context: latestStart && earliestEnd ? 'earliestLatest' : latestStart ? 'latestStart' : 'earliestEnd',
          latestStart: latestStartName,
          earliestEnd: earliestEndName,
        }),
      });
    }

    if (constraintsData.minMaxHours !== undefined) {
      const { min, max } = constraintsData.minMaxHours;
      updatedConstraints.push({
        type: 'minMaxHours',
        priority: constraintsData.minMaxHoursPriority ?? null,
        text: t('constraints.minMaxHours.listText', {
          count: min !== undefined && max !== undefined ? 0 : min !== undefined ? min : max,
          context: min !== undefined && max !== undefined ? 'minMax' : min !== undefined ? 'min' : 'max',
          min: min,
          max: max,
        }),
      });
    }

    if (constraintsData.maxFreePeriod !== undefined) {
      const maxFreePeriod = constraintsData.maxFreePeriod;
      updatedConstraints.push({
        type: 'maxFreePeriod',
        priority: constraintsData.maxFreePeriodPriority ?? null,
        text: t('constraints.maxFreePeriod.listText', { maxFreePeriod }),
      });
    }

    if (constraintsData.sameEnd !== undefined) {
      updatedConstraints.push({
        type: 'sameEnd',
        priority: constraintsData.sameEndPriority ?? null,
        text: t('constraints.sameEnd.listText'),
      });
    }

    if (constraintsData.lunchBreak !== undefined) {
      const { from, until } = constraintsData.lunchBreak;
      updatedConstraints.push({
        type: 'lunchBreak',
        priority: constraintsData.lunchBreakPriority ?? null,
        text: t('constraints.lunchBreak.listText', {
          context: from && until ? 'fromUntil' : from ? 'from' : 'until',
          from: lunchBreakFromName,
          until: lunchBreakUntilName,
        }),
      });
    }

    if (constraintsData.maxOnUnknownPosition !== undefined) {
      const maxOnUnknownPosition = constraintsData.maxOnUnknownPosition;
      updatedConstraints.push({
        type: 'maxOnUnknownPosition',
        priority: constraintsData.maxOnUnknownPositionPriority ?? null,
        text: t('constraints.maxOnUnknownPosition.listText', { maxOnUnknownPosition }),
      });
    }

    setConstraints(updatedConstraints);
  }, [t, constraintsData, earliestEndName, latestStartName, lunchBreakFromName, lunchBreakUntilName]);

  return constraints;
};
