import { FactorsFormTypes } from '../../types';
import { TeachingLoadFactor } from '../../graphql/types';
import { CurrentSchoolYear } from '../../../../stores/UserConfigStore';
import { UseMutationExecute } from 'urql';
import {
  CreateTeachingLoadFactorMutation,
  Exact,
  InputMaybe,
  TeachingLoadFactorCreateInput,
  TeachingLoadFactorUpdateInput,
  TeachingLoadFactorWhere,
  UpdateTeachingLoadFactorMutation,
  ValueMode,
} from '../../../../types/planung-graphql-client-defs';

export const submitFactor = async (
  values: FactorsFormTypes,
  teachingLoadFactor: TeachingLoadFactor | null,
  currentSchoolYear: CurrentSchoolYear | null,
  context: { additionalTypenames: string[] },
  updateTeachingLoadFactor: UseMutationExecute<
    UpdateTeachingLoadFactorMutation,
    Exact<{
      where?: InputMaybe<TeachingLoadFactorWhere> | undefined;
      update?: InputMaybe<TeachingLoadFactorUpdateInput> | undefined;
    }>
  >,
  createTeachingLoadFactor: UseMutationExecute<
    CreateTeachingLoadFactorMutation,
    Exact<{ input: TeachingLoadFactorCreateInput | TeachingLoadFactorCreateInput[] }>
  >,
) => {
  const where = (uuid?: string) => ({ where: { node: { uuid } } });
  const valuePartialRaw = values.valuePartialRaw;
  const valuePartial = values.valuePartial;
  const timeGridEntries = values.timeGridEntries;
  const teachers = values.teachers;

  // create
  if (!teachingLoadFactor?.uuid) {
    const createInput: TeachingLoadFactorCreateInput = {
      comment: values.comment,
      factorType: values.factorType,
      gradeGroup: { connect: values.gradeGroups?.map((item) => where(item)) },
      grades: values.grades,
      schoolYear: { connect: where(currentSchoolYear?.uuid) },
      subject: { connect: values.subjects?.map((item) => where(item)) },
      subjectContainer: { connect: values.subjectContainers?.map((item) => where(item)) },
      value: parseFloat(values.value),
      valueRaw: values.valueRaw,
      valueMode: values.valueMode ?? ValueMode.UnitCount,
      active: values.active,
    };

    // add optional values
    if (valuePartialRaw && valuePartial) {
      createInput['valuePartialRaw'] = valuePartialRaw;
      createInput['valuePartial'] = parseFloat(valuePartial);
    }
    if (teachers) createInput['teachers'] = { connect: teachers.map((item) => where(item)) };
    if (timeGridEntries) createInput['timeGridEntries'] = { connect: timeGridEntries.map((item) => where(item)) };

    return createTeachingLoadFactor({ input: createInput }, context);
  } else {
    // update
    const disconnect = [{}];
    const updateInput: TeachingLoadFactorUpdateInput = {
      comment: values.comment,
      factorType: values.factorType,
      gradeGroup: [
        {
          disconnect,
          connect: values.gradeGroups?.map((uuid) => where(uuid)),
        },
      ],
      grades: values.grades,
      schoolYear: { disconnect: {}, connect: where(currentSchoolYear?.uuid) },
      subject: [
        {
          disconnect,
          connect: values.subjects?.map((uuid) => where(uuid)),
        },
      ],
      subjectContainer: [
        {
          disconnect,
          connect: values.subjectContainers?.map((uuid) => where(uuid)),
        },
      ],
      valueRaw: values.valueRaw,
      value: parseFloat(values.value),
      valueMode: values.valueMode,
      active: values.active,
    };

    // add optional values
    if (valuePartialRaw && valuePartial) {
      updateInput['valuePartialRaw'] = valuePartialRaw;
      updateInput['valuePartial'] = parseFloat(valuePartial);
    }

    if (timeGridEntries)
      updateInput['timeGridEntries'] = [{ disconnect, connect: timeGridEntries.map((item) => where(item)) }];

    if (teachers) updateInput['teachers'] = [{ disconnect, connect: teachers?.map((item) => where(item)) }];

    return await updateTeachingLoadFactor(
      {
        update: updateInput,
        where: {
          uuid: teachingLoadFactor?.uuid,
        },
      },
      context,
    );
  }
};
