import { useTranslation } from 'react-i18next';
import React, { FC, Suspense, useMemo, useState } from 'react';
import {
  use_TimetableCardsQuery,
  useDeleteTimeGridEntryMutation,
  useTimeGridEntriesByTimeGridQuery,
} from '../../types/planung-graphql-client-defs';
import { TimeGridEntryType } from './graphql/types';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import {
  AddIcon,
  Button,
  ButtonGroup,
  Card,
  Chip,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  LazyLoader,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import styles from './TimeGrid.module.scss';
import dayjs from 'dayjs';
import classNames from 'classnames';
import TimeGridEntryForm from './Forms/TimeGridEntryForm';
import { useConfirm } from '../../hooks/useConfirm';
import { useUpdateTimegridOrder } from '../../hooks/useUpdateTimegridOrder';

type TimeGridEntryListProps = {
  gridUuid: string;
};

export const TimeGridEntriesTable: FC<TimeGridEntryListProps> = observer(({ gridUuid }) => {
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('TIME_GRID');
  const { updateOrders } = useUpdateTimegridOrder(gridUuid);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [entry, setEntry] = useState<TimeGridEntryType | null>(null);
  const [, deleteTimeGridEntry] = useDeleteTimeGridEntryMutation();

  const [{ data }] = useTimeGridEntriesByTimeGridQuery({
    variables: {
      timeGridUuid: gridUuid,
    },
    context: context,
  });

  const [{ data: placedCardsData }] = use_TimetableCardsQuery({
    variables: {
      where: {
        timetableVersion: { timetable: { timegridConfigs_SOME: { timegrid: { uuid: gridUuid } } } },
        NOT: { weekday: null },
      },
    },
  });

  const createColumns = (): TableColumns<TimeGridEntryType>[] => {
    return [
      {
        header: t('common.order'),
        id: 'order',
        accessorKey: 'order',
        enableGlobalFilter: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return <Chip value={row.original.order} />;
        },
        size: 30,
      },
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        enableGlobalFilter: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          const classes = classNames(styles['indicator'], {
            [styles['is-break']]: row.original.pause,
          });
          return (
            <div className={styles['name-wrapper']}>
              <div className={classes}></div>
              <div>{row.original.name}</div>
            </div>
          );
        },
      },
      {
        header: t('timeGrid.item.start'),
        accessorKey: 'start',
        id: 'start',
        enableGlobalFilter: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          const hour = dayjs(row.original.start).hour().toString().padStart(2, '0');
          const minute = dayjs(row.original.start).minute().toString().padStart(2, '0');
          return `${hour}:${minute}`;
        },
      },
      {
        header: t('timeGrid.item.end'),
        accessorKey: 'end',
        id: 'end',
        enableGlobalFilter: false,
        enableColumnFilter: false,
        cell: ({ row }) => {
          const hour = dayjs(row.original.end).hour().toString().padStart(2, '0');
          const minute = dayjs(row.original.end).minute().toString().padStart(2, '0');
          return `${hour}:${minute}`;
        },
      },
      {
        header: t('timeGrid.item.isReliable'),
        accessorKey: 'reliable',
        id: 'reliable',
        type: 'boolean',
        size: 75,
      },
      {
        header: t('timeGrid.item.isPause') as string,
        accessorKey: 'pause',
        id: 'pause',
        type: 'boolean',
        size: 75,
      },
    ];
  };

  const tableColumns = useMemo(createColumns, []);

  const memoizedData = useMemo((): TimeGridEntryType[] => {
    return data ? data.timeGridEntries : [];
  }, [data]);

  const onEdit = (entry: TimeGridEntryType) => {
    setEntry(entry ?? null);
    setModalOpen(true);
  };

  const onAddClick = () => {
    setEntry(null);
    setModalOpen(true);
  };

  const closeModal = () => {
    setEntry(null);
    setModalOpen(false);
  };

  const onDelete = async (uuid: string) => {
    await deleteTimeGridEntry({ uuid: uuid }, context);
    await updateOrders();
    setEntry(null);
    setModalOpen(false);
  };

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('common.delete'),
  });

  const dropDownMenu = (row: Row<TimeGridEntryType>): DropdownMenuItem[] => {
    return [
      {
        label: t('common.edit'),
        type: 'default',
        onClick: () => onEdit(row.original),
      },
      { type: 'ruler' },
      {
        label: t('common.delete'),
        type: 'default',
        color: 'error',
        onClick: async () => {
          const res = await confirmDelete();
          if (res) {
            await onDelete(row.original.uuid);
          }
        },
      },
    ];
  };

  return (
    <>
      <Card className={styles['grid-list']} contentPadding='none'>
        <div className={styles['hour-list-header']}>
          <div className={styles['title']}>{t('timeGrid.item.title')}</div>
          <Button className={styles['button']} hierarchy='tertiary' icon={<AddIcon />} onClick={onAddClick}>
            {t('common.add')}
          </Button>
        </div>
        <Table
          showBorderRadius
          columns={tableColumns}
          data={memoizedData}
          onAddClick={() => onAddClick()}
          lastColWidth='80px'
          lastCol={(row) => {
            const placedCard = placedCardsData?.timetableCards.find((card) => {
              return (
                card.endTimeGridEntry?.uuid === row.original.uuid || card.startTimeGridEntry?.uuid === row.original.uuid
              );
            });
            const menu = dropDownMenu(row);
            if (menu.length === 0) return;

            return (
              <ButtonGroup>
                <Button
                  hierarchy='secondary'
                  icon={<EditIcon className='small' />}
                  onClick={() => {
                    onEdit(row.original);
                  }}
                />
                {placedCard ? (
                  <Button disabled={true} hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />
                ) : (
                  <Dropdown trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}>
                    <DropdownMenu data={menu} />
                  </Dropdown>
                )}
              </ButtonGroup>
            );
          }}
        />
      </Card>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={entry ? (t('timeGrid.item.edit') as string) : (t('timeGrid.item.create') as string)}
      >
        <Suspense fallback={<LazyLoader transparent />}>
          <TimeGridEntryForm entryUuid={entry?.uuid ?? ''} handleClose={closeModal} gridUuid={gridUuid} />
        </Suspense>
      </Modal>
      <ConfirmationDialog />
    </>
  );
});
