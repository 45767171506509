export type FieldKeys<T> = keyof T;

export function removeNullAndRelatedFields<T extends Record<string, unknown>>(
  obj: T,
  fields: FieldKeys<T>[],
  relatedSuffix: string,
): T {
  const newObj = { ...obj };

  for (const field of fields) {
    if (newObj[field] === null || newObj[field] === undefined) {
      delete newObj[field];
      const priorityField = `${String(field)}${relatedSuffix}` as FieldKeys<T>;
      delete newObj[priorityField];
    }
  }

  return newObj;
}
