import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { groupByArray } from '../../../utils/arrayFunc';
import { TeachingBlockVersionsType, TimetableVersionsType, UsedInVersionsTooltipProps } from '../types';

export const UsedInVersionsTooltipContent: FC<UsedInVersionsTooltipProps> = ({ data, usedInText }) => {
  const { t } = useTranslation();

  // assuming we have a Timetable
  let versions: TimetableVersionsType | undefined = data?.versions;

  // but we might have a TeachingBlock
  if (data?.teachingBlockVersions) {
    versions = data.teachingBlockVersions as unknown as TimetableVersionsType;
    versions.forEach((v) => {
      const tb = (v as unknown as TeachingBlockVersionsType).teachingBlock;
      v.timetable = {
        uuid: tb.uuid,
        name: tb.name,
      };
    });
  }

  const groups = groupByArray(versions as TimetableVersionsType, (v) => v.timetable.uuid);

  return (
    <div>
      <div className={'mb-2'}>{usedInText}</div>
      {groups.map((timetable) => {
        return (
          <div key={timetable[0].timetable.uuid}>
            {timetable[0].timetable.name}{' '}
            <ul>
              {timetable.slice(0, 5).map((v, index) => {
                return (
                  <li key={v.uuid}>
                    {t('timetableVersion.title.singular')} {v.version}
                  </li>
                );
              })}
              {versions && versions.length > 5 && <li style={{ listStyle: 'none' }}>...</li>}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
