import { LoaderFunctionArgs } from 'react-router-dom';
import { urqlClient } from '../urqlClient';
import {
  _SubjectContainersDocument,
  _SubjectContainersQuery,
  _SubjectContainersQueryVariables,
  _TeachingBlocksDocument,
  _TeachingBlocksQuery,
  _TeachingBlocksQueryVariables,
  _TeachingBlockVersionsDocument,
  _TeachingBlockVersionsQuery,
  _TeachingBlockVersionsQueryVariables,
} from '../../types/planung-graphql-client-defs';
import { addLeadingZero } from '../helper';

export async function teachingBlockVersionLoader(
  args: LoaderFunctionArgs<{ teachingBlockVersionUuid: string }>,
): Promise<{ subjectContainerName: string; versionName: string; versionUuid: string }> {
  const uuid = args.params.teachingBlockVersionUuid;

  const { data } = await urqlClient
    .query<_TeachingBlockVersionsQuery, _TeachingBlockVersionsQueryVariables>(_TeachingBlockVersionsDocument, {
      where: {
        uuid: uuid,
      },
    })
    .toPromise();

  const { data: teachingBlockData } = await urqlClient
    .query<_TeachingBlocksQuery, _TeachingBlocksQueryVariables>(_TeachingBlocksDocument, {
      where: {
        versions_SOME: {
          uuid: uuid ?? '',
        },
      },
    })
    .toPromise();

  const { data: subjectContainersData } = await urqlClient
    .query<_SubjectContainersQuery, _SubjectContainersQueryVariables>(_SubjectContainersDocument, {})
    .toPromise();

  return {
    versionUuid: data?.teachingBlockVersions[0].uuid ?? '',
    subjectContainerName:
      subjectContainersData?.subjectContainers.find(
        (sc) => sc.uuid === teachingBlockData?.teachingBlocks[0].subjectContainer.uuid,
      )?.name ?? '',
    versionName: `v${addLeadingZero(data?.teachingBlockVersions[0].version ?? 0)}`,
  };
}
