import { FieldArray, useFormikContext } from 'formik';
import { VersionLessonFormType } from '../../types';
import { useTranslation } from 'react-i18next';
import { AddIcon, BadgeCard, Button, DeleteIcon, Input } from '@bp/ui-components';

export const TeachingBlockDurationAndCounter = () => {
  const { values, handleChange } = useFormikContext<VersionLessonFormType>();
  const { t } = useTranslation();
  return (
    <>
      <FieldArray name={'durationCountArray'}>
        {(arrayHelpers) => {
          return (
            <>
              <div className='form-group tks__grid'>
                {values.durationCountArray?.map((durationCount, index) => {
                  const count = !durationCount.duration ? 0 : durationCount.duration;
                  return (
                    <div key={index} className='tks__row'>
                      <div className='tks__col col-xs-9 no-gap'>
                        <div className={'form-row'}>
                          <Input
                            className={'quarter'}
                            type={'number'}
                            label={t('lessonUnit.duration')}
                            name={`durationCountArray.${index}.duration`}
                            onChange={handleChange}
                            value={durationCount.duration}
                            min={1}
                            max={52}
                            suffix={t('common.week', {
                              count: count,
                            })}
                          />

                          <Input
                            className={'quarter'}
                            type={'number'}
                            label={t('common.count') as string}
                            tooltipText={t('lessonUnit.tooltip.count') as string}
                            name={`durationCountArray.${index}.count`}
                            onChange={handleChange}
                            value={durationCount.count}
                          />
                          <div className={'quarter align-center pl-10'}>
                            <BadgeCard
                              label={values.subject?.name.slice(0, 2).toUpperCase() ?? ''}
                              count={durationCount.count ?? 0}
                              width={durationCount.duration ?? 0}
                            ></BadgeCard>
                          </div>
                        </div>
                      </div>
                      <div className='tks__col col-xs-3'>
                        <Button
                          className='mt-6 align-end'
                          hierarchy='tertiary'
                          disabled={!values.durationCountArray?.length || values.durationCountArray.length <= 1}
                          icon={
                            <DeleteIcon
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  );
                })}
                <Button
                  hierarchy={'tertiary'}
                  icon={<AddIcon />}
                  onClick={async () => {
                    arrayHelpers.push({
                      count: 1,
                      duration: 1,
                    });
                  }}
                >
                  {t('common.add')}
                </Button>
              </div>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};
