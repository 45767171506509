import { TeachingBlockExclusionType } from '../TeachingBlockVersion/graphql/types';
import styles from './ExclusionTimeframe.module.scss';
import {
  Button,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  Grid,
  GridColumn,
  GridRow,
  Input,
  QuestionmarkIcon,
  Tooltip,
} from '@bp/ui-components';
import { niceDate } from '../../utils/dateCalculations';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

type ExclusionTimeframeItemProps = {
  item: TeachingBlockExclusionType;
  onEdit: (item: string) => void;
  onDelete: (uuid: string) => void;
};

export const ExclusionTimeframeItem = ({ item, onEdit, onDelete }: ExclusionTimeframeItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles['exclusion-timeframe-item']}>
      <Grid useFormGap>
        <GridRow>
          <GridColumn width={10}>
            <GridRow spacingBottom='none' spacingTop='none'>
              <GridColumn width={4}>
                <Input
                  onChange={() => {}}
                  label={t('common.from')}
                  name='from'
                  readonly
                  value={niceDate(item.from, 'medium')}
                />
              </GridColumn>
              <GridColumn width={4}>
                <Input
                  onChange={() => {}}
                  label={t('common.until')}
                  name='to'
                  readonly
                  value={niceDate(item.to, 'medium')}
                />
              </GridColumn>
              <GridColumn width={4}>
                {item.priority === 0 ? (
                  <div className='pt-5'>
                    <Tooltip content={t('teachingBlockVersion.exclusion.full')}>
                      <CloseIcon color='var(--color-error)' className='bordered' />
                    </Tooltip>
                  </div>
                ) : (
                  <div className='pt-5'>
                    <Tooltip content={t('teachingBlockVersion.exclusion.partial')}>
                      <QuestionmarkIcon
                        color='var(--color-warning)'
                        className={classNames(styles['question-mark'], 'bordered')}
                      />
                    </Tooltip>
                  </div>
                )}
              </GridColumn>
            </GridRow>
            {item.comment && (
              <GridRow spacingTop='none' spacingBottom='s'>
                <Input
                  dense
                  onChange={() => {}}
                  label={t('common.comment')}
                  name='comment'
                  readonly
                  value={item.comment}
                />
              </GridRow>
            )}
          </GridColumn>
          <GridColumn width={2}>
            <GridRow>
              <div className={styles.actions}>
                <Button hierarchy='tertiary' icon={<EditIcon />} onClick={() => onEdit(item.uuid)} />
                <Button hierarchy='tertiary' icon={<DeleteIcon />} onClick={() => onDelete(item.uuid)} />
              </div>
            </GridRow>
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
};
