import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Page } from '../../../components/Page/Page';
import { MathplanJobsTable } from '../../../components/MathplanJobs/MathplanJobsTable';

export const MathplanSubpage: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('routes.mathplan.title')}>
      <MathplanJobsTable />
    </Page>
  );
};
