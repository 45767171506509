import { useMemo } from 'react';
import { TimetableReports } from '../../../components/EvaluateTimetable/TimetableReports';
import { useTranslation } from 'react-i18next';
import { TimetableReportType } from '@bp/planung-graphql-types';

export const EvaluateByClass = () => {
  const { t } = useTranslation();
  const tableData = useMemo(() => {
    return [
      { id: TimetableReportType.SingleClass, name: t('pdf.singlePlans') },
      { id: TimetableReportType.AllClasses, name: t('pdf.overallPlans') },
      { id: TimetableReportType.TeachingBlocksClass, name: t('pdf.epochPlans') },
      { id: null, name: t('common.notYet', { planned: t('pdf.annualPlans') }) },
      { id: null, name: t('common.notYet', { planned: t('pdf.annualOverviewPlans') }) },
      { id: null, name: t('common.notYet', { planned: t('pdf.annualCompactPlans') }) },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TimetableReports tableData={tableData} topic={'classes'} />;
};
