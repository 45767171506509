import { ColorOption } from '@bp/ui-components';
import colors from '../assets/colors/Colors.json';

export function hexToColorOption(hexColorValue: string): ColorOption {
  return (
    colors.find((c) => {
      return c.html.toUpperCase() === hexColorValue.toUpperCase();
    }) ?? {
      html: '#ffffff',
      label: hexColorValue.toUpperCase(),
      value: 'colo-not-set',
    }
  );
}
