import { showSuccessCreateToast } from './toast';
import { ReportType } from '../components/EvaluateTimetable/types';
import { apiHelper } from './apiHelper';
import { ReportPdfTableData } from '../components/EvaluateTimetable/TimetableReportsTable';
import dayjs from 'dayjs';
import { TeachingLoadReportType, TimetableReportType } from '@bp/planung-graphql-types';

export const downloadReport = async (
  report: ReportPdfTableData,
  reportType: TeachingLoadReportType | TimetableReportType,
) => {
  const downloadFileName = report.name.replace(/ /g, '_') + '_' + report.timestamp.format('YYYY-MM-DD');

  await apiHelper<void>({
    method: 'GET',
    path: `/pdf/${reportType}/${report.uuid}`,
    type: 'download',
    downloadFileName,
  });
};

export const deleteReport = async (
  report: { uuid: string; name: string },
  reportType: TeachingLoadReportType | TimetableReportType,
) => {
  await apiHelper<void>({
    method: 'DELETE',
    path: `/pdf/${reportType}/${report.uuid}`,
  });
};

export const downloadDebug = async (uuid: string, type: ReportType) => {
  await apiHelper<void>({
    method: 'GET',
    path: `/pdf/export/${type}/${uuid}`,
    type: 'download',
    downloadFileName: type + '_' + uuid + '_' + dayjs().format('YYYY-MM-DD') + '.json',
  });
};

export const createTimetableCollectiveReport = async (
  uuid: string,
  type: ReportType,
  subType: TimetableReportType | undefined,
) => {
  const response = await apiHelper<Response>({
    method: 'POST',
    path: `/pdf/${type}/${subType}/${uuid}`,
  });

  if (response.ok) {
    showSuccessCreateToast();
  }
};

export const createTimetableSingleReport = async (
  uuid: string,
  type: ReportType,
  subType: TimetableReportType | undefined,
) => {
  const response = await apiHelper<Response>({
    method: 'POST',
    path: `/pdf/timetablezip/${subType}/${uuid}`,
  });

  if (response.ok) {
    showSuccessCreateToast();
  }
};

export const createTeachingLoadReport = async (
  uuid: string,
  scenario: string,
  type: ReportType,
  subType: TimetableReportType | TeachingLoadReportType | undefined,
) => {
  const response = await apiHelper<Response>({
    method: 'POST',
    path: `/pdf/${type}/${subType}/${uuid}/${scenario}`,
  });

  if (response.ok) {
    showSuccessCreateToast();
  }
};
