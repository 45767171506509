import styles from './GenerateModal.module.scss';
import { observer } from 'mobx-react-lite';
import { useTimetableStore } from '../TimetableProvider';
import { useTranslation } from 'react-i18next';
import { Grid, GridColumn, GridRow, ImportantIcon, Switch } from '@bp/ui-components';

export const GenerateForm = observer(() => {
  const pinboardStore = useTimetableStore();
  const { t } = useTranslation();

  return (
    <div className={styles.options}>
      <Grid>
        <GridRow headline={t('mathplan.disclaimer')}>
          <GridColumn width={6}>
            <div>{t('pinboard.generate.selected')}</div>
            <div>
              {pinboardStore.selectedRows.length > 0 && pinboardStore.context === 'classes'
                ? pinboardStore.selectedRows.map((row) => row.label).join(', ')
                : t('pinboard.generate.all')}
            </div>
          </GridColumn>
          <GridColumn width={6}>
            <GridRow>
              <GridColumn width={12}>
                <div>{t('pinboard.generate.options')}</div>
              </GridColumn>
            </GridRow>
            <GridRow spacingBottom={'s'}>
              <GridColumn width={6}>
                <div>{t('pinboard.generate.keepCards')}</div>
              </GridColumn>
              <GridColumn width={6} align={'end'}>
                <Switch
                  name='cards'
                  checked={pinboardStore.generatorOptions.keepExistingPositions}
                  onChange={() =>
                    pinboardStore.setKeepExistingPositions(!pinboardStore.generatorOptions.keepExistingPositions)
                  }
                />
              </GridColumn>
            </GridRow>
            <GridRow spacingBottom={'s'} spacingTop={'s'}>
              <GridColumn width={6}>
                <div>{t('pinboard.generate.withRooms')}</div>
              </GridColumn>
              <GridColumn width={6} align={'end'}>
                <Switch
                  name='rooms'
                  checked={pinboardStore.generatorOptions.includeRoomResources}
                  onChange={() =>
                    pinboardStore.setIncludeRoomResources(!pinboardStore.generatorOptions.includeRoomResources)
                  }
                />
              </GridColumn>
            </GridRow>
            <GridRow spacingTop={'s'}>
              <GridColumn width={6}>
                <div>{t('pinboard.generate.considerTeachingBlock')}</div>
              </GridColumn>
              <GridColumn width={6} align={'end'}>
                <Switch
                  name='teachingBlock'
                  checked={pinboardStore.generatorOptions.includeSubjectContainer}
                  onChange={() =>
                    pinboardStore.setIncludeSubjectContainer(!pinboardStore.generatorOptions.includeSubjectContainer)
                  }
                />
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
      </Grid>
      {!pinboardStore.generatorOptions.keepExistingPositions && (
        <Grid>
          <GridRow spacingTop={'l'}>
            <GridColumn width={12}>
              <div className={styles.warning}>
                <ImportantIcon className={styles.icon} />
                <div>{t('pinboard.generate.deleteHint')}</div>
              </div>
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </div>
  );
});
