import { Form, Formik } from 'formik';
import { Checkbox } from '@bp/ui-components';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useTranslation } from 'react-i18next';

type EditProfileFormProps = {
  handleClose: (values?: GrantRolesFormValues) => void;
  wasPlanungUser: boolean;
};

export type GrantRolesFormValues = {
  isPlanungUser: boolean;
  isPlanungAdmin: boolean;
};

export const GrantRolesForm = ({ wasPlanungUser, handleClose }: EditProfileFormProps) => {
  const { t } = useTranslation();

  const handleSubmit = (values: GrantRolesFormValues) => {
    handleClose(values);
  };

  return (
    <>
      <Formik<GrantRolesFormValues>
        onSubmit={handleSubmit}
        initialValues={{ isPlanungUser: wasPlanungUser, isPlanungAdmin: wasPlanungUser }}
      >
        {({ touched, resetForm, isSubmitting, errors, setFieldValue, setFieldTouched, values }) => {
          const onClose = () => {
            resetForm();
            handleClose();
          };

          return (
            <Form>
              <Checkbox
                name={'isPlanungUser'}
                onChange={async (e) => {
                  await setFieldValue('isPlanungUser', e.target.checked);
                  await setFieldTouched('isPlanungUser', true);
                  /* Prepare to handle more/specific roles for planung */
                  await setFieldValue('isPlanungAdmin', e.target.checked);
                  await setFieldTouched('isPlanungAdmin', true);
                }}
                checked={values.isPlanungUser}
                label={t('profile.isPlanungUser')}
              />
              <div className={'mt-6'}>{t('profile.createUserInfo')}</div>
              <ModalBottomButtons
                closeButton={{ callback: onClose, disabled: !touched.isPlanungUser }}
                isLoading={isSubmitting}
                errors={errors}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
