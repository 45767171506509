import { Page } from '../../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatches, useParams } from 'react-router-dom';
import { CrumbType } from '../../../../components/Breadcrumb/Breadcrumb';
import { createContext, useContext } from 'react';
import { useSubjectsListQuery } from '../../../../types/planung-graphql-client-defs';

export type CurriculumContextType = {
  showMenu: boolean;
  versionUuid: string;
  grade?: string;
  subjectUuid?: string;
  subject?: { uuid: string; name: string };
};
export const CurriculumContext = createContext<CurriculumContextType>({ showMenu: true, versionUuid: '' });

export const CurriculaPage = () => {
  const { t } = useTranslation();

  const matches = useMatches();
  const crumb = matches
    .filter((match) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Boolean(match.handle?.crumb); // How to overwrite crumb ? see src/types/react-router.d.ts
    })
    .map((match) => {
      const handle = match.handle as CrumbType;
      return handle.crumb(match.data);
    })
    .filter((c) => (Array.isArray(c) ? c.some((ct) => ct.asHeadline) : c.asHeadline))
    .flat();

  const curriculumContext = useContext(CurriculumContext);

  const { uuid, grade, subjectUuid } = useParams();

  const [data] = useSubjectsListQuery({ variables: { where: { uuid: subjectUuid ?? '' } } });
  if (subjectUuid && data.data?.subjects.length === 1) {
    curriculumContext.subject = data.data.subjects[0];
  }

  curriculumContext.versionUuid = uuid ?? '';
  curriculumContext.showMenu = true;
  curriculumContext.grade = grade;
  curriculumContext.subjectUuid = subjectUuid;

  if (grade || subjectUuid) {
    curriculumContext.showMenu = false;
  }

  let title = crumb.find((c) => c.asHeadline)?.title ?? t('curriculumVersion.title.plural');

  const subTitle = Array.isArray(crumb) ? crumb.findLast((c) => c.asHeadline)?.title : '';

  if (curriculumContext.grade !== undefined) {
    title = t('curriculumVersion.forGrade', { sst: title, grade });
  }
  if (curriculumContext.subjectUuid) {
    title = t('curriculumVersion.forSubject', { sst: title, subject: subTitle });
  }

  return (
    <Page title={title} hasOutlet>
      <Outlet />
    </Page>
  );
};
