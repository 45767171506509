import { useTranslation } from 'react-i18next';
import ClassDivisionsClassesTable from '../../../../components/ClassDivisions/ClassDivisionsClassesTable';
import { Tab } from '@bp/ui-components';
import { DeputateClassesTable } from '../../../../components/Classes/Tables/DeputateClassesTable';
import { Page } from '../../../../components/Page/Page';

export const SetupClassesAndDivisions = () => {
  const { t } = useTranslation();

  return (
    <Page title={null}>
      <Tab
        variant={'large'}
        tabs={[
          {
            title: t('classes.title.plural'),
            value: 'classes',
            content: <DeputateClassesTable key={'classes'} />,
          },
          {
            title: t('divisions.title.plural'),
            value: 'divisions',
            content: <ClassDivisionsClassesTable key={'divisions'} />,
          },
        ]}
      />
    </Page>
  );
};
