import * as Yup from 'yup';
import i18next from 'i18next';

export const schema = Yup.object().shape({
  name: Yup.string().required(i18next.t('validation.timeGrid.nameRequired')),
});

export const itemSchema = Yup.object().shape({
  isPause: Yup.boolean(),
  print: Yup.boolean(),
  order: Yup.number(),
  name: Yup.string().required(i18next.t('validation.timeGrid.itemNameRequired')),
  shortName: Yup.string().required(i18next.t('validation.timeGrid.itemShortNameRequired')),
  start: Yup.string().required(i18next.t('validation.timeGrid.startRequired')),
  end: Yup.string().required(i18next.t('validation.timeGrid.endRequired')),
});
