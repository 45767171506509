import { FC, ReactNode } from 'react';
import styles from './AvailabilityMatrix.module.scss';
import classNames from 'classnames';

export type AvailabilityMatrixStates = 'available' | 'conditional' | 'notAvailable';

export type AvailabilityMatrixItemType = {
  state: AvailabilityMatrixStates;
  icon: ReactNode;
  title: string;
};

type AvailabilityMatrixItemProps = {
  item: AvailabilityMatrixItemType;
  blocked: boolean;
  readonly: boolean;
  onClick?: () => void;
};

export const AvailabilityMatrixItem: FC<AvailabilityMatrixItemProps> = ({ item, blocked, readonly, onClick }) => {
  const classes = classNames(
    styles['matrix-item'],
    { [styles['is-blocked']]: blocked, [styles['read-only']]: readonly },
    styles[item.state === 'notAvailable' ? 'not-available' : item.state],
  );

  return (
    <div title={item.title} className={classes} onClick={onClick}>
      <div className={styles['icon-wrapper']}>{item.icon}</div>
    </div>
  );
};
