import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import {
  useCreateSubjectGroupMutation,
  useSubjectGroupsQuery,
  useUpdateSubjectGroupMutation,
} from '../../../types/planung-graphql-client-defs';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Input, showToast } from '@bp/ui-components';
import { schema } from './validation/schema';

type SubjectGroupFormProps = {
  subjectGroupUuid?: string | null;
  closeForm: () => void;
};

type SubjectGroupFormData = {
  uuid: string;
  name: string;
};

export const SubjectGroupForm = ({ subjectGroupUuid, closeForm }: SubjectGroupFormProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();

  const context = useMemorizedCacheTag('SUBJECT_GROUPS');

  const [, update] = useUpdateSubjectGroupMutation();
  const [, create] = useCreateSubjectGroupMutation();

  const [{ data }] = useSubjectGroupsQuery({
    variables: {
      subjectGroupUuid,
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  const handleSubmit = async (values: SubjectGroupFormData, formikHelpers: FormikHelpers<SubjectGroupFormData>) => {
    let result;
    if (values.uuid) {
      result = await update(
        {
          uuid: values.uuid,
          update: {
            name: values.name,
          },
        },
        context,
      );
    } else {
      result = await create(
        {
          input: {
            name: values.name,
            organization: {
              connect: {
                where: {
                  node: {
                    uuid: pimAuthClaims.getOrganizationUuid(),
                  },
                },
              },
            },
          },
        },
        context,
      );
    }
    if (!result || result.error) {
      showToast('Ups.', { type: 'error' });
    } else {
      showToast('Success', { type: 'success' });
      formikHelpers.resetForm();
      closeForm();
    }
  };

  const initialValues: SubjectGroupFormData =
    data && data.subjectGroups && data.subjectGroups[0]
      ? data.subjectGroups[0]
      : {
          uuid: '',
          name: '',
        };

  return (
    <Formik<SubjectGroupFormData> initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
      {({ values, handleChange, resetForm, errors }) => (
        <Form>
          <div className={'form-block'}>
            <div className={'form-group'}>
              <div className={'form-col'}>
                <div className={'form-row'}>
                  <Input
                    autoFocus={true}
                    label={t('common.name')}
                    className={'full'}
                    name={'name'}
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={'modal-bottom'}>
            <Button
              hierarchy='tertiary'
              type='button'
              onClick={() => {
                resetForm();
                closeForm();
              }}
            >
              {t('common.cancelChanges')}
            </Button>
            <Button type='submit'>{t('common.save')}</Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
