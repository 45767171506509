import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import {
  useBuildingsListQuery,
  useCreateBuildingsMutation,
  useUpdateBuildingsMutation,
} from '../../../types/planung-graphql-client-defs';
import { BuildingsListType } from '../graphgl/types';
import { Button, Checkbox, Input } from '@bp/ui-components';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';

export type BuildingsFormInitialValuesType = {
  uuid?: string;
  name: string;
  description?: string;
  active: boolean;
};

export const BuildingsForm = ({
  buildingUuid,
  handleClose,
}: {
  buildingUuid: string | null;
  handleClose: () => void;
}) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('BUILDINGS');

  const [, create] = useCreateBuildingsMutation();
  const [, update] = useUpdateBuildingsMutation();

  const [{ data }] = useBuildingsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      buildingUuid: buildingUuid,
    },
    context,
  });

  const currentBuilding: BuildingsFormInitialValuesType =
    data && data.buildings && data.buildings.length > 0
      ? { ...resultToFormData(data.buildings[0]) }
      : ({} as BuildingsFormInitialValuesType);

  function resultToFormData(currentBuilding: BuildingsListType): BuildingsFormInitialValuesType {
    return {
      uuid: currentBuilding.uuid,
      name: currentBuilding.name,
      description: currentBuilding.description ?? undefined,
      active: currentBuilding.active ?? false,
    };
  }

  const handleSubmit = async (
    values: BuildingsFormInitialValuesType,
    formHelpers: FormikHelpers<BuildingsFormInitialValuesType>,
  ) => {
    let mutationResult;
    const basics = {
      name: values.name,
      description: values.description,
      active: values.active,
    };
    if (values.uuid) {
      mutationResult = await update(
        {
          where: { uuid: values.uuid },
          update: { ...basics },
        },
        context,
      );
      if (!mutationResult.error) {
        showSuccessUpdateToast([values.name]);
      }
    } else {
      mutationResult = await create(
        {
          input: {
            ...basics,
            organization: { connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } } },
          },
        },
        context,
      );
      if (!mutationResult.error) {
        showSuccessCreateToast([values.name]);
      }
    }
    if (mutationResult.error) {
      showUserErrorToast({ error: mutationResult.error });
    }
    formHelpers.resetForm();
    handleClose();
  };

  return (
    <>
      <Formik initialValues={currentBuilding} onSubmit={handleSubmit}>
        {(formik) => {
          const values = formik.values;
          return (
            <Form>
              <div className='tks__grid mt-6 mb-8'>
                <div className='tks__row small-gap'>
                  <div className='tks__col col-xs-5'>
                    <Input
                      label={t('common.name')}
                      name={'name'}
                      onChange={formik.handleChange}
                      value={values.name}
                      error={formik.errors.name}
                    />
                  </div>
                  <div className='tks__col col-xs-5'>
                    <Input
                      label={t('common.description') as string}
                      name={'description'}
                      onChange={formik.handleChange}
                      value={values.description}
                      error={formik.errors.description}
                    />
                  </div>
                  <div className='tks__col col-xs-2'>
                    <Checkbox
                      className='mt-5'
                      label={t('common.active.full') as string}
                      name={'active'}
                      onChange={(e) => {
                        formik.setFieldValue('active', e.target.checked);
                        formik.setFieldTouched('active', true);
                      }}
                      checked={values.active}
                      error={formik.errors.active}
                    />
                  </div>
                </div>
              </div>
              <div className={'modal-bottom'}>
                <Button
                  hierarchy='tertiary'
                  type='button'
                  onClick={() => {
                    formik.resetForm();
                    handleClose();
                  }}
                  className={'buttons'}
                >
                  {t('common.cancelChanges')}
                </Button>
                <Button
                  type='submit'
                  disabled={formik.isSubmitting || !formik.dirty || formik.isValidating}
                  className={'buttons'}
                >
                  {t('common.save')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
