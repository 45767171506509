import AvailabilityMatrix, { Day, TimeGridEntry } from '../../AvailabilityMatrix/AvailabilityMatrix';
import { TimetableVersionTableType } from '../graphql/types';
import { useTeacherAvailabilityMatrixQuery } from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { TimetableRelationshipProperties } from '@bp/planung-graphql-types';

type TimetableVersionTeachersMatrixProps = {
  selected: TimetableVersionTableType | null;
  onChange: (availability: TimetableRelationshipProperties) => void;
  versionUuid?: string | null;
  timeGridEntries: TimeGridEntry[];
};

export const TimetableVersionTeachersMatrix = ({
  selected,
  versionUuid,
  onChange,
  timeGridEntries,
}: TimetableVersionTeachersMatrixProps) => {
  const context = useMemorizedCacheTag('TEACHER_MATRIX');

  const [{ data }, execute] = useTeacherAvailabilityMatrixQuery({
    variables: {
      versionUuid: versionUuid ?? '',
    },
    context: context,
  });

  const days: Day[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const hours = timeGridEntries.filter((entry) => !entry.pause);

  const availability = data?.timetableVersions[0].teachersConnection.edges.find((e) => e.node.uuid === selected?.uuid);

  return (
    <AvailabilityMatrix
      days={days}
      hours={hours}
      availability={availability?.properties}
      onChange={(items) => {
        onChange(items);
        execute();
      }}
    />
  );
};
