export const STORAGE_KEY =
  'oidc.user:' + import.meta.env.VITE_APP_AUTH_SERVER_URI + ':' + import.meta.env.VITE_APP_AUTH_CLIENT_ID;

export interface IpClaim {
  at_hash?: string;
  sub?: string;
  aud?: string;
  iss?: string;
  exp?: number;
  iat?: number;
}

const getAuthData = (): string | null => {
  return sessionStorage.getItem(STORAGE_KEY);
};

export const getAccessToken = (): string | null => {
  const data = getAuthData();
  if (data) {
    return JSON.parse(data).access_token;
  }
  return null;
};

export const getRefreshToken = () => {
  const data = getAuthData();
  if (data) {
    return JSON.parse(data).refresh_token;
  }
  return null;
};

export const getExpiresAt = (): number | null => {
  const data = getAuthData();
  if (data) {
    return JSON.parse(data).expires_at;
  }
  return null;
};

export const getIdToken = (): string | null => {
  const data = getAuthData();
  if (data) {
    return JSON.parse(data).id_token;
  }
  return null;
};

export const getTokens = () => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const expiresAt = getExpiresAt();
  const idToken = getIdToken();
  // TODO check refresh token
  if (accessToken && refreshToken) {
    return { access_token: accessToken, refresh_token: refreshToken, expires_at: expiresAt, id_token: idToken };
  }
  return null;
};

export const clearTokens = () => {
  sessionStorage.clear();
  return sessionStorage.removeItem(STORAGE_KEY);
};
