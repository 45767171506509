import { EmptyState, LazyLoader, Modal } from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useTimetableStore } from '../TimetableProvider';

export const CardControlModal = observer(() => {
  const { t } = useTranslation();

  const pinboardStore = useTimetableStore();

  return (
    <Modal
      width='s'
      height='s'
      hideHeader
      isOpen={pinboardStore.controlMode.isLoading}
      onRequestClose={() => pinboardStore.setControlModeLoading(false)}
      hideCloseButton
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      parent={document.fullscreenElement ? document.getElementById('timetable-grid') : null}
    >
      <EmptyState
        title={t('pinboard.control.loadingHint')}
        icon={<LazyLoader size='xxl' embedded />}
        iconColor='var(--color-grey-dark)'
      />
    </Modal>
  );
});
