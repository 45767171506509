import { TimetableVersionDependenciesTable } from '../../../../../../components/TimetableVersion/TimetableVersionDataTables/TimetableVersionDependenciesTable';
import { useParams } from 'react-router-dom';

export const PlanTimetableDependencies = () => {
  const { versionUuid } = useParams();

  return (
    <div>
      <div className={'tks__grid'}>
        <div className={'tks__row'}>
          <div className={'tks__col col-xs-12'}>
            <TimetableVersionDependenciesTable versionUuid={versionUuid ?? ''} />
          </div>
        </div>
      </div>
    </div>
  );
};
