import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import styles from '../Constraints.module.scss';
import { Button, Select, SelectOptionType, TextArea } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { StartEndConstraintItem } from './FormItems/StartEndConstraintItem';
import React, { useEffect, useMemo, useState } from 'react';
import { MinMaxHoursConstraintItem } from './FormItems/MinMaxHoursConstraintItem';
import { FreePeriodConstraintItem } from './FormItems/FreePeriodConstraintItem';
import { SameEndConstraintItem } from './FormItems/SameEndConstraintItem';
import { LunchBreakConstraintItem } from './FormItems/LunchBreakConstraintItem';
import { MaxOnUnknownPositionConstraintItem } from './FormItems/MaxOnUnknownPositionConstraintItem';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { constraintOrder, ConstraintsFormInitialValuesType, ConstraintsSelectType, ConstraintTypes } from '../types';
import { TimeGridEntry } from '../../AvailabilityMatrix/AvailabilityMatrix';

type ConstraintsFormProps = {
  onClose: () => void;
  timeGridEntries: TimeGridEntry[];
  constraints: ConstraintsFormInitialValuesType;
  onSubmit: (values: ConstraintsFormInitialValuesType) => Promise<boolean> | boolean;
};

export const ConstraintsForm = ({ onClose, timeGridEntries, constraints, onSubmit }: ConstraintsFormProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<ConstraintTypes[]>([]);

  useEffect(() => {
    function getFilledFields(constraints: ConstraintsFormInitialValuesType): ConstraintTypes[] {
      const filledFields: ConstraintTypes[] = [];
      constraintOrder.forEach((type) => {
        if (constraints[type] !== undefined) {
          filledFields.push(type);
        }
      });
      return filledFields;
    }

    setSelected(getFilledFields(constraints));
  }, [constraints]);

  const possibleConstraints: ConstraintsSelectType[] = useMemo(() => {
    return [
      {
        label: t('conditions.startEnd.label'),
        value: 'startEnd',
      },
      {
        label: t('conditions.hourCount.label'),
        value: 'minMaxHours',
      },
      {
        label: t('conditions.freePeriod.label'),
        value: 'maxFreePeriod',
      },
      {
        label: t('conditions.sameEnd.label'),
        value: 'sameEnd',
      },
      {
        label: t('conditions.lunchBreak.label'),
        value: 'lunchBreak',
      },
      {
        label: t('conditions.maxHours.label'),
        value: 'maxOnUnknownPosition',
      },
    ];
  }, []);

  const availableConstraints = useMemo(() => {
    return possibleConstraints.filter((constraint) => !selected.includes(constraint.value as ConstraintTypes));
  }, [possibleConstraints, selected]);

  const timeGridOptions = useCreateSelectOptions(timeGridEntries, 'uuid', 'shortName');

  const handleSubmit = async (
    values: ConstraintsFormInitialValuesType,
    formHelpers: FormikHelpers<ConstraintsFormInitialValuesType>,
  ) => {
    const success = await onSubmit(values);
    if (success) {
      formHelpers.resetForm();
      onClose();
    }
  };
  return (
    <Formik initialValues={constraints} onSubmit={handleSubmit}>
      {({
        values,
        handleChange,
        dirty,
        resetForm,
        isSubmitting,
        isValidating,
        errors,
      }: FormikProps<ConstraintsFormInitialValuesType>) => (
        <Form className={styles['conditions-form']}>
          <TextArea
            name='comment'
            onChange={handleChange}
            label={t('common.comment')}
            value={values.comment}
            isScrollable
            error={errors.comment}
          />
          <Select
            isTrigger
            className={styles['master-select']}
            placeholder={t('conditions.pleaseSelect')}
            options={availableConstraints}
            name='addCondition'
            menuPosition={'fixed'}
            onChange={(value) => {
              const type = (value as SelectOptionType).value as ConstraintTypes;
              setSelected([type, ...selected]);
            }}
            label={t('conditions.add')}
          />
          <div className='tks__grid no-gap'>
            {selected.map((value) => {
              return (
                <div className={styles['condition-form-item']} key={value}>
                  {value === 'startEnd' && (
                    <StartEndConstraintItem
                      options={timeGridOptions}
                      onRemove={() => {
                        setSelected([...selected.filter((s) => s !== 'startEnd')]);
                      }}
                    />
                  )}
                  {value === 'minMaxHours' && (
                    <MinMaxHoursConstraintItem
                      onRemove={() => {
                        setSelected([...selected.filter((s) => s !== 'minMaxHours')]);
                      }}
                    />
                  )}
                  {value === 'maxFreePeriod' && (
                    <FreePeriodConstraintItem
                      onRemove={() => {
                        setSelected([...selected.filter((s) => s !== 'maxFreePeriod')]);
                      }}
                    />
                  )}
                  {value === 'sameEnd' && (
                    <SameEndConstraintItem
                      onRemove={() => {
                        setSelected([...selected.filter((s) => s !== 'sameEnd')]);
                      }}
                    />
                  )}
                  {value === 'lunchBreak' && (
                    <LunchBreakConstraintItem
                      options={timeGridOptions}
                      onRemove={() => {
                        setSelected([...selected.filter((s) => s !== 'lunchBreak')]);
                      }}
                    />
                  )}
                  {value === 'maxOnUnknownPosition' && (
                    <MaxOnUnknownPositionConstraintItem
                      onRemove={() => {
                        setSelected([...selected.filter((s) => s !== 'maxOnUnknownPosition')]);
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className={'modal-bottom'}>
            <Button
              hierarchy='tertiary'
              type='button'
              onClick={() => {
                resetForm();
                onClose();
              }}
            >
              {t('common.cancelChanges')}
            </Button>
            <Button type='submit' disabled={isSubmitting || !dirty || isValidating}>
              {t('common.save')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
