import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { AddIcon, Button, DeleteIcon } from '@bp/ui-components';
import LessonUnitFormItem from './LessonUnitFormItem';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { LessonFormType, LessonFormUnitType } from '../../types';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useSubjectContainersListQuery } from '../../../../types/planung-graphql-client-defs';
import { LessonTableType } from '../../Tables/TimetableVersionLessonsTable';
import { v4 } from 'uuid';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';

const LessonUnitForm = ({ status }: { status: LessonTableType['editStatus'] }) => {
  const { values, setFieldTouched } = useFormikContext<LessonFormType>();
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();

  const context = useMemorizedCacheTag('SUBJECT_CONTAINER');

  const [{ data }] = useSubjectContainersListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });
  const { classesData } = useLoadBasicData({ pause: false });

  const grades = values.lessonClasses?.map((lc) => classesData?.classes.find((c) => c.uuid === lc.class.uuid)?.grade);

  const subjectContainers = data?.subjectContainers.filter((sc) => {
    if (sc.grades.length === 0) {
      return true;
    }
    return grades ? grades.filter((g) => g).every((g) => (g ? sc.grades.includes(g) : false)) : false;
  });

  return (
    <>
      <FieldArray name={'lessonUnit'}>
        {(arrayHelpers) => {
          return (
            <>
              <div className='form-group tks__grid'>
                {values.lessonUnit?.map((lessonUnit, index) => {
                  return (
                    <div key={lessonUnit.uuid} className='tks__row'>
                      <div className='tks__col col-xs-9 no-gap'>
                        <LessonUnitFormItem
                          disabled={lessonUnit.blocked ?? false}
                          index={index}
                          subjectContainer={subjectContainers}
                        />
                      </div>
                      <div className='tks__col col-xs-3'>
                        <Button
                          className='mt-6 align-end'
                          hierarchy='tertiary'
                          disabled={lessonUnit.blocked ?? false}
                          icon={
                            <DeleteIcon
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  );
                })}
                <Button
                  key={`${values.subject?.uuid}-${values.lessonClasses?.length}`}
                  disabled={!values.subject?.uuid || !values.lessonClasses?.length || status === 'blocked'}
                  hierarchy={'tertiary'}
                  icon={<AddIcon />}
                  onClick={async () => {
                    if (values.lessonUnit && values.lessonUnit.length > 0) {
                      for (const index in values.lessonUnit) {
                        await setFieldTouched(`lessonUnit.${index}`);
                      }
                    }
                    const lessonUnit: LessonFormUnitType = {
                      uuid: v4(),
                      count: null,
                      factor: null,
                      duration: null,
                      blocked: false,
                      lessonUnitType: 'subjectHour',
                    };
                    arrayHelpers.push(lessonUnit);
                  }}
                >
                  {t('lessonUnit.add')}
                </Button>
              </div>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

export default LessonUnitForm;
