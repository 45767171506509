import styles from './../TimetableGrid.module.scss';
import { DayItem, HourItem } from '../../../stores/PinboardStore';
import { useTimetableStore } from '../TimetableProvider';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useCallback } from 'react';

export const GridHeaderHour = observer(
  ({ cellWidth, hour, day }: { day: DayItem; hour: HourItem; cellWidth: string }) => {
    const pinboardStore = useTimetableStore();

    let isSelected = false;
    pinboardStore.selectedColumns.forEach((selected) => {
      if (selected === `${hour.value}:${day.value}`) {
        isSelected = true;
      }
    });

    const cardHours = pinboardStore.pickedCard?.cardUuid
      ? (pinboardStore.placedCardsTimeGridEntries.get(pinboardStore.pickedCard?.cardUuid) ?? null)
      : null;

    const cardDay = pinboardStore.pickedCard
      ? (pinboardStore.placedCardsDay.get(pinboardStore.pickedCard?.cardUuid) ?? null)
      : null;

    const highlight =
      (pinboardStore.context === 'rooms' &&
        pinboardStore.pickedCard &&
        hour &&
        cardHours?.some((ch) => ch === hour.value) &&
        cardDay === day.value) ||
      !pinboardStore.pickedCard;

    const lowlight =
      (pinboardStore.context === 'rooms' &&
        pinboardStore.pickedCard &&
        (cardDay !== day.value || !cardHours?.some((ch) => ch === hour.value))) ||
      (pinboardStore.context === 'classes' && pinboardStore.pickedCard && pinboardStore.lowlightHour(hour));

    const nameClasses = classNames([
      styles['hour-name'],
      { [styles['selected']]: isSelected },
      {
        [styles.highlight]: highlight,
        [styles.lowlight]: lowlight,
      },
    ]);

    const onClick = useCallback(() => {
      if (pinboardStore.context === 'rooms') {
        if (pinboardStore.selectedColumns.has(`${hour.value}:${day.value}`)) {
          pinboardStore.selectedColumns.delete(`${hour.value}:${day.value}`);
        } else {
          pinboardStore.selectedColumns.add(`${hour.value}:${day.value}`);
        }
      }
    }, [day.value, hour.value, pinboardStore.selectedColumns]);

    const entry = pinboardStore.timeGridEntries?.find((entry) => entry.uuid === hour.value);
    const title = entry
      ? `${hour.label} ${dayjs(entry.start).format('HH:mm')} - ${dayjs(entry.end).format('HH:mm')}`
      : hour.label;

    return (
      <div style={{ flex: `1 1 ${cellWidth}` }} className={nameClasses} title={title} onClick={onClick}>
        {hour.label}
      </div>
    );
  },
);
