import { RouteObject } from 'react-router-dom';
import { SubstitutionsPlanPage } from './SubstitutionsPlanPage';

export const substitutionPlanRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <SubstitutionsPlanPage />,
    },
  ];
};
