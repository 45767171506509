import { initialMatrix } from '../components/TimetableVersion/utils/TimetableVersionTableUtils';
import { TimetableRelationshipProperties } from '@bp/planung-graphql-types';
import {
  _TimeGridEntriesQuery,
  UpdateTimetableVersionClassAvailabilityDocument,
  UpdateTimetableVersionClassAvailabilityMutation,
  UpdateTimetableVersionClassAvailabilityMutationVariables,
  UpdateTimetableVersionRoomAvailabilityDocument,
  UpdateTimetableVersionRoomAvailabilityMutation,
  UpdateTimetableVersionRoomAvailabilityMutationVariables,
  UpdateTimetableVersionSubjectAvailabilityDocument,
  UpdateTimetableVersionSubjectAvailabilityMutation,
  UpdateTimetableVersionSubjectAvailabilityMutationVariables,
  UpdateTimetableVersionTeachersAvailabilityDocument,
  UpdateTimetableVersionTeachersAvailabilityMutation,
  UpdateTimetableVersionTeachersAvailabilityMutationVariables,
} from '../types/planung-graphql-client-defs';
import { urqlClient } from '../utils/urqlClient';
import { getCacheTag } from '../hooks/useMemorizedCacheTag';
import { makeObservable, observable, runInAction } from 'mobx';

export type Availabilities = {
  class: {
    uuid: string;
    availabilities: TimetableRelationshipProperties;
  }[];
  teacher: {
    uuid: string;
    availabilities: TimetableRelationshipProperties;
  }[];
  room: {
    uuid: string;
    availabilities: TimetableRelationshipProperties;
  }[];
  subject: {
    uuid: string;
    availabilities: TimetableRelationshipProperties;
  }[];
};

export class AvailabilityStore {
  public availabilities?: Availabilities;
  public timeGridEntries: Pick<_TimeGridEntriesQuery, 'timeGridEntries'>['timeGridEntries'] = [];

  constructor() {
    makeObservable(this, {
      availabilities: observable,
    });
  }

  public setAvailability(
    type: keyof Availabilities,
    uuid: string,
    availability: TimetableRelationshipProperties,
    versionUuid: string,
  ) {
    const newAvailabilities = this.availabilities?.[type]?.map((av) => {
      return av.uuid === uuid ? { uuid: av.uuid, availabilities: availability } : av;
    });
    runInAction(() => {
      if (this.availabilities?.[type] && newAvailabilities) {
        this.availabilities = { ...this.availabilities, [type]: newAvailabilities };
      }
    });

    switch (type) {
      case 'class':
        void this.updateCardClassAvailability(uuid, availability, versionUuid);
        break;
      case 'subject':
        void this.updateCardSubjectAvailability(uuid, availability, versionUuid);
        break;
      case 'room':
        void this.updateCardRoomAvailability(uuid, availability, versionUuid);
        break;
      case 'teacher':
        void this.updateCardTeacherAvailability(uuid, availability, versionUuid);
        break;
    }
  }

  private async updateCardClassAvailability(
    classUuid: string,
    availability: TimetableRelationshipProperties,
    versionUuid: string,
  ) {
    const versionsContext = getCacheTag('TIMETABLE_VERSIONS');
    void urqlClient
      .mutation<
        UpdateTimetableVersionClassAvailabilityMutation,
        UpdateTimetableVersionClassAvailabilityMutationVariables
      >(
        UpdateTimetableVersionClassAvailabilityDocument,
        {
          data: {
            mon: availability.mon,
            tue: availability.tue,
            wed: availability.wed,
            thu: availability.thu,
            fri: availability.fri,
            sat: availability.sat,
            sun: availability.sun,
          },
          versionUuid: versionUuid,
          classUuid: classUuid,
        },
        versionsContext,
      )
      .toPromise();
  }

  private async updateCardTeacherAvailability(
    teacherUuid: string,
    availability: TimetableRelationshipProperties,
    versionUuid: string,
  ) {
    const versionsContext = getCacheTag('TIMETABLE_VERSIONS');
    void urqlClient
      .mutation<
        UpdateTimetableVersionTeachersAvailabilityMutation,
        UpdateTimetableVersionTeachersAvailabilityMutationVariables
      >(
        UpdateTimetableVersionTeachersAvailabilityDocument,
        {
          data: {
            mon: availability.mon,
            tue: availability.tue,
            wed: availability.wed,
            thu: availability.thu,
            fri: availability.fri,
            sat: availability.sat,
            sun: availability.sun,
          },
          versionUuid: versionUuid,
          uuid: teacherUuid,
        },
        versionsContext,
      )
      .toPromise();
  }

  private updateCardRoomAvailability(
    roomUuid: string,
    availability: TimetableRelationshipProperties,
    versionUuid: string,
  ) {
    const versionsContext = getCacheTag('TIMETABLE_VERSIONS');
    void urqlClient
      .mutation<
        UpdateTimetableVersionRoomAvailabilityMutation,
        UpdateTimetableVersionRoomAvailabilityMutationVariables
      >(
        UpdateTimetableVersionRoomAvailabilityDocument,
        {
          data: {
            mon: availability.mon,
            tue: availability.tue,
            wed: availability.wed,
            thu: availability.thu,
            fri: availability.fri,
            sat: availability.sat,
            sun: availability.sun,
          },
          versionUuid,
          roomUuid,
        },
        versionsContext,
      )
      .toPromise();
  }

  private updateCardSubjectAvailability(
    subjectUuid: string,
    availability: TimetableRelationshipProperties,
    versionUuid: string,
  ) {
    const versionsContext = getCacheTag('TIMETABLE_VERSIONS');

    void urqlClient
      .mutation<
        UpdateTimetableVersionSubjectAvailabilityMutation,
        UpdateTimetableVersionSubjectAvailabilityMutationVariables
      >(
        UpdateTimetableVersionSubjectAvailabilityDocument,
        {
          data: {
            mon: availability.mon,
            tue: availability.tue,
            wed: availability.wed,
            thu: availability.thu,
            fri: availability.fri,
            sat: availability.sat,
            sun: availability.sun,
          },
          versionUuid,
          subjectUuid,
        },
        versionsContext,
      )
      .toPromise();
  }

  public getTeacherAvailability(teacherUuid: string) {
    return this.availabilities?.teacher.find((a) => a.uuid === teacherUuid)?.availabilities ?? initialMatrix;
  }

  public getSubjectAvailability(subjectUuid: string) {
    return this.availabilities?.subject.find((a) => a.uuid === subjectUuid)?.availabilities ?? initialMatrix;
  }

  public getRoomAvailability(roomUuid: string) {
    return this.availabilities?.room.find((a) => a.uuid === roomUuid)?.availabilities ?? initialMatrix;
  }

  public getClassAvailability(classUuid: string) {
    return this.availabilities?.class.find((a) => a.uuid === classUuid)?.availabilities ?? initialMatrix;
  }
}
