import styles from './Months.module.scss';
import dayjs from 'dayjs';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';

export const Months = () => {
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const start = dayjs(schoolYear?.start);
  const end = dayjs(schoolYear?.end);
  const months: { month: number; partial: boolean; days: number }[] = [];

  let current = start.startOf('month');
  while (current.isBefore(end)) {
    const isPartial = current.isSame(start, 'month') || current.isSame(end, 'month');
    const daysInMonth = current.daysInMonth();
    const days = isPartial
      ? current.isSame(start, 'month')
        ? daysInMonth - start.date() + 1
        : end.date()
      : daysInMonth;
    months.push({ month: current.month() + 1, partial: isPartial, days });
    current = current.add(1, 'month');
  }

  return (
    <div className={styles.months}>
      {months.map(({ month, partial, days }, index) => {
        const date = dayjs()
          .utc()
          .startOf('month')
          .month(month - 1);
        const width = partial ? `${(days / date.daysInMonth()) * 100}%` : '100%';
        return (
          <div key={index} className={styles.month} style={{ width }}>
            {date.toDate().toLocaleString('default', { month: 'short' })}
          </div>
        );
      })}
    </div>
  );
};
