import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubjectsTableType } from '../graphql/types';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  Modal,
  Row,
  showToast,
  Table,
  TableColumns,
  useDefaultSelecting,
} from '@bp/ui-components';
import { SubjectForm } from '../Forms/SubjectForm';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useSubject } from '../graphql/hooks/useSubject';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useConfirm } from '../../../hooks/useConfirm';
import { partition } from '../../../utils/arrayFunc';
import { hexToColorOption } from '../../../utils/colorUtils';
import { useIsUsedInCurriculumOrLesson } from '../../../hooks/useIsUsedInCurriculumOrLesson';

export const TimetableSubjectsTable: FC = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const { confirm, ConfirmationDialog } = useConfirm();
  const check = useIsUsedInCurriculumOrLesson();

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('timetable-subjects-list', {
    other: false,
    'subjectGroup.name': false,
    roomName: false,
  });
  const { sorting, saveSorting } = useColumnsSort('timetable-subjects-list');
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [subject, setSubject] = useState<null | SubjectsTableType>(null);

  const {
    deleteSubjects,
    updateCheckboxes: update,
    setSubjectGroup,
    loadingState,
    data,
    subjectGroupsData,
    roomsData,
  } = useSubject();

  const updateCheckboxes = async ({
    type,
    uuids,
    value,
  }: {
    type: 'subjectHourEnabled' | 'epochEnabled' | 'other' | 'active';
    uuids: string[];
    value: boolean;
  }) => {
    onRowSelectionChange({});
    await update({ type, uuids, value });
  };

  const colorsInUse = data?.subjects?.map((sc) => sc.timetableConfig ?? null) ?? [];

  function createColumns(): TableColumns<SubjectsTableType>[] {
    return [
      {
        header: t('common.name') as string,
        id: 'name',
        accessorKey: 'name',
        meta: {
          filterName: t('common.name') as string,
        },
        size: 250,
      },
      {
        header: t('common.shortName') as string,
        id: 'shortName',
        accessorKey: 'shortName',
        size: 150,
      },
      {
        header: t('subject.subjectHourEnabled.name') as string,
        id: 'subjectHourEnabled',
        type: 'boolean',
        size: 75,
        accessorKey: 'subjectHourEnabled',
        meta: {
          filterName: t('subject.subjectHourEnabled.tooltip') as string,
          tooltip: t('subject.subjectHourEnabled.tooltip') as string,
        },
      },
      {
        header: t('subject.epochEnabled.name') as string,
        accessorKey: 'epochEnabled',
        type: 'boolean',
        size: 75,
        id: 'epochEnabled',
        meta: {
          filterName: t('subject.epochEnabled.tooltip') as string,
          tooltip: t('subject.epochEnabled.tooltip') as string,
        },
      },
      {
        header: t('subject.other.name') as string,
        meta: {
          filterName: t('subject.other.tooltip') as string,
          tooltip: t('subject.other.tooltip') as string,
        },
        accessorKey: 'other',
        type: 'boolean',
        size: 75,
        id: 'other',
      },
      {
        header: t('subject.subjectGroup') as string,
        id: 'subjectGroup.name',
        accessorFn: (originalRow: SubjectsTableType) => {
          return originalRow.subjectGroup?.name;
        },
        enableGlobalFilter: false,
        enableColumnFilter: false,
        size: 250,
      },
      {
        header: t('lesson.table.rooms'),
        id: 'roomName',
        accessorKey: 'roomName',
        size: 300,
      },
      {
        header: t('common.color') as string,
        accessorKey: 'color',
        id: 'color',
        type: 'color',
      },
      {
        header: t('common.active.short') as string,
        accessorKey: 'active',
        meta: {
          filterName: t('common.active.full') as string,
          tooltip: t('common.active.full') as string,
        },
        id: 'active',
        type: 'active',
      },
    ];
  }

  const handleDelete = useCallback(
    async (rows: Row<SubjectsTableType>[]) => {
      const [used, notUsed] = partition(rows, (row) => check(row.original.uuid, 'subject').isUsed);
      const uuids = notUsed.map((r) => r.original.uuid);
      await confirm({
        message: (
          <div>
            <div>{t('subject.deleteConfirm', { count: uuids.length })}</div>
            <ul>
              {notUsed.map((s) => {
                return <li key={s.original.uuid}>{s.original.name}</li>;
              })}
            </ul>
            <div>{t('subject.canNotDelete', { count: used.length })}</div>
            <ul>
              {used.map((s) => {
                return <li key={s.original.uuid}>{s.original.name}</li>;
              })}
            </ul>
          </div>
        ),
        onConfirm: async () => {
          if (uuids) {
            const response = await deleteSubjects(uuids);
            if (response.error) {
              showToast(t('subject.delete.error'), { type: 'error' });
            } else {
              showToast(t('subject.delete.success'), { type: 'success' });
            }
          }
          onRowSelectionChange({});
        },
      });
    },
    [deleteSubjects],
  );

  const createActionItems = useCallback(
    (row: Row<SubjectsTableType>): DropdownMenuItem[] => {
      const menuItems: DropdownMenuItem[] = [];
      const used = check(row.original.uuid, 'subject').isUsed;

      menuItems.push(
        {
          label: t('subject.subjectHourEnabled.tooltip') as string,
          type: 'switch',
          value: row.original.subjectHourEnabled ?? false,
          onValueChange: async () => {
            await updateCheckboxes({
              type: 'subjectHourEnabled',
              uuids: [row.original.uuid],
              value: !row.original.subjectHourEnabled,
            });
          },
        },
        {
          label: t('subject.epochEnabled.tooltip') as string,
          type: 'switch',
          value: row.original.epochEnabled ?? false,
          onValueChange: async () => {
            await updateCheckboxes({
              type: 'epochEnabled',
              uuids: [row.original.uuid],
              value: !row.original.epochEnabled,
            });
          },
        },
        {
          label: t('subject.other.tooltip') as string,
          type: 'switch',
          value: row.original.other ?? false,
          onValueChange: async () => {
            await updateCheckboxes({
              type: 'other',
              uuids: [row.original.uuid],
              value: !row.original.other,
            });
          },
        },
        {
          label: t('common.active.full') as string,
          type: 'switch',
          value: row.original.active ?? false,
          onValueChange: async () => {
            await updateCheckboxes({
              type: 'active',
              uuids: [row.original.uuid],
              value: !row.original.active,
            });
          },
        },
        {
          label: '',
          type: 'ruler',
        },
        {
          label: t('common.delete') as string,
          type: 'default',
          color: 'error',
          disabled: used,
          tooltip: used ? t('subject.usedInLesson') : undefined,
          onClick: async () => {
            if (!used) {
              await handleDelete([row]);
            }
          },
        },
      );
      return menuItems;
    },
    [handleDelete, updateCheckboxes],
  );

  const updateSubjectGroups = async ({
    uuidsToUpdate,
    subjectGroupUuid,
  }: {
    uuidsToUpdate: string[];
    subjectGroupUuid: string;
  }) => {
    onRowSelectionChange({});
    uuidsToUpdate.forEach(async (uuid) => {
      const subject = memoizedData.find((subject) => subject.uuid === uuid);
      if (!subject) return;
      await setSubjectGroup(subject.uuid, subjectGroupUuid);
    });
  };

  const createBulkEditItems = useCallback(
    (rows: Row<SubjectsTableType>[]): DropdownMenuItem[] => {
      return rows.length
        ? [
            {
              label: t('subject.subjectHourEnabled.tooltip') as string,
              type: 'default',
              subContent: [
                {
                  label: t('common.activateAll') as string,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'subjectHourEnabled',
                      uuids: rows.map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll') as string,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'subjectHourEnabled',
                      uuids: rows.map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
            {
              label: t('subject.epochEnabled.tooltip') as string,
              type: 'default',
              subContent: [
                {
                  label: t('common.activateAll') as string,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'epochEnabled',
                      uuids: rows.map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll') as string,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'epochEnabled',
                      uuids: rows.map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
            {
              label: t('subject.other.tooltip') as string,
              type: 'default',
              subContent: [
                {
                  label: t('common.activateAll') as string,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'other',
                      uuids: rows.map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll') as string,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'other',
                      uuids: rows.map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
            {
              label: t('subject.subjectGroup'),
              subContent: [
                ...(subjectGroupsData?.subjectGroups?.map((group) => {
                  return {
                    label: group.name,
                    onClick: () =>
                      updateSubjectGroups({
                        uuidsToUpdate: rows.map(({ original }) => original.uuid),
                        subjectGroupUuid: group.uuid,
                      }),
                  };
                }) ?? []),
                { type: 'ruler' },
                {
                  label: t('common.remove') as string,
                  onClick: () =>
                    updateSubjectGroups({
                      uuidsToUpdate: rows.map(({ original }) => original.uuid),
                      subjectGroupUuid: '',
                    }),
                },
              ],
            },
            {
              label: t('common.active.full') as string,
              type: 'default',
              subContent: [
                {
                  label: t('common.activateAll') as string,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'active',
                      uuids: rows.map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll') as string,
                  onClick: () => {
                    updateCheckboxes({
                      type: 'active',
                      uuids: rows.map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            },
            { type: 'ruler' },
            {
              label: t('common.delete') as string,
              type: 'default',
              color: 'error',
              onClick: async () => {
                await handleDelete(rows);
              },
            },
          ]
        : [];
    },
    [handleDelete, updateCheckboxes],
  );

  const tableColumns = useMemo(createColumns, []);

  const memoizedData = useMemo((): SubjectsTableType[] => {
    return data && data
      ? data.subjects.map((subject) => {
          const { html, label } = hexToColorOption(subject.timetableConfig?.color ?? '');
          const subjectRooms = subject.defaultRoomsConnection.edges.map((edge) => edge.node.uuid);
          const roomName =
            roomsData?.rooms
              .filter((room) => subjectRooms.includes(room.uuid))
              .map((room) => room.name)
              .join(', ') ?? '';
          return {
            ...subject,
            roomName,
            color: {
              color: html,
              colorLabel: label,
            },
          };
        })
      : [];
  }, [data, roomsData?.rooms]);

  const handleEdit = (subjectRow: Row<SubjectsTableType>) => {
    setSubject(subjectRow.original);
    setIsModalOpen(true);
  };

  return (
    <>
      <Table<SubjectsTableType>
        showBorderRadius
        showShadow
        canScroll
        loading={loadingState?.some(({ uuid, loading }) => uuid === 'all' && loading)}
        minHeight={600}
        breakpoint={null}
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        sorting={sorting}
        onSortingChange={saveSorting}
        showSort={true}
        columns={tableColumns}
        data={memoizedData}
        showSelect
        showVisibility
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('subject.title.plural')} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('subject.title.plural')}_${currentSchoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={{
          showExpertFilter: true,
          showAddButton: true,
          showPrintButton: true,
          showBulkEdit: true,
        }}
        isOnWhite={false}
        onAddClick={() => {
          setIsModalOpen(true);
        }}
        bulkEditDropdownContent={(rows) => {
          return <DropdownMenu data={createBulkEditItems(rows)} />;
        }}
        lastColWidth='80px'
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button
                hierarchy={'secondary'}
                onClick={() => {
                  handleEdit(row);
                }}
                isLoading={loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)}
                icon={<EditIcon className='small' />}
              />
              <Dropdown
                noPadding
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu data={createActionItems(row)} />
              </Dropdown>
            </ButtonGroup>
          );
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setSubject(null);
          setIsModalOpen(false);
        }}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={subject ? subject.name : (t('subject.add') as string)}
      >
        <SubjectForm
          htmlColorsInUse={colorsInUse}
          subjectUuid={subject?.uuid ?? null}
          closeForm={() => {
            setSubject(null);
            setIsModalOpen(false);
          }}
        />
      </Modal>
      <ConfirmationDialog />
    </>
  );
});
