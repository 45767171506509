import { useTranslation } from 'react-i18next';
import { Input, LazyLoader, Select, SelectOptionType } from '@bp/ui-components';
import { Form, Formik } from 'formik';
import {
  AdditionalTeachingLoadCreateInput,
  AdditionalTeachingLoadUpdateInput,
  useCreateAdditionalTeachingLoadsMutation,
  useUpdateAdditionalTeachingLoadMutation,
} from '../../../types/planung-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { Suspense } from 'react';
import { AdditionalTeachingLoadTableType } from '../Tables/AdditionalTeachingLoadTable';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { SingleValue } from 'react-select';
import { schema } from './schema';
import { CombinedError } from 'urql';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { interpreteAsNumber } from '../../../utils/helper';
import { FactorInterpretationInfo } from '../../Factors/Forms/components/FactorInterpretationInfo';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';

type AdditionalTeachingLoadFormType = {
  hoursRaw: string | undefined;
  hours: string;
  comment: string;
  teacherName: string;
  teacherUuid: string;
  subjectName: string;
  subjectUuid?: string;
  className: string;
  classUuid?: string;
};

export const AdditionalTeachingLoadForm = ({
  currentDeputat,
  closeForm,
}: {
  currentDeputat: AdditionalTeachingLoadTableType | null;
  closeForm: () => void;
}) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const [, createAdditionalTeachingLoadForm] = useCreateAdditionalTeachingLoadsMutation();
  const [, updateAdditionalTeachingLoadForm] = useUpdateAdditionalTeachingLoadMutation();

  const { teacherData, subjectData, classesData } = useLoadBasicData({ pause: false });

  const teacherOpts = useCreateSelectOptions(teacherData?.people, 'uuid', 'listName');
  const subjectOpts = useCreateSelectOptions(subjectData?.subjects, 'uuid', 'name');
  const gradeGroupsOptions = useCreateSelectOptions(classesData?.classes, 'uuid', 'shortName');

  const context = useMemorizedCacheTag('ADDITIONAL_TEACHINGLOAD');
  const handleSubmit = async (values: AdditionalTeachingLoadFormType) => {
    let result: { error?: CombinedError | undefined } = { error: undefined };

    // update
    if (currentDeputat) {
      const updateInput: AdditionalTeachingLoadUpdateInput = {
        teacher: { disconnect: {}, connect: { where: { node: { uuid: values.teacherUuid } } } },
        hours: parseFloat(values.hours),
        hoursRaw: values.hoursRaw ?? values.hours,
      };

      updateInput.comment = '';
      values.comment && (updateInput.comment = values.comment);

      updateInput.class = { disconnect: {} };
      values.classUuid &&
        (updateInput.class = { disconnect: {}, connect: { where: { node: { uuid: values.classUuid } } } });

      updateInput.subject = { disconnect: {} };
      values.subjectUuid &&
        (updateInput.subject = { disconnect: {}, connect: { where: { node: { uuid: values.subjectUuid } } } });

      result = await updateAdditionalTeachingLoadForm({ update: updateInput, uuid: currentDeputat.uuid }, context);
      if (result.error) {
        showUserErrorToast({ error: result.error });
      } else {
        showSuccessUpdateToast();
      }
      closeForm();
    }

    // create
    if (!currentDeputat) {
      const createInput: AdditionalTeachingLoadCreateInput = {
        organization: { connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } } },
        schoolYear: { connect: { where: { node: { uuid: schoolYear?.uuid } } } },
        teacher: { connect: { where: { node: { uuid: values.teacherUuid } } } },
        hours: parseFloat(values.hours),
        hoursRaw: values.hoursRaw ?? values.hours,
      };

      values.comment && (createInput.comment = values.comment);
      values.classUuid && (createInput.class = { connect: { where: { node: { uuid: values.classUuid } } } });
      values.subjectUuid && (createInput.subject = { connect: { where: { node: { uuid: values.subjectUuid } } } });

      result = await createAdditionalTeachingLoadForm({ input: createInput }, context);
      if (result.error) {
        showUserErrorToast({ error: result.error });
      } else {
        showSuccessCreateToast();
      }
      closeForm();
    }
  };

  const initialValues = {
    teacherUuid: currentDeputat?.teacherUuid ?? '',
    teacherName: currentDeputat?.teacherName ?? '',
    subjectUuid: currentDeputat?.subjectUuid ?? '',
    subjectName: currentDeputat?.subjectName ?? '',
    classUuid: currentDeputat?.classUuid ?? '',
    className: currentDeputat?.className ?? '',
    comment: currentDeputat?.comment ?? '',
    hours: currentDeputat?.hours ?? '',
    hoursRaw: currentDeputat?.hoursRaw ?? currentDeputat?.hours,
  };

  return (
    <Suspense fallback={<LazyLoader />}>
      <Formik<AdditionalTeachingLoadFormType>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {(formik) => (
          <Form>
            <div className='tks__row'>
              <div className='tks__col col-xs-11 no-gap'>
                <div className='form-row'>
                  <Select
                    name={`teacherUuid`}
                    className={'full'}
                    isSearchable
                    label={t('persons.title.singular')}
                    value={teacherOpts.find((to) => to.value === formik.values?.teacherUuid)}
                    options={teacherOpts}
                    onChange={async (option) => {
                      const opt = option as SingleValue<SelectOptionType>;
                      await formik.setFieldTouched(`teacherUuid`, true);
                      await formik.setFieldValue(`teacherUuid`, opt?.value);
                    }}
                    error={formik.errors.teacherUuid}
                  />
                </div>

                <div className='form-row'>
                  <Select
                    isClearable
                    isSearchable
                    name={`subjectUuid`}
                    className={'full'}
                    value={subjectOpts.find((to) => to.value === formik.values?.subjectUuid)}
                    label={t('subject.title.singular')}
                    options={subjectOpts}
                    onChange={async (option) => {
                      const opt = option as SingleValue<SelectOptionType>;
                      await formik.setFieldTouched(`subjectUuid`, true);
                      await formik.setFieldValue(`subjectUuid`, opt?.value);
                    }}
                  />
                </div>
                <div className='tks__grid'>
                  <div className='tks__row'>
                    <div className='tks__col  col-xs-10'>
                      <Select
                        isClearable
                        isSearchable
                        name={`classUuid`}
                        className={'half'}
                        label={t('classes.title.singular')}
                        value={gradeGroupsOptions.find((to) => to.value === formik.values?.classUuid)}
                        options={gradeGroupsOptions}
                        onChange={async (option) => {
                          const opt = option as SingleValue<SelectOptionType>;
                          await formik.setFieldTouched(`classUuid`, true);
                          await formik.setFieldValue(`classUuid`, opt?.value);
                        }}
                      />
                    </div>

                    <div className='tks__col  col-xs-2'>
                      <Input
                        className={'half'}
                        value={formik.values.hoursRaw}
                        name={`hours`}
                        type={'text'}
                        onChange={async (e) => {
                          await formik.setFieldValue('hoursRaw', e.target.value);
                          await formik.setFieldValue('hours', interpreteAsNumber(e.target.value));
                          await formik.setFieldTouched('hours');
                        }}
                        label={t('common.hours')}
                        error={formik.errors.hours}
                      />
                    </div>

                    <div className='tks__col col-xs-3 align-center'>
                      <FactorInterpretationInfo value={interpreteAsNumber(formik.values.hours)} />
                    </div>
                  </div>
                  <div className='form-row'>
                    <Input
                      className={'full'}
                      name={`comment`}
                      value={formik.values.comment}
                      onChange={formik.handleChange}
                      label={t('common.comment')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ModalBottomButtons
              closeButton={{
                text: t('common.cancel'),
                callback: () => {
                  formik.resetForm();
                  closeForm();
                },
              }}
              submitButton={{ disabled: formik.isSubmitting || !formik.dirty }}
              isLoading={formik.isSubmitting}
              errors={formik.errors}
            />
          </Form>
        )}
      </Formik>
    </Suspense>
  );
};
