import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Logo, Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import styles from './AboutModal.module.scss';
import classNames from 'classnames';
import { useAuth } from 'react-oidc-context';
import { ORGANIZATION_CLAIM, OrganizationClaim } from '@bp/pim-auth-constants';

export type AboutModalProps = {
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
};

type PimHealth = {
  name?: string;
  version?: string;
  status: string;
  ok: boolean;
  neo4j?: {
    name?: string;
    ok: boolean;
    status?: string;
    message?: string;
  };
};

type ApolloHealth = {
  status: string;
  ok: boolean;
  version?: string;
};

export const AboutModal = (props: AboutModalProps) => {
  const { t } = useTranslation();

  const auth = useAuth();

  const [pimGraphHealth, setPimGraphHealth] = useState<ApolloHealth | null>({
    status: 'ERROR',
    ok: false,
  });

  const [pimHealth, setPimHealth] = useState<PimHealth | null>({
    status: 'ERROR',
    ok: false,
  });

  useEffect(() => {
    fetch(import.meta.env.VITE_APP_BACKEND_URI + '/_health')
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        throw resp;
      })
      .then((data) => setPimGraphHealth(data))
      .catch(() => {
        setPimGraphHealth({ status: 'ERROR', ok: false });
      });

    fetch(import.meta.env.VITE_APP_AUTH_SERVER_URI + '/_health')
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        throw resp;
      })
      .then((data) => setPimHealth(data))
      .catch(() => {
        setPimHealth({ status: 'ERROR', ok: false });
      });
  }, []);

  let buildTime: string | undefined = import.meta.env.VITE_APP_BUILD_TIME;
  if (buildTime === undefined) {
    buildTime = 'hmr/live';
  }

  const organizationClaim = auth?.user?.profile[ORGANIZATION_CLAIM] as OrganizationClaim | undefined;

  return (
    <Modal
      className={styles['about-modal']}
      title={t('auth.app-title')}
      subtitle={(APP_VERSION ?? 'dev') + '/' + import.meta.env.VITE_APP_ENVIRONMENT + ' (' + buildTime + ')'}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={props.state}
      onRequestClose={() => props.setState(false)}
    >
      <div className={styles.help}>
        <div>{t('about.help')}</div>
        <div className={styles.contact}>
          <div>+49 (0) 711 – 50 87 00 00</div>
          <div>support@tks.eu</div>
          <br />
          <div>
            Das Handbuch und weitere Hilfestellungen finden Sie&nbsp;
            <a href={'https://r.tks.eu/planung-doku'} target={'_blank'} rel='noreferrer'>
              unter diesem Link
            </a>
          </div>
          <br />
          <div>
            Eine Übersicht über die Neuerungen finden Sie im&nbsp;
            <a href={'https://r.tks.eu/changelog'} target={'_blank'} rel='noreferrer'>
              Changelog
            </a>
          </div>
        </div>
      </div>

      <div className={styles.info}>
        <div className={styles.address}>
          <Logo className={styles.logo} type='tk' collapsed={false} />
          <div className={styles.data}>
            <div>TK-Schulsoftware GmbH & Co. KG</div>
            <div>Rosenwiesstr. 17</div>
            <div>70567 Stuttgart</div>
          </div>
        </div>
        <div className={styles.status}>
          <div className={styles.login}>
            <div>TK-Login (Version: {pimHealth?.version ?? 'unknown'})</div>
            <div className={classNames(styles.status, styles[pimHealth?.status ?? 'red'])}>
              {pimHealth?.status === 'green' ? 'Online' : 'Offline'}
            </div>
          </div>
          <div className={styles.server}>
            <div>Server (Version: {pimGraphHealth?.version ?? 'unknown'})</div>
            <div className={classNames(styles.status, styles[pimGraphHealth?.status ?? 'red'])}>
              {pimGraphHealth?.status === 'green' ? 'Online' : 'Offline'}
            </div>
          </div>
          <div className={styles.server}>
            <div>Organisation (UUID: {organizationClaim?.uuid ?? 'unknown'})</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
