import {
  use_TeachingBlockVersionsQuery,
  useDeleteClassesMutation,
  useDeleteLessonClassesMutation,
  useUpdateTeachingBlockVersionsMutation,
} from '../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from './useMemorizedCacheTag';

export const useDeleteClasses = () => {
  const teachingBlockVersionContext = useMemorizedCacheTag('TEACHINGBLOCK_VERSIONS');
  const [, updateTbVersion] = useUpdateTeachingBlockVersionsMutation();
  const [{ data: teachingBlockVersions }] = use_TeachingBlockVersionsQuery();
  const [, deleteClasses] = useDeleteClassesMutation();
  const [, deleteLC] = useDeleteLessonClassesMutation();

  const updateTeachingBlock = async (uuid: string) => {
    const v = teachingBlockVersions?.teachingBlockVersions.find((tv) =>
      tv.classesConnection.edges.map((c) => c.node.uuid).includes(uuid),
    );
    return await updateTbVersion({
      where: {
        uuid: v?.uuid ?? '',
      },
      update: {
        classes: [
          {
            disconnect: [
              {
                where: {
                  node: {
                    uuid: uuid,
                  },
                },
              },
            ],
          },
        ],
      },
    });
  };

  const deleteLessonClass = async (uuid: string) => {
    return await deleteLC({ where: { class: { uuid: uuid } } });
  };

  const deleteClass = async (uuid: string) => {
    await updateTeachingBlock(uuid);
    await deleteLessonClass(uuid);
    return await deleteClasses({ uuid }, teachingBlockVersionContext);
  };
  return { deleteClass, updateTeachingBlock, deleteLessonClass };
};
