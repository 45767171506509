import { Button, ProfileIcon, QuestionmarkCircleIcon, Select, SelectOptionType } from '@bp/ui-components';
import styles from './AppHeader.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSchoolYearsQuery } from '../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { SingleValue } from 'react-select';
import { observer } from 'mobx-react-lite';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useMemo, useState } from 'react';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { AboutModal } from '../AboutModal/AboutModal';

export const AppHeader = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pimAuthClaims } = useAuthClaims();

  const [showAboutModal, setShowAboutModal] = useState<boolean>(false);

  const profile = pimAuthClaims.getProfile();
  const name = ((profile?.firstName ?? '') + ' ' + (profile?.lastName ?? '')).trim() || 'unknown person';
  const school = profile?.organization?.name ?? '';
  const userConfig = useUserConfigContext();

  const context = useMemorizedCacheTag('SCHOOL_YEAR');

  const [{ data }] = useSchoolYearsQuery({
    variables: {
      organizationUuid: profile?.organization?.uuid ?? '',
    },
    context: context,
  });

  const schoolYears = useMemo(() => {
    return data?.schoolYears;
  }, [data]);

  const schoolYearOptions = useCreateSelectOptions(data?.schoolYears, 'uuid', 'name');

  const updateUserConfig = (uuid: string) => {
    const schoolYear = schoolYears?.find((schoolYear) => {
      return schoolYear.uuid === uuid;
    });
    userConfig?.setSchoolYear(schoolYear ?? null);
  };

  return (
    <header className={styles['app-header']} data-cy='headerWrapper'>
      <div className={styles.container}>
        <div className={styles.left}>
          <span className={styles.label}>{t('common.schoolYear')}</span>
          <Select
            className={styles.select}
            name='year'
            isMulti={false}
            dense
            disabled={(schoolYears && !schoolYears.length) || !schoolYears}
            onChange={(option) => {
              const opt = option as SingleValue<SelectOptionType>;
              updateUserConfig(opt?.value as string);
            }}
            options={schoolYearOptions}
            value={
              userConfig.selectedSchoolYear?.uuid
                ? schoolYearOptions.find((s) => s.value === userConfig.selectedSchoolYear?.uuid)
                : { value: '', label: t('common.unavailable') }
            }
          />
        </div>
        <div className={styles.center}>{school}</div>
        <div className={styles.right}>
          <Button
            className='ml-3'
            hierarchy='tertiary'
            icon={<QuestionmarkCircleIcon onClick={() => setShowAboutModal(true)} />}
          />
          <Button
            name='profile'
            className='ml-2'
            hierarchy='tertiary'
            icon={<ProfileIcon />}
            onClick={() => navigate(`/${t('routes.profile.slug')}`)}
          >
            {profile ? <p>{name}</p> : <p>Logged-in, but no profile</p>}
          </Button>
        </div>
      </div>

      <AboutModal state={showAboutModal} setState={(value) => setShowAboutModal(value)} />
    </header>
  );
});
