import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { AddIcon, Button, Checkbox, DeleteIcon, Input } from '@bp/ui-components';
import { v4 } from 'uuid';
import { CurriculaTableDataType } from '../types';

export const CurriculumPersonsForm = () => {
  const formik = useFormikContext<CurriculaTableDataType>();
  const { t } = useTranslation();

  return (
    <FieldArray name={'teachers'}>
      {(arrayHelpers) => {
        return (
          <>
            <div className='form-group tks__grid'>
              {formik.values.teachers?.map((person, index) => {
                return (
                  <div key={person.uuid} className='tks__row'>
                    <div className='tks__col col-xs-9 no-gap'>
                      <Input
                        onChange={formik.handleChange}
                        name={`teachers.${index}.name`}
                        value={formik.values.teachers?.[index].name ?? t('common.subject_teacher')}
                        label={t('common.description')}
                      />
                    </div>

                    <div className='tks__col col-xs-3 no-gap'>
                      <Checkbox
                        name={`teachingLoad-${index}`}
                        checked={!!formik.values.teachers?.[index].teachingLoad}
                        label={t('lesson.basics.isTeachingLoadEnabled.full')}
                        onChange={async (event) => {
                          await formik.setFieldValue(`teachers.${index}.teachingLoad`, event.target.checked, true);
                          await formik.setFieldTouched(`teachers.${index}.teachingLoad`, true);
                        }}
                      />
                      <Checkbox
                        name={`present-${index}`}
                        checked={!!formik.values.teachers?.[index].present}
                        label={t('lesson.person.present')}
                        onChange={async (event) => {
                          await formik.setFieldValue(`teachers.${index}.present`, event.target.checked, true);
                          await formik.setFieldTouched(`teachers.${index}.present`, true);
                        }}
                      />
                    </div>

                    <div className='tks__col col-xs-1'>
                      <Button
                        className='mt-6 align-end'
                        hierarchy='tertiary'
                        icon={
                          <DeleteIcon
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                );
              })}
              <Button
                hierarchy={'tertiary'}
                icon={<AddIcon />}
                disabled={!formik.values.subject}
                onClick={async () => {
                  if (formik.values.teachers && formik.values.teachers.length > 0) {
                    for (const index in formik.values.teachers) {
                      await formik.setFieldTouched(`teachers.${index}`);
                    }
                  }
                  arrayHelpers.push({ uuid: v4(), teachingLoad: true, present: true });
                }}
              >
                {t('common.person_one')}
              </Button>
            </div>
          </>
        );
      }}
    </FieldArray>
  );
};
