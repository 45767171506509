import { Form, Formik, FormikHelpers } from 'formik';
import { epochSchema } from './validation/schema';
import { LazyLoader } from '@bp/ui-components';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { EpochLessonUnitForm } from './LessonUnits/EpochLessonUnitForm';
import FormBlockHeader from '../../Form/FormBlockHeader';
import { LessonFormType } from '../types';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useDeputateLessons } from '../hooks/useDeputateLessons';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { EpochBasicsForm } from './Basics/EpochBasicsForm';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';

type EpochPlanFormProps = {
  closeForm: () => void;
  defaultInitialValues: Partial<LessonFormType>;
  lessonUuid?: string | null;
};

export const EpochForm = observer(({ closeForm, defaultInitialValues, lessonUuid }: EpochPlanFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { createLesson, updateLesson, queryLessonsData, lessonsData } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
  });

  const lesson = queryLessonsData?.lessons.find((lesson) => {
    return lesson.uuid === lessonUuid;
  });

  const status = lessonsData.find((lesson) => {
    return lesson.uuid === lessonUuid;
  })?.editStatus;

  const initialValues: LessonFormType = lesson
    ? {
        uuid: lesson.uuid,
        comment: lesson.comment,
        lessonUnit: lesson.lessonUnitConnection.edges.map(({ node }) => {
          return { ...node, lessonUnitType: node.subjectContainer?.uuid ? 'epoch' : 'subjectHour' };
        }),
        lessonClasses: lesson.lessonClassesConnection.edges.map((lc) => {
          return { ...lc.node };
        }),
        subject: lesson.subject,
        subTitle: lesson.subTitle,
      }
    : { ...defaultInitialValues };

  const handleSubmit = async (values: LessonFormType, formikHelpers: FormikHelpers<LessonFormType>) => {
    let result: { error?: unknown; success?: boolean };
    if (!values.uuid) {
      const createResult = await createLesson(values);
      result = createResult[0];
      if (result.success) {
        showSuccessCreateToast();
      }
    } else {
      result = await updateLesson(values);
      if (result.success) {
        showSuccessUpdateToast();
      }
    }
    if (result.error) {
      showUserErrorToast({ error: result.error });
    }
    formikHelpers.resetForm();
    closeForm();
  };

  return (
    <Suspense fallback={<LazyLoader transparent forceHeight='40vh' />}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={epochSchema}>
        {(formik) => {
          const currentClass =
            formik.values.lessonClasses && formik.values.lessonClasses.length === 1
              ? formik.values.lessonClasses[0].class
              : null;
          return (
            <Form>
              <div className='form-block'>
                <div className={'form-group'}>
                  <EpochBasicsForm key={formik.values.subject?.uuid} status={status} filterSubjectContainers={true} />
                </div>
              </div>

              <div className='form-block'>
                <FormBlockHeader title={t('subject.subjectHour')} />
                <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                  <EpochLessonUnitForm disabled={status === 'blocked'} key={formik.values.subject?.uuid} />
                </Suspense>
              </div>

              <ModalBottomButtons
                submitButton={{
                  disabled: !currentClass,
                }}
                closeButton={{
                  callback: () => {
                    formik.resetForm();
                    closeForm();
                  },
                }}
                isLoading={formik.isSubmitting}
                errors={formik.errors}
              />
            </Form>
          );
        }}
      </Formik>
    </Suspense>
  );
});
