import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  SortDirection,
  useClassesListQuery,
  useTeachingLoadFactorsQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { DeputateLessonsTable } from '../../../../components/Lessons/Tables/DeputateLessonsTable';
import { LessonStats } from '../../../../components/Lessons/LessonStats/LessonStats';
import { useDeputateLessons } from '../../../../components/Lessons/hooks/useDeputateLessons';
import { Page } from '../../../../components/Page/Page';
import { hexToColorOption } from '../../../../utils/colorUtils';
import { v4 } from 'uuid';

export const ClassLessonsPage: FC = observer(() => {
  const { classUuid } = useParams();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const { pimAuthClaims } = useAuthClaims();

  const { lessonsData: allLessonData } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
    querySort: [{ subjectName: SortDirection.Asc }, { teachersSort: SortDirection.Asc }],
  });

  const lessonsData = allLessonData.filter((lesson) => {
    return lesson.classes.some((c) => c.uuid === classUuid);
  });

  const [{ data: classData }] = useClassesListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: schoolYear?.uuid ?? '',
      classUuid: classUuid,
    },
    context: useMemorizedCacheTag('CLASSES'),
  });

  const currentClass = classData?.classes[0];

  const { html, label } = hexToColorOption(currentClass?.timetableConfig?.color ?? '');

  const [{ data: factorsData }] = useTeachingLoadFactorsQuery({
    variables: {
      where: {
        schoolYear: { uuid: schoolYear?.uuid },
      },
    },
    context: useMemorizedCacheTag('TEACHINGLOADFACTOR'),
  });

  return (
    <Page title={currentClass?.name} colorHint={{ color: html, colorLabel: label }}>
      {currentClass && (
        <div className='tks__grid mt-4'>
          <div className='tks__row row-xs-4 mb-8'>
            <LessonStats lessonsData={lessonsData} factorsData={factorsData} />
          </div>
          <div className='tks__row'>
            <DeputateLessonsTable
              isClassContext={true}
              userConfigKey={'subject-table-classes'}
              lessonsData={lessonsData}
              showAddButton={true}
              contextUuid={currentClass?.uuid}
              defaultInitialValues={{
                teachingLoadEnabled: true,
                lessonClasses: [
                  {
                    uuid: v4(),
                    groups: [],
                    class: {
                      uuid: currentClass?.uuid ?? '',
                    },
                  },
                ],
              }}
            />
          </div>
        </div>
      )}
    </Page>
  );
});
