import { Grid, GridColumn, GridRow } from '@bp/ui-components';
import { CardInfo } from '../../../../../components/TeachingBlockGrid/CardInfo/CardInfo';
import { GridActionArea } from '../../../../../components/TeachingBlockGrid/GridActionArea/GridActionArea';
import { GridMenuBar } from '../../../../../components/TeachingBlockGrid/GridMenuBar/GridMenuBar';
import { TeachingBlockGrid } from '../../../../../components/TeachingBlockGrid/TeachingBlockGrid';
import { observer } from 'mobx-react-lite';

export type TeachingBlockVersionBoardViews = 'classes' | 'teachers';

export const TeachingBlockVersionBoard = observer(() => {
  return (
    <div className='board-wrapper' id='teaching-block-grid'>
      <Grid>
        <GridRow spacingBottom='s'>
          <GridColumn width={8}>
            <GridMenuBar />
            <GridActionArea />
          </GridColumn>
          <GridColumn width={4}>
            <CardInfo />
          </GridColumn>
        </GridRow>
        <GridRow className='pinboard' spacingTop='s'>
          <TeachingBlockGrid />
        </GridRow>
      </Grid>
    </div>
  );
});
