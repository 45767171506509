import styles from './TimetableVersionTopBar.module.scss';
import { ArrowLeftIcon, Button, LockIcon, Tooltip, UnlockIcon } from '@bp/ui-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavigationTabs } from '../../NavigationTabs/NavigationTabs';
import { useTimetableStore } from '../../TimetableGrid/TimetableProvider';

export const TimetableVersionTopBar = observer(
  ({ versionUuid, baseSlug }: { versionUuid: string; baseSlug: string }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const pinboardStore = useTimetableStore();

    return (
      <div className={styles['timetable-version-top-bar']}>
        <div className={styles.header}>
          <Button
            className={styles.back}
            icon={<ArrowLeftIcon />}
            hierarchy='tertiary'
            onClick={() => navigate('/' + t('routes.timetable.slug') + '/' + t('routes.plan.slug'), { replace: true })}
          />
          {pinboardStore.readonly && <Tooltip content={t('timetable.locked')}>{<LockIcon />}</Tooltip>}
          {pinboardStore.temporarilyEditable && (
            <Tooltip content={t('timetable.lockedEditable')}>{<UnlockIcon color={'var(--color-warning)'} />}</Tooltip>
          )}

          <div
            className={styles.headline}
          >{`${pinboardStore.currentVersion?.timetableName} - ${t('timetableVersion.title.singular')} ${pinboardStore.currentVersion?.versionName}`}</div>
          {pinboardStore.currentVersion?.version && (
            <div className={styles.description}>({pinboardStore.currentVersion.version})</div>
          )}
          {pinboardStore.currentVersion?.description && (
            <div className={styles.description}>
              <p>({pinboardStore.currentVersion.description})</p>
            </div>
          )}
        </div>

        <NavigationTabs
          className={styles['tab-bar']}
          tabs={[
            {
              title: t('timetable.timetableData'),
              path: `/${baseSlug}/${t('routes.versions.slug')}/${versionUuid}/${t('routes.versionPages.data.slug')}/${t('routes.versionsFilter.class.slug')}`,
              active: location.pathname.includes(t('routes.versionPages.data.slug')),
            },
            {
              title: t('pinboard.title'),
              path: `/${baseSlug}/${t('routes.versions.slug')}/${versionUuid}/${t('routes.versionPages.board.slug')}`,
            },
          ]}
        />
      </div>
    );
  },
);
