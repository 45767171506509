import styles from './GridHeader.module.scss';
import { GridDateType } from '../types';

type GridHeaderWeeksProps = {
  weeks: Map<string, GridDateType[]>;
};

export const GridHeaderWeeks = ({ weeks }: GridHeaderWeeksProps) => {
  return (
    <div className={styles['grid-header-weeks']}>
      {[...weeks.values()].map((week, i) => {
        const number = week[0].weekNumber;
        return (
          <div key={week + '_' + i} className={styles.week}>
            {number}
          </div>
        );
      })}
    </div>
  );
};
