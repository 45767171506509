import { FC } from 'react';
import { ConstraintsFormInitialValuesType } from '../../Constraints/types';
import { useConstraints } from '../../../hooks/useConstraints';
import styles from './ConstraintModal.module.scss';
import { ArrowHeadRightIcon } from '@bp/ui-components';

export type ConstraintModalItemProps = {
  title: string;
  constraintsData: ConstraintsFormInitialValuesType;
};

export const ConstraintModalItem: FC<ConstraintModalItemProps> = ({ title, constraintsData }) => {
  const constraints = useConstraints(constraintsData);

  return (
    <div className={styles['constraint-modal-item']}>
      <div className={styles.title}>{title}</div>
      <div className={styles.constraints}>
        {constraints.map((c, i) => {
          return (
            <div className={styles.constraint} key={i}>
              <ArrowHeadRightIcon className='small mb-2 mr-2' /> {c.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};
