import { CheckIcon, EmptyState } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import {
  SortDirection,
  use_DeleteTeachingBlockAvailabilityExclusionsMutation,
  use_TeachingBlockAvailabilityExclusionsQuery,
} from '../../../types/planung-graphql-client-defs';
import { ExclusionTimeframeItem } from '../../ExclusionTimeframe/ExclusionTimeframeItem';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { showSuccessDeleteToast, showUserErrorToast } from '../../../utils/toast';
import { useConfirm } from '../../../hooks/useConfirm';

export const Exclusions = ({ uuids, onEdit }: { uuids: string[]; onEdit: (uuid: string) => void }) => {
  const { t } = useTranslation();

  const context = useMemorizedCacheTag('TEACHING_BLOCK_AVAILABILITY_EXCLUSION');

  const [{ data }] = use_TeachingBlockAvailabilityExclusionsQuery({
    variables: {
      options: {
        sort: [{ from: SortDirection.Asc }],
      },
    },
    context: context,
  });

  const [, remove] = use_DeleteTeachingBlockAvailabilityExclusionsMutation();

  const exclusions =
    data?.teachingBlockAvailabilityExclusions.filter((e) => {
      return uuids.includes(e.uuid);
    }) ?? [];

  const { confirm, ConfirmationDialog } = useConfirm();

  async function onDelete(uuid: string) {
    const confirmed = await confirm({ message: t('teachingBlockVersion.exclusion.deleteConfirm') });
    if (confirmed) {
      const response = await remove({ where: { uuid: uuid } }, context);
      if (response.error) {
        showUserErrorToast({ error: response.error });
      } else {
        showSuccessDeleteToast();
      }
    }
  }

  return (
    <>
      {exclusions.length === 0 ? (
        <EmptyState
          icon={<CheckIcon className='bordered' />}
          size='small'
          iconColor='var(--color-success)'
          title={t('teachingBlockVersion.exclusion.isFullyAvailable', {
            type: t('classes.title.singular'),
          })}
          subtitle={t('teachingBlockVersion.exclusion.isFullyAvailableHint')}
          padding='xl'
        />
      ) : (
        <>
          {exclusions.map((item) => {
            return <ExclusionTimeframeItem key={item.uuid} item={item} onEdit={onEdit} onDelete={onDelete} />;
          })}
        </>
      )}
      <ConfirmationDialog />
    </>
  );
};
