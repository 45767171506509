import { useMemo } from 'react';
import { SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';

export function useCreateSelectOptions<T, K extends keyof T>(
  data: T[] | undefined,
  value: K,
  label: K,
  unavailable = true,
) {
  const { t } = useTranslation();
  return useMemo(() => {
    const result = data
      ? (data.map((d) => {
          return { value: d[value] ?? '', label: d[label] ?? '' };
        }) as SelectOptionType[])
      : ([] as SelectOptionType[]);
    if (unavailable && (!data || data?.length === 0)) {
      result.push({
        value: null,
        label: t('common.unavailable'),
      });
    }
    return result;
  }, [data, value, label]);
}
