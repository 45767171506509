import React, { useMemo, useState } from 'react';
import { Modal, Tab, TabProps } from '@bp/ui-components';
import { DeputateLessonForm } from '../../../../components/Lessons/Forms/DeputateLessonForm';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { DeputateLessonsTable } from '../../../../components/Lessons/Tables/DeputateLessonsTable';
import { useDeputateLessons } from '../../../../components/Lessons/hooks/useDeputateLessons';

export type LessonAssignment = 'assigned' | 'unassigned' | 'all';
export type OverviewTabsType = Omit<Pick<TabProps, 'tabs'>['tabs'][0], 'value'> & { value: LessonAssignment }; // refactor if TabItemProps is exported

export const LessonOverviewPage = observer(() => {
  const [currentLessonUuid, setCurrentLessonUuid] = useState<string | null>(null);
  const [modalIsOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const { pimAuthClaims } = useAuthClaims();

  const handleClose = () => {
    setCurrentLessonUuid(null);
    setIsModalOpen(false);
  };

  const { lessonsData } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
  });

  const assignedData = lessonsData.filter((l) => l.teachers.length > 0);
  const unassignedData = lessonsData.filter((l) => l.teachers.length === 0);

  const overviewTabs: OverviewTabsType[] = useMemo(() => {
    return [
      {
        title: t('lesson.all'),
        value: 'all',
        content: (
          <DeputateLessonsTable
            showAddButton={true}
            userConfigKey={'lessons-table-overview-all'}
            lessonsData={lessonsData}
          />
        ),
        disabled: false,
      },
      {
        title: t('lesson.assigned'),
        value: 'assigned',
        content: (
          <DeputateLessonsTable
            showAddButton={true}
            userConfigKey={'lessons-table-overview-assigned'}
            lessonsData={assignedData ?? []}
          />
        ),
        disabled: false,
      },
      {
        title: t('lesson.unassigned'),
        value: 'unassigned',
        content: (
          <DeputateLessonsTable
            showAddButton={true}
            userConfigKey={'lessons-table-overview-unassigned'}
            lessonsData={unassignedData ?? []}
          />
        ),
        disabled: false,
      },
    ];
  }, [assignedData, lessonsData, unassignedData]);

  return (
    <>
      <Tab tabs={overviewTabs} />
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleClose}
          title={currentLessonUuid ? t('lesson.edit') : t('lesson.add')}
        >
          <DeputateLessonForm
            closeForm={handleClose}
            lessonUuid={currentLessonUuid}
            defaultInitialValues={{ timetableEnabled: true }}
          />
        </Modal>
      )}
    </>
  );
});
