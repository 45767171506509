import { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Chip,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  ImportIcon,
  LazyLoader,
  LockIcon,
  Modal,
  Row,
  showToast,
  Table,
  TableColumns,
  Tooltip,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { LessonCard } from '../../LessonCard/LessonCard';
import { TimetableCardsForm } from '../../TimetableVersion/TimetableVersionLessonCards/TimetableCardsForm';
import { TimetableLessonForm } from '../Forms/TimetableLessonForm';
import { ImportLessonsToTimetableVersionModal } from './LessonsImport/ImportLessonsToTimetableVersionModal';
import { observer } from 'mobx-react-lite';
import { useTimetableLessons } from '../hooks/useTimetableLessons';
import { LessonInfo } from '../../../utils/calculateLessonInfos';
import { useConfirm } from '../../../hooks/useConfirm';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useFilterTags } from '../../../hooks/useFilterTags';
import { CardType } from '../../TimetableVersion/graphql/types';
import { useGetTimetableCardType } from '../../../hooks/useGetTimetableCardType';
import { useTimetableStore } from '../../TimetableGrid/TimetableProvider';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { use_TimetableVersionsQuery } from '../../../types/planung-graphql-client-defs';
import { gridCardsStore } from '../../../pages/Timetable/Plan/TimetableVersion/TimetableVersion';
import { RowSelectionState } from '@tanstack/react-table';

export type CardsInfoLesson = {
  uuid: string;
  groups: { uuid: string; shortName: string }[];
  usedDivision?: {
    groupsConnection: {
      edges: Array<{ node: { uuid: string; name: string } }>;
    };
    uuid: string;
  } | null;
  classes?: Array<{ uuid: string; name: string; shortName: string }>;
  teachers?: Array<{ uuid: string; name: string; color: string }>;
  subject?: string;
};

export type CardsInfo = {
  uuid: string;
  type: 'FS' | 'Container' | 'Plan';
  typeLabel: string;
  typeLabelShort: string;
  cardLabel: string;
  cardLabelShort: string;
  colors: string[];
  counter: number;
  duration: number;
  lesson?: CardsInfoLesson | null;
};

export type ClassInterfaceType = {
  uuid?: string;
  grade?: number;
  gradeGroup?: { uuid?: string; name: string; shortName?: string } | null;
  name: string;
  shortName?: string;
};

export type LessonTableType = {
  uuid: string;
  subject: string;
  subjectShortName: string;
  classSort?: string;
  teacherSort?: string;
  subjectUuid: string;
  editStatus?: 'unused' | 'inVersion' | 'placedCards' | 'blocked' | 'used';
  cardsInfos?: {
    defaultCards: CardsInfo[][];
    subjectContainerCards: CardsInfo[][];
  };
  classes: Array<
    ClassInterfaceType & {
      groups?: Array<{ uuid: string; shortName?: string; name: string; classFraction?: number }>;
    }
  >;
  classesUuids: string[];
  teachers: {
    uuid: string;
    name?: string | null;
    color?: string | null;
    teachingLoadEnabled: boolean;
    presenceEnabled: boolean;
    teachingLoadHours?: number;
  }[];
  teacherUuids: string[];
  elective: boolean;
  teachingLoadEnabled: boolean;
  timetableEnabled: boolean;
  onlyInTimetableVersion: boolean;
  lessonUnits?: {
    uuid: string;
    duration: number;
    count: number;
    subjectContainer?: { uuid: string; name: string; shortName: string } | null;
  }[];
  lessonInfo?: LessonInfo;
  groupClassNames?: string;
  teachingLoadFactors?: string;
  placedCardsCount?: number | null;
  isEpochPlan?: boolean;
  curriculumUuid?: string;
  schoolYearUuid?: string;
  rooms?: { uuid: string; name: string }[];
  roomsUuids?: string[];
  cardInfoLesson?: CardsInfoLesson;
};

type LessonTableProps = {
  currentClassUuid?: string;
  versionUuid: string;
};

export const TimetableVersionLessonsTable: FC<LessonTableProps> = observer(({ currentClassUuid, versionUuid }) => {
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const store = useTimetableStore();

  const { memoFilter: dataFilter, filterTags } = useFilterTags(versionUuid);
  const { pimAuthClaims } = useAuthClaims();

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('lessons-table', {
    elective: false,
    teachingLoadEnabled: false,
    timetableEnabled: false,
    rooms: false,
  });
  const { sorting, saveSorting } = useColumnsSort(`lessons-table-${versionUuid}`);

  const [currentLessonUuid, setCurrentLessonUuid] = useState<string | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [lessonCardsModalOpen, setLessonCardsModalOpen] = useState<boolean>(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();
  const [refresh, setRefresh] = useState(false);

  const {
    detachLessons,
    deleteCardsAndLessons,
    deactivateLessons,
    assignLessons,
    updateCheckboxes: update,
    duplicateLesson,
    loadingState,
    queryLessonsData,
    lessonsData,
    dispatch,
  } = useTimetableLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
    versionUuid: versionUuid,
  });

  const updateCheckboxes = useCallback(
    async ({
      type,
      uuids,
      value,
    }: {
      type: 'teachingLoadEnabled' | 'timetableEnabled' | 'elective';
      uuids: string[];
      value: boolean;
    }) => {
      await update({
        type,
        uuids,
        value,
      });
    },
    [update],
  );

  const { confirm, ConfirmationDialog } = useConfirm();

  const handleDelete = async (lessons: LessonTableType[]) => {
    const placedCards = lessons.some((l) => l.placedCardsCount && l.placedCardsCount > 0);
    const text = placedCards
      ? t('lesson.confirm.delete.hasPlacedCards', { count: lessons.length })
      : t('lesson.confirm.delete.default', { count: lessons.length });

    const confirmed = await confirm({ message: text });

    if (confirmed) {
      const response = await deleteCardsAndLessons(lessons.map((l) => l.uuid));
      if (response.error) {
        showToast(t('lesson.message.delete.error'), { type: 'error' });
      } else {
        showToast(t('lesson.message.delete.success'), { type: 'success' });
      }
    }
  };

  const handleDuplicate = async (_lessons: LessonTableType[]) => {
    const lessons = _lessons.filter((l) => !l.isEpochPlan);
    const versionLessons = lessons.some((l) => !l.onlyInTimetableVersion);

    const uuids = lessons.map((l) => l.uuid);

    if (loadingState.some(({ uuid, loading }) => loading && uuids.includes(uuid))) {
      return;
    }

    const confirmed = await confirm({
      title: versionLessons ? t('timetableVersion.editInVersion') : t('common.duplicate'),
      message: versionLessons
        ? t('lesson.confirm.editInVersion.confirm', { count: lessons.length })
        : t('lesson.confirm.duplicate.confirm', { count: lessons.length }),
    });

    if (confirmed) {
      const response = await duplicateLesson(uuids, versionLessons);
      if (response) {
        if (response.error) {
          showToast(t('lesson.message.duplicate.error'), { type: 'error' });
        } else {
          showToast(t('lesson.message.duplicate.success'), { type: 'success' });
        }
      } else {
        showToast(t('lesson.message.duplicate.error'), { type: 'error' });
      }
    }
  };

  const handleDeactivate = useCallback(
    async (lessons: LessonTableType[]) => {
      const placedCards = lessons.some((l) => l.placedCardsCount && l.placedCardsCount > 0);

      const text = placedCards
        ? t('lesson.confirm.deactivate.hasPlacedCards', { count: lessons.length })
        : t('lesson.confirm.deactivate.default', { count: lessons.length });

      const confirmed = await confirm({ message: text });
      if (confirmed) {
        const response = await deactivateLessons(lessons.map((l) => l.uuid));
        if (response.error) {
          showToast(t('lesson.message.deactivate.error'), { type: 'error' });
        } else {
          showToast(t('lesson.message.deactivate.success'), { type: 'success' });
        }
      }
    },
    [deactivateLessons, versionUuid, loadingState],
  );

  const handleDetach = async (uuids: string[]) => {
    const response = await detachLessons(uuids);

    if (response.error) {
      showToast(t('lesson.message.detach.error'), { type: 'error' });
    } else {
      showToast(t('lesson.message.detach.success'), { type: 'success' });
    }
  };

  const handleAssign = async (item: LessonTableType, teachers: { uuid: string }[]) => {
    await assignLessons([item.uuid], teachers);
  };

  const handleAdd = () => {
    setEditModalOpen(true);
  };

  const handleEdit = (uuid: string) => {
    setCurrentLessonUuid(uuid);
    setEditModalOpen(true);
  };

  const handleClose = () => {
    setCurrentLessonUuid(null);
    setLessonCardsModalOpen(false);
    setEditModalOpen(false);
    setRefresh(!refresh);
    setImportModalOpen(false);
    dispatch({ type: 'RESET' });
  };

  const tableColumns = useMemo((): TableColumns<LessonTableType>[] => {
    return [
      {
        header: t('lesson.table.subject'),
        id: 'subject',
        accessorKey: 'subject',
        meta: {
          filterName: t('lesson.table.subject'),
        },
        size: 250,
      },
      {
        header: t('common.hours'),
        id: 'cards',
        size: 150,
        canOverflow: true,
        enableSorting: false,
        cell: ({ row }) => {
          const cardInfos = row.original.cardsInfos;
          return (
            <>
              {cardInfos && cardInfos.defaultCards.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 'var(--spacing-2)',
                    margin: 'var(--spacing-2) 0',
                  }}
                >
                  {cardInfos.defaultCards.map((group) => {
                    const card = group[0];
                    return (
                      <div key={card.uuid} title={card.cardLabel}>
                        <LessonCard
                          label={card.cardLabelShort}
                          lessonClasses={card.lesson}
                          count={card.counter}
                          teacherColors={card.colors}
                          duration={card.duration}
                          showHover={false}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          );
        },
      },
      {
        header: t('lesson.table.classes'),
        id: 'classes',
        size: 100,
        cell: ({ row }) => {
          const shortNames = row.original.classes.map((node) => node.name).join(', ');
          return (
            <Tooltip triggerStyle={{ display: 'inline' }} content={shortNames}>
              {shortNames}
            </Tooltip>
          );
        },
        accessorFn: (data) => {
          const shortNames = data.classes.map((node) => node.name);
          return shortNames.join(', ');
        },
      },
      {
        header: t('lesson.table.teacher'),
        id: 'teachers',
        cell: ({ row }) => {
          const displayNamesShort = row.original.teachers.map((teacher) => teacher.name).join(', ');
          return (
            <Tooltip triggerStyle={{ display: 'inline' }} content={displayNamesShort}>
              {displayNamesShort}
            </Tooltip>
          );
        },
        accessorFn: (originalRow) => {
          const displayNamesShort = originalRow.teachers.map((teacher) => teacher.name);
          return displayNamesShort.join(', ');
        },
        size: 225,
      },
      {
        header: t('lesson.table.rooms'),
        id: 'rooms',
        cell: ({ row }) => {
          const displayNamesShort = (row.original.rooms?.map((room) => room.name) ?? []).join(', ');
          return (
            <Tooltip triggerStyle={{ display: 'inline' }} content={displayNamesShort}>
              {displayNamesShort}
            </Tooltip>
          );
        },
        accessorFn: (originalRow) => {
          const displayNamesShort = originalRow.rooms?.map((room) => room.name) ?? [];
          return displayNamesShort.join(', ');
        },
        size: 225,
      },
      {
        header: t('lesson.basics.isElective'),
        id: 'elective',
        accessorKey: 'elective',
        type: 'boolean',
        size: 80,
      },
      {
        header: t('lesson.basics.isTeachingLoadEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTeachingLoadEnabled.full'),
          tooltip: t('lesson.basics.isTeachingLoadEnabled.full'),
        },
        id: 'teachingLoadEnabled',
        accessorKey: 'teachingLoadEnabled',
        type: 'boolean',
        size: 80,
      },
      {
        header: t('lesson.basics.isTimetableEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTimetableEnabled.full'),
          tooltip: t('lesson.basics.isTimetableEnabled.full'),
        },
        id: 'timetableEnabled',
        accessorKey: 'timetableEnabled',
        type: 'boolean',
        size: 80,
      },
      {
        header: t('deputate.titleSingluar'),
        id: 'editStatus',
        accessorKey: 'editStatus',
        meta: {
          filterName: t('common.editable.full'),
          tooltip: t('common.editable.full'),
        },
        size: 80,
        cell: ({ row }) => {
          return row.original.editStatus === 'blocked' ? (
            <Chip bgColor={'var(--chip-bg-color)'} value={<LockIcon className={'smallest'} />} />
          ) : (
            <></>
          );
        },
      },
    ];
  }, [currentClassUuid]);

  const defaultDropdownItems = (row: Row<LessonTableType>): DropdownMenuItem[] => [
    {
      label: t('timetableVersion.editInVersion'),
      disabled: row.original.isEpochPlan,
      onClick: async () => {
        // disabled does not prevent the click event
        if (!row.original.isEpochPlan) {
          await handleDuplicate([row.original]);
        }
      },
    },
    {
      label: t('timetableVersion.editCards'),
      onClick: () => {
        setCurrentLessonUuid(row.original.uuid);
        setLessonCardsModalOpen(true);
      },
    },
    {
      label: t('timetableVersion.deactivate'),
      type: 'default',
      color: 'error',
      onClick: async () => {
        await handleDeactivate([row.original]);
      },
    },
  ];

  const inVersionDropdownItems = (row: Row<LessonTableType>): DropdownMenuItem[] => {
    const qualified = queryLessonsData?.people.filter(
      (person) =>
        person.qualifications.some((q) => q.subject.uuid === row.original.subjectUuid) &&
        !row.original.teacherUuids.includes(person.uuid),
    );
    const teachers = qualified?.length
      ? qualified
      : queryLessonsData?.people.filter((person) => !row.original.teacherUuids.includes(person.uuid));

    return [
      {
        label: t('lesson.edit'),
        disabled: store.readonly,
        onClick: () => {
          handleEdit(row.original.uuid);
        },
      },
      {
        label: t('timetableVersion.editCards'),
        disabled: store.readonly,

        onClick: () => {
          setCurrentLessonUuid(row.original.uuid);
          setLessonCardsModalOpen(true);
        },
      },
      {
        label: t('common.duplicate'),
        disabled: store.readonly,

        onClick: async () => {
          await handleDuplicate([row.original]);
        },
      },
      {
        label: t('lesson.actions.assignTo'),
        disabled: store.readonly,
        subContent: teachers
          ? teachers.map((teacher) => {
              return {
                type: 'default',
                label: teacher.selectName ?? '',
                onClick: () => {
                  handleAssign(row.original, [teacher]);
                },
              };
            })
          : [],
      },
      {
        label: t('lesson.actions.detach'),
        disabled: store.readonly,

        onClick: () => {
          handleDetach([row.original.uuid]);
        },
      },
      {
        type: 'ruler',
      },
      {
        label: t('lesson.actions.toggle.isElective'),
        type: 'switch',
        value: row.original.elective ?? false,
        disabled: store.readonly,

        onValueChange: async () => {
          await updateCheckboxes({ type: 'elective', uuids: [row.original.uuid], value: !row.original.elective });
        },
      },
      {
        label: t('lesson.basics.isTeachingLoadEnabled.full'),
        type: 'switch',
        disabled: store.readonly,

        value: row.original.teachingLoadEnabled ?? false,
        onValueChange: async () => {
          await updateCheckboxes({
            type: 'teachingLoadEnabled',
            uuids: [row.original.uuid],
            value: !row.original.teachingLoadEnabled,
          });
        },
      },
      {
        label: t('lesson.basics.isTimetableEnabled.full'),
        type: 'switch',
        disabled: true,
        value: row.original.timetableEnabled ?? false,
        onValueChange: async () => {
          await updateCheckboxes({
            type: 'timetableEnabled',
            uuids: [row.original.uuid],
            value: !row.original.timetableEnabled,
          });
        },
      },
      {
        type: 'ruler',
      },
      {
        label: t('common.delete'),
        type: 'default',
        color: 'error',
        disabled: store.readonly,
        onClick: () => {
          handleDelete([row.original]);
        },
      },
    ];
  };

  const actionBarSettings = useMemo(() => {
    return {
      extendedActionsLeft: <>{filterTags.map((tag) => tag)}</>,
      extendedActionsRight: (
        <Button
          hierarchy={'tertiary'}
          disabled={store.readonly}
          onClick={() => setImportModalOpen(true)}
          icon={<ImportIcon />}
        >
          {t('lesson.import')}
        </Button>
      ),

      showExpertFilter: true,
      showAddButton: !store.readonly,
      showPrintButton: true,
      showBulkEdit: !store.readonly,
      marginBottom: 'var(--spacing-3)',
    };
  }, [filterTags, store.readonly, t]);

  const data = lessonsData.filter(dataFilter);

  const [{ data: versionsData }] = use_TimetableVersionsQuery({
    variables: {
      where: {
        uuid: versionUuid,
      },
    },
    pause: !versionUuid,
  });

  const version = versionsData?.timetableVersions[0];

  const versionClasses = useMemo(() => {
    return version?.classesConnection.edges.map((edge) => edge.node.uuid) ?? [];
  }, [version?.classesConnection.edges]);

  const cards: CardType[] = useGetTimetableCardType(versionUuid ?? '');
  const { subjectData, classesData, teacherData, roomsData } = useLoadBasicData({
    pause: !versionsData,
  });

  useEffect(() => {
    if (classesData?.classes && roomsData?.rooms && teacherData?.people && subjectData?.subjects) {
      gridCardsStore.update(versionUuid).then(() => {
        store.initData({
          cards,
          classes: classesData?.classes.filter((c) => versionClasses.includes(c.uuid)),
          rooms: roomsData?.rooms.sort((a, b) => a.name.localeCompare(b.name)),
          teachers: teacherData?.people.sort((a, b) => a.lastName.localeCompare(b.lastName)),
          subjects: subjectData?.subjects.sort((a, b) => a.name.localeCompare(b.name)),
        });
      });
    }
  }, [
    cards,
    classesData?.classes,
    refresh,
    roomsData?.rooms,
    store,
    subjectData?.subjects,
    teacherData?.people,
    versionClasses,
  ]);

  const rowMarker = useMemo(() => {
    const marker: RowSelectionState = {};
    lessonsData.forEach((lesson, index) => {
      if (lesson.isEpochPlan) {
        marker[index] = true;
      }
    });
    return marker;
  }, [lessonsData]);

  return (
    <>
      <Table<LessonTableType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={600}
        showPagination={true}
        pageSize={50}
        breakpoint={null}
        rowMarker={rowMarker}
        showVisibility
        loading={loadingState?.some(({ uuid, loading }) => uuid === 'all' && loading)}
        isOnWhite={false}
        onAddClick={handleAdd}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('lesson.title.plural')} - ${t('common.schoolYear')} ${schoolYear?.shortName}`,
          filename: `${t('lesson.title.plural')}_${schoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={actionBarSettings}
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        showSort={true}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        sorting={sorting}
        onSortingChange={saveSorting}
        columns={tableColumns}
        data={data}
        lastColWidth='34px'
        lastCol={(row) => {
          return (
            <>
              {row.original.onlyInTimetableVersion ? (
                <Dropdown
                  disabled={
                    store.readonly || loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)
                  }
                  trigger={
                    <Button
                      disabled={store.readonly}
                      isLoading={loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)}
                      hierarchy='secondary'
                      icon={<DotsHorizontalIcon className='small' />}
                    />
                  }
                >
                  <DropdownMenu data={inVersionDropdownItems(row)} />
                </Dropdown>
              ) : (
                <Dropdown
                  disabled={
                    store.readonly || loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)
                  }
                  trigger={
                    <Button
                      disabled={store.readonly}
                      isLoading={loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)}
                      hierarchy='secondary'
                      icon={<DotsHorizontalIcon className='small' />}
                    />
                  }
                >
                  <DropdownMenu data={defaultDropdownItems(row)} />
                </Dropdown>
              )}
            </>
          );
        }}
      />
      {editModalOpen && (
        <Modal
          isOpen={editModalOpen}
          onRequestClose={() => handleClose()}
          title={currentLessonUuid ? t('lesson.edit') : t('lesson.add')}
        >
          {editModalOpen && versionUuid && (
            <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
              <TimetableLessonForm
                closeForm={() => handleClose()}
                lessonUuid={currentLessonUuid}
                defaultInitialValues={{ timetableEnabled: true }}
                versionUuid={versionUuid}
              />
            </Suspense>
          )}
        </Modal>
      )}
      {lessonCardsModalOpen && (
        <Modal
          isOpen={lessonCardsModalOpen && currentLessonUuid !== null}
          onRequestClose={() => handleClose()}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          title={t('timetableVersion.editCards') as string}
        >
          {currentLessonUuid && (
            <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
              <TimetableCardsForm
                onClose={() => handleClose()}
                lessonUuid={currentLessonUuid}
                versionUuid={versionUuid}
              />
            </Suspense>
          )}
        </Modal>
      )}
      {versionUuid && (
        <ImportLessonsToTimetableVersionModal
          isOpen={importModalOpen}
          onClose={() => handleClose()}
          versionUuid={versionUuid}
        />
      )}

      <ConfirmationDialog />
    </>
  );
});
