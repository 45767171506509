import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { PageNotFoundPage } from './pages/PageNotFound/PageNotFoundPage';
import { t } from 'i18next';
import { timetableRoutes } from './pages/Timetable/routes';
import { Login } from './pages/Login/Login';
import { PlanungAuthProvider } from './PlanungAuthProvider';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { deputateRoutes } from './pages/Deputate/routes';
import { settingsRoutes } from './pages/Settings/routes';
import { SignupPage } from './pages/Signup/SignupPage';
import { adminRoutes } from './pages/Admin/routes';
import { ProtectedOutlet } from './components/ProtectedOutlet/ProtectedOutlet';
import { ErrorPage } from './pages/ErrorPage/ErrorPage';
import { App } from './App';
import { Profile } from './pages/Profile/Profile';
import { substitutionsRoutes } from './pages/Substitutions/routes';

const router = createBrowserRouter(
  [
    {
      path: t('routes.login.slug'),
      element: (
        <PlanungAuthProvider>
          <Login />
        </PlanungAuthProvider>
      ),

      handle: {
        crumb: () => ({
          slug: t('routes.login.slug'),
          title: t('routes.login.title'),
        }),
      },
    },
    {
      path: `${t('routes.signup.slug')}/:token`,
      element: (
        <PlanungAuthProvider>
          <SignupPage />
        </PlanungAuthProvider>
      ),
      handle: {
        crumb: () => ({
          slug: t('routes.signup.slug'),
          title: t('routes.signup.title'),
        }),
      },
    },
    {
      path: '/*',
      element: <App />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => ({
          slug: '/',
          title: 'Home',
        }),
      },
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: t('routes.profile.slug'),
          element: <Profile />,
          handle: {
            crumb: () => ({
              slug: t('routes.profile.slug'),
              title: t('routes.profile.title'),
            }),
          },
        },
        {
          path: t('routes.deputate.slug'),
          element: <Outlet />,
          handle: {
            crumb: () => ({
              slug: t('routes.deputate.slug'),
              title: t('routes.deputate.title'),
            }),
          },
          children: [...deputateRoutes(t('routes.deputate.slug'))],
        },
        {
          path: t('routes.timetable.slug') + '/*',
          element: <Outlet />,
          handle: {
            crumb: () => ({
              slug: t('routes.timetable.slug'),
              title: t('routes.timetable.title'),
            }),
          },
          children: [...timetableRoutes(t('routes.timetable.slug'))],
        },
        {
          path: t('routes.substitutions.slug'),
          element: <Outlet />,
          handle: {
            crumb: () => ({
              slug: t('routes.substitutions.slug'),
              title: t('routes.substitutions.title'),
            }),
          },
          children: [...substitutionsRoutes(t('routes.substitutions.slug'))],
        },
        {
          path: t('routes.settings.slug'),
          element: <Outlet />,
          handle: {
            crumb: () => ({
              slug: t('routes.settings.slug'),
              title: t('routes.settings.title'),
            }),
          },
          children: [...settingsRoutes(t('routes.settings.slug'))],
        },
        {
          path: t('routes.admin.slug'),
          element: <ProtectedOutlet isAllowed={(perms) => perms?.isOmniAdmin() ?? false} redirect={'/'} />,
          handle: {
            crumb: () => ({
              slug: t('routes.admin.slug'),
              title: t('routes.admin.title'),
            }),
          },
          children: [...adminRoutes(t('routes.admin.slug'))],
        },
      ],
    },
    {
      path: '*',
      element: <PageNotFoundPage />,
    },
  ],
  { basename: import.meta.env.VITE_APP_BASEPATH },
);

export const Router = () => {
  return <RouterProvider router={router} />;
};
