import { FC } from 'react';
import styles from './TimetableGrid.module.scss';
import { observer } from 'mobx-react-lite';
import { GridHeaderDay } from './GridHeaderDay/GridHeaderDay';
import { GridRows } from './GridRows/GridRows';
import { useFullscreen } from '../../hooks/useFullscreen';
import classNames from 'classnames';
import { useTimetableStore } from './TimetableProvider';
import { GridSpaceCell } from './GridSpaceCell/GridSpaceCell';

export const TimetableGrid: FC = observer(() => {
  const isFullscreen = useFullscreen();

  const pinboardStore = useTimetableStore();

  const cellWidth = pinboardStore.showPause
    ? `${100 / pinboardStore.hours.length}%`
    : `${100 / pinboardStore.hours.filter((h) => !h.isPause).length}%`;

  const daysWidth = `${100 / pinboardStore.days.length}%`;

  const classes = classNames(styles['timetable-grid'], {
    [styles['is-fullscreen']]: isFullscreen,
    [styles['control-mode']]: pinboardStore.controlMode.isActive,
  });

  return (
    <div className={classes}>
      <div className={styles.header}>
        <GridSpaceCell />
        {pinboardStore.days.map((day) => {
          return <GridHeaderDay key={day.value} cellWidth={cellWidth} day={day} daysWidth={daysWidth} />;
        })}
      </div>
      <GridRows cellWidth={cellWidth} daysWidth={daysWidth} />
    </div>
  );
});
