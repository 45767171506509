import {
  CurriculumPersonRelationshipProperties,
  LessonCreateInput,
  LessonSort,
  SortDirection,
  use_CurriculumsQuery,
  use_LessonsQuery,
  use_TeachingBlockVersionsQuery,
  use_TimetableVersionsQuery,
  useCreateLessonsMutation,
  useDeleteCardsMutation,
  useDeleteLessonsMutation,
  useTeachingLoadFactorsQuery,
  useUpdateLessonsMutation,
} from '../../../types/planung-graphql-client-defs';
import { LessonFormType } from '../types';
import { useMemorizedCacheTag, useMemorizedCacheTags } from '../../../hooks/useMemorizedCacheTag';
import { useCallback, useMemo, useReducer } from 'react';
import { loadingReducer, loadingReducerInitialState } from '../../../reducer/loadingReducer';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { isNotEmpty } from '../../../utils/typeguards';
import { useLessonResponseToTableType } from '../../../hooks/useLessonResponseToTableType';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';

type useLessonsProps = {
  organizationUuid?: string;
  schoolYearUuid?: string;
  querySort?: LessonSort[];
};

export function useDeputateLessons({
  organizationUuid,
  schoolYearUuid,
  querySort = [
    { classSort: SortDirection.Asc },
    { subjectName: SortDirection.Asc },
    { teachersSort: SortDirection.Asc },
  ],
}: useLessonsProps) {
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const _organizationUuid = organizationUuid ?? pimAuthClaims.getOrganizationUuid();
  const _schoolYearUuid = schoolYearUuid ? schoolYearUuid : schoolYear?.uuid;

  const [loadingState, dispatch] = useReducer(loadingReducer, loadingReducerInitialState);

  const context = useMemorizedCacheTags(['LESSONS', 'LESSON_CLASS']);
  const [, create] = useCreateLessonsMutation();
  const [, update] = useUpdateLessonsMutation();
  const [, remove] = useDeleteLessonsMutation();
  const [, deleteCards] = useDeleteCardsMutation();

  const [{ data: lessonsQueryData }] = use_LessonsQuery({
    variables: {
      where: {
        NOT: {
          onlyInTimetableVersion: true,
        },
        schoolYear: {
          uuid: _schoolYearUuid,
        },
      },
      options: { sort: querySort },
    },
  });

  const {
    subjectData,
    subjectContainerData,
    roomsData,
    classesData,
    groupsData,
    teacherData,
    lessonClassesData,
    divisionsData,
  } = useLoadBasicData({
    pause: false,
  });

  const [{ data: versionData }] = use_TimetableVersionsQuery({
    variables: {
      where: {
        lessons: {
          uuid_IN: lessonsQueryData?.lessons.map((l) => l.uuid) ?? [],
        },
      },
    },
  });

  const [{ data: teachingblockVersionsData }] = use_TeachingBlockVersionsQuery({
    variables: {
      where: {
        lessons: {
          uuid_IN: lessonsQueryData?.lessons.map((l) => l.uuid) ?? [],
        },
      },
    },
  });

  const [{ data: curriculaData }] = use_CurriculumsQuery({
    variables: {},
  });

  const queryLessonsData = useMemo(() => {
    return {
      rooms: roomsData.rooms,
      classes: classesData?.classes ?? [],
      people: teacherData?.people ?? [],
      subjects: subjectData?.subjects ?? [],
      lessons:
        lessonsQueryData?.lessons.map((lesson) => {
          const subject = subjectData?.subjects.find((s) => s.uuid === lesson.subject.uuid);
          const subjectContainer = subjectContainerData?.subjectContainers.find(
            (sc) => sc.uuid === lesson.subject.uuid,
          );
          const curr = curriculaData?.curricula.find((c) => c.uuid === lesson.curriculum?.uuid);
          return {
            uuid: lesson.uuid,
            groupClassNames: lesson.groupClassNames,
            onlyInTimetableVersion: false,
            elective: lesson.elective,
            teachingLoadEnabled: lesson.teachingLoadEnabled,
            timetableEnabled: lesson.timetableEnabled,
            placedCardsCount: lesson.placedCardsCount,
            schoolYear: {
              uuid: _schoolYearUuid ?? '',
            },
            subject:
              lesson.subject.__typename === 'SubjectContainer'
                ? {
                    __typename: 'SubjectContainer' as const,
                    uuid: lesson.subject.uuid,
                    name: subjectContainer?.name ?? '',
                    shortName: subjectContainer?.shortName ?? '',
                  }
                : {
                    __typename: 'Subject' as const,
                    uuid: lesson.subject.uuid,
                    name: subject?.name ?? '',
                    shortName: subject?.shortName ?? '',
                    defaultRooms:
                      subject?.defaultRoomsConnection.edges.map((r) => {
                        return roomsData.rooms.find((room) => room.uuid === r.node.uuid) ?? { uuid: '', name: '' };
                      }) ?? [],
                  },
            curriculum: {
              uuid: lesson.curriculum?.uuid ?? '',
              personsConnection: curr?.personsConnection ?? { edges: [], totalCount: 0 },
            },
            versions: lesson.versions.map((version) => {
              const currentVersion = versionData?.timetableVersions.find((v) => v.uuid === version.uuid);
              return {
                uuid: version.uuid,
                version: currentVersion?.version ?? 0,
                active: currentVersion?.active,
                timetable: {
                  uuid: currentVersion?.timetable?.uuid ?? '',
                  name: currentVersion?.timetable?.name ?? '',
                },
              };
            }),
            comment: lesson.comment,
            isClassTrip: lesson.isClassTrip,
            subTitle: lesson.subTitle,
            gradeGroupUuid: lesson.gradeGroupUuid,
            teachingBlockVersions: lesson.teachingBlockVersions.map((version) => {
              const currentVersion = teachingblockVersionsData?.teachingBlockVersions.find(
                (v) => v.uuid === version.uuid,
              );
              return {
                uuid: version.uuid,
                version: currentVersion?.version ?? 0,
                active: currentVersion?.active,
                teachingBlock: {
                  uuid: currentVersion?.teachingBlock?.uuid ?? '',
                  name: currentVersion?.teachingBlock?.name ?? '',
                },
              };
            }),
            activeTimetables: lesson.activeTimetables,
            activeTeachingBlocks: lesson.activeTeachingBlocks,
            lessonClassesConnection: {
              edges: lesson.lessonClassesConnection.edges.map((edge) => {
                const lessonClassUuid = edge.node.uuid;
                const lessonClass = lessonClassesData?.lessonClasses.find((lc) => lc.uuid === lessonClassUuid);
                const classC = classesData?.classes.find((c) => c.uuid === lessonClass?.class.uuid);
                const groups = groupsData?.groups.filter((g) =>
                  lessonClass?.groups.map((group) => group.uuid).includes(g.uuid),
                );
                const division = divisionsData?.divisions.find((d) => d.uuid === lessonClass?.usedDivision?.uuid);
                const classDivisions = divisionsData?.divisions.filter((d) =>
                  classC?.divisions.map((div) => div.uuid).includes(d.uuid),
                );

                return {
                  node: {
                    uuid: lessonClassUuid,
                    class: {
                      grade: classC?.grade ?? null,
                      uuid: classC?.uuid ?? '',
                      name: classC?.name ?? '',
                      shortName: classC?.shortName ?? '',
                      timetableConfig: {
                        color: classC?.timetableConfig?.color ?? '',
                      },
                      divisions:
                        classDivisions?.map((cd) => {
                          return {
                            uuid: cd.uuid,
                            name: cd.name,
                            groups: cd.groupsConnection.edges.map((edge) => {
                              const group = groupsData?.groups.find((g) => g.uuid === edge.node.uuid);
                              return {
                                uuid: group?.uuid ?? '',
                                name: group?.name ?? '',
                              };
                            }),
                          };
                        }) ?? [],
                    },
                    groups: groups ?? [],
                    usedDivision: {
                      name: division?.name ?? '',
                      uuid: division?.uuid ?? '',
                      groupsConnection: {
                        edges:
                          division?.groupsConnection.edges.map((e) => {
                            const group = groups?.find((g) => g.uuid === e.node.uuid);
                            return {
                              node: {
                                uuid: e.node.uuid,
                                name: group?.name ?? '',
                              },
                              properties: {
                                order: e.properties.order,
                              },
                            };
                          }) ?? [],
                      },
                    },
                  },
                };
              }),
            },
            lessonUnitConnection: {
              edges: lesson.lessonUnitConnection.edges.map((edge) => {
                const sc = subjectContainerData?.subjectContainers.find(
                  (s) => s.uuid === edge.node.subjectContainer?.uuid,
                );
                return {
                  node: {
                    uuid: edge.node.uuid,
                    count: edge.node.count,
                    duration: edge.node.duration,
                    subjectContainer: sc
                      ? {
                          uuid: sc?.uuid ?? '',
                          name: sc?.name ?? '',
                          shortName: sc?.shortName ?? '',
                        }
                      : undefined,
                  },
                };
              }),
            },
            teachersConnection: {
              edges: lesson.teachersConnection.edges
                .map((edge) => {
                  const teacher = teacherData?.people.find((p) => p.uuid === edge.node.uuid);
                  return {
                    node: {
                      uuid: teacher?.uuid ?? '',
                      fullName: teacher?.fullName ?? '',
                      shortName: teacher?.displayNameShort ?? teacher?.fullName ?? '',
                      firstName: teacher?.firstName ?? '',
                      lastName: teacher?.lastName ?? '',
                      displayNameShort: teacher?.displayNameShort ?? teacher?.fullName,
                      timetableConfig: {
                        color: teacher?.timetableConfig?.color ?? '',
                      },
                    },
                    properties: {
                      description: edge.properties.description,
                      present: edge.properties.present,
                      teachingLoad: edge.properties.teachingLoad,
                      teachingLoadHours: edge.properties.teachingLoadHours,
                      writesCertificate: edge.properties.writesCertificate,
                    },
                  };
                })
                .sort((a, b) => a.node.lastName.localeCompare(b.node.lastName)),
            },
            roomSupplyConnection: {
              __typename: undefined,
              edges: lesson.roomSupplyConnection.edges.map((edge) => {
                const room = roomsData.rooms.find((r) => r.uuid === edge.node.uuid);
                return {
                  properties: {
                    descriptions: edge.properties.descriptions,
                  },
                  node: {
                    uuid: room?.uuid ?? '',
                    name: room?.name ?? '',
                    roomNumber: room?.roomNumber ?? '',
                  },
                };
              }),
            },
          };
        }) ?? [],
    };
  }, [
    _schoolYearUuid,
    classesData?.classes,
    curriculaData?.curricula,
    divisionsData?.divisions,
    groupsData?.groups,
    lessonClassesData?.lessonClasses,
    lessonsQueryData?.lessons,
    roomsData.rooms,
    subjectContainerData?.subjectContainers,
    subjectData?.subjects,
    teacherData?.people,
    teachingblockVersionsData?.teachingBlockVersions,
    versionData?.timetableVersions,
  ]);

  const [{ data: teachingLoadFactors }] = useTeachingLoadFactorsQuery({
    variables: {
      where: {
        schoolYear: { uuid: _schoolYearUuid },
      },
    },
    context: useMemorizedCacheTag('TEACHINGLOADFACTOR'),
  });

  const lessonsData = useLessonResponseToTableType({
    queryLessonsData,
    teachingLoadFactors,
    versionContext: false,
  });

  const getBasics = (values: LessonFormType) => {
    return {
      subTitle: values.subTitle,
      comment: values.comment,
      teachingLoadEnabled: values.teachingLoadEnabled ?? false,
      timetableEnabled: values.timetableEnabled ?? false,
      elective: values.elective ?? false,
      onlyInTimetableVersion: false,
      isClassTrip: values.isClassTrip ?? false,
    };
  };

  const addMissingEpochPlans = async (values: LessonFormType) => {
    const subjectContainerUuids = values.lessonUnit?.map((lu) => lu.subjectContainerUuid).filter((a) => isNotEmpty(a));
    const classUuids = values.lessonClasses?.map((lc) => lc.class.uuid).filter((a) => isNotEmpty(a));

    let itemsToCreate = subjectContainerUuids
      ? subjectContainerUuids?.flatMap((subjectContainerUuid) =>
          classUuids?.map((classUuid) => ({ classUuid, subjectContainerUuid })),
        )
      : [];

    if (subjectContainerUuids) {
      itemsToCreate?.forEach((item, index) => {
        lessonsData.forEach((ld) => {
          if (
            item?.subjectContainerUuid === ld.subjectUuid &&
            ld.classes.length === 1 &&
            item.classUuid === ld.classes[0].uuid
          ) {
            itemsToCreate[index] = undefined;
          }
        });
      });
    }

    itemsToCreate = itemsToCreate?.filter((item) => isNotEmpty(item));

    if (itemsToCreate?.length > 0) {
      const createInput = itemsToCreate.map((classSubjectContainer, index) => {
        let input: LessonCreateInput = {
          organization: { connect: { where: { node: { uuid: _organizationUuid } } } },
          ...getBasics(values),
          schoolYear: {
            connect: {
              where: {
                node: {
                  uuid: _schoolYearUuid,
                },
              },
            },
          },
          subject: {
            SubjectContainer: {
              connect: {
                where: {
                  node: {
                    uuid: classSubjectContainer?.subjectContainerUuid,
                  },
                },
              },
            },
          },
        };
        const subjectContainerUuid = classSubjectContainer?.subjectContainerUuid;
        const classUuid = classSubjectContainer?.classUuid;
        if (subjectContainerUuid) {
          input = {
            ...input,
          };
        }
        if (classUuid) {
          input = {
            ...input,
            lessonClasses: {
              create: [{ edge: { order: 0 }, node: { class: { connect: { where: { node: { uuid: classUuid } } } } } }],
            },
          };
        }
        return input;
      });
      return await create({ input: createInput }, context);
    }

    return { success: true }; //If no epochs need to be created then it's ok
  };

  const createLesson = async (values: LessonFormType, addEpochPlans = true) => {
    const input: LessonCreateInput = {
      organization: { connect: { where: { node: { uuid: _organizationUuid } } } },
      curriculum: { connect: { where: { node: { uuid: values.curriculumUuid ?? '' } } } },
      ...getBasics(values),
      schoolYear: {
        connect: {
          where: {
            node: {
              uuid: _schoolYearUuid,
            },
          },
        },
      },
      subject: {
        Subject: {
          connect: {
            where: {
              node: {
                uuid: values.subject?.uuid ?? '',
              },
            },
          },
        },
        SubjectContainer: {
          connect: {
            where: {
              node: {
                uuid: values.subject?.uuid ?? '',
              },
            },
          },
        },
      },
      lessonClasses: {
        create: values.lessonClasses?.map((lessonClass, index) => ({
          edge: {
            order: index,
          },
          node: {
            class: {
              connect: {
                where: {
                  node: {
                    uuid: lessonClass.class.uuid,
                  },
                },
              },
            },
            ...(lessonClass.groups &&
              lessonClass.groups.length > 0 && {
                groups: {
                  connect: lessonClass.groups.map((group) => ({ where: { node: { uuid: group.uuid } } })),
                },
              }),
          },
        })),
      },
      teachers: {
        connect: values.teachers
          ?.filter((t) => isNotEmpty(t.person?.uuid))
          .map((teacher, index) => {
            return {
              where: {
                node: { uuid: teacher.person?.uuid },
              },
              edge: {
                order: index,
                description: teacher.description,
                present: teacher.present,
                teachingLoad: teacher.teachingLoad,
                teachingLoadHours: teacher.teachingLoadHours,
                writesCertificate: teacher.writesCertificate,
              },
            };
          }),
      },
      roomSupply: {
        connect: values.roomSupply?.map((room, index) => {
          return {
            where: {
              node: { uuid: room.uuid },
            },
            edge: {
              order: index,
              descriptions: room.description,
            },
          };
        }),
      },
      lessonUnit: {
        create: values.lessonUnit?.map((lessonUnit, index) => {
          return {
            edge: {
              order: index,
            },
            node: {
              count: lessonUnit.count,
              duration: lessonUnit.duration,
              ...(lessonUnit.subjectContainerUuid && {
                subjectContainer: {
                  connect: {
                    where: {
                      node: {
                        uuid: lessonUnit.subjectContainerUuid,
                      },
                    },
                  },
                },
              }),
            },
          };
        }),
      },
    };
    const promises: Promise<{ error?: unknown; success?: boolean }>[] = [create({ input }, context)];
    if (addEpochPlans) {
      promises.push(addMissingEpochPlans(values));
    }

    return await Promise.all(promises);
  };

  const duplicateLesson = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    let result: { error?: unknown; success?: boolean }[] = [];

    const currentData = queryLessonsData?.lessons.find((lesson) => lesson.uuid === uuids[0]);

    if (currentData) {
      const createData: LessonFormType = {
        ...currentData,
        uuid: undefined,
        lessonClasses: currentData.lessonClassesConnection.edges.map(({ node }) => {
          return {
            class: {
              uuid: node.class.uuid,
              name: node.class.name,
              color: node.class.timetableConfig?.color,
            },
            uuid: node.uuid,
            groups: node.groups,
          };
        }),

        roomSupply: currentData.roomSupplyConnection.edges.map((edge) => ({
          uuid: edge.node.uuid,
          description: edge.properties.descriptions ?? '',
        })),
        teachers: currentData.teachersConnection.edges.map((teacherEdge) => {
          return {
            present: teacherEdge.properties.present,
            teachingLoad: teacherEdge.properties.teachingLoad,
            teachingLoadHours: teacherEdge.properties.teachingLoadHours,
            description: teacherEdge.properties.description,
            writesCertificate: teacherEdge.properties.writesCertificate,
            person: {
              uuid: teacherEdge.node.uuid,
              fullName: teacherEdge.node.fullName,
              displayNameShort: teacherEdge.node.displayNameShort ?? teacherEdge.node.fullName,
              color: teacherEdge.node.timetableConfig?.color,
            },
          };
        }),
        lessonUnit: currentData.lessonUnitConnection.edges.map(({ node }) => {
          return {
            blocked: false,
            duration: node.duration,
            count: node.count,
            subjectContainerUuid: node.subjectContainer?.uuid,
            lessonUnitType: node.subjectContainer?.uuid ? 'epoch' : 'subjectHour',
          };
        }),
      };
      result = await createLesson(createData);
    }
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return result;
  };

  const detachLessons = useCallback(
    async (uuids: string[]) => {
      dispatch({ type: 'SET_LOADING', uuids: uuids });

      const result = await update({ where: { uuid_IN: uuids }, update: { teachers: [{ disconnect: [{}] }] } }, context);
      dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
      return result;
    },
    [context, update],
  );

  const assignLessons = useCallback(
    async (
      uuids: string[],
      teachers: { uuid: string }[],
      curriculumPersonProperties?: CurriculumPersonRelationshipProperties | undefined,
    ) => {
      dispatch({ type: 'SET_LOADING', uuids: uuids });

      const result = await update(
        {
          where: { uuid_IN: uuids },
          update: {
            teachers: [
              {
                connect: teachers?.map((teacher, index) => {
                  return {
                    where: {
                      node: { uuid: teacher.uuid },
                    },
                    edge: {
                      order: index,
                      present: curriculumPersonProperties?.present ?? true,
                      teachingLoad: curriculumPersonProperties?.teachingLoad ?? true,
                    },
                  };
                }),
              },
            ],
          },
        },
        context,
      );
      dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
      return result;
    },
    [context, update],
  );

  const updateLesson = async (values: LessonFormType) => {
    dispatch({ type: 'SET_LOADING', uuids: [values.uuid ?? 'all'] });

    const result = await update(
      {
        where: { uuid: values.uuid },
        update: {
          ...getBasics(values),
          schoolYear: {
            disconnect: {},
            connect: {
              where: {
                node: {
                  uuid: _schoolYearUuid,
                },
              },
            },
          },
          subject: {
            Subject: {
              disconnect: {},
              connect: {
                where: {
                  node: {
                    uuid: values.subject?.uuid,
                  },
                },
              },
            },
          },
          lessonClasses: [
            {
              delete: [{}],
              create: values.lessonClasses?.map((lessonClass, index) => ({
                edge: {
                  order: index,
                },
                node: {
                  class: {
                    connect: {
                      where: {
                        node: {
                          uuid: lessonClass.class.uuid,
                        },
                      },
                    },
                  },
                  ...(lessonClass.groups &&
                    lessonClass.groups.length > 0 && {
                      groups: {
                        connect: lessonClass.groups.map((group) => ({ where: { node: { uuid: group.uuid } } })),
                      },
                    }),
                },
              })),
            },
          ],
          lessonUnit: [
            {
              delete: [{}],
              create: values.lessonUnit?.map((lessonUnit, index) => {
                return {
                  edge: { order: index },
                  node: {
                    count: lessonUnit.count,
                    duration: lessonUnit.duration,
                    ...(lessonUnit.subjectContainerUuid && {
                      subjectContainer: {
                        connect: {
                          where: {
                            node: {
                              uuid: lessonUnit.subjectContainerUuid,
                            },
                          },
                        },
                      },
                    }),
                  },
                };
              }),
            },
          ],
          teachers: [
            {
              disconnect: [{}],
              connect: values.teachers
                ?.filter((t) => isNotEmpty(t.person?.uuid))
                .map((teacher, index) => {
                  return {
                    where: {
                      node: { uuid: teacher.person?.uuid },
                    },
                    edge: {
                      order: index,
                      description: teacher.description,
                      present: teacher.present,
                      teachingLoad: teacher.teachingLoad,
                      teachingLoadHours: teacher.teachingLoadHours,
                      writesCertificate: teacher.writesCertificate,
                    },
                  };
                }),
            },
          ],
          roomSupply: [
            {
              disconnect: [{}],
              connect: values.roomSupply?.map((room, index) => {
                return {
                  where: {
                    node: { uuid: room.uuid },
                  },
                  edge: {
                    order: index,
                    descriptions: room.description,
                  },
                };
              }),
            },
          ],
        },
      },
      context,
    );
    dispatch({ type: 'REMOVE_LOADING', uuids: [values.uuid ?? 'all'] });
    return result;
  };

  const deleteLessons = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });

    let result: { error?: unknown; success?: boolean } = {};
    await deleteCards({ where: { lesson: { uuid_IN: uuids } } }, context);
    result = await remove({ where: { uuid_IN: uuids } }, context);

    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });

    return result;
  };

  const updateCheckboxes = async ({
    type,
    uuids,
    value,
  }: {
    type: 'teachingLoadEnabled' | 'timetableEnabled' | 'elective';
    uuids: string[];
    value: boolean;
  }) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    const res = await update({ where: { uuid_IN: uuids }, update: { [type]: value } }, context);
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return res;
  };

  return {
    createLesson,
    updateLesson,
    detachLessons,
    assignLessons,
    deleteLessons,
    updateCheckboxes,
    duplicateLesson,
    loadingState,
    lessonContext: context,
    queryLessonsData,
    lessonsData,
  };
}
