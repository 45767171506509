import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useMemo } from 'react';
import { ArrowRightIcon, Button, Table, TableColumns } from '@bp/ui-components';
import { formatNumber } from '../../../utils/helper';
import {
  BillingMethods,
  SortDirection,
  use_ContractsQuery,
  use_PeopleWithContractQuery,
  useAdditionalTeachingLoadsListQuery,
  useListPlannedTeacherTeachingLoadQuery,
} from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { sumUpArray } from '../../../utils/arrayFunc';
import { observer } from 'mobx-react-lite';
import { _evalBillingMethod } from '../../Lessons/Tables/Teachers/utils/eval-billing-method';

export type DeputatesTableData = {
  teacherUuid: string;
  name: string;
  teachingBlockWeeks: number;
  teachingBlocks: number;
  deputateSubjectContainer: number;
  deputateLessonUnit: number;
  deputateAdditional: number;
  contractHours: number;
  billedHours: number;
};

type PlannedDeputatesTableProps = {
  handleRowClick: (data: string) => void;
};

export const PlannedDeputatesTable = observer(({ handleRowClick }: PlannedDeputatesTableProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('deputate-list');
  const { sorting, saveSorting } = useColumnsSort('deputate-list');

  const [{ data: deputateData }] = useListPlannedTeacherTeachingLoadQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: currentSchoolYear?.uuid ?? '',
    },
  });

  const [{ data: teacherData }] = use_PeopleWithContractQuery({
    variables: {
      schoolYearUuid: currentSchoolYear?.uuid ?? '',
      options: { sort: [{ lastName: SortDirection.Asc }, { firstName: SortDirection.Asc }] },
    },
  });
  const [{ data: contractData }] = use_ContractsQuery({
    pause: false,
  });

  const atlContext = useMemorizedCacheTag('ADDITIONAL_TEACHINGLOAD');
  const [{ data: additionalTeachingLoadQueryResult }] = useAdditionalTeachingLoadsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolyear: currentSchoolYear?.uuid ?? '',
    },
    context: atlContext,
  });
  const fullTimeHours = useUserConfigContext().selectedSchoolYear?.fullTimeHours;
  const memoizedData = useMemo((): DeputatesTableData[] => {
    return (
      teacherData?.people.map((person) => {
        const lessonsForPerson =
          deputateData?.plannedTeachingLoadByTeacher.filter((lesson) => lesson.teacherUuid === person.uuid) ?? [];

        const deputateAdditional =
          sumUpArray(
            additionalTeachingLoadQueryResult?.additionalTeachingLoads
              ?.filter((l) => l.teacher.uuid === person.uuid)
              .map((a) => a.hours) ?? [],
          ) ?? 0;

        const deputateSubjectContainer = lessonsForPerson
          .filter((lesson) => lesson.subjectContainerUuid !== undefined && lesson.subjectContainerUuid !== null)
          .map((lesson) => lesson.deputat ?? 0)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        const teachingBlocks = sumUpArray(lessonsForPerson.map((lesson) => lesson.teachingBlocks ?? 0));
        const teachingBlockWeeks = sumUpArray(lessonsForPerson.map((lesson) => lesson.teachingBlockWeeks ?? 0));

        const deputateLessonUnit = lessonsForPerson
          .filter((lesson) => lesson.subjectContainerUuid === undefined || lesson.subjectContainerUuid === null)
          .map((lesson) => lesson.deputat ?? 0)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        const contract = contractData?.contracts.find((c) => c.uuid === person.currentContract?.uuid);
        const billedHours = _evalBillingMethod({
          deputateLesson: deputateLessonUnit + deputateSubjectContainer,
          deputateManually: deputateAdditional,
          deputateContract: contract?.hoursWeekly ?? 0,
          billingMethod: contract?.billingMethod ?? BillingMethods.GivenHours,
          limitedToFullTime: contract?.limitedToFullTime ?? false,
          fullTimeHours: fullTimeHours ?? 0,
        });

        return {
          teacherUuid: person.uuid,
          name: person.listName ?? '',
          teachingBlockWeeks,
          teachingBlocks,
          deputateAdditional,
          deputateLessonUnit,
          deputateSubjectContainer,
          contractHours: contract?.hoursWeekly ?? 0,
          billedHours: billedHours,
        };
      }) ?? []
    );
  }, [
    teacherData?.people,
    deputateData?.plannedTeachingLoadByTeacher,
    additionalTeachingLoadQueryResult?.additionalTeachingLoads,
    contractData?.contracts,
    fullTimeHours,
  ]);

  function tableColumns(): TableColumns<DeputatesTableData>[] {
    return [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        size: 300,
      },
      {
        header: t('common.week', { count: 2 }),
        id: 'teachingBlockWeeks',
        accessorKey: 'teachingBlockWeeks',
        size: 100,
        alignment: 'right',
        accessorFn: ({ teachingBlockWeeks }) => teachingBlockWeeks.toString(),
      },
      {
        header: t('common.epochs'),
        accessorKey: 'teachingBlocks',
        id: 'teachingBlocks',
        size: 100,
        alignment: 'right',
        accessorFn: ({ teachingBlocks }) => teachingBlocks.toString(),
      },
      {
        header: t('deputate.deputateEpoch'),
        accessorFn: (row) => formatNumber(row.deputateSubjectContainer),
        id: 'deputateSubjectContainer',
        size: 100,
        alignment: 'right',
      },
      {
        header: t('deputate.deputateSubject'),
        accessorFn: (row) => formatNumber(row.deputateLessonUnit),
        id: 'deputateLessonUnit',
        size: 100,
        alignment: 'right',
      },
      {
        header: t('deputate.deputateManually'),
        accessorFn: (row) => formatNumber(row.deputateAdditional),
        id: 'deputateAdditional',
        size: 100,
        alignment: 'right',
      },
      {
        header: t('common.sum'),
        accessorFn: (row) =>
          formatNumber(row.deputateAdditional + row.deputateSubjectContainer + row.deputateLessonUnit),
        size: 100,
        id: 'sum',
        alignment: 'right',
      },
      {
        header: t('contracts.contract'),
        accessorFn: (row) => formatNumber(row.contractHours),
        size: 100,
        id: 'contractHours',
        alignment: 'right',
      },
      {
        header: t('common.status'),
        accessorFn: (row) => {
          const sum = row.deputateAdditional + row.deputateSubjectContainer + row.deputateLessonUnit;
          if (sum === null || sum === 0 || row.contractHours === null || row.contractHours === 0) {
            return '--';
          }
          const status = Math.floor((sum / row.contractHours) * 100);
          return status + '%';
        },
        cell: ({ row }) => {
          const sum =
            row.original.deputateAdditional + row.original.deputateSubjectContainer + row.original.deputateLessonUnit;
          if (sum === null || sum === 0 || row.original.contractHours === null || row.original.contractHours === 0) {
            return <>--</>;
          }
          const status = Math.floor((sum / row.original.contractHours) * 100);
          return (
            <span
              style={{
                color:
                  status > 100 ? 'var(--color-error)' : status < 100 ? 'var(--color-warning)' : 'var(--color-success)',
              }}
            >
              {status}%
            </span>
          );
        },
        size: 75,
        id: 'status',
        alignment: 'right',
        accessorKey: 'status',
      },
      {
        header: t('deputate.payed'),
        accessorFn: (row) => formatNumber(row.billedHours),
        size: 100,
        id: 'billedHours',
        alignment: 'right',
      },
    ];
  }

  const columns = useMemo(tableColumns, []);

  return (
    <Table<DeputatesTableData>
      showBorderRadius
      showRowHover
      showShadow
      canScroll
      minHeight={600}
      columns={columns}
      data={memoizedData}
      sorting={sorting}
      onSortingChange={saveSorting}
      isOnWhite={false}
      showVisibility
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={saveColumnVisibility}
      showActionBar
      actionBarSettings={{ showPrintButton: true }}
      printerSettings={{
        headline: pimAuthClaims.getProfile()?.organization.name,
        subline: `${t('routes.deputate.evaluate.planned.title')} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
        filename: `${t('routes.deputate.evaluate.planned.title')}_${currentSchoolYear?.shortName}`,
      }}
      showSelect={false}
      onRowClick={(e, row) => {
        handleRowClick(row.original.teacherUuid);
      }}
      lastColWidth='100px'
      lastCol={(row) => {
        return (
          <Button
            hierarchy='ghost'
            onClick={() => handleRowClick(row.original.teacherUuid)}
            icon={<ArrowRightIcon className='small' />}
          />
        );
      }}
    />
  );
});
