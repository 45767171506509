import { getIn, useField, useFormikContext } from 'formik';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputProps, SelectOptionType } from '@bp/ui-components';
import { ClassFormInitialValuesType } from '../ClassesForm';

export const NameField: FC<
  InputProps & { showGrade: boolean; longName?: boolean; gradeGroupsOptions: SelectOptionType[] }
> = (props) => {
  const { t } = useTranslation();
  const {
    values: { gradeGroupUuid, grade },
    touched,
    setFieldValue,
    initialValues,
    setFieldTouched,
    validateField,
  } = useFormikContext<ClassFormInitialValuesType>();
  const [field] = useField(props.name);
  const { longName, ...inputProps } = props;

  useEffect(() => {
    const fieldIsTouched = getIn(touched, props.name);
    const initialFieldValue = getIn(initialValues, props.name);
    const gradeName = props.gradeGroupsOptions.find((g) => g.value === gradeGroupUuid);

    if (touched.grade && !fieldIsTouched && initialFieldValue === '') {
      const gradeGradeGroupShort = gradeName?.label ? `${grade}${gradeName?.label}` : `${grade}`;
      const gradeGradeGroupLong = gradeName?.label
        ? `${grade}.${t('classes.title.singular')} ${gradeName?.label}`
        : `${grade}.${t('classes.title.singular')}`;
      const computedName = longName
        ? props.showGrade
          ? gradeGradeGroupLong
          : `${t('classes.title.singular')}`
        : props.showGrade
          ? gradeGradeGroupShort
          : ``;

      setFieldValue(props.name, computedName, true).then(() => {
        validateField(props.name);
      });
    }
  }, [
    grade,
    initialValues,
    longName,
    props.name,
    props.showGrade,
    setFieldTouched,
    setFieldValue,
    t,
    touched,
    gradeGroupUuid,
  ]);

  return <Input {...inputProps} {...field} />;
};
