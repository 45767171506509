import { action, makeObservable, observable } from 'mobx';
import {
  ClassGridCard,
  ClassGridCardsDocument,
  ClassGridCardsQuery,
  ClassGridCardsQueryVariables,
} from '../types/planung-graphql-client-defs';
import { ClassGridCards } from '../pages/Timetable/Plan/TimetableVersion/TimetableVersion';
import { urqlClient } from '../utils/urqlClient';

export type TimetableCardUuid = string;

export class GridCardsStore {
  private _loading: boolean;

  public gridCards: Map<TimetableCardUuid, ClassGridCard[]> = new Map();

  constructor() {
    this._loading = false;
    makeObservable(this, {
      gridCards: observable,
      setData: action,
    });
  }

  setData(cards: ClassGridCards[]) {
    this.gridCards.clear();
    cards.forEach((card) => {
      if (this.gridCards.has(card.timetableCardUuid)) {
        const currentCards = this.gridCards.get(card.timetableCardUuid) ?? [];
        currentCards.push(card);
        this.gridCards.set(card.timetableCardUuid, currentCards);
      } else {
        this.gridCards.set(card.timetableCardUuid, [card]);
      }
    });
  }

  async update(versionUuid: string) {
    if (this._loading) {
      return;
    }
    this._loading = true;

    const { data } = await urqlClient
      .query<ClassGridCardsQuery, ClassGridCardsQueryVariables>(ClassGridCardsDocument, {
        where: { timetableVersion: { uuid: versionUuid } },
      })
      .toPromise();
    if (data) {
      this.setData(data.classGridCards);
    }
    this._loading = false;
  }
}
