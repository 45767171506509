import { useColumnsSort } from '../../../hooks/useColumnsSort';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Row,
  Table,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeachingBlockVersionTableType } from '../graphql/types';
import { createTeachingBlockVersionTableColumns } from '../utils/TeachingBlockVersionTableUtils';
import { observer } from 'mobx-react-lite';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';

type TeachingBlockVersionRoomsTableProps = {
  versionUuid: string | undefined;
  onRowSelection: (rowData: TeachingBlockVersionTableType, index: number) => void;
  onShowLessonClick: (uuid: string) => void;
  versionRoomUuids: string[];
};

export const TeachingBlockVersionRoomsTable = observer(
  ({ onRowSelection, onShowLessonClick, versionUuid, versionRoomUuids }: TeachingBlockVersionRoomsTableProps) => {
    const { t } = useTranslation();

    const { sorting, saveSorting } = useColumnsSort('teachingBlockVersionRoomsTable');
    const { rowSelection, onRowSelectionChange } = useDefaultSelecting({ 0: true });

    const columns = useMemo(() => createTeachingBlockVersionTableColumns(t('rooms.name')), [t]);

    const createActionItems = useCallback((row: Row<TeachingBlockVersionTableType>): DropdownMenuItem[] => {
      return [
        {
          label: t('teachingBlockVersion.editData'),
        },
        {
          label: t('teachingBlockVersion.test'),
        },
        {
          label: t('teachingBlockVersion.remove'),
          type: 'default',
          color: 'error',
        },
      ];
    }, []);

    const { roomsData } = useLoadBasicData({ pause: !versionUuid });

    const roomsTableData: TeachingBlockVersionTableType[] = useMemo(() => {
      return (
        roomsData?.rooms
          .filter((c) => versionRoomUuids.includes(c.uuid))
          .map((c) => {
            return {
              color: {},
              count: 0,
              name: c.name,
              shortName: c.roomNumber ?? '',
              uuid: c.uuid,
            };
          }) ?? []
      );
    }, [roomsData?.rooms, versionRoomUuids]);

    return (
      <>
        <Table<TeachingBlockVersionTableType>
          showBorderRadius
          showShadow
          canScroll
          minHeight={900}
          sorting={sorting}
          onSortingChange={saveSorting}
          columns={columns}
          data={roomsTableData}
          lastColWidth='140px'
          lastCol={(row) => {
            return (
              <ButtonGroup>
                <Button hierarchy='secondary' onClick={() => onShowLessonClick(row.original.uuid)}>
                  {t('teachingBlockVersion.showLessons')}
                </Button>
                <Dropdown
                  noPadding
                  trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
                >
                  <DropdownMenu data={createActionItems(row)} />
                </Dropdown>
              </ButtonGroup>
            );
          }}
          showRowHover
          onRowClick={(event, row) => {
            if (onRowSelectionChange) onRowSelectionChange({ [row.id]: true });
            onRowSelection(row.original, row.index);
            document.querySelector('#content')?.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          rowSelection={rowSelection}
          showSelected
        />
      </>
    );
  },
);
