import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { AddIcon, BadgeCard, Button, DeleteIcon, InfoIcon, Input, Tooltip } from '@bp/ui-components';
import { LessonFormType, LessonFormUnitType } from '../../types';
import { v4 } from 'uuid';

export const EpochLessonUnitForm = ({ disabled }: { disabled: boolean }) => {
  const { values, setFieldTouched } = useFormikContext<LessonFormType>();
  const { t } = useTranslation();
  const formik = useFormikContext<LessonFormType>();

  return (
    <>
      <FieldArray name={'lessonUnit'}>
        {(arrayHelpers) => {
          return (
            <>
              <div className='form-group tks__grid'>
                {values.lessonUnit?.map((lessonUnit, index) => {
                  return (
                    <div key={lessonUnit.uuid} className='tks__row'>
                      <div className='tks__col col-xs-9 no-gap'>
                        <div className={'form-row'}>
                          <Input
                            readonly={disabled}
                            className={'quarter'}
                            type={'number'}
                            label={t('lessonUnit.duration') as string}
                            name={`lessonUnit.${index}.duration`}
                            onChange={formik.handleChange}
                            value={
                              formik.values.lessonUnit
                                ? formik.values.lessonUnit[index].duration ?? undefined
                                : undefined
                            }
                            suffix={
                              <div>
                                {t('common.hour', {
                                  count:
                                    formik.values.lessonUnit && formik.values.lessonUnit[index].duration
                                      ? formik.values.lessonUnit[index].duration ?? 0
                                      : 0,
                                })}
                              </div>
                            }
                          />
                          <Input
                            readonly={disabled}
                            className={'quarter'}
                            type={'number'}
                            label={t('common.count') as string}
                            name={`lessonUnit.${index}.count`}
                            onChange={formik.handleChange}
                            value={
                              formik.values.lessonUnit ? formik.values.lessonUnit[index].count ?? undefined : undefined
                            }
                          />
                          <Input
                            className={'quarter'}
                            type={'number'}
                            label={t(`lessonUnit.factor`) as string}
                            name={`lessonUnit.${index}.factor`}
                            readonly
                            onChange={formik.handleChange}
                            value={
                              formik.values.lessonUnit ? formik.values.lessonUnit[index].factor ?? undefined : undefined
                            }
                            suffix={
                              <Tooltip content={t('lessonUnit.tooltip.factor')}>
                                <InfoIcon className={'small'} />
                              </Tooltip>
                            }
                          />
                          <div className={'quarter align-center pl-10'}>
                            {formik.values.lessonUnit &&
                              formik.values.lessonUnit[index] &&
                              formik.values.lessonUnit[index].duration && (
                                <BadgeCard
                                  label={formik.values.subject?.name.slice(0, 2).toUpperCase() ?? ''}
                                  count={formik.values.lessonUnit[index].count ?? 0}
                                  width={
                                    Math.abs(Math.min(Math.floor(formik.values.lessonUnit[index].duration ?? 0), 4)) as
                                      | 1
                                      | 2
                                      | 3
                                      | 4
                                  }
                                ></BadgeCard>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className='tks__col col-xs-3'>
                        <Button
                          className='mt-6 align-end'
                          hierarchy='tertiary'
                          disabled={disabled}
                          icon={
                            <DeleteIcon
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  );
                })}
                <Button
                  key={values.subject?.uuid}
                  disabled={disabled}
                  hierarchy={'tertiary'}
                  icon={<AddIcon />}
                  onClick={async () => {
                    if (values.lessonUnit && values.lessonUnit.length > 0) {
                      for (const index in values.lessonUnit) {
                        await setFieldTouched(`lessonUnit.${index}`);
                      }
                    }
                    const lessonUnit: LessonFormUnitType = {
                      uuid: v4(),
                      count: null,
                      factor: null,
                      duration: null,
                      lessonUnitType: 'subjectHour',
                    };
                    arrayHelpers.push(lessonUnit);
                  }}
                >
                  {t('subject.subjectHour')}
                </Button>
              </div>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};
