import { t } from 'i18next';
import { RouteObject } from 'react-router-dom';
import { SettingsPage } from './SettingsPage';
import { SetupSubjectsAndSubjectContainer } from './SubjectsAndSubjectContainer/SetupSubjectsAndSubjectContainer';
import { SetupPersons } from './Persons/SetupPersons';
import { SetupClassesAndDivisions } from './ClassesAndDivisions/SetupClassesAndDivisions';
import { SetupRoomsAndBuildings } from './RoomsAndBuildings/SetupRoomsAndBuildings';
import { SetupSchoolYears } from './SchoolYears/SetupSchoolYears';
import { SetupDivisions } from './Divisions/SetupDivisions';
import { GradeGroups } from './GradeGroups/GradeGroups';
import { UsersPage } from './Users/UsersPage';

export const settingsRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <SettingsPage />,
    },
    {
      path: t('routes.subjects.slug') as string,
      element: <SetupSubjectsAndSubjectContainer />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.subjects.slug')}`,
          title: t('routes.subjects.title'),
        }),
      },
    },
    {
      path: t('routes.teacher.slug') as string,
      element: <SetupPersons />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.teacher.slug')}`,
          title: t('routes.teacher.title'),
        }),
      },
    },
    {
      path: t('routes.classes.slug') as string,
      element: <SetupClassesAndDivisions />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.classes.slug')}`,
          title: t('routes.classes.title'),
        }),
      },
    },
    {
      path: t('routes.rooms.slug') as string,
      element: <SetupRoomsAndBuildings />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.rooms.slug')}`,
          title: t('routes.rooms.title'),
        }),
      },
    },
    {
      path: t('routes.schoolyear.slug') as string,
      element: <SetupSchoolYears />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.schoolyear.slug')}`,
          title: t('routes.schoolyear.title'),
        }),
      },
    },
    {
      path: t('routes.divisions.slug') as string,
      element: <SetupDivisions />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.divisions.slug')}`,
          title: t('routes.divisions.title'),
        }),
      },
    },
    {
      path: t('routes.gradeGroups.slug'),
      element: <GradeGroups />,
      handle: {
        crumb: () => ({
          slug: t('routes.gradeGroups.slug'),
          title: t('routes.gradeGroups.title'),
        }),
      },
    },
    {
      path: t('routes.users.slug'),
      element: <UsersPage />,
      handle: {
        crumb: () => ({
          slug: t('routes.users.slug'),
          title: t('routes.users.title'),
        }),
      },
    },
  ];
};
