import { Input, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { SingleValue } from 'react-select';
import { LessonFormType } from '../../types';
import { LessonTableType } from '../../Tables/TimetableVersionLessonsTable';
import { useDeputateLessons } from '../../hooks/useDeputateLessons';
import { isNotEmpty } from '../../../../utils/typeguards';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';

export const EpochBasicsForm = ({
  status,
  filterSubjectContainers,
}: {
  status: LessonTableType['editStatus'];
  filterSubjectContainers: boolean;
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext<LessonFormType>();

  const currentClass =
    formik.values.lessonClasses && formik.values.lessonClasses.length === 1
      ? formik.values.lessonClasses[0].class
      : null;

  const { lessonsData } = useDeputateLessons({});

  const { subjectContainerData } = useLoadBasicData({ pause: false });

  const lessons = lessonsData
    .filter((lesson) => lesson.classes.some((c) => currentClass?.uuid === c.uuid && lesson.isEpochPlan === true))
    .map((l) => l.subjectUuid);

  const subjectContainers = subjectContainerData?.subjectContainers
    .filter((sc) => {
      if (!filterSubjectContainers) {
        return true;
      }
      if (sc.grades.length === 0) {
        return true;
      }
      if (isNotEmpty(currentClass?.grade)) {
        return sc.grades.includes(currentClass.grade);
      }
      return false;
    })
    .filter((sc) => {
      return !lessons.includes(sc.uuid);
    });

  const subjectContainerOptions = useCreateSelectOptions(subjectContainers, 'uuid', 'name');

  return (
    <div className='tks__grid'>
      <div className='tks__row'>
        <div className='tks__col col-xs-9 no-gap'>
          <Select
            options={subjectContainerOptions}
            isSearchable
            readonly={status === 'blocked' || !!formik.values.uuid}
            name={'subject'}
            onChange={async (option) => {
              const opt = option as SingleValue<SelectOptionType>;
              await formik.setFieldTouched(`subject`, true);
              if (opt) {
                const subject = subjectContainers?.find((s) => s.uuid === opt.value);
                await formik.setFieldValue(`subject`, { uuid: subject?.uuid, name: subject?.name }, true);
              } else {
                await formik.setFieldValue(`subject`, null, true);
              }
            }}
            value={subjectContainerOptions.find((option) => {
              return option.value === formik.values.subject?.uuid;
            })}
            label={t('lessonUnit.subjectContainer')}
          />
          <Input
            value={formik.values.subTitle ?? ''}
            name={'subTitle'}
            onChange={formik.handleChange}
            label={t('lesson.basics.subTitle')}
            tooltipText={t('lesson.tooltip.subTitle')}
            readonly={status === 'blocked'}
          />
          <Input
            value={formik.values.comment ?? ''}
            name={'comment'}
            onChange={formik.handleChange}
            label={t('lesson.basics.comment')}
            tooltipText={t('lesson.tooltip.comment')}
            readonly={status === 'blocked'}
          />
        </div>
      </div>
    </div>
  );
};
