import { ContextMenuItem } from '@bp/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomItem, TeachingBlockCardType } from '../components/TeachingBlockGrid/types';
import { getWarningTypeTranslation } from '../utils/getWarningTypeTranslation';
import { useTeachingBlockStore } from '../components/TeachingBlockGrid/TeachingBlockContext';

type TeachingBlockCardMenuProps = {
  card: TeachingBlockCardType;
  styles: CSSModuleClasses;
  hasWarnings: boolean;
  hasAvailabilityWarnings: boolean;
  isPicked: boolean;
  isPinned: boolean;
  onCardEditOpen?: () => void;
};

export const useTeachingBlockCardMenu = ({
  card,
  styles,
  hasWarnings,
  hasAvailabilityWarnings,
  isPicked,
  isPinned,
  onCardEditOpen,
}: TeachingBlockCardMenuProps) => {
  const { t } = useTranslation();

  const { pickCard, pinCard, lockCard, unlockCard, discardCard, setRoomsForCards, removeRoomsFromCards, cardRooms } =
    useTeachingBlockStore();

  const isPlaced = !!card.startDate;
  const hasRooms = card.rooms.length > 0;

  const contextMenu: ContextMenuItem[] = useMemo(() => {
    const menu: ContextMenuItem[] = [];

    menu.push({
      type: 'component',
      node: (
        <div
          className={styles['menu-name']}
          style={{
            color: card.badgeCardTextColor,
            background:
              card.classGridCards && card.classGridCards[0]
                ? (card.classGridCards[0].cssGradientString ?? undefined)
                : undefined,
          }}
        >
          {card.subject?.name}
        </div>
      ),
    });

    if (hasWarnings || hasAvailabilityWarnings || (isPlaced && !hasRooms)) {
      menu.push({
        type: 'ruler',
      });
    }

    if (hasWarnings) {
      menu.push({
        type: 'component',
        node: (
          <div className={styles['menu-warning']}>
            {card.warnings
              ?.map((warning) => {
                return `${getWarningTypeTranslation(warning.type)} (${warning.data?.map((d) => d.name)})`;
              })
              .join(', ')}
          </div>
        ),
      });
    }

    if (hasAvailabilityWarnings) {
      menu.push({
        type: 'component',
        node: (
          <div className={styles['menu-warning']}>
            {card.availabilityWarnings
              ?.map((warning) => {
                return getWarningTypeTranslation(warning.type);
              })
              .join(', ')}
          </div>
        ),
      });
    }

    if (isPlaced && !hasRooms) {
      menu.push({
        type: 'component',
        node: <div className={styles['menu-info']}>{t('pinboard.reason.noRoom')}</div>,
      });
    }

    menu.push(
      {
        type: 'ruler',
      },
      {
        label: isPicked ? t('pinboard.actions.unpick') : t('pinboard.actions.pick'),
        onClick: (e) => {
          e.stopPropagation();
          if (!card.locked) {
            if (isPicked) {
              pickCard(null);
            } else {
              pickCard(card, { x: e.clientX, y: e.clientY });
            }
          }
        },
        disabled: card.locked,
      },
      {
        label: isPinned ? t('pinboard.actions.unpin') : t('pinboard.actions.pin'),
        onClick: (e) => {
          e.stopPropagation();
          pinCard(card ?? card);
        },
      },
      {
        label: card.locked ? t('pinboard.actions.unlock') : t('pinboard.actions.lock'),
        onClick: () => {
          if (card.locked) unlockCard(card);
          else lockCard(card);
        },
        disabled: false,
      },
      {
        type: 'ruler',
      },
      {
        label: t('rooms.title.plural'),
        subContent: getRoomsSubmenu(),
        disabled: !isPlaced,
      },
      {
        label: t('pinboard.actions.editTime'),
        onClick: () => onCardEditOpen && onCardEditOpen(),
        disabled: !isPlaced,
      },
    );

    menu.push(
      {
        type: 'ruler',
      },
      {
        label: t('common.remove'),
        onClick: () => discardCard(card),
        type: 'error',
        disabled: !isPlaced,
      },
    );

    function getRoomsSubmenu(): ContextMenuItem[] {
      const submenu: ContextMenuItem[] = [];

      const rooms = cardRooms.get(card.uuid);
      const assignedRooms: RoomItem[] = rooms?.get('assigned') ?? [];

      const hasAssigned = (rooms?.get('assigned')?.length ?? 0) > 0;
      const hasLessons = (rooms?.get('lesson')?.length ?? 0) > 0;
      const hasSubjects = (rooms?.get('subject')?.length ?? 0) > 0;
      const hasTeachers = (rooms?.get('teacher')?.length ?? 0) > 0;
      const hasClasses = (rooms?.get('class')?.length ?? 0) > 0;

      assignedRooms.forEach((r) => submenu.push(mapRoom(r)));
      if (hasAssigned && (hasLessons || hasSubjects || hasTeachers || hasClasses)) {
        submenu.push({ type: 'ruler' });
      }

      rooms?.get('lesson')?.forEach((r) => submenu.push(mapRoom(r)));
      if (hasLessons && (hasSubjects || hasTeachers || hasClasses)) {
        submenu.push({ type: 'ruler' });
      }

      rooms?.get('subject')?.forEach((r) => submenu.push(mapRoom(r)));
      if (hasSubjects && (hasTeachers || hasClasses)) {
        submenu.push({ type: 'ruler' });
      }

      rooms?.get('teacher')?.forEach((r) => submenu.push(mapRoom(r)));
      if (hasTeachers && hasClasses) {
        submenu.push({ type: 'ruler' });
      }

      rooms?.get('class')?.forEach((r) => submenu.push(mapRoom(r)));

      function mapRoom(room: RoomItem): ContextMenuItem {
        return {
          value: !!assignedRooms.find((r) => r.value === room.value),
          className: room.inUse ? styles['menu-room-used'] : undefined,
          onValueChange: (value) => onRoomValueChange(value as boolean, room),
          label: room.label,
          type: 'switch',
        };
      }

      async function onRoomValueChange(value: boolean, room: RoomItem) {
        if (value) {
          void setRoomsForCards(
            [card],
            [
              {
                name: room.label,
                uuid: room.value,
              },
            ],
            true,
          );
        } else {
          void removeRoomsFromCards(
            [card],
            [
              {
                name: room.label,
                uuid: room.value,
              },
            ],
          );
        }
      }
      return submenu;
    }

    return menu;
  }, [
    styles,
    card,
    hasWarnings,
    hasAvailabilityWarnings,
    isPlaced,
    hasRooms,
    isPicked,
    t,
    isPinned,
    pickCard,
    pinCard,
    unlockCard,
    lockCard,
    onCardEditOpen,
    discardCard,
    cardRooms,
    setRoomsForCards,
    removeRoomsFromCards,
  ]);

  return {
    menu: contextMenu,
    hasRooms,
  };
};
