import { AddIcon, Button, Modal } from '@bp/ui-components';
import { FC, useMemo, useState } from 'react';
import { TeachingBlockVersionsOverview } from './TeachingBlockVersionsOverview';
import { useTranslation } from 'react-i18next';
import styles from './TeachingBlockVersionsOverview.module.scss';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import {
  use_SubjectContainersQuery,
  useCreateBackendTeachingBlockVersionMutation,
} from '../../../types/planung-graphql-client-defs';
import { showSuccessCreateToast, showUserErrorToast } from '../../../utils/toast';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';

type VersionsOverviewModalProps = {
  currentSubjectContainerUuid: string;
  isOpen: boolean;
  onClose: () => void;
};

export const TeachingBlockOverviewModal: FC<VersionsOverviewModalProps> = observer(
  ({ currentSubjectContainerUuid, isOpen, onClose }) => {
    const { t } = useTranslation();
    const { pimAuthClaims } = useAuthClaims();
    const schoolYear = useUserConfigContext().selectedSchoolYear;

    const context = useMemo(() => {
      return {
        additionalTypenames: ['TeachingBlockVersion', 'TeachingBlock', 'SubjectContainer'],
      };
    }, []);

    const [loading, setLoading] = useState<boolean>(false);

    const [, createTeachingBlockVersionMutation] = useCreateBackendTeachingBlockVersionMutation();

    const [{ data: subjectContainersData }] = use_SubjectContainersQuery({
      variables: {
        where: {
          uuid: currentSubjectContainerUuid,
        },
      },
    });

    async function onAdd() {
      setLoading(true);
      const response = await createTeachingBlockVersionMutation(
        {
          organizationUuid: pimAuthClaims.getOrganizationUuid(),
          schoolYearUuid: schoolYear?.uuid ?? '',
          subjectContainerUuid: currentSubjectContainerUuid,
        },
        context,
      );
      response.error ? showUserErrorToast({ error: response.error }) : showSuccessCreateToast();
      setLoading(false);
    }

    return (
      <Modal
        className={styles['teaching-block-versions-overview-modal']}
        title={subjectContainersData?.subjectContainers[0]?.name}
        subtitle={`${t('common.grades')}: ${subjectContainersData?.subjectContainers[0]?.grades.join(', ')}`}
        isOpen={isOpen}
        onRequestClose={onClose}
        loading={loading}
        actions={
          <Button onClick={() => onAdd()} hierarchy='tertiary' icon={<AddIcon />}>
            {t('teachingBlockVersion.add')}
          </Button>
        }
      >
        <TeachingBlockVersionsOverview
          subjectContainerUuid={currentSubjectContainerUuid}
          onNavigate={() => setLoading(true)}
          schoolYearUuid={schoolYear?.uuid ?? ''}
        />
      </Modal>
    );
  },
);
