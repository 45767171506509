import { useTimetableStore } from '../../TimetableProvider';
import styles from '../GridCard.module.scss';
import React from 'react';
import { LockIcon, PinIcon, RoomIcon } from '@bp/ui-components';
import { observer } from 'mobx-react-lite';

export const CardIcons = observer(({ cardUuid }: { cardUuid: string }) => {
  const store = useTimetableStore();

  if (store.highlightMode.includes('noIcons')) {
    return <></>;
  }
  const pinned = store.pinnedCard === cardUuid;
  const locked = store.lockedCards.get(cardUuid);
  const rooms = store.cardRooms.get(cardUuid);

  return (
    <>
      {pinned && <PinIcon className={styles.icon} />}
      {locked && <LockIcon className={styles.icon} />}
      {(!rooms || rooms?.size === 0) && <RoomIcon className={styles.icon} />}
    </>
  );
});
