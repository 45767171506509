import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useMutation } from 'urql';
import { CreateTimeGridDocument, UpdateTimeGridDocument } from '../../../types/planung-graphql-client-defs';
import { TimeGridType } from '../graphql/types';
import { FormikHelpers } from 'formik/dist/types';
import { Button, Input, showToast } from '@bp/ui-components';
import { Form, Formik } from 'formik';
import { schema } from './validation/schema';
import dayjs from 'dayjs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';

type TimeGridFormTypes = {
  timeGrid?: TimeGridType | null;
  handleClose: () => void;
};

const TimeGridForm: FC<TimeGridFormTypes> = ({ timeGrid, handleClose }) => {
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('TIME_GRID');
  const { pimAuthClaims } = useAuthClaims();

  const [, create] = useMutation(CreateTimeGridDocument);
  const [, update] = useMutation(UpdateTimeGridDocument);

  const initialValue: TimeGridType = {
    name: timeGrid?.name ?? '',
    description: timeGrid?.description ?? '',
    uuid: timeGrid?.uuid ?? '',
    itemsConnection: {
      edges:
        timeGrid?.itemsConnection.edges.map((edge) => {
          const { __typename, ...other } = edge;
          return {
            ...other,
            node: {
              uuid: edge.node.uuid,
              name: edge.node.name,
              shortName: edge.node.shortName,
              start: dayjs(edge.node.start),
              end: dayjs(edge.node.end),
              order: edge.node.order,
              reliable: edge.node.reliable,
              pause: edge.node.pause,
              print: edge.node.print,
            },
          };
        }) ?? [],
    },
    itemsWithoutPause: { edges: [] },
  };

  const handleSubmit = async (values: TimeGridType, formHelpers: FormikHelpers<TimeGridType>) => {
    let mutationResult;
    if (timeGrid) {
      mutationResult = await update(
        {
          uuid: timeGrid?.uuid,
          update: {
            name: values.name,
            description: values.description,
          },
        },
        context,
      );
    } else {
      mutationResult = await create(
        {
          input: {
            name: values.name,
            description: values.description,
            organization: {
              connect: {
                where: {
                  node: {
                    uuid: pimAuthClaims.getOrganizationUuid(),
                  },
                },
              },
            },
          },
        },
        context,
      );
    }
    if (!mutationResult || mutationResult.error) {
      showToast('Ups.', { type: 'error' });
    } else {
      showToast('Success', { type: 'success' });
    }
    formHelpers.resetForm();
    handleClose();
  };

  return (
    <>
      <Formik validationSchema={schema} initialValues={initialValue} enableReinitialize onSubmit={handleSubmit}>
        {(formik) => {
          const values = formik.values;
          return (
            <Form>
              <div className='tks__grid mt-6 mb-10 small-gap'>
                <div className='tks__row row-xs-10'>
                  <div className='tks__col col-xs-12'>
                    <Input
                      label={t('common.name')}
                      name={'name'}
                      onChange={formik.handleChange}
                      value={values.name}
                      error={formik.errors.name}
                    />
                  </div>
                </div>
                <div className='tks__row row-xs-10'>
                  <div className='tks__col col-xs-12'>
                    <Input
                      label={t('common.description') as string}
                      name={'description'}
                      onChange={formik.handleChange}
                      value={values.description}
                      error={formik.errors.description}
                    />
                  </div>
                </div>
              </div>
              <div className={'modal-bottom'}>
                <Button
                  hierarchy='tertiary'
                  type='button'
                  onClick={() => {
                    formik.resetForm();
                    handleClose();
                  }}
                  className={'buttons'}
                >
                  {t('common.cancelChanges')}
                </Button>
                <Button
                  type='submit'
                  disabled={formik.isSubmitting || !formik.dirty || formik.isValidating}
                  className={'buttons'}
                >
                  {t('common.save')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default TimeGridForm;
