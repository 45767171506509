import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  Modal,
  ProfileIcon,
  Select,
  SelectOptionType,
  Table,
  TableColumns,
  Tooltip,
  useDefaultSorting,
} from '@bp/ui-components';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import {
  PersonRole,
  SortDirection,
  useGetApplicationGrantsQuery,
  usePimProfilesQuery,
  UserStatus,
  useUpdateGrantedProfilesMutation,
  useUpdatePeopleMutation,
} from '../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { EditProfileForm } from '../EditProfileForm/EditProfileForm';
import { GrantRolesForm, GrantRolesFormValues } from './Forms/GrantRolesForm';
import { getRoleName } from '../../utils/getRoleName';
import { PersonForm } from '../Persons/Forms/PersonForm';
import { SingleValue } from 'react-select';
import { ConnectPimProfileForm } from './Forms/ConnectPimProfileForm';
import { showErrorToast, showSuccessUpdateToast } from '../../utils/toast';
import { getTypeChecktArray } from '../../utils/typeguards';
import { PlanungAdminRoles } from '@bp/pim-auth-constants';
import { useLoadBasicData } from '../../hooks/useLoadBasicData';

export type UserDataType = {
  uuid: string | null;
  roleName: string;
  user: boolean;
  planungUser: boolean;
  pimFullName?: string | null;
  fullName: string;
  pimProfileUuid?: string | null;
  personalId?: string | null;
  displayName?: string | null;
  selectName?: string | null;
  birthDate?: string | null;
  firstName?: string;
  lastName?: string;
  email?: string | null;
  salutation?: string | null;
  title?: string | null;
  prefixName?: string | null;
  shortName?: string | null;
};

type PimPerson = {
  pimFullName?: string | null;
  fullName: string;
  uuid: string;
  roleName: string;
  user: boolean;
  planungUser: boolean;
  pimProfileUuid?: string;
};

export const UserTable = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const personsContext = useMemorizedCacheTag('PERSONS');
  const applicationContext = useMemorizedCacheTag('APPLICATION_GRANT');

  const [data, setData] = useState<UserDataType | null>(null);
  const [pimProfileUuid, setPimProfileUuid] = useState<string | null>(null);
  const [connectPimProfileModalOpen, setConnectPimProfileModalOpen] = useState(false);
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [grantRolesModalOpen, setGrantRolesModalOpen] = useState(false);
  const [createPersonModalOpen, setCreatePersonModalOpen] = useState(false);
  const [newPersonRole, setNewPersonRole] = useState<PersonRole>(PersonRole.Other);

  // queries / mutations
  const [{ data: pimProfiles }, refetchPimProfiles] = usePimProfilesQuery({
    variables: {
      where: { organization: { uuid: pimAuthClaims.getOrganization().uuid } },
      options: { sort: [{ lastName: SortDirection.Asc }] },
    },
    context: personsContext,
  });

  const { teacherData, refetchTeacherData } = useLoadBasicData({ pause: false });

  const [{ data: applicationGrants }, refetchApplicationGrants] = useGetApplicationGrantsQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: applicationContext,
  });

  const [, updatePerson] = useUpdatePeopleMutation();
  const [, updateGrantedProfiles] = useUpdateGrantedProfilesMutation();

  const disconnectProfiles = async (data: UserDataType) => {
    const { uuid } = data;
    if (uuid) {
      const { error } = await updatePerson(
        {
          where: {
            uuid: uuid,
          },
          update: {
            pimProfileUuid: null,
          },
        },
        { context: personsContext },
      );

      error ? showErrorToast(error) : showSuccessUpdateToast(getTypeChecktArray<string>([data.displayName]));
    }
  };

  // button actions
  const createPerson = (person: UserDataType) => {
    setPimProfileUuid(person.uuid);
    setCreatePersonModalOpen(true);
  };

  const connectPimProfile = (person: UserDataType) => {
    setData(person);
    setConnectPimProfileModalOpen(true);
  };

  const grantRoles = (data: UserDataType) => {
    setData(data);
    setGrantRolesModalOpen(true);
  };

  const editProfile = (data: UserDataType) => {
    data && setData(data);
    data && setEditProfileModalOpen(true);
  };

  // modals close handler
  const handleConnectPimProfileModalClose = () => {
    refetchApplicationGrants({ requestPolicy: 'network-only' });
    refetchTeacherData({ requestPolicy: 'network-only' });
    refetchPimProfiles({ requestPolicy: 'network-only' });
    setConnectPimProfileModalOpen(false);
  };

  const handleEditProfileModalClose = () => {
    setData(null);
    refetchApplicationGrants({ requestPolicy: 'network-only' });
    refetchTeacherData({ requestPolicy: 'network-only' });
    refetchPimProfiles({ requestPolicy: 'network-only' });
    setEditProfileModalOpen(false);
  };

  const handleGrantRolesModalClose = async (values?: GrantRolesFormValues) => {
    const uuid = (data as UserDataType).pimProfileUuid ?? (data as UserDataType).uuid;
    if (values !== undefined && uuid) {
      const roles: string[] = []; // empty roles revoke the right to use planung!
      if (values.isPlanungUser || values.isPlanungAdmin) {
        roles.push(PlanungAdminRoles.Admin);
      }
      const resp = await updateGrantedProfiles(
        {
          organizationUuid: pimAuthClaims.getOrganization().uuid,
          profiles: [uuid],
          roles,
          frontendUri: import.meta.env.VITE_APP_BASE_URI,
        },
        { context: personsContext },
      );

      if (!resp.error) {
        showSuccessUpdateToast(getTypeChecktArray<string>([data?.displayName]));
      }

      if (resp.error) {
        showErrorToast(resp.error);
      }
    }
    setData(null);
    refetchApplicationGrants({ requestPolicy: 'network-only' });
    refetchTeacherData({ requestPolicy: 'network-only' });
    refetchPimProfiles({ requestPolicy: 'network-only' });
    setGrantRolesModalOpen(false);
  };

  // table data
  const tableData: UserDataType[] = useMemo(() => {
    const pimFullName = (person: Omit<UserDataType, 'roleName' | 'user' | 'planungUser'>) => {
      const profileUuid = person.pimProfileUuid;
      const connectedPIMProfile = pimProfiles?.pimProfiles.find((p) => p.uuid === profileUuid);

      if (connectedPIMProfile) return connectedPIMProfile.fullName;
      return person.pimFullName;
    };

    const planungPersons =
      teacherData?.people
        .map((person) => ({
          ...person,
          email: person.email ?? pimProfiles?.pimProfiles.find((p) => p.uuid === person.pimProfileUuid)?.email ?? '',
          user: pimProfiles?.pimProfiles.find((p) => p.uuid === person.pimProfileUuid)?.userStatus === UserStatus.Full,
          planungUser: !!applicationGrants?.grantedProfiles.find((uuid) => uuid === person.pimProfileUuid),
          roleName: getRoleName(person.organizationConnection.edges[0].properties.name),
          pimFullName: pimFullName(person) ?? '',
          isConnected: !!pimProfiles?.pimProfiles.find((p) => p.uuid === person.pimProfileUuid),
        }))
        .sort((a, b) => {
          // Sorting by isConnected in descending order (connected first)
          return a.isConnected === b.isConnected ? 0 : a.isConnected ? -1 : 1;
        }) ?? [];

    const pimPersons: PimPerson[] =
      pimProfiles?.pimProfiles.map((p) => ({
        ...p,
        pimFullName: p.selectName ?? p.displayName,
        displayName: '', // necessary to clear in table
        roleName: '', // necessary to clear in table
        fullName: '', // necessary to clear in table
        user: p.userStatus === UserStatus.Full,
        planungUser: !!applicationGrants?.grantedProfiles.find((uuid) => uuid === p.uuid),
        pimProfileUuid: p.uuid,
      })) ?? [];

    const connectedPimProfileUuids = planungPersons.filter((p) => p.pimProfileUuid !== '').map((p) => p.pimProfileUuid);
    const notConnectedPimProfiles = pimPersons.filter((p) => !connectedPimProfileUuids.includes(p.uuid));

    return [...planungPersons, ...notConnectedPimProfiles];
  }, [applicationGrants, teacherData, pimProfiles]);

  const profiles = pimProfiles?.pimProfiles;
  const memoizedColumns = useMemo((): TableColumns<UserDataType>[] => {
    return [
      {
        id: 'roleName',
        accessorKey: 'roleName',
        header: 'Planung ' + t('role.title.singular'),
        size: 120,
      },
      {
        id: 'displayName',
        accessorKey: 'displayName',
        header: 'Planung ' + t('profile.title.singular'),
        size: 350,
        canExpand: true,
      },
      {
        id: 'connect',
        header: '',
        accessorFn: (originalRow) => {
          return '';
        },
        enableGlobalFilter: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell) => {
          const person = cell.row.original as UserDataType;

          const pimProfileForPerson = (person: UserDataType) =>
            profiles?.find((pimProfile) => pimProfile.uuid === person.pimProfileUuid);

          if (!pimProfileForPerson(person)) {
            return (
              'personalId' in person && (
                <Button
                  style={{ width: '160px' }}
                  hierarchy='secondary'
                  onClick={() => connectPimProfile(person)}
                  icon={<ArrowRightIcon className='svg-icon' />}
                  iconPosition={'right'}
                >
                  {t('profile.connectProfile')}
                </Button>
              )
            );
          } else {
            return (
              !('personalId' in person) && (
                <Button
                  style={{ width: '160px' }}
                  hierarchy='secondary'
                  onClick={() => createPerson(person ?? null)}
                  icon={<ArrowLeftIcon className='svg-icon' />}
                >
                  {t('profile.createProfile')}
                </Button>
              )
            );
          }
        },
        size: 160,
      },
      {
        id: 'pimFullName',
        accessorKey: 'pimFullName',
        header: t('users.title'),
        size: 200,
        canExpand: true,
      },
      {
        id: 'email',
        accessorKey: 'email',
        header: t('common.email'),
        size: 200,
      },
      {
        id: 'button',
        size: 75,
        alignment: 'right',
        header: () => <ProfileIcon />,
        accessorFn: (originalRow) => {
          return '';
        },
        enableGlobalFilter: false,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return row.original.planungUser ? (
            <Tooltip content={t('profile.isPlanungUser')}>
              <ProfileIcon color={'var(--color-primary)'} />
            </Tooltip>
          ) : row.original.user ? (
            <Tooltip content={t('profile.noAccess')}>
              <ProfileIcon />
            </Tooltip>
          ) : (
            <Tooltip content={t('profile.noProfile')}>
              <ProfileIcon color={'var(--color-grey-lighter)'} />
            </Tooltip>
          );
        },
      },
    ];
  }, [profiles]);

  const { sorting, onSortingChange } = useDefaultSorting();

  return (
    <>
      <Table<UserDataType>
        columns={memoizedColumns}
        data={tableData}
        sorting={sorting}
        emptyStateSettings={{
          hideIcon: true,
          title: t('profile.noProfile'),
          subtitle: t('profile.noProfileHint'),
          padding: 'l',
        }}
        canScroll
        minHeight={600}
        showSort
        showActionBar={true}
        isOnWhite={false}
        showBorderRadius
        showShadow
        onSortingChange={onSortingChange}
        lastCol={(row) => {
          const data = row.original;
          return (
            <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon className='svg-icon' />} />}>
              <DropdownMenu
                data={[
                  {
                    label: t('common.edit'),
                    onClick: () => data.pimProfileUuid && editProfile(data),
                    disabled: !data.pimProfileUuid, // has Person
                  },
                  {
                    label: t('common.disconnect'),
                    onClick: () => data.pimProfileUuid && disconnectProfiles(data),
                    disabled: !data.pimProfileUuid || data.pimProfileUuid === data.uuid,
                  },
                  {
                    label: t('common.userPermissions'),
                    onClick: () => (data.pimProfileUuid || data.pimFullName) && grantRoles(data),
                    disabled: !data.pimProfileUuid,
                  },
                ]}
              />
            </Dropdown>
          );
        }}
      />

      {/* -- Modals -- */}
      {connectPimProfileModalOpen && (
        <Modal
          title={t('users.add')}
          isOpen={connectPimProfileModalOpen}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          onRequestClose={() => {
            handleConnectPimProfileModalClose();
            setData(null);
          }}
        >
          <Button
            onClick={() => {
              handleConnectPimProfileModalClose();
              setEditProfileModalOpen(true);
            }}
          >
            {t('users.create')}
          </Button>
          <div className={'mt-6'}>
            <strong>{t('common.or')}</strong>
          </div>
          <ConnectPimProfileForm
            context={personsContext}
            onClose={handleConnectPimProfileModalClose}
            pimProfiles={pimProfiles?.pimProfiles ?? []}
            uuid={data?.uuid ?? ''}
          />
        </Modal>
      )}
      {editProfileModalOpen && (
        <Modal
          title={data ? t('users.edit') : t('users.add')}
          isOpen={editProfileModalOpen}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          onRequestClose={handleEditProfileModalClose}
        >
          <EditProfileForm setModalClosed={handleEditProfileModalClose} data={data} />
        </Modal>
      )}
      {grantRolesModalOpen && (
        <Modal
          title={t('profile.grantRolesTo', { profile: data?.pimFullName })}
          isOpen={grantRolesModalOpen}
          shouldCloseOnEsc={true}
          onRequestClose={() => setGrantRolesModalOpen(false)}
          width='m'
        >
          <GrantRolesForm
            wasPlanungUser={!!(data as UserDataType)?.planungUser}
            handleClose={handleGrantRolesModalClose}
          />
        </Modal>
      )}

      {createPersonModalOpen && (
        <Modal
          isOpen={createPersonModalOpen}
          onRequestClose={() => setCreatePersonModalOpen(false)}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          title={t('persons.add')}
        >
          <div className={'tks__grid'}>
            <div className={'tks__row'}>
              <div className={'col-xs-2'}>
                <Select
                  isMulti={false}
                  label={t('profile.grantRolesTo', { profile: (data as UserDataType)?.displayName })}
                  options={[
                    {
                      value: PersonRole.Other,
                      label: t('persons.other'),
                    },
                    {
                      value: PersonRole.Teacher,
                      label: t('persons.title.singular'),
                    },
                  ]}
                  defaultValue={{
                    value: PersonRole.Other,
                    label: t('persons.other'),
                  }}
                  onChange={(value) => {
                    const selectedValue = value as SingleValue<SelectOptionType>;
                    selectedValue && setNewPersonRole(selectedValue.value as PersonRole);
                  }}
                  name={'role'}
                />
              </div>
            </div>
          </div>

          <PersonForm
            htmlColorsInUse={[]}
            personsRole={newPersonRole}
            closeForm={() => setCreatePersonModalOpen(false)}
            pimProfileUuid={pimProfileUuid}
          />
        </Modal>
      )}
    </>
  );
};
