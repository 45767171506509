import styles from './../TimetableGrid.module.scss';
import { RowItem } from '../../../stores/PinboardStore';
import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { GridDay } from '../GridDay/GridDay';
import { useTimetableStore } from '../TimetableProvider';
import { GridRowName } from '../GridRowName/GridRowName';
import classNames from 'classnames';

type GridRowProps = {
  row: RowItem;
  daysWidth: string;
  cellWidth: string;
};

export const GridRow: FC<GridRowProps> = observer(({ row, daysWidth, cellWidth }) => {
  const pinboardStore = useTimetableStore();
  const isFullscreen = pinboardStore.isFullscreen;
  const days = pinboardStore.days;
  const hours = pinboardStore.hours;
  const classes = classNames(styles['grid-row'], {
    [styles['is-fullscreen']]: isFullscreen,
  });
  return (
    <div className={classes}>
      <GridRowName row={row} />
      {days.map((day, index) => {
        return (
          <GridDay
            key={`${row.value}-${day.value}`}
            daysWidth={daysWidth}
            hours={hours}
            day={day}
            dayIndex={index}
            row={row}
            cellWidth={cellWidth}
          />
        );
      })}
    </div>
  );
});
