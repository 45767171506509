import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { Form, Formik, FormikHelpers } from 'formik';
import { Checkbox, DatePicker, Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import {
  use_CreateTeachingBlockAvailabilityExclusionsMutation,
  use_TeachingBlockAvailabilityExclusionsQuery,
  use_UpdateTeachingBlockAvailabilityExclusionsMutation,
} from '../../types/planung-graphql-client-defs';
import { isFirstAfterSecond, isFirstBeforeSecond } from '../../utils/dateCalculations';
import { TeachingBlockExclusionType } from '../TeachingBlockVersion/graphql/types';
import { availabilityStoreHandler } from '../../pages/Timetable/Plan/TeachingBlockVersion/TeachingBlockVersion';

export type ExclusionTimeframeFormProps = {
  type: 'Class' | 'Subject' | 'Person' | 'Room';
  teachingBlockVersionUuid: string;
  holderUuid: string;
  exclusionUuid?: string | null;
  onClose: () => void;
};

export const ExclusionTimeframeForm = ({
  type,
  teachingBlockVersionUuid,
  holderUuid,
  exclusionUuid,
  onClose,
}: ExclusionTimeframeFormProps) => {
  const { t } = useTranslation();

  const context = useMemorizedCacheTag('TEACHING_BLOCK_AVAILABILITY_EXCLUSION');

  const [{ data: exclusionsData }] = use_TeachingBlockAvailabilityExclusionsQuery({
    context,
  });

  const data = exclusionsData?.teachingBlockAvailabilityExclusions.find((e) => e.uuid === exclusionUuid);

  const values: TeachingBlockExclusionType = {
    uuid: data?.uuid ?? '',
    holder: data?.holder ?? {
      __typename: 'Class',
      uuid: holderUuid,
    },
    from: data?.from ? new Date(data?.from) : new Date(),
    to: data?.to ? new Date(data?.to) : new Date(),
    priority: data?.priority ?? 0,
    comment: data?.comment ?? '',
  };

  const [, update] = use_UpdateTeachingBlockAvailabilityExclusionsMutation();
  const [, create] = use_CreateTeachingBlockAvailabilityExclusionsMutation();
  const availabilityStore = availabilityStoreHandler.get(teachingBlockVersionUuid);

  const handleSubmit = async (
    values: TeachingBlockExclusionType,
    formHelpers: FormikHelpers<TeachingBlockExclusionType>,
  ) => {
    if (values.uuid && data) {
      availabilityStore.removeExclusion({
        from: data.from,
        to: data.to,
        holder: {
          __typename: type,
          uuid: holderUuid,
        },
        priority: data.priority, // old value
      });

      availabilityStore.setExclusion({
        from: values.from,
        to: values.to,
        holder: {
          __typename: type,
          uuid: holderUuid,
        },
        priority: values.priority,
      });
      await update(
        {
          where: { uuid: values?.uuid },
          update: {
            teachingBlockVersion: {
              connect: { where: { node: { uuid: teachingBlockVersionUuid } } },
            },
            holder: {
              [type]: { connect: { where: { node: { uuid: holderUuid } } } },
            },
            from: values.from,
            to: values.to,
            priority: values.priority,
            comment: values.comment,
          },
        },
        context,
      );
    } else {
      availabilityStore.setExclusion({
        from: values.from,
        to: values.to,
        holder: {
          __typename: type,
          uuid: holderUuid,
        },
        priority: values.priority,
      });
      await create(
        {
          input: {
            teachingBlockVersion: {
              connect: { where: { node: { uuid: teachingBlockVersionUuid } } },
            },
            holder: {
              [type]: { connect: { where: { node: { uuid: holderUuid } } } },
            },
            from: values.from,
            to: values.to,
            priority: values.priority,
            comment: values.comment,
          },
        },
        context,
      );
    }
    formHelpers.resetForm();
    onClose();
  };

  return (
    <Formik<TeachingBlockExclusionType> onSubmit={handleSubmit} initialValues={values}>
      {({ values, errors, isSubmitting, isValidating, setFieldValue }) => {
        return (
          <Form>
            <Grid useFormGap>
              <GridRow spacingBottom='s' spacingTop='s' breakpoint='phone'>
                <GridColumn width={6}>
                  <DatePicker
                    label={t('common.from')}
                    value={values.from}
                    onChange={(date) => {
                      if (isFirstAfterSecond(date, values.to)) setFieldValue('to', date);
                      setFieldValue('from', date);
                    }}
                    name='from'
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <DatePicker
                    label={t('common.until')}
                    value={values.to}
                    onChange={(date) => {
                      if (isFirstBeforeSecond(date, values.from)) setFieldValue('from', date);
                      setFieldValue('to', date);
                    }}
                    name='to'
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingTop='s' spacingBottom='s'>
                <Input
                  label={t('common.comment')}
                  onChange={(event) => setFieldValue('comment', event.target.value)}
                  name='comment'
                  value={values.comment}
                />
              </GridRow>
              <GridRow spacingTop='none'>
                <Checkbox
                  name='is-partial'
                  onChange={(event) => setFieldValue('priority', event.target.checked ? 1 : 0)}
                  checked={values.priority === 1}
                  label={t('teachingBlockVersion.exclusion.partial')}
                />
              </GridRow>
            </Grid>
            <ModalBottomButtons
              closeButton={{
                callback: onClose,
              }}
              errors={errors}
              isLoading={isSubmitting || isValidating}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
