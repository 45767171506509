import { FC, MouseEvent } from 'react';
import { BadgeCard, ContextMenu } from '@bp/ui-components';
import styles from './TeachingBlockCard.module.scss';
import classNames from 'classnames';
import { TeachingBlockCardType } from '../types';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import { observer } from 'mobx-react-lite';
import { isBadgeCardWidth } from '../../../utils/typeguards';
import { useTeachingBlockCardMenu } from '../../../hooks/useTeachingBlockCardMenu';

export interface TeachingBlockCardProps {
  card: TeachingBlockCardType;
  passive: boolean;
  duration?: number;
  count?: number;
  onHover?: (isHovering: boolean, card: TeachingBlockCardType) => void;
  onClick?: (event: MouseEvent<HTMLDivElement>, card: TeachingBlockCardType) => void;
  className?: string | undefined;
}

export const TeachingBlockCard: FC<TeachingBlockCardProps> = observer(
  ({ card, count = 1, duration, passive, onHover, onClick, className }) => {
    const { pickedCard } = useTeachingBlockStore();

    const realDuration = duration ? Math.ceil(duration / 7) : (card.duration ?? 1);

    const { menu } = useTeachingBlockCardMenu({
      card,
      styles,
      hasWarnings: false,
      hasAvailabilityWarnings: false,
      isPicked: pickedCard?.card === card,
      isPinned: !!card.pinned,
    });

    const wrapperClasses = classNames(
      styles['teaching-block-card'],
      {
        [styles['is-passive']]: passive,
        [styles['is-multi']]: count >= 1,
        [styles['is-picked']]: pickedCard?.card.uuid === card.uuid,
        [styles['is-pinned']]: card.pinned,
        [styles['is-locked']]: card.locked,
        [styles['has-conflict']]:
          (card.availabilityWarnings && card.availabilityWarnings.length > 0) ||
          (card.warnings && card.warnings.length > 0),
      },
      className,
    );

    return (
      <>
        <ContextMenu className={styles.menu} data={menu} parent={document.getElementById('teaching-block-grid')}>
          <div
            className={wrapperClasses}
            title={card.label}
            onClick={(e) => {
              !card.loading && onClick && onClick(e, card);
            }}
            onMouseEnter={() => onHover && onHover(true, card)}
            onMouseLeave={() => onHover && onHover(false, card)}
          >
            <BadgeCard
              count={count}
              color={card.badgeCardTextColor}
              width={isBadgeCardWidth(realDuration) ? realDuration : 6}
              label={card.subject?.shortName}
              rows={card.badgeCardRows}
              showHover
            />
          </div>
        </ContextMenu>
      </>
    );
  },
);
