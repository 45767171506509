export const isBrightColor = (hexcolor: string) => {
  if (hexcolor.startsWith('#')) {
    hexcolor = hexcolor.slice(1);
  }

  // handle three-character hexcode
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split('')
      .map((hex) => {
        return hex + hex;
      })
      .join('');
  }

  const r = parseInt(hexcolor.substring(0, 2), 16);
  const g = parseInt(hexcolor.substring(2, 4), 16);
  const b = parseInt(hexcolor.substring(4, 6), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128;
};
