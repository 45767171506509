import { observer } from 'mobx-react-lite';
import { PickedCardView } from './PickedCardView';
import { useEffect } from 'react';
import { useTimetableStore } from '../TimetableProvider';

export const CardPreview = observer(() => {
  const pinboardStore = useTimetableStore();
  const pickedCard = pinboardStore.pickedCard;

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        pinboardStore.discardPickedCard();
      }
    }
    // function handleClick() {
    //   if (!pinboardStore.conflictModal.isOpen) {
    //     pinboardStore.discardPickedCard();
    //   }
    // }

    document.addEventListener('keydown', handleKeyDown);
    //  document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      //  document.removeEventListener('click', handleClick);
    };
  }, [pickedCard?.cardUuid, pinboardStore]);

  if (!pickedCard) {
    return <></>;
  }
  return <PickedCardView card={pickedCard} />;
});
