import { observer } from 'mobx-react-lite';
import styles from '../TimetableGrid.module.scss';
import React, { MouseEvent, useCallback } from 'react';
import classNames from 'classnames';
import { useTimetableStore } from '../TimetableProvider';
import { ContextMenu, ContextMenuItem } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { RowItem } from '../../../stores/PinboardStore';

export const GridRowName = observer(({ row }: { row: RowItem }) => {
  const pinboardStore = useTimetableStore();
  const isSelected = pinboardStore.selectedRows.some((selected) => selected.value === row.value);
  const { t } = useTranslation();

  const nameClasses = classNames([
    styles.name,
    { [styles['selected']]: isSelected },
    {
      [styles.highlight]:
        (pinboardStore.context === 'classes' &&
          pinboardStore.pickedCard &&
          pinboardStore.rowsToHighlight.has(row.value)) ||
        !pinboardStore.pickedCard,
      [styles.lowlight]:
        pinboardStore.context === 'classes' &&
        pinboardStore.pickedCard &&
        !pinboardStore.rowsToHighlight.has(row.value),
    },
  ]);

  const contextMenu: ContextMenuItem[] = [
    {
      node: (
        <div className={styles['menu-name']}>
          {pinboardStore.selectedRows.map((selected) => selected.label).join(', ')}
        </div>
      ),
      type: 'component',
    },
    {
      type: 'ruler',
    },
    {
      label: t('pinboard.actions.lockAll'),
      onClick: (e) => {
        e.stopPropagation();
        pinboardStore.lockCardsInSelectedRows();
      },
    },
    {
      label: t('pinboard.actions.unlockAll'),
      onClick: (e) => {
        e.stopPropagation();
        pinboardStore.unlockCardsInSelectedRows();
      },
    },
    {
      label: t('pinboard.actions.removeAll'),
      type: 'error',
      onClick: (e) => {
        e.stopPropagation();
        pinboardStore.removeCardsInSelectedRows();
      },
    },
  ];

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      pinboardStore.rowClickHandler(event, row);
    },
    [pinboardStore, row],
  );

  return (
    <>
      <div className={styles['name-wrapper']}>
        <ContextMenu className={styles.menu} data={contextMenu} usePortal={false}>
          <div
            onClick={(e) => handleClick(e)}
            className={nameClasses}
            title={row.label}
            onContextMenu={() => {
              if (pinboardStore.selectedRows.length <= 1 || !pinboardStore.selectedRows.includes(row))
                pinboardStore.selectRows([row]);
            }}
          >
            {row.label}
          </div>
        </ContextMenu>
      </div>
    </>
  );
});
