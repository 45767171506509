import React, { FunctionComponent, useMemo, useState } from 'react';
import {
  ClassTutorsConnectFieldInput,
  InputMaybe,
  useClassesListQuery,
  useCreateClassMutation,
  useGradeGroupsListQuery,
  useRoomsListQuery,
  useUpdateClassesMutation,
} from '../../../types/planung-graphql-client-defs';
import { Form, Formik, FormikProps } from 'formik';
import { Checkbox, ColorPicker, Grid, GridColumn, GridRow, Input, Select, SelectOptionType } from '@bp/ui-components';
import { FormikHelpers } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';
import { NameField } from './NameField/NameField';
import { colorPickerOptions, UsedColors } from '../../../utils/colorPickerOptions';
import { SingleValue } from 'react-select';
import { validationSchema } from './validation/validationSchema';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { CombinedError } from 'urql';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';

type ClassesFormProps = {
  classUuid: string | null;
  closeForm: () => void;
  htmlColorsInUse?: Array<UsedColors | null>;
};

export type ClassFormInitialValuesType = {
  uuid?: string;
  name: string;
  shortName: string;
  grade: number | null | undefined;
  gradeGroupUuid?: string | null;
  certificatesEnabled?: boolean;
  tutorUuid_1?: string;
  tutorUuid_2?: string;
  tutorUuid_3?: string;
  color?: string;
  colorLabel?: string;
  printOrder?: number;
  printPageNr?: number;
  defaultRoom?: string;
};

const ClassesForm: FunctionComponent<ClassesFormProps> = ({ classUuid, closeForm, htmlColorsInUse }) => {
  const { pimAuthClaims } = useAuthClaims();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const context = useMemorizedCacheTag('ROOMS');
  const [{ data: roomsListQuery }] = useRoomsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  const roomOptions = useCreateSelectOptions(roomsListQuery?.rooms, 'uuid', 'name');

  const classesContext = useMemorizedCacheTag('CLASSES');
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const [classesResult] = useClassesListQuery({
    variables: {
      schoolYearUuid: currentSchoolYear?.uuid,
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      classUuid: classUuid,
    },
    context: classesContext,
  });

  const gradeGroupsContext = useMemorizedCacheTag('GRADE_GROUPS');

  const [{ data: gradeGroupsResult }] = useGradeGroupsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: gradeGroupsContext,
  });

  const [, createClass] = useCreateClassMutation();
  const [, updateClass] = useUpdateClassesMutation();

  const { data: classListQuery, fetching } = classesResult;

  const currentClass = classUuid ? classListQuery?.classes[0] : null;

  const initialValues: ClassFormInitialValuesType = {
    uuid: currentClass?.uuid ?? '',
    name: currentClass?.name ?? '',
    shortName: currentClass?.shortName ?? '',
    certificatesEnabled: currentClass?.certificatesEnabled ?? false,
    grade: currentClass?.grade ?? undefined,
    gradeGroupUuid: currentClass?.gradeGroup?.uuid ?? null,
    tutorUuid_1: currentClass?.tutorsConnection?.edges[0]
      ? currentClass.tutorsConnection?.edges[0].node.uuid
      : undefined,
    tutorUuid_2: currentClass?.tutorsConnection?.edges[1]
      ? currentClass.tutorsConnection?.edges[1].node.uuid
      : undefined,
    tutorUuid_3: currentClass?.tutorsConnection?.edges[2]
      ? currentClass.tutorsConnection?.edges[2].node.uuid
      : undefined,
    color: currentClass?.timetableConfig?.color ?? '',
    colorLabel: currentClass?.timetableConfig?.colorLabel ?? '',
    printOrder: currentClass?.timetableConfig?.printOrder ?? undefined,
    printPageNr: currentClass?.timetableConfig?.printPageNr ?? undefined,
    defaultRoom: currentClass?.defaultRoom?.uuid ?? undefined,
  };
  const people = classListQuery && classListQuery.people;

  const tutorsOptions: SelectOptionType[] = people
    ? people.map((person) => {
        return { value: person.uuid, label: person.selectName ?? '' };
      })
    : [];

  const gradeGroupsOptions = useCreateSelectOptions(gradeGroupsResult?.gradeGroups, 'uuid', 'shortName');

  const handleSubmit = async (
    values: ClassFormInitialValuesType,
    formHelpers: FormikHelpers<ClassFormInitialValuesType>,
  ) => {
    let mutationResult: { error?: CombinedError | undefined };
    setLoading(true);
    const tutors: InputMaybe<Array<ClassTutorsConnectFieldInput>> = [];
    if (values.tutorUuid_1) {
      tutors.push({
        edge: {
          order: 1,
        },
        where: {
          node: {
            uuid: values.tutorUuid_1,
          },
        },
      });
    }
    if (values.tutorUuid_2) {
      tutors.push({
        edge: {
          order: 2,
        },
        where: {
          node: {
            uuid: values.tutorUuid_2,
          },
        },
      });
    }
    if (values.tutorUuid_3) {
      tutors.push({
        edge: {
          order: 3,
        },
        where: {
          node: {
            uuid: values.tutorUuid_3,
          },
        },
      });
    }

    const baseData = {
      shortName: values.shortName,
      name: values.name,
      grade: values.grade,
      certificatesEnabled: values.certificatesEnabled,
    };

    if (classUuid) {
      mutationResult = await updateClass(
        {
          where: {
            uuid: classUuid,
          },
          update: {
            ...baseData,
            defaultRoom: {
              disconnect: {},
              connect: { where: { node: { uuid: values.defaultRoom ?? '' } } },
            },
            gradeGroup: {
              disconnect: {},
              connect: { where: { node: { uuid: values.gradeGroupUuid ?? '' } } },
            },
            tutors: [
              {
                disconnect: [{}],
                connect: tutors,
              },
            ],
            timetableConfig: {
              update: {
                node: {
                  color: values.color,
                  colorLabel: values.colorLabel,
                  printOrder: values.printOrder,
                  printPageNr: values.printPageNr,
                  isEnabled: true,
                  organization: {
                    connect: {
                      where: {
                        node: {
                          uuid: pimAuthClaims.getOrganizationUuid(),
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        classesContext,
      );
    } else {
      mutationResult = await createClass(
        {
          input: {
            ...baseData,
            defaultRoom: {
              connect: { where: { node: { uuid: values.defaultRoom ?? '' } } },
            },
            organization: {
              connect: {
                where: {
                  node: {
                    uuid: pimAuthClaims.getOrganizationUuid(),
                  },
                },
              },
            },
            schoolYear: {
              connect: {
                where: {
                  node: {
                    uuid: currentSchoolYear?.uuid,
                  },
                },
              },
            },
            tutors: { connect: tutors },
            timetableConfig: {
              create: {
                node: {
                  color: values.color,
                  colorLabel: values.colorLabel,
                  printOrder: values.printOrder,
                  printPageNr: values.printPageNr,
                  isEnabled: true,
                  organization: {
                    connect: {
                      where: {
                        node: {
                          uuid: pimAuthClaims.getOrganizationUuid(),
                        },
                      },
                    },
                  },
                },
              },
            },
            gradeGroup: {
              connect: { where: { node: { uuid: values.gradeGroupUuid ?? '' } } },
            },
          },
        },
        classesContext,
      );
    }

    if (mutationResult.error) {
      showUserErrorToast({ error: mutationResult.error });
    } else {
      if (classUuid) {
        showSuccessUpdateToast();
      } else {
        showSuccessCreateToast();
      }
    }
    setLoading(false);
    formHelpers.resetForm();
    closeForm();
  };

  const colorPickerData = colorPickerOptions({
    htmlColorsInUse: htmlColorsInUse,
    currentColor: currentClass?.timetableConfig?.color ?? '',
    currentColorUsedBy: currentClass?.name,
    forTypename: 'Class',
  });

  const classNumberOptions = useMemo(
    () =>
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, null].map((level) => {
        return {
          value: level,
          label: level === null ? t('classes.functionalClass') : '' + level,
        };
      }),
    [],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        values,
        handleBlur,
        handleChange,
        resetForm,
        isSubmitting,
        dirty,
      }: FormikProps<ClassFormInitialValuesType>) => (
        <Form>
          <Grid useFormGap>
            <GridRow spacingTop={'l'}>
              <GridColumn width={3}>
                <Select
                  name='grade'
                  isClearable={true}
                  isSearchable
                  value={classNumberOptions.find((cno) => cno.value === values.grade)}
                  options={classNumberOptions}
                  label={t('classes.level.full')}
                  className={'quarter'}
                  onChange={async (option) => {
                    const opt = option as SingleValue<SelectOptionType>;
                    await setFieldValue('grade', opt?.value, true);
                    await setFieldTouched('grade', true, true);
                  }}
                  {...(errors.grade &&
                    touched.grade && {
                      error: errors.grade,
                    })}
                />
              </GridColumn>
              <GridColumn width={3}>
                <Select
                  name='gradeGroup'
                  value={gradeGroupsOptions.find((gg) => gg.value === values.gradeGroupUuid)}
                  options={gradeGroupsOptions}
                  isClearable
                  isSearchable
                  label={t('gradeGroups.title.singular')}
                  className={'quarter'}
                  onChange={async (option) => {
                    const opt = option as SingleValue<SelectOptionType>;
                    await setFieldTouched('gradeGroupUuid', true);
                    await setFieldValue('gradeGroupUuid', opt?.value);
                  }}
                  onBlur={handleBlur}
                  error={errors.gradeGroupUuid}
                />
              </GridColumn>
              <GridColumn width={3}>
                <NameField
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name={'name'}
                  label={t('common.name')}
                  className={'quarter'}
                  longName
                  gradeGroupsOptions={gradeGroupsOptions}
                  showGrade={values.grade ? values.grade >= 0 : false}
                  {...(errors.name &&
                    touched.name && {
                      error: errors.name,
                    })}
                />
              </GridColumn>
              <GridColumn width={3}>
                <NameField
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name={'shortName'}
                  label={t('common.shortName')}
                  gradeGroupsOptions={gradeGroupsOptions}
                  showGrade={values.grade ? values.grade >= 0 : false}
                  className={'quarter'}
                  {...(errors.shortName &&
                    touched.shortName && {
                      error: errors.shortName,
                    })}
                />
              </GridColumn>
              <GridColumn width={4} align={'center'}>
                <Checkbox
                  name='certificatesEnabled'
                  label={t('classes.certificatesEnabled')}
                  onChange={handleChange}
                  checked={values.certificatesEnabled}
                />
              </GridColumn>
            </GridRow>
            <GridRow spacingBottom={'none'}>
              <GridColumn width={9}>
                <Select
                  isClearable
                  isSearchable
                  name='tutorUuid_1'
                  options={tutorsOptions}
                  label={t('classes.tutor.full') + ' 1'}
                  defaultValue={tutorsOptions.find((tutor) => {
                    return tutor.value === values.tutorUuid_1;
                  })}
                  className={'full'}
                  onChange={(option) => {
                    const opt = option as SingleValue<SelectOptionType>;
                    setFieldTouched('tutorUuid_1', true);
                    setFieldValue('tutorUuid_1', opt?.value);
                  }}
                  onBlur={handleBlur}
                  tooltipText=''
                  {...(errors.tutorUuid_1 &&
                    touched.tutorUuid_1 && {
                      error: errors.tutorUuid_1,
                    })}
                />
              </GridColumn>
              <GridColumn width={3}>
                <></>
              </GridColumn>
            </GridRow>
            <GridRow spacingBottom={'none'}>
              <GridColumn width={9}>
                <Select
                  isClearable
                  isSearchable
                  name='tutorUuid_2'
                  options={tutorsOptions}
                  label={t('classes.tutor.full') + ' 2'}
                  defaultValue={tutorsOptions.find((tutor) => {
                    return tutor.value === values.tutorUuid_2;
                  })}
                  className={'full'}
                  onChange={(option) => {
                    const opt = option as SingleValue<SelectOptionType>;
                    setFieldTouched('tutorUuid_2', true);
                    setFieldValue('tutorUuid_2', opt?.value);
                  }}
                  onBlur={handleBlur}
                  tooltipText=''
                  {...(errors.tutorUuid_2 &&
                    touched.tutorUuid_2 && {
                      error: errors.tutorUuid_2,
                    })}
                />
              </GridColumn>
              <GridColumn width={3}>
                <></>
              </GridColumn>
            </GridRow>
            <GridRow spacingBottom={'none'}>
              <GridColumn width={9}>
                <Select
                  isClearable
                  isSearchable
                  name='tutorUuid_3'
                  options={tutorsOptions}
                  label={t('classes.tutor.full') + ' 3'}
                  defaultValue={tutorsOptions.find((tutor) => {
                    return tutor.value === values.tutorUuid_3;
                  })}
                  className={'full'}
                  onChange={(option) => {
                    const opt = option as SingleValue<SelectOptionType>;
                    setFieldTouched('tutorUuid_3', true);
                    setFieldValue('tutorUuid_3', opt?.value);
                  }}
                  onBlur={handleBlur}
                  tooltipText=''
                  {...(errors.tutorUuid_3 &&
                    touched.tutorUuid_3 && {
                      error: errors.tutorUuid_3,
                    })}
                />
              </GridColumn>
              <GridColumn width={3}>
                <></>
              </GridColumn>
            </GridRow>
            <GridRow headline={t('rooms.title.asStandard')} spacingBottom='none'>
              <GridColumn width={3}>
                <Select
                  isSearchable
                  label={t('rooms.title.singular')}
                  isClearable
                  options={roomOptions}
                  name={`defaultRoom`}
                  className={'half'}
                  value={roomOptions.find((opt) => opt.value === values.defaultRoom)}
                  onChange={async (option) => {
                    const opt = option as SingleValue<SelectOptionType>;
                    await setFieldTouched(`defaultRoom`, true);
                    await setFieldValue(`defaultRoom`, opt?.value);
                  }}
                ></Select>
              </GridColumn>
            </GridRow>
            <GridRow headline={t('timetable.title.plural')} spacingBottom='none'>
              <GridColumn width={3}>
                <Input
                  name='printPageNr'
                  type='number'
                  label={t('timetable.printPageNr')}
                  className={'third'}
                  value={values.printPageNr ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.printPageNr}
                />
              </GridColumn>
              <GridColumn width={3}>
                <Input
                  name='printOrder'
                  type='number'
                  label={t('timetable.printOrder')}
                  className={'third'}
                  value={values.printOrder ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.printOrder}
                />
              </GridColumn>
              <GridColumn width={3}>
                <ColorPicker
                  name='color'
                  label={t('common.color')}
                  className={'third'}
                  options={colorPickerData.groupedColorOptions}
                  maxMenuHeight={300}
                  tooltipText=''
                  onChange={(option) => {
                    setFieldValue('color', option?.html ?? '');
                    setFieldValue('colorLabel', option?.label ?? '');
                    setFieldTouched('color', true, false);
                  }}
                  onBlur={handleBlur}
                  defaultValue={colorPickerData.currentColor}
                  error={errors.color}
                />
              </GridColumn>
            </GridRow>
            <GridColumn width={3}>
              <></>
            </GridColumn>
          </Grid>

          <ModalBottomButtons
            closeButton={{
              text: t('common.cancelChanges'),
              callback: () => {
                resetForm();
                closeForm();
              },
            }}
            submitButton={{ disabled: isSubmitting || !dirty || fetching }}
            isLoading={loading}
            errors={errors}
          />
        </Form>
      )}
    </Formik>
  );
};

ClassesForm.displayName = 'ClassesForm';

export default observer(ClassesForm);
