import React, { FC, useMemo, useState } from 'react';
import { Modal, Tab } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import {
  PersonRole,
  use_ContractsQuery,
  use_PeopleWithContractQuery,
  use_SubjectsQuery,
  useAdditionalTeachingLoadsListQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useParams } from 'react-router-dom';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { DeputateLessonsTable } from '../../../../components/Lessons/Tables/DeputateLessonsTable';
import { TeacherStats } from '../../../../components/Lessons/Tables/Teachers/TeacherStats';
import {
  OverbookedTeachersTable,
  OverbookedTeachersTableDataType,
} from '../../../../components/Lessons/Tables/Teachers/OverbookedTeachersTable';
import { useDeputateLessons } from '../../../../components/Lessons/hooks/useDeputateLessons';
import { Page } from '../../../../components/Page/Page';
import { hexToColorOption } from '../../../../utils/colorUtils';
import { OpenLessonsList } from '../../../../components/Lessons/Tables/Teachers/OpenLessonsList';
import { AssignTeacher } from '../../../../components/Lessons/Tables/Teachers/AssignTeacher';
import { sumUpArray } from '../../../../utils/arrayFunc';
import { LessonInfo } from '../../../../utils/calculateLessonInfos';

export const TeacherLessonsPage: FC = observer(() => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { lessonsData: allLessonData, loadingState } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
  });

  const [{ data: teacherData }] = use_PeopleWithContractQuery({
    variables: {
      schoolYearUuid: schoolYear?.uuid ?? '',
      where: {
        organizationConnection: {
          node: {
            uuid: pimAuthClaims.getOrganizationUuid(),
          },
          edge: {
            name: PersonRole.Teacher,
          },
        },
      },
    },
  });
  const [{ data: contractData }] = use_ContractsQuery({
    pause: !teacherData,
  });
  const [{ data: subjectData }] = use_SubjectsQuery({
    pause: !teacherData,
  });

  const currentPerson = teacherData?.people.find((p) => p.uuid === uuid);
  const currentPersonQualifications = currentPerson?.qualifications.map((q) => q.subject.uuid);

  const lessonsData = allLessonData
    .filter((lesson) => {
      return uuid ? lesson.teacherUuids.includes(uuid) : false;
    })
    .map((lesson) => ({
      ...lesson,
      // teachingLoadEnabled:
      //   lesson.teachers.find((lt) => lt.uuid === currentPerson?.uuid ?? '')?.teachingLoadEnabled ??
      //   lesson.teachingLoadEnabled,
      lessonInfo: (uuid
        ? {
            ...lesson.lessonInfo,
            deputat: lesson.lessonInfo?.teacherInfos?.get(uuid ?? '')?.deputat ?? 0,
          }
        : lesson.lessonInfo) as LessonInfo,
    }));

  const [lessonToAssignUuid, setAddLessonToAssignUuid] = useState<null | string>(null);

  const { html, label } = hexToColorOption(currentPerson?.timetableConfig?.color ?? '');

  const lessonDeputate = currentPerson
    ? lessonsData
        .map((lesson) => lesson.lessonInfo?.teacherInfos?.get(currentPerson?.uuid)?.deputat ?? 0)
        .reduce((sum, dep) => sum + dep, 0)
    : 0;

  const currentContract = contractData?.contracts.find((c) => c.person.uuid === currentPerson?.uuid);

  const context = useMemorizedCacheTag('ADDITIONAL_TEACHINGLOAD');
  const [{ data }] = useAdditionalTeachingLoadsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolyear: schoolYear?.uuid ?? '',
      teacherUuid: uuid,
    },
    context,
  });

  const deputateManually = useMemo(() => {
    return sumUpArray(data?.additionalTeachingLoads?.map((a) => a.hours) ?? []) ?? 0;
  }, [data]);

  const overbookedTeachersTableData: OverbookedTeachersTableDataType[] = useMemo(() => {
    //  all teachers
    return (
      teacherData?.people
        .flatMap((person) => {
          // deputate from lessons for person
          const plannedDeputate =
            allLessonData
              .map((lesson) => lesson.lessonInfo?.teacherInfos?.get(person?.uuid)?.deputat ?? 0)
              .reduce((acc, curr) => acc + curr, 0) ?? 0;

          // 0 when  no contract
          const contractDeputate = currentContract?.hoursWeekly ?? 0;

          const relation = contractDeputate ? (plannedDeputate / contractDeputate) * 100 : 0;

          const a: OverbookedTeachersTableDataType = {
            uuid: person.uuid,
            teacherName: person.listName ?? '',
            deputateRelation: `${plannedDeputate.toFixed(2)} / ${contractDeputate?.toFixed(2)}`,
            status: contractDeputate ? relation.toFixed(0) + '%' : '-', // teachers without contract don't have a status
            relationRaw: relation,
            subjects:
              person?.qualifications
                ?.map((q) => subjectData?.subjects.find((s) => s.uuid === q.subject.uuid)?.shortName.trim())
                .filter((s) => s)
                .sort()
                .join(', ') ?? '',
            qualified: person.qualifications.some((q) => currentPersonQualifications?.includes(q.subject.uuid)),
          };
          return a;
        })
        // only overbooked and qualified teachers
        .filter((d) => d.relationRaw > 100 && d.uuid !== currentPerson?.uuid && d.qualified) ?? []
    );
  }, [allLessonData, currentContract, currentPerson, currentPersonQualifications, subjectData, teacherData]);

  return (
    <Page title={currentPerson?.fullName} colorHint={{ color: html, colorLabel: label }}>
      <div className='tks__grid mt-4'>
        <div className='tks__row mb-8'>
          <div className='tks__col col-xs-4'>
            <TeacherStats
              deputateLesson={lessonDeputate}
              deputateManually={deputateManually}
              deputateContract={currentContract?.hoursWeekly ?? 0}
              billingMethod={currentContract?.billingMethod}
              limitedToFullTime={currentContract?.limitedToFullTime}
              fullTimeHours={schoolYear?.fullTimeHours}
            />
          </div>
          <div className='tks__col col-xs-8'>
            <Tab
              tabs={[
                {
                  title: t('persons.overbooked'),
                  value: 'teachers',
                  content: <OverbookedTeachersTable data={overbookedTeachersTableData} />,
                },
                {
                  title: t('lesson.unassigned'),
                  value: 'lessons',
                  content: (
                    <OpenLessonsList
                      loading={loadingState.some((ls) => ls.uuid === lessonToAssignUuid && ls.loading)}
                      onAssignClick={(lesson) => {
                        setAddLessonToAssignUuid(lesson.uuid);
                      }}
                      filter={(l) => {
                        return currentPersonQualifications
                          ? currentPersonQualifications.includes(l.subjectUuid)
                          : false;
                      }}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>

      <DeputateLessonsTable
        userConfigKey={'lessons-table-teachers'}
        lessonsData={lessonsData}
        showAddButton={true}
        defaultInitialValues={{
          teachingLoadEnabled: true,
          teachers: [
            {
              present: true,
              teachingLoad: true,
              writesCertificate: false,
              person: {
                uuid: currentPerson?.uuid ?? '',
                fullName: currentPerson?.fullName ?? '',
                displayNameShort: currentPerson?.displayNameShort ?? '',
                color: currentPerson?.timetableConfig?.color ?? '',
              },
            },
          ],
        }}
      />
      {currentPerson && (
        <Modal
          title={t('lesson.assign')}
          isOpen={!!lessonToAssignUuid}
          onRequestClose={() => setAddLessonToAssignUuid(null)}
          width={'s'}
        >
          <AssignTeacher
            onClose={() => setAddLessonToAssignUuid(null)}
            teacher={currentPerson}
            lessonUuid={lessonToAssignUuid ?? ''}
          ></AssignTeacher>
        </Modal>
      )}
    </Page>
  );
});
