import { Link, useMatches } from 'react-router-dom';
import styles from './Breadcrumb.module.scss';
import { Fragment, memo } from 'react';

export type CrumbItemType = {
  slug: string;
  title: string;
  asHeadline?: boolean;
};

export type CrumbType = {
  crumb: (data: unknown) => CrumbItemType | CrumbItemType[];
};

export const Breadcrumb = memo(() => {
  const matches = useMatches();

  const crumbs = matches
    .filter((match) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Boolean(match.handle?.crumb); // How to overwrite crumb ? see src/types/react-router.d.ts
    })
    .map((match) => {
      const handle = match.handle as CrumbType;
      return handle.crumb(match.data);
    });
  return (
    <div className={styles.breadcrumb}>
      {crumbs.map((crumb, index) => (
        <Fragment key={index}>
          {Array.isArray(crumb) ? (
            crumb.map((c, j) => (
              <div key={`${index}${j}`} className={styles.link}>
                {c.slug && c.slug !== '' ? (
                  <Link key={c.slug} to={c.slug}>
                    {c.title}
                  </Link>
                ) : (
                  <span>{c.title}</span>
                )}
              </div>
            ))
          ) : (
            <div className={styles.link}>
              {crumb.slug && crumb.slug !== '' ? (
                <Link key={crumb.slug} to={crumb.slug}>
                  {crumb.title}
                </Link>
              ) : (
                <span>{crumb.title}</span>
              )}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
});
Breadcrumb.displayName = 'Breadcrumb';
