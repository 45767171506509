import { ControlResult, GenericWarning } from './types';
import { apiHelper } from '../apiHelper';
import { MathplanJob } from '../../types/planung-graphql-client-defs';
import { showErrorToast } from '../toast';
import { t } from 'i18next';

export const testTimetableVersion = async (
  versionUuid: string,
  classes?: string[] | string,
): Promise<GenericWarning[]> => {
  return await apiHelper({
    path: `/test/timetableversion/${versionUuid}/${classes?.toString()}`,
    method: 'GET',
    type: 'json',
  });
};

export const controlTimetableVersion = async (
  versionUuid: string,
  classes?: string[] | string,
): Promise<ControlResult> => {
  return await apiHelper({
    path: `/control/timetable/${versionUuid}/${classes?.toString()}`,
    method: 'GET',
    type: 'json',
  });
};

export const solveTimetableVersionWithParams = async (
  versionUuid: string,
  options?: {
    classes: string[];
    includeRoomResources: boolean;
    keepExistingPositions: boolean;
    includeSubjectContainer: boolean;
  },
): Promise<MathplanJob | undefined> => {
  return await apiHelper({
    method: 'POST',
    path: `/mathplan/solve/${versionUuid}`,
    data: JSON.stringify(options),
    type: 'json',
  })
    .then((res) => res as MathplanJob)
    .catch((err) => {
      showErrorToast({ name: t('pinboard.generate.error'), message: err.cause ?? err.message, graphQLErrors: [] });
      return undefined;
    });
};

export const exportTimetableVersionToAsc = async (versionUuid: string): Promise<string | undefined> => {
  return await apiHelper({
    method: 'GET',
    path: `/mathplan/asc/${versionUuid}`,
    type: 'xml',
  })
    .then((res) => res as string)
    .catch((err) => {
      showErrorToast({ name: t('pinboard.generate.error'), message: err.cause ?? err.message, graphQLErrors: [] });
      return undefined;
    });
};

export const cancelTimetableVersionGeneration = async (jobId: string): Promise<MathplanJob> => {
  return await apiHelper({
    path: `/mathplan/cancel/${jobId}`,
    method: 'POST',
    data: '',
    type: 'json',
  });
};

export const importTimetableVersionGeneration = async (jobId: string): Promise<MathplanJob> => {
  return await apiHelper({
    path: `/mathplan/import/${jobId}`,
    method: 'POST',
    data: '',
    type: 'json',
  });
};
