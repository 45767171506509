export function generateGradientString(colors: string[]): string {
  const angle = '135deg';
  const percentageStep = 100 / colors.length;

  if (colors.length === 0) {
    return '';
  }
  const colorStops = colors.map((color, index) => {
    const startPercentage = index * percentageStep;
    const endPercentage = startPercentage + percentageStep;
    return `${color} ${startPercentage.toFixed(3)}%, ${color} ${endPercentage.toFixed(3)}%`;
  });

  return `linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0) 5px), linear-gradient(${angle}, ${colorStops.join(
    ', ',
  )})`;
}
