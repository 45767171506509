import { useTranslation } from 'react-i18next';
import { FactorsTable } from '../../../../components/Factors/FactorsTable';
import { Page } from '../../../../components/Page/Page';

export const FactorsPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('lessonUnit.factors')}>
      <FactorsTable />
    </Page>
  );
};
