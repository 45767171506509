import { FC, useMemo, useState } from 'react';
import { HolidayType } from './graphql';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { useDeleteHolidaysMutation, useSchoolYearsQuery } from '../../types/planung-graphql-client-defs';
import {
  AlertDialog,
  Button,
  ButtonGroup,
  DeleteIcon,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import HolidaysForm from './Form/HolidaysForm';
import dayjs from 'dayjs';
import { useAuthClaims } from '../../hooks/useAuthClaims';

type HolidaysEntriesListProps = {
  selectedYearUuid: string;
};

const HolidaysEntriesList: FC<HolidaysEntriesListProps> = ({ selectedYearUuid }) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('SCHOOL_YEAR');

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [holiday, setHoliday] = useState<HolidayType | null>(null);
  const [, deleteHolidays] = useDeleteHolidaysMutation();

  const [result] = useSchoolYearsQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: context,
  });
  const { data } = result;

  const createColumns = (): TableColumns<HolidayType>[] => {
    return [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        size: 400,
      },
      {
        header: t('holidays.span'),
        id: 'span',
        cell: ({ row }) => {
          return `${dayjs(row.original.start).format('DD.MM.YYYY')} - ${dayjs(row.original.end).format('DD.MM.YYYY')}`;
        },
        size: 200,
      },
    ];
  };

  const tableColumns = useMemo(createColumns, []);

  const schoolYear = useMemo(() => {
    return data && data ? data.schoolYears.find((year) => year.uuid === selectedYearUuid) : null;
  }, [data, selectedYearUuid]);

  const sortedHolidays: HolidayType[] =
    schoolYear?.holidays.sort((a, b) => {
      return dayjs(a.start).isBefore(b.start) ? -1 : 1;
    }) ?? [];

  const handleEdit = (row: Row<HolidayType>) => {
    setHoliday(row.original ?? null);
    setModalOpen(true);
  };

  const onAddClick = () => {
    setHoliday(null);
    setModalOpen(true);
  };

  const closeModal = () => {
    setHoliday(null);
    setModalOpen(false);
  };

  const handleDelete = async (uuid: string) => {
    await deleteHolidays({ uuid }, context);
  };

  return (
    <>
      <Table
        showBorderRadius
        columns={tableColumns}
        data={sortedHolidays}
        onAddClick={() => onAddClick()}
        customPadding='var(--spacing-6)'
        showActionBar
        actionBarSettings={
          schoolYear
            ? {
                showAddButton: true,
                showGlobalFilter: false,
                extendedActionsLeft: `${t('holidays.title')} ${schoolYear?.name}`,
              }
            : { showAddButton: false, showGlobalFilter: false }
        }
        breakpoint={null}
        lastColWidth='80px'
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                type='button'
                icon={<EditIcon className='small' />}
                onClick={() => {
                  handleEdit(row);
                }}
              />
              <AlertDialog
                title={t('holidays.deleteConfirm', {
                  name: row.original.name,
                })}
                confirmText={t('common.confirmDelete')}
                cancelText={t('common.cancelDelete')}
                onConfirm={async () => {
                  await handleDelete(row.original.uuid);
                }}
                trigger={<Button hierarchy='secondary' type='button' icon={<DeleteIcon className='small' />} />}
              />
            </ButtonGroup>
          );
        }}
      />
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={holiday ? (t('holidays.edit') as string) : (t('holidays.add') as string)}
      >
        <HolidaysForm holiday={holiday} handleClose={closeModal} schoolYear={schoolYear} />
      </Modal>
    </>
  );
};

export default HolidaysEntriesList;
