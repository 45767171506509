import { useTranslation } from 'react-i18next';
import { Tab } from '@bp/ui-components';
import { DeputateBuildingsTable } from '../../../../components/Buildings/Tables/DeputateBuildingsTable';
import { Page } from '../../../../components/Page/Page';
import { RoomsTable } from '../../../../components/Rooms/Tables/RoomsTable';

export const SetupRoomsAndBuildings = () => {
  const { t } = useTranslation();

  return (
    <Page title={null}>
      <Tab
        variant={'large'}
        tabs={[
          {
            title: t('rooms.title.plural'),
            value: 'rooms',
            content: <RoomsTable />,
          },
          {
            title: t('buildings.title'),
            value: 'buildings',
            content: <DeputateBuildingsTable />,
          },
        ]}
      />
    </Page>
  );
};
