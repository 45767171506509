import * as yup from 'yup';
import { t } from 'i18next';

export const LessonFormUnitSchema = yup
  .object()
  .shape({
    uuid: yup.string().nullable(),
    duration: yup.number().integer(t('validation.common.integerOnly')).nullable(),
    count: yup.number().nullable(),
    factor: yup.number().nullable(),
    subjectContainerUuid: yup.string().nullable(),
    blocked: yup.boolean(),
    lessonUnitType: yup.string().oneOf(['epoch', 'subjectHour']).required(),
  })
  .test('subjectContainerUuid', t('validation.subjectContainer.required'), (value, context) => {
    if (value?.lessonUnitType === 'epoch') {
      return !!value.subjectContainerUuid;
    }
    return true;
  });

const LessonFormSubjectTypeSchema = yup.object().shape({
  uuid: yup.string().required(),
  epochEnabled: yup.boolean().nullable(),
  subjectHourEnabled: yup.boolean().nullable(),
});

const LessonFormClassTypeSchema = yup.object().shape({
  uuid: yup.string(),
  class: yup.object().shape({
    name: yup.string(),
    uuid: yup.string().required(),
    color: yup.string().nullable(),
    groupCount: yup.number(),
  }),
  groups: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      uuid: yup.string().required(),
    }),
  ),
});

const LessonFormTeacherTypeSchema = yup.object().shape({
  person: yup.object().shape({
    uuid: yup.string().required(),
    color: yup.string().nullable(),
  }),
  teachingLoadHours: yup.number().nullable(),
  teachingLoad: yup.boolean().nullable(),
  writesCertificate: yup.boolean().nullable(),
  present: yup.boolean().nullable(),
  description: yup.string().nullable(),
});

export const schema = yup.object().shape({
  uuid: yup.string(),
  subject: LessonFormSubjectTypeSchema.required(),
  subTitle: yup.string().nullable(),
  comment: yup.string().nullable(),
  elective: yup.boolean(),
  teachingLoadEnabled: yup.boolean(),
  timetableEnabled: yup.boolean(),
  teachers: yup.array().of(LessonFormTeacherTypeSchema),
  lessonClasses: yup.array().of(LessonFormClassTypeSchema).required(),
  lessonUnit: yup.array().of(LessonFormUnitSchema),
});

export const epochSchema = yup.object().shape({
  uuid: yup.string(),
  subject: LessonFormSubjectTypeSchema.required(),
  subTitle: yup.string().nullable(),
  comment: yup.string().nullable(),
});
