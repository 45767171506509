import dayjs from 'dayjs';
import { _TimeGridEntriesQuery } from '../types/planung-graphql-client-defs';
import { BitStatus, StatusManager } from './StatusManager';
type HH = string;
type mm = string;
type day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export type Time = `${HH}:${mm}`; // 'HH:mm'
type TimeString = `${Time}${day}`; // 'HH:mm' + day
type ResourceUUID = string; // Klasse / DivisionGroupFormt / Lehrer / Raum UUID
type TimetableCardUUID = string;

type ClassUuid = string;
type DivisionUuid = string;
type GroupUuid = string;

export type DetailedPlacedInfo = {
  type: AvailabilityResourceType;
  uuid: string;
};

export type CheckCardAvailabilityResult = {
  status: AvailableStatus;
  placed?: DetailedPlacedInfo[];
};
export type CardClassDivisionGroupFormat = `${TimetableCardUUID}:${ClassUuid}:${DivisionUuid}:${GroupUuid}`;

export type AvailabilityResourceType = 'teacher' | 'class' | 'divisionGroup' | 'room' | 'subject';

export type CardAvailability = Map<
  ResourceUUID,
  {
    type: AvailabilityResourceType;
    maybe: boolean;
    blocked: boolean;
    placed: Set<TimetableCardUUID>;
  }
>;

export type Availability = Map<
  TimeString,
  Map<
    ResourceUUID,
    {
      type: AvailabilityResourceType;
      maybe: boolean;
      blocked: boolean;
      placed: Set<TimetableCardUUID>;
    }
  >
>;

export type AvailabilityCardType = {
  uuid: string;
  classes: string[];
  classDivisionGroups: CardClassDivisionGroupFormat[];
  teachers: string[];
  rooms: string[];
  subject: string;
};

export type Day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export type Context = 'teachers' | 'classes' | 'divisionGroups' | 'rooms' | 'subjects';
export type AvailableStatus = 'blocked' | 'maybe' | 'placed' | 'available';

export class TimeAvailabilityStore {
  availabilities: Availability = new Map();
  days: Day[] = ['mon', 'tue', 'wed', 'thu', 'fri'];
  statusManager: ReturnType<typeof StatusManager>;
  timeGridEntries: _TimeGridEntriesQuery['timeGridEntries'] | null;

  constructor(from: string, to: string, days?: Day[], timeGridEntries?: _TimeGridEntriesQuery['timeGridEntries']) {
    this.statusManager = StatusManager();
    this.timeGridEntries = timeGridEntries ?? null;

    const fromTime = dayjs(from, 'HH:mm');
    const toTime = dayjs(to, 'HH:mm');
    const allMinutes = toTime.diff(fromTime, 'minute');
    if (days) {
      this.days = days;
    }

    for (const day of this.days) {
      for (let i = 0; i <= allMinutes; i++) {
        const timeString = fromTime.add(i, 'minute').format('HH:mm') as Time;
        this.availabilities.set(`${timeString}${day}`, new Map());
      }
    }
  }

  isResourcePlaced(time: string, day: Day, resourceUUID: ResourceUUID) {
    const timeString = dayjs(time, 'HH:mm').format('HH:mm') as Time;
    const placedSize = this.availabilities.get(`${timeString}${day}`)?.get(resourceUUID)?.placed.size ?? 0;
    return placedSize > 0;
  }

  isResourceBlocked(time: string, day: Day, resourceUUID: ResourceUUID) {
    const timeString = dayjs(time, 'HH:mm').format('HH:mm') as Time;
    return this.availabilities.get(`${timeString}${day}`)?.get(resourceUUID)?.blocked ?? false;
  }

  isResourceMaybe(time: string, day: Day, resourceUUID: ResourceUUID) {
    const timeString = dayjs(time, 'HH:mm').format('HH:mm') as Time;
    return this.availabilities.get(`${timeString}${day}`)?.get(resourceUUID)?.maybe ?? false;
  }

  setResourcePlaced(
    time: string,
    day: Day,
    resourceUUID: ResourceUUID,
    cardUuid: string,
    type: AvailabilityResourceType,
  ) {
    const timeString = dayjs(time, 'HH:mm').format('HH:mm') as Time;
    const timeAvailabilities = this.availabilities.get(`${timeString}${day}`);

    if (timeAvailabilities) {
      const currentBlocked = timeAvailabilities?.get(resourceUUID)?.blocked ?? false;
      const currentMaybe = timeAvailabilities?.get(resourceUUID)?.maybe ?? false;
      const currentPlaced = timeAvailabilities?.get(resourceUUID)?.placed ?? new Set();
      timeAvailabilities?.set(resourceUUID, {
        type: type,
        placed: currentPlaced.add(cardUuid),
        maybe: currentMaybe,
        blocked: currentBlocked,
      });
    }
  }

  removeResourcePlaced(
    time: string,
    day: Day,
    resourceUUID: ResourceUUID,
    cardUuid: string,
    type: AvailabilityResourceType,
  ) {
    const timeString = dayjs(time, 'HH:mm').format('HH:mm') as Time;
    const timeAvailabilities = this.availabilities.get(`${timeString}${day}`);
    if (timeAvailabilities) {
      const currentBlocked = timeAvailabilities?.get(resourceUUID)?.blocked ?? false;
      const currentMaybe = timeAvailabilities?.get(resourceUUID)?.maybe ?? false;
      const currentPlaced = timeAvailabilities?.get(resourceUUID)?.placed ?? new Set();
      currentPlaced.delete(cardUuid);
      timeAvailabilities?.set(resourceUUID, {
        type: type,
        placed: currentPlaced,
        maybe: currentMaybe,
        blocked: currentBlocked,
      });
    }
  }

  setResourceBlocked(
    time: string,
    day: Day,
    resourceUUID: ResourceUUID,
    blocked: boolean,
    type: AvailabilityResourceType,
  ) {
    const timeString = dayjs(time, 'HH:mm').format('HH:mm') as Time;
    const newAvailabilities = this.availabilities.get(`${timeString}${day}`);
    if (newAvailabilities) {
      const currentMaybe = newAvailabilities?.get(resourceUUID)?.maybe ?? false;
      const currentPlaced = newAvailabilities?.get(resourceUUID)?.placed ?? new Set();
      newAvailabilities?.set(resourceUUID, { type: type, placed: currentPlaced, maybe: currentMaybe, blocked });
    }
  }

  setResourceMaybe(time: string, day: Day, resourceUUID: ResourceUUID, maybe: boolean, type: AvailabilityResourceType) {
    const timeString = dayjs(time, 'HH:mm').format('HH:mm') as Time;
    const newAvailabilities = this.availabilities.get(`${timeString}${day}`);
    if (newAvailabilities) {
      const currentBlocked = newAvailabilities?.get(resourceUUID)?.blocked ?? false;
      const currentPlaced = newAvailabilities?.get(resourceUUID)?.placed ?? new Set();
      newAvailabilities?.set(resourceUUID, {
        type: type,
        placed: currentPlaced,
        maybe,
        blocked: currentBlocked,
      });
    }
  }

  setResourceAvailable(time: string, day: Day, resourceUUID: ResourceUUID, type: AvailabilityResourceType) {
    const timeString = dayjs(time, 'HH:mm').format('HH:mm') as Time;
    const newAvailabilities = this.availabilities.get(`${timeString}${day}`);
    if (newAvailabilities) {
      const currentPlaced = newAvailabilities?.get(resourceUUID)?.placed ?? new Set();

      newAvailabilities?.set(resourceUUID, {
        type: type,
        placed: currentPlaced,
        maybe: false,
        blocked: false,
      });
    }
  }

  checkResourceConflicts({
    uuid,
    day,
    times,
    rooms,
    teachers,
    classes,
    classDivisionGroups,
    subject,
    context,
  }: {
    subject: string;
    uuid: string;
    times: Time[];
    day: Day;
    rooms: string[];
    teachers: string[];
    classes: string[];
    classDivisionGroups: string[];
    context: Context;
  }): CardAvailability | null {
    const cardAvailability: CardAvailability = new Map();

    times.forEach((time) => {
      const minAvailabilities = this.availabilities.get(`${time}${day}`);

      if (minAvailabilities) {
        minAvailabilities.forEach((minAvailability, key) => {
          if (minAvailability) {
            const placedCards = minAvailability.placed;
            const placedWithoutCurrent = placedCards ? [...placedCards].filter((c) => c !== uuid) : [];

            rooms.forEach((room) => {
              if (key === room) {
                if (
                  minAvailability.type === 'room' &&
                  (minAvailability.maybe ||
                    minAvailability.blocked ||
                    (minAvailability.placed && placedWithoutCurrent.length > 0))
                ) {
                  const current = cardAvailability.get(room);
                  if (minAvailability.blocked) {
                    cardAvailability.set(room, minAvailability);
                  } else if (current?.maybe) {
                    cardAvailability.set(room, minAvailability);
                  } else if (!current?.blocked) {
                    cardAvailability.set(room, minAvailability);
                  }
                }
              }
            });

            teachers.forEach((teacher) => {
              if (key === teacher) {
                if (
                  minAvailability.type === 'teacher' &&
                  (minAvailability.maybe ||
                    minAvailability.blocked ||
                    (minAvailability.placed && placedWithoutCurrent.length > 0))
                ) {
                  const current = cardAvailability.get(teacher);
                  if (minAvailability.blocked) {
                    cardAvailability.set(teacher, minAvailability);
                  } else if (current?.maybe) {
                    cardAvailability.set(teacher, minAvailability);
                  } else if (!current?.blocked) {
                    cardAvailability.set(teacher, minAvailability);
                  }
                }
              }
            });

            classes.forEach((c) => {
              if (key === c) {
                if (
                  minAvailability.type === 'class' &&
                  (minAvailability.maybe ||
                    minAvailability.blocked ||
                    (minAvailability.placed && placedWithoutCurrent.length > 0))
                ) {
                  const current = cardAvailability.get(c);
                  if (minAvailability.blocked) {
                    cardAvailability.set(c, minAvailability);
                  } else if (minAvailability?.maybe && !current?.blocked) {
                    cardAvailability.set(c, minAvailability);
                  }
                }
              }
            });

            if (subject && key === subject) {
              if (minAvailability.maybe || (minAvailability.type === 'subject' && minAvailability.blocked)) {
                const current = cardAvailability.get(subject);
                if (minAvailability.blocked) {
                  cardAvailability.set(subject, minAvailability);
                } else if (current?.maybe) {
                  cardAvailability.set(subject, minAvailability);
                } else if (!current?.blocked) {
                  cardAvailability.set(subject, minAvailability);
                }
              }
            }

            if (key.includes(':')) {
              const [card, classUuid, divisionUuid, groupUuid] = key.split(':');
              if (
                card !== uuid &&
                classes.includes(classUuid) &&
                classDivisionGroups.some((cdg) => cdg.includes(divisionUuid)) &&
                classDivisionGroups.some((cdg) => cdg.includes(groupUuid)) &&
                minAvailability.placed.size > 0
              ) {
                cardAvailability.set(groupUuid, minAvailability);
              }
              if (
                card !== uuid &&
                classes.includes(classUuid) &&
                classDivisionGroups.every((cdg) => !cdg.includes(divisionUuid)) &&
                minAvailability.placed.size > 0
              ) {
                cardAvailability.set(divisionUuid, minAvailability);
              }
            }
          }
        });
      }
    });

    return cardAvailability.size > 0 ? cardAvailability : null;
  }

  checkCardAvailability({
    day,
    time,
    card,
    context,
  }: {
    time: Time;
    day: Day;
    card: AvailabilityCardType;
    context: Context;
  }): CheckCardAvailabilityResult {
    const minAvailabilities = this.availabilities.get(`${time}${day}`);
    const placedCards: DetailedPlacedInfo[] = [];
    if (minAvailabilities && minAvailabilities?.size > 0) {
      const isBlocked = (uuid: string) => minAvailabilities.get(uuid)?.blocked ?? false;
      const isMaybe = (uuid: string) => minAvailabilities.get(uuid)?.maybe ?? false;
      const isPlaced = (uuid: string) => {
        const cards = minAvailabilities.get(uuid)?.placed;
        return cards ? [...cards].filter((c) => c !== card.uuid).length > 0 : false;
      };

      const blocked =
        card.teachers.some(isBlocked) ||
        card.classes.some(isBlocked) ||
        card.classDivisionGroups.some(isBlocked) ||
        card.rooms.some(isBlocked);

      if (blocked) return { status: 'blocked' };

      let placed = card.teachers.some((teacher) => {
        if (isPlaced(teacher)) {
          placedCards.push({ type: 'teacher', uuid: teacher });
          return true;
        }
        return false;
      });

      placed =
        placed ||
        (context !== 'classes' &&
          card.classes.some((c) => {
            if (isPlaced(c)) {
              placedCards.push({ type: 'class', uuid: c });
              return true;
            }
            return false;
          }));

      placed =
        placed ||
        card.rooms.some((r) => {
          if (isPlaced(r)) {
            placedCards.push({ type: 'room', uuid: r });
            return true;
          }
          return false;
        });

      if (placed) return { status: 'placed', placed: placedCards };

      const maybe =
        card.teachers.some(isMaybe) ||
        card.classes.some(isMaybe) ||
        card.classDivisionGroups.some(isMaybe) ||
        card.rooms.some(isMaybe);

      if (maybe) return { status: 'maybe' };
    }
    return { status: 'available' };
  }

  setMaybeForRange(
    range: { start: dayjs.Dayjs; end: dayjs.Dayjs; day: Day },
    resourceUUID: ResourceUUID,
    maybe: boolean,
    type: AvailabilityResourceType,
  ) {
    const { start, end, day } = range;
    const allMinutes = end.diff(start, 'minute');
    for (let i = 0; i <= allMinutes; i++) {
      this.setResourceMaybe(start.add(i, 'minute').format('HH:mm'), day, resourceUUID, maybe, type);
    }
  }

  setBlockedForRange(
    range: { start: dayjs.Dayjs; end: dayjs.Dayjs; day: Day },
    resourceUUID: ResourceUUID,
    blocked: boolean,
    type: AvailabilityResourceType,
  ) {
    const { start, end, day } = range;
    const allMinutes = end.diff(start, 'minute');
    for (let i = 0; i <= allMinutes; i++) {
      this.setResourceBlocked(start.add(i, 'minute').format('HH:mm'), day, resourceUUID, blocked, type);
    }
  }

  setAvailableForRange(
    range: { start: string; end: string; day: Day },
    resourceUUID: ResourceUUID,
    type: AvailabilityResourceType,
  ) {
    const { start, end, day } = range;
    const startTime = dayjs(start, 'HH:mm');
    const endTime = dayjs(end, 'HH:mm');
    const allMinutes = endTime.diff(startTime, 'minute');
    for (let i = 0; i <= allMinutes; i++) {
      this.setResourceAvailable(startTime.add(i, 'minute').format('HH:mm'), day, resourceUUID, type);
    }
  }

  setCardsResourcesPlaced(
    cards: AvailabilityCardType[],
    range: {
      start: Time;
      end: Time;
      day: Day;
    },
  ) {
    const { start, end, day } = range;
    const startTime = dayjs(start, 'HH:mm');
    const endTime = dayjs(end, 'HH:mm');
    const allMinutes = endTime.diff(startTime, 'minute');
    cards.forEach((card) => {
      for (let i = 0; i <= allMinutes; i++) {
        card.teachers.forEach((teacher) => {
          this.setResourcePlaced(startTime.add(i, 'minute').format('HH:mm'), day, teacher, card.uuid, 'teacher');
        });
        card.classes.forEach((c) => {
          this.setResourcePlaced(startTime.add(i, 'minute').format('HH:mm'), day, c, card.uuid, 'class');
        });
        card.classDivisionGroups.forEach((g) => {
          this.setResourcePlaced(startTime.add(i, 'minute').format('HH:mm'), day, g, card.uuid, 'divisionGroup');
        });
        card.rooms.forEach((r) => {
          this.setResourcePlaced(startTime.add(i, 'minute').format('HH:mm'), day, r, card.uuid, 'room');
        });
      }
    });
  }

  removeCardsResourcesPlaced(
    cards: AvailabilityCardType[],
    range: {
      start: string;
      end: string;
      day: Day;
    },
  ) {
    const { start, end, day } = range;
    const startTime = dayjs(start, 'HH:mm');
    const endTime = dayjs(end, 'HH:mm');
    const allMinutes = endTime.diff(startTime, 'minute');

    cards.forEach((card) => {
      for (let i = 0; i <= allMinutes; i++) {
        card.teachers.forEach((teacher) => {
          this.removeResourcePlaced(startTime.add(i, 'minute').format('HH:mm'), day, teacher, card.uuid, 'teacher');
        });
        card.classes.forEach((c) => {
          this.removeResourcePlaced(startTime.add(i, 'minute').format('HH:mm'), day, c, card.uuid, 'class');
        });
        card.classDivisionGroups.forEach((g) => {
          this.removeResourcePlaced(startTime.add(i, 'minute').format('HH:mm'), day, g, card.uuid, 'divisionGroup');
        });
        card.rooms.forEach((r) => {
          this.removeResourcePlaced(startTime.add(i, 'minute').format('HH:mm'), day, r, card.uuid, 'room');
        });
      }
    });
  }

  setResourceAvailability(
    availabilities: {
      uuid: string;
      days: {
        mon?: string | null;
        tue?: string | null;
        wed?: string | null;
        thu?: string | null;
        fri?: string | null;
        sat?: string | null;
        sun?: string | null;
      };
    }[],
    type: AvailabilityResourceType,
  ) {
    this.timeGridEntries?.length &&
      this.timeGridEntries
        .sort((a, b) => {
          return a.order - b.order;
        })
        .forEach((timeGridEntry) => {
          const { order, start, end } = timeGridEntry;
          const startTime = dayjs(start);
          const endTime = dayjs(end);
          this.days.forEach((day) => {
            availabilities.forEach((availability) => {
              const { uuid, days } = availability;
              if (days) {
                const currentValue = days[day] ?? '';
                const currentBit = this.statusManager.isFree(this.statusManager.stringToBit(currentValue[order]))
                  ? BitStatus.FREE
                  : this.statusManager.isMaybe(this.statusManager.stringToBit(currentValue[order]))
                    ? BitStatus.MAYBE
                    : BitStatus.OCCUPIED;
                if (this.statusManager.isOccupied(currentBit)) {
                  this.setBlockedForRange({ start: startTime, end: endTime, day }, uuid, true, type);
                }
                if (this.statusManager.isMaybe(currentBit)) {
                  this.setMaybeForRange({ start: startTime, end: endTime, day }, uuid, true, type);
                }
                if (this.statusManager.isFree(currentBit)) {
                  this.setAvailableForRange({ start: start, end: end, day }, uuid, type);
                }
              }
            });
          });
        });
  }
}
