import { PlannedDeputatesTable } from '../../../../components/Deputate/Tables/PlannedDeputatesTable';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';

type Props = { slug: string };
export const EvaluatePlannedOverview: FC<Props> = ({ slug }) => {
  const navigate = useNavigate();
  const handleRowClick = (data: string) => navigate(slug + '/' + data);

  return <PlannedDeputatesTable handleRowClick={handleRowClick} />;
};
