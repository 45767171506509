import { action, makeObservable, observable, runInAction } from 'mobx';
import { urqlClient } from '../utils/urqlClient';
import {
  CreateUserConfigDocument,
  CreateUserConfigMutation,
  CreateUserConfigMutationVariables,
  GetUserConfigsDocument,
  GetUserConfigsQuery,
  GetUserConfigsQueryVariables,
  SchoolYearsDocument,
  SchoolYearsQuery,
  SchoolYearsQueryVariables,
  UpdateUserConfigDocument,
  UpdateUserConfigMutation,
  UpdateUserConfigMutationVariables,
  UpdateUserConfigSchoolYearDocument,
  UpdateUserConfigSchoolYearMutation,
  UpdateUserConfigSchoolYearMutationVariables,
} from '../types/planung-graphql-client-defs';
import { SortingState } from '@tanstack/react-table';
import { PimAuthClaimsType } from '../hooks/useAuthClaims';
import { getCacheTag } from '../hooks/useMemorizedCacheTag';
import dayjs from 'dayjs';

interface UserTableConfig {
  table: string;
  hiddenColumns: string[];
  columnsSort: SortingState;
}

export interface CurrentSchoolYear {
  uuid: string;
  name: string;
  shortName: string;
  start: string;
  end: string;
  fullTimeHours?: number | null | undefined;
}

export class UserConfigStore {
  private context = 'UserConfig';
  public pimProfileUuid = '';
  public tableConfigs: UserTableConfig[] | null = null;
  public selectedSchoolYear: CurrentSchoolYear | null = null;
  public language = 'de';
  public loading = false;

  constructor() {
    makeObservable(this, {
      pimProfileUuid: observable,
      tableConfigs: observable,
      selectedSchoolYear: observable,
      setSchoolYear: action,
      clearUserConfig: action,
    });
  }

  async loadUserConfig(authClaims: PimAuthClaimsType) {
    if (this.loading) return;
    this.loading = true;
    const { data } = await urqlClient
      .query<GetUserConfigsQuery, GetUserConfigsQueryVariables>(
        GetUserConfigsDocument,
        {
          pimProfileUuid: authClaims.getProfile().uuid,
        },
        { additionalTypenames: [this.context] },
      )
      .toPromise();

    let userConfig = data?.userConfigs[0];
    if (!userConfig) {
      const { data } = await urqlClient
        .mutation<CreateUserConfigMutation, CreateUserConfigMutationVariables>(
          CreateUserConfigDocument,
          {
            pimProfileUuid: authClaims.getProfile().uuid,
          },
          { additionalTypenames: [this.context] },
        )
        .toPromise();

      userConfig = data?.createUserConfigs.userConfigs[0];
    }

    if (userConfig?.schoolYear) {
      await this.setSchoolYear(userConfig.schoolYear);
    } else {
      // load and set the current school year
      const { data: schoolYearData } = await urqlClient
        .query<SchoolYearsQuery, SchoolYearsQueryVariables>(
          SchoolYearsDocument,
          {
            organizationUuid: authClaims.getOrganizationUuid(),
          },
          getCacheTag('SCHOOL_YEAR'),
        )
        .toPromise();

      const todaysSchoolYear = schoolYearData?.schoolYears?.find((schoolYear) => {
        return dayjs(schoolYear.start) <= dayjs() && dayjs(schoolYear.end) >= dayjs();
      });

      // since schoolyears are sorted by start date, we can just take the first one
      const futureSchoolYear = schoolYearData?.schoolYears?.find((schoolYear) => {
        return dayjs(schoolYear.start) > dayjs();
      });

      if (todaysSchoolYear) {
        await this.setSchoolYear(todaysSchoolYear);
      } else if (futureSchoolYear) {
        await this.setSchoolYear(futureSchoolYear);
      } else if (schoolYearData?.schoolYears.length) {
        await this.setSchoolYear(schoolYearData.schoolYears[0]);
      }
    }

    runInAction(() => {
      this.pimProfileUuid = authClaims.getProfile().uuid;
      this.tableConfigs =
        userConfig?.tableConfigs.map((tc) => ({
          table: tc.table,
          hiddenColumns: tc.hiddenColumns,
          columnsSort: tc.columnsSortConnection.edges.map(({ node }) => ({ id: node.id, desc: node.desc ?? false })),
        })) ?? null;
      this.language = 'de';
      this.loading = false;
    });
  }

  async setSchoolYear(selectedSchoolYear: CurrentSchoolYear | null) {
    await urqlClient
      .mutation<UpdateUserConfigSchoolYearMutation, UpdateUserConfigSchoolYearMutationVariables>(
        UpdateUserConfigSchoolYearDocument,
        {
          schoolYearUuid: selectedSchoolYear ? selectedSchoolYear.uuid : '',
          pimProfileUuid: this.pimProfileUuid,
        },
        { additionalTypenames: [this.context] },
      )
      .toPromise();

    runInAction(() => {
      this.selectedSchoolYear = selectedSchoolYear;
    });
  }

  async saveHiddenColumns(tableName: string, hiddenColumns: string[]) {
    const currentTableConfig = this.tableConfigs?.find((tc) => tc.table === tableName);

    await urqlClient
      .mutation<UpdateUserConfigMutation, UpdateUserConfigMutationVariables>(
        UpdateUserConfigDocument,
        {
          pimProfileUuid: this.pimProfileUuid,
          update: {
            tableConfigs: [
              {
                delete: [{ where: { node: { table: tableName } } }],
                create: [
                  {
                    node: {
                      table: tableName,
                      hiddenColumns: hiddenColumns,
                      columnsSort: {
                        create: currentTableConfig?.columnsSort.map((cs, index) => {
                          return { edge: { order: index }, node: { id: cs.id, desc: cs.desc } };
                        }),
                      },
                    },
                  },
                ],
              },
            ],
          },
        },
        { additionalTypenames: [this.context] },
      )
      .toPromise();

    runInAction(() => {
      this.tableConfigs =
        this.tableConfigs?.map((tc) => {
          if (tc.table === tableName) {
            return {
              table: tableName,
              hiddenColumns: hiddenColumns,
              columnsSort: tc.columnsSort,
            };
          }
          return tc;
        }) ?? null;
    });
  }

  async saveColumnsSort(tableName: string, sort: SortingState) {
    const currentTableConfig = this.tableConfigs?.find((tc) => tc.table === tableName);

    await urqlClient
      .mutation<UpdateUserConfigMutation, UpdateUserConfigMutationVariables>(UpdateUserConfigDocument, {
        pimProfileUuid: this.pimProfileUuid,
        update: {
          tableConfigs: [
            {
              delete: [{ where: { node: { table: tableName } } }],
              create: [
                {
                  node: {
                    table: tableName,
                    hiddenColumns: currentTableConfig?.hiddenColumns ?? [],
                    columnsSort: {
                      create: sort.map((cs, index) => {
                        return { edge: { order: index }, node: { id: cs.id, desc: cs.desc } };
                      }),
                    },
                  },
                },
              ],
            },
          ],
        },
      })
      .toPromise();

    runInAction(() => {
      this.tableConfigs =
        this.tableConfigs?.map((tc) => {
          if (tc.table === tableName) {
            return {
              table: tableName,
              hiddenColumns: tc.hiddenColumns,
              columnsSort: sort,
            };
          }
          return tc;
        }) ?? null;
    });
  }

  async clearUserConfig(pimProfileUuid: string) {
    await urqlClient
      .mutation(
        UpdateUserConfigDocument,
        {
          pimProfileUuid: pimProfileUuid,
          update: {
            schoolYear: { disconnect: {} },
            tableConfigs: [{ delete: [{}] }],
          },
        },
        { additionalTypenames: [this.context] },
      )
      .toPromise();
    runInAction(() => {
      this.pimProfileUuid = pimProfileUuid;
      this.tableConfigs = [];
      this.language = 'de';
    });
  }
}
