import styles from './TimetableBlock.module.scss';
import {
  AddIcon,
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  LazyLoader,
  Modal,
  Tooltip,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { TimetableSubjectContainerForm } from './Forms/TimetableSubjectContainerForm';
import { getDaysDifference, isFirstSameOrBeforeSecond } from '../../utils/dateCalculations';
import { useTimetableBlock } from './useTimetableBlock';
import { showSuccessDeleteToast, showUserErrorToast } from '../../utils/toast';
import { SubjectContainer } from './SubjectContainer';
import { Months } from './months/Months';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import dayjs from 'dayjs';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';

export const TimetableBlock = () => {
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { deleteTimetableBlock, isLoading, processedBlocks, openModal, TimetableBlockModal } = useTimetableBlock();

  const [containerModalOpen, setContainerModalOpen] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const activeBlockRef = useRef<HTMLDivElement | null>(null);

  const [progressWidth, setProgressWidth] = useState<number>(0);

  const schoolYearDays = getDaysDifference(schoolYear?.start, schoolYear?.end);
  const daysPassed = getDaysDifference(new Date(), schoolYear?.end);
  const yearProgressWidthPixel = wrapperRef.current?.clientWidth
    ? (wrapperRef.current?.clientWidth / schoolYearDays) * daysPassed
    : 0;

  async function handleDelete(uuid: string) {
    const result = await deleteTimetableBlock(uuid);
    if (result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessDeleteToast();
    }
  }

  const activeBlock = useMemo(() => {
    return processedBlocks.find((b) => {
      return b.currentActive;
    });
  }, [processedBlocks]);

  const getActiveBlockProgressWidthPixel = () => {
    if (activeBlock) {
      const daysPassed = getDaysDifference(activeBlock.start, dayjs().toDate());
      const blockWidthPixel = activeBlockRef.current ? activeBlockRef.current.clientWidth : 0;
      return (blockWidthPixel / activeBlock.days) * daysPassed;
    }
    return 0;
  };

  const onResize = useDebounceCallback(() => {
    if (activeBlock) {
      const progressWidthPixel = getActiveBlockProgressWidthPixel();
      const offset = activeBlockRef?.current ? activeBlockRef?.current?.offsetLeft + progressWidthPixel : 0;
      setProgressWidth(offset ?? 0);
    }
  }, 200);

  useResizeObserver({
    ref: wrapperRef,
    onResize,
  });

  useEffect(() => {
    onResize();
  }, [processedBlocks, activeBlockRef.current?.offsetLeft, activeBlock, onResize]);

  return (
    <div className={styles['timetable-block']} ref={wrapperRef}>
      {isLoading && <LazyLoader className={styles.loader} embedded size='xxl' />}
      <div className={styles['sub-headline']}>{t('timetable.title.plural')}</div>
      <div className={styles.blocks}>
        {processedBlocks.map((block, index) => {
          const isPast = isFirstSameOrBeforeSecond(block.end, new Date());
          const showProgress = isPast || block.currentActive;

          const daysPassed = getDaysDifference(new Date(), block.end);
          const blockWidthPercent = (block.days / schoolYearDays) * 100;
          const progressWidthPixel = getActiveBlockProgressWidthPixel();

          const actionClasses = classNames(styles.actions, {
            [styles['actions-right']]: block.days >= 14,
          });

          const blockClasses = classNames(styles.block, {
            [styles['has-block']]: !block.empty,
          });

          return (
            <div
              key={index + block.uuid}
              className={blockClasses}
              style={{ flex: `0 1 ${blockWidthPercent}%` }}
              ref={(el) => {
                if (block.currentActive) {
                  activeBlockRef.current = el;
                }
              }}
            >
              <Tooltip
                usePortal={true}
                content={
                  <>
                    <div>{block.name ?? t('timetableBlock.title.singular')}</div>
                    <div>{`${dayjs(block.start).format('DD.MM.')} - ${dayjs(block.end).format('DD.MM.')}`}</div>
                  </>
                }
              >
                {block.days >= 21 && (
                  <div className={styles.name}>{block.name ?? t('timetableBlock.title.singular')}</div>
                )}
                {block.days >= 14 && (
                  <div
                    className={styles['time-span']}
                  >{`${dayjs(block.start).format('DD.MM.')} - ${dayjs(block.end).format('DD.MM.')}`}</div>
                )}
                {block.days < 14 && <div className={styles.placeholder}></div>}
              </Tooltip>
              {showProgress && (
                <div
                  className={styles.progress}
                  style={{ width: isPast ? '100%' : `round(to-zero, ${progressWidthPixel}px, 1px)` }}
                ></div>
              )}
              {block.empty ? (
                <Button
                  className={actionClasses}
                  icon={<AddIcon className='small' />}
                  hierarchy='ghost'
                  onClick={() => {
                    openModal(block);
                  }}
                />
              ) : (
                <Dropdown
                  triggerClass={actionClasses}
                  trigger={<Button icon={<DotsVerticalIcon className='small' />} hierarchy='ghost' />}
                >
                  <DropdownMenu
                    data={[
                      {
                        label: t('common.edit'),
                        onClick: () => {
                          openModal(block);
                        },
                      },
                      {
                        label: t('timetableBlock.divide'),
                        disabled: true,
                      },
                      {
                        label: t('timetableBlock.mergeWith'),
                        disabled: true,
                      },
                      {
                        label: t('common.delete'),
                        type: 'default',
                        color: 'error',
                        onClick: () => handleDelete(block.uuid),
                        disabled: daysPassed < block.days,
                      },
                    ]}
                  />
                </Dropdown>
              )}
            </div>
          );
        })}
      </div>

      <SubjectContainer progress={progressWidth > 0 ? progressWidth : yearProgressWidthPixel} />
      <Months />

      <TimetableBlockModal />

      <Modal
        title={t('subjectContainer.add')}
        isOpen={containerModalOpen}
        onRequestClose={() => setContainerModalOpen(false)}
      >
        <TimetableSubjectContainerForm onClose={() => setContainerModalOpen(false)} />
      </Modal>
    </div>
  );
};
