import { TimetableReportType } from '@bp/planung-graphql-types';
import styles from './TimetableReports.module.scss';
import { TimetableReportsTable } from './TimetableReportsTable';
import { ReportTopic } from './types';

type TimetableReportsContentProps = {
  topic: ReportTopic;
  selected: TimetableReportType | null;
  timetableBlocks?: { name: string; uuid: string }[];
  teachingBlocks?: { name: string; uuid: string }[];
};

export const TimetableReportsContent = ({
  topic,
  selected,
  timetableBlocks,
  teachingBlocks,
}: TimetableReportsContentProps) => {
  return (
    <>
      {selected !== TimetableReportType.TeachingBlocksClass &&
        selected !== null &&
        timetableBlocks?.map((timetableBlock) => (
          <div key={timetableBlock.uuid} className={styles.block}>
            <div className={styles.name}>{timetableBlock?.name}</div>
            <TimetableReportsTable
              contextUuid={timetableBlock.uuid}
              type={'timetable'}
              topic={topic}
              subType={selected}
            />
          </div>
        ))}
      {selected === TimetableReportType.TeachingBlocksClass &&
        teachingBlocks?.map((teachingBlock) => (
          <div key={teachingBlock.uuid} className={styles.block}>
            <div className={styles.name}>{teachingBlock?.name}</div>
            <TimetableReportsTable
              contextUuid={teachingBlock.uuid}
              type={'teachingblock'}
              topic={topic}
              subType={selected}
            />
          </div>
        ))}
    </>
  );
};
