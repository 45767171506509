import { FC, useState } from 'react';
import styles from './TeachingBlockCardsForm.module.scss';
import { useTranslation } from 'react-i18next';
import { AddIcon, Button, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { FieldArray, Form, Formik } from 'formik';
import { SingleValue } from 'react-select';
import { LessonCard } from '../../LessonCard/LessonCard';
import {
  useCreateTeachingBlockCardMutation,
  useDeleteTeachingBlockCardsMutation,
  useTeachingBlockCardsQuery,
  useTeachingBlockLessonsQuery,
} from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { FormikHelpers } from 'formik/dist/types';
import { createIntArray } from '../../../utils/arrayFunc';
import { observer } from 'mobx-react-lite';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { getOriginalTeachingBlockCardsFormLesson } from '../../../utils/getOriginalCardsForLesson';
import { TeachingBlockCardType } from '../graphql/types';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';

type LessonsListFormProps = {
  versionUuid: string;
  subjectContainerUuid: string;
  onClose: () => void;
  lessonUuid: string;
};
type LessonTeachingBlockCardFormType = {
  cards: Array<TeachingBlockCardType>;
};

export const TeachingBlockCardsForm: FC<LessonsListFormProps> = observer(
  ({ subjectContainerUuid, onClose, lessonUuid, versionUuid }) => {
    const { pimAuthClaims } = useAuthClaims();
    const { t } = useTranslation();
    const context = useMemorizedCacheTag('VERSIONS');
    const [, createCards] = useCreateTeachingBlockCardMutation();

    const [loading, setLoading] = useState(false);

    const [, deleteCards] = useDeleteTeachingBlockCardsMutation();
    const lessonContext = useMemorizedCacheTag('TEACHINGBLOCK_LESSONS');
    const teachingBlockCardContext = useMemorizedCacheTag('TEACHING_BLOCK_CARDS');

    const [{ data }] = useTeachingBlockLessonsQuery({
      variables: {
        lessonWhere: { uuid: lessonUuid },
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
      },
      context: lessonContext,
    });

    const [{ data: cardsData }] = useTeachingBlockCardsQuery({
      variables: {
        where: {
          teachingBlockVersion: {
            uuid: versionUuid,
          },
          lesson: {
            uuid: lessonUuid,
          },
        },
      },
      context: teachingBlockCardContext,
    });

    const lesson = data?.lessons[0];
    const originalCards = lesson
      ? getOriginalTeachingBlockCardsFormLesson({
          lesson,
          subjectContainerUuid,
          version: { uuid: versionUuid, version: 0 },
        })
      : [];

    if (lesson) {
      const _cards = cardsData?.teachingBlockCards.sort((card, card2) => {
        if (card.startDate && !card2.startDate) {
          return 1;
        } else if (!card.startDate && card2.startDate) {
          return -1;
        } else {
          return 0;
        }
      });

      const initialValue: LessonTeachingBlockCardFormType = {
        cards:
          _cards?.map((card) => {
            return {
              uuid: card.uuid,
              lesson: card.lesson,
              duration: card.duration,
              locked: card.locked,
              lessonClasses: card.lessonClasses,
              rooms: card.rooms,
              subject: card.subject,
              teachers: card.teachers,
              editable: !card.locked && !card.startDate,
              teachingBlockVersion: {
                version: 0,
              },
              includeHoliday: false,
            };
          }) ?? [],
      };

      const handleSubmit = async (
        values: LessonTeachingBlockCardFormType,
        formikHelpers: FormikHelpers<LessonTeachingBlockCardFormType>,
      ) => {
        if (versionUuid) {
          setLoading(true);
          const newCards = values.cards.filter((card) => card.editable);
          const cardUuidsToRemoveUuids = initialValue.cards.filter((card) => card.editable).map((card) => card.uuid);

          if (cardUuidsToRemoveUuids.length > 0) {
            await deleteCards({ where: { uuid_IN: cardUuidsToRemoveUuids } }, teachingBlockCardContext);
          }

          if (newCards.length > 0) {
            await Promise.all(
              newCards.map((newCard) =>
                createCards(
                  {
                    duration: Number(newCard.duration),
                    lessonUuid: lesson.uuid,
                    versionUuid: versionUuid,
                  },
                  context,
                ),
              ),
            );
          }
          onClose();
          setLoading(false);
          formikHelpers.resetForm();
        }
      };
      const subjectContainerOptions: SelectOptionType[] = createIntArray(52).map((value) => {
        return {
          label: `${t('common.weeksCount', { count: value + 1 })}`,
          value: value + 1,
        };
      });

      let originalSumDuration = 0;
      originalCards.forEach((card) => {
        if (card.duration) {
          originalSumDuration = originalSumDuration + card.duration;
        }
      });

      const defaultCard: TeachingBlockCardType = {
        duration: 1,
        uuid: '',
        editable: true,
        lesson: lesson,
        locked: false,
        rooms: lesson.roomSupplyConnection.edges.length === 1 ? [lesson.roomSupplyConnection.edges[0].node] : [],
        lessonClasses: lesson.lessonClassesConnection.edges.map((e) => e.node),
        teachers: lesson.teachersConnection.edges.map((e) => e.node),
        subject: lesson.subject,
        teachingBlockVersion: {
          version: 0,
        },
        includeHoliday: false,
      };

      return (
        <>
          <Formik initialValues={initialValue} onSubmit={handleSubmit} enableReinitialize={true}>
            {({ setFieldValue, values, isSubmitting, dirty, resetForm }) => {
              const placedCards = values.cards.filter((card) => {
                return !card.editable;
              });
              const cardsForBulkEdit = values.cards.filter((card) => card.editable);

              const setCards = async (_cards: TeachingBlockCardType[]) => {
                await setFieldValue('cards', [..._cards, ...placedCards]);
              };
              const cardsForBulkEditDuration = cardsForBulkEdit.reduce((sum, card) => sum + (card.duration || 1), 0);

              return (
                <Form>
                  <div className={styles['lessons-list-form']}>
                    <div className={'tks__grid  mb-8'}>
                      <div className={'tks__row '}>
                        <div className={'tks__col '}>{`${t('subject.title.singular')}: ${lesson?.subject.name}`}</div>
                        <div className={'tks__col'}>
                          {`${t('teachers.titleSingular')}: ${lesson?.teachersConnection.edges.map(({ node }) => node.displayNameShort).join(', ')}`}
                        </div>
                        <div className={'tks__col'}>
                          {`${t('classes.title.plural')}: ${lesson?.lessonClassesConnection.edges.map(({ node }) => node.class.shortName).join(', ')}`}
                        </div>
                      </div>
                      <div className={'tks__row'}>
                        <div className={'tks__col'}>
                          <div className={styles['planned']}>
                            {t('lesson.plannedWeeks', {
                              planned: originalSumDuration,
                            })}
                          </div>
                        </div>
                        <div className={'tks__col'}>
                          <div className={styles['given']}>
                            {t('lesson.givenWeeks', {
                              given: values.cards.reduce(
                                (totalDuration, card) => totalDuration + (card.duration ?? 0),
                                0,
                              ),
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles['form-items']}>
                      <div className={'tks__grid'}>
                        <div className={'tks__row'}>
                          {subjectContainerUuid && (
                            <Button
                              hierarchy={'secondary'}
                              disabled={cardsForBulkEdit.length <= 1}
                              onClick={async () => {
                                await setCards([{ ...defaultCard, duration: cardsForBulkEditDuration }]);
                              }}
                            >
                              {t('subjectContainer.allConnected')}
                            </Button>
                          )}
                          {!lesson?.onlyInTimetableVersion && (
                            <Button
                              hierarchy={'secondary'}
                              disabled={placedCards.length > 0}
                              onClick={async () => {
                                await setFieldValue('cards', originalCards);
                              }}
                            >
                              {t('lesson.reset')}
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className={`${styles['items-wrapper']} tks__grid no-gap`}>
                        <FieldArray name={'cards'}>
                          {(arrayHelpers) => {
                            return (
                              <>
                                {values.cards.map((card, index) => {
                                  return (
                                    <div className='tks__row big-gap' key={index}>
                                      <div className='tks__col col-xs-5'>
                                        <Select
                                          options={subjectContainerOptions}
                                          disabled={card.locked || !card.editable}
                                          value={subjectContainerOptions.find((o) => o.value === card.duration)}
                                          onChange={async (option) => {
                                            const opt = option as SingleValue<SelectOptionType>;
                                            await setFieldValue(`cards.${index}`, { ...card, duration: opt?.value });
                                          }}
                                          name={`cards.${index}`}
                                          label={`${t('lesson.weekCard')} ${index + 1}`}
                                        ></Select>
                                      </div>
                                      <div className='tks__col col-xs-2 align-center mt-2'>
                                        <LessonCard
                                          lessonClasses={card.lessonClasses[0]}
                                          teacherColors={card.teachers.map((t) => t.timetableConfig?.color ?? '') ?? []}
                                          label={card.subject?.shortName ?? ''}
                                          count={1}
                                          duration={card.duration}
                                        />
                                      </div>
                                      <div className='tks__col col-xs-5 align-center mt-2'>
                                        <div className={'tks__row justify-end'}>
                                          {card.locked ? (
                                            <div className={'align-center'}>{t('card.locked')}</div>
                                          ) : card.startDate ? (
                                            <div className={'align-center'}>{t('card.placed')}</div>
                                          ) : (
                                            <></>
                                          )}
                                          <Button
                                            hierarchy='tertiary'
                                            disabled={!card.editable}
                                            onClick={async () => {
                                              await arrayHelpers.remove(index);
                                            }}
                                            icon={<DeleteIcon />}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                <Button
                                  hierarchy={'tertiary'}
                                  icon={<AddIcon />}
                                  onClick={async () => {
                                    await setFieldValue('cards', [...cardsForBulkEdit, defaultCard, ...placedCards]);
                                  }}
                                >
                                  {t('lesson.weekCard')}
                                </Button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </div>

                    <ModalBottomButtons
                      closeButton={{
                        callback: () => {
                          resetForm();
                          onClose();
                        },
                        text: t('common.cancelChanges'),
                      }}
                      submitButton={{
                        disabled: isSubmitting || !dirty,
                      }}
                      isLoading={loading}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      );
    }
    return <></>;
  },
);
