import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { AddIcon, Button, DeleteIcon, GroupedGroupOption } from '@bp/ui-components';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { LessonClassesFormItem } from './LessonClassesFormItem';
import { LessonFormClassType, LessonFormType } from '../../types';
import { v4 } from 'uuid';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';
import { Fragment, memo } from 'react';

export const LessonClassesForm = memo(({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const formik = useFormikContext<LessonFormType>();
  const usedClassesUuids = formik.values.lessonClasses?.map((lc) => lc.class.uuid);

  const { classesData, groupsData, divisionsData } = useLoadBasicData({ pause: !usedClassesUuids });

  const classOptions =
    classesData?.classes
      .filter((c) => c.schoolYear.uuid === currentSchoolYear?.uuid)
      .map((c) => {
        return { value: c.uuid, label: c.name };
      }) ?? [];

  return (
    <>
      <FieldArray name={'lessonClasses'}>
        {(arrayHelpers) => {
          return (
            <>
              <div className='form-group tks__grid'>
                {formik.values.lessonClasses?.map((lessonClass, index) => {
                  const currentClass = classesData?.classes.find((c) => c.uuid === lessonClass.class.uuid);
                  const classDivisionsUuids = currentClass?.divisions.map((d) => d.uuid);

                  const groupOptions: GroupedGroupOption[] =
                    divisionsData?.divisions
                      .filter((d) => classDivisionsUuids?.includes(d.uuid))
                      .map((d) => {
                        const groupUuids = d.groupsConnection.edges.map((g) => g.node.uuid);
                        const groups = groupsData?.groups.filter((g) => groupUuids.includes(g.uuid));
                        return {
                          label: d.name,
                          options:
                            groups?.map((g) => {
                              return { value: g.uuid, label: g.name };
                            }) ?? [],
                        };
                      }) ?? [];

                  return (
                    <div key={lessonClass.uuid} className='tks__row'>
                      <div className='tks__col col-xs-9'>
                        <LessonClassesFormItem
                          disabled={disabled}
                          classOptions={classOptions}
                          classUuid={currentClass?.uuid ?? ''}
                          className={currentClass?.name ?? ''}
                          groupOptions={groupOptions}
                          groups={lessonClass.groups.map((g) => g.uuid)}
                          index={index}
                          onClassChange={async (classUuid) => {
                            arrayHelpers.replace(index, {
                              groups: [],
                              class: { uuid: classUuid },
                              uuid: lessonClass.uuid,
                            });
                          }}
                          onGroupsChange={async (groups) => {
                            arrayHelpers.replace(index, {
                              groups: groups.map((uuid) => ({ uuid })),
                              class: { uuid: lessonClass.class.uuid },
                              uuid: lessonClass.uuid,
                            });
                          }}
                        />
                      </div>
                      <div className='tks__col col-xs-3'>
                        <Button
                          className='mt-6 align-end'
                          hierarchy='tertiary'
                          disabled={disabled}
                          icon={
                            <DeleteIcon
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <Button
                hierarchy={'tertiary'}
                disabled={disabled}
                icon={<AddIcon />}
                onClick={async () => {
                  if (formik.values.lessonClasses && formik.values.lessonClasses.length > 0) {
                    for (const index in formik.values.lessonClasses) {
                      await formik.setFieldTouched(`lessonClasses.${index}`);
                    }
                  }
                  const lessonClass: LessonFormClassType = {
                    uuid: v4(),
                    class: { uuid: v4() },
                    groups: [],
                  };

                  arrayHelpers.push(lessonClass);
                }}
              >
                {t('classes.createClass')}
              </Button>
            </>
          );
        }}
      </FieldArray>
    </>
  );
});
LessonClassesForm.displayName = 'LessonClassesForm';
