import { useTranslation } from 'react-i18next';
import styles from '../../Constraints.module.scss';
import { Button, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { useCreateNumbersSelectValues } from '../../../../hooks/useCreateNumbersSelectValues';
import { ConstraintsFormInitialValuesType } from '../../types';
import { usePriorityOptions } from '../../../../hooks/usePriorityOptions';
import classNames from 'classnames';

type FreePeriodConditionItemProps = {
  onRemove: () => void;
};

export const FreePeriodConstraintItem = ({ onRemove }: FreePeriodConditionItemProps) => {
  const { t } = useTranslation();
  const { setFieldValue, setFieldTouched, values } = useFormikContext<ConstraintsFormInitialValuesType>();

  const options = useCreateNumbersSelectValues();
  const priorityOptions = usePriorityOptions();

  const handleRemove = () => {
    setFieldValue('maxFreePeriod', null);
    setFieldTouched('maxFreePeriod', false);
    setFieldTouched('maxFreePeriodPriority', false);
    setFieldValue('maxFreePeriodPriority', null);
    onRemove();
  };

  return (
    <>
      <div className={styles['title']}>{t('conditions.freePeriod.label')}</div>
      <div className={styles['row-wrapper']}>
        <div className='tks__row row-xs-11 mr-5'>
          <div className='tks__col col-xs-12'>
            <Select
              options={options}
              onChange={(value) => {
                const opt = value as SingleValue<SelectOptionType>;
                setFieldTouched('maxFreePeriod', true);
                setFieldValue('maxFreePeriod', opt?.value);
              }}
              value={values.maxFreePeriod !== undefined ? options[values.maxFreePeriod] : null}
              label={t('conditions.freePeriod.freePeriods') as string}
              name={'maxFreePeriod'}
            />
          </div>
        </div>
        <div className='tks__col col-xs-1'>
          <Select
            className={classNames(styles['priority-icon-select'], styles[`icon-${values.maxFreePeriodPriority}`])}
            options={priorityOptions}
            value={priorityOptions.find((option) => option.value === values.maxFreePeriodPriority)}
            onChange={(value) => {
              const opt = value as SingleValue<SelectOptionType>;
              setFieldTouched('maxFreePeriodPriority', true);
              setFieldValue('maxFreePeriodPriority', opt?.value);
            }}
            label={t('common.priority.full') as string}
            name={'maxFreePeriodPriority'}
            fitMenuToOptions
            showIconOnly
          />
        </div>
        <div className={styles['button-wrapper']}>
          <Button name='remove' hierarchy='secondary' onClick={handleRemove} icon={<DeleteIcon />} />
        </div>
      </div>
    </>
  );
};
