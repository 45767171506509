import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Page/Page';
import IconMenu from '../../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../../components/IconMenuItem/IconMenuItem';
import { useNavigate } from 'react-router-dom';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';

export const SubstitutionsSetupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const permissions = usePermissionChecker();

  return (
    <Page title={`${t('substitutions.title.plural')} ${t('substitutions.setup').toLowerCase()}`}>
      <IconMenu headline={t('common.whatToDo')}>
        <IconMenuItem
          label={t('schoolEvents.title.plural')}
          icon={undefined}
          bubbleType={1}
          onClick={() => navigate(t('routes.schoolEvents.slug'))}
          disabled={!permissions?.isOmniAdmin()}
        />
      </IconMenu>
    </Page>
  );
};
