import React, { memo, useState } from 'react';
import classNames from 'classnames';
import styles from './SidebarWrapper.module.scss';
import { Sidebar } from '../Sidebar/Sidebar';

export const SideBarWrapper = memo(({ children }: { children: React.ReactNode }) => {
  const [isFixed, setIsFixed] = useState(false);
  return (
    <>
      <div className={classNames(styles.wrapper, { [styles.fixed]: isFixed })} data-cy='layoutWrapper'>
        <Sidebar isFixed={isFixed} setIsFixed={setIsFixed} />
        {children}
      </div>
    </>
  );
});

SideBarWrapper.displayName = 'SideBarWrapper';
