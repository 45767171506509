import { useColumnsSort } from '../../../hooks/useColumnsSort';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Row,
  Table,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeachingBlockVersionTableType } from '../graphql/types';
import { createTeachingBlockVersionTableColumns } from '../utils/TeachingBlockVersionTableUtils';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { hexToColorOption } from '../../../utils/colorUtils';

type TeachingBlockVersionTeachersTableProps = {
  versionUuid: string | undefined;
  onRowSelection: (rowData: TeachingBlockVersionTableType, index: number) => void;
  onShowLessonClick: (uuid: string) => void;
  versionTeacherUuids: string[];
};

export const TeachingBlockVersionTeachersTable = ({
  versionTeacherUuids,
  versionUuid,
  onRowSelection,
  onShowLessonClick,
}: TeachingBlockVersionTeachersTableProps) => {
  const { t } = useTranslation();

  const { sorting, saveSorting } = useColumnsSort('teachingBlockVersionTeachersTable');
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting({ 0: true });

  const columns = useMemo(() => createTeachingBlockVersionTableColumns(t('persons.name')), [t]);

  const createActionItems = useCallback((row: Row<TeachingBlockVersionTableType>): DropdownMenuItem[] => {
    return [
      {
        label: t('teachingBlockVersion.editData'),
      },
      {
        label: t('teachingBlockVersion.test'),
      },
      {
        label: t('teachingBlockVersion.remove'),
        type: 'default',
        color: 'error',
      },
    ];
  }, []);

  const { teacherData } = useLoadBasicData({ pause: !versionUuid });

  const teacherTableData: TeachingBlockVersionTableType[] = useMemo(() => {
    return (
      teacherData?.people
        .filter((t) => versionTeacherUuids.includes(t.uuid))
        .map((t) => {
          const { html, label } = hexToColorOption(t.timetableConfig?.color ?? '');
          return {
            color: {
              color: html,
              label,
            },
            count: 0,
            name: t.displayNameShort ?? t.fullName,
            shortName: t.shortName,
            uuid: t.uuid,
          };
        }) ?? []
    );
  }, [teacherData?.people, versionTeacherUuids]);

  return (
    <Table<TeachingBlockVersionTableType>
      showBorderRadius
      showShadow
      canScroll
      minHeight={900}
      sorting={sorting}
      onSortingChange={saveSorting}
      columns={columns}
      data={teacherTableData}
      lastColWidth='140px'
      lastCol={(row) => {
        return (
          <ButtonGroup>
            <Button hierarchy='secondary' onClick={() => onShowLessonClick(row.original.uuid)}>
              {t('teachingBlockVersion.showLessons')}
            </Button>
            <Dropdown
              noPadding
              trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
            >
              <DropdownMenu data={createActionItems(row)} />
            </Dropdown>
          </ButtonGroup>
        );
      }}
      showRowHover
      onRowClick={(event, row) => {
        if (onRowSelectionChange) onRowSelectionChange({ [row.id]: true });
        onRowSelection(row.original, row.index);
        document.querySelector('#content')?.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      rowSelection={rowSelection}
      showSelected
    />
  );
};
