import { useRouteError } from 'react-router-dom';
import styles from '../../RequireAuth.module.css';
import { AboutModal } from '../../components/AboutModal/AboutModal';
import { Button, Card, InfoIcon, Logo } from '@bp/ui-components';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ErrorPage = memo(() => {
  const error = useRouteError();
  const { t } = useTranslation();
  const [showAbout, setShowAbout] = useState<boolean>(false);

  console.error(error);

  return (
    <div className={styles.wrapper} data-cy='layoutWrapper'>
      <header></header>
      <AboutModal state={showAbout} setState={setShowAbout}></AboutModal>
      <main className={styles.container}>
        <Card
          fitContent={true}
          header={{
            headline: 'Error',
            actions: [
              {
                icon: <InfoIcon />,
                callback: () => {
                  setShowAbout(true);
                },
              },
            ],
          }}
        >
          <div className={styles.logo}>
            <Logo type={'planung'} collapsed={false} />
          </div>
          <code>{error?.toString()}</code>
          <div className={styles.vspace}>
            {/* eslint-disable-next-line no-restricted-globals */}
            <Button hierarchy='primary' onClick={() => history.back()} fullWidth={true}>
              {t('common.back')}
            </Button>
          </div>
        </Card>
      </main>
      <footer>Impressum | Datenschutz | Nutzungsbedingungen | Partner</footer>
    </div>
  );
});

ErrorPage.displayName = 'ErrorPage';
