import {
  TimetablePrintBaseData,
  TimetablePrintLayoutData,
} from '../components/TimetablePrintConfiguration/TimetablePrintConfiguration';
import {
  TimetablePrintColorOrigins,
  TimetablePrintColorPositions,
  TimetablePrintLayoutOptions,
  TimetableReportType,
} from '@bp/planung-graphql-types';

export const timetablePrintColorDefaults: Record<TimetableReportType, Omit<TimetablePrintBaseData, 'color'>> = {
  allClasses: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Teacher,
  },
  allSubjects: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Class,
  },
  allRooms: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Teacher,
  },
  allTeachers: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Class,
  },
  singleSubject: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Class,
  },
  singleRoom: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Teacher,
  },
  singleClass: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Subject,
  },
  singleTeacher: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Class,
  },
  teachingBlocksSubject: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Class,
  },
  teachingBlocksTeacher: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Subject,
  },
  teachingBlocksRoom: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Subject,
  },
  teachingBlocksClass: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Subject,
  },
  doorSigns: {
    exactMinute: true,
    printClasses: true,
    printLogo: false,
    scaleText: true,
    printSubtitle: true,
    colorPosition: TimetablePrintColorPositions.Full,
    colorOrigin: TimetablePrintColorOrigins.Teacher,
  },
};

export const timetablePrintLayoutDefaults: Record<TimetableReportType, TimetablePrintLayoutData> = {
  allClasses: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.Empty,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.TeacherLong,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.GroupName,
  },
  allSubjects: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.Empty,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.Empty,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.Empty,
  },
  allRooms: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.ClassName,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.TeacherLong,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.GroupName,
  },
  allTeachers: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.Empty,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.TeacherLong,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.GroupName,
  },
  singleSubject: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.Empty,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.Empty,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.Empty,
  },
  singleRoom: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.ClassName,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.TeacherLong,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.GroupName,
  },
  singleClass: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.RoomName,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.TeacherLong,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.GroupName,
  },
  singleTeacher: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.RoomName,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.ClassName,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.GroupName,
  },
  teachingBlocksSubject: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.Empty,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.Empty,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.Empty,
  },
  teachingBlocksTeacher: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.RoomName,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.ClassName,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.Empty,
  },
  teachingBlocksRoom: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.RoomName,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.ClassName,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.Empty,
  },
  teachingBlocksClass: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.RoomName,
    bottomRight: TimetablePrintLayoutOptions.GroupName,
    center: TimetablePrintLayoutOptions.SubjectLong,
    topLeft: TimetablePrintLayoutOptions.TeacherLong,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.Empty,
  },
  doorSigns: {
    bottomCenter: TimetablePrintLayoutOptions.Empty, // center2ndLine
    bottomLeft: TimetablePrintLayoutOptions.SubjectLong,
    bottomRight: TimetablePrintLayoutOptions.Empty,
    center: TimetablePrintLayoutOptions.TeacherLong,
    topLeft: TimetablePrintLayoutOptions.ClassName,
    topCenter: TimetablePrintLayoutOptions.Empty,
    topRight: TimetablePrintLayoutOptions.GroupName,
  },
};
