import { SchoolYearType } from '../graphql/types';
import { useTranslation } from 'react-i18next';
import { Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import { useMutation } from 'urql';
import { CreateSchoolYearsDocument, UpdateSchoolYearsDocument } from '../../../types/planung-graphql-client-defs';
import { schema } from './validation/schema';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { Form, Formik, FormikHelpers } from 'formik';
import { DatePickerSchoolYearsField } from './DatePickerSchoolYearsField';
import { useState } from 'react';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';

type SchoolYearFormProps = {
  schoolYear: SchoolYearType | null;
  others: SchoolYearType[] | null;
  handleClose: () => void;
};

export type SchoolYearListType = {
  name: string;
  shortName: string;
  fullTimeHours?: number | null;
  start: Date;
  end: Date;
};

export const SchoolYearForm = ({ schoolYear, others, handleClose }: SchoolYearFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();

  const context = useMemorizedCacheTag('SCHOOL_YEAR');

  const [, create] = useMutation(CreateSchoolYearsDocument);
  const [, update] = useMutation(UpdateSchoolYearsDocument);
  const [validateOnChange, setValidateOnChange] = useState(!!schoolYear);

  const initialValue: SchoolYearListType = {
    name: schoolYear?.name ?? '',
    shortName: schoolYear?.shortName ?? '',
    fullTimeHours: schoolYear?.fullTimeHours,
    start: schoolYear?.start,
    end: schoolYear?.end,
  };

  const handleSubmit = async (values: SchoolYearListType, formHelpers: FormikHelpers<SchoolYearListType>) => {
    let mutationResult;

    const data = {
      name: values.name,
      shortName: values.shortName,
      fullTimeHours: (values.fullTimeHours as unknown as string) === '' ? 0 : values.fullTimeHours,
      start: values.start,
      end: values.end,
    };

    if (schoolYear) {
      mutationResult = await update(
        {
          where: {
            uuid: schoolYear.uuid,
          },
          update: data,
        },
        context,
      );
      !mutationResult && showSuccessUpdateToast();
    } else {
      mutationResult = await create(
        {
          input: {
            ...data,
            organization: {
              connect: {
                where: {
                  node: {
                    uuid: pimAuthClaims.getOrganizationUuid(),
                  },
                },
              },
            },
          },
        },
        context,
      );
      !mutationResult && showSuccessCreateToast();
    }
    if (!mutationResult || mutationResult.error) {
      showUserErrorToast({ error: '' });
    }
    formHelpers.resetForm();
    handleClose();
  };

  return (
    <Formik
      validationSchema={() => {
        return schema(others);
      }}
      initialValues={initialValue}
      onSubmit={handleSubmit}
      validateOnChange={validateOnChange}
      validateOnBlur={false}
    >
      {(formik) => {
        const values = formik.values;
        const errors = formik.errors;
        const touched = formik.touched;

        return (
          <Form>
            <Grid useFormGap>
              <GridRow spacingBottom='s'>
                <GridColumn width={10}>
                  <Input
                    label={t('schoolYear.name')}
                    name={'name'}
                    onChange={formik.handleChange}
                    value={values.name}
                    {...(errors.name &&
                      touched.name && {
                        error: errors.name,
                      })}
                  />
                </GridColumn>
                <GridColumn width={2}>
                  <Input
                    label={t('schoolYear.shortName')}
                    name={'shortName'}
                    onChange={formik.handleChange}
                    value={values.shortName}
                    {...(errors.shortName &&
                      touched.shortName && {
                        error: errors.shortName,
                      })}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingTop='none' spacingBottom='none'>
                <GridColumn width={6}>
                  <DatePickerSchoolYearsField
                    label={t('schoolYear.start')}
                    fieldName={'start'}
                    validateOnChange={validateOnChange}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <DatePickerSchoolYearsField
                    label={t('schoolYear.end')}
                    fieldName={'end'}
                    validateOnChange={validateOnChange}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingTop='s'>
                <GridColumn width={4}>
                  <Input
                    name={'fullTimeHours'}
                    value={values.fullTimeHours}
                    onChange={async (e) => {
                      const hours = parseFloat(e.target.value);
                      await formik.setFieldValue('fullTimeHours', isNaN(hours) ? null : hours);
                    }}
                    label={t('contracts.fullTimeHours')}
                    type={'number'}
                    error={formik.errors.fullTimeHours}
                  />
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                text: t('common.cancelChanges'),
                callback: () => {
                  formik.resetForm();
                  handleClose();
                },
              }}
              submitButton={{
                disabled: formik.isSubmitting || !formik.dirty || formik.isValidating,
                callback: () => {
                  setValidateOnChange(true);
                  formik.handleSubmit();
                },
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
