import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Page } from '../../../../components/Page/Page';
import { TeachersTable } from '../../../../components/Lessons/Tables/Teachers/TeachersTable';

export const TeachersPage: FC = observer(() => {
  return (
    <Page>
      <TeachersTable />
    </Page>
  );
});
