import { t } from 'i18next';
import { Outlet, RouteObject } from 'react-router-dom';
import { TimetableVersion } from './TimetableVersion/TimetableVersion';
import { TimetablePlanPage } from './TimetablePlanPage';
import { urqlClient } from '../../../utils/urqlClient';
import {
  _TimetableVersionsDocument,
  _TimetableVersionsQuery,
  _TimetableVersionsQueryVariables,
  TimetableVersionsQuery,
} from '../../../types/planung-graphql-client-defs';
import { TimetableVersionData } from './TimetableVersion/Data/TimetableVersionData';
import { addLeadingZero } from '../../../utils/helper';
import { filterLoader } from '../../../utils/routes-loader/filterLoader';
import { TeachingBlockVersion } from './TeachingBlockVersion/TeachingBlockVersion';
import { getCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { TeachingBlockVersionData } from './TeachingBlockVersion/Data/TeachingBlockVersionData';
import { PlanTimetableLessons } from './TimetableVersion/Data/Lessons/PlanTimetableLessons';
import { PlanTeachingBlockClasses } from './TeachingBlockVersion/Data/Classes/PlanTeachingBlockClasses';
import { PlanTeachingBlockSubjects } from './TeachingBlockVersion/Data/Subjects/PlanTeachingBlockSubjects';
import { PlanTeachingBlockTeachers } from './TeachingBlockVersion/Data/Teachers/PlanTeachingBlockTeachers';
import { PlanTeachingBlockRooms } from './TeachingBlockVersion/Data/Rooms/PlanTeachingBlockRooms';
import { PlanTeachingBlockLessons } from './TeachingBlockVersion/Data/Lessons/PlanTeachingBlockLessons';
import { PlanTeachingBlockDependencies } from './TeachingBlockVersion/Data/Dependencies/PlanTeachingBlockDependencies';
import { TeachingBlockVersionBoard } from './TeachingBlockVersion/Board/TeachingBlockVersionBoard';
import { PlanTimetableClasses } from './TimetableVersion/Data/Classes/PlanTimetableClasses';
import { PlanTimetableSubjects } from './TimetableVersion/Data/Subjects/PlanTimetableSubjects';
import { PlanTimetableTeachers } from './TimetableVersion/Data/Teachers/PlanTimetableTeachers';
import { PlanTimetableRooms } from './TimetableVersion/Data/Rooms/PlanTimetableRooms';
import { PlanTimetableDependencies } from './TimetableVersion/Data/Dependencies/PlanTimetableDependencies';
import { Board } from './TimetableVersion/Board/Board';
import { teachingBlockVersionLoader } from '../../../utils/routes-loader/teachingBlockVersionLoader';

export const timetablePlanRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      element: <TimetablePlanPage />,
      index: true,
    },
    // TIMETABLE
    {
      path: t('routes.versions.slug'),
      element: <Outlet />,
      children: [
        {
          path: ':versionUuid',
          id: 'version',
          element: <TimetableVersion baseSlug={baseSlug} />,
          loader: ({ params }) => {
            return urqlClient
              .query<_TimetableVersionsQuery, _TimetableVersionsQueryVariables>(
                _TimetableVersionsDocument,
                {
                  where: {
                    uuid: params.versionUuid ?? '',
                  },
                },
                getCacheTag('TIMETABLE_VERSIONS'),
              )
              .toPromise();
          },
          handle: {
            crumb: ({ data }: { data: TimetableVersionsQuery }) => {
              return [
                {
                  slug: `${baseSlug}/${t('routes.versions.slug')}/${data.timetableVersions[0].uuid}/${t('routes.versionPages.data.slug')}/${t('routes.versionsFilter.class.slug')}`,
                  title: `${data.timetableVersions[0].timetable.name} - ${addLeadingZero(
                    data.timetableVersions[0].version,
                  )}`,
                },
              ];
            },
          },
          children: [
            {
              path: t('routes.versionPages.data.slug'),
              element: <TimetableVersionData />,
              children: [
                {
                  path: t('routes.versionsFilter.class.slug'),
                  element: <PlanTimetableClasses />,
                  handle: {
                    crumb: () => ({
                      title: t('routes.versionsFilter.class.title'),
                    }),
                  },
                },
                {
                  path: t('routes.versionsFilter.subject.slug'),
                  element: <PlanTimetableSubjects />,
                  handle: {
                    crumb: () => ({
                      title: t('routes.versionsFilter.subject.title'),
                    }),
                  },
                },
                {
                  path: t('routes.versionsFilter.person.slug'),
                  element: <PlanTimetableTeachers />,
                  handle: {
                    crumb: () => ({
                      title: t('routes.versionsFilter.person.title'),
                    }),
                  },
                },
                {
                  path: t('routes.versionsFilter.room.slug'),
                  element: <PlanTimetableRooms />,
                  handle: {
                    crumb: () => ({
                      title: t('routes.versionsFilter.room.title'),
                    }),
                  },
                },
                {
                  path: t('routes.versionsFilter.lesson.slug'),
                  element: <PlanTimetableLessons />,
                  handle: {
                    crumb: () => ({
                      title: t('routes.versionsFilter.lesson.title'),
                    }),
                  },
                  loader: filterLoader,
                },
                {
                  path: t('routes.versionsFilter.dependency.slug'),
                  element: <PlanTimetableDependencies />,
                  handle: {
                    crumb: () => ({
                      title: t('routes.versionsFilter.dependency.title'),
                    }),
                  },
                },
              ],
            },
            {
              path: t('routes.versionPages.board.slug'),
              element: <Board />,
              handle: {
                crumb: () => ({
                  title: t('routes.versionPages.board.title'),
                }),
              },
            },
          ],
        },
      ],
    },

    // TEACHINGBLOCK
    {
      path: t('routes.teachingBlockVersions.slug'),
      element: <Outlet />,
      children: [
        {
          path: ':teachingBlockVersionUuid',
          id: 'teachingBlockVersion',
          element: <TeachingBlockVersion baseSlug={baseSlug} />,
          loader: teachingBlockVersionLoader,
          handle: {
            crumb: (loaderResponse: { subjectContainerName: string; versionName: string; versionUuid: string }) => {
              return [
                {
                  slug: `${baseSlug}/${t('routes.teachingBlockVersions.slug')}/${loaderResponse.versionUuid}/${t('routes.versionPages.data.slug')}/${t('routes.versionsFilter.class.slug')}`,
                  title: `${loaderResponse.subjectContainerName} - ${loaderResponse.versionName}`,
                },
              ];
            },
          },
          children: [
            {
              path: t('routes.versionPages.data.slug'),
              element: <TeachingBlockVersionData />,
              children: [
                {
                  path: t('routes.versionsFilter.class.slug'),
                  element: <PlanTeachingBlockClasses />,
                  handle: {
                    crumb: () => ({
                      slug: `${baseSlug}/${t('routes.versions.slug')}/${t('routes.versionsFilter.class.slug')}`,
                      title: t('routes.versionsFilter.class.title'),
                    }),
                  },
                },
                {
                  path: t('routes.versionsFilter.subject.slug'),
                  element: <PlanTeachingBlockSubjects />,
                  handle: {
                    crumb: () => ({
                      slug: `${baseSlug}/${t('routes.versions.slug')}/${t('routes.versionsFilter.subject.slug')}`,
                      title: t('routes.versionsFilter.subject.title'),
                    }),
                  },
                },
                {
                  path: t('routes.versionsFilter.person.slug'),
                  element: <PlanTeachingBlockTeachers />,
                  handle: {
                    crumb: () => ({
                      slug: `${baseSlug}/${t('routes.versions.slug')}/${t('routes.versionsFilter.person.slug')}`,
                      title: t('routes.versionsFilter.person.title'),
                    }),
                  },
                },
                {
                  path: t('routes.versionsFilter.room.slug'),
                  element: <PlanTeachingBlockRooms />,
                  handle: {
                    crumb: () => ({
                      slug: `${baseSlug}/${t('routes.versions.slug')}/${t('routes.versionsFilter.room.slug')}`,
                      title: t('routes.versionsFilter.room.title'),
                    }),
                  },
                },
                {
                  path: t('routes.versionsFilter.lesson.slug'),
                  element: <PlanTeachingBlockLessons />,
                  handle: {
                    crumb: () => ({
                      slug: `${baseSlug}/${t('routes.versions.slug')}/${t('routes.versionsFilter.lesson.slug')}`,
                      title: t('routes.versionsFilter.lesson.title'),
                    }),
                  },
                  loader: filterLoader,
                },
                {
                  path: t('routes.versionsFilter.dependency.slug'),
                  element: <PlanTeachingBlockDependencies />,
                  handle: {
                    crumb: () => ({
                      slug: `${baseSlug}/${t('routes.versions.slug')}/${t('routes.versionsFilter.dependency.slug')}`,
                      title: t('routes.versionsFilter.dependency.title'),
                    }),
                  },
                },
              ],
              loader: ({ params }) => {
                return { activeTimetableVersionTab: 'data' };
              },
            },
            {
              path: t('routes.versionPages.board.slug'),
              element: <TeachingBlockVersionBoard />,
              loader: () => {
                return { activeTimetableVersionTab: 'board' };
              },
            },
          ],
        },
      ],
    },
  ];
};
