import { ArrowRightIcon, Button, Table, TableColumns } from '@bp/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnsSort } from '../../../../hooks/useColumnsSort';
import styles from '../../LessonsComponents.module.scss';

export type OverbookedTeachersTableDataType = {
  uuid: string;
  teacherName: string;
  subjects: string;
  qualified: boolean;
  deputateRelation: string;
  relationRaw: number;
  status: string;
};

export const OverbookedTeachersTable = ({ data }: { data: OverbookedTeachersTableDataType[] }) => {
  const { t } = useTranslation();
  const { sorting, saveSorting } = useColumnsSort('status');

  const memoizedData = useMemo(() => data, [data]);
  const tableColumns = useMemo((): TableColumns<OverbookedTeachersTableDataType>[] => {
    return [
      {
        header: t('persons.title.singular'),
        id: 'teacherName',
        accessorKey: 'teacherName',
      },
      {
        header: t('subject.title.plural') as string,
        id: 'subjects',
        accessorKey: 'subjects',
      },
      {
        header: t('deputate.titleSingluar') as string,
        id: 'deputateRelation',
        accessorKey: 'deputateRelation',
      },

      {
        header: t('common.status') as string,
        id: 'status',
        accessorKey: 'status',
      },
    ];
  }, []);

  return (
    <Table<OverbookedTeachersTableDataType>
      className={styles['overbook-teachers-table']}
      showSort
      sorting={sorting}
      onSortingChange={saveSorting}
      columns={tableColumns}
      data={memoizedData}
      canScroll
      emptyStateSettings={{ size: 'small', padding: 's', title: t('persons.noOverbooked'), subtitle: '' }}
      showBorderRadius
      showShadow
      maxHeight={244}
      lastCol={() => {
        return <Button disabled hierarchy={'tertiary'} onClick={() => {}} icon={<ArrowRightIcon />} />;
      }}
    />
  );
};
