export function partition<T>(array: T[], isValid: (elem: T) => boolean) {
  return array.reduce(
    ([pass, fail]: T[][], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []],
  );
}

export function groupByMap<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, V> {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function groupByMapArray<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function groupByArray<K, V>(list: Array<V>, keyGetter: (input: V) => K): V[][] {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  const array: V[][] = [];
  map.forEach((value) => {
    array.push(value);
  });
  return array;
}

export function createIntArray(length: number): number[] {
  return Array.from({ length }, (_, index) => index);
}

export function createArrayOf<ArrayValue>(length: number, item: ArrayValue): ArrayValue[] {
  return Array.from({ length }, () => item);
}

export function hasOverlap<T>(array1: T[], array2: T[], propertyToCheck: keyof T): boolean {
  const valuesArray1 = array1.map((obj) => obj[propertyToCheck]);
  const valuesArray2 = array2.map((obj) => obj[propertyToCheck]);

  for (const value of valuesArray1) {
    if (valuesArray2.includes(value)) {
      return true;
    }
  }

  return false;
}

export function findAndReplace<T>(array: T[], item: T): T[] {
  const index = array.findIndex((i) => i === item);
  if (index > -1) {
    array = array.splice(index, 1, item);
  }
  return array;
}

export const multiplyArray = (arr: number[]) =>
  arr.reduce((accumulator, currentValue) => accumulator * currentValue, 1) ?? 1;

export const sumUpArray = (arr: number[]) => arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

export function uniqueBy<T>(array: T[], key: keyof T): T[] {
  const set = new Set();
  return array.filter((item) => {
    const k = item[key];
    return set.has(k) ? false : set.add(k);
  });
}
