import styles from './../TimetableGrid.module.scss';
import { GridCell } from '../GridCell/GridCell';
import { CellItem, DayItem, HourItem, RowItem } from '../../../stores/PinboardStore';
import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { GridCards } from '../GridCards/GridCards';
import { toJS } from 'mobx';

export const GridDay = observer(
  ({
    daysWidth,
    hours,
    row,
    day,
    dayIndex,
    cellWidth,
  }: {
    daysWidth: string;
    cellWidth: string;
    hours: HourItem[];
    row: RowItem;
    day: DayItem;
    dayIndex: number;
  }) => {
    return (
      <div className={styles['row']} style={{ flex: `1 1 ${daysWidth}` }}>
        {hours.map((hour, index) => {
          const cell: CellItem = { row: toJS(row), day: day, hour: hour };
          if (hour.isPause) {
            return <Fragment key={`${row.value}-${day.value}-${hour.value}-pause`}></Fragment>;
          }
          return (
            <div
              key={`${row.value}-${day.value}-${hour.value}-cell`}
              style={{ position: 'relative', width: 'calc(100% - 2px)', height: '100%' }}
            >
              <GridCell
                key={`${row.value}-${day.value}-${hour.value}`}
                cell={cell}
                dayIndex={dayIndex}
                width={cellWidth}
              />
              <GridCards cell={cell} />
            </div>
          );
        })}
      </div>
    );
  },
);
GridDay.displayName = 'GridDay';
