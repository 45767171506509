import { ControlIcon, EmptyState, LazyLoader, Modal } from '@bp/ui-components';
import { FC, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ConstraintModal.module.scss';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useClassConstraintsForClassesQuery } from '../../../types/planung-graphql-client-defs';
import { ConstraintsFormInitialValuesType } from '../../Constraints/types';
import { ConstraintModalItem } from './ContraintModalItem';
import { observer } from 'mobx-react-lite';
import { useTimetableStore } from '../TimetableProvider';

export const ConstraintModal: FC = observer(() => {
  const { t } = useTranslation();

  const pinboardStore = useTimetableStore();

  const selectedCard = pinboardStore.getCardToShowOnInfo();

  const uuids = useMemo(() => {
    return selectedCard?.lessonClasses.map((lessonClass) => lessonClass.class.uuid);
  }, [selectedCard?.lessonClasses]);

  const context = useMemorizedCacheTag('CLASSES_CONSTRAINTS', selectedCard?.uuid);

  const [{ data }] = useClassConstraintsForClassesQuery({
    variables: {
      versionUuid: pinboardStore.getCurrentVersion()?.uuid ?? '',
      uuids: uuids,
    },
    context: context,
  });

  const constraintsData = data && data.timetableVersions[0] ? data.timetableVersions[0].classesConnection.edges : [];

  const hasConstraints = constraintsData
    ? constraintsData.filter((c) => !c.properties.constraints).length === 0
    : false;

  return (
    <Modal
      className={styles['constraint-modal']}
      isOpen={pinboardStore.constraintModal.isOpen}
      onRequestClose={() => pinboardStore.setConstraintModalOpen(false)}
      title={t('pinboard.checkConstraints', { title: selectedCard?.subject?.name })}
      parent={document.fullscreenElement ? document.getElementById('timetable-grid') : null}
    >
      <Suspense fallback={<LazyLoader forceHeight='15vh' embedded />}>
        <div className={styles.constraints}>
          {!hasConstraints ? (
            <EmptyState icon={<ControlIcon />} title={t('pinboard.noConstraints')} />
          ) : (
            constraintsData.map((constraint, i) => {
              const classUuid =
                selectedCard?.lessonClasses.find((lessonClass) => {
                  return lessonClass.class.uuid === constraint.node.uuid;
                })?.class.uuid ?? '';

              const title = selectedCard?.classes.find((c) => c.uuid === classUuid)?.name ?? '';

              const constraintsData = JSON.parse(
                constraint.properties.constraints ??
                  JSON.stringify({
                    comment: '',
                  }),
              ) as ConstraintsFormInitialValuesType;

              return <ConstraintModalItem key={i} title={title} constraintsData={constraintsData} />;
            })
          )}
        </div>
      </Suspense>
    </Modal>
  );
});
