import { FunctionComponent, useMemo } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import { FormikHelpers } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { validationSchema } from '../validation/validationSchema';
import { CombinedError, useMutation, useQuery } from 'urql';
import {
  CreateGradeGroupsDocument,
  CreateGradeGroupsMutation,
  CreateGradeGroupsMutationVariables,
  GradeGroupsListDocument,
  GradeGroupsListQuery,
  UpdateGradeGroupsDocument,
  UpdateGradeGroupsMutation,
  UpdateGradeGroupsMutationVariables,
} from '../../../types/planung-graphql-client-defs';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';

type GradeGroupFormProps = {
  gradeGroupUuid: string | null;
  closeForm: () => void;
};

export type GradeGroupFormInitialValues = {
  __typename?: 'GradeGroup' | undefined;
  uuid?: string;
  name: string;
  shortName: string;
  description?: string | null;
};

export const GradeGroupForm: FunctionComponent<GradeGroupFormProps> = ({ closeForm, gradeGroupUuid }) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();

  const context = useMemorizedCacheTag('GRADE_GROUPS');
  const [{ data, error, fetching }] = useQuery<GradeGroupsListQuery>({
    query: GradeGroupsListDocument,
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      uuid: gradeGroupUuid,
    },
    context,
  });

  const [, create] = useMutation<CreateGradeGroupsMutation, CreateGradeGroupsMutationVariables>(
    CreateGradeGroupsDocument,
  );
  const [, update] = useMutation<UpdateGradeGroupsMutation, UpdateGradeGroupsMutationVariables>(
    UpdateGradeGroupsDocument,
  );

  const initialValue: GradeGroupFormInitialValues = useMemo(
    () => ({
      uuid: '',
      name: '',
      shortName: '',
      description: '',
    }),
    [],
  );

  function resultToFormData(): GradeGroupFormInitialValues {
    const currentGradeGroup: GradeGroupFormInitialValues =
      data && data.gradeGroups ? (data.gradeGroups.length > 0 ? data.gradeGroups[0] : initialValue) : initialValue;

    return {
      uuid: currentGradeGroup.uuid,
      name: currentGradeGroup.name,
      shortName: currentGradeGroup.shortName,
      description: currentGradeGroup.description,
    };
  }

  const handleSubmit = async (
    values: GradeGroupFormInitialValues,
    formHelpers: FormikHelpers<GradeGroupFormInitialValues>,
  ) => {
    let mutationResult: { error?: CombinedError | undefined } = { error: error };
    const gradeGroupData = {
      shortName: values.shortName,
      name: values.name,
      description: values.description,
    };

    if (gradeGroupUuid) {
      mutationResult = await update(
        {
          where: {
            uuid: gradeGroupUuid,
          },
          update: gradeGroupData,
        },
        context,
      );
      if (!mutationResult.error) {
        showSuccessUpdateToast();
      }
    }

    if (gradeGroupUuid === null) {
      mutationResult = await create(
        {
          input: [
            {
              ...gradeGroupData,
              organization: {
                connect: {
                  where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } },
                },
              },
            },
          ],
        },
        context,
      );
      if (!mutationResult.error) {
        showSuccessCreateToast();
      }
    }

    if (mutationResult.error) {
      showUserErrorToast({ error: mutationResult.error });
    }

    formHelpers.resetForm();
    closeForm();
  };

  return (
    <Formik initialValues={resultToFormData()} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(formik: FormikProps<GradeGroupFormInitialValues>) => (
        <Form>
          <Grid useFormGap>
            <GridRow spacingBottom='s'>
              <GridColumn width={10}>
                <Input
                  name='name'
                  label={t('common.name')}
                  value={formik.values.name ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  {...(formik.errors.name &&
                    formik.touched.name && {
                      error: formik.errors.name,
                    })}
                />
              </GridColumn>
              <GridColumn width={2}>
                <Input
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  name='shortName'
                  value={formik.values.shortName ?? ''}
                  label={t('common.shortName')}
                  {...(formik.errors.shortName &&
                    formik.touched.shortName && {
                      error: formik.errors.shortName,
                    })}
                />
              </GridColumn>
            </GridRow>
            <GridRow spacingTop='s'>
              <Input
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                name='description'
                value={formik.values.description ?? ''}
                label={t('common.description')}
                {...(formik.errors.description &&
                  formik.touched.description && {
                    error: formik.errors.description,
                  })}
              />
            </GridRow>
          </Grid>
          <ModalBottomButtons
            closeButton={{
              callback: () => {
                formik.resetForm();
                closeForm();
              },
            }}
            submitButton={{ disabled: formik.isSubmitting || !formik.dirty || fetching }}
            isLoading={formik.isSubmitting || formik.isValidating}
            errors={formik.errors}
          />
        </Form>
      )}
    </Formik>
  );
};
