import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { DatePicker, Input } from '@bp/ui-components';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import * as Yup from 'yup';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import {
  useCreatePimProfilesMutation,
  useUpdatePeopleMutation,
  useUpdatePimProfilesMutation,
} from '../../types/planung-graphql-client-defs';
import { UserDataType } from '../Users/UserTable';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../utils/toast';
import { getTypeChecktArray } from '../../utils/typeguards';

type EditProfileFormProps = {
  setModalClosed: (close: boolean) => void;
  data: UserDataType | null;
};

export const EditProfileForm = ({ setModalClosed, data }: EditProfileFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const personsContext = useMemorizedCacheTag('PERSONS');
  const [, updatePerson] = useUpdatePeopleMutation();
  const [, createProfile] = useCreatePimProfilesMutation();
  const [, updateProfile] = useUpdatePimProfilesMutation();

  const formikValues: UserDataType = {
    ...data,
    uuid: data?.uuid ?? null,
    roleName: data?.roleName ?? 'OTHER',
    user: data?.user ?? false,
    fullName: data?.fullName ?? data?.pimFullName ?? '',
    planungUser: data?.planungUser ?? false,
    title: data?.title ?? '',
    birthDate: data?.birthDate ? new Date(data.birthDate).toISOString() : '',
  };

  const connectProfile = async (pimProfileUuid: string) =>
    await updatePerson(
      {
        where: {
          uuid: data?.uuid,
        },
        update: {
          pimProfileUuid: pimProfileUuid,
        },
      },
      { context: personsContext },
    );

  const handleSubmit = async (values: typeof formikValues) => {
    if (data?.pimProfileUuid) {
      const resp = await updateProfile({
        update: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          prefixName: values.prefixName,
          salutation: values.salutation,
          title: values.title,
          birthday: values.birthDate ? new Date(values.birthDate).toISOString() : undefined,
        },
        where: { uuid: data.pimProfileUuid },
      });
      if (!resp.error) {
        showSuccessUpdateToast(getTypeChecktArray<string>([values.displayName]));
      }
      setModalClosed(true);
    }

    if (!data?.pimProfileUuid) {
      const createPimProfileResp = await createProfile({
        input: {
          organization: { connect: { where: { node: { uuid: organizationUuid } } } },
          firstName: values.firstName as string,
          lastName: values.lastName as string,
          email: values.email,
          prefixName: values.prefixName,
          salutation: values.salutation,
          title: values.title,
          birthday: values.birthDate ? new Date(values.birthDate).toISOString() : '',
        },
      });
      const pimProfileUuid = createPimProfileResp.data?.createPimProfiles.pimProfiles[0].uuid;

      if (!createPimProfileResp.error && pimProfileUuid) {
        const resp = await connectProfile(pimProfileUuid);
        if (resp.error) {
          showUserErrorToast({ error: resp.error });
        } else {
          showSuccessCreateToast();
        }
      } else {
        if (createPimProfileResp.error) {
          showUserErrorToast({ error: createPimProfileResp.error });
        } else {
          showSuccessCreateToast();
        }
      }
      setModalClosed(true);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(),
    firstName: Yup.string().required(t('validation.required.firstName')).min(2, t('validation.textTooShort')),
    lastName: Yup.string().required(t('validation.required.lastName')).min(2, t('validation.textTooShort')),
    birthDate: Yup.date().nullable(),
    salutation: Yup.string().nullable(),
    prefixName: Yup.string().nullable(),
    title: Yup.string().nullable(),
  });

  return (
    <Formik<typeof formikValues>
      onSubmit={handleSubmit}
      initialValues={formikValues}
      validationSchema={validationSchema}
    >
      {({ resetForm, isSubmitting, errors, handleChange, setFieldValue, values }) => {
        return (
          <Form>
            <div className='tks__grid'>
              <div className='tks__row'>
                <div className='tks__col col-xs-6'>
                  <Input
                    error={errors.salutation}
                    onChange={handleChange}
                    name={'salutation'}
                    label={t('common.salutation')}
                    value={values.salutation as string}
                    type={'text'}
                  />
                </div>
                <div className='tks__col col-xs-6'>
                  <Input
                    label={t('common.title')}
                    onChange={handleChange}
                    name={'title'}
                    error={errors.title}
                    value={values.title as string}
                    type={'text'}
                  />
                </div>
              </div>
              <div className='tks__row'>
                <div className='tks__col col-xs-5'>
                  <Input
                    error={errors.firstName}
                    required={true}
                    label={t('common.firstName')}
                    onChange={handleChange}
                    name={'firstName'}
                    value={values.firstName}
                    type={'text'}
                  />
                </div>
                <div className='tks__col col-xs-2'>
                  <Input
                    error={errors.prefixName}
                    label={t('common.prefixName')}
                    onChange={handleChange}
                    name={'prefixName'}
                    value={values.prefixName as string}
                    type={'text'}
                  />
                </div>
                <div className='tks__col col-xs-5'>
                  <Input
                    error={errors.lastName}
                    required={true}
                    label={t('common.lastName')}
                    onChange={handleChange}
                    name={'lastName'}
                    value={values.lastName}
                    type={'text'}
                  />
                </div>
              </div>
              <div className='tks__row'>
                <div className='tks__col col-xs-6'>
                  <DatePicker
                    className={'bp__col no-gap'}
                    label={t('persons.birthDate')}
                    onChange={(e) => {
                      setFieldValue('birthDate', e?.toISOString());
                    }}
                    value={values.birthDate ? new Date(values.birthDate) : undefined}
                    name={'birthDate'}
                    error={errors.birthDate}
                    showMonthYearDropdown
                  />
                </div>
                <div className='tks__col col-xs-6'>
                  <Input
                    className={'bp__col no-gap'}
                    error={errors.email}
                    label={t('common.email')}
                    onChange={handleChange}
                    name={'email'}
                    value={values.email as string}
                    type={'email'}
                  />
                </div>
              </div>
            </div>
            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  resetForm();
                  setModalClosed(true);
                },
              }}
              isLoading={isSubmitting}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
