import { useMousePosition } from '../../../hooks/useMousePosition';
import { CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import { PickedCardType } from '../../../stores/PinboardStore';
import { isBadgeCardWidth } from '../../../utils/typeguards';
import { BadgeCard } from '@bp/ui-components';

export const PickedCardView = ({ card }: { card: PickedCardType }) => {
  const { x, y } = useMousePosition({ defaultX: card?.position.x ?? 0, defaultY: card?.position.y ?? 0 });

  const offsetX = -10;
  const offsetY = -10;

  const style: CSSProperties = {
    position: 'fixed',
    zIndex: 10,
    left: x - offsetX,
    top: y - offsetY,
    opacity: 0.75,
  };
  return createPortal(
    <BadgeCard
      style={style}
      count={1}
      color={card.labelColor}
      width={isBadgeCardWidth(card.duration) ? card.duration : 6}
      label={card.label}
      rows={card.cardRows}
      showHover
    />,
    document.fullscreenElement ? (document.querySelector('#timetable-grid') ?? document.body) : document.body,
  );
};
