import { ArrowDownIcon, Card, Dropdown, DropdownMenu, DropdownMenuItem } from '@bp/ui-components';
import classNames from 'classnames';
import styles from './MenuBar.module.scss';
import { FC } from 'react';

export type MenuBarEntry = {
  actions: {
    icon: React.ReactNode;
    title: string;
    action: () => void;
    disabled?: boolean;
    subactions?: Subaction[];
  }[];
};

export type Subaction = {
  title: string | null;
  disabled?: boolean;
  action: (() => void) | null;
  type?: 'ruler' | 'switch';
  value?: boolean;
};

export type MenuBarProps = {
  entries: MenuBarEntry[];
  usePortal?: boolean;
  passive?: boolean;
  className?: string | undefined;
};

export const MenuBar: FC<MenuBarProps> = ({ entries, usePortal, passive, className }) => {
  function subactionsToMenuEntry(subactions: Subaction[]): DropdownMenuItem[] {
    return subactions.map((subaction) => {
      return {
        label: subaction.title ?? undefined,
        disabled: subaction.disabled ?? false,
        onClick: subaction.action ?? undefined,
        type: subaction.type ?? 'default',
        value: subaction.value ?? undefined,
        onValueChange: subaction.action ?? undefined,
      };
    });
  }

  const menuBarClasses = classNames(styles['menu-bar'], className);

  return (
    <div className={menuBarClasses}>
      {entries.length === 0 ? (
        <></>
      ) : (
        entries.map((entry, index) => {
          return entry.actions.length === 0 ? (
            <></>
          ) : (
            <Card
              key={index}
              className={classNames(styles['menu-block'], {
                [styles.single]: entry.actions.length <= 1,
              })}
              wrapperClass={classNames(styles.wrapper, {
                [styles['is-passive']]: passive,
              })}
              wrapperStyle={{
                width: 'fit-content',
                padding: 'var(--spacing-3)',
              }}
              contentPadding='none'
              fixedHeight='var(--card-menu-height)'
            >
              {entry.actions.map((action, index) => {
                return action.subactions && action.subactions.length > 1 ? (
                  // multiaction entry with drop-down
                  <div key={index} className={classNames(styles.entry, { [styles.disabled]: action.disabled })}>
                    <Dropdown
                      usePortal={usePortal}
                      trigger={
                        <div className={styles.title}>
                          {action.icon}
                          {action.title}
                          <div className={styles.indicator}>
                            <ArrowDownIcon />
                          </div>
                        </div>
                      }
                      disabled={action.disabled}
                    >
                      <DropdownMenu usePortal={usePortal} data={subactionsToMenuEntry(action.subactions)} />
                    </Dropdown>
                  </div>
                ) : (
                  // single action entry
                  <div
                    key={index}
                    className={classNames(styles.entry, { [styles.disabled]: action.disabled })}
                    onClick={action.disabled ? undefined : action.action}
                  >
                    <div className={styles.title}>
                      {action.icon}
                      {action.title}
                    </div>
                  </div>
                );
              })}
            </Card>
          );
        })
      )}
    </div>
  );
};
