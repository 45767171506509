import * as Yup from 'yup';
import { ValidationError } from 'yup';
import i18next from 'i18next';
import { SchoolYearListType } from '../SchoolYearForm';
import dayjs from 'dayjs';
import { checkTimeRangeOverlap } from '../../../../utils/checkTimeRangeOverlap';

export const schema = (other: SchoolYearListType[] | null) => {
  return Yup.object()

    .shape({
      name: Yup.string().required(i18next.t('validation.schoolYear.nameRequired')),
      shortName: Yup.string().required(i18next.t('validation.schoolYear.shortNameRequired')),
      start: Yup.date().required(i18next.t('validation.common.startRequired')),
      end: Yup.date().required(i18next.t('validation.common.endRequired')),
      fullTimeHours: Yup.number().nullable().max(2500, i18next.t('validation.common.notUseful')),
    })
    .test('date-range', function (value) {
      return value?.start <= value?.end
        ? true
        : this.createError({
            message: i18next.t('validation.common.startBeforeEnd'),
            path: 'start',
          });
    })
    .test('no-overlapping', function (value) {
      const overlappingResult = checkTimeRangeOverlap(
        { start: dayjs(value.start).toDate(), end: dayjs(value.end).toDate() },
        other?.map((o) => {
          return { start: dayjs(o.start).toDate(), end: dayjs(o.end).toDate() };
        }) ?? [],
      );
      const errors = [];
      if (overlappingResult.overlapping) {
        if (overlappingResult.startOverlaps) {
          errors.push(
            this.createError({
              message: i18next.t('validation.schoolYear.noOverlapping'),
              path: 'end',
            }),
          );
        }
        if (overlappingResult.endOverlaps) {
          errors.push(
            this.createError({
              message: i18next.t('validation.schoolYear.noOverlapping') as string,
              path: 'start',
            }),
          );
        }
      }
      return errors.length ? new ValidationError(errors) : true;
    });
};
