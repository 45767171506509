import { showToast } from '@bp/ui-components';
import { CombinedError } from 'urql';
import { t } from 'i18next';

const autoClose = 2000;

export function showSuccessSaveToast(names?: string[]) {
  showToast(
    t('common.successSaveToastText', { count: names ? names.length : 0, names: names ? names.join(', ') : '' }),
    {
      autoClose,
      type: 'success',
    },
  );
}

export function showSuccessCreateToast(names?: string[]) {
  showToast(
    t('common.successCreateToastText', { count: names ? names.length : 0, names: names ? names.join(', ') : '' }),
    {
      autoClose,
      type: 'success',
    },
  );
}

export function showSuccessDeleteToast(names?: string[]) {
  showToast(
    t('common.successDeleteToastText', { count: names ? names.length : 0, names: names ? names.join(', ') : '' }),
    {
      autoClose,
      type: 'success',
    },
  );
}

export function showSuccessUpdateToast(names?: string[]) {
  showToast(
    t('common.successUpdateToastText', { count: names ? names.length : 0, names: names ? names.join(', ') : '' }),
    {
      autoClose,
      type: 'success',
    },
  );
}

export function showSuccessDuplicateToast(names?: string[]) {
  showToast(
    t('common.successDuplicateToastText', { count: names ? names.length : 0, names: names ? names.join(', ') : '' }),
    {
      autoClose,
      type: 'success',
    },
  );
}

export function showUserErrorToast({ text, error }: { text?: string; error: unknown }) {
  const _text = text ?? t('common.errorToastText');
  showToast(_text, {
    autoClose,
    type: 'error',
  });
}

export function showUserDbErrorToast() {
  showToast(t('common.dbErrorToastText'), {
    autoClose,
    type: 'error',
  });
}

export function showErrorToast(error: CombinedError) {
  showToast(`Error - ${error.message}`, {
    type: 'error',
  });
}
