import { useTranslation } from 'react-i18next';
import SchoolYearTable from '../../../components/SchoolYear/SchoolYearTable';
import Holidays from '../../../components/Holidays/Holidays';
import { Tab } from '@bp/ui-components';
import { Page } from '../../../components/Page/Page';

export const SetupSchoolYears = () => {
  const { t } = useTranslation();

  return (
    <Page title={null}>
      <Tab
        variant={'large'}
        tabs={[
          {
            title: t('schoolYear.title'),
            value: 'schoolYear',
            content: <SchoolYearTable key={'schoolYear'} />,
          },
          {
            title: t('holidays.title'),
            value: 'holidays',
            content: <Holidays key={'holidays'} />,
          },
        ]}
      />
    </Page>
  );
};
