import { observer } from 'mobx-react-lite';
import styles from '../TimetableGrid.module.scss';
import { useTimetableStore } from '../TimetableProvider';

export const GridSpaceCell = observer(() => {
  const pinboardStore = useTimetableStore();

  return (
    <>
      <div
        className={styles['toggle-all']}
        title='Click to select/deselect all'
        onClick={() => pinboardStore.toggleAllRowSelection()}
      ></div>
    </>
  );
});
