import { RouteObject } from 'react-router-dom';
import { t } from 'i18next';
import {
  _PeopleDocument,
  _PeopleQuery,
  _PeopleQueryVariables,
  ClassesListDocument,
  ClassesListQuery,
  ClassesListQueryVariables,
  SubjectsListDocument,
  SubjectsListQuery,
  SubjectsListQueryVariables,
} from '../../../types/planung-graphql-client-defs';
import { urqlClient } from '../../../utils/urqlClient';
import { LessonPlanPage } from './Lesson/LessonPlanPage';
import { AdditionalDeputatePage } from './Deputate/AdditionalDeputatePage';
import { DeputatePlanPage } from './DeputatePlanPage';
import { LessonOverviewPage } from './Lesson/LessonOverviewPage';
import { ClassesTable } from '../../../components/Lessons/Tables/Classes/ClassesTable';
import { SubjectsTable } from '../../../components/Lessons/Tables/Subjects/SubjectsTable';
import { ClassLessonsPage } from './Lesson/ClassLessonsPage';
import { SubjectLessonsPage } from './Lesson/SubjectLessonsPage';
import { TeacherLessonsPage } from './Lesson/TeacherLessonsPage';
import { TeachersPage } from './Lesson/TeachersPage';

export const deputatePlanRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <DeputatePlanPage />,
    },
    {
      path: t('routes.deputate.additional.slug'),
      element: <AdditionalDeputatePage />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.deputate.additional.slug')}`,
          title: t('routes.deputate.additional.title'),
        }),
      },
    },

    {
      path: t('routes.lessons.slug'),
      element: <LessonPlanPage baseSlug={baseSlug} />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.lessons.slug')}`,
          title: t('routes.lessons.title'),
        }),
      },
      children: [
        {
          index: true,
          element: <LessonOverviewPage />,
        },
        {
          path: t('routes.lessons.plan.classes.slug'),
          // TODO: do not use components in routes!
          element: <ClassesTable />,
          handle: {
            crumb: () => {
              return {
                slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.classes.slug')}`,
                title: t('routes.lessons.plan.classes.title'),
              };
            },
          },
        },

        {
          path: t('routes.lessons.plan.subjects.slug'),
          // TODO: do not use components in routes!
          element: <SubjectsTable />,
          handle: {
            crumb: () => {
              return {
                slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.subjects.slug')}`,
                title: t('routes.lessons.plan.subjects.title'),
              };
            },
          },
        },
        {
          path: t('routes.lessons.plan.teachers.slug'),
          element: <TeachersPage />,
          handle: {
            crumb: () => {
              return {
                slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.teachers.slug')}`,
                title: t('routes.lessons.plan.teachers.title'),
              };
            },
          },
        },

        // on row click
        {
          path: `${t('routes.lessons.plan.classes.slug')}/:classUuid`,
          element: <ClassLessonsPage />,
          loader: ({ params }) => {
            return urqlClient
              .query<ClassesListQuery, ClassesListQueryVariables>(ClassesListDocument, { classUuid: params.classUuid })
              .toPromise();
          },
          handle: {
            crumb: ({ data }: { data: ClassesListQuery }) => {
              return [
                {
                  slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.classes.slug')}`,
                  title: t('routes.lessons.plan.classes.title'),
                },
                {
                  slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.classes.slug')}/${
                    data.classes[0].uuid
                  }`,
                  title: data.classes[0].name,
                },
              ];
            },
          },
        },
        {
          path: `${t('routes.lessons.plan.subjects.slug')}/:subjectUuid`,
          element: <SubjectLessonsPage />,
          loader: ({ params }) => {
            return urqlClient
              .query<
                SubjectsListQuery,
                SubjectsListQueryVariables
              >(SubjectsListDocument, { where: { uuid: params.subjectUuid } })
              .toPromise();
          },
          handle: {
            crumb: ({ data }: { data: SubjectsListQuery }) => {
              return [
                {
                  slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.subjects.slug')}`,
                  title: t('routes.lessons.plan.subjects.title'),
                },
                {
                  slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.subjects.slug')}/${
                    data.subjects[0].uuid
                  }`,
                  title: data.subjects[0].name,
                },
              ];
            },
          },
        },
        {
          path: `${t('routes.lessons.plan.teachers.slug')}/:uuid`,
          element: <TeacherLessonsPage />,
          loader: ({ params }) => {
            return urqlClient
              .query<_PeopleQuery, _PeopleQueryVariables>(_PeopleDocument, { where: { uuid: params.uuid } })
              .toPromise();
          },
          handle: {
            crumb: ({ data }: { data?: _PeopleQuery }) => {
              return [
                {
                  slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.teachers.slug')}`,
                  title: t('routes.lessons.plan.teachers.title'),
                },
                {
                  slug: `${baseSlug}/${t('routes.lessons.slug')}/${t('routes.lessons.plan.teachers.slug')}/${
                    data?.people[0].uuid
                  }`,
                  title: data?.people[0].fullName,
                },
              ];
            },
          },
        },
      ],
    },
  ];
};
