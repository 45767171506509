import { CSSProperties, memo, useMemo } from 'react';
import { BadgeCard, BadgeCardRow } from '@bp/ui-components';
import { isBrightColor } from '../../utils/isBrightColor';
import { averageColor } from '../../utils/average-color';
import { CardsInfoLesson } from '../Lessons/Tables/TimetableVersionLessonsTable';

type LessonCardProps = {
  label: string;
  duration?: number | null;
  count: number;
  showHover?: boolean;
  gapSize?: number;
  showRounded?: boolean;
  lessonClasses?: CardsInfoLesson | null;
  teacherColors: string[];
  currentClassUuid?: string;
  isLoading?: boolean;
  style?: CSSProperties;
  className?: string;
};

export const LessonCard = memo(
  ({
    label,
    duration,
    count,
    showHover = true,
    gapSize = 3,
    showRounded = true,
    lessonClasses,
    teacherColors,
    isLoading,
    style,
    className,
  }: LessonCardProps) => {
    const width = useMemo(() => {
      return Math.abs(Math.min(Math.floor(duration ?? 0), 6)) as 1 | 2 | 3 | 4 | 5 | 6;
    }, [duration]);

    const showGap = !!lessonClasses?.usedDivision;

    const badgeCardRows: BadgeCardRow[] = useMemo(() => {
      const usedClassDivisionGroupUuids = lessonClasses ? lessonClasses?.groups.map((g) => g.uuid) : [];
      const division = lessonClasses?.usedDivision;
      const allDivisionGroupsEdges = division ? division.groupsConnection.edges : null;

      const rows: BadgeCardRow[] = [];
      if (allDivisionGroupsEdges && allDivisionGroupsEdges?.length > 0) {
        allDivisionGroupsEdges.forEach((edge) => {
          if (usedClassDivisionGroupUuids?.includes(edge.node.uuid)) {
            rows.push({
              label: edge.node.name,
              rowColor: 'var(--color-grey-light)',
              colColors: teacherColors,
            });
          } else {
            rows.push({
              label: '',
              rowColor: 'var(--color-grey-light)',
              colColors: [],
            });
          }
        });
      } else {
        rows.push({
          label: '',
          rowColor: 'var(--color-grey-light)',
          colColors: teacherColors,
        });
      }
      return rows;
    }, [lessonClasses, teacherColors]);

    const color = teacherColors.length
      ? isBrightColor(averageColor(teacherColors))
        ? 'var(--grid-card-text)'
        : 'var(--grid-card-text-light)'
      : 'var(--grid-card-text-light)';

    return (
      <BadgeCard
        label={label}
        count={count}
        rows={badgeCardRows}
        width={width}
        showHover={showHover}
        showGap={showGap}
        gapSize={gapSize}
        showRounded={showRounded}
        style={style}
        isLoading={isLoading}
        className={className}
        color={color}
      />
    );
  },
);
LessonCard.displayName = 'LessonCard';
