import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Input } from '@bp/ui-components';
import { isConstraintError } from '../../../utils/formHelpers';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { showSuccessSaveToast, showUserErrorToast } from '../../../utils/toast';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { use_TeachingBlocksQuery, use_UpdateTeachingBlocksMutation } from '../../../types/planung-graphql-client-defs';
import { TeachingBlockFormType } from '../graphql';

type TeachingBlockFormProps = {
  onClose: () => void;
  currentSubjectContainerUuid: string;
};
export const TeachingBlockForm = ({ onClose, currentSubjectContainerUuid }: TeachingBlockFormProps) => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [{ data }] = use_TeachingBlocksQuery({
    variables: {
      where: {
        subjectContainer: {
          uuid: currentSubjectContainerUuid,
        },
        schoolYear: {
          uuid: currentSchoolYear?.uuid,
        },
      },
    },
    pause: !currentSubjectContainerUuid,
  });

  const [, update] = use_UpdateTeachingBlocksMutation();

  const current = data?.teachingBlocks[0];

  if (!current) {
    return null;
  }
  const currentDraft = {
    comment: current.comment ?? '',
  };

  const handleSubmit = async (values: TeachingBlockFormType, formHelpers: FormikHelpers<TeachingBlockFormType>) => {
    let mutationResult;
    if (current) {
      mutationResult = await update({
        where: {
          subjectContainer: {
            uuid: currentSubjectContainerUuid,
          },
        },
        update: {
          comment: values.comment,
        },
      });
    }
    if (mutationResult && mutationResult.error) {
      if (isConstraintError(mutationResult.error)) {
        await formHelpers.validateForm(values);
      } else {
        showUserErrorToast({ error: mutationResult.error });
      }
    } else {
      showSuccessSaveToast();
      formHelpers.resetForm();
      onClose();
    }
  };

  return (
    <>
      <Formik initialValues={currentDraft} onSubmit={handleSubmit}>
        {({ values, handleChange, resetForm, isSubmitting, isValidating, dirty, errors, touched }) => {
          return (
            <Form>
              <div className='tks__grid mt-6 mb-8'>
                <div className='tks__row row-xs-10'>
                  <div className='tks__col col-xs'>
                    <Input
                      label={t('common.comment')}
                      name={'comment'}
                      onChange={handleChange}
                      value={values.comment ?? ''}
                      {...(errors.comment &&
                        touched.comment && {
                          error: errors.comment,
                        })}
                    />
                  </div>
                </div>
              </div>

              <ModalBottomButtons
                closeButton={{
                  callback: () => {
                    resetForm();
                    onClose();
                  },
                  text: t('common.cancelChanges'),
                }}
                submitButton={{ disabled: isSubmitting || !dirty || isValidating }}
                isLoading={isSubmitting}
                errors={errors}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
