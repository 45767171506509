import { useTranslation } from 'react-i18next';
import styles from '../../Constraints.module.scss';
import { Button, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { useFormikContext } from 'formik';
import { ConstraintsFormInitialValuesType } from '../../types';
import { useEffect } from 'react';
import { usePriorityOptions } from '../../../../hooks/usePriorityOptions';
import { SingleValue } from 'react-select';
import classNames from 'classnames';

type SameEndConditionItemProps = {
  onRemove: () => void;
};

export const SameEndConstraintItem = ({ onRemove }: SameEndConditionItemProps) => {
  const { t } = useTranslation();
  const { setFieldValue, setFieldTouched, values } = useFormikContext<ConstraintsFormInitialValuesType>();

  const priorityOptions = usePriorityOptions();

  useEffect(() => {
    setFieldValue('sameEnd', true);
    setFieldTouched('sameEnd', true);
    if (!values.sameEndPriority) {
      setFieldTouched('sameEndPriority', true);
      setFieldValue('sameEndPriority', 'important');
    }
  }, []);

  const handleRemove = () => {
    setFieldValue('sameEnd', null);
    setFieldTouched('sameEnd', false);
    setFieldTouched('sameEndPriority', false);
    setFieldValue('sameEndPriority', null);
    onRemove();
  };

  return (
    <>
      <div className={styles['row-wrapper']}>
        <div className='tks__row row-xs-11 mr-5'>
          <div className='tks__col col-xs-12 align-center'>
            <div className={styles['title']}>{t('conditions.sameEnd.label')}</div>
          </div>
        </div>
        <div className='tks__col col-xs-1'>
          <Select
            className={classNames(styles['priority-icon-select'], styles[`icon-${values.sameEndPriority}`])}
            onChange={(value) => {
              const opt = value as SingleValue<SelectOptionType>;
              setFieldTouched('sameEndPriority', true);
              setFieldValue('sameEndPriority', opt?.value);
            }}
            options={priorityOptions}
            value={priorityOptions.find((option) => option.value === values.sameEndPriority)}
            label={t('common.priority.full') as string}
            name={'sameEndPriority'}
            fitMenuToOptions
            showIconOnly
          />
        </div>
        <div className={styles['button-wrapper']}>
          <Button name='remove' hierarchy='secondary' onClick={handleRemove} icon={<DeleteIcon />} />
        </div>
      </div>
    </>
  );
};
