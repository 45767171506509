import styles from './Constraints.module.scss';
import {
  Button,
  ButtonGroup,
  DeleteIcon,
  DuplicateIcon,
  ImportantIcon,
  MandatoryIcon,
  OptimizingIcon,
} from '@bp/ui-components';
import { ConstraintPriorities, ConstraintsItemType } from './types';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useTimetableStore } from '../TimetableGrid/TimetableProvider';

type ConstraintsItemProps = {
  item: ConstraintsItemType;
  onDuplicate: (item: ConstraintsItemType) => void;
  onDelete: (item: ConstraintsItemType) => void;
};

export const ConstraintsItem = observer(({ item, onDuplicate, onDelete }: ConstraintsItemProps) => {
  const store = useTimetableStore();

  function getPriorityIcon(type: ConstraintPriorities | null) {
    return (
      <>
        {type === 'optimizing' && (
          <div title={t('conditions.priority.optimizing')}>
            <OptimizingIcon color='var(--color-success)' />
          </div>
        )}
        {type === 'important' && (
          <div color={'var(--color-warning)'} title={t('conditions.priority.important')}>
            <ImportantIcon color='var(--color-warning)' />
          </div>
        )}
        {type === 'mandatory' && (
          <div color={'var(--color-error)'} title={t('conditions.priority.mandatory')}>
            <MandatoryIcon color='var(--color-error)' />
          </div>
        )}
      </>
    );
  }

  return (
    <div className={styles['condition-item']}>
      <div className={styles['title-wrapper']}>{item.text}</div>
      <div className={styles['actions']}>
        {getPriorityIcon(item.priority)}
        <ButtonGroup>
          <Button
            name='duplicate'
            hierarchy='tertiary'
            disabled={true}
            onClick={() => onDuplicate(item)}
            icon={<DuplicateIcon className='small' />}
          />
          <Button
            name='delete'
            hierarchy='tertiary'
            disabled={store.readonly}
            onClick={() => onDelete(item)}
            icon={<DeleteIcon className='small' />}
          />
        </ButtonGroup>
      </div>
    </div>
  );
});
