import {
  DependencyPriority,
  useDeleteDependenciesMutation,
  useDependenciesQuery,
  useUpdateDependenciesMutation,
} from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  LazyLoader,
  Modal,
  Row,
  Table,
  TableColumns,
  useDefaultSelecting,
} from '@bp/ui-components';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { DependenciesForm } from '../../Dependencies/Form/DependenciesForm';
import { showSuccessDeleteToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { useConfirm } from '../../../hooks/useConfirm';
import { useTimetableStore } from '../../TimetableGrid/TimetableProvider';

type TableData = {
  uuid: string;
  isActive: boolean | null | undefined;
  type: string;
  priority: DependencyPriority;
  comment: string | null | undefined;
  subjects: string[];
  classes: string[];
};

export const TimetableVersionDependenciesTable = observer(({ versionUuid }: { versionUuid: string }) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const [currentDependency, setCurrentDependency] = useState<string | null>(null);

  const { sorting, saveSorting } = useColumnsSort('timetableVersionDependenciesTable');
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);

  const context = useMemorizedCacheTag('DEPENDENCIES');
  const [dependenciesResult] = useDependenciesQuery({
    variables: {
      timetableVersion: versionUuid,
    },
    context,
  });
  const { data } = dependenciesResult;

  const store = useTimetableStore();

  const dependencies: TableData[] = useMemo(() => {
    return (
      data?.dependencies.map((dep) => ({
        uuid: dep.uuid,
        type: dep.alias,
        isActive: dep.active,
        priority: dep.priority,
        comment: dep.comment,
        subjects: dep.subjects.map((s) => s.shortName),
        classes: dep.classes.map((s) => s.shortName),
      })) ?? []
    );
  }, [data]);

  function createColumns(): TableColumns<TableData>[] {
    return [
      {
        header: t('common.priority.full'),
        accessorKey: 'priority',
        accessorFn: (cell) => {
          return t(`conditions.priority.${cell.priority.toLowerCase()}`);
        },
        id: 'priority',
        cell: ({ row }) => {
          return t(`conditions.priority.${row.original.priority.toLowerCase()}`);
        },
      },
      {
        header: t('subject.title.plural'),
        accessorKey: 'subjects',
        id: 'subjects',
        size: 100,
      },
      {
        header: t('classes.title.plural'),
        accessorKey: 'classes',
        id: 'classes',
        size: 100,
      },
      {
        header: t('common.type'),
        accessorFn: (cell) => {
          return t(`dependencies.${cell.type}.label`);
        },
        cell: (cell) => {
          return t(`dependencies.${cell.row.original.type}.label`);
        },
        id: 'type',
        size: 530,
      },
      {
        header: t('common.comment'),
        accessorKey: 'comment',
        id: 'comment',
        size: 130,
      },
      {
        header: t('common.active.short'),
        meta: {
          tooltip: t('common.active.full'),
        },
        accessorKey: 'isActive',
        id: 'isActive',
        type: 'active',
      },
    ];
  }

  const [, deleteDependencies] = useDeleteDependenciesMutation();
  const { confirm, ConfirmationDialog } = useConfirm();
  const handleDelete = async (uuid: string) => {
    const confirmed = await confirm({ message: t('dependencies.deleteConfirm') });
    if (confirmed) {
      const response = await deleteDependencies({ uuid }, context);
      if (response.error) {
        showUserErrorToast({ error: response.error });
      } else {
        showSuccessDeleteToast();
      }
    }
  };

  const [, updateDependency] = useUpdateDependenciesMutation();
  const handleActivate = async (uuid: string) => {
    const response = await updateDependency(
      {
        where: { uuid: uuid },
        update: {
          active: true,
        },
      },
      context,
    );
    if (response.error) {
      showUserErrorToast({ error: response.error });
    } else {
      showSuccessUpdateToast();
    }
  };
  const columns = useMemo(createColumns, [t]);

  const createActionItems = useCallback(
    (row: Row<TableData>): DropdownMenuItem[] => {
      return [
        {
          label: t('common.activate'),
          onClick: () => {
            !row.original.isActive && void handleActivate(row.original.uuid);
          },
          disabled: (store.readonly || row.original.isActive) ?? false,
        },
        {
          label: t('common.delete'),
          type: 'default',
          color: 'error',
          disabled: store.readonly,
          onClick: () => {
            void handleDelete(row.original.uuid);
          },
        },
      ];
    },
    [handleActivate, handleDelete],
  );

  function handleEdit(uuid: string) {
    setCurrentDependency(uuid);
    setAddModalOpen(true);
  }

  function handleAdd() {
    setCurrentDependency(null);
    setAddModalOpen(true);
  }

  const handleClose = () => {
    setCurrentDependency(null);
    setAddModalOpen(false);
  };

  const actionBarSettings = useMemo(() => {
    return {
      showExpertFilter: true,
      showAddButton: !store.readonly,
      showPrintButton: true,
      showBulkEdit: false,
      marginBottom: 'var(--spacing-3)',
    };
  }, []);
  return (
    <>
      <Table<TableData>
        showBorderRadius
        showShadow
        canScroll
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        data={dependencies}
        columns={columns}
        sorting={sorting}
        onSortingChange={saveSorting}
        onAddClick={handleAdd}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('dependencies.title.plural')} - ${t('common.schoolYear')} ${schoolYear?.shortName}`,
          filename: `${t('dependencies.title.plural')}_${schoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={actionBarSettings}
        breakpoint={null}
        showVisibility
        isOnWhite={false}
        lastCol={(row) => {
          return (
            <ButtonGroup disabled={store.readonly}>
              <Button
                disabled={store.readonly}
                hierarchy='tertiary'
                icon={<EditIcon className='small' />}
                onClick={() => handleEdit(row.original.uuid)}
              />
              <Dropdown
                disabled={store.readonly}
                noPadding
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu data={createActionItems(row)} />
              </Dropdown>
            </ButtonGroup>
          );
        }}
      />

      <Modal
        isOpen={addModalOpen}
        onRequestClose={handleClose}
        title={currentDependency ? t('dependencies.edit') : t('dependencies.add')}
      >
        {addModalOpen && versionUuid && (
          <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
            <DependenciesForm
              handleClose={handleClose}
              versionUuid={versionUuid}
              currentDependency={data?.dependencies.find((dep) => dep.uuid === currentDependency)}
            />
          </Suspense>
        )}
      </Modal>
      <ConfirmationDialog />
    </>
  );
});
