import styles from './TeachingBlockVersionTopBar.module.scss';
import { ArrowLeftIcon, Button, Tab, TabItemProps } from '@bp/ui-components';
import { useMatches, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { use_TeachingBlocksQuery, use_TeachingBlockVersionsQuery } from '../../../types/planung-graphql-client-defs';
import { addLeadingZero } from '../../../utils/helper';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';

const TeachingBlockVersionTopBar = ({ versionUuid, baseSlug }: { versionUuid: string; baseSlug: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const matches = useMatches();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const active = matches.find((match) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Boolean(match.data?.activeTimetableVersionTab); // How to overwrite handle ? see src/types/react-router.d.ts
  })?.data.activeTimetableVersionTab;

  const tabs: TabItemProps[] = [
    {
      title: t('teachingBlockVersion.teachingBlockData'),
      value: 'data',
      content: '',
    },
    {
      title: t('pinboard.title'),
      value: 'board',
      content: '',
    },
  ];

  const [{ data: versionResult }] = use_TeachingBlockVersionsQuery({
    variables: {
      where: {
        uuid: versionUuid ?? '',
      },
    },
  });

  const [{ data: teachingBlocksData }] = use_TeachingBlocksQuery({
    variables: {
      where: {
        versions_SOME: {
          uuid: versionUuid ?? '',
        },
      },
    },
    pause: !versionUuid,
  });

  const currentTeachingBlock = teachingBlocksData?.teachingBlocks[0];
  const { subjectContainerData } = useLoadBasicData({ pause: !teachingBlocksData });

  const currentSubjectContainer = subjectContainerData?.subjectContainers.find((sc) => {
    return currentTeachingBlock?.subjectContainer.uuid === sc.uuid;
  });

  return (
    <div className={styles['teaching-block-version-top-bar']}>
      <div className={styles.header}>
        <Button
          className={styles.back}
          icon={<ArrowLeftIcon />}
          hierarchy='tertiary'
          onClick={() => navigate('/' + t('routes.timetable.slug') + '/' + t('routes.plan.slug'), { replace: true })}
        />
        <div className={styles.headline}>
          {currentSubjectContainer?.name} - v{addLeadingZero(versionResult?.teachingBlockVersions[0].version ?? '')}
        </div>
        <div className={styles.description}>
          {currentSubjectContainer?.grades && currentSubjectContainer?.grades.length > 0
            ? t('common.grades') + ': ' + currentSubjectContainer?.grades.join(', ')
            : t('common.all') + ' ' + t('common.grades')}
        </div>
      </div>
      <Tab
        defaultValue={active}
        className={styles['tab-bar']}
        tabs={tabs}
        onValueChange={(type) => {
          if (type === 'data') {
            navigate(
              `/${baseSlug}/${t('routes.teachingBlockVersions.slug')}/${versionUuid}/${t('routes.versionPages.data.slug')}/${t(
                'routes.versionsFilter.class.slug',
              )}`,
              { replace: true },
            );
          } else {
            navigate(
              `/${baseSlug}/${t('routes.teachingBlockVersions.slug')}/${versionUuid}/${t('routes.versionPages.board.slug')}/`,
              { replace: true },
            );
          }
        }}
      />
    </div>
  );
};

export default observer(TeachingBlockVersionTopBar);
