import {
  DashboardIcon,
  DeputateIcon,
  LockIcon,
  ProfileIcon,
  SettingsIcon,
  Sidebar as BaseSidebar,
  SidebarContentProps,
  SidebarItemProps,
  SubstitutionsIcon,
  TimetableIcon,
} from '@bp/ui-components';
import { useLocation } from 'react-router-dom';
import { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type SidebarProps = {
  isFixed: boolean;
  setIsFixed: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};
export const Sidebar = memo(({ isFixed, setIsFixed }: SidebarProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [expandedSidebar, setExpandedSidebar] = useState(true);
  const sidebarContent: SidebarContentProps[] = useRef([
    {
      items: [
        {
          icon: <DashboardIcon />,
          link: '',
          title: 'Dashboard',
        },
        {
          icon: <DeputateIcon />,
          link: t(`routes.deputate.slug`),
          title: t(`routes.deputate.title`),
          sublist: [
            {
              title: t(`timetable.setup`),
              link: t(`routes.setup.slug`),
            },
            {
              title: t(`timetable.plan`),
              link: t(`routes.plan.slug`),
            },
            {
              title: t(`timetable.evaluate`),
              link: t(`routes.evaluate.slug`),
            },
          ],
        },
        {
          icon: <TimetableIcon />,
          link: t(`routes.timetable.slug`),
          title: t(`timetable.title.plural`),
          sublist: [
            {
              title: t(`timetable.setup`),
              link: t(`routes.setup.slug`),
            },
            {
              title: t(`timetable.plan`),
              link: t(`routes.plan.slug`),
            },
            {
              title: t(`timetable.evaluate`),
              link: t(`routes.evaluate.slug`),
            },
          ],
        },
        {
          icon: <SubstitutionsIcon />,
          link: t(`routes.substitutions.slug`),
          title: t(`substitutions.title.plural`),
          sublist: [
            {
              title: t(`substitutions.setup`),
              link: t(`routes.setup.slug`),
            },
            {
              title: t(`substitutions.plan`),
              link: t(`routes.plan.slug`),
            },
            {
              title: t(`substitutions.evaluate`),
              link: t(`routes.evaluate.slug`),
            },
          ],
        },
      ],
    },
  ]).current as SidebarContentProps[];

  const sidebarFooterItems = useRef([
    {
      icon: <SettingsIcon />,
      link: t(`routes.settings.slug`),
      title: t(`settings.title`),
    },
    {
      icon: <LockIcon />,
      link: t(`routes.admin.slug`),
      title: t(`routes.admin.title`),
    },
    {
      icon: <ProfileIcon />,
      link: t(`routes.profile.slug`),
      title: t(`profile.title.singular`),
    },
  ]).current as SidebarItemProps[];

  return (
    <BaseSidebar
      sidebarContent={sidebarContent}
      sidebarFooterItems={sidebarFooterItems}
      isExpanded={expandedSidebar}
      setIsExpanded={setExpandedSidebar}
      isFixed={isFixed}
      setIsFixed={setIsFixed}
      logoType={'planung'}
      currentLocationPath={location.pathname}
    />
  );
});

Sidebar.displayName = 'Sidebar';
