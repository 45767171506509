import { Button, CloseIcon, Modal } from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { CardForm } from '../CardForm/CardForm';
import { useTranslation } from 'react-i18next';
import styles from './CardEditModal.module.scss';
import { PinboardCard } from '../../PinboardCard/PinboardCard';
import { useTimetableStore } from '../TimetableProvider';

export const CardEditModal = observer(() => {
  const { t } = useTranslation();

  const { cardEditModal, setCardEditModal } = useTimetableStore();

  function onClose() {
    setCardEditModal({ isOpen: false });
  }
  const store = useTimetableStore();
  const card = store.getCardToShowOnInfo();
  const { isOpen } = cardEditModal;

  const Header = (
    <div className={styles['custom-header']}>
      <div className={styles.headline}>{t('pinboard.actions.edit')}</div>
      {card && <PinboardCard card={card} passive />}
      <Button className={styles.close} icon={<CloseIcon />} hierarchy='tertiary' onClick={onClose} />
    </div>
  );

  return (
    <Modal
      parent={document.fullscreenElement ? document.getElementById('timetable-grid') : null}
      isOpen={isOpen ?? false}
      onRequestClose={onClose}
      customHeader={Header}
      className={styles['card-edit-modal']}
      width='s'
    >
      {card && <CardForm card={card} onClose={onClose} />}
    </Modal>
  );
});
