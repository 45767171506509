import {
  LessonCreateInput,
  LessonsDocument,
  LessonsQuery,
  LessonsQueryVariables,
  LessonTimetableCardsCreateFieldInput,
  SortDirection,
  use_SubjectContainersQuery,
  use_SubjectsQuery,
  use_TimetableCardsQuery,
  useCreateLessonsMutation,
  useDeleteCardsMutation,
  useDeleteLessonsMutation,
  useLessonsWithoutCardsQuery,
  useUpdateLessonsMutation,
} from '../../../types/planung-graphql-client-defs';
import { LessonFormType, VersionLessonFormType } from '../types';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useCallback, useReducer } from 'react';
import { groupByArray } from '../../../utils/arrayFunc';
import { hasDurationCountArray, hasLessonUnit } from '../../../utils/typeguards';
import { loadingReducer, loadingReducerInitialState } from '../../../reducer/loadingReducer';
import { urqlClient } from '../../../utils/urqlClient';
import { LessonTableType } from '../Tables/TimetableVersionLessonsTable';
import { lessonResponseToTableType } from '../utils/lessonResponseToTableType';

type VersionLessonFormWithCardsType =
  | (VersionLessonFormType & { timetableCards?: LessonTimetableCardsCreateFieldInput[] | null })
  | (LessonFormType & { timetableCards?: LessonTimetableCardsCreateFieldInput[] | null });

export function useTimetableLessons({
  organizationUuid,
  schoolYearUuid,
  versionUuid,
}: {
  organizationUuid: string;
  schoolYearUuid: string;
  versionUuid: string;
}) {
  const [loadingState, dispatch] = useReducer(loadingReducer, loadingReducerInitialState);

  const context = useMemorizedCacheTag('LESSONS');
  const cardContext = useMemorizedCacheTag('TIMETABLE_CARD');

  const [, create] = useCreateLessonsMutation();
  const [, update] = useUpdateLessonsMutation();
  const [, remove] = useDeleteLessonsMutation();
  const [, deleteCards] = useDeleteCardsMutation();

  const [{ data: queryLessonsData }] = useLessonsWithoutCardsQuery({
    variables: {
      lessonWhere: {
        versions_SOME: {
          uuid: versionUuid,
        },
        organization: {
          uuid: organizationUuid,
        },
        schoolYear: {
          uuid: schoolYearUuid,
        },
      },
      organizationUuid: organizationUuid,
      options: {
        sort: [
          { classSort: SortDirection.Asc },
          { subjectName: SortDirection.Asc },
          { teachersSort: SortDirection.Asc },
        ],
      },
    },
    context,
  });

  const [{ data: timetableCardsData }] = use_TimetableCardsQuery({
    variables: { where: { timetableVersion: { uuid: versionUuid } } },
    context: cardContext,
    pause: !versionUuid || versionUuid === '',
  });

  const timetableCards = timetableCardsData?.timetableCards;

  const [{ data: subjectsData }] = use_SubjectsQuery({
    variables: { where: { uuid_IN: timetableCards?.map((tbc) => tbc.subject.uuid) } },
    pause: !timetableCards,
  });

  const [{ data: subjectContainerData }] = use_SubjectContainersQuery({
    variables: { where: { uuid_IN: timetableCards?.map((tbc) => tbc.subject.uuid) } },
    pause: !timetableCards,
  });
  const lessonsData: LessonTableType[] = lessonResponseToTableType({
    queryLessonsData,
    versionContext: true,
    isTeachingBlock: false,
    timetableCards: timetableCards,
    subjectsData: subjectsData?.subjects,
    subjectContainerData: subjectContainerData?.subjectContainers,
  });

  const getBasics = (values: LessonFormType) => {
    return {
      subTitle: values.subTitle,
      comment: values.comment,
      teachingLoadEnabled: values.teachingLoadEnabled ?? false,
      timetableEnabled: values.timetableEnabled ?? false,
      elective: values.elective ?? false,
      isClassTrip: values.isClassTrip ?? false,
      onlyInTimetableVersion: true,
    };
  };

  const createLesson = async (values: VersionLessonFormWithCardsType) => {
    const input: LessonCreateInput = {
      organization: { connect: { where: { node: { uuid: organizationUuid } } } },
      ...getBasics(values),
      schoolYear: {
        connect: {
          where: {
            node: {
              uuid: schoolYearUuid,
            },
          },
        },
      },
      subject: {
        Subject: {
          connect: {
            where: {
              node: {
                uuid: values.subject?.uuid,
              },
            },
          },
        },
      },
      lessonClasses: {
        create: values.lessonClasses?.map((lessonClass, index) => ({
          edge: {
            order: index,
          },
          node: {
            class: {
              connect: {
                where: {
                  node: {
                    uuid: lessonClass.class.uuid,
                  },
                },
              },
            },
            ...(lessonClass.groups &&
              lessonClass.groups.length > 0 && {
                groups: {
                  connect: lessonClass.groups.map((group) => ({ where: { node: { uuid: group.uuid } } })),
                },
              }),
          },
        })),
      },
      teachers: {
        connect: values.teachers?.map((teacher, index) => {
          return {
            where: {
              node: { uuid: teacher.person?.uuid },
            },
            edge: {
              order: index,
              description: teacher.description,
              present: teacher.present,
              teachingLoad: teacher.teachingLoad,
              teachingLoadHours: teacher.teachingLoadHours,
              writesCertificate: teacher.writesCertificate,
            },
          };
        }),
      },
      roomSupply: {
        connect: values.roomSupply?.map((room, index) => {
          return {
            where: {
              node: { uuid: room.uuid },
            },
            edge: {
              order: index,
              descriptions: room.description,
            },
          };
        }),
      },
      versions: {
        connect: [
          {
            where: {
              node: { uuid: versionUuid },
            },
          },
        ],
      },
    };

    if (hasDurationCountArray(values)) {
      const groups = groupByArray(values.durationCountArray, (v) => v.duration);
      const timetableCardsCreate: LessonTimetableCardsCreateFieldInput[] = [];

      groups.forEach((group) => {
        group.forEach((durationCount) => {
          const count = durationCount.count ? Array.from(Array(Math.abs(durationCount.count)), (x, i) => i) : [];
          count.forEach(() => {
            timetableCardsCreate.push({
              node: {
                timetableVersion: { connect: { where: { node: { uuid: versionUuid } } } },
                duration: durationCount.duration ?? 1,
                locked: false,
              },
            });
          });
        });
      });

      input.timetableCards = {
        create: timetableCardsCreate,
      };
    } else if (hasLessonUnit(values)) {
      const timetableCardsCreate: LessonTimetableCardsCreateFieldInput[] = [];
      values.lessonUnit.forEach((lessonUnit) => {
        const count = lessonUnit.count ? Array.from(Array(Math.abs(lessonUnit.count)), (x, i) => i) : [];
        count.forEach(() => {
          timetableCardsCreate.push({
            node: {
              timetableVersion: { connect: { where: { node: { uuid: versionUuid } } } },
              duration: lessonUnit.duration ?? 1,
              locked: false,
            },
          });
        });
      });
      input.timetableCards = {
        create: timetableCardsCreate,
      };
    }

    if (values.timetableCards) {
      input.timetableCards = {
        create: values.timetableCards,
      };
    }

    return await create({ input: input }, { additionalTypenames: ['Lesson', 'TimetableCard', 'ClassGridCard'] });
  };

  /*
    moveCardsToNewLesson is only for import to version
   */
  const duplicateLesson = async (uuids: string[], versionLessons: boolean) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    let result: { error?: unknown; success?: boolean } = {};

    const currentData = queryLessonsData?.lessons.find((lesson) => lesson.uuid === uuids[0]);

    if (currentData) {
      const cards = timetableCards?.filter((card) => card.lesson.uuid === currentData.uuid);

      const createData: VersionLessonFormWithCardsType = {
        ...currentData,
        uuid: undefined,
        lessonUnit: undefined,
        lessonClasses: currentData.lessonClassesConnection.edges.map(({ node }) => {
          return {
            class: {
              uuid: node.class.uuid,
              name: node.class.name,
              color: node.class.timetableConfig?.color,
            },
            uuid: node.uuid,
            groups: node.groups,
          };
        }),
        roomSupply: currentData.roomSupplyConnection.edges.map((edge) => ({
          uuid: edge.node.uuid,
          description: edge.properties.descriptions ?? '',
        })),
        teachers: currentData.teachersConnection.edges.map((teacherEdge) => {
          return {
            present: teacherEdge.properties.present,
            teachingLoad: teacherEdge.properties.teachingLoad,
            teachingLoadHours: teacherEdge.properties.teachingLoadHours,
            description: teacherEdge.properties.description,
            writesCertificate: teacherEdge.properties.writesCertificate,
            person: {
              uuid: teacherEdge.node.uuid,
              fullName: teacherEdge.node.fullName,
              displayNameShort: teacherEdge.node.displayNameShort ?? teacherEdge.node.fullName,
              color: teacherEdge.node.timetableConfig?.color,
            },
          };
        }),
        timetableCards: cards?.map((card) => {
          return {
            node: {
              timetableVersion: { connect: { where: { node: { uuid: versionUuid } } } },
              duration: card.duration ?? 1,
              locked: false,
            },
          };
        }),
      };

      const createResult = await createLesson(createData);
      if (versionLessons) {
        result = await deactivateLessons(uuids);
      } else {
        result = createResult;
      }
    }
    if (versionUuid) {
      //   await pinboardStore.loadData(versionUuid); TODO
    }
    dispatch({ type: 'RESET' });
    return result;
  };

  const detachLessons = useCallback(
    async (uuids: string[]) => {
      dispatch({ type: 'SET_LOADING', uuids: uuids });

      const result = await update({ where: { uuid_IN: uuids }, update: { teachers: [{ disconnect: [{}] }] } }, context);
      if (versionUuid) {
        // await pinboardStore.loadData(versionUuid); TODO
      }
      dispatch({ type: 'RESET' });
      return result;
    },
    [context, update, versionUuid],
  );

  const assignLessons = useCallback(
    async (uuids: string[], teachers: { uuid: string }[]) => {
      dispatch({ type: 'SET_LOADING', uuids: uuids });

      const result = await update(
        {
          where: { uuid_IN: uuids },
          update: {
            teachers: [
              {
                connect: teachers?.map((teacher, index) => {
                  return {
                    where: {
                      node: { uuid: teacher.uuid },
                    },
                    edge: {
                      order: index,
                    },
                  };
                }),
              },
            ],
          },
        },
        context,
      );
      if (versionUuid) {
        // await pinboardStore.loadData(versionUuid); TODO
      }
      dispatch({ type: 'RESET' });
      return result;
    },
    [context, update, versionUuid],
  );

  const updateLesson = async (values: LessonFormType) => {
    dispatch({ type: 'SET_LOADING', uuids: [values.uuid ?? 'all'] });

    const result = await update(
      {
        where: { uuid: values.uuid },
        update: {
          ...getBasics(values),
          schoolYear: {
            disconnect: {},
            connect: {
              where: {
                node: {
                  uuid: schoolYearUuid,
                },
              },
            },
          },
          subject: {
            Subject: {
              disconnect: {},
              connect: {
                where: {
                  node: {
                    uuid: values.subject?.uuid,
                  },
                },
              },
            },
          },
          lessonClasses: [
            {
              delete: [{}],
              create: values.lessonClasses?.map((lessonClass, index) => ({
                edge: {
                  order: index,
                },
                node: {
                  class: {
                    connect: {
                      where: {
                        node: {
                          uuid: lessonClass.class.uuid,
                        },
                      },
                    },
                  },
                  ...(lessonClass.groups &&
                    lessonClass.groups.length > 0 && {
                      groups: {
                        connect: lessonClass.groups.map((group) => ({ where: { node: { uuid: group.uuid } } })),
                      },
                    }),
                },
              })),
            },
          ],
          lessonUnit: [
            {
              delete: [{}],
              create: values.lessonUnit?.map((lessonUnit, index) => {
                return {
                  edge: { order: index },
                  node: {
                    count: lessonUnit.count,
                    duration: lessonUnit.duration,
                    ...(lessonUnit.subjectContainerUuid && {
                      subjectContainer: {
                        connect: {
                          where: {
                            node: {
                              uuid: lessonUnit.subjectContainerUuid,
                            },
                          },
                        },
                      },
                    }),
                  },
                };
              }),
            },
          ],
          teachers: [
            {
              disconnect: [{}],
              connect: values.teachers?.map((teacher, index) => {
                return {
                  where: {
                    node: { uuid: teacher.person?.uuid },
                  },
                  edge: {
                    order: index,
                    description: teacher.description,
                    present: teacher.present,
                    teachingLoad: teacher.teachingLoad,
                    teachingLoadHours: teacher.teachingLoadHours,
                    writesCertificate: teacher.writesCertificate,
                  },
                };
              }),
            },
          ],
          roomSupply: [
            {
              disconnect: [{}],
              connect: values.roomSupply?.map((room, index) => {
                return {
                  where: {
                    node: { uuid: room.uuid },
                  },
                  edge: {
                    order: index,
                    descriptions: room.description,
                  },
                };
              }),
            },
          ],
        },
      },
      context,
    );
    dispatch({ type: 'RESET' });
    return result;
  };

  const deleteLessons = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });

    let result: { error?: unknown; success?: boolean };

    if (versionUuid) {
      result = await remove({ where: { uuid_IN: uuids } }, context);
    } else {
      result = { error: { message: 'version not found', name: 'version not found' }, success: false };
    }
    if (versionUuid) {
      //    await pinboardStore.loadData(versionUuid);TODO
    }
    dispatch({ type: 'RESET' });

    return result;
  };

  const deleteCardsAndLessons = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    let result: { error?: unknown; success?: boolean };
    // TODO delete if lesson has no cards
    if (versionUuid) {
      result = await deleteCards(
        {
          where: { timetableVersion: { uuid: versionUuid }, lesson: { uuid_IN: uuids } },
          delete: { lesson: { where: { node: { uuid_IN: uuids } } } },
        },
        context,
      );
    } else {
      result = { error: { message: 'version not found', name: 'version not found' }, success: false };
    }
    if (versionUuid) {
      //   await pinboardStore.loadData(versionUuid);TODO
    }
    dispatch({ type: 'RESET' });
    return result;
  };

  const deactivateLessons = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    let result: { error?: unknown; success?: boolean };
    if (versionUuid) {
      const resCards = await deleteCards(
        { where: { timetableVersion: { uuid: versionUuid }, lesson: { uuid_IN: uuids } } },
        cardContext,
      );
      const resLesson = await update(
        {
          where: { uuid_IN: uuids },
          update: {
            versions: [
              {
                disconnect: [
                  {
                    where: { node: { uuid: versionUuid } },
                  },
                ],
              },
            ],
          },
        },
        context,
      );
      const hasError = [resCards, resLesson].some((res) => res.error);
      result = hasError
        ? { error: [resCards, resLesson].find((res) => res.error)?.error, success: false }
        : { success: true };
    } else {
      result = { error: { message: 'version not found', name: 'version not found' }, success: false };
    }
    dispatch({ type: 'RESET' });
    return result;
  };

  const updateCheckboxes = async ({
    type,
    uuids,
    value,
  }: {
    type: 'teachingLoadEnabled' | 'timetableEnabled' | 'elective' | 'isClassTrip';
    uuids: string[];
    value: boolean;
  }) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    const res = await update({ where: { uuid_IN: uuids }, update: { [type]: value } }, context);
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return res;
  };

  const addLessonsToVersion = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    let result: { error?: unknown; success?: boolean } = {};
    const { data: lessonData } = await urqlClient
      .query<LessonsQuery, LessonsQueryVariables>(
        LessonsDocument,
        {
          lessonWhere: { uuid_IN: uuids },
          organizationUuid: organizationUuid,
          timetableCardsWhere: { timetableVersion: { uuid_IN: uuids } },
        },
        context,
      )
      .toPromise();

    if (versionUuid && uuids) {
      for (const lesson of lessonData?.lessons.filter((l) => uuids.includes(l.uuid)) ?? []) {
        const cards: LessonTimetableCardsCreateFieldInput[] = [];
        lesson.lessonUnitConnection.edges
          .filter(({ node }) => {
            return node.subjectContainer === null;
          })
          .forEach(({ node }) => {
            const count = node.count ? Array.from(Array(Math.abs(node.count)), (x, i) => i) : [];
            count.forEach(() => {
              cards.push({
                node: {
                  duration: node.duration,
                  locked: false,
                  timetableVersion: {
                    connect: { where: { node: { uuid: versionUuid } } },
                  },
                },
              });
            });
          });

        result = await update(
          {
            where: { uuid: lesson.uuid },
            update: {
              versions: [{ connect: [{ where: { node: { uuid: versionUuid } } }] }],
              timetableCards: [{ create: cards }],
            },
          },
          { additionalTypenames: ['Lesson', 'TimetableCard', 'ClassGridCard'] },
        );
      }
    } else {
      result = { error: { message: 'version not found', name: 'version not found' }, success: false };
    }
    dispatch({ type: 'RESET' });
    return result;
  };

  return {
    createLesson,
    updateLesson,
    detachLessons,
    assignLessons,
    deleteLessons,
    deleteCardsAndLessons,
    updateCheckboxes,
    deactivateLessons,
    addLessonsToVersion,
    duplicateLesson,
    loadingState,
    dispatch,
    lessonContext: context,
    queryLessonsData,
    lessonsData,
  };
}
