import { Button, CloseIcon, Modal } from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { CardDurationForm } from '../../Forms/CardDurationForm/CardDurationForm';
import { useTranslation } from 'react-i18next';
import { useTeachingBlockStore } from '../../TeachingBlockContext';
import dayjs from 'dayjs';
import styles from './CardDurationModal.module.scss';
import { TeachingBlockCard } from '../../TeachingBlockCard/TeachingBlockCard';

export const CardDurationModal = observer(() => {
  const { t } = useTranslation();

  const { cardDurationModal, setCardDurationModal } = useTeachingBlockStore();

  const { isOpen, card, isVirtual, previousHoliday, nextHoliday, previousCard, nextCard } = cardDurationModal;

  const Header = (
    <div className={styles['custom-header']}>
      <div className={styles.headline}>{t('pinboard.actions.editTime')}</div>
      {card && <TeachingBlockCard card={card} passive />}
      <Button className={styles.close} icon={<CloseIcon />} hierarchy='tertiary' onClick={onClose} />
    </div>
  );

  const previous =
    previousCard && previousHoliday
      ? dayjs(previousCard?.endDate).isAfter(previousHoliday?.end, 'day')
        ? previousCard
        : previousHoliday
      : previousCard
        ? previousCard
        : (previousHoliday ?? null);

  const next =
    nextCard && nextHoliday
      ? dayjs(nextCard?.startDate).isBefore(nextHoliday?.start, 'day')
        ? nextCard
        : nextHoliday
      : nextCard
        ? nextCard
        : (nextHoliday ?? null);

  function onClose() {
    setCardDurationModal({ isOpen: false });
  }

  return (
    <Modal
      parent={document.fullscreenElement ? document.getElementById('teaching-block-grid') : null}
      isOpen={isOpen ?? false}
      onRequestClose={onClose}
      customHeader={Header}
      className={styles['card-edit-modal']}
    >
      {card && (
        <CardDurationForm
          card={card}
          isVirtual={isVirtual ?? false}
          previous={previous}
          next={next}
          onClose={onClose}
        />
      )}
    </Modal>
  );
});
