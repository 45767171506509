import * as Yup from 'yup';

export const iphisSchema = Yup.object().shape({
  dbHost: Yup.string(),
  dbPort: Yup.string(),
  dbUser: Yup.string(),
  dbPass: Yup.string(),
  dbName: Yup.string(),
  schema: Yup.string(),
});
