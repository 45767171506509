import { FieldArray, useFormikContext } from 'formik';
import { AddIcon, Button, DeleteIcon } from '@bp/ui-components';
import { v4 } from 'uuid';
import { CurriculaTableDataType, CurriculumClassType } from '../types';
import { CurriculumClassesFormItem } from './Classes/CurriculumClassesFormItem';
import { useTranslation } from 'react-i18next';

export type CurriculumGradesFormProps = {
  enableGroups: boolean;
  selectableGrades: number[];
};
export const CurriculumGradesForm = ({ enableGroups, selectableGrades }: CurriculumGradesFormProps) => {
  // Curriculum -> curriculumGrades contain CurriculumClasses
  const formik = useFormikContext<CurriculaTableDataType>();
  const { t } = useTranslation();

  return (
    <FieldArray name={'curriculumGrades'}>
      {(arrayHelpers) => {
        return (
          <>
            <div className='form-group tks__grid'>
              {formik.values.curriculumGrades?.map((curriculumClass, index) => {
                return (
                  <div key={curriculumClass.uuid} className='tks__row'>
                    <div className='tks__col col-xs-9'>
                      <CurriculumClassesFormItem
                        index={index}
                        multiEnabled={(formik.values.curriculumGrades ?? []).length < 2}
                        curriculumClass={(formik.values.curriculumGrades ?? [])[index]}
                        disabled={false}
                        enableGroups={enableGroups}
                        selectableGrades={selectableGrades}
                      />
                    </div>
                    <div className='tks__col col-xs-3'>
                      <Button
                        className='mt-6 align-end'
                        hierarchy='tertiary'
                        icon={
                          <DeleteIcon
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                          />
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <Button
              hierarchy={'tertiary'}
              icon={<AddIcon />}
              disabled={
                formik.values.curriculumGrades &&
                !formik.values.curriculumGrades?.every(
                  (cg) =>
                    !!cg.grades?.length &&
                    (cg.grades?.length ?? 0) < 2 &&
                    (cg.gradeGroups?.length ?? 0) < 2 &&
                    (cg.groups?.length ?? 0) < 2,
                )
              }
              onClick={async () => {
                const curriculumClass: CurriculumClassType = {
                  uuid: v4(),
                  grades: [],
                };
                arrayHelpers.push(curriculumClass);
              }}
            >
              {t('classes.createClass')}
            </Button>
          </>
        );
      }}
    </FieldArray>
  );
};
