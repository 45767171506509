import { Checkbox, Input, Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useEffect, useState } from 'react';
import { TimetableVersion } from '../TimetableVersionsOverview/TimetableVersionsOverview';

type TimetableVersionModalFormProps = {
  editVersion?: TimetableVersion | null;
  onSave: (editVersion: Partial<TimetableVersion>) => void;
  onClose: () => void;
  loading: boolean;
  timeTableIsActive: boolean;
};

export const TimetableVersionModalForm = ({
  editVersion,
  onSave,
  onClose,
  loading,
  timeTableIsActive,
}: TimetableVersionModalFormProps) => {
  const { t } = useTranslation();

  const [description, setDescription] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    setDescription(editVersion?.description ?? '');
    setActive(editVersion?.active ?? false);
  }, [editVersion]);

  return (
    <Modal
      title={t('timetableVersion.edit', { version: editVersion?.version })}
      width='s'
      isOpen={!!editVersion}
      onRequestClose={() => onClose()}
      style={{ overlay: { zIndex: 100 } }}
    >
      <div className='tks__grid no-wrap'>
        <div className='tks__col col-xs-10'>
          <Input
            label={t('common.name')}
            value={description}
            onChange={(value) => setDescription(value.target.value)}
            name='name'
          />
        </div>
        <div className='tks__col col-xs-2 row align-center bottom-xs'>
          <Checkbox
            label={t('common.active.full')}
            name='isActive'
            checked={active}
            disabled={timeTableIsActive}
            onChange={(value) => setActive(value.target.checked)}
          />
        </div>
      </div>
      <ModalBottomButtons
        closeButton={{ callback: () => onClose() }}
        submitButton={{
          callback: () => onSave({ ...editVersion, active, description }),
          disabled: !editVersion?.uuid,
        }}
        isLoading={loading}
        errors={undefined}
      />
    </Modal>
  );
};
