import { useAuthClaims } from '../../hooks/useAuthClaims';
import IconMenu from '../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../components/IconMenuItem/IconMenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { DeputateIcon, LockIcon, ProfileIcon, SettingsIcon, SubstitutionsIcon, TimetableIcon } from '@bp/ui-components';
import { Page } from '../../components/Page/Page';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useLoadBasicData } from '../../hooks/useLoadBasicData';

export const Dashboard: FC = () => {
  const { pimAuthClaims } = useAuthClaims();
  const permissions = usePermissionChecker();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useLoadBasicData({ pause: false });

  return (
    <Page title={t('dashboard.salutation', { name: pimAuthClaims.getProfile()?.displayName })}>
      <IconMenu headline={t('dashboard.organize')}>
        <IconMenuItem
          label={t('deputate.title')}
          icon={<DeputateIcon />}
          bubbleType={5}
          onClick={() => navigate(t('routes.deputate.slug'))}
        />
        <IconMenuItem
          label={t('timetable.title.plural')}
          icon={<TimetableIcon />}
          bubbleType={6}
          onClick={() => navigate(t('routes.timetable.slug'))}
        />
        <IconMenuItem
          label={t('substitutions.title.plural')}
          icon={<SubstitutionsIcon />}
          bubbleType={7}
          onClick={() => navigate(t('routes.substitutions.slug'))}
        />
      </IconMenu>
      <IconMenu headline={t('dashboard.maintain')} hasMarginTop>
        <IconMenuItem
          label={t('settings.title')}
          icon={<SettingsIcon />}
          bubbleType={8}
          onClick={() => navigate(t('routes.settings.slug'))}
        />
        {(permissions?.isOmniAdmin() ?? false) && (
          <IconMenuItem
            label={t('routes.admin.title')}
            icon={<LockIcon />}
            bubbleType={9}
            onClick={() => navigate(t('routes.admin.slug'))}
          />
        )}
        <IconMenuItem
          label={t('profile.title.singular')}
          icon={<ProfileIcon />}
          bubbleType={12}
          onClick={() => navigate(t('routes.profile.slug'))}
        />
      </IconMenu>
    </Page>
  );
};
