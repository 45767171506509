import { useUserConfigContext } from './useUserConfigContext';
import { useState } from 'react';
import { SortingState, Updater } from '@tanstack/react-table';

export function useColumnsSort(tableName: string, initial?: SortingState) {
  const userConfig = useUserConfigContext();
  const currentTableConfig = userConfig.tableConfigs?.find((tc) => tc.table === tableName);

  const [sorting, setSorting] = useState<SortingState>(currentTableConfig?.columnsSort ?? initial ?? []);

  const saveSorting = (updaterOrValue: Updater<SortingState>) => {
    if (updaterOrValue) {
      setSorting(updaterOrValue as SortingState);
    }
  };

  return { sorting, saveSorting };
}
