import { ProfileRoles } from '@bp/pim-auth-constants';
import i18next from 'i18next';

export function getRoleName(role?: ProfileRoles | string, _locale = 'de') {
  switch (role) {
    case ProfileRoles.Parent:
      return i18next.t('common.parents');
    case ProfileRoles.Student:
      return i18next.t('students.titleSingular');
    case ProfileRoles.Teacher:
      return i18next.t('teachers.titleSingular');
    case ProfileRoles.Other:
      return i18next.t('common.others');
    default:
      return i18next.t('common.unknown');
  }
}
