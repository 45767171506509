import { t } from 'i18next';
import { CurriculumOutlet } from '../../../../components/Curricula/CurriculumOutlet';
import { RouteObject } from 'react-router-dom';
import { CurriculumGradesTable } from '../../../../components/Curricula/Tables/CurriculumGradesTable';
import { CurriculumOverviewTable } from '../../../../components/Curricula/Tables/CurriculumOverviewTable';
import { urqlClient } from '../../../../utils/urqlClient';
import {
  CurriculumVersionsListDocument,
  CurriculumVersionsListQuery,
  CurriculumVersionsListQueryVariables,
  SubjectsListDocument,
  SubjectsListQuery,
  SubjectsListQueryVariables,
} from '../../../../types/planung-graphql-client-defs';
import { CurriculumVersionsTable } from '../../../../components/Curricula/Tables/CurriculumVersionsTable';
import { CurriculumSubjectsTable } from '../../../../components/Curricula/Tables/CurriculumSubjectsTable';
import { SubjectCurriculaPage } from './SubjectCurriculaPage';
import { GradeCurriculaPage } from './GradeCurriculaPage';

export const curriculaRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      element: <CurriculumVersionsTable />,
      index: true,
    },
    {
      path: ':uuid',
      element: <CurriculumOutlet baseSlug={`${baseSlug}`} />,
      loader: ({ params }) => {
        return urqlClient
          .query<CurriculumVersionsListQuery, CurriculumVersionsListQueryVariables>(
            CurriculumVersionsListDocument,
            {
              where: {
                uuid: params.uuid ?? '',
              },
            },
            { additionalTypenames: ['CURRICULA'] },
          )
          .toPromise();
      },
      handle: {
        crumb: ({ data }: { data: CurriculumVersionsListQuery }) => ({
          slug: `${baseSlug}/${t('routes.curriculum.slug')}/${data.curriculumVersions[0].uuid}`,
          title: data.curriculumVersions[0].name,
          asHeadline: true,
        }),
      },
      children: [
        {
          element: <CurriculumOverviewTable />,
          index: true,
        },
        {
          element: <CurriculumGradesTable />,
          path: t('routes.lessons.plan.classes.slug'),
        },
        {
          element: <GradeCurriculaPage />,
          path: t('routes.lessons.plan.classes.slug') + '/:grade',
          loader: ({ params }) => params,
          handle: {
            crumb: ({ uuid, grade }: { uuid: string | undefined; grade: string | undefined }) => {
              return {
                slug: `${baseSlug}/${t('routes.curriculum.slug')}/${uuid}??""`,
                title: grade ?? '',
              };
            },
          },
        },
        {
          element: <CurriculumSubjectsTable />,
          path: t('routes.lessons.plan.subjects.slug'),
        },
        {
          element: <SubjectCurriculaPage />,
          path: t('routes.lessons.plan.subjects.slug') + '/:subjectUuid',
          loader: async ({ params }) => {
            return urqlClient
              .query<SubjectsListQuery, SubjectsListQueryVariables>(
                SubjectsListDocument,
                {
                  where: { uuid: params.subjectUuid ?? '' },
                },
                { additionalTypenames: ['SUBJECTS'] },
              )
              .toPromise();
          },
          handle: {
            crumb: ({
              uuid,
              grade,
              data,
            }: {
              uuid: string | undefined;
              grade: string | undefined;
              data: SubjectsListQuery;
            }) => {
              return {
                slug: `${baseSlug}/${t('routes.curriculum.slug')}/${uuid}??""`,
                title: data.subjects[0]?.name ?? '',
                asHeadline: true,
              };
            },
          },
        },
      ],
    },
  ];
};
