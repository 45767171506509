import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { loadingReducer, loadingReducerInitialState } from '../../../reducer/loadingReducer';
import {
  _LessonsQuery,
  LessonCreateInput,
  LessonTeachingBlockCardsCreateFieldInput,
  use_CreateLessonsMutation,
  use_CreateTeachingBlockCardsMutation,
  use_DeleteLessonsMutation,
  use_DeleteTeachingBlockCardsMutation,
  use_LessonsQuery,
  use_SubjectContainersQuery,
  use_TeachingBlockCardsQuery,
  use_UpdateLessonsMutation,
  useTeachingBlockVersionsQuery,
} from '../../../types/planung-graphql-client-defs';
import { ClassInterfaceType, LessonTableType } from '../Tables/TimetableVersionLessonsTable';
import { hasDurationCountArray, hasLessonUnit } from '../../../utils/typeguards';
import { groupByArray } from '../../../utils/arrayFunc';
import { VersionLessonFormType } from '../types';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { v4 } from 'uuid';

type TeachingBlockLessonFormWithCardsType = VersionLessonFormType & {
  teachingBlockCards?: LessonTeachingBlockCardsCreateFieldInput[] | null;
};

export const useTeachingBlockLessons = ({ versionUuid }: { versionUuid: string }) => {
  const [loadingState, dispatch] = useReducer(loadingReducer, loadingReducerInitialState);

  const [teachingBlockSubjectContainerUuid, setTeachingBlockSubjectContainerUuid] = useState<string | null>(null);

  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const lessonContext = useMemorizedCacheTag('LESSONS');

  const [, create] = use_CreateLessonsMutation();
  const [, update] = use_UpdateLessonsMutation();
  const [, deleteCards] = use_DeleteTeachingBlockCardsMutation();
  const [, createCards] = use_CreateTeachingBlockCardsMutation();
  const [, deleteLessons] = use_DeleteLessonsMutation();

  const [{ data: versionResult }] = useTeachingBlockVersionsQuery({
    variables: {
      uuid: versionUuid ?? '',
    },
    pause: !versionUuid || versionUuid === '',
  });

  const currentVersion = versionResult?.teachingBlockVersions[0];

  useEffect(() => {
    if (!teachingBlockSubjectContainerUuid) {
      setTeachingBlockSubjectContainerUuid(currentVersion?.teachingBlock.subjectContainer.uuid ?? null);
    }
  }, [currentVersion, teachingBlockSubjectContainerUuid]);

  const [{ data: lessonsQueryData }] = use_LessonsQuery({
    variables: {
      where: {
        teachingBlockVersions_SOME: { uuid: versionUuid },
      },
    },
    pause: !teachingBlockSubjectContainerUuid || !versionUuid || versionUuid === '',
  });

  const { groupsData, classesData, gradeGroupData, lessonClassesData, roomsData, subjectData, teacherData } =
    useLoadBasicData({ pause: !lessonsQueryData });

  const [{ data: teachingBlockCardsData }] = use_TeachingBlockCardsQuery({
    variables: { where: { teachingBlockVersion: { uuid: versionUuid }, lessonAggregate: { count_GT: 0 } } },
    pause: !versionUuid || versionUuid === '',
  });

  const teachingBlockCards = teachingBlockCardsData?.teachingBlockCards;

  const [{ data: subjectContainerData }] = use_SubjectContainersQuery({
    variables: { where: { uuid_IN: teachingBlockCards?.map((tbc) => tbc.subject.uuid) } },
    pause: !teachingBlockCardsData,
  });

  const lessonsData: LessonTableType[] = useMemo(() => {
    return (
      lessonsQueryData?.lessons.map((lesson) => {
        const subject =
          subjectData?.subjects.find((s) => s.uuid === lesson.subject.uuid) ??
          subjectContainerData?.subjectContainers.find((s) => s.uuid === lesson.subject.uuid);

        const lessonClasses = lessonClassesData?.lessonClasses
          .filter((c) => lesson.lessonClassesConnection.edges.some(({ node }) => node.uuid === c.uuid))
          .sort((a, b) => {
            const classA = classesData?.classes.find((c) => c.uuid === a.class.uuid);
            const classB = classesData?.classes.find((c) => c.uuid === b.class.uuid);
            return (classA?.grade ?? 0) - (classB?.grade ?? 0);
          });

        const rooms = roomsData?.rooms.filter((r) =>
          lesson.roomSupplyConnection.edges.some(({ node }) => node.uuid === r.uuid),
        );

        const classes = lessonClasses
          ?.map((lc) => {
            return classesData?.classes.find((c) => c.uuid === lc.class.uuid);
          })
          .sort((a, b) => (a?.grade ?? 0) - (b?.grade ?? 0));

        const teachers = teacherData?.people.filter((p) =>
          lesson.teachersConnection.edges.find((t) => t.node.uuid === p.uuid),
        );

        const res: LessonTableType = {
          classesUuids: classes?.map((c) => c?.uuid ?? '') ?? [],
          elective: lesson.elective ?? false,
          onlyInTimetableVersion: lesson.onlyInTimetableVersion,
          editStatus: lesson.onlyInTimetableVersion ? 'inVersion' : 'blocked',
          subjectShortName: subject?.shortName ?? '',
          teacherUuids: teachers?.map((t) => t.uuid) ?? [],
          teachingLoadEnabled: lesson.teachingLoadEnabled ?? false,
          timetableEnabled: lesson.timetableEnabled ?? false,
          uuid: lesson.uuid,
          groupClassNames: lesson.groupClassNames,
          rooms: rooms,
          subject: subject?.name ?? '',
          subjectUuid: subject?.uuid ?? '',
          classSort: lesson.classSort,
          teacherSort: lesson.teachersSort,
          placedCardsCount: lesson.teachingBlockPlacedCardsCount,
          teachers:
            teachers?.map((t) => {
              return {
                uuid: t.uuid,
                name: t.displayNameShort,
                color: t.timetableConfig?.color,
                teachingLoadEnabled: false,
                presenceEnabled: false,
                teachingLoadHours: 2,
              };
            }) ?? [],
          classes:
            classes?.map((c) => {
              const gradeGroup = gradeGroupData?.gradeGroups.find((gg) => c?.gradeGroup?.uuid === gg.uuid);

              const res: ClassInterfaceType & {
                groups?: Array<{ uuid: string; shortName?: string; name: string; classFraction?: number }>;
              } = {
                uuid: c?.uuid ?? '',
                grade: c?.grade ?? 0,
                name: c?.name ?? '',
                shortName: c?.shortName,
                gradeGroup: gradeGroup,
              };
              return res;
            }) ?? [],
        };
        return res;
      }) ?? []
    );
  }, [
    classesData?.classes,
    gradeGroupData?.gradeGroups,
    lessonClassesData?.lessonClasses,
    lessonsQueryData?.lessons,
    roomsData?.rooms,
    subjectContainerData?.subjectContainers,
    subjectData?.subjects,
    teacherData?.people,
  ]);

  const detachLessons = useCallback(
    async (uuids: string[]) => {
      dispatch({ type: 'SET_LOADING', uuids: uuids });

      const result = await update({ where: { uuid_IN: uuids }, update: { teachers: [{ disconnect: [{}] }] } });
      dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
      return result;
    },
    [update],
  );

  const deleteCardsAndLessons = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });

    if (!versionUuid) {
      dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
      return { error: { message: 'version not found', name: 'version not found' }, success: false };
    }
    const deleteResult = await deleteCards(
      {
        where: { teachingBlockVersion: { uuid: versionUuid }, lesson: { uuid_IN: uuids } },
        delete: { lesson: { where: { node: { uuid_IN: uuids } } } },
      },
      { additionalTypenames: ['TeachingBlockCard'] },
    );

    await deleteLessons({ where: { uuid_IN: uuids } }, { additionalTypenames: ['Lesson'] });

    const result = deleteResult.error ? { success: false, error: deleteResult.error } : { success: true };

    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });

    return result;
  };

  const deactivateLessons = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    let result: { error?: unknown; success?: boolean };
    if (versionUuid) {
      const resCards = await deleteCards(
        {
          where: { teachingBlockVersion: { uuid: versionUuid }, lesson: { uuid_IN: uuids } },
        },
        lessonContext,
      );
      const resLesson = await update(
        {
          where: { uuid_IN: uuids },
          update: { teachingBlockVersions: [{ disconnect: [{ where: { node: { uuid: versionUuid } } }] }] },
        },
        lessonContext,
      );
      const hasError = [resCards, resLesson].some((res) => res.error);
      result = hasError
        ? { error: [resCards, resLesson].find((res) => res.error)?.error, success: false }
        : { success: true };
    } else {
      result = { error: { message: 'version not found', name: 'version not found' }, success: false };
    }

    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });

    return result;
  };

  const assignLessons = useCallback(
    async (uuids: string[], teachers: { uuid: string }[]) => {
      dispatch({ type: 'SET_LOADING', uuids: uuids });

      const result = await update(
        {
          where: { uuid_IN: uuids },
          update: {
            teachers: [
              {
                connect: teachers?.map((teacher, index) => {
                  return {
                    where: {
                      node: { uuid: teacher.uuid },
                    },
                    edge: {
                      order: index,
                    },
                  };
                }),
              },
            ],
          },
        },
        lessonContext,
      );
      dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
      return result;
    },
    [update, versionUuid],
  );

  const updateCheckboxes = async ({
    type,
    uuids,
    value,
  }: {
    type: 'teachingLoadEnabled' | 'timetableEnabled' | 'elective';
    uuids: string[];
    value: boolean;
  }) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    const res = await update({ where: { uuid_IN: uuids }, update: { [type]: value } });
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return res;
  };

  const getBasics = (values: TeachingBlockLessonFormWithCardsType) => {
    return {
      subTitle: values.subTitle,
      comment: values.comment,
      teachingLoadEnabled: values.teachingLoadEnabled ?? false,
      timetableEnabled: values.timetableEnabled ?? false,
      elective: values.elective ?? false,
      onlyInTimetableVersion: true,
    };
  };

  const createLesson = async (values: TeachingBlockLessonFormWithCardsType) => {
    const input: LessonCreateInput = {
      organization: { connect: { where: { node: { uuid: organizationUuid } } } },
      ...getBasics(values),
      schoolYear: {
        connect: {
          where: {
            node: {
              uuid: schoolYear?.uuid,
            },
          },
        },
      },
      subject: {
        Subject: {
          connect: {
            where: {
              node: {
                uuid: values.subject?.uuid,
              },
            },
          },
        },
      },
      lessonClasses: {
        create: values.lessonClasses?.map((lessonClass, index) => ({
          edge: {
            order: index,
          },
          node: {
            class: {
              connect: {
                where: {
                  node: {
                    uuid: lessonClass.class.uuid,
                  },
                },
              },
            },
            ...(lessonClass.groups &&
              lessonClass.groups.length > 0 && {
                groups: {
                  connect: lessonClass.groups.map((group) => ({ where: { node: { uuid: group.uuid } } })),
                },
              }),
          },
        })),
      },
      teachers: {
        connect: values.teachers?.map((teacher, index) => {
          return {
            where: {
              node: { uuid: teacher.person?.uuid },
            },
            edge: {
              order: index,
              description: teacher.description,
              present: teacher.present,
              teachingLoad: teacher.teachingLoad,
              teachingLoadHours: teacher.teachingLoadHours,
              writesCertificate: teacher.writesCertificate,
            },
          };
        }),
      },
      roomSupply: {
        connect: values.roomSupply?.map((room, index) => {
          return {
            where: {
              node: { uuid: room.uuid },
            },
            edge: {
              order: index,
              descriptions: room.description,
            },
          };
        }),
      },
      teachingBlockVersions: {
        connect: [
          {
            where: {
              node: { uuid: versionUuid },
            },
          },
        ],
      },
    };

    if (hasDurationCountArray(values)) {
      const groups = groupByArray(values.durationCountArray, (v) => v.duration);
      const teachingBlockCardsCreate: LessonTeachingBlockCardsCreateFieldInput[] = [];

      groups.forEach((group) => {
        group.forEach((durationCount) => {
          const count = durationCount.count ? Array.from(Array(Math.abs(durationCount.count)), (x, i) => i) : [];
          count.forEach(() => {
            teachingBlockCardsCreate.push({
              node: {
                teachingBlockVersion: { connect: { where: { node: { uuid: versionUuid } } } },
                duration: durationCount.duration ?? 1,
                locked: false,
                includeHoliday: false,
              },
            });
          });
        });
      });

      input.teachingBlockCards = {
        create: teachingBlockCardsCreate,
      };
    } else if (hasLessonUnit(values)) {
      const teachingBlockCardsCreate: LessonTeachingBlockCardsCreateFieldInput[] = [];
      values.lessonUnit.forEach((lessonUnit) => {
        const count = lessonUnit.count ? Array.from(Array(Math.abs(lessonUnit.count)), (x, i) => i) : [];
        count.forEach(() => {
          teachingBlockCardsCreate.push({
            node: {
              teachingBlockVersion: { connect: { where: { node: { uuid: versionUuid } } } },
              duration: lessonUnit.duration ?? 1,
              locked: false,
              includeHoliday: false,
            },
          });
        });
      });
      input.teachingBlockCards = {
        create: teachingBlockCardsCreate,
      };
    }

    if (values.teachingBlockCards) {
      input.teachingBlockCards = {
        create: values.teachingBlockCards,
      };
    }

    return await create({ input: input }, lessonContext);
  };

  const lessonToFormType = (lesson: Pick<_LessonsQuery, 'lessons'>['lessons'][0]): VersionLessonFormType | null => {
    const currentLesson = lesson;
    const currentLessonSubject =
      subjectData?.subjects.find((subject) => subject.uuid === currentLesson?.subject.uuid) ??
      subjectContainerData?.subjectContainers.find(
        (subjectContainer) => subjectContainer.uuid === currentLesson?.subject.uuid,
      );

    const lessonClasses = lessonClassesData?.lessonClasses
      .filter((c) => lesson.lessonClassesConnection.edges.some(({ node }) => node.uuid === c.uuid))
      .sort((a, b) => {
        const classA = classesData?.classes.find((c) => c.uuid === a.class.uuid);
        const classB = classesData?.classes.find((c) => c.uuid === b.class.uuid);
        return (classA?.grade ?? 0) - (classB?.grade ?? 0);
      });

    return currentLesson
      ? {
          uuid: lesson.uuid,
          subject: currentLessonSubject
            ? currentLessonSubject.__typename === 'Subject'
              ? { ...currentLessonSubject }
              : { ...currentLessonSubject }
            : undefined,
          lessonClasses:
            lessonClasses?.map((lc) => {
              const lessonClass = lessonClassesData?.lessonClasses.find((c) => c.uuid === lc.uuid);
              const currentClass = classesData?.classes.find((c) => c.uuid === lessonClass?.class.uuid);

              const currentGroups = groupsData?.groups.filter((group) =>
                lessonClass?.groups.some((g) => g.uuid === group.uuid),
              );
              return currentClass && lessonClass
                ? {
                    uuid: lessonClass.uuid,
                    groups:
                      currentGroups?.map((group) => ({
                        name: group.name,
                        uuid: group.uuid,
                      })) ?? [],
                    class: {
                      uuid: currentClass.uuid,
                      name: currentClass.name,
                      color: currentClass.timetableConfig?.color,
                      grade: currentClass.grade,
                    },
                  }
                : {
                    class: {
                      uuid: v4(),
                    },
                    uuid: v4(),
                    groups: [],
                  };
            }) ?? [],
          comment: currentLesson.comment,
          elective: currentLesson.elective,
          teachingLoadEnabled: currentLesson.teachingLoadEnabled,
          timetableEnabled: currentLesson.timetableEnabled,
          subTitle: currentLesson.subTitle,
          roomSupply: roomsData?.rooms
            .filter((r) => currentLesson.roomSupplyConnection.edges.some(({ node }) => node.uuid === r.uuid))
            .map((r) => ({ uuid: r.uuid, description: r.description })),
          teachers: currentLesson.teachersConnection.edges.map((person) => {
            const teacher = teacherData?.people.find((t) => t.uuid === person.node.uuid);
            return {
              present: person.properties.present,
              teachingLoad: person.properties.teachingLoad,
              teachingLoadHours: person.properties.teachingLoadHours,
              description: person.properties.description,
              writesCertificate: person.properties.writesCertificate,
              person: {
                uuid: person.node.uuid,
                fullName: teacher?.fullName ?? '',
                displayNameShort: teacher?.displayNameShort ?? teacher?.fullName ?? '',
                color: teacher?.timetableConfig?.color,
              },
            };
          }),

          durationCountArray: [
            {
              duration: 1,
              count: 1,
            },
          ],
        }
      : null;
  };

  const duplicateLesson = async (uuids: string[], versionLessons: boolean) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    let result: { error?: unknown; success?: boolean } = {};

    const currentLesson = lessonsQueryData?.lessons.find((lesson) => uuids.includes(lesson.uuid));

    if (currentLesson) {
      const cards = teachingBlockCards?.filter((card) => card.lesson.uuid === currentLesson.uuid);

      const createData: TeachingBlockLessonFormWithCardsType | null = lessonToFormType({
        ...currentLesson,
        uuid: '',
      });
      if (createData) {
        const createResult = await createLesson({
          ...createData,
          teachingBlockCards: cards?.map((card) => {
            return {
              node: {
                teachingBlockVersion: { connect: { where: { node: { uuid: versionUuid } } } },
                duration: card.duration ?? 1,
                locked: false,
                includeHoliday: false,
              },
            };
          }),
        });
        if (versionLessons) {
          await update(
            {
              where: { uuid_IN: uuids },
              update: { teachingBlockVersions: [{ disconnect: [{}] }] },
            },
            lessonContext,
          );

          result = await deactivateLessons(uuids);
        } else {
          result = createResult;
        }
      }
    }
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return result;
  };

  const updateLesson = async (values: VersionLessonFormType) => {
    dispatch({ type: 'SET_LOADING', uuids: [values.uuid ?? 'all'] });

    const result = await update({
      where: { uuid: values.uuid },
      update: {
        ...getBasics(values),
        schoolYear: {
          disconnect: {},
          connect: {
            where: {
              node: {
                uuid: schoolYear?.uuid,
              },
            },
          },
        },
        subject: {
          Subject: {
            disconnect: {},
            connect: {
              where: {
                node: {
                  uuid: values.subject?.uuid,
                },
              },
            },
          },
        },
        lessonClasses: [
          {
            delete: [{}],
            create: values.lessonClasses?.map((lessonClass, index) => ({
              edge: {
                order: index,
              },
              node: {
                class: {
                  connect: {
                    where: {
                      node: {
                        uuid: lessonClass.class.uuid,
                      },
                    },
                  },
                },
                ...(lessonClass.groups &&
                  lessonClass.groups.length > 0 && {
                    groups: {
                      connect: lessonClass.groups.map((group) => ({ where: { node: { uuid: group.uuid } } })),
                    },
                  }),
              },
            })),
          },
        ],
        teachers: [
          {
            disconnect: [{}],
            connect: values.teachers?.map((teacher, index) => {
              return {
                where: {
                  node: { uuid: teacher.person?.uuid },
                },
                edge: {
                  order: index,
                  description: teacher.description,
                  present: teacher.present,
                  teachingLoad: teacher.teachingLoad,
                  teachingLoadHours: teacher.teachingLoadHours,
                  writesCertificate: teacher.writesCertificate,
                },
              };
            }),
          },
        ],
        roomSupply: [
          {
            disconnect: [{}],
            connect: values.roomSupply?.map((room, index) => {
              return {
                where: {
                  node: { uuid: room.uuid },
                },
                edge: {
                  order: index,
                  descriptions: room.description,
                },
              };
            }),
          },
        ],
      },
    });
    dispatch({ type: 'REMOVE_LOADING', uuids: [values.uuid ?? 'all'] });
    return result;
  };
  // sort lessons data first by classSort then by teacherSort
  return {
    lessonsData: lessonsData.sort((a, b) =>
      ((a.classSort ?? '') + a.teacherSort + a.subject).localeCompare((b.classSort ?? '') + b.teacherSort + b.subject),
    ),
    loadingState,
    teachingBlockSubjectContainerUuid,
    detachLessons,
    deleteCardsAndLessons,
    deactivateLessons,
    assignLessons,
    updateCheckboxes,
    duplicateLesson,
    createLesson,
    updateLesson,
    currentVersion,
    dispatch,
    lessonToFormType,
    update,
    createCards,
  };
};
