import { BillingMethods } from '@bp/planung-graphql-types';

type Props = {
  deputateLesson: number;
  deputateManually: number;
  deputateContract: number;
  billingMethod?: BillingMethods | null;
  limitedToFullTime?: boolean | null;
  fullTimeHours?: number | null;
};

export function _evalBillingMethod({
  deputateLesson = 0,
  deputateManually = 0,
  deputateContract = 0,
  billingMethod,
  limitedToFullTime,
  fullTimeHours,
}: Props) {
  const actualWorkload = deputateManually + deputateLesson;
  let payed: number;
  switch (billingMethod) {
    case 'EXACT':
      payed = deputateContract;
      break;
    case 'CONTRACT_IS_MAX':
      payed = deputateContract - actualWorkload <= 0 ? deputateContract : actualWorkload;
      break;
    case 'CONTRACT_IS_MIN':
      payed = actualWorkload < deputateContract ? deputateContract : actualWorkload;
      break;
    case 'GIVEN_HOURS':
      payed = actualWorkload;
      break;
    default:
      payed = actualWorkload;
  }

  if (limitedToFullTime && payed > (fullTimeHours ?? payed)) {
    payed = fullTimeHours ?? payed;
  }

  return payed;
}
