import * as Yup from 'yup';
import i18next from 'i18next';

const timegridConfigsSchema = Yup.object().shape({
  timegridUuid: Yup.string().required(i18next.t('validation.timetableDraft.timegridConfig.timegridRequired') as string),
});

export const timetableSchema = (timetableDraftNames: string[]) => {
  return Yup.object().shape({
    draft: Yup.boolean().required(),
    name: Yup.string()
      .required(i18next.t('validation.timetableDraft.nameRequired') as string)
      .test('unique', i18next.t('validation.timetableDraft.nameUnique') as string, (value) => {
        const duplication = timetableDraftNames.find((ttdn) => ttdn === value);
        return duplication === undefined;
      }),
    comment: Yup.string().nullable(),
    schoolYearUuid: Yup.string().required(i18next.t('validation.timetableDraft.schoolYearUuidRequired') as string),
    timegridConfigs: Yup.array()
      .of(timegridConfigsSchema)
      .min(1, i18next.t('validation.timetableDraft.timegridConfigsRequired') as string)
      .required(),
  });
};
