import { useTranslation } from 'react-i18next';
import { UserTable } from '../../../components/Users/UserTable';
import { Page } from '../../../components/Page/Page';

export const UsersPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('users.title')}>
      <UserTable />
    </Page>
  );
};
