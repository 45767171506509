import { Outlet, RouteObject } from 'react-router-dom';
import { t } from 'i18next';
import { deputateSetupRoutes } from './Setup/routes';
import { deputatePlanRoutes } from './Plan/routes';
import { deputateEvaluateRoutes } from './Evaluate/routes';
import { DeputatePage } from './DeputatePage';

export const deputateRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <DeputatePage />,
    },
    {
      path: t('routes.setup.slug') + '/*',
      element: <Outlet />,
      children: [...deputateSetupRoutes(`${baseSlug}/${t('routes.setup.slug')}`)],
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.setup.slug')}`,
          title: t('routes.setup.title'),
        }),
      },
    },
    {
      path: t('routes.plan.slug') + '/*',
      element: <Outlet />,
      children: [...deputatePlanRoutes(`${baseSlug}/${t('routes.plan.slug')}`)],
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.plan.slug')}`,
          title: t('routes.plan.title'),
        }),
      },
    },
    {
      path: t('routes.evaluate.slug'),
      element: <Outlet />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.evaluate.slug')}`,
          title: t('routes.evaluate.title'),
        }),
      },
      children: [...deputateEvaluateRoutes(`${baseSlug}/${t('routes.evaluate.slug')}`)],
    },
  ];
};
