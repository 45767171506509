import { ContextMenu, ContextMenuItem, EmptyState } from '@bp/ui-components';
import styles from './GridRows.module.scss';
import { useTranslation } from 'react-i18next';
import { GridWeek } from '../GridWeek/GridWeek';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useFullscreen } from '../../../hooks/useFullscreen';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';

export const GridRows = observer(() => {
  const { t } = useTranslation();
  const isFullscreen = useFullscreen();

  const [weekWidth, setWeekWidth] = useState<number>(0);

  const {
    currentVersion,
    rows,
    dates,
    weeks,
    rowClickHandler,
    selectedRows,
    selectRows,
    lockCardsInSelectedRows,
    unlockCardsInSelectedRows,
    discardCardsInSelectedRows,
  } = useTeachingBlockStore();

  const contextMenu: ContextMenuItem[] = [
    {
      node: <div className={styles['menu-name']}>{selectedRows.map((selected) => selected.label).join(', ')}</div>,
      type: 'component',
    },
    {
      type: 'ruler',
    },
    {
      label: t('pinboard.actions.lockAll'),
      onClick: (e) => {
        e.stopPropagation();
        lockCardsInSelectedRows();
      },
    },
    {
      label: t('pinboard.actions.unlockAll'),
      onClick: (e) => {
        e.stopPropagation();
        unlockCardsInSelectedRows();
      },
    },
    {
      label: t('pinboard.actions.removeAll'),
      type: 'error',
      onClick: (e) => {
        e.stopPropagation();
        discardCardsInSelectedRows();
      },
    },
  ];

  const weekRef = useRef<HTMLDivElement>(null);

  const today = dayjs().utc().startOf('day');
  const isInCurrentYear = today.isSameOrAfter([...dates.keys()][0], 'day');
  const distance = today.diff([...dates.keys()][0], 'weeks');
  const offset = today.day();

  const timeframeWidth = weekWidth * distance + (weekWidth / 7) * offset;

  const classes = classNames(styles['grid-rows'], {
    [styles['is-fullscreen']]: isFullscreen,
  });

  useEffect(() => {
    setWeekWidth(weekRef.current?.getBoundingClientRect().width ?? 0);
  }, [weekRef.current?.getBoundingClientRect().width]);

  return (
    <div className={classes}>
      {rows?.length === 0 ? (
        <EmptyState
          title={t('pinboard.empty', { type: t('classes.title.plural') })}
          padding='l'
          className={styles.empty}
        />
      ) : (
        <>
          {isInCurrentYear && !!currentVersion.active && (
            <div
              title={t('pinboard.pastTimeframe')}
              className={styles['today-indicator']}
              style={{ width: `${timeframeWidth}px` }}
            ></div>
          )}
          {rows?.map((row) => {
            return (
              <div key={row.value} className={styles.row}>
                <ContextMenu data={contextMenu} usePortal={!isFullscreen}>
                  <div
                    className={styles['name-wrapper']}
                    onClick={(event) => {
                      rowClickHandler(event, { label: row.label, value: row.value });
                    }}
                    title={row.label}
                    onContextMenu={() => {
                      if (selectedRows.length <= 1 || !selectedRows.includes(row)) selectRows([row]);
                    }}
                  >
                    <div
                      className={classNames(styles.name, {
                        [styles.selected]: selectedRows.find((r) => r.value === row.value),
                      })}
                    >
                      {row.label}
                    </div>
                  </div>
                </ContextMenu>

                {[...weeks.keys()].map((weekNumber, index) => {
                  return (
                    <GridWeek
                      key={weekNumber + '_' + index}
                      row={row}
                      weekNumber={[...weeks.values()][index][0].weekNumber}
                      days={weeks.get(weekNumber) ?? []}
                      weekRef={index === 0 ? weekRef : undefined}
                    />
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
});
