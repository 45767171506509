type UuidItem = {
  uuid: string;
  loading: boolean;
};

type Action =
  | { type: 'SET_LOADING'; uuids: string[] }
  | { type: 'REMOVE_LOADING'; uuids: string[] }
  | { type: 'RESET' };

const loadingReducerInitialState: UuidItem[] = [];

const loadingReducer = (state: UuidItem[], action: Action): UuidItem[] => {
  switch (action.type) {
    case 'SET_LOADING':
      return [
        ...state.filter((item) => {
          return action.uuids.includes(item.uuid) ? { uuid: item.uuid, loading: false } : item;
        }),
        ...action.uuids.map((uuid) => ({ uuid: uuid, loading: true })),
      ];
    case 'REMOVE_LOADING':
      return state.map((item) => ({
        ...item,
        loading: action.uuids.includes(item.uuid) ? false : item.loading,
      }));
    case 'RESET':
      return loadingReducerInitialState;
    default:
      return state;
  }
};

export { loadingReducerInitialState, loadingReducer };
