import { useTranslation } from 'react-i18next';
import { MenuBar, MenuBarEntry } from '../../MenuBar/MenuBar';
import { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useConfirm } from '../../../hooks/useConfirm';
import styles from './GridMenuBar.module.scss';
import { useContainerDimensions } from '../../../hooks/useDimensions';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import {
  ChangeViewIcon,
  DeleteIcon,
  FullscreenActiveIcon,
  FullscreenIcon,
  LockIcon,
  UnlockIcon,
} from '@bp/ui-components';
import { GridContext, GridHighlightType } from '../types';

export const GridMenuBar: FC = observer(() => {
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(wrapperRef);
  const isCompact = width < 1220;

  const { placedCards, unlockAllCards, lockAllCards, discardAllCards, setContext, highlightMode, setHighlightMode } =
    useTeachingBlockStore();

  const allLocked = placedCards.length > 0 && placedCards.filter((card) => card.locked).length === placedCards.length;

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [escFullscreenExit, setEscFullscreenExit] = useState<boolean>(false);

  // const handleDownload = async () => {
  //   const data = await apiHelper({
  //     path: `/mathplan/export/${pinboardStore.getCurrentVersion()?.uuid}`,
  //     type: 'xml',
  //     method: 'GET',
  //   });
  //   return new Blob([data as string], { type: 'application/xml' });
  // };

  // const handleUploadChange = async (files: FileList | null) => {
  //   if (files) {
  //     const fileUploaded = files[0];

  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       const stringData = e.target?.result;
  //       try {
  //         const currentVersion = pinboardStore.getCurrentVersion();
  //         if (currentVersion !== null) {
  //           await apiHelper({
  //             path: `/mathplan/import/${currentVersion.uuid}`,
  //             method: 'POST',
  //             data: stringData,
  //             type: 'xml',
  //           });
  //           await pinboardStore.loadData(currentVersion.uuid ?? '');
  //         }
  //       } catch (error) {
  //         console.error('Fehler beim Hochladen der XML-Datei:', error);
  //         throw error;
  //       }
  //     };
  //     reader.readAsText(fileUploaded);
  //   }
  // };

  // const { download } = useDownload({
  //   getFile: () => handleDownload(),
  //   getFileName: () => {
  //     return `${pinboardStore.getCurrentVersion()?.uuid}_${Date.now().toString()}.xml`;
  //   },
  // });

  // const { upload } = useUpload({
  //   uploadFile: handleUploadChange,
  // });

  function onFullscreen() {
    const grid = document.querySelector('#teaching-block-grid');
    if (!grid) return;
    if (!document.fullscreenElement) {
      grid
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
          document.body.classList.add('fullscreen');
        })
        .catch((err) => {
          console.log(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
        });
    } else {
      setIsFullscreen(false);
      document.body.classList.remove('fullscreen');
      void document.exitFullscreen();
    }
  }

  function onChangeView(value: GridContext) {
    setContext(value);
  }

  function onChangeHighlightMode(highlight: GridHighlightType) {
    setHighlightMode(highlight);
  }

  // async function control() {
  //   const versionUuid = pinboardStore.getCurrentVersion()?.uuid;
  //   if (versionUuid) {
  //     if (!pinboardStore.controlMode.isActive) {
  //       void pinboardStore.setControlModeLoading(true);

  //       const controlResult = await controlTimeTableVersion(
  //         versionUuid,
  //         pinboardStore.selectedRows.map((row) => row.value),
  //       );

  //       void pinboardStore.setControlModeLoading(false);
  //       void pinboardStore.setControlModeResult(controlResult);
  //     }
  //     void pinboardStore.setControlModeActive(!pinboardStore.controlMode.isActive);
  //   }
  // }

  // async function test() {
  //   const versionUuid = pinboardStore.getCurrentVersion()?.uuid;
  //   if (versionUuid) {
  //     void pinboardStore.setTestModalOpen(true);
  //     void pinboardStore.setTestModalLoading(true);
  //     const testResult = await testTimeTableVersion(
  //       versionUuid,
  //       pinboardStore.selectedRows.map((row) => row.value),
  //     );
  //     void pinboardStore.setTestModalResult(testResult);
  //     void pinboardStore.setTestModalLoading(false);
  //   }
  // }

  useEffect(() => {
    const fullscreenchanged = () => {
      if (escFullscreenExit) {
        document.body.classList.remove('fullscreen');
        setIsFullscreen(false);
      }
      setEscFullscreenExit(!escFullscreenExit);
    };
    document.addEventListener('fullscreenchange', fullscreenchanged);
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenchanged);
    };
  }, [escFullscreenExit]);

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('pinboard.removeAllConfirm'),
    defaultConfirmText: t('pinboard.actions.removeAll'),
  });

  const toggleAllActions: MenuBarEntry[] = [
    {
      actions: [
        {
          icon: allLocked ? <UnlockIcon /> : <LockIcon />,
          title: allLocked ? t('pinboard.actions.unlockAll') : t('pinboard.actions.lockAll'),
          action: () => {
            allLocked ? unlockAllCards() : lockAllCards();
          },
        },
        {
          icon: <DeleteIcon />,
          title: t('pinboard.actions.removeAll'),
          action: async () => {
            const res = await confirmDelete();
            if (res) {
              discardAllCards();
            }
          },
        },
      ],
    },
  ];

  const toggleViewActions: MenuBarEntry[] = [
    {
      actions: [
        {
          icon: <ChangeViewIcon />,
          title: t('pinboard.actions.changeView'),
          action: () => console.log('view clicked'),
          subactions: [
            {
              title: t('pinboard.actions.viewClass').toString(),
              action: () => onChangeView('classes'),
            },
            {
              title: t('pinboard.actions.viewTeacher').toString(),
              action: () => onChangeView('teachers'),
            },
            {
              title: t('pinboard.actions.viewRoom'),
              action: () => onChangeView('rooms'),
            },
            {
              title: null,
              action: null,
              type: 'ruler',
            },
            {
              title: t('pinboard.actions.showWarnings'),
              action: () => onChangeHighlightMode('warnings'),
              type: 'switch',
              value: highlightMode.includes('warnings'),
            },
            {
              title: t('pinboard.actions.showNoRooms'),
              action: () => onChangeHighlightMode('rooms'),
              type: 'switch',
              value: highlightMode.includes('rooms'),
            },
            {
              title: t('pinboard.actions.hideIcons'),
              action: () => onChangeHighlightMode('noIcons'),
              type: 'switch',
              value: highlightMode.includes('noIcons'),
            },
          ],
        },
        {
          icon: isFullscreen ? <FullscreenActiveIcon /> : <FullscreenIcon />,
          title: isFullscreen ? t('common.fullscreenCancel') : t('common.fullscreen'),
          action: () => onFullscreen(),
        },
      ],
    },
  ];

  const compactEntries: MenuBarEntry[] = [
    // {
    //   actions: [
    //     {
    //       icon: <TimetableIcon />,
    //       title: t('common.additionalActions'),
    //       action: () => {},
    //       subactions: [
    //         {
    //           title: t('pinboard.actions.upload'),
    //           action: () => upload(),
    //         },
    //         {
    //           title: t('pinboard.actions.download'),
    //           action: () => download(),
    //         },
    //         {
    //           title: t('pinboard.actions.improve'),
    //           action: () => console.log('improve clicked'),
    //           disabled: true,
    //         },
    //         {
    //           title: t('pinboard.actions.assignRoom'),
    //           action: () => console.log('room clicked'),
    //           disabled: true,
    //         },
    //         {
    //           title: t('pinboard.actions.test'),
    //           action: () => test(),
    //         },
    //         {
    //           title: t('pinboard.actions.control'),
    //           action: () => control(),
    //         },
    //       ],
    //     },
    //   ],
    // },
    ...toggleAllActions,
    ...toggleViewActions,
  ];

  const entries: MenuBarEntry[] = [
    // {
    //   actions: [
    //     {
    //       icon: <GenerateIcon />,
    //       title: t('pinboard.actions.generate'),
    //       action: () => console.log('generate clicked'),
    //       subactions: [
    //         {
    //           title: t('pinboard.actions.upload'),
    //           action: () => upload(),
    //         },
    //         {
    //           title: t('pinboard.actions.download'),
    //           action: () => download(),
    //         },
    //       ],
    //     },
    //     {
    //       icon: <ImproveIcon />,
    //       title: t('pinboard.actions.improve'),
    //       action: () => console.log('improve clicked'),
    //       disabled: true,
    //     },
    //   ],
    // },
    // {
    //   actions: [
    //     {
    //       icon: <RoomIcon />,
    //       title: t('pinboard.actions.assignRoom'),
    //       action: () => console.log('room clicked'),
    //       disabled: true,
    //     },
    //     {
    //       icon: <TestIcon />,
    //       title: t('pinboard.actions.test'),
    //       action: () => test(),
    //     },
    //     {
    //       icon: <ControlIcon />,
    //       title: t('pinboard.actions.control'),
    //       action: () => control(),
    //     },
    //   ],
    // },
    ...toggleAllActions,
    ...toggleViewActions,
  ];

  // const controlModeEntries: MenuBarEntry[] = [
  //   {
  //     actions: [
  //       {
  //         icon: <ControlIcon />,
  //         title: t('pinboard.control.quit'),
  //         action: () => control(),
  //       },
  //     ],
  //   },
  //   {
  //     actions: [
  //       {
  //         icon: <ArrowDownIcon />,
  //         title: t('pinboard.control.expandAll'),
  //         action: () => pinboardStore.expandControlResults(true),
  //       },
  //       {
  //         icon: <ArrowUpIcon />,
  //         title: t('pinboard.control.collapseAll'),
  //         action: () => pinboardStore.expandControlResults(false),
  //       },
  //     ],
  //   },
  //   ...toggleViewActions,
  // ];

  return (
    <div className={styles['grid-menu-bar']} ref={wrapperRef}>
      <MenuBar
        entries={isCompact ? compactEntries : entries}
        usePortal={!isFullscreen}
        // passive={pinboardStore.controlMode.isActive}
      />
      <ConfirmationDialog />
    </div>
  );
});
