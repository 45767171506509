export type ConstraintPriorities = 'optimizing' | 'important' | 'mandatory';

export type ConstraintTypes =
  | 'startEnd'
  | 'minMaxHours'
  | 'maxFreePeriod'
  | 'sameEnd'
  | 'lunchBreak'
  | 'maxOnUnknownPosition';

export const constraintOrder: ConstraintTypes[] = [
  'startEnd',
  'minMaxHours',
  'maxFreePeriod',
  'sameEnd',
  'lunchBreak',
  'maxOnUnknownPosition',
];

export type ConstraintsSelectType = {
  label: string;
  value: ConstraintTypes;
};

export type ConstraintsFormInitialValuesType = {
  comment: string;
  startEnd?: { latestStart?: string; earliestEnd?: string };
  startEndPriority?: ConstraintPriorities;
  minMaxHours?: { min?: number; max?: number };
  minMaxHoursPriority?: ConstraintPriorities;
  maxFreePeriod?: number;
  maxFreePeriodPriority?: ConstraintPriorities;
  sameEnd?: boolean;
  sameEndPriority?: ConstraintPriorities;
  lunchBreak?: { from?: string; until?: string };
  lunchBreakPriority?: ConstraintPriorities;
  maxOnUnknownPosition?: number;
  maxOnUnknownPositionPriority?: ConstraintPriorities;
};

export type ConstraintsItemType = {
  type: ConstraintTypes;
  text: string;
  priority: ConstraintPriorities | null;
};
