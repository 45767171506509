import { observer } from 'mobx-react-lite';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import styles from './GridHeader.module.scss';
import { GridHeaderMonth } from './GridHeaderMonth';
import { GridHeaderWeeks } from './GridHeaderWeeks';

type GridHeaderProps = {
  months: number[];
};

export const GridHeader = observer(({ months }: GridHeaderProps) => {
  const { selectedRows, selectRows, weeks, rows } = useTeachingBlockStore();

  const toggleAllRowSelection = () => {
    selectedRows.length > 0 && selectedRows.length === rows.length ? selectRows([]) : selectRows(rows);
  };

  return (
    <div className={styles['grid-header']}>
      <div
        className={styles['toggle-all']}
        title='Click to select/deselect all'
        onClick={() => toggleAllRowSelection()}
      ></div>
      <div className={styles.wrapper}>
        <div className={styles.months}>
          {months.map((month, i) => (
            <GridHeaderMonth key={month + ' ' + i} index={month} count={months.length} />
          ))}
        </div>
        <GridHeaderWeeks weeks={weeks} />
      </div>
    </div>
  );
});
