import { formatNumber } from '../../utils/helper';
import styles from './CurriculumStats.module.scss';
import { Card } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';

type CurriculumStatsProps = {
  subjectHours: number;
  deputat: number;
  studentHours: number;
};

export const CurriculumStats = ({ subjectHours, deputat, studentHours }: CurriculumStatsProps) => {
  const { t } = useTranslation();
  return (
    <Card wrapperClass={styles['curriculum-stats']} className={styles.content} fitContent contentPadding='none'>
      <div className={styles.stats}>
        <div className={styles.stat}>
          <div>{t('subjectHours.subjectHour_other')}</div>
          <div>{formatNumber(subjectHours)}</div>
        </div>
        <div className={styles.stat}>
          <div>{t('common.studentHours')}</div>
          <div>{formatNumber(studentHours)}</div>
        </div>
        <div className={styles.stat}>
          <div>{t('deputate.titleSingluar')}</div>
          <div>{formatNumber(deputat)}</div>
        </div>
      </div>
    </Card>
  );
};
