import { RouteObject } from 'react-router-dom';
import { SubstitutionsEvaluatePage } from './SubstitutionsEvaluatePage';

export const substitutionEvaluateRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <SubstitutionsEvaluatePage />,
    },
  ];
};
