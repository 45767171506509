import { ErrorState } from '@bp/ui-components';
import { Page } from '../../components/Page/Page';
import { useTranslation } from 'react-i18next';

export const PageNotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('pageNotFound.title')}>
      <ErrorState onNavigateBack={() => {}} type='error' />
    </Page>
  );
};
