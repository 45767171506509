import * as Yup from 'yup';
import i18next from 'i18next';

export const holidaysSchema = Yup.object()
  .shape({
    name: Yup.string().required(i18next.t('validation.holidays.nameRequired')),
    start: Yup.date().required(i18next.t('validation.common.startRequired')),
    end: Yup.date().required(i18next.t('validation.common.endRequired')),
  })
  .test('date-range', function (value) {
    const start = value.start.setHours(0, 0, 0, 0);
    const end = value.end.setHours(0, 0, 0, 0);
    return start <= end
      ? true
      : this.createError({
          message: i18next.t('validation.common.startBeforeEnd'),
          path: 'start',
        });
  });
