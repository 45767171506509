import { TimetableReportType, use_TimetablePrintConfigsQuery } from '../../types/planung-graphql-client-defs';
import { TimetablePrintBaseData, TimetablePrintLayoutData } from './TimetablePrintConfiguration';
import { useMemo } from 'react';
import { TimetablePrintColorOrigins } from '@bp/planung-graphql-types';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { timetablePrintColorDefaults, timetablePrintLayoutDefaults } from '../../utils/printDefaults';

export const useGetInitialTimetablePrintConfig = ({ topic }: { topic: TimetableReportType }) => {
  const subjectColor = '#C0CEF2';
  const teacherColor = '#F2C1C0';
  const roomColor = '#C8F2C0';
  const classColor = '#FAECB9';
  const white = '#f7f7f7';

  const context = useMemorizedCacheTag('TIMETABLE_PRINT_CONFIG');

  const [{ data }] = use_TimetablePrintConfigsQuery({
    variables: {
      where: {
        type: topic,
      },
    },
    context,
    pause: !topic,
  });

  const current = data?.timetablePrintConfigs?.[0];

  const colorDefaults = timetablePrintColorDefaults[topic];

  const initialBaseData: TimetablePrintBaseData = useMemo(() => {
    const getInitialColor = (from?: TimetablePrintColorOrigins | null): string => {
      switch (from) {
        case TimetablePrintColorOrigins.Room:
          return roomColor;
        case TimetablePrintColorOrigins.Subject:
          return subjectColor;
        case TimetablePrintColorOrigins.Teacher:
          return teacherColor;
        case TimetablePrintColorOrigins.Class:
          return classColor;
        case TimetablePrintColorOrigins.None:
          return white;
        default:
          return subjectColor;
      }
    };

    return {
      color: getInitialColor(current?.printColorOrigin),
      colorPosition: current?.printColorPosition || colorDefaults.colorPosition,
      colorOrigin: current?.printColorOrigin || colorDefaults.colorOrigin,
      printClasses: current?.printClasses || colorDefaults.printClasses,
      scaleText: current?.scaleText || colorDefaults.scaleText,
      exactMinute: current?.exactMinute || colorDefaults.exactMinute,
      printSubtitle: current?.printSubtitle || colorDefaults.printSubtitle,
      printLogo: current?.printLogo || colorDefaults.printLogo,
    };
  }, [current, colorDefaults]);

  const layoutDefaults = timetablePrintLayoutDefaults[topic];

  const initialLayoutData: TimetablePrintLayoutData = useMemo(() => {
    return {
      bottomCenter: current?.bottomCenter || layoutDefaults.bottomCenter,
      bottomLeft: current?.bottomLeft || layoutDefaults.bottomLeft,
      bottomRight: current?.bottomRight || layoutDefaults.bottomRight,
      center: current?.center || layoutDefaults.center,
      topLeft: current?.topLeft || layoutDefaults.topLeft,
      topCenter: current?.topCenter || layoutDefaults.topCenter,
      topRight: current?.topRight || layoutDefaults.topRight,
    };
  }, [current, layoutDefaults]);

  return { initialBaseData, initialLayoutData };
};
