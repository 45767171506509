import { Button, Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import styles from './TimeConflictModal.module.scss';
import dayjs from 'dayjs';
import { useTeachingBlockStore } from '../../TeachingBlockContext';

export const TimeConflictModal = observer(() => {
  const { t } = useTranslation();

  const { holidays, timeConflictModal, setTimeConflictModal, ignoreTimeConflict, splitTimeConflict, abortConflict } =
    useTeachingBlockStore();

  const isSchoolYearEnd = !!timeConflictModal.holidayUuids?.find((uuid) => uuid === 'school-year-end-indicator');
  // (timeConflictModal.holidays &&
  //   timeConflictModal.holidays.length > 0 &&
  //   dayjs(timeConflictModal.holidays[timeConflictModal.holidays.length - 1].end).isAfter(dayjs(schoolYear.end)));

  // const holidayConflicts = groupByArray(timeConflictModal.holidays ?? [], (h) => h.name);
  // const teachingBlockConflicts = groupByArray(timeConflictModal.cards ?? [], (c) => c.label);

  const holidayConflicts = timeConflictModal.holidayUuids?.map((uuid) => holidays.get(uuid)) ?? [];

  return (
    <Modal
      width='s'
      title={
        isSchoolYearEnd
          ? t('teachingBlockVersion.timeConflict.schoolYearEnd')
          : t('teachingBlockVersion.timeConflict.found')
      }
      isOpen={timeConflictModal.isOpen ?? false}
      onRequestClose={() => setTimeConflictModal({ isOpen: false })}
      hideCloseButton
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      parent={document.fullscreenElement ? document.getElementById('teaching-block-grid') : null}
      className={styles['time-conflict-modal']}
    >
      {isSchoolYearEnd ? (
        <div className='mb-6'>{t('teachingBlockVersion.timeConflict.schoolYearEndHint')}</div>
      ) : (
        <div className={styles.conflicts}>
          <div className='mb-2'>{t('teachingBlockVersion.timeConflict.conflicts')}</div>
          {holidayConflicts.map((conflict, i) => {
            return (
              <div key={i} className={styles.conflict}>
                {`${conflict?.name} (${dayjs(conflict?.start).format('DD.MM.YY')} - ${dayjs(conflict?.end).format('DD.MM.YY')})`}
              </div>
            );
          })}
          {/* {teachingBlockConflicts.map((conflict, i) => {
            const card = conflict[0];
            return (
              <div key={i} className={styles.conflict}>
                {`${card?.label} (${dayjs(card?.startDate).format('DD.MM.YY')} - ${dayjs(card?.endDate).format('DD.MM.YY')})`}
              </div>
            );
          })} */}
          <div className='mt-5 mb-2'>{t('teachingBlockVersion.timeConflict.planned')}</div>
          <div
            className={styles.conflict}
          >{`${timeConflictModal.card?.label} (${dayjs(timeConflictModal.start).format('DD.MM.YY')} - ${dayjs(timeConflictModal.end).format('DD.MM.YY')})`}</div>
        </div>
      )}

      <div>{t('common.howToSolve')}</div>
      <div className={styles.actions}>
        <Button className='mr-3' hierarchy='secondary' onClick={() => ignoreTimeConflict()}>
          {t('teachingBlockVersion.timeConflict.ignore')}
        </Button>
        {!isSchoolYearEnd && (
          <Button className='mr-3' hierarchy='secondary' onClick={() => splitTimeConflict()}>
            {t('teachingBlockVersion.timeConflict.split')}
          </Button>
        )}
        <Button hierarchy='secondary' onClick={() => abortConflict()}>
          {t('teachingBlockVersion.timeConflict.abort')}
        </Button>
      </div>
    </Modal>
  );
});
