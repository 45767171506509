import yup from '../../../../globals/yup.custom';

export const validationSchema = yup.object().shape({
  uuid: yup.string(),
  name: yup.string().required('name required'),
  groups: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('name required'),
        shortName: yup.string().required('shortName required'),
      }),
    )
    .min(1, 'min 1 group')
    .max(20, 'max 20 groups')
    .required('groups required'),
});
