import React, { createContext, ReactNode, useState } from 'react';
import { UserConfigStore } from '../../stores/UserConfigStore';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { LazyLoader } from '@bp/ui-components';

export const UserConfigContext = createContext<UserConfigStore>({} as UserConfigStore);
export const userConfigStore = new UserConfigStore();

export function UserConfigProvider({ children }: { children: ReactNode }) {
  const { pimAuthClaims } = useAuthClaims();
  const profile = pimAuthClaims.getProfile();
  const [loading, setLoading] = useState(true);

  if (profile.uuid) {
    userConfigStore.loadUserConfig(pimAuthClaims).then(() => {
      setLoading(false);
    });
  }

  return (
    <>
      {loading ? (
        <LazyLoader embedded forceHeight='100vh' forceWidth='100vw' size='xxl' />
      ) : (
        <UserConfigContext.Provider value={userConfigStore}>{children}</UserConfigContext.Provider>
      )}
    </>
  );
}
