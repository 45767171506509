import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import de from './translations/de.json';

i18n.use(initReactI18next).init({
  returnNull: false,
  returnObjects: false,
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
  lng: 'de',
  interpolation: {
    escapeValue: false,
  },
});

// eslint-disable-next-line import/no-default-export
export default i18n;
