import { DatePicker } from '@bp/ui-components';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { SchoolYearListType } from './SchoolYearForm';

type DatePickerFieldProps = {
  label: string;
  fieldName: 'end' | 'start';
  validateOnChange: boolean;
};

export const DatePickerSchoolYearsField = ({ label, fieldName, validateOnChange }: DatePickerFieldProps) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<SchoolYearListType>();

  const newEnd = useMemo(() => {
    return dayjs(values.start).utc(true).add(1, 'year').subtract(1, 'day').toDate();
  }, [values.start]);

  useEffect(() => {
    if (fieldName === 'start' && touched.start && !touched.end && !validateOnChange) {
      setFieldValue('end', newEnd, validateOnChange);
    }
  }, [fieldName, newEnd, setFieldTouched, setFieldValue, touched.end, touched.start, validateOnChange]);

  return (
    <DatePicker
      label={label}
      name={fieldName}
      onChange={(date) => {
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, dayjs(date).utc(true).toDate(), validateOnChange);
      }}
      value={values[fieldName]}
      error={errors[fieldName] as string}
      showIcon
    />
  );
};
