import { LessonsQuery, LessonsWithoutCardsQuery, TeachingLoadFactorsQuery } from '../types/planung-graphql-client-defs';
import { useMemo } from 'react';
import { lessonResponseToTableType } from '../components/Lessons/utils/lessonResponseToTableType';
import { LessonTableType } from '../components/Lessons/Tables/TimetableVersionLessonsTable';

export type LessonResponseToTableTypeProps = {
  queryLessonsData: LessonsQuery | LessonsWithoutCardsQuery | undefined;
  teachingLoadFactors?: TeachingLoadFactorsQuery | undefined;
  versionContext: boolean;
};

export const useLessonResponseToTableType = ({
  versionContext,
  teachingLoadFactors,
  queryLessonsData,
}: LessonResponseToTableTypeProps): LessonTableType[] => {
  return useMemo(() => {
    return lessonResponseToTableType({
      versionContext,
      teachingLoadFactors,
      queryLessonsData,
    });
  }, [queryLessonsData, teachingLoadFactors, versionContext]);
};
