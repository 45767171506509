import { GridCard } from '../GridCard/GridCard';
import React, { Fragment, useCallback } from 'react';
import { useTimetableStore } from '../TimetableProvider';
import { observer } from 'mobx-react-lite';
import { CellItem } from '../../../stores/PinboardStore';
import style from './GridCards.module.scss';

export const GridCards = observer(({ cell }: { cell: CellItem }) => {
  const store = useTimetableStore();

  const cardsArray = store.getPlacedCardsForCell(cell);

  const handleClick = useCallback(() => {
    store.placeCard(cell);
  }, [cell, store]);

  if (cardsArray.length === 0) {
    return null;
  }

  return (
    <div className={style['grid-card-wrapper']} onClick={handleClick}>
      {cardsArray?.map((card, i) => {
        const borders = ((card.duration ?? 1) - 1) * 2;
        const gridCards = store.getGridCards(card.uuid);

        if (card.startTimeGridEntry?.uuid !== cell.hour.value) {
          return <Fragment key={card.uuid}></Fragment>;
        }

        if (store.context === 'classes') {
          return gridCards
            ?.filter((cc) => {
              return cc.classUuid === cell.row.value;
            })
            .map((cc) => {
              return (
                <Fragment key={`${cc.timetableCardUuid}-${cc.uuid}`}>
                  <GridCard
                    width={`calc(${(card.duration ?? 1) * 100}% + ${borders}px)`}
                    height={cc.cssHeight ?? '100%'}
                    left={'0%'}
                    top={cc.cssTop ?? '0%'}
                    background={cc.cssGradientString ?? ''}
                    textColor={cc.textColor ?? ''}
                    card={card}
                    cell={cell}
                  />
                </Fragment>
              );
            });
        }
        if (store.context === 'rooms') {
          const cc = gridCards?.find((cc) => cc.timetableCardUuid === card.uuid);
          if (cc) {
            return (
              <Fragment key={`${cc.timetableCardUuid}-${cc.uuid}`}>
                <GridCard
                  width={`calc(${(card.duration ?? 1) * 100}% + ${borders}px)`}
                  height={'100%'}
                  left={'0%'}
                  top={'0%'}
                  background={cc.cssGradientString ?? ''}
                  textColor={cc.textColor ?? ''}
                  card={card}
                  cell={cell}
                />
              </Fragment>
            );
          }
          return <Fragment key={card.uuid}></Fragment>;
        }
        if (store.context === 'teachers') {
          const cc = gridCards?.find((cc) => cc.timetableCardUuid === card.uuid);
          if (cc) {
            return (
              <Fragment key={`${cc.timetableCardUuid}-${cc.uuid}`}>
                <GridCard
                  width={`calc(${(card.duration ?? 1) * 100}% + ${borders}px)`}
                  height={cc.cssHeight ?? '100%'}
                  left={'0%'}
                  top={cc.cssTop ?? '0%'}
                  background={cc.cssGradientString ?? ''}
                  textColor={cc.textColor ?? ''}
                  card={card}
                  cell={cell}
                />
              </Fragment>
            );
          }
          return <Fragment key={card.uuid}></Fragment>;
        }
        return <Fragment key={card.uuid}></Fragment>;
      })}
    </div>
  );
});
GridCards.displayName = 'GridClassCell';
