import { Input, LazyLoader } from '@bp/ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { Suspense } from 'react';
import { createCurriculumVersionSchema } from './validation/schema';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import {
  useCreateCurriculumVersionMutation,
  useUpdateCurriculumVersionsMutation,
} from '../../../types/planung-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { CombinedError } from 'urql';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';

type CurriculumVersionFormProps = {
  closeForm: () => void;
  currentVersion?: CurriculumVersionType | null;
};

export type CurriculumVersionType = {
  uuid?: string;
  name: string;
  description?: string | null;
  used?: boolean;
};
export const CurriculumVersionForm = observer(({ closeForm, currentVersion }: CurriculumVersionFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('CURRICULUM_VERSIONS');

  const [, createCurriculumVersion] = useCreateCurriculumVersionMutation();
  const [, updateCurriculumVersion] = useUpdateCurriculumVersionsMutation();

  const handleSubmit = async (values: CurriculumVersionType, formikHelpers: FormikHelpers<CurriculumVersionType>) => {
    let result: { error?: CombinedError | undefined } = { error: undefined };

    if (currentVersion) {
      result = await updateCurriculumVersion(
        {
          update: {
            description: values.description,
            name: values.name,
          },
          where: {
            uuid: currentVersion.uuid,
          },
        },
        context,
      );
      showSuccessUpdateToast([values.name]);
    } else if (!currentVersion) {
      result = await createCurriculumVersion(
        {
          input: {
            name: values.name,
            description: values.description,
            organization: { connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } } },
            validGrades: [0],
          },
        },
        context,
      );
      if (!result.error) {
        showSuccessCreateToast([values.name]);
      }
    }

    if (result.error) {
      showUserErrorToast({ error: result.error });
    }
    formikHelpers.resetForm();
    closeForm();
  };

  const initialValues = {
    description: currentVersion?.description ?? '',
    name: currentVersion?.name ?? '',
  };

  return (
    <Suspense fallback={<LazyLoader transparent forceHeight='40vh' />}>
      <Formik<CurriculumVersionType>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={createCurriculumVersionSchema}
      >
        {(formik) => (
          <Form>
            <div className='form-block'>
              <Input
                name={'name'}
                value={formik.values.name}
                onChange={formik.handleChange}
                label={t('curriculumVersion.name')}
                error={formik.errors.name}
              />
              <Input
                name={'description'}
                value={formik.values.description ?? ''}
                onChange={formik.handleChange}
                label={t('curriculumVersion.description')}
                error={formik.errors.description}
              />
            </div>
            <ModalBottomButtons
              closeButton={{
                text: t('common.cancel'),
                callback: () => {
                  formik.resetForm();
                  closeForm();
                },
              }}
              submitButton={{
                disabled: Object.keys(formik.errors).length > 0,
              }}
              isLoading={formik.isSubmitting}
              errors={formik.errors}
            />
          </Form>
        )}
      </Formik>
    </Suspense>
  );
});
