import * as yup from 'yup';
import i18next from 'i18next';

export const schema = yup.object().shape({
  uuid: yup.string(),
  comment: yup.string().nullable(),
  hoursRaw: yup.string().required(i18next.t('validation.additionalTeachingLoad.hoursRequired')),
  teacherUuid: yup
    .string()
    .test('empty-uuid', i18next.t('validation.additionalTeachingLoad.teacherRequired'), (uuid) => {
      return !!uuid?.length;
    })
    .required(i18next.t('validation.additionalTeachingLoad.teacherRequired')),
  subjectUuid: yup.string().nullable(),
  classUuid: yup.string().nullable(),
});
