import { useTranslation } from 'react-i18next';
import styles from '../../Constraints.module.scss';
import { Button, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { useFormikContext } from 'formik';
import { SingleValue } from 'react-select';
import { ConstraintsFormInitialValuesType } from '../../types';
import { usePriorityOptions } from '../../../../hooks/usePriorityOptions';
import classNames from 'classnames';

type StartEndConditionItemProps = {
  onRemove: () => void;
  options: SelectOptionType[];
};

export const StartEndConstraintItem = ({ onRemove, options }: StartEndConditionItemProps) => {
  const { t } = useTranslation();
  const { setFieldValue, setFieldTouched, values, errors } = useFormikContext<ConstraintsFormInitialValuesType>();

  const priorityOptions = usePriorityOptions();

  const handleRemove = () => {
    setFieldValue('startEnd', null);
    setFieldTouched('startEnd', false);
    setFieldTouched('startEndPriority', false);
    setFieldValue('startEndPriority', null);
    onRemove();
  };

  return (
    <>
      <div className={styles['title']}>{t('conditions.startEnd.label')}</div>
      <div className={styles['row-wrapper']}>
        <div className='tks__row row-xs-11 mr-5'>
          <div className='tks__col col-xs-6'>
            <Select
              value={options.find((o) => o.value === values.startEnd?.latestStart)}
              options={options}
              onChange={async (value) => {
                const opt = value as SingleValue<SelectOptionType>;
                await setFieldTouched('startEnd.latestStart', true);
                await setFieldValue('startEnd.latestStart', opt?.value);
              }}
              label={t('conditions.startEnd.latestStart') as string}
              name={'startEnd.latest'}
              isClearable={true}
              error={errors.startEnd}
            />
          </div>
          <div className='tks__col col-xs-6'>
            <Select
              value={options.find((o) => o.value === values.startEnd?.earliestEnd)}
              options={options}
              onChange={async (value) => {
                const opt = value as SingleValue<SelectOptionType>;
                await setFieldTouched('startEnd.earliestEnd', true);
                await setFieldValue('startEnd.earliestEnd', opt?.value);
              }}
              label={t('conditions.startEnd.earliestEnd') as string}
              name={'startEnd.earliest'}
              isClearable={true}
            />
          </div>
        </div>
        <div className='tks__col col-xs-1'>
          <Select
            className={classNames(styles['priority-icon-select'], styles[`icon-${values.startEndPriority}`])}
            options={priorityOptions}
            value={priorityOptions.find((option) => option.value === values.startEndPriority)}
            onChange={(value) => {
              const opt = value as SingleValue<SelectOptionType>;
              setFieldTouched('startEndPriority', true);
              setFieldValue('startEndPriority', opt?.value);
            }}
            label={t('common.priority.full') as string}
            name={'startEndPriority'}
            fitMenuToOptions
            showIconOnly
            error={errors.startEndPriority}
          />
        </div>
        <div className={styles['button-wrapper']}>
          <Button name='remove' hierarchy='secondary' onClick={handleRemove} icon={<DeleteIcon />} />
        </div>
      </div>
    </>
  );
};
