export class StoreHandler<T> {
  protected stores: { [key: string]: T } = {};

  register = (key: string, createStore: () => T): T => {
    if (!this.stores[key]) {
      this.stores[key] = createStore();
    }
    return this.stores[key];
  };

  get = (key: string): T => {
    return this.stores[key];
  };
}
