import { ContextMenu, LockIcon, PinIcon, RoomIcon } from '@bp/ui-components';
import styles from './GridCard.module.scss';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { TeachingBlockCardType } from '../types';
import { MouseEvent, useCallback } from 'react';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import { useTeachingBlockCardMenu } from '../../../hooks/useTeachingBlockCardMenu';
import { useDebounceCallback } from 'usehooks-ts';
import { toJS } from 'mobx';

type GridCardProps = {
  card: TeachingBlockCardType;
  width: string;
  height: string;
  top: string;
  background: string;
  textColor: string;
  onCardClick: (event: MouseEvent<HTMLDivElement>, card: TeachingBlockCardType) => void;
};

export const GridCard = observer(({ card, width, height, top, background, textColor, onCardClick }: GridCardProps) => {
  const { pinnedCard, pickedCard, setCardDurationModal, highlightMode, selectCard, selectedCard } =
    useTeachingBlockStore();

  const isPicked =
    card.uuid === pickedCard?.card.uuid ||
    (!!card.virtualChildren && !!card.virtualChildren.find((uuid) => card.uuid.includes(uuid)));

  const isPinned =
    card.uuid === pinnedCard?.uuid ||
    (!!card.virtualChildren && !!card.virtualChildren.find((uuid) => card.uuid.includes(uuid)));

  const isLocked = card.locked ?? false;

  const hasWarnings = !!card.warnings && card.warnings.length > 0;
  const hasAvailabilityWarnings = !!card.availabilityWarnings && card.availabilityWarnings.length > 0;

  function onCardEditOpen() {
    setCardDurationModal({
      isOpen: true,
      card: card ?? undefined,
    });
  }

  const { menu, hasRooms } = useTeachingBlockCardMenu({
    card: card,
    styles,
    hasWarnings,
    hasAvailabilityWarnings,
    isPicked,
    isPinned,
    onCardEditOpen,
  });

  const menuClasses = classNames(styles.menu, { [styles['has-warning']]: hasWarnings || hasAvailabilityWarnings });

  const cardClasses = classNames(styles['grid-card'], {
    [styles.picked]: isPicked,
    [styles.pinned]: isPinned,
    [styles.locked]: isLocked,
    [styles.warning]: hasWarnings || hasAvailabilityWarnings,
    // [styles['high-light']]:
    //   pinboardStore.controlMode.isActive &&
    //   pinboardStore.controlMode.highlights.length !== 0 &&
    //   pinboardStore.controlMode.highlights.includes(card.uuid),
    // [styles['low-light']]:
    //   pinboardStore.controlMode.isActive &&
    //   pinboardStore.controlMode.highlights.length !== 0 &&
    //   !pinboardStore.controlMode.highlights.includes(card.uuid),
  });

  const debounceOnCardHover = useDebounceCallback(() => {
    selectCard(toJS(card));
  }, 500);

  const onMouseEnter = useCallback(() => {
    if (!selectedCard) {
      selectCard(toJS(card));
    } else {
      debounceOnCardHover();
    }
  }, [card, debounceOnCardHover, selectCard, selectedCard]);

  const onMouseLeave = useCallback(() => {
    debounceOnCardHover.cancel();
  }, [debounceOnCardHover]);

  const onOpenChange = useCallback(() => {
    debounceOnCardHover.cancel();
    selectCard(toJS(card));
  }, [card, debounceOnCardHover, selectCard]);

  return (
    <>
      <ContextMenu
        className={menuClasses}
        data={menu}
        parent={document.getElementById('teaching-block-grid')}
        onOpenChange={onOpenChange}
      >
        <div
          key={card.uuid}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={(e) => onCardClick(e, card)}
          title={card.label}
          className={cardClasses}
          style={{
            height,
            width: `round(nearest, ${width}, 1px)`,
            top,
            background,
            color: textColor,
          }}
        >
          <div className={styles.title}>{card.labelShort}</div>
          {!highlightMode.includes('noIcons') && (card.pinned || card.locked || !hasRooms) && (
            <div className={styles.info}>
              {card.pinned && <PinIcon className={styles.icon} />}
              {card.locked && <LockIcon className={styles.icon} />}
              {!hasRooms && <RoomIcon className={styles.icon} />}
            </div>
          )}
        </div>
      </ContextMenu>
    </>
  );
});
