import { PersonFormValuesType } from '../graphql/types';
import {
  CreatePeopleDocument,
  PersonQualificationsCreateFieldInput,
  UpdatePeopleDocument,
  UpdatePeopleMutation,
  UpdatePeopleMutationVariables,
  UpdateShortNamesDocument,
} from '../../../types/planung-graphql-client-defs';
import { urqlClient } from '../../../utils/urqlClient';
import { OperationContext } from '@urql/core';
import { isNotEmpty } from '../../../utils/typeguards';

type SubmitType = {
  values: PersonFormValuesType;
  uuid?: string;
  pimProfileUuid: string | null;
  organizationUuid: string;
  personsRole: string;
  context: Partial<OperationContext>;
};

export const formSubmit = async ({
  values,
  organizationUuid,
  uuid,
  pimProfileUuid,
  personsRole,
  context,
}: SubmitType) => {
  let mutationResult;
  const basics = {
    pimProfileUuid: pimProfileUuid,
    firstName: values.firstName,
    lastName: values.lastName,
    personalId: values.personalId,
    email: values.email,
    ...(values.birthday && values.birthday instanceof Date && { birthday: values.birthday }),
    shortName: values.shortName,
    gender: values.gender,
    title: values.title,
    active: values.active,
    salutation: values.salutation,
    prefixName: values.prefixName,
  };

  const contracts = values.contracts.map((contractData) => {
    // remove __typename and uuid
    const { __typename, uuid, ...data } = contractData;
    return {
      node: {
        ...data,
      },
    };
  });

  const qualificationsNode: PersonQualificationsCreateFieldInput[] = values.qualifications
    .filter((qualification) => isNotEmpty(qualification.subject.uuid))
    .map((qualification) => {
      return {
        node: {
          maxGrade: qualification.maxGrade ?? null,
          minGrade: qualification.minGrade ?? null,
          maxTeachingLoad: qualification.maxTeachingLoad ?? null,
          subject: {
            connect: {
              where: {
                node: {
                  uuid: qualification.subject.uuid,
                },
              },
            },
          },
          owner: {
            connect: {
              where: {
                node: {
                  uuid: uuid,
                },
              },
            },
          },
        },
      };
    });

  if (!uuid) {
    mutationResult = await urqlClient
      .mutation(
        CreatePeopleDocument,
        {
          input: [
            {
              ...basics,
              defaultRoom: { connect: { where: { node: { uuid: values.defaultRoom ?? '' } } } },
              contracts: {
                create: contracts,
              },
              qualifications: {
                create: qualificationsNode,
              },
              timetableConfig: {
                create: {
                  node: {
                    color: values.timetableConfig?.color,
                    colorLabel: values.timetableConfig?.colorLabel,
                    organization: {
                      connect: {
                        where: {
                          node: { uuid: organizationUuid },
                        },
                      },
                    },
                  },
                },
              },
              organization: {
                connect: {
                  edge: {
                    name: personsRole,
                  },
                  where: {
                    node: {
                      uuid: organizationUuid,
                    },
                  },
                },
              },
            },
          ],
        },
        context,
      )
      .toPromise();
  } else {
    mutationResult = await urqlClient
      .mutation<UpdatePeopleMutation, UpdatePeopleMutationVariables>(
        UpdatePeopleDocument,
        {
          where: {
            uuid: uuid,
          },
          update: {
            ...basics,
            defaultRoom: {
              disconnect: {},
              connect: {
                where: {
                  node: {
                    uuid: values.defaultRoom ?? '',
                  },
                },
              },
            },
            contracts: [{ delete: [{}] }, { create: contracts }],
            qualifications: [{ delete: [{}] }, { create: qualificationsNode }],
            timetableConfig: {
              update: {
                node: {
                  color: values.timetableConfig?.color,
                  colorLabel: values.timetableConfig?.colorLabel,
                },
              },
            },
          },
        },
        context,
      )
      .toPromise();
  }
  await urqlClient.mutation(UpdateShortNamesDocument, { organizationUuid: organizationUuid }).toPromise();
  return mutationResult;
};
