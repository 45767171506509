import { Button, ButtonGroup, Card, Grid, GridColumn, GridRow, Table, TableColumns } from '@bp/ui-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection, useMathplanJobQuery } from '../../types/planung-graphql-client-defs';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import dayjs from 'dayjs';
import {
  cancelTimetableVersionGeneration,
  importTimetableVersionGeneration,
} from '../../utils/timetable/timetableVersion';

type MathplanJobsListDataType = {
  jobId: string;
  status: string;
  schoolyear: string;
  createdAt?: dayjs.Dayjs;
  updatedAt?: dayjs.Dayjs;
  gap: number;
  solution?: string;
  solutionFound: boolean;
  problem?: string;
  errors?: string;
};

export const MathplanJobsTable = observer(() => {
  const { t } = useTranslation();

  const { sorting, saveSorting } = useColumnsSort('mathplan-job-list');

  const [selectedJob, setSelectedJob] = useState<MathplanJobsListDataType | null>(null);

  const [{ data }, refetchJobs] = useMathplanJobQuery({
    variables: {
      where: {},
      options: {
        sort: [{ createdAt: SortDirection.Desc }],
      },
    },
  });

  const mathplanJobs: MathplanJobsListDataType[] = useMemo(() => {
    return (
      data?.mathplanJobs.map((o) => {
        return {
          ...o,
          schoolyear:
            o.timetableVersion.timetable.schoolYear.name +
            ' / ' +
            o.timetableVersion.timetable.name +
            '#' +
            o.timetableVersion.version,
          createdAt: dayjs(o.createdAt),
          updatedAt: dayjs(o.updatedAt),
          solution: o.solution ?? undefined,
          problem: o.problem ?? undefined,
          errors: o.errors ?? undefined,
        };
      }) ?? []
    );
  }, [data]);

  const tableColumns: TableColumns<MathplanJobsListDataType>[] = useMemo(() => {
    return [
      {
        header: t('common.createdAt'),
        accessorKey: 'createdAt',
        id: 'createdAt',
        type: 'date',
        accessorFn: (row) => {
          return row.createdAt?.format('DD.MM.YY HH:mm:ss') ?? '';
        },
        size: 150,
      },
      {
        header: t('common.name'),
        accessorKey: 'schoolyear',
        id: 'schoolyear',
        canExpand: true,
      },
      {
        header: t('common.status'),
        accessorKey: 'status',
        id: 'status',
        canExpand: true,
      },
      {
        header: t('mathplan.solutionFound'),
        accessorKey: 'solutionFound',
        id: 'solutionFound',
        type: 'boolean',
      },
    ];
  }, [t]);

  return (
    <Grid>
      <GridRow>
        <GridColumn width={6} fitRowHeight>
          <Table<MathplanJobsListDataType>
            showBorderRadius
            showShadow
            canScroll
            minHeight={600}
            breakpoint={null}
            isOnWhite={false}
            onSortingChange={saveSorting}
            sorting={sorting}
            columns={tableColumns}
            data={mathplanJobs}
            showActionBar
            actionBarSettings={{
              showExpertFilter: false,
              showAddButton: false,
              showBulkEdit: false,
              showPrintButton: false,
            }}
            lastColWidth='120px'
            lastCol={(row) => {
              return (
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      setSelectedJob(row.original);
                    }}
                  >
                    Details
                  </Button>
                </ButtonGroup>
              );
            }}
          />
        </GridColumn>
        {selectedJob && (
          <GridColumn width={6} fitRowHeight>
            <GridRow headline={'Stundenplan'}>
              <GridColumn width={12}>
                <p>Solver-ID: {selectedJob?.jobId}</p>
                <p>Name / Schuljahr: {selectedJob?.schoolyear}</p>
                <p>Erstellt: {selectedJob?.createdAt?.toISOString()}</p>
                <p>zuletzt aktualisiert: {JSON.stringify(selectedJob?.updatedAt)}</p>
                <p>gap: {selectedJob?.gap}</p>
              </GridColumn>
            </GridRow>
            {!['FINISHED', 'ERROR', 'CANCELLED'].includes(selectedJob.status) && (
              <Card canScroll contentPadding={'s'} fitContent>
                <Button
                  onClick={async () => {
                    await cancelTimetableVersionGeneration(selectedJob.jobId);
                    refetchJobs({ requestPolicy: 'network-only' });
                  }}
                >
                  Abbrechen
                </Button>
              </Card>
            )}
            {['FINISHED', 'SOLUTION'].includes(selectedJob.status) && (
              <Card canScroll contentPadding={'s'} fitContent>
                <Button
                  onClick={async () => {
                    await importTimetableVersionGeneration(selectedJob.jobId);
                    refetchJobs({ requestPolicy: 'network-only' });
                  }}
                >
                  Erneut importieren
                </Button>
              </Card>
            )}
            {selectedJob.problem && (
              <Card
                header={{
                  headline: 'Problem (XML)',
                  actions: [
                    {
                      hierarchy: 'secondary',
                      text: 'Download',
                      callback: () => {
                        const file = new Blob([selectedJob?.problem ?? ''], { type: 'text/xml' });
                        const url = URL.createObjectURL(file);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `problem-${selectedJob?.jobId}.xml`;
                        document.body.appendChild(a);
                        a.click();
                      },
                    },
                  ],
                }}
                collapsibleOn={'always'}
                canScroll
                contentPadding={'s'}
                fitContent
                isCollapsed
              >
                <pre>{selectedJob?.problem}</pre>
              </Card>
            )}
            {selectedJob.solution && (
              <Card
                header={{ headline: 'Solution (JSON)' }}
                collapsibleOn={'always'}
                canScroll
                contentPadding={'s'}
                fitContent
                isCollapsed
              >
                <pre>{JSON.stringify(JSON.parse(selectedJob?.solution ?? '{}'), null, 2)}</pre>
              </Card>
            )}
            {selectedJob.solution && (
              <Card header={{ headline: 'Solution (LOG)' }} canScroll contentPadding={'s'} fitContent>
                <pre>{JSON.parse(selectedJob?.solution ?? '{}').schoolTimetablingProblem.log}</pre>
              </Card>
            )}
            {selectedJob.errors && (
              <Card
                header={{ headline: 'Errors' }}
                collapsibleOn={'always'}
                canScroll
                contentPadding={'s'}
                fitContent
                isCollapsed
              >
                <pre>{JSON.stringify(JSON.parse(selectedJob?.errors ?? '{}'), null, 2)}</pre>
              </Card>
            )}
          </GridColumn>
        )}
      </GridRow>
    </Grid>
  );
});
