import { useAuthClaims } from './useAuthClaims';
import { useMemorizedCacheTag } from './useMemorizedCacheTag';
import { UsedInCurriculaQuery, useUsedInCurriculaQuery } from '../types/planung-graphql-client-defs';

export type UsedInCurriculumQueryDataType = Pick<UsedInCurriculaQuery, 'curricula'>['curricula'][0];

export const useIsUsedInCurriculum = () => {
  const { pimAuthClaims } = useAuthClaims();

  const context = useMemorizedCacheTag('CURRICULA');

  const [{ data }] = useUsedInCurriculaQuery({
    variables: { curriculaWhere: { organization: { uuid: pimAuthClaims.getOrganizationUuid() } } },
    context,
  });

  return (
    uuid: string,
    entityToCheck?: 'subject' | 'room',
  ): { isUsed: boolean; curricula: UsedInCurriculumQueryDataType[] } => {
    const checkSubject = (curricula: UsedInCurriculumQueryDataType) => curricula.subject.uuid === uuid;
    const checkRoom = (curricula: UsedInCurriculumQueryDataType) =>
      curricula.roomSupplyConnection.edges.some((r) => r.node.uuid === uuid);

    const checkEntity = (curricula: UsedInCurriculumQueryDataType) => {
      if (!entityToCheck || entityToCheck === 'subject') {
        return checkSubject(curricula);
      }
      if (!entityToCheck || entityToCheck === 'room') {
        return checkRoom(curricula);
      }
      return false;
    };

    const foundCurricula = data?.curricula.filter((c) => checkEntity(c)) || [];

    return { isUsed: foundCurricula.length > 0, curricula: foundCurricula };
  };
};
