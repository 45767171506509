import { useTranslation } from 'react-i18next';
import { FC, useMemo, useState } from 'react';
import { useMutation } from 'urql';
import { DeleteTimeGridDocument } from '../../types/planung-graphql-client-defs';
import { TimeGridType } from './graphql/types';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import {
  AddIcon,
  AlertDialog,
  Button,
  ButtonGroup,
  Card,
  DeleteIcon,
  EditIcon,
  Modal,
  Row,
  RowSelectionState,
  showToast,
  Table,
  TableColumns,
} from '@bp/ui-components';
import TimeGridForm from './Forms/TimeGridForm';
import { observer } from 'mobx-react-lite';
import styles from './TimeGrid.module.scss';
import { useColumnsSort } from '../../hooks/useColumnsSort';

type TimeGidListProps = {
  data: TimeGridType[] | null;
  onSelect: (uuid: string) => void;
};

const TimeGidList: FC<TimeGidListProps> = ({ data, onSelect }) => {
  const { t } = useTranslation();

  const { sorting, saveSorting } = useColumnsSort('time_grid');
  const context = useMemorizedCacheTag('TIME_GRID');

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [timeGrid, setTimeGrid] = useState<null | TimeGridType>(null);
  const [, deleteTimeGrid] = useMutation(DeleteTimeGridDocument);
  const [selected, setSelected] = useState<RowSelectionState>({ 0: true });

  const createColumns = (): TableColumns<TimeGridType>[] => {
    return [
      {
        header: t('timeGrid.name'),
        accessorKey: 'name',
        id: 'name',
      },
      {
        header: t('common.comment'),
        accessorKey: 'description',
        id: 'description',
      },
      {
        header: t('timeGrid.hours'),
        accessorFn: (originalRow) => {
          return originalRow.itemsConnection.edges.filter((e) => !e.node.pause).length;
        },
        id: 'hours',
      },
      {
        header: t('timeGrid.pauses'),
        accessorFn: (originalRow) => {
          return originalRow.itemsConnection.edges.filter((e) => e.node.pause).length;
        },
        id: 'pause',
      },
    ];
  };

  const tableColumns = useMemo(createColumns, []);

  const memoizedData = useMemo((): TimeGridType[] => {
    return data && data ? data : [];
  }, [data]);

  const onAddClick = () => {
    setTimeGrid(null);
    setModalOpen(true);
  };

  const handleEdit = (row: Row<TimeGridType>) => {
    setTimeGrid(row.original ?? null);
    setModalOpen(true);
  };

  const closeModal = () => {
    setTimeGrid(null);
    setModalOpen(false);
  };

  async function handleDelete(row: Row<TimeGridType>) {
    if (!row.original.uuid) {
      return;
    }
    const response = await deleteTimeGrid(
      {
        uuid: row.original.uuid,
      },
      context,
    );
    if (response.error) {
      showToast(response.error.message, { type: 'error' });
    } else {
      showToast('SUCCESS DELETE TIME_GRID', { type: 'success' });
    }
    closeModal();
  }

  return (
    <>
      <Card className={styles['grid-list']} contentPadding='none'>
        <div className={styles['grid-list-header']}>
          <div className={styles['title']}>{t('timeGrid.title')}</div>
          <Button className={styles['button']} hierarchy='tertiary' icon={<AddIcon />} onClick={onAddClick}>
            {t('common.add')}
          </Button>
        </div>
        <Table
          sorting={sorting}
          onSortingChange={saveSorting}
          columns={tableColumns}
          data={memoizedData}
          onRowClick={(event, row) => {
            setSelected({ [row.id]: true });
            onSelect(row.original.uuid);
          }}
          showSelected
          showRowHover
          rowSelection={selected}
          lastColWidth='80px'
          lastCol={(row) => {
            return (
              <ButtonGroup>
                <Button
                  hierarchy='secondary'
                  type='button'
                  icon={<EditIcon className='small' />}
                  onClick={() => {
                    handleEdit(row);
                  }}
                />
                <AlertDialog
                  title={t('timeGrid.deleteConfirm', {
                    name: row.original.name,
                  })}
                  confirmText={t('common.confirmDelete')}
                  cancelText={t('common.cancelDelete')}
                  onConfirm={async () => {
                    await handleDelete(row);
                  }}
                  trigger={<Button hierarchy='secondary' type='button' icon={<DeleteIcon className='small' />} />}
                />
              </ButtonGroup>
            );
          }}
        />
      </Card>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={timeGrid ? (t('timeGrid.edit') as string) : (t('timeGrid.create') as string)}
      >
        <TimeGridForm timeGrid={timeGrid} handleClose={closeModal}></TimeGridForm>
      </Modal>
    </>
  );
};

export default observer(TimeGidList);
