import { useGetHolidays } from '../../hooks/useGetHolidays';
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TeachingBlockStore } from '../../stores/TeachingBlockStore';
import { useTeachingBlockLessons } from '../Lessons/hooks/useTeachingBlockLessons';
import {
  _ClassesQuery,
  _LessonClassesQuery,
  _PeopleQuery,
  use_LessonsQuery,
  use_TeachingBlockAvailabilityExclusionsQuery,
  use_TeachingBlockCardsQuery,
  use_TeachingBlockVersionsQuery,
} from '../../types/planung-graphql-client-defs';
import { TeachingBlockCardType } from './types';
import dayjs from 'dayjs';
import { useLoadBasicData } from '../../hooks/useLoadBasicData';
import { BadgeCardRow, LazyLoader } from '@bp/ui-components';
import { useTeachingBlockDataCards } from '../../hooks/useTeachingBlockDataCards';
import { createIntArray } from '../../utils/arrayFunc';
import { StoreHandler } from '../../stores/StoreHandler';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { availabilityStoreHandler } from '../../pages/Timetable/Plan/TeachingBlockVersion/TeachingBlockVersion';
import { ClassDivisionGroupFormat } from '../../stores/DayAvailabilityStore';
import { getDaysDifference } from '../../utils/dateCalculations';

const teachingBlockStoreHandler = new StoreHandler<TeachingBlockStore>();

const TeachingBlockStoreContext = createContext<TeachingBlockStore>({} as TeachingBlockStore);

export const TeachingBlockProvider: FC<PropsWithChildren> = ({ children }) => {
  const { teachingBlockVersionUuid } = useParams();
  const { holidays, schoolYear } = useGetHolidays();

  const teachingBlockCardsContext = useMemorizedCacheTag('TEACHING_BLOCK_CARDS');
  const lessonContext = useMemorizedCacheTag('LESSONS');

  const { currentVersion } = useTeachingBlockLessons({ versionUuid: teachingBlockVersionUuid ?? '' });
  const availabilityStore = availabilityStoreHandler.get(currentVersion?.uuid ?? '');

  const [{ data: versionsData }] = use_TeachingBlockVersionsQuery({
    variables: {
      where: {
        uuid: teachingBlockVersionUuid,
      },
    },
    pause: !teachingBlockVersionUuid || teachingBlockVersionUuid === '',
  });

  const [{ data: lessonsQueryData }] = use_LessonsQuery({
    variables: {
      where: {
        teachingBlockVersions_SOME: { uuid: teachingBlockVersionUuid },
      },
    },
    pause: !teachingBlockVersionUuid || teachingBlockVersionUuid === '',
    context: lessonContext,
  });

  const { subjectData, lessonClassesData, classesData, teacherData, groupsData, roomsData } = useLoadBasicData({
    pause: !versionsData,
  });

  const version = versionsData?.teachingBlockVersions[0];

  const versionClasses = useMemo(() => {
    return version?.classesConnection.edges.map((edge) => edge.node.uuid) ?? [];
  }, [version?.classesConnection.edges]);

  const versionRooms = useMemo(() => {
    return version?.roomsConnection.edges.map((edge) => edge.node.uuid) ?? [];
  }, [version?.roomsConnection.edges]);

  const versionTeachers = useMemo(() => {
    return version?.teachersConnection.edges.map((edge) => edge.node.uuid) ?? [];
  }, [version?.teachersConnection.edges]);

  const versionSubjects = useMemo(() => {
    return version?.subjectsConnection.edges.map((edge) => edge.node.uuid) ?? [];
  }, [version?.subjectsConnection.edges]);

  const [{ data }] = use_TeachingBlockCardsQuery({
    variables: {
      where: {
        teachingBlockVersion: { uuid: teachingBlockVersionUuid },
        lessonAggregate: { count_GT: 0 },
      },
    },
    pause: !teachingBlockVersionUuid,
    context: teachingBlockCardsContext,
  });

  const [{ data: availabilityData }] = use_TeachingBlockAvailabilityExclusionsQuery({
    variables: {
      where: {
        teachingBlockVersion: { uuid: teachingBlockVersionUuid },
      },
    },
    pause: !teachingBlockVersionUuid,
  });

  const teachingBlockDataCards = useTeachingBlockDataCards(teachingBlockVersionUuid ?? '');

  const rooms = useMemo(() => {
    return (
      roomsData?.rooms.map((r) => ({ ...r, building: { ...r.building, uuid: r.building?.uuid ?? '', name: '' } })) ?? []
    );
  }, [roomsData?.rooms]);

  const cards: TeachingBlockCardType[] = useMemo(() => {
    return (
      data?.teachingBlockCards.map((card): TeachingBlockCardType => {
        const lesson = lessonsQueryData?.lessons.find((lesson) => lesson.uuid === card?.lesson.uuid);
        const subject = subjectData?.subjects.find((subject) => subject.uuid === lesson?.subject.uuid);

        const start = dayjs(card.startDate).startOf('day');
        const end = dayjs(card.endDate).startOf('day');
        const difference =
          card.startDate && card.endDate ? getDaysDifference(start, end, true) : (card.duration ?? 1) * 7;

        // grid cards
        const classGridCards = teachingBlockDataCards.classGridCards.filter((classGridCard) => {
          return classGridCard.lessonUuid === lesson?.uuid;
        });
        const teacherGridCards = teachingBlockDataCards.teacherGridCards.filter((teacherGridCard) => {
          return teacherGridCard.lessonUuid === lesson?.uuid;
        });
        const roomGridCards = teachingBlockDataCards.roomGridCards.filter((roomGridCard) => {
          return roomGridCard.lessonUuid === lesson?.uuid;
        });

        // data
        const lessonClasses =
          lesson?.lessonClassesConnection.edges.map((edge) => {
            return lessonClassesData?.lessonClasses.find((lessonClass) => lessonClass.uuid === edge.node.uuid);
          }) ?? [];

        const groupUuids = lessonClasses.map((lc) => {
          return lc?.groups.map((g) => g.uuid);
        });
        const groups = groupsData?.groups.filter((group) => {
          return groupUuids.some((groupUuid) => groupUuid?.includes(group.uuid)) ?? false;
        });

        const classes = lessonClasses
          .map((lc) => {
            return classesData?.classes.find((c) => c.uuid === lc?.class.uuid);
          })
          .sort((a, b) => {
            return a?.name.localeCompare(b?.name ?? '') ?? 0;
          });

        const teachers = lesson?.teachersConnection.edges.map((t) => {
          return (
            teacherData?.people.find((p) => p.uuid === t.node.uuid) ?? ({} as Pick<_PeopleQuery, 'people'>['people'][0])
          );
        });

        // positions + divisions
        const postions: number[] = [];
        classGridCards.forEach((c) => {
          if ((c.divisionsCount ?? 1) > 1) {
            postions.push(c.usedPosition ?? 1);
          } else {
            postions.push(0);
          }
        });

        const cardWithBiggestDivisionsCount = classGridCards.find(
          (c) => c.divisionsCount === Math.max(...classGridCards.map((c) => c.divisionsCount ?? 1)),
        );

        const classDivisionGroups: ClassDivisionGroupFormat[] = [];
        lessonClasses.forEach((lc) => {
          lc?.groups.forEach((g) => {
            if (lc.usedDivision) {
              classDivisionGroups.push(
                `${lc.class.uuid}:${lc.usedDivision?.uuid ? lc.usedDivision.uuid : ''}:${g.uuid}`,
              );
            }
          });
        });

        // availabilty
        const availabilityCard = {
          uuid: card.uuid,
          rooms: card.rooms.map((r) => r.uuid),
          classes: classes.map((c) => c?.uuid ?? ''),
          teachers: teachers?.map((t) => t.uuid) ?? [],
          classDivisionGroup: classDivisionGroups,
        };

        const rows: BadgeCardRow[] = createIntArray(cardWithBiggestDivisionsCount?.divisionsCount ?? 1).map((i) => {
          if (postions.includes(i)) {
            return {
              rowColor: '',
              colColors: cardWithBiggestDivisionsCount?.colors ?? [],
            };
          }
          return {
            rowColor: '',
            colColors: [],
          };
        });

        const teachingBlockCard: TeachingBlockCardType = {
          ...card,
          label: subject?.name ?? '',
          labelShort: subject?.shortName ?? '',
          durationInDays: difference ?? 0,
          subject,
          lesson,
          classGridCards,
          teacherGridCards,
          roomGridCards,
          lessonClasses:
            (lessonClasses.filter((lc) => lc) as Pick<_LessonClassesQuery, 'lessonClasses'>['lessonClasses']) ?? [], // hack hack hack since we remove lessonClasses or change cache (no idea if it will help then). After lesson update (delete lessonClasses / create lessonClasses) we have to reload the page. We can not delete / create lessonClasses separately because of missing connection
          classes: (classes.filter((c) => c) as Pick<_ClassesQuery, 'classes'>['classes']) ?? [], // hack hack hack since we remove lessonClasses
          teachers: teachers ?? [],
          groups: groups ?? [],
          groupClassNames: lesson?.groupClassNames ?? '',
          badgeCardRows: rows,
          badgeCardTextColor: cardWithBiggestDivisionsCount?.textColor ?? '',
          availabilityCard: availabilityCard,
        };

        return teachingBlockCard;
      }) ?? []
    );
  }, [
    classesData?.classes,
    data?.teachingBlockCards,
    groupsData?.groups,
    lessonClassesData?.lessonClasses,
    lessonsQueryData?.lessons,
    subjectData?.subjects,
    teacherData?.people,
    teachingBlockDataCards,
  ]);

  const teachingBlockStore = useMemo(() => {
    if (
      teachingBlockVersionUuid &&
      holidays &&
      schoolYear &&
      cards &&
      currentVersion &&
      roomsData?.rooms &&
      classesData?.classes &&
      teacherData?.people &&
      subjectData?.subjects
    ) {
      return teachingBlockStoreHandler.register(teachingBlockVersionUuid, () => {
        if (availabilityStore) {
          availabilityData?.teachingBlockAvailabilityExclusions.forEach((exclusion) => {
            availabilityStore.setExclusion({
              holder: exclusion.holder,
              from: exclusion.from,
              to: exclusion.to,
              priority: exclusion.priority,
            });
          });

          cards.forEach((card) => {
            if (card.startDate !== null && card.endDate !== null) {
              const classDivisionGroups: ClassDivisionGroupFormat[] = [];
              card.lessonClasses.forEach((lc) => {
                lc?.groups.forEach((g) => {
                  if (lc.usedDivision) {
                    classDivisionGroups.push(
                      `${lc.class.uuid}:${lc.usedDivision?.uuid ? lc.usedDivision.uuid : ''}:${g.uuid}`,
                    );
                  }
                });
              });

              availabilityStore.setCardsResourcesPlaced(
                [
                  {
                    uuid: card.uuid,
                    teachers: card.teachers.map((t) => t.uuid),
                    classes: card.classes.map((c) => c.uuid),
                    classDivisionGroup: classDivisionGroups,
                    rooms: card.rooms.map((r) => r.uuid),
                  },
                ],
                {
                  start: card.startDate,
                  end: card.endDate,
                },
              );
            }
          });
        }

        const store = new TeachingBlockStore({
          holidays,
          schoolYear,
          rooms,
          initialCards: cards,
          currentVersion,
        });

        cards.forEach((c) => store.mapRoomsForCard(c));

        store.loadData({
          classes: classesData?.classes.filter((c) => versionClasses.includes(c.uuid)),
          rooms: roomsData?.rooms
            .filter((c) => versionRooms.includes(c.uuid))
            .sort((a, b) => a.name.localeCompare(b.name)),
          teachers: teacherData?.people
            .filter((c) => versionTeachers.includes(c.uuid))
            .sort((a, b) => a.lastName.localeCompare(b.lastName)),
          subjects: subjectData?.subjects
            .filter((c) => versionSubjects.includes(c.uuid))
            .sort((a, b) => a.name.localeCompare(b.name)),
        });
        return store;
      });
    }

    return null;
  }, [
    availabilityData?.teachingBlockAvailabilityExclusions,
    availabilityStore,
    cards,
    classesData?.classes,
    currentVersion,
    holidays,
    rooms,
    roomsData?.rooms,
    schoolYear,
    subjectData?.subjects,
    teacherData?.people,
    teachingBlockVersionUuid,
    versionClasses,
  ]);

  return (
    <>
      {teachingBlockStore ? (
        <TeachingBlockStoreContext.Provider value={teachingBlockStore}>{children}</TeachingBlockStoreContext.Provider>
      ) : (
        <LazyLoader transparent forceHeight='60vh' />
      )}
    </>
  );
};

export const useTeachingBlockStore = () => useContext(TeachingBlockStoreContext);
