import { IconMenuItem } from '../../components/IconMenuItem/IconMenuItem';
import IconMenu from '../../components/IconMenu/IconMenu';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EvaluateIllustration, PlanIllustration, SetupIllustration } from '@bp/ui-components';
import { Page } from '../../components/Page/Page';

export const TimetablePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Page title={t('timetable.title.singular')}>
      <IconMenu headline={t('common.whatToDo')}>
        <IconMenuItem
          label={t('timetable.setup')}
          icon={<SetupIllustration />}
          bubbleType={14}
          onClick={() => navigate(t('routes.setup.slug'))}
        />
        <IconMenuItem
          label={t('timetable.plan')}
          icon={<PlanIllustration />}
          bubbleType={16}
          onClick={() => navigate(t('routes.plan.slug'))}
        />
        <IconMenuItem
          label={t('timetable.evaluate')}
          icon={<EvaluateIllustration />}
          bubbleType={17}
          onClick={() => navigate(t('routes.evaluate.slug'))}
        />
      </IconMenu>
    </Page>
  );
};
