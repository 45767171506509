import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  use_LessonsQuery,
  use_TimeGridEntriesQuery,
  use_TimetablesQuery,
  use_TimetableVersionsQuery,
} from '../../types/planung-graphql-client-defs';
import { useLoadBasicData } from '../../hooks/useLoadBasicData';
import { StoreHandler } from '../../stores/StoreHandler';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { DayItem, PinboardStore } from '../../stores/PinboardStore';
import { CardType } from '../TimetableVersion/graphql/types';
import { LazyLoader } from '@bp/ui-components';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useTranslation } from 'react-i18next';
import { timeAvailabilityStoreHandler } from '../../pages/Timetable/Plan/TimetableVersion/TimetableVersion';
import { useGetTimetableCardType } from '../../hooks/useGetTimetableCardType';
import { isNotEmpty } from '../../utils/typeguards';

const timetableStoreHandler = new StoreHandler<PinboardStore>();
const TimetableStoreContext = createContext<PinboardStore>({} as PinboardStore);

export const TimetableProvider: FC<PropsWithChildren> = ({ children }) => {
  const { versionUuid } = useParams();
  const selectedSchoolYear = useUserConfigContext().selectedSchoolYear;
  const lessonContext = useMemorizedCacheTag('LESSONS');
  const versionContext = useMemorizedCacheTag('TIMETABLE_VERSIONS');

  const { t } = useTranslation();

  const [{ data: versionsData }] = use_TimetableVersionsQuery({
    variables: {
      where: {
        uuid: versionUuid,
      },
    },
    context: versionContext,
    pause: !versionUuid,
  });

  const version = versionsData?.timetableVersions[0];

  const versionClasses = useMemo(() => {
    return version?.classesConnection.edges.map((edge) => edge.node.uuid) ?? [];
  }, [version?.classesConnection.edges]);

  const [{ data: timetableData }] = use_TimetablesQuery({
    variables: {
      where: {
        versions_SOME: { uuid: versionUuid },
      },
    },
    pause: !versionUuid,
  });

  const timetableActive = timetableData?.timetables[0]?.draft === false;

  const timegridUuid = timetableData?.timetables[0]?.timegridConfigsConnection.edges[0]?.node.timegrid?.uuid;

  const [{ data: lessonsQueryData }] = use_LessonsQuery({
    variables: {
      where: {
        versions_SOME: { uuid: versionUuid },
      },
    },
    pause: !versionUuid || versionUuid === '',
    context: lessonContext,
  });

  const { subjectData, classesData, teacherData, roomsData } = useLoadBasicData({
    pause: !lessonsQueryData,
  });

  const [{ data: timeGridEntriesData }] = use_TimeGridEntriesQuery({
    variables: {
      where: {
        timeGrid: {
          uuid: timegridUuid,
        },
      },
    },
    pause: !timegridUuid,
  });

  const availabilityStore = timeAvailabilityStoreHandler.get(versionUuid ?? '');

  const cards: CardType[] = useGetTimetableCardType(versionUuid ?? '');

  const timetableStore = useMemo(() => {
    if (
      version &&
      selectedSchoolYear &&
      cards &&
      timeGridEntriesData?.timeGridEntries &&
      classesData?.classes &&
      roomsData?.rooms &&
      subjectData?.subjects &&
      teacherData?.people
    ) {
      return timetableStoreHandler.register(version.uuid, () => {
        const days: DayItem[] = [];

        days.push(
          { value: 'mon', label: t('days.monday') },
          { value: 'tue', label: t('days.tuesday') },
          { value: 'wed', label: t('days.wednesday') },
          { value: 'thu', label: t('days.thursday') },
          { value: 'fri', label: t('days.friday') },
        );
        // if (timetableData?.timetables[0].monEnabled) {
        //   days.push({ value: 'mon', label: t('days.monday') });
        // }
        // if (timetableData?.timetables[0].tueEnabled) {
        //   days.push({ value: 'tue', label: t('days.tuesday') });
        // }
        // if (timetableData?.timetables[0].wedEnabled) {
        //   days.push({ value: 'wed', label: t('days.wednesday') });
        // }
        // if (timetableData?.timetables[0].thuEnabled) {
        //   days.push({ value: 'thu', label: t('days.thursday') });
        // }
        // if (timetableData?.timetables[0].friEnabled) {
        //   days.push({ value: 'fri', label: t('days.friday') });
        // }
        // if (timetableData?.timetables[0].satEnabled) {
        //   days.push({ value: 'sat', label: t('days.saturday') });
        // }
        // if (timetableData?.timetables[0].sunEnabled) {
        //   days.push({ value: 'sun', label: t('days.sunday') });
        // }
        const timeGridEntries = timeGridEntriesData?.timeGridEntries.sort((a, b) => a.order - b.order) ?? [];
        const store = new PinboardStore({
          currentVersion: {
            uuid: version.uuid,
            versionName: `${version.version}`,
            description: version.description ?? '',
            timetableName: timetableData?.timetables[0].name ?? '',
            version: version.version,
            active: version.active ?? false,
          },
          timeGridEntries: timeGridEntries,
          days,
          timeAvailabilityStore: availabilityStore,
          readonly: isNotEmpty(version.editable)
            ? !version.editable
            : (timetableActive && version.active) || version.isSnapshot || false,
          timetableActive: timetableActive,
          temporarilyEditable: (timetableActive && version.active && version.editable) ?? false,
        });

        if (availabilityStore) {
          timeGridEntries.forEach((entry) => {
            availabilityStore.setResourceAvailability(
              versionsData.timetableVersions[0].classesConnection.edges.map((edge) => {
                return {
                  uuid: edge.node.uuid,
                  days: {
                    mon: edge.properties.mon,
                    tue: edge.properties.tue,
                    wed: edge.properties.wed,
                    thu: edge.properties.thu,
                    fri: edge.properties.fri,
                    sat: edge.properties.sat,
                    sun: edge.properties.sun,
                  },
                };
              }),
              'class',
            );
            availabilityStore.setResourceAvailability(
              versionsData.timetableVersions[0].teachersConnection.edges.map((edge) => {
                return {
                  uuid: edge.node.uuid,
                  days: {
                    mon: edge.properties.mon,
                    tue: edge.properties.tue,
                    wed: edge.properties.wed,
                    thu: edge.properties.thu,
                    fri: edge.properties.fri,
                    sat: edge.properties.sat,
                    sun: edge.properties.sun,
                  },
                };
              }),
              'teacher',
            );
            availabilityStore.setResourceAvailability(
              versionsData.timetableVersions[0].roomsConnection.edges.map((edge) => {
                return {
                  uuid: edge.node.uuid,
                  days: {
                    mon: edge.properties.mon,
                    tue: edge.properties.tue,
                    wed: edge.properties.wed,
                    thu: edge.properties.thu,
                    fri: edge.properties.fri,
                    sat: edge.properties.sat,
                    sun: edge.properties.sun,
                  },
                };
              }),
              'room',
            );
            availabilityStore.setResourceAvailability(
              versionsData.timetableVersions[0].subjectsConnection.edges.map((edge) => {
                return {
                  uuid: edge.node.uuid,
                  days: {
                    mon: edge.properties.mon,
                    tue: edge.properties.tue,
                    wed: edge.properties.wed,
                    thu: edge.properties.thu,
                    fri: edge.properties.fri,
                    sat: edge.properties.sat,
                    sun: edge.properties.sun,
                  },
                };
              }),
              'subject',
            );
          });
        }
        store.initData({
          cards,
          classes: classesData?.classes.filter((c) => versionClasses.includes(c.uuid)),
          rooms: roomsData?.rooms.sort((a, b) => a.name.localeCompare(b.name)),
          teachers: teacherData?.people.sort((a, b) => a.lastName.localeCompare(b.lastName)),
          subjects: subjectData?.subjects.sort((a, b) => a.name.localeCompare(b.name)),
        });
        return store;
      });
    }
    return null;
  }, [
    availabilityStore,
    cards,
    classesData?.classes,
    roomsData?.rooms,
    selectedSchoolYear,
    subjectData?.subjects,
    teacherData?.people,
    timeGridEntriesData?.timeGridEntries,
    timetableActive,
    timetableData?.timetables,
    version,
    versionClasses,
    versionsData?.timetableVersions,
  ]);

  return (
    <>
      {timetableStore ? (
        <TimetableStoreContext.Provider value={timetableStore}>{children}</TimetableStoreContext.Provider>
      ) : (
        <LazyLoader transparent forceHeight='60vh' />
      )}
    </>
  );
};

export const useTimetableStore = () => useContext(TimetableStoreContext);
