import { Table, TableColumns } from '@bp/ui-components';
import { RowSelectionState } from '@tanstack/react-table';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { SchoolYearType } from '../SchoolYear/graphql/types';
import { CurrentSchoolYear } from '../../stores/UserConfigStore';

type HolidaysListProps = {
  data: SchoolYearType[] | null;
  currentSchoolYear: CurrentSchoolYear | null;
  onSelect: (yearUuid: string) => void;
};

const HolidaysList: FC<HolidaysListProps> = ({ data, currentSchoolYear, onSelect }) => {
  const { t } = useTranslation();

  const { sorting, saveSorting } = useColumnsSort('holidays-list');
  const [selected, setSelected] = useState<RowSelectionState>({ 0: true });

  const createColumns = (): TableColumns<SchoolYearType>[] => {
    return [
      {
        header: t('schoolYear.name'),
        accessorKey: 'name',
        id: 'name',
      },
      {
        header: t('schoolYear.shortName'),
        accessorKey: 'shortName',
        id: 'shortName',
      },
      {
        header: t('holidays.title') as string,
        id: 'holidays',
        type: 'number',
        cell: ({ row }) => {
          return row.original.holidays.length;
        },
      },
    ];
  };

  const columns = useMemo(createColumns, []);

  const memoizedData = useMemo((): SchoolYearType[] => {
    return data && data ? data : [];
  }, [data]);

  useEffect(() => {
    const index = memoizedData.findIndex((year) => year.uuid === currentSchoolYear?.uuid);
    setSelected(index ? { [index]: true } : { 0: true });
  }, [currentSchoolYear?.uuid, memoizedData]);

  return (
    <Table
      showBorderRadius
      showShadow
      showSelected
      canScroll
      breakpoint={null}
      columns={columns}
      data={memoizedData}
      sorting={sorting}
      onSortingChange={saveSorting}
      showRowHover
      rowSelection={selected}
      onRowClick={(event, row) => {
        setSelected({ [row.id]: true });
        onSelect(row.original.uuid);
      }}
    />
  );
};

export default HolidaysList;
