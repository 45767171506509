import React from 'react';
import { FormikHelpers } from 'formik/dist/types';
import { Form, Formik } from 'formik';
import { iphisSchema } from './validation/iphisSchema';
import { Grid, GridColumn, GridRow, Input, ModalBottomButtons } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { showSuccessSaveToast, showUserErrorToast } from '../../../utils/toast';
import {
  useOrganizationsQuery,
  useUpdateSubstitutionSettingsMutation,
} from '../../../types/planung-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';

export type IphisSubstitutionSettingsType = {
  dbHost: string;
  dbPort: string;
  dbName: string;
  dbUser: string;
  dbPass: string;
  schema: string;
};

export const IphisSubstitutionSettingsForm = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();

  const [{ data }] = useOrganizationsQuery({});
  const [, updateSubstitionSettings] = useUpdateSubstitutionSettingsMutation();

  let initialValues: IphisSubstitutionSettingsType = {
    dbHost: '10.11.102.1',
    dbPort: '5432',
    dbName: 'iphis',
    dbUser: '',
    dbPass: '',
    schema: '',
  };
  try {
    initialValues = {
      ...initialValues,
      ...JSON.parse(
        data?.organizations.find((orga) => orga.uuid === pimAuthClaims.getOrganizationUuid())?.iphisSubstitutionProps ??
          '{}',
      ),
    };
  } catch (e) {
    console.error(e);
  }

  const handleSubmit = async (
    values: IphisSubstitutionSettingsType,
    formikHelpers: FormikHelpers<IphisSubstitutionSettingsType>,
  ) => {
    let result;
    if (values?.dbHost) {
      result = await updateSubstitionSettings({
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
        update: {
          iphisSubstitutionProps: JSON.stringify(values),
        },
      });
    } else {
      result = await updateSubstitionSettings({
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
        update: { iphisSubstitutionProps: JSON.stringify({}) },
      });
    }
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessSaveToast();
    }
    formikHelpers.resetForm();
  };

  return (
    <Formik<IphisSubstitutionSettingsType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={iphisSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values, handleChange, resetForm, dirty, isValidating, isSubmitting, errors, touched }) => (
        <Form>
          <Grid useFormGap>
            <GridRow spacingBottom='none' breakpoint='phone'>
              <GridColumn width={4}>
                <Input
                  label={t('substitutions.settings.iphis.dbHost')}
                  name={'dbHost'}
                  onChange={handleChange}
                  value={values.dbHost}
                  error={touched.dbHost ? errors.dbHost : undefined}
                ></Input>
              </GridColumn>
              <GridColumn width={4}>
                <Input
                  label={t('substitutions.settings.iphis.dbPort')}
                  name={'dbPort'}
                  onChange={handleChange}
                  value={values.dbPort}
                  error={touched.dbPort ? errors.dbPort : undefined}
                ></Input>
              </GridColumn>
              <GridColumn width={4}>
                <Input
                  label={t('substitutions.settings.iphis.dbName')}
                  name={'dbName'}
                  onChange={handleChange}
                  value={values.dbName}
                  error={touched.dbName ? errors.dbName : undefined}
                ></Input>
              </GridColumn>
            </GridRow>
            <GridRow spacingBottom='none' breakpoint='phone'>
              <GridColumn width={4}>
                <Input
                  label={t('substitutions.settings.iphis.dbUser')}
                  name={'dbUser'}
                  onChange={handleChange}
                  value={values.dbUser}
                  error={touched.dbUser ? errors.dbUser : undefined}
                ></Input>
              </GridColumn>
              <GridColumn width={4}>
                <Input
                  label={t('substitutions.settings.iphis.dbPass')}
                  name={'dbPass'}
                  onChange={handleChange}
                  value={values.dbPass}
                  error={touched.dbPass ? errors.dbPass : undefined}
                ></Input>
              </GridColumn>
              <GridColumn width={4}>
                <Input
                  label={t('substitutions.settings.iphis.schema')}
                  name={'schema'}
                  onChange={handleChange}
                  value={values.schema}
                  error={touched.schema ? errors.schema : undefined}
                ></Input>
              </GridColumn>
            </GridRow>
          </Grid>
          <ModalBottomButtons
            closeButton={{
              text: t('common.cancelChanges'),
              callback: () => {
                resetForm();
              },
            }}
            submitButton={{ disabled: isSubmitting || !dirty || isValidating }}
            errors={errors}
          />
        </Form>
      )}
    </Formik>
  );
};
