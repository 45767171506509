import { observer } from 'mobx-react-lite';
import { RoomItem, RoomTypes, TeachingBlockCardType } from '../../types';
import { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  GridRow,
  ImportantIcon,
  Modal,
  ModalBottomButtons,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import classNames from 'classnames';
import styles from './CardRoomsForm.module.scss';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';
import { useTeachingBlockStore } from '../../TeachingBlockContext';
import { TeachingBlockCard } from '../../TeachingBlockCard/TeachingBlockCard';

type CardRoomsFormProps = {
  card: TeachingBlockCardType;
  onClose: () => void;
  className?: string | undefined;
};

export const CardRoomsForm = observer(({ card, onClose, className }: CardRoomsFormProps) => {
  const { t } = useTranslation();

  const { setRoomsForCards, removeRoomsFromCards, cardRooms, placedCards } = useTeachingBlockStore();

  const [conflicts, setConflicts] = useState<RoomItem[]>([]);
  const [overrides, setOverrides] = useState<RoomItem[]>([]);
  const [roomValues, setRoomValues] = useState<SelectOptionType[]>([]);
  const [touched, setTouched] = useState<boolean>(false);

  const groupedRooms = cardRooms.get(card.uuid);

  const allRooms: RoomItem[] = [
    ...(groupedRooms?.get('assigned') ?? []),
    ...(groupedRooms?.get('lesson') ?? []),
    ...(groupedRooms?.get('subject') ?? []),
    ...(groupedRooms?.get('teacher') ?? []),
    ...(groupedRooms?.get('class') ?? []),
    ...(groupedRooms?.get('school') ?? []),
  ];

  const roomOptions: SelectOptionType[] = allRooms.map((r) => ({ ...r, group: getGroupTranslation(r.group) }));

  function getGroupTranslation(group: RoomTypes): string {
    switch (group) {
      case 'assigned':
        return t('common.selected');
      case 'lesson':
        return t('rooms.stock');
      case 'subject':
        return t('subject.title.plural');
      case 'teacher':
        return t('persons.title.plural');
      case 'class':
        return t('classes.title.plural');
      case 'school':
        return t('common.school');
      default:
        return t('common.noGroup');
    }
  }

  const handleSubmit = async () => {
    if (overrides.length > 0) {
      const cards = placedCards.filter((card) =>
        overrides.find((override) => card.rooms.some((room) => room.uuid === override.value)),
      );
      void removeRoomsFromCards(
        cards,
        overrides.map((o) => ({
          name: o.label,
          uuid: o.value,
        })),
      );
    }

    void setRoomsForCards(
      [card],
      roomValues.map((r) => ({
        name: r.label ?? '',
        uuid: (r.value as string) ?? '',
      })),
    );

    resetForm();
    onClose();
  };

  function resetForm() {
    setRoomValues([]);
    setConflicts([]);
    setOverrides([]);
    setTouched(false);
  }

  function onAssignDouble() {
    setRoomValues([...roomValues, ...conflicts.map((c) => ({ value: c.value, label: c.label }))]);
    setConflicts([]);
    setOverrides([]);
    setTouched(true);
  }

  function onRemoveOtherRooms() {
    setRoomValues([...roomValues, ...conflicts.map((c) => ({ value: c.value, label: c.label }))]);
    setOverrides(conflicts);
    setConflicts([]);
    setTouched(true);
  }

  function onCancelConflict() {
    setConflicts([]);
    setOverrides([]);
    setTouched(false);
  }

  const classes = classNames(styles['card-rooms-form'], className);

  useEffect(() => {
    setRoomValues(roomOptions.filter((room) => card.rooms.some((r) => r.uuid === room.value)));
  }, []);

  return (
    <>
      <form className={classes}>
        <Grid useFormGap>
          <GridRow spacingBottom='s'>
            <Select
              menuPosition='fixed'
              isMulti
              isSearchable
              hasGroups
              options={roomOptions}
              onChange={(option) => {
                const conflicts: RoomItem[] = [];
                const selectedRooms = roomOptions.filter((roomOption) =>
                  (option as MultiValue<SelectOptionType>).some(({ value }) => value === roomOption.value),
                );
                selectedRooms.forEach((r) => {
                  const room = allRooms.find((room) => room.value === r.value);
                  if (room && room.inUse) conflicts.push(room);
                });
                setConflicts(conflicts);
                if (conflicts.length > 0) return;
                setRoomValues(selectedRooms);
                setTouched(true);
              }}
              value={roomValues.filter((r) => !conflicts.some((c) => c.value === r.value))}
              disabled={!card.startDate}
              name='rooms'
              label={t('pinboard.edit.assignRoom')}
            />
          </GridRow>
          {overrides && overrides.length > 0 && (
            <GridRow className={styles.overrides} spacingTop='none' direction='column'>
              <div className={styles.warning}>
                <ImportantIcon className={styles.icon} />
                {t('pinboard.edit.overrideRoomsHint')}
              </div>
              <div className={styles.cards}>
                {overrides.map((o) => {
                  const card = placedCards.find((c) => c.rooms.find((r) => r.uuid === o.value));
                  return card ? <TeachingBlockCard key={card.uuid} card={card} passive /> : <></>;
                })}
              </div>
            </GridRow>
          )}
        </Grid>

        <ModalBottomButtons
          closeButton={{
            text: t('common.cancelChanges'),
            callback: () => {
              resetForm();
              onClose();
            },
          }}
          submitButton={{
            disabled: !touched,
            callback: handleSubmit,
          }}
        />
      </form>

      <Modal
        isOpen={conflicts.length > 0}
        onRequestClose={() => setConflicts([])}
        title={t('pinboard.roomConflict.alreadyAssigned')}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        hideCloseButton
        style={{ overlay: { zIndex: `calc(var(--z-index-modal) + 1)` } }}
        className={styles['room-conflict-modal']}
      >
        <div className='mb-2'>
          {t('pinboard.roomConflict.alreadyAssignedCards', { room: conflicts && conflicts[0] && conflicts[0].label })}
        </div>
        <div className={styles.cards}>
          {conflicts.map((conflict) => {
            const card = placedCards.find((c) => c.rooms.find((r) => r.uuid === conflict.value));
            return card ? <TeachingBlockCard key={card.uuid} card={card} passive /> : <></>;
          })}
        </div>
        <div>{t('common.howToSolve')}</div>
        <div className={styles.actions}>
          <Button hierarchy='secondary' onClick={() => onAssignDouble()}>
            {t('pinboard.roomConflict.assignDouble')}
          </Button>
          <Button className={styles['long-button']} hierarchy='secondary' onClick={() => onRemoveOtherRooms()}>
            {t('pinboard.roomConflict.removeOtherRooms')}
          </Button>
          <Button hierarchy='secondary' onClick={() => onCancelConflict()}>
            {t('common.cancel')}
          </Button>
        </div>
      </Modal>
    </>
  );
});
