import { FC } from 'react';
import { Page } from '../../../../components/Page/Page';
import { AdditionalTeachingLoadTable } from '../../../../components/AdditionalTeachingLoad/Tables/AdditionalTeachingLoadTable';
import { useTranslation } from 'react-i18next';

export const AdditionalDeputatePage: FC = () => {
  const { t } = useTranslation();
  return (
    <Page title={t('routes.deputate.additional.title')}>
      <AdditionalTeachingLoadTable />
    </Page>
  );
};
