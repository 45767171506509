import styles from './TimetableVersionsOverview.module.scss';
import { useValidCopyTargets } from './useValidCopyTargets';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
} from '@bp/ui-components';
import { TimetableVersion } from './TimetableVersionsOverview';
import { useTranslation } from 'react-i18next';
import { _TimetablesQuery } from '../../../types/planung-graphql-client-defs';

type TimetableDefaultVersionItemProps = {
  version: TimetableVersion;
  timetable: Pick<_TimetablesQuery, 'timetables'>['timetables'][0] | null;
  onDataNavigate: (uuid: string) => void;
  onBoardNavigate: (uuid: string) => void;
  onDuplicate: (version: TimetableVersion, createNewTimetable: boolean) => void;
  onCopy: (targetTimetableUuid: string, versionUuids: string[]) => void;
  onExport: (version: TimetableVersion) => void;
  onActivate: (version: TimetableVersion) => void;
  onDelete: (version: TimetableVersion) => void;
  onEdit: (version: TimetableVersion) => void;
};

export const TimetableDefaultVersionListItem = ({
  version,
  timetable,
  onDataNavigate,
  onActivate,
  onBoardNavigate,
  onDuplicate,
  onCopy,
  onExport,
  onDelete,
  onEdit,
}: TimetableDefaultVersionItemProps) => {
  const getValidCopyTargets = useValidCopyTargets(timetable);
  const { t } = useTranslation();

  const targets = getValidCopyTargets();

  const menu: DropdownMenuItem[] = [
    {
      label: t('common.duplicate'),
      onClick: () => onDuplicate(version, false),
    },
    {
      label: t('common.copyTo'),
      subContent: [
        {
          label: t('timetableVersion.copyToNewTimetable'),
          onClick: () => onDuplicate(version, true),
        },
        { type: 'ruler' },
        ...targets.map((target) => ({
          label: target.name,
          onClick: () => onCopy(target.uuid, [version.uuid]),
        })),
      ],
    },
    {
      label: t('common.export'),
      onClick: () => onExport(version),
    },
    { type: 'ruler' },
    {
      label: t('common.activate'),
      onClick: () => onActivate(version),
    },
    { type: 'ruler' },
    {
      label: t('common.delete'),
      type: 'default',
      color: 'error',
      onClick: () => onDelete(version),
    },
  ];

  return (
    <div className={styles.data} key={version.uuid}>
      <div className={styles.version}>{version.version}</div>
      <div className={styles.edited}>{version.updatedAt}</div>
      <div className={styles.name}>{version.description}</div>
      <div className={styles.status}>{`${version.status}%`}</div>
      <div className={styles.version}>{version.parentVersion}</div>

      <div className={styles.actions}>
        <Button hierarchy='tertiary' onClick={() => onDataNavigate(version.uuid)} disabled={version.isSnapshot}>
          {t('timetable.timetableData')}
        </Button>
        <Button
          hierarchy='tertiary'
          className='mr-3'
          onClick={() => onBoardNavigate(version.uuid)}
          disabled={version.isSnapshot}
        >
          {t('pinboard.title')}
        </Button>
        <ButtonGroup disabled={version.isSnapshot}>
          <Button
            disabled={version.isSnapshot}
            icon={<EditIcon className='small' />}
            hierarchy='tertiary'
            onClick={() => onEdit(version)}
          />
          <Dropdown
            disabled={version.isSnapshot}
            usePortal={false}
            trigger={
              <Button
                disabled={version.isSnapshot}
                icon={<DotsHorizontalIcon className='small' />}
                hierarchy='tertiary'
              />
            }
          >
            <DropdownMenu usePortal={false} data={menu} />
          </Dropdown>
        </ButtonGroup>
      </div>
    </div>
  );
};
