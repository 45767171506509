import { Card, Header } from '@bp/ui-components';
import { CardPicker } from '../CardPicker/CardPicker';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import styles from './GridActionArea.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTeachingBlockStore } from '../TeachingBlockContext';

export const GridActionArea: FC = observer(() => {
  const { t } = useTranslation();

  const { selectedRows, gridContext } = useTeachingBlockStore();

  const headline = t('pinboard.showSelectionFor', { type: getSelectionType() });

  const selection = selectedRows
    .map((row) => row.label)
    .sort()
    .join(', ');

  function getSelectionType() {
    switch (gridContext) {
      case 'classes':
        return t('classes.title.singular');
      case 'teachers':
        return t('teachers.title');
      default:
        break;
    }
  }

  return (
    <Card
      className={styles['grid-action-area']}
      wrapperClass={classNames(styles.wrapper)}
      contentPadding='none'
      fitContent
    >
      <Header
        className={styles.header}
        headline={selection ? headline : ''}
        subHeadline={selection ?? undefined}
        size='s'
        actions={[
          {
            text: t('pinboard.actions.showTimetable'),
            disabled: true,
            hierarchy: 'tertiary',
          },
        ]}
      />
      <CardPicker />
    </Card>
  );
});
