import { RouteObject } from 'react-router-dom';
import { t } from 'i18next';
import { FactorsPage } from './Factors/FactorsPage';
import { CurriculaPage } from './Curricula/CurriculaPage';
import { curriculaRoutes } from './Curricula/routes';
import { DeputateSetupPage } from './DeputateSetupPage';
import { SetupSubjectsAndSubjectContainer } from '../../Timetable/Setup/SubjectsAndSubjectContainer/SetupSubjectsAndSubjectContainer';
import { SetupPersons } from './Persons/SetupPersons';
import { SetupClassesAndDivisions } from './ClassesAndDivisions/SetupClassesAndDivisions';
import { SetupRoomsAndBuildings } from './RoomsAndBuildings/SetupRoomsAndBuildings';

export const deputateSetupRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <DeputateSetupPage />,
    },
    {
      path: t('routes.subjects.slug') as string,
      element: <SetupSubjectsAndSubjectContainer />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.subjects.slug')}`,
          title: t('routes.subjects.title'),
        }),
      },
    },
    {
      path: t('routes.teacher.slug') as string,
      element: <SetupPersons />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.teacher.slug')}`,
          title: t('routes.teacher.title'),
        }),
      },
    },
    {
      path: t('routes.classes.slug') as string,
      element: <SetupClassesAndDivisions />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.classes.slug')}`,
          title: t('routes.classes.title'),
        }),
      },
    },
    {
      path: t('routes.rooms.slug') as string,
      element: <SetupRoomsAndBuildings />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.rooms.slug')}`,
          title: t('routes.rooms.title'),
        }),
      },
    },
    {
      path: t('routes.factors.slug'),
      element: <FactorsPage />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.factors.slug')}`,
          title: t('routes.factors.title'),
        }),
      },
    },
    {
      path: t('routes.curriculum.slug'),
      element: <CurriculaPage />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.curriculum.slug')}`,
          title: t('routes.curriculum.title'),
        }),
      },
      children: [...curriculaRoutes(baseSlug)],
    },
  ];
};
